import React, { Component, Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isEqual, reverse } from 'lodash'
import isRole from './../../../helpers/roles'
import { UnfoldMoreIcon } from '../../sharedComponents/StyledIcons'

import { colors } from './../../../helpers/foundation'
import { sortByDate } from './../../../utils'
import ProjectCard from './ProjectCard'
import QuoteProjectCard from './QuoteProjectCard'
import Split from '../../sharedComponents/Split'
import Title from '../../sharedComponents/Title'
import { duplicateProject, projectUpdate } from '../../../redux/modules/projects'
import { getTimeDistance } from '../../../helpers/time'

const StyledCardList = styled.div`
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  user-select: none;

  .card {
    margin-bottom: 0.5em;

    .title {
      transition: color 0.15s ease-in-out;
    }

    .content {
      &:hover {
        .title {
          color: ${colors.primary};
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error {
    color: red;
  }

  > .list {
    border-radius: 4px;
    flex-grow: 1;
    padding: 0 0.25em;
    margin: 0.5em 0;
    overflow-x: hidden;
    overflow-y: auto;

    /* Scrollbars */
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 2px;
      background: ${colors.grey7};
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.grey};
      border-radius: 2px;
    }
  }

  > .header {
    padding: 0.5em;
    margin-bottom: 0;

    .semi-head {
      display: flex;
    }

    .asc-dsc-icon {
      margin-left: 0.5em;
      color: ${colors.grey6};
      font-weight: 600;
      cursor: pointer;
      > * {
        cursor: pointer;
      }
    }

    .meta {
      font-size: 14px;
      color: ${colors.grey2};
    }
  }
`

class ProjectList extends Component {
  constructor() {
    super()

    this._isMounted = false

    // interval for global countdown
    this.updateCountdownInterval = null

    this.state = {
      itemsWithTimeDistances: [],
      // 0 is dsc 1 is asc
      order: 0,
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    if (this._isMounted) {
      if (this.props.items && !this.props.isQuotes) {
        this.updateTimeDistances()
      }
      // times will be updated every 30 seconds
      this.updateCountdownInterval = setInterval(this.updateTimeDistances, 30000)
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
    clearInterval(this.updateCountdownInterval)
  }

  componentDidUpdate = prevProps => {
    if (!isEqual(prevProps.items.sort(), this.props.items.sort())) {
      this.updateTimeDistances()
    }
  }

  updateTimeDistances = () => {
    if (this._isMounted && !this.props.isQuotes) {
      let items = []
      if (this.props.user.role !== 'Customer') {
        items = this.props.items.map(item => {
          return {
            ...item,
            timeDistance: getTimeDistance(item, this.props.globalSettings),
          }
        })
      } else {
        items = this.props.items
      }
      this.setState(prevState => ({ itemsWithTimeDistances: items }))
    }
  }

  reverseOrder = e => {
    this.setState(prevState => ({
      order: prevState.order === 0 ? 1 : 0,
    }))
  }

  render() {
    const { itemsWithTimeDistances } = this.state
    const {
      stage,
      status,
      items,
      title,
      hasBorder,
      duplicate,
      update,
      user,
      compact,
      openModal,
      closeModal,
      openDeleteProjectModal,
    } = this.props

    return (
      <StyledCardList stage={stage} hasBorder={hasBorder}>
        <div className="header">
          <Split>
            <div className="semi-head">
              <Title text={title} step={2} tag="h3" size="16px" weight="600" />
              <div className="asc-dsc-icon" onClick={this.reverseOrder}>
                <UnfoldMoreIcon />
              </div>
            </div>
            <span className="meta">{items.length} Projects</span>
          </Split>
        </div>
        {/*
        if the column is quotes, we want to render different project card
        instead of trying to make the project projectcard
        */}
        {this.props.isQuotes ? (
          <div className="list">
            {this.props.isQuotes &&
              this.props.items &&
              this.props.items.length > 0 &&
              sortByDate(this.state.order === 0 ? 'dsc' : 'asc', this.props.items).map(item => {
                // remove janky behavior where quotes would load before other projects
                {
                  /* if (this.props.projectsFetched) { */
                }
                return (
                  <QuoteProjectCard
                    stage={stage}
                    user={user}
                    history={this.props.history}
                    isAdmin={isRole(user).admin}
                    compact={compact}
                    key={item._id}
                    item={item}
                    openModal={openModal}
                    closeModal={closeModal}
                    openDeleteProjectModal={openDeleteProjectModal}
                    fakeItem={
                      item.fakeItem === undefined || item.fakeItem === false ? false : item.fakeItem
                    }
                  />
                )
              })}
          </div>
        ) : itemsWithTimeDistances.length > 0 ? (
          <div className="list">
            {this.props.user.role !== 'Customer' ? (
              <Fragment>
                {sortByDate(this.state.order === 0 ? 'dsc' : 'asc', itemsWithTimeDistances).map(
                  item => {
                    const {
                      additionalClientInfo,
                      estimate,
                      archived,
                      name,
                      _id,
                      slug,
                      members,
                      createdAt,
                      updatedAt,
                      timeDistance,
                      customer,
                    } = item
                    const { expirationDate, expiration } = estimate

                    return (
                      <ProjectCard
                        key={_id}
                        id={_id}
                        history={this.props.history}
                        url={`/project/${_id}/${slug}`}
                        title={name}
                        info={additionalClientInfo}
                        stage={stage}
                        members={members[stage]}
                        archived={archived}
                        expiration={expirationDate}
                        expirationTerm={expiration}
                        createdAt={createdAt}
                        updatedAt={updatedAt}
                        duplicate={duplicate}
                        update={update}
                        user={user}
                        isAdmin={isRole(user).admin}
                        compact={compact}
                        timeDistance={timeDistance}
                        customer={customer}
                        openModal={openModal}
                        closeModal={closeModal}
                        project={item}
                        openDeleteProjectModal={openDeleteProjectModal}
                        fakeItem={
                          item.fakeItem === undefined || item.fakeItem === false
                            ? false
                            : item.fakeItem
                        }
                      />
                    )
                  }
                )}
              </Fragment>
            ) : (
              <Fragment>
                {this.props.items.map(item => {
                  const {
                    additionalClientInfo,
                    estimate,
                    archived,
                    name,
                    _id,
                    slug,
                    members,
                    createdAt,
                    updatedAt,
                    customer,
                  } = item
                  const { expirationDate, expiration } = estimate

                  return (
                    <ProjectCard
                      key={_id}
                      id={_id}
                      url={`/project/${_id}/${slug}`}
                      title={name}
                      info={additionalClientInfo}
                      stage={stage}
                      members={members[stage]}
                      archived={archived}
                      expiration={expirationDate}
                      expirationTerm={expiration}
                      createdAt={createdAt}
                      updatedAt={updatedAt}
                      duplicate={duplicate}
                      update={update}
                      user={user.role}
                      compact={compact}
                      customer={customer}
                      openModal={openModal}
                      closeModal={closeModal}
                      project={item}
                      fakeItem={
                        item.fakeItem === undefined || item.fakeItem === false
                          ? false
                          : item.fakeItem
                      }
                    />
                  )
                })}
              </Fragment>
            )}
          </div>
        ) : (
          <span className="message">Looks like nothing is here.</span>
        )}
      </StyledCardList>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    duplicate: id => dispatch(duplicateProject(id)),
    update: (id, updates) => dispatch(projectUpdate(id, updates)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ProjectList)
