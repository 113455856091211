import PropTypes from 'prop-types'
import styled from 'styled-components'
import { em } from 'polished'
import { colors } from './../../helpers/foundation'

const Tag = styled.span`
  background-color: ${props => (props.variant !== 'bordered' ? props.color : 'transparent')};
  border-radius: 0.2em;
  border: 1px solid transparent;
  display: inline-block;
  vertical-align: baseline;

  color: ${props =>
    props.variant !== 'bordered' ? '#fff' : props.color ? props.color : colors.grey2};
  font-size: ${em('11px')};
  font-family: 'source-code-pro';
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 0.5em;
  margin-right: 0.75em;

  &:last-child {
    margin-right: 0;
  }

  ${props => {
    if (props.variant === 'skeleton') {
      return `
      background-color: ${colors.grey};
      color: ${colors.grey};
      `
    }
    if (props.variant === 'bordered') {
      if (props.color) {
        return `border-color: ${props.color}`
      } else {
        return `border-color: ${colors.grey2}`
      }
    }
  }};
`

Tag.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
}

export default Tag
