import React from 'react'
import styled from 'styled-components'
import { colors } from './../../../../helpers/foundation'
import { em } from 'polished'
import { Link } from 'react-router-dom'
import { differenceInHours } from 'date-fns'

import Icon from './../../../sharedComponents/Icon'
import Tag from './../../../sharedComponents/Tag'

const tagColor = {
  survey: `${colors.blue}`,
  estimate: `${colors.purple}`,
  paperwork: `${colors.orange}`,
  overdue: `${colors.danger}`,
  installation: `${colors.pink}`,
}

const NotificationItem = props => {
  const { notification, toggleNotifications } = props

  // get hours ago
  let timeAgoHours = differenceInHours(new Date(), notification.time)
  let timeAgoDays = null
  let timeAgoHoursRemaining = null
  // if hours past 1 day, get the number of days and then the remaining hours
  if (timeAgoHours > 23) {
    timeAgoDays = Math.floor(timeAgoHours / 24)
    timeAgoHoursRemaining = timeAgoHours % 24
  }

  return (
    <StyledNotificationItem>
      <Link
        to={`/project/${notification.projectId}/${notification.projectSlug}`}
        onClick={() => toggleNotifications(false)}
      >
        <div className="inner">
          {!notification.is_seen && <div className="read-dot" />}
          <div className="time">
            <Icon icon="clock" />
            <div className="time-text">
              {/* 12d 06h or 12h */}
              {timeAgoDays > 0 ? `${timeAgoDays}d ${timeAgoHoursRemaining}h` : `${timeAgoHours}h`}
            </div>
          </div>
          <div className="info">
            <div className="project">
              <div className="name">{notification.projectName}</div>
              <div className="address">
                {notification.projectAddress ? notification.projectAddress : 'No address.'}
              </div>
              <div className="tags">
                <Tag color={tagColor[notification.projectStage]}>{notification.projectStage}</Tag>
                <Tag variant="bordered" bordered={true}>
                  {notification.projectStageStatus}
                </Tag>
              </div>
              <div className="message">This project was due {notification.timeDueAgo}.</div>
            </div>
          </div>
        </div>
      </Link>
    </StyledNotificationItem>
  )
}

const StyledNotificationItem = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors.lightGrey};
  cursor: pointer;

  .inner {
    display: flex;
    user-select: none;
    font-size: 0.875em;
    padding: 0.75rem;

    > a {
      width: 100%;
    }
  }

  .read-dot {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${colors.danger};
  }

  &:first-child {
    border-top: 1px solid ${colors.lightGrey};
  }

  &:hover {
    background-color: ${colors.grey7};
  }

  .time {
    position: relative;
    text-align: center;
    padding-right: 1rem;
    .time-text {
      font-size: 12px;
      position: relative;
      top: -4px;
      color: ${colors.grey};
    }
  }

  .info {
    flex-grow: 1;
  }

  .name {
    /* font-size: ${em('14px')}; */
    font-size: 14px;
    margin-bottom: -4px;
  }

  .address {
    /* font-size: ${em('12px')}; */
    font-size: 12px;
    color: ${colors.grey};
    margin-bottom: -2px;
  }

  .tags {
    font-size: 15px;
    margin-bottom: 10px;
  }
`

export default NotificationItem
