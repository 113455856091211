import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { addPropertyToObjectsOfObject } from './../../../../../../utils'
import { colors } from './../../../../../../helpers/foundation'

import TimerGroup from './TimerGroup'

class Timers extends PureComponent {
  state = {
    surveyDt: {},
    surveyTimers: {},
    estimateTimers: {},
    paperworkTimers: {},
    installationTimers: {},
  }

  componentDidMount = () => {
    const { surveyTimers, estimateTimers, paperworkTimers, installationTimers } = this.props

    // add 'editing' to each timer groups timer
    this.setState(prevState => ({
      surveyTimers: addPropertyToObjectsOfObject(surveyTimers, { editing: false }),
      estimateTimers: addPropertyToObjectsOfObject(estimateTimers, { editing: false }),
      paperworkTimers: addPropertyToObjectsOfObject(paperworkTimers, { editing: false }),
      installationTimers: addPropertyToObjectsOfObject(installationTimers, { editing: false }),
    }))
  }

  editTimer = (timerGroup, timerName) => {
    // instead of toggle, we will just statically set editing, the save
    // will have to make a comparision of old timer value vs new timer value
    this.setState(prevState => ({
      [timerGroup]: {
        ...prevState[timerGroup],
        [timerName]: {
          ...prevState[timerGroup][timerName],
          editing: true,
        },
      },
    }))
  }

  saveTimer = (timerGroup, timerName) => {
    const stateHour = this.state[timerGroup][timerName].hour
    const stateMinute = this.state[timerGroup][timerName].minute
    const propsHour = this.props[timerGroup][timerName].hour
    const propsMinute = this.props[timerGroup][timerName].minute

    // dont save the timer if the value is the same as it was before
    const dontSave = stateHour === propsHour && stateMinute === propsMinute ? true : false

    if (!dontSave) {
      // console.log('running here')
      this.props.updateTimer(timerGroup, timerName, { hour: stateHour, minute: stateMinute })
    }

    this.setState(prevState => ({
      [timerGroup]: {
        ...prevState[timerGroup],
        [timerName]: {
          ...prevState[timerGroup][timerName],
          editing: false,
        },
      },
    }))
  }

  updateTime = (e, timerGroup, hourOrMinute) => {
    // update the value of X or Y => timerGroup: { timerName: { hour: X, minute: Y } }
    // e.target.name will be the name of the timer within its timerGroup
    const timerName = e.target.name
    const value = Number(e.target.value)

    this.setState(prevState => ({
      [timerGroup]: {
        ...prevState[timerGroup],
        [timerName]: {
          ...prevState[timerGroup][timerName],
          [hourOrMinute]: value,
        },
      },
    }))
  }

  render() {
    const { project } = this.props
    const { surveyTimers, estimateTimers, paperworkTimers, installationTimers } = this.state

    return (
      <StyledTimers>
        <TimerGroup
          name="Survey"
          timerGroup="surveyTimers"
          timers={surveyTimers}
          editTimer={this.editTimer}
          saveTimer={this.saveTimer}
          updateTime={this.updateTime}
        />
        <hr />
        <TimerGroup
          name="Estimate"
          timerGroup="estimateTimers"
          timers={estimateTimers}
          editTimer={this.editTimer}
          saveTimer={this.saveTimer}
          updateTime={this.updateTime}
        />
        <hr />
        <TimerGroup
          name="Paperwork"
          timerGroup="paperworkTimers"
          timers={paperworkTimers}
          editTimer={this.editTimer}
          saveTimer={this.saveTimer}
          updateTime={this.updateTime}
        />
        <hr />
        <TimerGroup
          name="Installation"
          timerGroup="installationTimers"
          timers={installationTimers}
          editTimer={this.editTimer}
          saveTimer={this.saveTimer}
          updateTime={this.updateTime}
        />
      </StyledTimers>
    )
  }
}

const StyledTimers = styled.div`
  hr {
    outline: none;
    border: 0.5px solid ${colors.grey6};
  }
`

export default Timers
