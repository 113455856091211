import React, { Fragment } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'
import { format } from 'date-fns'

import Modal from './../../sharedComponents/Modal'
import Icon from './../../sharedComponents/Icon'
import { itemsFetchData } from '../../../redux/modules/projects'

const StyledProjectCustomerModal = styled.div`
  .section {
    padding: 1em 0;
    font-size: 0.875em;
    border-bottom: 1px solid ${colors.grey5};
  }

  .customer {
    padding: 0 0 1em;
  }

  .misc {
    padding: 1em 0 0;
    border-bottom: none;
  }

  .head {
    margin-bottom: 0.5em;
    .above {
      img {
        border-radius: 50%;
      }

      .top-head {
        color: ${colors.grey2};
        font-size: 0.9em;
        margin-bottom: -4px;
      }
    }

    .title {
      font-size: 1.5em;
      margin-bottom: 0;
    }

    .title-2 {
      font-size: 1.25em;
      margin-bottom: 0;
    }

    .mini-head {
      font-size: 0.9em;
      color: ${colors.grey2};

      &.below {
        display: block;
        margin-left: 0;
      }
    }
  }

  .info {
    b {
      font-weight: 600;
    }
  }

  .green {
    display: inline-block;
    color: ${colors.primary};
  }

  .created-at {
    margin-bottom: 0.5em;
  }
`

const ProjectCustomerModal = props => {
  const { modalInfo, isQuote } = props
  const { customer, project } = modalInfo
  const projectVersions = (project && project.versions) || []

  // the quote modal will be different from the Project/Customer Project Modal
  if (modalInfo.isQuote) {
    return (
      <StyledProjectCustomerModal>
        <Modal width={420} close={props.closeModal} centered={false}>
          <div className="close">
            <Icon icon="close" handleClick={props.closeModal} />
          </div>

          <Fragment>
            <div className="section first customer">
              <div className="head">
                <div className="created-at">
                  Created {format(modalInfo.item.createdAt, 'MM/DD/YY @ h:mma')}
                </div>
                <div className="title">{modalInfo.item.full_name}</div>
              </div>

              <div className="infos">
                <a href={`mailto:${modalInfo.item.email}`} className="info">
                  {modalInfo.item.email}
                </a>
                <br />
                <a href={`tel:${modalInfo.item.phone}`} className="info">
                  {modalInfo.item.phone}
                </a>
              </div>
            </div>

            <div className="section address">
              <div className="street">{modalInfo.item.address}</div>
              <div className="citystatezip">
                {modalInfo.item.city}, {modalInfo.item.state} {modalInfo.item.zip}
              </div>
            </div>
          </Fragment>

          <div className="section misc">
            <div className="infos">
              {modalInfo.item.estimated_footage && (
                <div className="info">
                  Estimated footage{' '}
                  <b>
                    {modalInfo.item.estimated_footage.length > 0
                      ? modalInfo.item.estimated_footage
                      : '-'}
                  </b>
                </div>
              )}
              {modalInfo.item.desired_height && (
                <div className="info">
                  Desired height{' '}
                  <b>
                    {modalInfo.item.desired_height.length > 0 ? modalInfo.item.desired_height : '-'}
                  </b>
                </div>
              )}
              {modalInfo.item.interested_in && (
                <div className="info">
                  Customer interested in
                  <br />
                  <b>
                    {modalInfo.item.interested_in.length > 0 ? modalInfo.item.interested_in : '-'}
                  </b>
                </div>
              )}
              {modalInfo.item.additional_project_notes && (
                <div className="info">
                  Additional notes <br />
                  <b>
                    {modalInfo.item.additional_project_notes.length > 0
                      ? modalInfo.item.additional_project_notes
                      : '-'}
                  </b>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </StyledProjectCustomerModal>
    )
  } else {
    return (
      <StyledProjectCustomerModal>
        <Modal width={420} close={props.closeModal} centered={false}>
          <div className="close">
            <Icon icon="close" handleClick={props.closeModal} />
          </div>

          {customer && (
            <Fragment>
              <div className="section first customer">
                <div className="head">
                  <div className="above">
                    <img
                      src={customer.profileImage ? customer.profileImage : '//placehold.it/40x40'}
                      alt={customer.displayName}
                    />
                  </div>
                  <div className="title">{customer.displayName}</div>
                  <span className="mini-head">({customer.projects.length} projects)</span>
                </div>

                <div className="infos">
                  <a href={`mailto:${customer.email}`} className="info">
                    {customer.email}
                  </a>
                  <br />
                  <a href={`tel:${customer.phone}`} className="info">
                    {customer.phone}
                  </a>
                </div>
              </div>

              <div className="section address">
                <div className="street">{project.additionalClientInfo.address}</div>
                <div className="citystatezip">
                  {project.additionalClientInfo.city}, {project.additionalClientInfo.state}{' '}
                  {project.additionalClientInfo.zip}
                </div>
              </div>
            </Fragment>
          )}

          <div className="section project">
            <div className="head">
              <div className="above">
                <div className="top-head">{project.archived ? 'archived' : ''}</div>
              </div>
              <div className="title-2">{project.name}</div>
              <div className="mini-head below">({project.slug})</div>
            </div>
            <div className="infos">
              <div className="info">
                {projectVersions.length + 1 === 1
                  ? '1 version'
                  : `${projectVersions.length + 1} versions`}
              </div>
              {project.survey.event && project.survey.event.start && project.survey.event.end && (
                <Fragment>
                  <div className="info">
                    {project.survey.status !== 'Scheduled' && project.survey.status !== 'To Do'
                      ? 'Survey was'
                      : 'Survey takes place'}{' '}
                    on <b>{format(project.survey.event.start, 'MM-DD-YY')}</b>{' '}
                    <b>{format(project.survey.event.start, 'h:mma')}</b> to{' '}
                    <b>{format(project.survey.event.end, 'h:mma')}</b>
                  </div>
                  {project.scheduledBy && (
                    <div className="info">
                      Project was scheduled by {project.scheduledBy === 'Customer' && 'the'}{' '}
                      <div className="green">{project.scheduledBy}</div>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>

          <div className="section misc">
            <div className="infos">
              {modalInfo.info.estimatedFootage && (
                <div className="info">
                  Estimated footage{' '}
                  <b>
                    {modalInfo.info.estimatedFootage.length > 0
                      ? modalInfo.info.estimatedFootage
                      : '-'}
                  </b>
                </div>
              )}
              {modalInfo.info.desiredHeight && (
                <div className="info">
                  Desired height{' '}
                  <b>
                    {modalInfo.info.desiredHeight.length > 0 ? modalInfo.info.desiredHeight : '-'}
                  </b>
                </div>
              )}
              {modalInfo.info.notes && (
                <div className="info">
                  Additional notes <br />
                  <b>{modalInfo.info.notes.length > 0 ? modalInfo.info.notes : '-'}</b>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </StyledProjectCustomerModal>
    )
  }
}

export default ProjectCustomerModal
