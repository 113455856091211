import React from 'react'
import Step from './../../sharedComponents/step'
import Sidebar from './Sidebar'

const InstallationSidebar = ({
  sidebarStateObj,
  stepStatus,
  stepPointer,
  currentStep,
  // updateCurrentStep,
}) => {
  return (
    <Sidebar>
      <div className="inner">
        <Step
          status={stepStatus(11, sidebarStateObj)}
          title="Schedule Install"
          num={1}
          isActive={stepPointer === 11}
          isCurrent={currentStep === 11}
        />
        <Step
          status={stepStatus(12, sidebarStateObj)}
          title="Payment"
          num={2}
          isActive={stepPointer === 12}
          isCurrent={currentStep === 12}
        />
        <Step
          status={stepStatus(13, sidebarStateObj)}
          title="Install Prep"
          num={3}
          isActive={stepPointer === 13}
          isCurrent={currentStep === 13}
        />
        <Step
          status={stepStatus(14, sidebarStateObj)}
          title="Install In Progress"
          num={4}
          isActive={stepPointer === 14}
          isCurrent={currentStep === 14}
        />
        <Step
          status={stepStatus(15, sidebarStateObj)}
          title="Install Review"
          num={5}
          isActive={stepPointer === 15}
          isCurrent={currentStep === 15}
        />
        <Step
          status={stepStatus(16, sidebarStateObj)}
          title="Permit Inspection"
          num={6}
          isActive={stepPointer === 16}
          isCurrent={currentStep === 16}
        />
        <Step
          status={stepStatus(17, sidebarStateObj)}
          title="Final Payment"
          num={7}
          isActive={stepPointer === 17}
          isCurrent={currentStep === 17}
        />
        <Step
          status={stepStatus(18, sidebarStateObj)}
          title="Complete"
          num={8}
          isActive={stepPointer === 18}
          isCurrent={currentStep === 18}
        />
      </div>
    </Sidebar>
  )
}

export default InstallationSidebar
