import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'
import { blobToDataURL } from './../../../helpers/images'
import { openLinkInNewTab, isImage } from './../../../utils'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Sector from './../../sharedComponents/Sector'
import Loader from './../../sharedComponents/Loader'
import Icon from './../../sharedComponents/Icon'
import SecondaryButton from './../../sharedComponents/SecondaryButton'
import Button from './../../sharedComponents/Button'
import RemarkForm from './RemarkForm'
import AttachmentList from './../../sharedComponents/AttachmentList'

class PermitInspectionFail extends Component {
  state = {
    remark: '',
    error: '',
  }

  submitRemark = remark => {
    if (remark.length < 2) {
      this.setState(prevState => ({ error: 'Remark must at least 2 characters long.' }))
      return false
    }
    this.props.addInspectionRemark(this.props.project.installation._id, {
      text: remark,
      date: new Date(),
    })
  }

  addFile = e => {
    const file = e.target.files ? e.target.files[0] : null
    if (!file) return false
    blobToDataURL(file, dataURL => {
      this.props.addInspectionFile(this.props.project._id, this.props.project.installation._id, {
        name: file.name,
        filepath: dataURL,
        date: new Date(),
      })
    })
  }

  render() {
    console.log('INSPECTION FILE LOADING?', this.props.inspectionFileLoading)
    return (
      <StyledPermitInspectionFail>
        <Header title="Correct any issues with this installation." />
        <Container>
          <Sector title="Remarks">
            <div className="sector-inner">
              <div className="bold">Record any feedback or comments from the inspection.</div>
              <div className="remarks-list">
                {this.props.inspectionRemarks.map(remark => {
                  return (
                    <div key={remark._id} className="remark">
                      <div className="inner">{remark.text}</div>
                    </div>
                  )
                })}
              </div>
              <RemarkForm submitRemark={this.submitRemark} />
              {this.state.error && <div className="error">{this.state.error}</div>}
            </div>
          </Sector>
          <Sector title="Attachments">
            <div className="sector-inner">
              <AttachmentList attachments={this.props.inspectionFiles} />

              {this.props.inspectionFileLoading && (
                <div className="attachment-loading">
                  <Loader />
                </div>
              )}

              {!this.props.inspectionFileLoading && (
                <SecondaryButton tag="label">
                  <Icon icon="upload" color="grey3" />
                  <span>Upload a File</span>
                  <input type="file" name="addInspectionFile" onChange={this.addFile} />
                </SecondaryButton>
              )}
            </div>
          </Sector>

          <div className="save-btn">
            <Button
              text="Save"
              variant="lg"
              handleClick={() =>
                this.props.updatePostOps(this.props.project.installation._id, {
                  inspectionStatus: 'failRevert',
                })
              }
            />
          </div>
        </Container>
      </StyledPermitInspectionFail>
    )
  }
}

// PermitInspectionFail will be wrapped in StyledPermitInspection, these styles are no longer needed
const StyledPermitInspectionFail = styled.div``

export default PermitInspectionFail
