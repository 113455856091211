import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

const MediaCard = ({
  title,
  description,
  portrait,
  alt,
  children,
  ...props
}) => {
  const StyledCard = styled.div`
    display: flex;
    padding: 20px;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 ${rgba('#000', 0.2)};

    .portrait {
      border-radius: 50%;
      margin-right: 20px;
      max-width: 95px;
      width: 100%;
      height: 100%;
    }

    .title {
      margin-bottom: 0.75rem;
    }

    .description {
      margin-bottom: 0;
    }
  `

  return (
    <StyledCard>
      {portrait && <img className="portrait" src={portrait} alt={alt} />}
      <div className="content">
        {title && <h3 className="title">{title}</h3>}
        {description && <p className="description">{description}</p>}

        {children ? children : ''}
      </div>
    </StyledCard>
  )
}

export default MediaCard
