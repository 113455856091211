import React from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'
import shortid from 'shortid'

import Icon from './../sharedComponents/Icon'

const AttachmentList = props => {
  const attachments = props.attachments
  const hideMessage = props.hideMessage || false

  return (
    <StyledAttachmentList>
      {!!!attachments.length && !hideMessage ? (
        <div className="weak">There are no attachments.</div>
      ) : (
        attachments.map(file => {
          return (
            <div key={file.id || shortid.generate()} className="attachment">
              <a className="inner" href={file.filepath}>
                <div className="download">
                  <Icon icon="download" />
                </div>
                <div className="text">{file.name}</div>
              </a>
            </div>
          )
        })
      )}
    </StyledAttachmentList>
  )
}

const StyledAttachmentList = styled.div`
  margin: 0 0 2em;

  .weak {
    color: ${colors.grey2};
  }

  .remark,
  .attachment {
    border-bottom: 1px solid ${colors.grey6};
    padding: 1em 0;

    .inner {
      position: relative;
      display: flex;
      max-width: 40.125em;
      padding-left: 0.5em;
      width: 100%;
      .download {
        top: 1px;
        margin-right: 1em;
      }
    }

    &:first-child {
      border-top: 1px solid ${colors.grey6};
    }
    &:last-child {
      /* border-bottom: 0; */
    }
  }

  /* margin-top: 2em; */
  .attachment {
    font-size: 0.875em;
    cursor: pointer;
    &:hover {
      background-color: ${colors.grey7};
    }
  }
`

export default AttachmentList
