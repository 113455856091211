import {
  loading as _loading,
  requestError as _requestError,
  updateFile as _updateFile,
  removeFile as _removeFile,
  addFile as _addFile,
  updateStep as _updateStep,
  fetchPaperwork as _fetchPaperwork,
  updatePaperwork as _updatePaperwork,
  hoaEmailSending as _hoaEmailSending,
  hoaEmailError as _hoaEmailError,
  hoaEmailSent as _hoaEmailSent,
} from './actions'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'
import { dataURLToBlob } from './../../../helpers/images'
import shortid from 'shortid'
import { isImage } from './../../../utils'

export const updateFile = (projectId, paperworkId, fileid, updates, whichArr) => {
  // for now just dispatch the action, no server comm needed
  return async dispatch => {
    let toSend
    // update UI immediately if there is no file being uploaded or if the file being uploaded is an image
    try {
      if (
        (!updates.filepath && !updates.filename) ||
        (updates.filepath && updates.filename && isImage(updates.filepath))
      ) {
        dispatch(_updateFile(fileid, updates, whichArr))
      }

      // push info into formData if a file is being uploaded, otherwise just use obj
      if (updates.filepath && updates.filepath.length > 0) {
        toSend = new FormData()
        toSend.append('projectId', projectId)
        toSend.append('fileId', fileid)
        toSend.append('file', dataURLToBlob(updates.filepath), updates.filename)
        toSend.append('whichArr', whichArr)
        toSend.append('type', 'upload')
        if (updates.status !== undefined) {
          toSend.append('status', updates.status)
        }
        if (updates.revisionNotes !== undefined) {
          toSend.append('revisionNotes', updates.revisionNotes)
        }
      } else if (updates.status) {
        toSend = {
          fileId: fileid,
          ...updates,
          whichArr,
          type: 'update',
          ...(updates.revisionNotes && { emailToSend: 'revision' }),
        }
      }

      const response = await axios.patch(
        `${addProductionUrl()}/api/paperworks/${paperworkId}`,
        toSend,
        {
          headers: {
            enctype: 'multipart/form-data',
          },
        }
      )

      // update filepath if it is NOT an image after the upload has taken place
      if (updates.filepath && updates.filename && !isImage(updates.filepath)) {
        const fileIdx = response.data[whichArr].findIndex(file => file.id === fileid)
        dispatch(
          _updateFile(
            fileid,
            {
              filename: response.data[whichArr][fileIdx].filename,
              filepath: response.data[whichArr][fileIdx].filepath,
            },
            whichArr
          )
        )
      }

      return true
    } catch (err) {
      dispatch(_requestError(err))
    }
  }
}

export const removeFile = (paperworkId, fileid, whichArr) => {
  // for now just dispatch the action, no server comm needed
  return async dispatch => {
    try {
      dispatch(_removeFile(fileid, whichArr))

      await axios.patch(`${addProductionUrl()}/api/paperworks/${paperworkId}`, {
        fileId: fileid,
        whichArr,
        type: 'remove',
      })
    } catch (err) {
      dispatch(_requestError(err))
    }
  }
}

export const addFile = (paperworkId, name, type, responsible = 'customer') => {
  // for now just dispatch the action, no server comm needed
  return async dispatch => {
    try {
      const fileId = shortid.generate()
      dispatch(_addFile(fileId, name, type, responsible))
      await axios.patch(`${addProductionUrl()}/api/paperworks/${paperworkId}`, {
        name,
        which: type,
        type: 'add',
        fileId: fileId,
        responsible,
      })
    } catch (err) {
      dispatch(_requestError(err))
    }
  }
}

export const updateStep = (paperworkId, which, newStep, emailToSend = null, projectId = null) => {
  // for now just dispatch the action, no server comm needed
  return async dispatch => {
    // format string to be used for logging
    const loggerStr = `${which}.${newStep}`

    try {
      dispatch(_updateStep(which, newStep))
      await axios.patch(`${addProductionUrl()}/api/paperworks/${paperworkId}`, {
        [which]: newStep,
        ...(emailToSend && { emailToSend }),
        projectId,
        from: loggerStr,
      })
    } catch (err) {
      dispatch(_requestError(err))
    }
  }
}

export const fetchPaperwork = paperworkId => {
  return async dispatch => {
    try {
      dispatch(_loading(true))
      const response = await axios.get(`${addProductionUrl()}/api/paperworks/${paperworkId}`)
      dispatch(_fetchPaperwork(response.data))
      dispatch(_loading(false))
    } catch (err) {
      dispatch(_requestError(err))
    }
  }
}

export const updatePaperwork = (paperworkId, updates) => {
  return async dispatch => {
    try {
      dispatch(_updatePaperwork(paperworkId, updates))
      await axios.patch(`${addProductionUrl()}/api/paperworks/${paperworkId}`, updates)
      return true
    } catch (err) {
      dispatch(_requestError(err))
    }
  }
}

export const sendHoaEmail = (paperworkId, hoaEmail) => {
  return async dispatch => {
    try {
      dispatch(_hoaEmailSending(true))

      const response = await axios.post(
        `${addProductionUrl()}/api/paperworks/${paperworkId}/send-hoa`,
        { hoaEmail }
      )
      dispatch(_hoaEmailSent())
      dispatch(_hoaEmailSending(false))
      return true
    } catch (err) {
      console.log('there was an error', err)
      dispatch(_hoaEmailError('Something went wrong.'))
      dispatch(_hoaEmailSending(false))
      return false
    }
  }
}
