import React, { Fragment } from 'react'
import { format, isValid } from 'date-fns'
import { createSkeletonElement } from '@trainline/react-skeletor'

import * as Scheduler from './../styles'

const Slot = props => {
  const displayedDt = (props.event.rawDt / 60).toFixed(2)

  return (
    <Scheduler.Slot
      key={props.event.id}
      onClick={() => {
        if (!props.event.fakeTime) {
          props.selectTime(props.event)
        }
      }}
      selected={props.selectedTime && props.event.id === props.selectedTime.id}
      isFake={props.event.fakeTime}
    >
      {!props.isCustomer && (
        <div className="surveyor">Surveyor – {props.event.surveyor.displayName}</div>
      )}
      <div className="title">
        {props.event && props.event.start && props.event.start.dateTime && !props.event.fakeTime ? (
          <>
            {format(props.event.start.dateTime, 'h:mma')} -{' '}
            {format(props.event.end.dateTime, 'h:mma')}
          </>
        ) : (
          <div className="same-as-background">
            {format(props.event.start.dateTime, 'h:mma')} -{' '}
            {format(props.event.end.dateTime, 'h:mma')}
          </div>
        )}
      </div>

      {!props.isCustomer && <span className="eta">ETA – {displayedDt || 0} mins</span>}
    </Scheduler.Slot>
  )
}

export default Slot
