import React, { Component } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import axios from 'axios'

import Loader from './../../sharedComponents/Loader'
import Message from './../../sharedComponents/Message'
import ForgotPasswordForm from './../../auth/ForgotPasswordForm'
import ResetPasswordForm from './../../auth/ResetPasswordForm'
import { addProductionUrl } from '../../../helpers/constants'
import MainContent from './../../sharedComponents/layouts/MainContent'
import keyring from './images/keyring.png'
import lock from './images/lostpassword-lock.png'

const StyledContainer = styled.div`
  max-width: 335px;
  margin: 0 auto;
  padding-top: 100px;

  > .media {
    display: block;
    width: auto;
    max-height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 65px;
  }
`
class ForgotPassword extends Component {
  state = {
    user: null,
    loading: false,
    sentEmail: false,
    error: null,
  }

  checkResetToken = () => {
    let resetPasswordToken = qs.parse(this.props.location.search.slice(1))['token'] || null

    if (resetPasswordToken) {
      axios
        .get(`${addProductionUrl()}/api/reset-password/${resetPasswordToken}`)
        .then(res => {
          this.setState({
            user: res.data,
          })
        })
        .catch(error => {
          this.setState({
            error: error.response.data.message,
          })
        })
    }
  }

  resetPassword = password => {
    let resetPasswordToken = qs.parse(this.props.location.search.slice(1))['token']

    this.setState({
      loading: true,
    })

    if (resetPasswordToken) {
      axios
        .post(`${addProductionUrl()}/api/reset-password/${resetPasswordToken}`, {
          password,
        })
        .then(res => {
          this.setState({
            loading: false,
            sentEmail: true,
            error: false,
            user: res.data,
          })
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: error.response.data.message,
          })
        })
    }
  }

  requestPasswordReset = email => {
    this.setState({
      loading: true,
    })

    axios
      .post(`${addProductionUrl()}/api/reset-password`, { email })
      .then(res => {
        this.setState({
          loading: false,
          sentEmail: true,
          error: false,
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error.response.data.message,
        })
      })
  }

  componentDidMount() {
    this.checkResetToken()
  }

  render() {
    const { loading, error, sentEmail, user } = this.state

    if (user) {
      return (
        <MainContent>
          <StyledContainer>
            <img className="media" src={lock} alt="opened lock" />
            <h3>You're ready to reset!</h3>
            <p>Choose a new password for your OFS account.</p>
            {sentEmail ? (
              <Message type="success" message="Your password has been reset." />
            ) : (
              <ResetPasswordForm resetPassword={this.resetPassword} loading={loading} />
            )}

            {error && <Message type="error" message={error} />}

            {loading ? <Loader /> : ''}
          </StyledContainer>
        </MainContent>
      )
    }

    return (
      <MainContent>
        <StyledContainer>
          <img className="media" src={keyring} alt="key ring" />
          <h3>Forgot your password? No problem.</h3>
          <p>Enter your email address and we'll send you a link to reset your password.</p>

          {sentEmail ? (
            <Message type="success" message="Please check your email." />
          ) : (
            <ForgotPasswordForm
              requestPasswordReset={this.requestPasswordReset}
              loading={loading}
            />
          )}

          {error && <Message type="error" message={error} />}

          {loading ? <Loader /> : ''}
        </StyledContainer>
      </MainContent>
    )
  }
}

export default ForgotPassword
