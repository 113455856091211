import axios from 'axios'

const modifiedAxiosHeaders = {
  transformRequest: [
    (data, headers) => {
      delete headers.common.Authorization
      return data
    },
  ],
}

function encodeString(str, spaceBefore = false) {
  str = str.trim()
  str = spaceBefore ? ` ${str}` : str
  return str.replace(/ /g, '+')
}

function massageData(responseObj) {
  return responseObj.data.results[0].geometry.location
}

export async function coordsFromAddress(addr, city, state) {
  try {
    addr = encodeString(addr, false)
    city = encodeString(city, true)
    state = encodeString(state, true)

    const apiStr = `https://maps.googleapis.com/maps/api/geocode/json?address=${addr},${city},${state}&key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }`

    const response = await axios.get(apiStr, modifiedAxiosHeaders)

    return massageData(response)
  } catch (err) {
    throw err
  }
}

export async function isAddressValid(addr, city, state, zip) {
  try {
    const address = `${addr}, ${city}, ${state} ${zip}`.trim().replace(/, USA$/, '')
    addr = encodeString(addr, false)
    city = encodeString(city, true)
    state = encodeString(state, true)
    zip = encodeString(zip, true)

    const apiStr = `https://maps.googleapis.com/maps/api/geocode/json?address=${addr},${city},${state},${zip},&key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }`

    const response = await axios.get(apiStr, modifiedAxiosHeaders)

    if (response.data.status === 'OK') {
      const result = response.data.results[0]
      // If the result is a partial match, something in the address wasn't valid
      // Usually this means that the address was invalid and the state got used by default
      const partialMatch = result.partial_match

      if (partialMatch) {
        return false
      } else {
        // Remove USA from suggested result
        const formattedResult = result.formatted_address.replace(/, USA$/, '')

        // Formatted address matches user input
        if (address === formattedResult) {
          // Completely valid address
          return 'valid'
        } else {
          // If it wasn't a partial match maybe the address is close to being correct
          return formattedResult
        }
      }
    } else {
      throw 'Could not auto validate address. Please check address by other means, and resubmit form.'
    }
  } catch (err) {
    throw err
  }
}
