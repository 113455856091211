import React, { PureComponent, Fragment } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { groupBy, chain, isEmpty } from 'lodash'
import isRole from './../../../../helpers/roles'
import { colors, breakpoints } from './../../../../helpers/foundation'
import { addProductionUrl } from './../../../../helpers/constants'
import { withViewerContext } from './../../../../contexts/ViewerContext'

import Modal from './../../../sharedComponents/Modal'
import Field from './../../../sharedComponents/Field'
import NotPermitted from './../../../routeUtils/NotPermitted'
import { ViewerConsumer } from './../../../../contexts/ViewerContext'
import Viewer from './../../../sharedComponents/Viewer'
import { BackgroundHeader } from './../../../sharedComponents/Header-NEW'
import { StickyFooter } from './../../../sharedComponents/Footer'
import Button from './../../../sharedComponents/Button-NEW'
import ReviewSales from './components/ReviewSales'
import ReviewOverview from './components/ReviewOverview'

class EmployeeSurveyReview extends PureComponent {
  state = {
    reviseModalOpen: false,
    reviseModalNotes: '',
    tabs: [{ name: 'Overview', active: true }, { name: 'Sales', active: false }],
    projectActionsLoading: false,
    projectActions: null,
  }

  componentDidMount = () => {
    this.setState(prevState => ({ projectActionsLoading: true }))
    axios
      .get(`${addProductionUrl()}/api/actions/${this.props.match.params.id}`)
      .then(response => {
        if (response.data) {
          const groupedActions = chain(response.data)
            .map((action, i) => {
              const stage = action.type.split('_')[0].toLowerCase()
              return {
                stage,
                ...action,
              }
            })
            .groupBy('stage')
            .value()

          this.setState(prevState => ({
            projectActions: groupedActions,
            projectActionsLoading: false,
          }))
        }
      })
      .catch(err => {
        console.log('got err', err)
        this.setState(prevState => ({
          projectActionsLoading: false,
        }))
      })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => ({ [name]: value }))
  }

  handleClick = action => {
    const { reviseModalNotes } = this.state
    const { project } = this.props
    this.props.employeeMarkSurvey(project, action, action === 'To Revise' ? reviseModalNotes : null)
  }

  closeAndClearData = () => {
    this.setState({ reviseModalOpen: false, reviseModalNotes: '' })
  }

  changeTab = name => {
    const { tabs } = this.state

    // dont do anything if they click a tab they are currently on
    const currentTab = tabs.filter(tab => tab.active)
    if (name === currentTab.name) {
      return false
    }

    this.setState(prevState => ({
      tabs: prevState.tabs.map(tab => {
        if (tab.name === name) {
          return {
            ...tab,
            active: true,
          }
        } else {
          return {
            ...tab,
            active: false,
          }
        }
      }),
    }))
  }

  render() {
    const {
      reviseModalOpen,
      reviseModalNotes,
      tabs,
      projectActionsLoading,
      projectActions,
    } = this.state
    const { user, project } = this.props
    const { survey } = project

    const activeTab = tabs.find(tab => tab.active).name || ''

    // show not permitted page if the user is not allowed to view this route
    if (!(isRole(user).admin || isRole(user).surveyManager)) {
      return (
        <NotPermitted history={this.props.history} toText="Surveys" toUrl="/projects/surveys" />
      )
    } else {
      return (
        <Fragment>
          <ViewerConsumer>
            {/* sets the viewer for images being viewed from the history sidebar */}
            {({ currentImage, unsetImage }) => {
              if (currentImage) {
                return <Viewer currentImage={currentImage} unsetImage={unsetImage} />
              }
            }}
          </ViewerConsumer>
          <StyledEmployeeSurveyReview>
            <BackgroundHeader
              title="Review the survey for estimation"
              tabs={tabs}
              tabStyle="dark"
              width="1600"
              tabsHandleClick={this.changeTab}
            />
            {reviseModalOpen && (
              <Modal
                width={420}
                close={this.closeAndClearData}
                open={reviseModalOpen}
                centered={false}
              >
                <h3 className="title">Tell us why?</h3>
                <Field>
                  <label htmlFor="reviseModalNotes">Notes</label>
                  <textarea
                    name="reviseModalNotes"
                    value={reviseModalNotes}
                    onChange={this.handleChange}
                  />
                </Field>
                <Button
                  text="Submit"
                  fluid={true}
                  variant="dynamic"
                  handleClick={e => this.handleClick('To Revise')}
                />
              </Modal>
            )}

            {activeTab === 'Sales' && (
              <ReviewSales survey={survey} setImage={this.props.context.setImage} />
            )}
            {activeTab === 'Overview' && (
              <ReviewOverview
                project={project}
                survey={survey}
                projectActions={projectActions}
                projectActionsLoading={projectActionsLoading}
              />
            )}
          </StyledEmployeeSurveyReview>
          <StickyFooter width="1600">
            <div className="inner split">
              <div className="right list split">
                {/* <Button
                  variant="dynamic danger"
                  text="Decline Survey"
                  handleClick={e => this.setState({ reviseModalOpen: true })}
                />
                <Button
                  variant="dynamic"
                  text="Approve Survey"
                  handleClick={e => this.handleClick('Completed')}
                /> */}
              </div>
              <div className="left list split">
                <Button
                  variant="dynamic danger"
                  text="Decline Survey"
                  handleClick={e => this.setState({ reviseModalOpen: true })}
                />
                <Button
                  variant="dynamic"
                  text="Approve Survey"
                  handleClick={e => this.handleClick('Completed')}
                />
              </div>
            </div>
          </StickyFooter>
        </Fragment>
      )
    }
  }
}

export default withViewerContext(EmployeeSurveyReview)

const StyledEmployeeSurveyReview = styled.div`
  margin-bottom: 122px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    margin-bottom: 200px;
  }

  .bordered-section {
    margin: 1em 0 4em;
    border-top: 1px solid ${colors.lightGrey};
    border-bottom: 1px solid ${colors.lightGrey};
    padding: 4em 0;

    &.no-bottom {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .split {
      display: flex;
      width: 100%;
      > div {
        width: 50%;
        &:first-child {
          padding-right: 3em;
        }
      }
    }
  }

  .section .content {
    padding: 0 0.25em;
  }

  .light,
  .answer {
    font-weight: 600;
    color: ${colors.grey2};
  }

  .question {
    font-weight: 600;
    margin-bottom: 1em;
  }

  .qa {
    margin-bottom: 1.875em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .head {
    margin-bottom: 1.25rem;
    .detail-name {
      font-weight: 600;
      margin-left: 0.5em;
    }
  }
`
