import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { colors } from '../../helpers/foundation'

const DateCard = ({ day, date, from, to, tag, ...props }) => {
  const StyledCard = styled.div`
    display: block;
    padding: 20px;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 ${rgba('#000', 0.2)};

    .day {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .date {
      font-size: 24px;
      color: ${colors.primary};
    }

    .header {
      margin-bottom: 20px;
    }

    .desc {
      color: ${colors.grey2};
      font-size: 14px;
    }

    .time {
      color: ${colors.grey3};
    }
  `

  return (
    <StyledCard>
      <div className="header">
        <div className="day">{day}</div>
        <div className="date">{date}</div>
      </div>

      <div className="desc">
        From <span className="time">{from}</span> to
        <span className="time"> {to}</span>
      </div>
    </StyledCard>
  )
}

export default DateCard
