import React, { Component } from 'react'
import styled from 'styled-components'

const Group = styled.div`
  display: inline-flex;
  align-items: baseline;
  flex-flow: row;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 1em;
  width: 100%;
  ${props => {
    if (props.split) {
      return `
        width: 100%;
        margin: 0 -0.5em;
      `
    }
  }}
`

class RadioGroup extends Component {
  render() {
    return <Group split={this.props.split}>{this.props.children}</Group>
  }
}

export default RadioGroup
