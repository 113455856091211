import styled from 'styled-components'
import { em } from 'polished'
import { colors } from '../../../../helpers/foundation'

const Card = styled.div`
  width: 18.75em;
  margin: 0 0.5em 0.5em;
  border-radius: 4px;
  color: ${colors.grey3};
  padding: 1em;
  position: relative;

  ${props => {
    if (props.status === 'success') {
      return `border: 1px solid ${colors.success};`
    } else if (props.status === 'danger') {
      return `border: 1px solid ${colors.danger};`
    } else {
      return `border: 1px solid ${colors.grey6};`
    }
  }};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: ${em('20px')};
    margin-bottom: 0.5em;

    ${props => {
      if (props.status === 'success') {
        return `color: ${colors.success};`
      } else {
        return `color: ${colors.grey3};`
      }
    }};
  }

  .info {
    margin-bottom: 0.75em;
    cursor: pointer;

    .icon {
      margin-right: 0.5em;
      cursor: pointer;
    }
  }

  .meta {
    font-size: ${em('14px')};
  }

  .message {
    color: ${colors.danger};
    font-size: ${em('14px')};
    margin-bottom: 0;
    margin-top: 1em;
  }
`

export default Card
