import React from 'react'
import { injectGlobal } from 'styled-components'
import { em, darken } from 'polished'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import normalize from 'normalize.css'
import configureStore from './redux/configureStore'
import registerServiceWorker, { unregister } from './registerServiceWorker'
import App from './App'
import { colors, navSize } from './helpers/foundation'
import { updateServiceWorker } from './redux/modules/serviceWorker'

const store = configureStore()

// for showing the service worker updates
// const onUpdate = () => {
//   store.dispatch(updateServiceWorker())
// }

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
// registerServiceWorker(onUpdate)
// remove the serviceworker
navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
})
unregister()

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept()
}

// if env is production, override default console.log behavior to not log anything
if (process.env.NODE_ENV === 'production') {
  console.log = function() {}
}

injectGlobal`
  ${normalize}

  /* Global styles */
  @import url("https://use.typekit.net/clo2rsc.css");

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  :root {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  body {
    font-family: "acumin-pro", sans-serif;
    font-size: 16px;
    color: #343A40;
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    width: 100%;
  }

  a {
    color: ${colors.grey3};
    text-decoration: none;      
    transition: color .15s ease-in-out;

    &:hover, &:focus, &:active {
      color: ${colors.primary};
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }

  fieldset {
    padding: 0;
    border: none;
    margin-left: 0;
  }

  textarea {
    resize: none;
    min-height: 10em;
  }

  label {
    color: ${colors.grey2};
    font-size: ${em('14px')};
    font-weight: 600;
    display: block;
    margin-bottom: 0.25em;
  }

  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='text'],
  select,
  textarea {
    background-color: ${colors.white};
    border: 1px solid ${props => (props.invalid ? colors.danger : colors.grey6)};
    color: ${colors.base} !important;
    border-radius: 4px;
    box-shadow: none;
    font-size: ${em('14px')};
    display: block;
    padding: 1em ${em('14px')};
    line-height: 1.5;
    outline: none;
    transition: 0.1s border-color ease-in-out;
    width: 100%;
    -webkit-appearance: none;

    &:focus {
      border-color: ${props => (props.invalid ? darken(0.1, colors.danger) : colors.grey2)};
    }

    &:disabled {
      background-color: #fafafa;
      &:hover {
        cursor: not-allowed;
      }
    }

    ::placeholder {
      color: ${colors.grey6};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.15;
  }

  h1,h2,h3,h4,h5,h6,hgroup,
  ul,ol,dd,
  p,figure,
  pre,table,fieldset,hr {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .small {
    font-weight: 600;
    font-size: 14px;
  }

  ::-webkit-input-placeholder { 
    color: ${colors.grey};
  }

  ::-moz-placeholder { 
    color: ${colors.grey};
  }

  :-ms-input-placeholder {
    color: ${colors.grey};
  }

  :-moz-placeholder {
    color: ${colors.grey};
  }

  /* tools */

  .mbm {
    margin-bottom: ${em('20px')};
  }

  .mbl {
    margin-bottom: ${em('40px')};
  }

  .tar {
    text-align: right;
  }

  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }

  body {
    top: 0px !important;
    min-height: calc(100% - ${navSize.large}) !important;
  }
  .goog-tooltip {
    display: none !important;
  }
  .goog-tooltip:hover {
    display: none !important;
  }
  .goog-text-highlight {
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
  }
`
