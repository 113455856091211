import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from '../../../helpers/foundation'

import ResizeableSector from './../../sharedComponents/ResizeableSector'
import StyledTable from './../../sharedComponents/StyledTable'
import TableRow from './shared/TableRow'
import Icon from './../../sharedComponents/Icon'

class ListView extends Component {
  state = {
    members: [],
    currentSort: '',
  }

  componentDidMount() {
    this.setState({
      members: this.props.members,
    })
  }

  componentDidUpdate = prevProps => {
    if (prevProps.modalOpen && !this.props.modalOpen) {
      this.setState(prevState => ({ members: this.props.members }))
    }
  }

  sortByColumn = column => {
    // sort the members in array based on team passed in
    // if team is equal to the current team, reverse the list
    this.setState(prevState => {
      // will add a negative if it is the same column
      const currentSort = prevState.currentSort === column ? `-${column.currentSort}` : column
      let sortedMembers

      // a-z sort
      if (prevState.currentSort !== column) {
        sortedMembers = prevState.members.sort((a, b) => {
          let first, second

          // for teams, need to check the first item ( for now )
          if (column === 'teamName') {
            first = a.teams[0].name.toLowerCase()
            second = b.teams[0].name.toLowerCase()
          } else if (column === 'teamRole') {
            first = a.teams[0].role.toLowerCase()
            second = b.teams[0].role.toLowerCase()
          } else {
            first = a[column].toLowerCase()
            second = b[column].toLowerCase()
          }

          if (first > second) {
            return 1
          } else {
            return -1
          }
        })
        // z-a sort
      } else {
        sortedMembers = prevState.members.sort((a, b) => {
          let first, second

          if (column === 'teamName') {
            first = a.teams[0].name.toLowerCase()
            second = b.teams[0].name.toLowerCase()
          } else if (column === 'teamRole') {
            first = a.teams[0].role.toLowerCase()
            second = b.teams[0].role.toLowerCase()
          } else {
            first = a[column].toLowerCase()
            second = b[column].toLowerCase()
          }

          if (first > second) {
            return -1
          } else {
            return 1
          }
        })
      }

      return {
        currentSort,
        members: sortedMembers,
      }
    })
  }

  render() {
    const { members } = this.state
    const { optionsClick } = this.props

    return (
      <StyledListView>
        <ResizeableSector
          size="lg"
          title="All Users"
          smallTitle={`${members.length} Members`}
          color="white"
          bordered={true}
          borderColor="primary"
        >
          <div className="rs-wrapper">
            <StyledTable>
              <thead>
                <tr>
                  <th className="displayname-th" onClick={() => this.sortByColumn('displayName')}>
                    <span>Name</span> <Icon icon="upDown" />
                  </th>
                  <th className="title-th" onClick={() => this.sortByColumn('title')}>
                    <span>Title</span> <Icon icon="upDown" />
                  </th>
                  <th className="teamname-th" onClick={() => this.sortByColumn('teamName')}>
                    <span>Team</span> <Icon icon="upDown" />
                  </th>
                  <th className="teamrole-th" onClick={() => this.sortByColumn('teamRole')}>
                    <span>Team Role</span> <Icon icon="upDown" />
                  </th>
                  <th className="calendar-th">
                    <span>Calendars</span>
                  </th>
                  <th className="options-th" />
                </tr>
              </thead>
              <tbody>
                {this.state.members.map(member => {
                  return (
                    <TableRow
                      key={member._id}
                      profileImage={member.profileImage}
                      displayName={member.displayName}
                      calendar={member.googleCalendar}
                      title={member.title}
                      teams={member.teams}
                      optionsClick={() => optionsClick(member._id)}
                      listView={true}
                    />
                  )
                })}
              </tbody>
            </StyledTable>
          </div>
        </ResizeableSector>
      </StyledListView>
    )
  }
}

const StyledListView = styled.div`
  .displayname-th {
    width: 30%;
  }
  .title-th {
    width: 20%;
  }
  .teamrole-th {
    width: 15%;
  }
  .teamname-th {
    width: 15%;
  }
  .calendar-th {
    width: 15%;
  }
  .options-th {
    width: 5%;
  }
`

export default ListView
