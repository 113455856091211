import { connect } from 'react-redux'
import Installation from './../components/installation/'
import { withRouter } from 'react-router-dom'
import {
  updateInstallation,
  updateNestedInstallation,
  addPhotoComment,
  updatePostOps,
  addInspectionRemark,
  addInspectionFile,
  fetchInstallation,
} from './../redux/modules/installation/operations'

const mapStateToProps = state => {
  return {
    loading: state.installation.install.loading,
    error: state.installation.install.error,
    currentStep: state.installation.install.currentStep,
    installationStatus: state.installation.install.installationStatus,
    // schedule installation
    installationDate: state.installation.install.installationDate,
    waterDate: state.installation.install.waterDate,
    electricDate: state.installation.install.electricDate,
    gasDate: state.installation.install.gasDate,
    // install prep
    parts: state.installation.install.parts,
    allPartsLoaded: state.installation.install.allPartsLoaded,
    // in progress
    photos: state.installation.install.photos,
    // post-ops
    inspectionDate: state.installation.postOps.inspectionDate,
    inspectionStatus: state.installation.postOps.inspectionStatus,
    inspectionFileLoading: state.installation.postOps.inspectionFileLoading,
    inspectionRemarks: state.installation.postOps.inspectionRemarks,
    inspectionFiles: state.installation.postOps.inspectionFiles,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateInstallation: (installationId, updates, projectId, isCustomer) =>
      dispatch(updateInstallation(installationId, updates, projectId, isCustomer)),
    updateNestedInstallation: (projectId, installationId, field, itemId, updates) =>
      dispatch(updateNestedInstallation(projectId, installationId, field, itemId, updates)),
    addPhotoComment: (installationId, direction, photoId, updates) =>
      dispatch(addPhotoComment(installationId, direction, photoId, updates)),
    updatePostOps: (installationId, updates) => dispatch(updatePostOps(installationId, updates)),
    addInspectionRemark: (installationId, remark) =>
      dispatch(addInspectionRemark(installationId, remark)),
    addInspectionFile: (projectId, installationId, fileObj) =>
      dispatch(addInspectionFile(projectId, installationId, fileObj)),
    fetchInstallation: installationId => dispatch(fetchInstallation(installationId)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Installation)
)
