import React from 'react'
import styled from 'styled-components'
import { breakpoints } from './../../helpers/foundation'

const StyledNotice = styled.div`
  padding: 1em;
  color: #343a40;
  background-color: #f7f8f9;
  border: ${props => (props.border ? props.border : '1px solid #94D82D')};
  border-radius: 4px;
  line-height: 24px;
  margin-bottom: ${props => (props.noMarginBottom ? '0' : '1.75em')};

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 0.875em;
  }

  .notice-inner {
    max-width: 46.375em;
  }
`

const Notice = props => {
  return (
    <StyledNotice border={props.border} noMarginBottom={props.noMarginBottom}>
      <div className="notice-inner">{props.text ? props.text : props.children}</div>
    </StyledNotice>
  )
}

export default Notice
