import React from 'react'

const Select = ({ name, value, options, handleChange }) => {
  return (
    <select name={name} id={name} value={value} onChange={e => handleChange(e)}>
      {options.map((item, i) => {
        return (
          <option value={item} key={i}>
            {item}
          </option>
        )
      })}
    </select>
  )
}

export default Select
