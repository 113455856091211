import React, { Component } from 'react'
import styled from 'styled-components'
import { format, addDays } from 'date-fns'

import Header from './../../../sharedComponents/Header'
import Container from './../../../sharedComponents/Container'
import Notice from './../../../sharedComponents/Notice'
import Field from './../../../sharedComponents/Field'
import Button from './../../../sharedComponents/Button'
import SidebarLayout from './../../../sharedComponents/layouts/SidebarLayout'
import History from './../../../sharedComponents/History'

class EmployeeEstimateExpired extends Component {
  state = {
    newExpiration: '',
    newDate: null,
  }
  updateField = e => {
    const { name, value } = e.target
    this.setState(prevState => ({ [name]: value, newDate: addDays(new Date(), value) }))
  }
  extendExpiration = () => {
    const { newExpiration, newDate } = this.state
    const { project } = this.props

    if (!newExpiration || !newDate) return false

    this.setState(prevState => ({ newExpiration: '', newDate: null }))
    this.props.estimateRenew(project, newExpiration, newDate).then(success => {
      this.props.history.push('/projects/estimates')
    })
  }
  render() {
    const { newExpiration, newDate } = this.state
    const { project, user } = this.props
    const { estimate } = project
    return (
      <SidebarLayout
        history={<History project={project} survey={project.survey} estimate={project.estimate} />}
        statusComponent={
          <StyledEmployeeEstimateExpired>
            <Header title="This estimate is expired." />
            <Container>
              <Notice text="You have the priviledges to extend the expiration." />
              <div className="select-container">
                <Field>
                  <label htmlFor="newExpiration">New expiration</label>
                  <select
                    name="newExpiration"
                    id="newExpiration"
                    value={newExpiration}
                    onChange={this.updateField}
                  >
                    <option value="" />
                    <option value="15">15 Days</option>
                    <option value="30">30 Days</option>
                    <option value="60">60 Days</option>
                    <option value="90">90 Days</option>
                    <option value="120">120 Days</option>
                  </select>
                </Field>

                <div className="new-date">
                  {newDate ? `Will expire on ${format(newDate, 'MM/DD/YY [at] hh:mm a')}` : ' '}
                </div>
              </div>
              <Button
                text="Confirm"
                variant={newExpiration ? 'nm' : 'nm disabled'}
                handleClick={newExpiration ? () => this.extendExpiration() : null}
              />
            </Container>
          </StyledEmployeeEstimateExpired>
        }
      />
    )
  }
}

export default EmployeeEstimateExpired

const StyledEmployeeEstimateExpired = styled.div`
  .select-container {
    display: flex;
    width: 100%;
    max-width: 32em;
  }

  .new-date {
    position: relative;
    top: 26px;
    margin-left: 1em;
  }
`
