import { connect } from 'react-redux'
import Routes from '../components/routes'
import {
  login,
  logoutUser,
  checkAuthStatus,
  updateUser,
  userUpdated,
} from '../redux/modules/auth'

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    loggedIn: state.auth.loggedIn,
    token: state.auth.token,
    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    logoutUser: () => dispatch(logoutUser()),
    checkAuthStatus: () => dispatch(checkAuthStatus()),
    updateUser: (uid, data) => dispatch(updateUser(uid, data)),
    userUpdated: data => dispatch(userUpdated(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)
