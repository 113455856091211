import styled from 'styled-components'
import { triangle } from 'polished'
import { colors } from './../../helpers/foundation'

const Fab = styled.div`
  > .reveal {
    border: 1px solid ${colors.grey};
    background-color: ${colors.white};
    border-radius: 4px;
    visibility: ${props => (props.open ? 'visible' : 'hidden')};
    opacity: ${props => (props.open ? 1 : 0)};
    padding: 1em;
    position: fixed;
    bottom: 120px;
    right: 1em;
    width: 300px;
    transform: translateY(${props => (props.open ? '0px' : '10px')});
    transition: 0.15s ease-in-out;
    transition-delay: 0.1;
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 25px;

      ${triangle({
        pointingDirection: 'bottom',
        width: '20px',
        height: '10px',
        foregroundColor: colors.grey,
      })};
    }

    .message {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 1em;
    }

    .message.-error {
      color: ${colors.danger};
    }

    .message.-success {
      color: ${colors.success};
    }
  }

  > .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.grey4};
    transform-origin: 50% 50%;
    transform: scale(${props => (props.open ? 1 : 0.85)});
    border: none;
    outline: none;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    position: fixed;
    bottom: 40px;
    right: 1em;
    transition: 0.15s ease-in-out;
    z-index: 10;

    svg {
      cursor: pointer;
    }
  }
`

export default Fab
