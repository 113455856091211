import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colors } from '../../helpers/foundation'
import { em, hideVisually } from 'polished'

const SecondaryButton = ({ tag = 'button', variant, handleClick, icon = 'stroke', children }) => {
  const Button = styled(tag)`
    background-color: ${colors.white};
    border: 1px solid ${variant ? colors[variant] : colors.grey3};
    border-radius: 2px;
    color: ${variant ? colors[variant] : colors.grey3};
    cursor: pointer;
    display: inline-flex;
    padding: 0 0.5em;
    line-height: 2;
    font-size: ${em('11px')};
    font-weight: 400;
    user-select: none;
    transition: 0.15s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background-color: ${variant ? colors[variant] : colors.grey3};
      color: ${colors.white};

      path,
      circle,
      polyline,
      polygon {
        ${icon === 'fill' ? `fill: ${colors.white};` : `stroke: ${colors.white};`};
      }
    }

    > .icon {
      margin-right: 5px;
    }

    > input {
      ${hideVisually()};
    }
  `
  return <Button onClick={handleClick ? handleClick : null}>{children}</Button>
}

SecondaryButton.propTypes = {
  tag: PropTypes.oneOf(['a', 'label', 'button']),
  icon: PropTypes.string,
  variant: PropTypes.oneOf(['danger']),
  handleClick: PropTypes.func,
}

export default SecondaryButton
