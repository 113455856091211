import styled, { keyframes } from 'styled-components'
import { setLightness, hideVisually } from 'polished'
import posed, { PoseGroup } from 'react-pose'
import { breakpoints, colors } from './../../helpers/foundation'

const vars = {
  fixWeek: 1580,
}

export const Layout = styled.div`
  /* height: ${props => `calc(100% - ${props.user ? '84px' : '60px'})`}; */
  height: calc(100% - 84px);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakpoints.medium}px) {
    height: ${props => `calc(100% - ${props.user ? '111px' : '60px'})`};
  }
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.lightGrey};
  padding: 0 1em;

  &.no-slots {
    ${props => {
      if (props.isDayView) {
        return `
          padding-top: 4em;
        `
      }
    }}
  }

  .center {
    text-align: center;
  }

  .error-title {
    font-size: 1.25em;
    font-weight: 600;
  }

  .error-message {
    font-size: 1em;
    font-weight: 600;
    color: ${colors.grey2};
    margin-bottom: 1.5em;
  }
`

export const Content = styled.div`
  height: 100%;
  @media screen and (min-width: ${breakpoints.medium}px) {
    display: flex;
    flex: 1;
  }
`

export const MetaRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25em 1.5em;
  align-items: baseline;

  .month {
    font-weight: 700;
    font-size: 1.5em;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .actions {
    display: flex;
    margin-left: 2em;

    .divider {
      width: 1px;
      border-left: 1px solid ${colors.grey7};
      height: 1.5em;
      margin: 0 1.5em;
    }

    > div {
      font-size: 0.8em;
      color: ${colors.grey};
      cursor: pointer;
      > svg {
        cursor: pointer;
      }
    }

    .previous-week {
      &.disabled {
        cursor: default;
        color: ${colors.lightGrey};
      }
    }

    .next-week {
      &.disabled {
        cursor: default;
        color: ${colors.lightGrey};
      }
    }
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    display: block;
    padding: 0.5 1.5em;

    .month {
      text-align: center;
      margin-bottom: 0.5em;
    }

    .right {
      display: flex;
      justify-content: space-between;
    }
  }
`

export const Side = styled.aside`
  display: none;
  @media screen and (min-width: ${breakpoints.medium}px) {
    display: block;
    position: relative;
    flex: 0 0 16em;
    border-right: 1px solid ${colors.lightGrey};
    padding: ${props => (props.hasPaddingTop ? '1.25em 1.125em' : '0 1.125em 1.125em 1.125em')};

    .actions {
      position: absolute;
      bottom: 1.125em;
      left: 1.125em;
      right: 1.125em;
      width: inherit;
    }

    .info {
      margin: 1em 0 1em 0;
      font-weight: 600;
      font-size: 1em;
    }

    .custom-time {
      font-size: 0.875em;
      color: ${colors.primary};
      text-decoration: underline;
      cursor: pointer;
      &:hover {
      }
    }

    .person {
      font-size: 0.875em;
    }

    .address {
      font-size: 0.875em;
      color: ${colors.grey3};
      /* font-size: 0.75em; */
    }

    .help {
      margin-top: 2em;
      font-size: 0.875em;
      .head {
        font-weight: 600;
      }
    }
  }
`

export const Main = styled.div`
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  overflow-y: hidden;
  overflow-x: auto;
  flex: 1;

  .above {
    display: none;
  }

  .small-help {
    padding: 1em 1.5em;
    font-size: 0.875em;
    color: ${colors.grey};
  }

  /* YIKES: louis save me */

  @media screen and (min-width: ${breakpoints.medium}px) {
    &:last-child {
      > div {
        &:last-child {
          /* flex-basis: 100%; */
          /* height: 100%; */
          height: calc(100% - 76px);
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    .above {
      display: block;
      padding: 1.125em;
      padding-bottom: 0;
      > button {
        margin-bottom: 0;
        .icon {
          position: relative;
          top: 1px;
        }
      }
    }
  }
`

export const Week = styled.div`
  @media screen and (min-width: ${breakpoints.medium}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;

    position: relative;

    > div {
      position: relative;
      /* width: 100%; */
    }

    .head {
      text-align: center;
      padding: 1.5em 0;
      border-top: 1px solid ${colors.lightGrey};
      border-bottom: 1px solid ${colors.lightGrey};
      font-size: 1.125em;
      color: ${colors.grey2};
    }
  }

  .bottom-bar {
    display: none;
  }

  @media screen and (max-width: ${vars.fixWeek}px) {
    min-width: 82.8125em;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    min-width: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 6em;

    /* .head {
      padding: 1em 0;
    } */

    .head {
      display: flex;
      width: 100%;
      padding: 1.125em;
      justify-content: space-between;
      padding: 1em 1.5em;
      border-top: 1px solid ${colors.lightGrey};
      border-bottom: 1px solid ${colors.lightGrey};
      .change-day {
        display: block;
        color: ${colors.grey};
        cursor: pointer;
        > svg {
          cursor: pointer;
        }

        &.disabled {
          cursor: default;
          color: ${colors.lightGrey};
        }
      }
    }

    .bottom-bar {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: ${colors.grey5};
      padding: 1.125em;

      > button {
        margin-bottom: 0;
      }
    }
  }
`

export const Day = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  border-right: 1px solid ${colors.lightGrey};
  font-size: 0.875em;
  position: relative;
  flex: 1;

  overflow: hidden;

  &:last-child {
    border-right: none;
  }

  /* custom */
  @media screen and (max-width: 1440px) {
  }

  @media screen and (min-width: ${breakpoints.medium}px) {
    .head {
      .change-day {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    border-right: none;
    overflow: initial;
    .head {
      display: flex;
      width: 100%;
      padding: 1.125em;
      justify-content: space-between;
      padding: 1em 1.5em;
      border-top: 1px solid ${colors.lightGrey};
      border-bottom: 1px solid ${colors.lightGrey};
      .change-day {
        display: block;
        color: ${colors.grey};
        cursor: pointer;
        > svg {
          cursor: pointer;
        }
      }
    }
  }
`

const skeletonAnimation = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
`

// animations
const _Slots = posed.div({
  // enter: {
  //   opacity: 1,
  //   // x: 100,
  //   // x: props => (!props.initialLoad || !props.isDayView ? 0 : 100),
  //   x: 0,
  //   transition: {
  //     duration: 300,
  //     // delay: 1000,
  //   },
  // },
  // exit: {
  //   // opacity: props => (!props.initialLoad || !props.isDayView ? 1 : 0),
  //   // x: -100,
  //   x: props => (!props.initialLoad || !props.isDayView ? 0 : '100%'),
  //   transition: {
  //     duration: 1000,
  //     // delay: 1000,
  //   },
  //   // opacity: ({ isDayView }) => (isDayView ? 0 : 1),
  //   // x: ({ isDayView }) => (isDayView ? '-100%' : 0),
  // },
})

// export const Slots = styled(_Slots)`
export const Slots = styled.div`
  @media screen and (min-width: ${breakpoints.medium}px) {
    position: relative;
    flex: 1;
    flex-basis: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Scrollbars */
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 2px;
      /* background: ${colors.grey7}; */
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.grey};
      border-radius: 2px;
    }
  }

  .head {
    .change-day {
      display: none;
    }
  }

  ${props => {
    if (
      (props.isPast && props.isLoaded) ||
      (props.events && props.events.length < 1 && props.isLoaded)
    ) {
      return `
        background-color: ${colors.grey2};
        opacity: 0.1;
      `
    }
  }}

  @media screen and (max-width: ${breakpoints.medium}px) {
    padding: 0 1.5em;
  }
`

export const Slot = styled.div`
  cursor: pointer;
  font-weight: 600;
  background-color: ${props =>
    !props.selected ? `${setLightness(0.95, colors.purple)}` : colors.primary};
  color: ${props => (!props.selected ? colors.black : colors.white)};
  padding: 1em 1.5em;
  margin: 0.875em;
  border-radius: 4px;
  text-align: center;
  -webkit-transition: background-color 200ms ease-in-out;
  -ms-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;

  .title {
    margin-bottom: -0.1em;
    margin-top: 0.25em;
  }

  .surveyor,
  .eta {
    font-size: 0.75em;
  }

  .surveyor {
    color: ${props => (!props.selected ? colors.purple : colors.white)};
  }

  .eta {
  }

  ${props => {
    if (props.isFake) {
      return `
      color: #bdc3c7 !important;
      background-color: #bdc3c7 !important;
      border-color: #bdc3c7!important;
      animation-name: ${skeletonAnimation};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      .surveyor {
        color: #bdc3c7 !important;
        background-color: #bdc3c7 !important;
      }
      `
    }
  }}

  .same-as-background {
    color: transparent;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    overflow-x: hidden;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
`

export const Calendar = styled.div`
  /* @media screen and (min-width: ${breakpoints.large}px) { */
    background-color: ${colors.grey5};
    border: 1px solid ${colors.grey6};
    border-radius: 4px;
    padding: 0.5em;

    ${props => {
      if (props.forModal) {
        return `
        background-color: ${colors.white};
        border: none;
        border-radius: none;
        `
      }
    }}

    .meta {
      display: flex;
      justify-content: space-between;
      font-size: 0.875em;
      color: ${colors.grey2};
      margin: 0.5em 1em;
      margin-bottom: 1em;
      vertical-align: top;

      .prev,
      .next {
        color: ${colors.grey6};
        cursor: pointer;
        > svg {
          cursor: pointer;
        }
      }

      .prev {
        &.disabled {
          > svg {
            ${hideVisually()}
          }
        }
      }

      .curr {
        color: ${colors.grey3};
      }

      ${props => {
        if (props.forModal) {
          return `
            margin-bottom: 2em;
          `
        }
      }}
    }

    .months {
      display: flex;
      flex-wrap: wrap;
    }

    .month {
      position: relative;
      font-size: 0.875em;
      flex-basis: 25%;
      text-align: center;
      color: ${colors.grey2};

      &.disabled {
        color: ${colors.grey6};
      }

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      > .inner {
        display: flex;
        /* align text inside inner vertically and horizontally */
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0.5em;
        bottom: 0.5em;
        left: 0.5em;
        right: 0.5em;
        border-radius: 50%;
        vertical-align: center;
        cursor: pointer;
      }

      /* show circle around selected month  */
      &.selected {
        > .inner {
          background-color: ${colors.primary};
          color: ${colors.white};
        }
      }
    }
  /* } */
`
