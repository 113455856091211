export const LOADING = 'app/paperwork/LOADING'
export const ERROR = 'app/paperwork/ERROR'
export const UPDATE_FILE = 'app/paperwork/UPDATE_FILE'
export const REMOVE_FILE = 'app/paperwork/REMOVE_FILE'
export const ADD_FILE = 'app/paperwork/ADD_FILE'
export const UPDATE_STEP = 'app/paperwork/UPDATE_STEP'
export const FETCH_PAPERWORK = 'app/paperwork/FETCH_PAPERWORK'
export const UPDATE_PAPERWORK = 'app/paperwork/UPDATE_PAPERWORK'
export const HOA_EMAIL_SENDING = 'app/paperwork/HOA_EMAIL_SENDING'
export const HOA_EMAIL_ERROR = 'app/paperwork/HOA_EMAIL_ERROR'
export const HOA_EMAIL_SENT = 'app/paperwork/HOA_EMAIL_SENT'
