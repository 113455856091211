import { connect } from 'react-redux'
import Survey from './../components/survey/'
import {
  initSurvey,
  updateSurveyServer,
  resetMaterialAndChildren,
  surveyBlueprintCommentsAdd,
  surveyBlueprintCommentsUpdate,
  surveyBlueprintCommentsDelete,
  surveyError,
  uploadDirectionalPhoto,
  uploadDetailsPhoto,
  updateDetailsValue,
  updateDirectionalPhoto,
} from './../redux/modules/survey/'

import { duplicateProject } from './../redux/modules/projects/'

import { withRouter } from 'react-router-dom'

const mapStateToProps = state => {
  return {
    loading: state.survey.loading,
    error: state.survey.error,
    queuedPromises: state.survey.queuedPromises,
    _materials: state.survey._materials,
    _styles: state.survey._styles,
    _options: state.survey._options,
    survey: state.survey.survey,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initSurvey: projectId => dispatch(initSurvey(projectId)),
    updateSurveyServer: (surveyId, updates, updateProject, projectId) =>
      dispatch(updateSurveyServer(surveyId, updates, updateProject, projectId)),
    resetMaterialAndChildren: (surveyId, resetType, newMaterial) =>
      dispatch(resetMaterialAndChildren(surveyId, resetType, newMaterial)),
    surveyBlueprintCommentsAdd: (surveyId, comment) =>
      dispatch(surveyBlueprintCommentsAdd(surveyId, comment)),
    surveyBlueprintCommentsUpdate: (surveyId, comment) =>
      dispatch(surveyBlueprintCommentsUpdate(surveyId, comment)),
    surveyBlueprintCommentsDelete: (surveyId, comment) =>
      dispatch(surveyBlueprintCommentsDelete(surveyId, comment)),
    duplicateProject: projectId => dispatch(duplicateProject(projectId)),
    handleError: err => dispatch(surveyError(err)),
    uploadDirectionalPhoto: (surveyId, formData, stateData) =>
      dispatch(uploadDirectionalPhoto(surveyId, formData, stateData)),
    uploadDetailsPhoto: (surveyId, formData, stateData, which) =>
      dispatch(uploadDetailsPhoto(surveyId, formData, stateData, which)),
    updateDetailsValue: (surveyId, formData, stateData, which) =>
      dispatch(updateDetailsValue(surveyId, formData, stateData, which)),
    updateDirectionalPhoto: (surveyId, formData, stateData) =>
      dispatch(updateDirectionalPhoto(surveyId, formData, stateData)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Survey)
)
