import { connect } from 'react-redux'
import CustomerRouter from './../components/customer/CustomerRouter'
import {
  customerFetchData,
  customerHideTutorial,
  customerConfirmTime,
  updateProject,
  customerRequestEstimateRenewal,
  customerUpdateProjectStageStatus,
} from '../redux/modules/customer/'
import { estimateUpdateServer } from '../redux/modules/estimate/'
import { updateFile, fetchPaperwork } from '../redux/modules/paperwork/'
import { updateInstallation, submitFeedback } from '../redux/modules/installation/operations'
import { projectUpdate } from '../redux/modules/projects/'
import { requestProject } from './../redux/modules/project/operations'

const mapStateToProps = state => {
  return {
    loading: state.customer.loading,
    messages: state.customer.messages,
    customer: state.customer.customer,
    paperwork: state.paperwork.paperwork,
    paperworkHoaStatus: state.paperwork.hoaStatus,
    paperworkPermitsStatus: state.paperwork.permitsStatus,
    currentProject: state.project.project,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    customerFetchData: customerId => dispatch(customerFetchData(customerId)),
    customerHideTutorial: customerId => dispatch(customerHideTutorial(customerId)),
    customerUpdateProject: (projectId, updates) => dispatch(updateProject(projectId, updates)),
    customerConfirmTime: (projectId, surveyId) =>
      dispatch(customerConfirmTime(projectId, surveyId)),
    // load in this method from estimate module, so customer can update
    // the estimate when they are approving it / making payments
    estimateUpdateServer: (estimateId, updates, isCustomer) =>
      dispatch(estimateUpdateServer(estimateId, updates, isCustomer)),
    projectUpdate: (projectId, updates) => dispatch(projectUpdate(projectId, updates)),
    customerUpdateProjectStageStatus: (projectId, stage, status) =>
      dispatch(customerUpdateProjectStageStatus(projectId, stage, status)),
    customerRequestEstimateRenewal: project => dispatch(customerRequestEstimateRenewal(project)),
    // for paperwork
    customerUpdateFile: (projectId, paperworkId, fileid, updates, whichArr) =>
      dispatch(updateFile(projectId, paperworkId, fileid, updates, whichArr)),
    customerFetchPaperwork: paperworkId => dispatch(fetchPaperwork(paperworkId)),
    updateInstallation: (installationId, updates, projectId, isCustomer) =>
      dispatch(updateInstallation(installationId, updates, projectId, isCustomer)),
    submitFeedback: (projectId, feedback) => dispatch(submitFeedback(projectId, feedback)),
    requestProject: projectId => dispatch(requestProject(projectId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRouter)
