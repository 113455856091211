import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { em, hideVisually } from 'polished'
import { colors, breakpoints, viewportWidth, zIndexes } from './../../helpers/foundation'
import isRole from './../../helpers/roles'
import brandmark from './images/brandmark.svg'
import stackedBrandMark from './images/stacked-brandmark.png'
import {
  HomeIcon,
  ToolIcon,
  PeopleIcon,
  NotificationsIcon,
} from './../sharedComponents/StyledIcons'

import NavReveal from './navReveal'
import NavItem from './components/NavItem'
import NavAction from './components/NavAction'
import Icon from './../sharedComponents/Icon'
import NotificationsContainer from '../../containers/noticationsContainer'
import { toggleNotifications } from './../../redux/modules/notifications/actions'

const Header = styled.header`
  background-color: ${colors.grey4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.nav};

  @media screen and (max-width: 1600px) {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
  @media screen and (max-width: ${breakpoints.aboveTablet}px) {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .mark {
    display: inline-block;
    padding: 1em 1em 1em 0;

    > img {
      height: 30px;
    }
  }

  .notice {
    color: ${colors.primary};
    font-size: ${em('14px')};
    font-weight: 600;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .menu {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
  }

  .menu-item {
    color: #fff;
    position: relative;
  }

  .notification-li {
    position: relative;

    path,
    circle,
    polyline,
    polygon {
      stroke: none;
    }

    &:hover,
    &:focus,
    &.active {
      color: ${colors.primary};

      .icon {
        > * {
          color: ${colors.primary};
          font-size: 18px;
          margin-bottom: -8px;
        }
      }
    }
  }

  .notification-icon-container {
    color: ${colors.grey2};

    .icon {
      > * {
        color: ${colors.grey2};
        font-size: 18px;
        margin-bottom: -8px;
      }
    }
  }

  #google_translate_element {
    margin-left: 1.5em;
  }

  .goog-te-menu-value {
    @media screen and (max-width: ${breakpoints.medium}px) {
      > span:first-child {
        ${hideVisually()};
      }
    }
  }
`

class NavBar extends Component {
  _isMounted = false

  state = {
    mobile: viewportWidth >= breakpoints.large ? false : true,
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  handleResize = () => {
    if (this._isMounted) {
      const currentViewportWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      )

      this.setState({
        mobile: currentViewportWidth >= breakpoints.large ? false : true,
      })
    }
  }

  render() {
    const { user, logoutUser, loggedIn, currentProject } = this.props
    const { mobile } = this.state

    const projectsLink = () => {
      let url = '/projects'
      let text = 'Projects'

      if (currentProject) {
        if (currentProject.stage === 'survey') {
          url = '/projects/surveys'
          text = 'Surveys'
        } else if (currentProject.stage === 'estimate') {
          url = '/projects/estimates'
          text = 'Estimates'
        } else if (currentProject.stage === 'paperwork') {
          url = '/projects/paperwork'
          text = 'Paperwork'
        } else if (currentProject.stage === 'installation') {
          url = '/projects/installation'
          text = 'Installation'
        }
      }

      return (
        <li className="menu-item">
          <NavItem icon={<ToolIcon />} text={text} url={url} mobile={mobile} isProject={true} />
        </li>
      )
    }

    return (
      <Fragment>
        <Header>
          <nav className="menu">
            <ul className="list">
              <div className="left">
                <li className="menu-item">
                  <Link to="/" className="mark">
                    <p className="notice">Beta</p>
                    {mobile ? (
                      <img src={stackedBrandMark} alt="Osceola Fence Supply" />
                    ) : (
                      <img src={brandmark} alt="Osceola Fence Supply" />
                    )}
                  </Link>
                </li>
                <div className="menu-item">
                  <div id="google_translate_element" />
                </div>
              </div>
              {loggedIn && (
                <div className="right">
                  {user && user.role !== 'Customer' && !isRole(user).production ? (
                    <React.Fragment>
                      <li className="menu-item">
                        <NavItem
                          exact
                          icon={<HomeIcon />}
                          text="Dashboard"
                          url={'/'}
                          mobile={mobile}
                        />
                      </li>
                      {projectsLink()}
                      <li className="menu-item">
                        <NavItem
                          icon={<PeopleIcon />}
                          text="Teams"
                          url={'/teams'}
                          mobile={mobile}
                        />
                      </li>
                    </React.Fragment>
                  ) : (
                    <li className="menu-item">
                      <>
                        {!isRole(user).production && (
                          <NavItem icon={<HomeIcon />} text="Dashboard" url={'/'} mobile={mobile} />
                        )}
                      </>
                    </li>
                  )}
                  {isRole(user).admin && (
                    <li className="menu-item notification-li">
                      <Fragment>
                        <NavAction
                          isActive={this.props.notificationsOpen}
                          onClick={() =>
                            this.props.toggleNotifications(
                              this.props.notificationsOpen ? false : true
                            )
                          }
                        >
                          <div className="notification-icon-container">
                            <div className="icon">
                              <NotificationsIcon />
                            </div>
                          </div>
                        </NavAction>

                        <NotificationsContainer />
                      </Fragment>
                    </li>
                  )}
                  <li className="menu-item">
                    <NavReveal user={user} logoutUser={logoutUser} />
                  </li>
                </div>
              )}
            </ul>
          </nav>
        </Header>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.project,
    notificationsOpen: state.notifications.open,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleNotifications: bool => dispatch(toggleNotifications(bool)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavBar)
)
