export function getTotals(toAdd, taxRate = 0.07) {
  let total = 0,
    cashBefore,
    cashTax,
    cashTotal,
    processingFee = 0.03,
    creditBefore,
    creditTax,
    creditTotal

  toAdd.forEach(item => {
    // check here if item is number
    total += item
  })

  cashBefore = total
  cashTax = cashBefore * taxRate
  cashTotal = cashBefore + cashTax

  creditBefore = total * processingFee + total
  creditTax = creditBefore * taxRate
  creditTotal = creditBefore + creditTax

  return {
    cashBefore: cashBefore.toFixed(2),
    cashTax: cashTax.toFixed(2),
    cashTotal: cashTotal.toFixed(2),
    creditBefore: creditBefore.toFixed(2),
    creditTax: creditTax.toFixed(2),
    creditTotal: creditTotal.toFixed(2),
  }
}

/*
numi code:
taxRate = 0.07 = 0.07

base = 7613.70 = 7,613.7
cashTax = base * taxRate = 532.96
cashTotal = base + cashTax = 8,146.66

creditFee = 0.029 = 0.03
creditBase = base + (base * creditFee) = 7,834.5
creditTax = creditBase * taxRate = 548.41
creditTotal = creditBase + creditTax + 1 = 8,383.91
*/
