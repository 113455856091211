import React, { Component } from 'react'
import styled from 'styled-components'
import { colors, navSize, actionBarSize, controlsSize } from './../../../../helpers/foundation'
import houseImg from './../../assets/house.png'
import { toTitleCase, splitStringByCapital } from './../../../../utils'
import loadImage from 'blueimp-load-image'
import shortid from 'shortid'
import { format } from 'date-fns'

import Header from './../../../sharedComponents/Header'
import Container from './../../../sharedComponents/Container'
import Notice from './../../../sharedComponents/Notice'
import PictureBtn from './PictureBtn'
import PhotoBox from './../../../sharedComponents/PhotoBox'

class InProgress extends Component {
  state = {
    editing: false,
    activeDirection: null,
    currentPhoto: null,
    safeToUpdateStatus: true,
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => {
      return {
        [name]: value,
      }
    })
  }

  close = () => {
    this.setState(prevState => ({
      editing: false,
      activeDirection: null,
      currentPhoto: null,
    }))
  }

  editPhoto = photoKey => {
    const { photos } = this.props
    const photo = photos[photoKey]

    this.setState(prevState => ({
      editing: true,
      activeDirection: photoKey,
      currentPhoto: photo,
    }))
  }

  handleFile = e => {
    const file = e.target.files ? e.target.files[0] : null
    // console.log('curr photo!', this.state.currentPhoto)
    console.log('the curr photos status: ', this.props.photos[this.state.activeDirection].status)
    loadImage(
      file,
      image => {
        const imageUrl = image.toDataURL('image/jpeg')
        this.setState(prevState => ({ safeToUpdateStatus: false }))
        this.props
          .updateNestedInstallation(
            this.props.project._id,
            this.props.project.installation._id,
            ['photos', this.state.activeDirection],
            this.state.currentPhoto._id,
            {
              image: imageUrl,
              imageName: file.name,
              status: 'uploaded',
              ...(!this.props.photos[this.state.activeDirection].status && { status: 'uploaded' }),
            }
          )
          .then(success => {
            this.setState(prevState => ({ safeToUpdateStatus: true }))
          })
      },
      {
        maxWidth: 900,
        orientation: true,
      }
    )
  }

  markPhotoStatus = status => {
    const { currentPhoto } = this.state
    // console.log()
    if (status !== currentPhoto.status) {
      this.props
        .updateNestedInstallation(
          this.props.project._id,
          this.props.project.installation._id,
          ['photos', this.state.activeDirection],
          currentPhoto._id,
          { status: status }
        )
        .then(success => {
          this.props.addPhotoComment(
            this.props.project.installation._id,
            this.state.activeDirection,
            currentPhoto._id,
            {
              // add all info for new comment here
              _id: shortid.generate(),
              // user.userId,
              userId: this.props.user._id,
              // user.userName,
              userName: this.props.user.displayName,
              // user.profileImage,
              profileImage: this.props.user.profileImage,
              // text,
              text: `The photo has been marked as ${
                status === 'approved' ? 'Approved' : 'Needs Revision'
              } on ${format(new Date(), 'h:mma - ddd, MMM DD')}.`,
              status,
              date: new Date(),
            }
          )
        })
    }
  }

  render() {
    const { editing, currentPhoto, activeDirection } = this.state
    const { photos } = this.props

    const formattedActiveDirection = toTitleCase(
      splitStringByCapital(activeDirection ? activeDirection : '')
    )

    return (
      <StyledInProgress>
        <PhotoBox
          presentational={false}
          header={`${formattedActiveDirection} Side of the Installation`}
          imageUrl={currentPhoto ? photos[activeDirection].image : null}
          messages={currentPhoto ? photos[activeDirection].comments : []}
          photoStatus={currentPhoto ? photos[activeDirection].status : null}
          editing={editing}
          close={this.close}
          saveNotes={null}
          changePhoto={this.handleFile}
          updateNestedInstallation={this.props.updateNestedInstallation}
          handleChange={this.handleChange}
          // loading={loading}
          width="1072"
          type="chat"
          divider={true}
          noPadding={true}
          handleFile={this.handleFile}
          installationId={this.props.project.installation._id}
          activeDirection={this.state.activeDirection}
          currentPhotoId={this.state.currentPhotoId}
          addPhotoComment={this.props.addPhotoComment}
          photoId={currentPhoto ? photos[activeDirection]._id : null}
          user={this.props.user}
          markPhotoStatus={this.markPhotoStatus}
          safeToUpdateStatus={this.state.safeToUpdateStatus}
        />
        <Header title="Time for the installation!" />
        <div className="calc-container">
          <Container>
            <Notice
              text="Photograph as you complete phases of the installation."
              noMarginBottom={true}
            />
          </Container>
        </div>

        <PhotosContainer>
          <div className="direction-text top">Rear</div>
          <div className="direction-text left">Left</div>

          <div className="main">
            <div className="inner">
              <PictureBtn
                placement="rear-left"
                photo={photos.rearLeft}
                handleClick={e => this.editPhoto('rearLeft')}
              />
              <PictureBtn
                placement="rear-center"
                photo={photos.rearCenter}
                handleClick={e => this.editPhoto('rearCenter')}
              />
              <PictureBtn
                placement="rear-right"
                photo={photos.rearRight}
                handleClick={e => this.editPhoto('rearRight')}
              />

              <PictureBtn
                placement="center-left"
                photo={photos.centerLeft}
                handleClick={e => this.editPhoto('centerLeft')}
              />
              <img src={houseImg} alt="" className="center-img" />
              <PictureBtn
                placement="center-right"
                photo={photos.centerRight}
                handleClick={e => this.editPhoto('centerRight')}
              />

              <PictureBtn
                placement="front-left"
                photo={photos.frontLeft}
                handleClick={e => this.editPhoto('frontLeft')}
              />
              <PictureBtn
                placement="front-center"
                photo={photos.frontCenter}
                handleClick={e => this.editPhoto('frontCenter')}
              />
              <PictureBtn
                placement="front-right"
                photo={photos.frontRight}
                handleClick={e => this.editPhoto('frontRight')}
              />
            </div>
          </div>
          <div className="direction-text right">Right</div>
          <div className="direction-text bottom">Front</div>
        </PhotosContainer>
      </StyledInProgress>
    )
  }
}

export default InProgress

const PhotosContainer = styled.div`
  position: relative;
  border-top: 1px solid ${colors.lightGrey};
  height: calc(100vh - ${navSize.large} - ${actionBarSize.small} - 90px - 60px);
  width: 100%;
  margin: 0;
  /* padding: 1em; */

  .main {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .direction-text {
    font-size: 0.75em;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    &.top {
      position: absolute;
      top: 12px;
      width: 100%;
    }
    &.left {
      position: absolute;
      left: 12px;
      height: 100%;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      writing-mode: tb-rl;
    }
    &.right {
      position: absolute;
      right: 12px;
      height: 100%;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      writing-mode: tb-rl;
    }
    &.bottom {
      position: absolute;
      bottom: 12px;
      width: 100%;
    }
  }

  .center-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .picture-btn {
    position: absolute;
    &.rear-left {
      top: 30px;
      left: 30px;
    }
    &.rear-center {
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.rear-right {
      top: 30px;
      right: 30px;
    }
    &.center-left {
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }
    &.center-right {
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
    &.front-left {
      bottom: 30px;
      left: 30px;
    }
    &.front-center {
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.front-right {
      bottom: 30px;
      right: 30px;
    }
  }
`

const StyledInProgress = styled.div``
