// THIS IS FOR BASICALLY JUST REUSING THE CSS
import React from 'react'
import styled from 'styled-components'

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin-bottom: 0;

  .editable-td {
    width: 50px;
    color: #868e96;

    &:disabled {
      border: none;
      outline: none;
      background-color: transparent;
      cursor: text;
    }
  }

  .clickable {
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }

  th,
  td {
    padding: 1.5em;
    &:first-child,
    &:last-child {
    }
  }

  thead {
    th {
      padding: 1em;
      text-align: left;
    }
  }

  tbody {
    tr {
      padding: 1em;
      background-color: #f7f8f9;

      &:nth-child(even) {
        background-color: #f1f3f5;
      }
    }

    td {
      font-size: 13px;
      /* font-family: 'source-code-pro'; */
      color: #868e96;
    }

    &.striped-every-two {
      tr {
        background-color: #f1f3f5;
      }

      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: #f7f8f9;
      }
    }
  }

  .meta-actions {
    .delete,
    .confirm {
      position: relative;
      border-radius: 4px;
      text-align: center;
      /* right: 4px; */
      svg {
        margin-top: 4px;
      }
    }

    .delete {
      border: 1px solid #ff495c;
      padding: 0 10px;
    }

    .confirm {
      border: 1px solid #94d82d;
    }

    .small {
      position: relative;
      font-size: 1em;
      color: #ff495c;
      display: inline-block;
      bottom: 1px;
    }
  }
`

const StyledTable = props => {
  return <Container>{props.children}</Container>
}

export default StyledTable
