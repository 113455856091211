// to be used in api calls
export function addProductionUrl() {
  return process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_SERVER_URL : ''
}

export function addProductionUrlWithLocalhost() {
  return process.env.NODE_ENV !== 'development'
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:8081'
}

// to be used to send emails
export function chooseEmail(desired) {
  return process.env.NODE_ENV !== 'development' ? desired : 'matt@big.vision'
}

export function currentUrl() {
  let url
  const hostname = window && window.location && window.location.hostname
  if (hostname === 'ofs-app-beta.big.vision') {
    url = 'https://ofs-app-beta.big.vision'
  } else if (hostname === 'ofsapp.com') {
    url = `https://ofsapp.com`
  } else {
    url = 'http://localhost:3000'
  }
  return url
}
