import React, { Component } from 'react'
import styled from 'styled-components'
import { addDays } from 'date-fns'
import { colors } from './../../helpers/foundation'
import { getTotals } from './../../helpers/totals'

import Header from './../sharedComponents/Header'
import Message from './../sharedComponents/Message'
import Container from './../sharedComponents/Container'
import EstimateOverviewChunk from './shared/OverviewChunk'
import Button from './../sharedComponents/Button'
import Notice from './../sharedComponents/Notice'
import Checkbox from './../sharedComponents/Checkbox'

class EstimateOverview extends Component {
  state = {
    paymentTerms: '',
    expiration: '',
    agree: false,
    error: null,
  }

  componentDidMount() {
    this.setState({
      paymentTerms: this.props.estimate.paymentTerms || '25/50/25',
      expiration: this.props.estimate.expiration || '60',
    })
  }

  updateField = e => {
    const tarName = e.target.name,
      tarVal = e.target.value
    this.setState(prevState => {
      return {
        [tarName]: tarName === 'agree' ? !prevState.agree : tarVal,
      }
    })
  }

  submitOverview = () => {
    const { parts, labors, services } = this.props.estimate
    const { paymentTerms, expiration, agree } = this.state
    const expirationDate = addDays(new Date(), expiration)

    const totals = getTotals(
      [Number(parts.total), Number(labors.total), Number(services.total)],
      0.07
    )

    if (!agree || !paymentTerms || !expiration) {
      if (!paymentTerms || !expiration) {
        this.setState({
          error: 'Please make sure payment terms and estimate expiration are filled.',
        })
      } else {
        this.setState({
          error: "Please check the box confirming you've reviewed the estimate.",
        })
      }
    } else {
      let payments = null
      // decide what objs will be put into payments[]
      if (paymentTerms === '100') {
        payments = [
          {
            order: 0,
            paid: false,
            cashTotal: totals.cashTotal,
            creditTotal: totals.creditTotal,
            chargeId: null,
          },
        ]
      } else if (paymentTerms === '50/50') {
        payments = [
          {
            order: 0,
            paid: false,
            cashTotal: (totals.cashTotal / 2).toFixed(2),
            creditTotal: (totals.creditTotal / 2).toFixed(2),
            chargeId: null,
          },
          {
            order: 1,
            paid: false,
            cashTotal: (totals.cashTotal / 2).toFixed(2),
            creditTotal: (totals.creditTotal / 2).toFixed(2),
            chargeId: null,
          },
        ]
      } else if (paymentTerms === '25/50/25') {
        payments = [
          {
            order: 0,
            paid: false,
            cashTotal: (totals.cashTotal / 4).toFixed(2),
            creditTotal: (totals.creditTotal / 4).toFixed(2),
            chargeId: null,
          },
          {
            order: 1,
            paid: false,
            cashTotal: (totals.cashTotal / 2).toFixed(2),
            creditTotal: (totals.creditTotal / 2).toFixed(2),
            chargeId: null,
          },
          {
            order: 2,
            paid: false,
            cashTotal: (totals.cashTotal / 4).toFixed(2),
            creditTotal: (totals.creditTotal / 4).toFixed(2),
            chargeId: null,
          },
        ]
      }

      // console.log('huh wtffff', paymentTerms, payments)

      // console.log('the ids?')
      // console.log('estimate id', this.props.estimate._id)
      // console.log('proj id', this.props.project._id)

      this.props
        .estimateUpdateServer(this.props.estimate._id, {
          expiration,
          expirationDate,
          status: 'To Review',
          lastStatus: Date.now(),
          projectId: this.props.project._id,
          from: 'In Progress',
        })
        .then(res => {
          console.log('WHAT THEEEEEE', this.props.project._id)
          this.props.updateProject(this.props.project._id, {
            paymentTerms,
            payments,
          })
          this.props.history.push('/projects/estimates')
        })
    }
  }

  render() {
    const { estimate } = this.props
    const { paymentTerms, expiration, agree } = this.state
    const valid = agree && paymentTerms && expiration && estimate.officalBlueprint
    const stillNeedOfficalBlueprint =
      agree && paymentTerms && expiration && !estimate.officalBlueprint

    return (
      <StyledOverview>
        <Header title="Review the Estimate for accuracy." />
        <Container>
          {estimate.revisionNotes && <Notice text={estimate.revisionNotes} />}
          <EstimateOverviewChunk
            project={this.props.project}
            estimate={this.props.estimate}
            changeStepPointer={this.props.changeStepPointer}
            estimateUpdateServer={this.props.estimateUpdateServer}
          >
            <div className="selects">
              <div className="select-container">
                <div className="head">Payment Terms</div>
                <select
                  name="paymentTerms"
                  id="paymentTerms"
                  value={this.state.paymentTerms}
                  onChange={this.updateField}
                >
                  <option value="" />
                  <option value="25/50/25">25/50/25</option>
                  <option value="50/50">50/50</option>
                  <option value="100">100</option>
                </select>

                <div className="description">
                  <div className="desc-item">
                    <div className="plan">25/50/25</div>{' '}
                    <div className="plan-desc">
                      25% after Estimate approved, 50% before Installation, 25% after Installation.
                    </div>
                  </div>
                  <div className="desc-item">
                    <div className="plan">50/50</div>{' '}
                    <div className="plan-desc">
                      50% after Estimate approved, 50% before Installation.
                    </div>
                  </div>
                  <div className="desc-item">
                    <div className="plan">100</div>{' '}
                    <div className="plan-desc">100% after Estimate approved.</div>
                  </div>
                </div>
              </div>

              <div className="select-container expiration-container">
                <div className="head">Estimate Expiration</div>
                <select
                  name="expiration"
                  id="expiration"
                  value={this.state.expiration}
                  onChange={this.updateField}
                >
                  <option value="" />
                  <option value="30">30 Days</option>
                  <option value="60">60 Days</option>
                  <option value="90">90 Days</option>
                  <option value="120">120 Days</option>
                </select>
              </div>
            </div>

            <div className="agreement-container">
              <Checkbox
                name="agree"
                value="agree"
                checked={this.state.agree ? true : false}
                size="large"
                checkmark={true}
                text="I have completed this estimate thoroughly and to the best of my ability. I approve this estimate and it’s contents."
                handleChange={this.updateField}
              />
            </div>

            {this.state.error && <Message message={this.state.error} type="error" />}
            {stillNeedOfficalBlueprint && (
              <Message message="You must upload the offical Blueprint to continue." type="error" />
            )}

            <Button
              text="Submit Estimate"
              variant={`lg ${!valid && 'disabled'}`}
              handleClick={this.submitOverview}
            />
          </EstimateOverviewChunk>
        </Container>
      </StyledOverview>
    )
  }
}

const StyledOverview = styled.div`
  .selects {
    margin-bottom: 3em;
  }

  .select-container {
    /* display: inline-block; */
    vertical-align: top;
    &:first-child {
      margin-right: 4em;
    }

    select {
      width: 12em;
    }

    .description {
      font-size: 14px;
      color: ${colors.grey};
      margin-top: 1em;
      .desc-item {
        margin-bottom: -2px;
        .plan {
          color: ${colors.grey2};
          font-weight: 600;
          display: inline-flex;
        }
        .plan-desc {
          display: inline-flex;
        }
      }
    }
  }

  .expiration-container {
    margin-top: 2em;
  }

  .agreement-container {
    margin-bottom: 3em;
    max-width: 32em;
    width: 100%;
  }
`

export default EstimateOverview
