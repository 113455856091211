import {
  LOADING,
  ERROR,
  UPDATE_FILE,
  REMOVE_FILE,
  ADD_FILE,
  UPDATE_STEP,
  FETCH_PAPERWORK,
  UPDATE_PAPERWORK,
  HOA_EMAIL_SENDING,
  HOA_EMAIL_ERROR,
  HOA_EMAIL_SENT,
} from './types'

export const loading = bool => ({
  type: LOADING,
  payload: {
    loading: bool,
  },
})

export const requestError = message => ({
  type: ERROR,
  payload: {
    message,
  },
})

export const updateFile = (fileid, updates, whichArr) => ({
  type: UPDATE_FILE,
  payload: {
    fileid,
    updates,
    whichArr,
  },
})

export const removeFile = (fileid, whichArr) => ({
  type: REMOVE_FILE,
  payload: {
    fileid,
    whichArr,
  },
})

export const addFile = (fileId, name, type, responsible) => ({
  type: ADD_FILE,
  payload: {
    fileId,
    name,
    type,
    responsible,
  },
})

export const updateStep = (which, newStep) => ({
  type: UPDATE_STEP,
  payload: {
    which,
    newStep,
  },
})

export const fetchPaperwork = data => ({
  type: FETCH_PAPERWORK,
  payload: {
    data,
  },
})

export const updatePaperwork = (paperworkId, updates) => ({
  type: UPDATE_PAPERWORK,
  payload: {
    updates,
  },
})

export const hoaEmailSending = bool => ({
  type: HOA_EMAIL_SENDING,
  payload: {
    sending: bool,
  },
})

export const hoaEmailError = msg => ({
  type: HOA_EMAIL_ERROR,
  payload: {
    msg,
  },
})

export const hoaEmailSent = () => ({
  type: HOA_EMAIL_SENT,
  payload: {},
})
