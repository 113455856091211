import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { addProductionUrl } from './../../../helpers/constants'

import Title from './../../sharedComponents/Title'

class LoggedInConfirmation extends Component {
  componentDidMount = async () => {
    const { quote } = this.props
    this.props.setSubmitting(true)

    let response
    try {
      response = await axios.post(`${addProductionUrl()}/api/customers/create`, {
        email: this.props.user.email,
        quoteId: quote._id,
      })
      if (!response) throw 'Error creating project.'
    } catch (err) {
      this.setErrors([err])
      return false
    }

    const { user, project } = response.data

    this.props.scheduleTime(this.props.selectedTime, project, user)

    // set message
    this.props.setSubmitting(false)
    this.props.setMessage('Successfully scheduled time.')
    // clear pw field
    this.setState(prevState => ({ fields: { password: '' } }))

    // redirect after stall
    setTimeout(() => {
      this.props.history.push(`/project/${project._id}/${project.slug}`)
    }, 1500)
  }

  render() {
    return (
      <StyledLoggedInConfirmation>
        <Title text="Thank you. Scheduling your survey." tag="h3" size="24px" />
      </StyledLoggedInConfirmation>
    )
  }
}

const StyledLoggedInConfirmation = styled.div``

export default LoggedInConfirmation
