import React, { Component } from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { colors } from './../../../helpers/foundation'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'

import Loader from './../../sharedComponents/Loader'
import Button from './../../sharedComponents/Button'

function checkIfDateHasTime(date) {
  const minsAsNumber = Number(moment(date).format('HH.mm'))
  return minsAsNumber > 0
}

class CustomTimePanel extends Component {
  _isMounted = false

  state = {
    loading: false,
    error: false,
    selectedDate: null,
    requestingAvailability: false,
    result: null,
    surveyors: [],
    selectedOption: null, // for select
    ready: false,
  }

  componentDidMount = () => {
    this._isMounted = true
    this.props.getSurveyors().then(surveyors => {
      if (this._isMounted) {
        this.setState(prevState => ({ surveyors }))
      }
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      JSON.stringify(prevState.selectedDate) !== JSON.stringify(this.state.selectedDate) ||
      JSON.stringify(prevState.selectedOption) !== JSON.stringify(this.state.selectedOption)
    ) {
      if (checkIfDateHasTime(this.state.selectedDate)) {
        if (this.state.selectedOption) {
          this.setState(prevState => ({ ready: true }))
        }
      }
    }
  }

  // for calendar/time select
  handleInputChange = dateObj => {
    this.setState(prevState => ({
      selectedDate: dateObj,
    }))
  }

  // for react select
  handleChange = selectedOption => {
    this.setState(prevState => ({ selectedOption }))
  }

  scheduleTime = async e => {
    const { history, project, user } = this.props
    const { surveyors, selectedOption, selectedDate } = this.state
    const surveyor = surveyors.find(surveyor => surveyor._id === selectedOption.value)
    const dateTime = selectedDate._d

    this.setState(prevState => ({ loading: true }))

    try {
      await this.props.scheduleCustomTime(project, surveyor, dateTime, user)
      this.setState(prevState => ({ loading: false, error: false }))
      history.push('/projects/surveys')
    } catch (err) {
      console.log('err on the front', err)
      this.setState(prevState => ({ loading: false, error: true }))
    }
  }

  render() {
    const { toggleCustomTimeOpen } = this.props
    const {
      loading,
      error,
      selectedDate,
      requestingAvailability,
      surveyors,
      selectedOption,
      ready,
    } = this.state

    // console.log('custom time props', this.props)
    // console.log('custom time state', this.state)

    return (
      <StyledCustomTimePanel>
        <div className="meta-row">
          <div className="close" onClick={toggleCustomTimeOpen}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>

        <div className="content">
          <h3>Select a custom time</h3>

          <div className="picker">
            <DatePicker
              selected={selectedDate}
              onChange={date => this.handleInputChange(date)}
              minDate={moment()}
              inline
              showTimeSelect
            />
          </div>

          <div className="select">
            <Select
              value={selectedOption}
              onChange={this.handleChange}
              options={surveyors.map(surveyor => ({
                value: surveyor._id,
                label: surveyor.displayName,
              }))}
              isMulti={false}
              placeholder="Select a surveyor"
            />
          </div>

          {loading && <Loader />}

          <div className="schedule-btn">
            <Button
              text="Schedule time"
              variant={`lg ${ready ? 'primary' : 'disabled'}`}
              handleClick={() => {
                if (ready) {
                  this.scheduleTime()
                }
              }}
            />
          </div>

          {error && (
            <div className="error">
              This time is unavailable. This surveyor already is scheduled on this slot.
            </div>
          )}
        </div>
      </StyledCustomTimePanel>
    )
  }
}

const StyledCustomTimePanel = styled.div`
  .meta-row {
    margin-bottom: 1rem;
    .close {
      display: inline-flex;
      justify-content: flex-start;
      cursor: pointer;
      outline: none;
      user-select: none;
      font-size: 1.5em;
    }
  }

  .content {
    h3 {
      margin-bottom: 1.5rem;
    }
  }

  .picker {
    margin-bottom: 1.5em;
  }

  .select {
    width: 362.28px;
    margin-bottom: 1.5em;
  }

  .schedule-btn {
    margin-bottom: 1.5em;
  }

  .error {
    color: ${colors.danger};
  }

  /* datepicker overrides */
  .react-datepicker__time-list {
    padding: 0;
    width: 100%;
  }

  .react-datepicker__time-container {
    width: 120px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-right: 0;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 10px;
    font-size: 1em;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 130px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colors.primary};

    &:hover {
      background-color: ${colors.primary};
    }
  }
`

export default CustomTimePanel
