import React from 'react'
import { PoseGroup } from 'react-pose'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { em } from 'polished'
import { colors } from './../../helpers/foundation'

const skeletonAnimation = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
`

const StyledCard = styled.div`
  color: ${colors.grey3};
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: ${props => (props.pointer === true ? 'pointer' : null)};

  ${props => {
    if (props.fakeItem) {
      return `
        color: ${colors.white} !important;
        background-color: ${colors.white} !important;
        border-color: ${colors.white}!important;
        animation-name: ${skeletonAnimation};
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      `
    }
    if (props.variant === 'blend') {
      return `
        background-color: transparent;
      `
    }
    return `
      background-color: ${colors.white};
    `
  }}

  .header {
    background-color: ${colors.grey5};
    border: ${props => (props.variant === 'blend' ? 'none' : `1px solid ${colors.grey}`)};
    border-radius: ${props => (props.variant === 'blend' ? '0' : '4px 4px 0 0')};
    border-bottom: 0;
    padding: 0.25em 1em;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .item {
      margin-bottom: 0.25em;
      margin-right: 0.5em;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .content {
    padding: ${props => (props.compact ? '0.5em' : '1em')};
    border: ${props => (props.variant === 'blend' ? 'none' : `1px solid ${colors.grey}`)};
    border-radius: ${props => {
      if (props.variant === 'blend') return '0'
      if (props.compact) return '4px 4px 4px 4px'
      return '0 0 4px 4px'
    }};
    border-top: ${props => (props.compact ? null : 0)};

    ${props => {
      if (props.fakeItem) {
        return `
          &:hover {
            .title {
              color: ${colors.grey} !important;
            }
          }
        `
      }
    }}
  }

  .title {
    color: ${colors.grey3};
    font-size: ${props => (props.compact ? '0.875em' : '1em')};
    line-height: 1;
    font-weight: 600;
    margin-bottom: ${props => (props.compact ? '0.25em' : '0.5em')};

    ${props => {
      if (props.fakeItem) {
        return `
          display: inline-block;
          color: ${colors.grey};
          background-color: ${colors.grey};
        `
      }
    }}
  }

  .info {
    color: ${colors.grey3};
    font-size: ${em('12px')};
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${props => (props.compact ? '0.15em' : '0.5em')};
    opacity: 0.6;

    &.-danger {
      color: ${colors.danger};
    }

    ${props => {
      if (props.fakeItem) {
        return `
          opacity: 1;
          color: ${colors.grey};
          background-color: ${colors.grey};
        `
      }
    }};
  }

  .image {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  .icon {
    position: absolute;
    bottom: 0.75em;
    right: 0.75em;
  }

  .items {
    margin-bottom: -0.25em;
  }

  .menu {
    position: ${props => (props.compact ? 'absolute' : 'relative')};
    right: ${props => (props.compact ? '0' : '-1em')};
    top: ${props => (props.compact ? '0' : null)};

    ${props => {
      if (props.fakeItem) {
        return `
          display: none;
        `
      }
    }}
  }

  .dots {
    cursor: pointer;
    position: relative;
    padding: 0.75em;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .dot {
      background-color: ${colors.grey};
      display: inline-block;
      display: ${props => props.isSimple && 'none'};
      height: 4px;
      width: 4px;
      margin: 1px 0;
      border-radius: 50%;
    }

    display: ${props => props.isSimple && 'none'};
  }

  .footer {
    .countdown {
      float: right;
      clear: both;
    }

    &.flex-between {
      display: flex;
      justify-content: space-between;
    }

    .icon-container {
      .asterisk-icon {
        font-size: 10px;
        margin-right: 1px;
      }
    }
  }
`

const Card = ({
  items,
  variant,
  hasMenu,
  toggleMenu,
  menuIsOpen,
  menu,
  link,
  children,
  compact,
  pointer,
  handleClick,
  fakeItem,
}) => {
  return (
    <StyledCard
      className="card"
      variant={variant}
      compact={compact}
      pointer={pointer}
      fakeItem={fakeItem}
      {...(handleClick ? { onClick: e => handleClick(e) } : null)} // conditionally handle click
    >
      {!compact && (
        <div className="header">
          <div className="items">
            {items &&
              items.map((item, idx) => {
                return (
                  <img
                    key={idx}
                    className="item image"
                    src={item.profileImage ? item.profileImage : '//placehold.it/24x24'}
                    alt={item.displayName}
                  />
                )
              })}
          </div>
          {hasMenu && (
            <div className="menu">
              <PoseGroup>{menuIsOpen && menu}</PoseGroup>
              <div className="dots" onClick={e => toggleMenu(e)}>
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
          )}
        </div>
      )}
      {link ? (
        <React.Fragment>
          {compact && hasMenu && (
            <div className="menu">
              <PoseGroup>{menuIsOpen && menu}</PoseGroup>
              <div className="dots" onClick={e => toggleMenu(e)}>
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
          )}
          <Link to={link}>
            <div className="content">{children}</div>
          </Link>
        </React.Fragment>
      ) : (
        <div className="content">{children}</div>
      )}
    </StyledCard>
  )
}

Card.propTypes = {
  items: PropTypes.array,
  variant: PropTypes.string,
  hasMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  menu: PropTypes.element,
  link: PropTypes.string,
}

export default Card
