import moment from 'moment'

export const sortByStartTime = slots => {
  return slots.sort((a, b) => {
    return new Date(a.startTime) - new Date(b.startTime)
  })
}

export const getNestedValue = (props, object) =>
  props.reduce((prefix, val) => (prefix && prefix[val] ? prefix[val] : null), object)

export const isCurrentWeek = day => {
  return moment(day).isSame(new Date(), 'week')
}

export const convertTo24Hour = time => {
  let hours = parseInt(time.substr(0, 2))

  if (time.indexOf('am') !== -1 && hours === 12) {
    time = time.replace('12', '0')
  }
  if (time.indexOf('pm') !== -1 && hours < 12) {
    time = time.replace(hours, hours + 12)
  }

  let convertedTime = `${time.replace(/(am|pm)/, '')}`

  return convertedTime
}

export const convertToSeconds = time => {
  const timeString = time.split(':')
  const hours = timeString[0]
  const minutes = timeString[1]

  return hours * 60 * 60 + minutes * 60
}
