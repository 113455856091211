import React, { Component } from 'react'
import styled from 'styled-components'
import isEmpty from 'validator/lib/isEmpty'
import { hideVisually } from 'polished'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAddressValid } from '../../../helpers/google/geocoding'
import { colors, breakpoints } from '../../../helpers/foundation'

import Field from '../../sharedComponents/Field'
import Message from '../../sharedComponents/Message'
import Button from '../../sharedComponents/Button-NEW'
import Loader from '../../sharedComponents/Loader'
import { PlusIconCircle } from '../../sharedComponents/StyledIcons'

const initialFields = {
  fullname: '',
  email: '',
  // password: '',
  phone: '',
  address: '',
  city: '',
  state: 'FL',
  zip: '',
  notes: '',
  desiredHeight: '',
  estimatedFootage: '',
}

class EmployeeCreateProjectForm extends Component {
  state = {
    fields: initialFields,
    errors: {},
    valid: false,
    suggestedAddress: null,
    invalidAddress: null,
    addressValidationError: null,
    addressValidationLoading: false,
    addressValidationAttempted: false,
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    })

    if (name === 'address' || name === 'city' || name === 'zip') {
      // Detect changes to address fields to revalidate address
      this.setState({
        addressValidationAttempted: false,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { address, city, state, zip } = this.state.fields

    if (this.addressValidationLoading) return

    const createProject = () => {
      this.props.createProject(this.state.fields).then(({ slug, _id }) => {
        const projectUrl = `/project/${_id}/${slug}`

        this.setState({
          fields: {
            ...initialFields,
          },
          addressValidationLoading: false,
        })

        this.props.history.push(projectUrl)
      })
    }

    this.setState(
      {
        errors: this.validateFields(),
        invalidAddress: null,
      },
      () => {
        const errors = Object.keys(this.state.errors)
          .map(error => {
            return this.state.errors[error]
          })
          .filter(err => {
            return err
          })

        if (errors.length === 0) {
          this.setState({
            addressValidationLoading: true,
          })

          if (!this.state.addressValidationAttempted) {
            // If address was already suggested or validation is failing, allow them to continue
            isAddressValid(address, city, state, zip)
              .then(res => {
                if (res) {
                  if (res !== 'valid') {
                    this.setState({
                      suggestedAddress: res,
                      addressValidationAttempted: true,
                      addressValidationLoading: false,
                    })
                  } else {
                    createProject()
                  }
                } else {
                  this.setState({
                    invalidAddress: true,
                    addressValidationLoading: false,
                  })
                }
              })
              .catch(err => {
                this.setState({
                  addressValidationError: err,
                  addressValidationAttempted: true,
                  addressValidationLoading: false,
                })
              })
          } else {
            createProject()
          }
        }
      }
    )
  }

  validateFields = () => {
    const { fullname, email, phone, address, city, state, zip } = this.state.fields

    const errors = {
      fullname: isEmpty(fullname) || fullname.length < 4 ? true : false,
      // add regex to check email validity here
      email: isEmpty(email) || email.length < 4 ? true : false,
      // password: isEmpty(password) ? true : false,
      // passwordLength: password.length < 7 ? true : false,
      phone: isEmpty(phone) ? true : false,
      address: isEmpty(address) ? true : false,
      city: isEmpty(city) ? true : false,
      state: isEmpty(state) ? true : false,
      zip: isEmpty(zip) ? true : false,
    }

    return errors
  }

  applySuggestedAddress = e => {
    e.preventDefault()

    const suggestedAddress = this.state.suggestedAddress.split(',')

    this.setState({
      fields: {
        ...this.state.fields,
        address: suggestedAddress[0].trim(),
        city: suggestedAddress[1].trim(),
        state: suggestedAddress[2].split(' ')[1].trim(),
        zip: suggestedAddress[2].split(' ')[2].trim(),
      },
      suggestedAddress: null,
    })
  }

  render() {
    const {
      errors,
      suggestedAddress,
      invalidAddress,
      addressValidationError,
      addressValidationLoading,
    } = this.state
    const {
      fullname,
      email,
      // password,
      phone,
      address,
      city,
      state,
      zip,
      notes,
      desiredHeight,
      estimatedFootage,
    } = this.state.fields
    const { loading, message, toggleNewProjectPanel } = this.props

    return (
      <StyledEmployeeCreateProjectForm>
        <div className="meta-row">
          <div className="close" onClick={toggleNewProjectPanel}>
            <PlusIconCircle
              fontSize={32}
              color="white"
              width={64}
              height={64}
              backgroundColor="grey"
              isOpen={true}
              toggleFunc={this.toggleNewProjectPanel}
            />
          </div>
        </div>
        <h3>New Project</h3>
        <form onSubmit={e => this.handleSubmit(e)} noValidate>
          <fieldset>
            <Field invalid={errors.fullname}>
              <label htmlFor="fullname">Full Name</label>
              <input
                id="fullname"
                name="fullname"
                type="text"
                value={fullname}
                placeholder="John Doe"
                onChange={this.handleChange}
              />
              <span className="error">Please include a full name.</span>
            </Field>
            <Field invalid={errors.email}>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                value={email}
                placeholder="example@ofsapp.com"
                onChange={this.handleChange}
              />
              <span className="error">Please include a valid email.</span>
            </Field>
            <Field invalid={errors.phone}>
              <label htmlFor="phone">Phone Number</label>
              <input
                id="phone"
                name="phone"
                type="text"
                value={phone}
                placeholder="123456789"
                onChange={this.handleChange}
              />
              <span className="error">Please include a phone number.</span>
            </Field>
            <Field invalid={errors.address}>
              <label htmlFor="address">Street Address</label>
              <input
                id="address"
                name="address"
                type="text"
                value={address}
                placeholder="1337 D Dust Ln."
                onChange={this.handleChange}
              />
              <span className="error">Please include the street.</span>
            </Field>

            <Field invalid={errors.city}>
              <label htmlFor="city">City</label>
              <input
                id="city"
                name="city"
                type="text"
                value={city}
                placeholder="Fakeville"
                onChange={this.handleChange}
              />
              <span className="error">Please include the city.</span>
            </Field>

            <Field invalid={errors.state}>
              <label htmlFor="state">State</label>
              <select
                name="state"
                id="state"
                name="state"
                type="text"
                value={state}
                onChange={this.handleChange}
              >
                <option value="FL">Florida</option>
              </select>
              <span className="error">Please include the state.</span>
            </Field>

            <Field invalid={errors.zip}>
              <label htmlFor="zip">Zip</label>
              <input
                id="zip"
                name="zip"
                type="text"
                value={zip}
                placeholder="45"
                onChange={this.handleChange}
              />
              <span className="error">Please include the zip.</span>
            </Field>

            <Field>
              <label htmlFor="estimatedFootage">Estimated Footage (ft)</label>
              <input
                id="estimatedFootage"
                name="estimatedFootage"
                type="number"
                value={estimatedFootage}
                placeholder="123 ft"
                onChange={this.handleChange}
              />
            </Field>

            <Field>
              <label htmlFor="desiredHeight">Desired Height (ft)</label>
              <input
                id="desiredHeight"
                name="desiredHeight"
                type="number"
                placeholder="6 ft"
                value={desiredHeight}
                onChange={this.handleChange}
              />
            </Field>

            <Field>
              <label htmlFor="notes">Additional Notes</label>
              <textarea
                id="notes"
                name="notes"
                value={notes}
                placeholder="Something exciting!"
                onChange={this.handleChange}
              />
            </Field>
          </fieldset>

          <Button
            type="submit"
            variant={loading || addressValidationLoading ? 'disabled dynamic' : 'primary dynamic'}
            fluid={true}
            text="Create"
          />

          {loading || addressValidationLoading ? <Loader /> : ''}
          {message && <Message message={message} type="success" />}

          {suggestedAddress && (
            <Message>
              Did you mean{' '}
              <a href="#" onClick={e => this.applySuggestedAddress(e)}>
                {' '}
                {suggestedAddress}
              </a>
              ? To ignore this suggestion, submit again.
            </Message>
          )}

          {invalidAddress && <Message message={`Please use a valid address.`} type="error" />}

          {addressValidationError && <Message message={addressValidationError} type="error" />}
        </form>
      </StyledEmployeeCreateProjectForm>
    )
  }
}

export default EmployeeCreateProjectForm

const StyledEmployeeCreateProjectForm = styled.div`
  .meta-row {
    margin-bottom: 0.5rem;
    text-align: right;
    display: none;
    .close {
      display: inline-flex;
      justify-content: flex-end;
      cursor: pointer;
      outline: none;
      user-select: none;
      font-size: 1.5em;
    }
  }

  padding-top: 0;
  padding-bottom: 2em;

  h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    .meta-row {
      display: block;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background: ${colors.grey7};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.grey};
    border-radius: 2px;
  }
`
