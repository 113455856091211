import React from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import PropTypes from 'prop-types'
import { typography } from '../../helpers/foundation'

const Title = ({ step = 2, text, tag, size, color, weight }) => {
  const Tag = tag ? tag : step > 6 ? 'h6' : `h${step}`

  const Heading = styled(Tag)`
    font-size: ${size ? em(size) : typography[step]};
    margin-bottom: 0;
    font-weight: ${weight ? weight : 600};

    ${props => {
      if (color) {
        return `
          color: ${color};
        `
      }
    }}
  `

  return <Heading>{text}</Heading>
}

Title.propTypes = {
  step: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Title
