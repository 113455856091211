import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import fab from 'fabric'
import { lighten } from 'polished'
import { isEmpty } from 'lodash'
import { SketchField, Tools } from 'react-sketch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUndo,
  faRedo,
  faSearchPlus,
  faSearchMinus,
  faArrowsAlt,
  faMousePointer,
  faVectorSquare,
  faPaintBrush,
  faEraser,
  faPlus,
  faMinus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { faMap } from '@fortawesome/free-regular-svg-icons'
import throttle from 'lodash/throttle'
import { colors } from '../../../helpers/foundation'
import { coordsFromAddress } from '../../../helpers/google/geocoding'
import { blobToDataURL } from '../../../helpers/images'
import Tool from './components/Tool'
import Color from './components/Color'
import MapContainer from '../../sharedComponents/MapContainer'

let fabric = fab.fabric

class Drawer extends Component {
  _isMounted = false

  state = {
    canvasWidth: null,
    canvasHeight: null,
    color: colors.base,
    activeTool: 'Pencil',
    value: [],
    interactiveGoogleMapOpen: false,
    addressLat: null,
    addressLng: null,
    zoomLevel: 20,
    googleMapExists: false,
  }

  constructor() {
    super()
    this.handleResize = throttle(this.calculateCanvas, 200)
  }

  componentDidMount = () => {
    this._isMounted = true
    window.addEventListener('resize', this.handleResize)
    if (this._isMounted) {
      if (this.props.hasBackgroundImage) {
        this.setState(prevState => ({
          googleMapExists: true,
        }))
      }
      this.calculateCanvas()
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      (!this.state.googleMapExists &&
        !prevProps.hasBackgroundImage &&
        this.props.hasBackgroundImage) ||
      (!prevState.googleMapExists &&
        this.props.currentSketchData &&
        this.props.currentSketchData.backgroundImage &&
        !isEmpty(this.props.currentSketchData.backgroundImage) &&
        this.props.currentSketchData.backgroundImage.src !==
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=')
    ) {
      this.setState(prevState => ({
        googleMapExists: true,
      }))
    }
  }

  calculateCanvas = () => {
    // get px of inner (canvas parent)
    const $inner = document.getElementById('drawer')
    if (!$inner) return false
    const innerWidth = $inner.offsetWidth
    const innerHeight = $inner.offsetHeight

    if (this._isMounted) {
      this.setState({
        canvasWidth: innerWidth,
        canvasHeight: innerHeight,
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  getSketchJson = () => {
    console.log('the sketch json', this._sketch.toJSON())
    console.log('the sketch object', this._sketch)
  }

  selectTool = tool => {
    this.setState(prevState => {
      if (tool === prevState.activeTool) {
        return false
      }
      return {
        activeTool: tool,
      }
    })
  }

  changeColor = color => {
    this.setState({
      color,
    })
  }

  zoom = direction => {
    this._sketch.zoom(direction === 'in' ? 1.25 : 0.8)
  }

  undo = () => {
    if (this._sketch.canUndo()) {
      this._sketch.undo()
    }
  }

  redo = () => {
    if (this._sketch.canRedo()) {
      this._sketch.redo()
    }
  }

  clear = () => {
    this._sketch.clear()
  }

  save = () => {
    const sketchDataURL = this._sketch.toDataURL()
    const sketchJSON = this._sketch.toJSON()

    // console.log(sketchDataURL, sketchJSON)
    this.props.saveSketch(sketchDataURL, sketchJSON)
  }

  addGoogleInteractiveMap = async () => {
    const { address, city, state } = this.props.additionalClientInfo
    const { canvasWidth, canvasHeight } = this.state
    const coords = await coordsFromAddress(address, city, state)
    this.setState(prevState => ({
      interactiveGoogleMapOpen: true,
      addressLat: coords.lat,
      addressLng: coords.lng,
    }))
  }

  addGoogleMapsStaticImage = async (zoom = 20) => {
    const { canvasWidth, canvasHeight, addressLat, addressLng } = this.state
    const { address, city, state } = this.props.additionalClientInfo
    const coords = await coordsFromAddress(address, city, state)

    // Request to backend to get the static google map
    const response = await axios.get(`/api/surveys/${this.props.surveyId}/static-map`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'image/png',
        Accept: 'image/png',
        'Cache-Control': 'no-cache',
      },
      params: {
        lat: coords.lat,
        lng: coords.lng,
        screenW: canvasWidth,
        screenH: canvasHeight,
        zoom,
      },
    })

    // remove the previous background, and add the new one
    blobToDataURL(new Blob([response.data], { type: 'image/png' }), mapDataURI => {
      this.removeGoogleMapsStaticImage()
      setTimeout(() => {
        this._sketch.setBackgroundFromDataUrl(mapDataURI, {
          stretched: true,
          stretchedX: canvasWidth,
          stretchedY: canvasHeight,
        })
        this.setState(prevState => ({
          googleMapExists: true,
        }))
      }, 100)
    })
  }

  removeGoogleMapsStaticImage = (adjustState = false) => {
    // set a 1x1 transparent image to the background
    this._sketch.setBackgroundFromDataUrl(
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='
    )
    if (adjustState) {
      this.setState(prevState => ({
        googleMapExists: false,
      }))
    }
  }

  changeStaticGoogleMapZoomLevel = amt => {
    const { zoomLevel } = this.state
    this.addGoogleMapsStaticImage(zoomLevel + amt)
    this.setState(prevState => ({
      zoomLevel: prevState.zoomLevel + amt,
    }))
  }

  render() {
    const {
      canvasWidth,
      canvasHeight,
      activeTool,
      color,
      interactiveGoogleMapOpen,
      addressLat,
      addressLng,
      googleMapExists,
    } = this.state

    return (
      <Fragment>
        <div className="top-actions">
          <div className="save" onClick={this.save}>
            Done
          </div>
          <div className="notice">Center your drawing for best results.</div>
        </div>
        {interactiveGoogleMapOpen ? (
          <MapContainer
            coords={{ lat: addressLat, lng: addressLng }}
            additionalClientInfo={this.props.additionalClientInfo}
            initialZoom={20}
          />
        ) : (
          <StyledDrawer id="drawer">
            <SketchField
              ref={c => (this._sketch = c)}
              width={canvasWidth}
              height={canvasHeight}
              // dynamically load the active tool from state
              tool={Tools[activeTool]}
              // if eraser is select, just use the draw tool and color is white
              lineColor={activeTool === 'Eraser' ? '#fff' : color}
              // make eraser wider
              lineWidth={activeTool === 'Eraser' ? 18 : 3}
              imageFormat="png"
              value={this.props.currentSketchData}
            />
          </StyledDrawer>
        )}
        <div className="bottom-actions right">
          <div className="colors">
            <Color
              color={'#7048E8'}
              onClick={() => this.changeColor('#7048E8')}
              active={color === '#7048E8'}
            />
            <Color
              color={'#FF922B'}
              onClick={() => this.changeColor('#FF922B')}
              active={color === '#FF922B'}
            />
            <Color
              color={'#4DABF7'}
              onClick={() => this.changeColor('#4DABF7')}
              active={color === '#4DABF7'}
            />
            <Color
              color={'#E64980'}
              onClick={() => this.changeColor('#E64980')}
              active={color === '#E64980'}
            />
            <Color
              color={'#343A40'}
              onClick={() => this.changeColor('#343A40')}
              active={color === '#343A40'}
            />
          </div>
          <div className="bottom-actions left">
            <div className="map-options">
              {googleMapExists ? (
                <>
                  <Widget plus onClick={e => this.changeStaticGoogleMapZoomLevel(1)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Widget>
                  <Widget minus onClick={e => this.changeStaticGoogleMapZoomLevel(-1)}>
                    <FontAwesomeIcon icon={faMinus} />
                  </Widget>
                  <Widget onClick={e => this.removeGoogleMapsStaticImage(true)}>
                    <div className="cancel-map">
                      <FontAwesomeIcon icon={faMap} />
                    </div>
                    <div className="cancel-x">
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </Widget>
                </>
              ) : (
                <Widget onClick={e => this.addGoogleMapsStaticImage(this.props.zoomLevel)}>
                  <FontAwesomeIcon icon={faMap} />
                </Widget>
              )}
            </div>
          </div>
          <Tool onClick={this.undo}>
            <FontAwesomeIcon icon={faUndo} />
          </Tool>
          <Tool onClick={this.redo}>
            <FontAwesomeIcon icon={faRedo} />
          </Tool>
          <Tool onClick={() => this.zoom('in')}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </Tool>
          <Tool onClick={() => this.zoom('out')}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </Tool>
          <Tool onClick={() => this.selectTool('Pan')} active={activeTool === 'Pan'}>
            <FontAwesomeIcon icon={faArrowsAlt} />
          </Tool>
          <Tool onClick={() => this.selectTool('Select')} active={activeTool === 'Select'}>
            <FontAwesomeIcon icon={faMousePointer} />
          </Tool>
          <Tool onClick={() => this.selectTool('Eraser')} active={activeTool === 'Eraser'}>
            <FontAwesomeIcon icon={faEraser} />
          </Tool>
          <Tool
            onClick={() => this.selectTool('Rectangle')}
            color={color}
            active={activeTool === 'Rectangle'}
          >
            <FontAwesomeIcon icon={faVectorSquare} />
          </Tool>
          <Tool onClick={() => this.selectTool('Line')} active={activeTool === 'Line'}>
            <Line color={color} />
          </Tool>
          <Tool
            onClick={() => this.selectTool('Pencil')}
            color={color}
            active={activeTool === 'Pencil'}
          >
            <FontAwesomeIcon icon={faPaintBrush} />
          </Tool>
          {/* </div> */}
        </div>
      </Fragment>
    )
  }
}

const Line = styled.div`
  background-color: ${props => props.color};
  height: 0.15em;
  width: 1.25em;
  margin: auto;
  transform: rotate(45deg);
  transition: background-color 0.2s ease-in-out;
`

const StyledDrawer = styled.div`
  width: 100%;
  position: relative;
  /* top: 56px; */
  height: calc(100% - 56px - 56px);
`

const Widget = styled.div`
  position: relative;
  display: block;
  height: 3rem;
  width: 3rem;
  margin: 1rem;
  background-color: #eee;
  border-radius: 4px;
  outline: none;
  user-select: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;
  font-size: 1.6rem;
  text-align: center;

  * {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 25%;
  }

  .cancel-map {
  }
  .cancel-x {
    color: ${lighten(0.15, colors.danger)};
  }

  ${props => {
    if (props.plus || props.minus) {
      return `
        background-color: #eee;
      `
    }
  }}
`

export default Drawer
