import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

const InstallationInProgress = props => {
  return (
    <StyledInstallationInProgress>
      <Header title="Your installation is in progress!" />
      <Container>
        <Notice text="Our team has indicated that your installation is in progress. It’s not necessary for you to be home during this time." />
      </Container>
    </StyledInstallationInProgress>
  )
}

const StyledInstallationInProgress = styled.div`
  .para {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default InstallationInProgress
