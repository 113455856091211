import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { em, lighten } from 'polished'
import shortid from 'shortid'
import Select from 'react-select'
import { colors } from '../../helpers/foundation'

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? colors.primary : provided.borderColor,
      '&:hover': {
        borderColor: state.isFocused ? colors.primary : provided.borderColor,
      },
    }
  },
  container: (provided, state) => {
    console.log('prov?', provided)
    console.log('state?', state)
    return {
      ...provided,
      fontSize: `${em('14px')}`,
    }
  },
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  option: (provided, state) => {
    console.log('state', state)
    console.log('provded', provided)
    return {
      ...provided,
      '&:hover': {
        backgroundColor: lighten(0.15, colors.primary),
        color: colors.white,
      },
      backgroundColor: state.isSelected ? colors.primary : colors.white,
      color: state.isSelected ? colors.white : colors.base,
    }
  },
}

const Dropdown = ({ defaultFirst, selected, options, placeholder, handleChange }) => {
  return (
    <Select
      // If it is supposed to defaultFirst, defaults to first unless something is selected
      value={defaultFirst && !selected ? options[0] : options.find(opt => opt.value === selected)}
      onChange={handleChange}
      options={options}
      // defaultValue={defaultFirst ? options[0] : undefined}
      styles={customStyles}
      placeholder={placeholder}
    />
  )
}

export default Dropdown
