import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { addProductionUrl } from './../../../helpers/constants'

import Title from './../../sharedComponents/Title'
import Field from './../../sharedComponents/Field'
import Button from './../../sharedComponents/Button'

const initialFields = {
  email: '',
  password: '',
}

class ExistingUserConfirmation extends Component {
  state = {
    fields: initialFields,
  }

  componentDidMount = () => {
    const { quote } = this.props
    this.setState(prevState => ({
      fields: {
        email: quote.email,
        password: '',
      },
    }))
  }

  handleChange = e => {
    let { name, value } = e.target
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }))
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { quote } = this.props
    const { email, password } = this.state.fields

    if (this.props.submitting) {
      return false
    }

    if (password.length < 4) {
      this.props.setErrors(['Password must be at least 4 characters'])
      return false
    }

    const loginSuccess = await this.props.handleLogin(email, password)
    if (loginSuccess) {
      this.props.setCustomerData({
        email,
        quoteId: quote._id,
      })
    }
  }

  render() {
    return (
      <StyledExistingUserConfirmation>
        <Title text="Login to schedule your survey." tag="h3" size="24px" />
        <form onSubmit={this.handleSubmit}>
          <Field>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              value={this.state.fields.email}
              onChange={() => false}
              disabled={true}
            />
          </Field>
          <Field>
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              value={this.state.fields.password}
              onChange={this.handleChange}
              placeholder="Password must be at least 5 characters long..."
            />
          </Field>

          <Button
            text="Schedule Survey"
            fluid={true}
            variant={`lg ${this.props.submitting && 'disabled'}`}
            disabled={!this.props.submitting ? false : true}
          />
        </form>
      </StyledExistingUserConfirmation>
    )
  }
}

const StyledExistingUserConfirmation = styled.div`
  button {
    margin-top: 1em;
  }
`

export default ExistingUserConfirmation
