import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { estimateGet, estimateUpdateServer } from './../../../../redux/modules/estimate/'

import Header from './../../../sharedComponents/Header'
import Container from './../../../sharedComponents/Container'
import Notice from './../../../sharedComponents/Notice'
import SidebarLayout from './../../../sharedComponents/layouts/SidebarLayout'
import History from './../../../sharedComponents/History'
import CustomerEstimateApprove from '../../../customer/estimate/CustomerEstimateApprove'
import { ViewerConsumer, withViewerContext } from './../../../../contexts/ViewerContext'
import Viewer from './../../../sharedComponents/Viewer'

class EmployeePendingApproval extends Component {
  componentDidMount = () => {
    this.props.estimateGet(this.props.estimateId)
  }

  markApproved = async () => {
    await this.props.estimateUpdateServer(
      this.props.estimateId,
      { status: 'Pending Payment', from: 'Pending Approval', projectId: this.props.project._id },
      false
    )

    this.props.updateProjectStageStatus('estimate', 'Pending Payment')
  }

  render() {
    const { project } = this.props
    return (
      <Fragment>
        <ViewerConsumer>
          {/* sets the viewer for images being viewed from the history sidebar */}
          {({ currentImage, unsetImage }) => {
            if (currentImage) {
              return <Viewer currentImage={currentImage} unsetImage={unsetImage} />
            }
          }}
        </ViewerConsumer>
        <SidebarLayout
          history={
            <History project={project} survey={project.survey} estimate={project.estimate} />
          }
          statusComponent={
            <React.Fragment>
              <StyledEmployeePendingApproval>
                <Header title="The estimate is waiting for customer approval." />
                <Container>
                  <Notice text="We are waiting for customer approval. Once the customer approves their estimate, the card will move into 'Pending Payment'." />

                  <CustomerEstimateApprove
                    project={this.props.project}
                    user={this.props.user}
                    estimateUpdateServer={this.props.estimateUpdateServer}
                    estimate={this.props.estimate}
                    markApproved={this.markApproved}
                    isEmployee={true}
                  />
                </Container>
              </StyledEmployeePendingApproval>
            </React.Fragment>
          }
        />
      </Fragment>
    )
  }
}

const StyledEmployeePendingApproval = styled.div``

const mapStateToProps = state => {
  return {
    estimate: state.estimate.estimate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    estimateUpdateServer: (estimateId, updates, isCustomer) =>
      dispatch(estimateUpdateServer(estimateId, updates, isCustomer)),
    estimateGet: estimateId => dispatch(estimateGet(estimateId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeePendingApproval)
