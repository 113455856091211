import React, { Component } from 'react'
import styled from 'styled-components'
import isRole from './../../../../helpers/roles'
import { colors, breakpoints } from './../../../../helpers/foundation'

import Button from './../../../sharedComponents/Button'
import Field from './../../../sharedComponents/Field'
import Icon from './../../../sharedComponents/Icon'
import TimePicker from './../../../sharedComponents/TimePicker'

const Edit = ({
  step,
  // _id,
  email,
  displayName,
  title,
  address,
  city,
  state,
  zip,
  profileImage,
  startTime,
  endTime,
  updateTimes,
  teams,
  errors,
  removingUser,
  removeConfirmText,
  removeUser,
  closeModal,
  handleChange,
  handleSelect,
  addTeam,
  removeTeam,
  validateField,
  validFields,
  goToStep,
  finishAction,
  // removeMember,
  user,
}) => {
  return (
    <StyledEdit>
      {step === 0 && (
        <div>
          <div className="title">
            <div className="left">
              <img
                src={profileImage ? profileImage : '//placehold.it/60x60'}
                alt=""
                className="profile-image"
              />
              <h3>{displayName}</h3>
            </div>
            <div className="right">
              <div onClick={removeUser} className="remove">
                remove user
              </div>
            </div>
          </div>

          <div className="content">
            <Grid>
              <Column width="50%">
                <h4>Personal Info</h4>
                <Field invalid={errors.email}>
                  <label htmlFor="email">Email Address</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    onBlur={e => validateField(e, 'email')}
                  />
                  {/* <span className="error">Please enter a valid email.</span> */}
                </Field>
                <Field invalid={errors.displayName}>
                  <label htmlFor="displayName">Full Name</label>
                  <input
                    id="displayName"
                    name="displayName"
                    type="text"
                    value={displayName}
                    onChange={handleChange}
                    onBlur={e => validateField(e, 'displayName')}
                  />
                  {/* <span className="error">Please enter your full name.</span> */}
                </Field>
                <Field invalid={errors.title}>
                  <label htmlFor="title">Offical Title</label>
                  <input
                    id="title"
                    name="title"
                    type="text"
                    value={title}
                    onChange={handleChange}
                    onBlur={e => validateField(e, 'title')}
                  />
                  {/* <span className="error">Please enter a valid title.</span> */}
                </Field>

                <Field>
                  <TimePicker
                    title="Work Hours"
                    startTime={startTime}
                    endTime={endTime}
                    updateTimes={updateTimes}
                  />
                  {/* <span className="error">Please fill out your complete work hours.</span> */}
                </Field>

                <Field>
                  <label htmlFor="address">Street Address</label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    value={address}
                    onChange={handleChange}
                  />
                </Field>

                <Field>
                  <label htmlFor="city">City</label>
                  <input id="city" name="city" type="text" value={city} onChange={handleChange} />
                </Field>

                <Field>
                  <label htmlFor="state">State</label>
                  <select name="state" id="state" type="text" value={state} onChange={handleChange}>
                    <option value="FL">Florida</option>
                  </select>
                </Field>

                <Field>
                  <label htmlFor="zip">Zip</label>
                  <input id="zip" name="zip" type="text" value={zip} onChange={handleChange} />
                </Field>
              </Column>
              <Column width="50%">
                <h4>Teams</h4>
                {[...Array(teams.length)].map((key, idx) => {
                  return (
                    <Grid key={idx}>
                      <Column gutter="small" width="40%">
                        <label htmlFor="team">Team</label>
                        <select
                          name="name"
                          id={`team-${idx}`}
                          value={teams[idx].name}
                          onChange={e => handleSelect(e, idx)}
                        >
                          <option value="" />
                          {isRole(user).admin && <option value="Admin">Admin</option>}

                          {isRole(user).admin || isRole(user).surveyManager ? (
                            <option value="Survey">Survey</option>
                          ) : null}

                          {isRole(user).admin || isRole(user).estimateManager ? (
                            <option value="Estimate">Estimate</option>
                          ) : null}

                          {isRole(user).admin || isRole(user).customerServiceManager ? (
                            <option value="CustomerService">Customer Service</option>
                          ) : null}
                        </select>
                      </Column>
                      <Column gutter="small" width="40%">
                        <label htmlFor="role">Role</label>
                        <select
                          name="role"
                          id={`teams-${idx}`}
                          value={teams[idx].role}
                          onChange={e => handleSelect(e, idx)}
                        >
                          <option value="" />
                          {isRole(user).admin && <option value="Manager">Manager</option>}
                          <option value="Member">Member</option>
                        </select>
                      </Column>
                      <Column width="10%">
                        <div className="icon-outer" onClick={e => removeTeam(idx)}>
                          <Icon icon="close" color="danger" />
                        </div>
                      </Column>
                    </Grid>
                  )
                })}
                <div className="add-team" onClick={addTeam}>
                  <Icon icon="add" color="grey2" fill="transparent" /> <span>Add another team</span>
                </div>
              </Column>
            </Grid>
          </div>

          <div className="options">
            <div className="cancel" onClick={closeModal}>
              cancel
            </div>
            {/* <button onClick={validFields() ? () => goToStep() : null}>Continue</button> */}
            <Button
              variant={`lg ${validFields() ? 'primary' : 'disabled'}`}
              text="Continue"
              handleClick={validFields() ? () => goToStep() : null}
              // handleClick={() => (validFields() ? goToStep() : null)}
            />
          </div>
        </div>
      )}

      {step === 1 && (
        <React.Fragment>
          {removingUser ? (
            <div>
              <h3 className="title red">Are you sure?</h3>
              <div>
                By removing {displayName}
                's user account, you’re removing them from #PROJECT-NUM# projects that they’re
                assigned to. These projects will be reassigned to the manager of the team Orlando is
                leaving.
              </div>
              <div className="margin-vertical">
                <Button text="Cancel" variant="lg danger" fluid={true} handleClick={closeModal} />
              </div>
              <div className="confirm-container">
                <div>Please type "remove" to remove this user.</div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    if (removeConfirmText === 'remove') {
                      goToStep()
                    }
                  }}
                  className="flex"
                >
                  <input
                    className="remove-confirm-text"
                    type="text"
                    name="removeConfirmText"
                    value={removeConfirmText}
                    onChange={handleChange}
                  />
                  <Button
                    text="Confirm"
                    variant={removeConfirmText === 'remove' ? 'primary' : 'disabled'}
                  />
                </form>
              </div>
            </div>
          ) : (
            <div>
              <div className="title">
                <div className="left">
                  <img
                    src={profileImage ? profileImage : '//placehold.it/60x60'}
                    alt=""
                    className="profile-image"
                  />
                  <h3>{displayName}</h3>
                </div>
              </div>
              <Grid>
                <Column>
                  <h4>Personal Info</h4>
                  <div className="detail">
                    <div className="detail-title">Name</div>
                    {displayName}
                  </div>
                  <div className="detail">
                    <div className="detail-title">Email</div>
                    {email}
                  </div>
                  <div className="detail">
                    <div className="detail-title">Title</div>
                    {title}
                  </div>
                  <div className="detail">
                    <div className="detail-title">Work hours</div>
                    {startTime}
                    am - {endTime}
                    pm
                  </div>
                  <div className="detail">
                    <div className="detail-title">Address</div>
                    {address && city && zip
                      ? `${address} ${city}, ${state} ${zip}`
                      : 'Incomplete address'}
                  </div>
                </Column>
                <Column>
                  <h4>Teams</h4>
                  <div>
                    {teams.map((team, idx) => {
                      return (
                        <div key={idx}>
                          <b>{team.name}</b>
                          <div>{team.role}</div>
                        </div>
                      )
                    })}
                  </div>
                </Column>
              </Grid>

              <div className="options">
                <div className="left">
                  <div className="cancel" onClick={closeModal}>
                    cancel
                  </div>
                  <div className="edit" onClick={() => goToStep(0)}>
                    <Icon icon="pencil" /> <span>edit information</span>
                  </div>
                </div>
                <Button variant="lg primary" text="Continue" handleClick={() => goToStep()} />
              </div>
            </div>
          )}
        </React.Fragment>
      )}

      {step === 2 && (
        <React.Fragment>
          {removingUser ? (
            <div>
              <h3 className="title green">Success :(</h3>
              <div>
                You removed {displayName}
                's user account. We’ve sent a notification email to his team manager.
              </div>
              <div className="bottom">
                <Button
                  text="Finish"
                  fluid={true}
                  variant="lg"
                  handleClick={() => finishAction('remove')}
                />
              </div>
            </div>
          ) : (
            <div>
              <h3 className="title green">10-4!</h3>
              <div>
                You changed {displayName}
                's account details. We’ve sent a confirmation email to {displayName}, as well as to
                the managers of his teams.
              </div>
              <div className="bottom">
                <Button
                  text="Finish"
                  fluid={true}
                  variant="lg"
                  handleClick={() => finishAction('edit')}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </StyledEdit>
  )
}

export default Edit

const StyledEdit = styled.div`
  .icon-outer {
    position: relative;
    top: 1.875em;
    border: 1px solid ${colors.danger};
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    width: 20px;
    height: 20px;

    .icon {
      position: relative;
      top: 4px;
      vertical-align: top;
      cursor: pointer;
    }
  }
`

const Grid = styled.div`
  display: flex;

  @media screen and (max-width: ${breakpoints.small}px) {
    display: block;
  }
`

const Column = styled.div`
  width: ${props => {
    if (props.width) return props.width
    return '50%'
  }};

  h4 {
    font-size: 1.25em;
  }

  &:first-child {
    padding: ${props => (props.gutter && props.gutter === 'small' ? '0 0.25em 0 0' : '0 1em 0 0')};
  }

  &:last-child {
    padding: ${props => (props.gutter && props.gutter === 'small' ? '0 0 0 0.25em' : '0 0 0 1em')};
  }

  @media screen and (max-width: ${breakpoints.small}px) {
    display: block;
    width: 100%;

    &:first-child {
      padding: 0;
    }

    &:last-child {
      padding: 0;
    }
  }
`
