import styled from 'styled-components'
import { actionBarSize, breakpoints, zIndexes } from '../../helpers/foundation'

const MobileAction = styled.div`
  bottom: ${actionBarSize.small};
  left: 0;
  right: 0;
  position: fixed;
  z-index: ${zIndexes.base};

  @media screen and (min-width: ${breakpoints.medium}px) {
    display: none;
  }

  > button {
    border-radius: 0;
    margin-bottom: 0;
  }
`

export default MobileAction
