import {
  LOADING,
  ERROR,
  UPDATE_INSTALLATION,
  UPDATE_NESTED_INSTALLATION,
  ADD_PHOTO_COMMENT,
  UPDATE_POSTOPS,
  ADD_INSPECTION_REMARK,
  ADD_INSPECTION_FILE,
  FETCH_INSTALLATION,
  FETCH_POSTOPS,
  INSPECTION_FILE_LOADING,
} from './types'
import shortid from 'shortid'
import { combineReducers } from 'redux'

const installationState = {
  loading: false,
  error: null,
  // currentStep: 6,
  installationStatus: 'Schedule Installation',
  // schedule installation
  installationDate: null,
  waterDate: null,
  electricDate: null,
  gasDate: null,
  // install prep
  parts: [],
  allPartsLoaded: false,
  // in progress
  photos: {
    rearLeft: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    rearCenter: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    rearRight: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    centerLeft: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    centerRight: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    frontLeft: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    frontCenter: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
    frontRight: {
      _id: shortid.generate(),
      image: null,
      comments: [],
      status: null,
    },
  },
}

const postOpsState = {
  inspectionDate: null,
  inspectionStatus: null, // '', pass, fail, failRevert
  inspectionRemarks: [],
  inspectionFiles: [],
  inspectionFileLoading: false,
}

const postOpsReducer = (state = postOpsState, action) => {
  switch (action.type) {
    case INSPECTION_FILE_LOADING:
      return {
        ...state,
        inspectionFileLoading: action.payload.loading,
      }
    case FETCH_POSTOPS:
      return {
        ...state,
        ...action.payload.postOps,
      }
    case UPDATE_POSTOPS:
      return {
        ...state,
        ...action.payload.updates,
      }
    case ADD_INSPECTION_REMARK:
      return {
        ...state,
        inspectionRemarks: [
          ...state.inspectionRemarks,
          {
            _id: shortid.generate(),
            ...action.payload.remark,
          },
        ],
      }
    case ADD_INSPECTION_FILE:
      // console.log('am i getting here plzzz', action)
      return {
        ...state,
        inspectionFiles: [
          ...state.inspectionFiles,
          {
            _id: shortid.generate(),
            ...action.payload.fileObj,
          },
        ],
      }
    default:
      return state
  }
}

const installationReducer = (state = installationState, action) => {
  let idx
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case ERROR:
      return {
        ...state,
        error: action.payload.message,
      }
    case FETCH_INSTALLATION:
      return {
        ...action.payload.installation,
      }
    case UPDATE_INSTALLATION:
      return {
        ...state,
        ...action.payload.updates,
      }
    case UPDATE_NESTED_INSTALLATION:
      // field can either be string, for dealing with nested arrs, or an arr for dealing with nested object
      const fieldStringOrArr =
        typeof action.payload.field === 'string' ? action.payload.field : action.payload.field[0]
      // dont try to find the index if dealing with object
      idx =
        typeof action.payload.field === 'string'
          ? state[fieldStringOrArr].findIndex(item => item._id === action.payload.itemId)
          : null
      return {
        ...state,
        [fieldStringOrArr]:
          typeof action.payload.field === 'string'
            ? [
                ...state[action.payload.field].slice(0, idx),
                {
                  ...state[action.payload.field][idx],
                  ...action.payload.updates,
                },
                ...state[action.payload.field].slice(idx + 1),
              ]
            : {
                // update nested object based on 2nd item in field[]
                ...state[action.payload.field[0]],
                [action.payload.field[1]]: {
                  ...state[action.payload.field[0]][action.payload.field[1]],
                  ...action.payload.updates,
                },
              },
      }

    case ADD_PHOTO_COMMENT:
      return {
        ...state,
        photos: {
          ...state.photos,
          [action.payload.direction]: {
            ...state.photos[action.payload.direction],
            comments: [...state.photos[action.payload.direction].comments, action.payload.comment],
          },
        },
      }
    default:
      return state
  }
}

const reducer = combineReducers({
  install: installationReducer,
  postOps: postOpsReducer,
})

export default reducer
