import { connect } from 'react-redux'
import Paperwork from './../components/paperwork/'
import { withRouter } from 'react-router-dom'
import {
  updateFile,
  removeFile,
  addFile,
  updateStep,
  fetchPaperwork,
  updatePaperwork,
  sendHoaEmail,
} from './../redux/modules/paperwork/operations'

const mapStateToProps = state => {
  return {
    _id: state.paperwork._id,
    loading: state.paperwork.loading,
    error: state.paperwork.error,
    paperwork: state.paperwork.paperwork,
    status: state.paperwork.status,
    hoaStatus: state.paperwork.hoaStatus,
    permitsStatus: state.paperwork.permitsStatus,
    hoaEmailSending: state.paperwork.hoaEmailSending,
    hoaEmailError: state.paperwork.hoaEmailError,
    hoaEmailSent: state.paperwork.hoaEmailSent,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateFile: (projectId, paperworkId, fileid, updates, whichArr) =>
      dispatch(updateFile(projectId, paperworkId, fileid, updates, whichArr)),
    removeFile: (paperworkId, fileid, whichArr) =>
      dispatch(removeFile(paperworkId, fileid, whichArr)),
    addFile: (paperworkId, name, type, responsible) =>
      dispatch(addFile(paperworkId, name, type, responsible)),
    updateStep: (paperworkId, which, newStep, emailToSend, projectId) =>
      dispatch(updateStep(paperworkId, which, newStep, emailToSend, projectId)),
    fetchPaperwork: paperworkId => dispatch(fetchPaperwork(paperworkId)),
    // this will also probably need to be within project dispatcher
    updatePaperwork: (paperworkId, updates) => dispatch(updatePaperwork(paperworkId, updates)),
    // projectId: (paperworkId, updates) => dispatch(updatePaperwork(paperworkId, updates)),
    sendHoaEmail: (paperworkId, hoaEmail) => dispatch(sendHoaEmail(paperworkId, hoaEmail)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Paperwork)
)
