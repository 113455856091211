import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'

import ResizeableSector from './../../sharedComponents/ResizeableSector'
import StyledTable from './../../sharedComponents/StyledTable'
import TableRow from './shared/TableRow'
import Icon from './../../sharedComponents/Icon'

class TeamView extends Component {
  state = {
    admins: [],
    surveyors: [],
    estimators: [],
    customerServices: [],
    productions: [], // production/installation people
    currentSort: {
      admins: '',
      surveyors: '',
      estimators: '',
      customerServices: '',
    },
  }

  componentDidMount = () => {
    const updateState = this.massageData()
    this.setState(prevState => ({ ...updateState }))
  }

  componentDidUpdate = prevProps => {
    const { admins, surveyors, estimators } = this.state
    if (prevProps.modalOpen && !this.props.modalOpen) {
      const updateState = this.massageData()
      this.setState(prevState => ({ ...updateState }))
    }
  }

  massageData = () => {
    const { members } = this.props

    // get an array of all members that are on a certain team,
    // and remove all teams that arent specified team from member.teams
    const FilterByTeam = teamName =>
      members.reduce((acc, member) => {
        const teams = member.teams.filter(team => team.name === teamName)
        const retObj = { ...member, teams }
        retObj.teams.length > 0 && acc.push(retObj)
        return acc
      }, [])

    const updateObj = {}
    updateObj.admins = FilterByTeam('Admin')
    updateObj.surveyors = FilterByTeam('Survey')
    updateObj.estimators = FilterByTeam('Estimate')
    updateObj.customerServices = FilterByTeam('CustomerService')
    updateObj.productions = FilterByTeam('Production')
    return updateObj
  }

  sortByColumn = (column, field) => {
    // sort the members in array based on team passed in
    // if team is equal to the current team, reverse the list
    this.setState(prevState => {
      // will add a negative if it is the same column

      const currentSort =
        prevState.currentSort[field] === column ? `-${prevState.currentSort[field]}` : column

      let sortedMembers
      if (prevState.currentSort[field] !== column) {
        sortedMembers = prevState[field].sort((a, b) => {
          let first, second

          // for teams, need to check the first item ( for now )
          if (column === 'teamName') {
            first = a.teams[0].name.toLowerCase()
            second = b.teams[0].name.toLowerCase()
          } else if (column === 'teamRole') {
            first = a.teams[0].role.toLowerCase()
            second = b.teams[0].role.toLowerCase()
          } else {
            first = a[column].toLowerCase()
            second = b[column].toLowerCase()
          }

          if (first > second) {
            return 1
          } else {
            return -1
          }
        })
        // z-a sort
      } else {
        sortedMembers = prevState[field].sort((a, b) => {
          let first, second

          if (column === 'teamName') {
            first = a.teams[0].name.toLowerCase()
            second = b.teams[0].name.toLowerCase()
          } else if (column === 'teamRole') {
            first = a.teams[0].role.toLowerCase()
            second = b.teams[0].role.toLowerCase()
          } else {
            first = a[column].toLowerCase()
            second = b[column].toLowerCase()
          }

          if (first > second) {
            return -1
          } else {
            return 1
          }
        })
      }

      return {
        currentSort: {
          ...prevState.currentSort,
          [field]: currentSort,
        },
        [field]: sortedMembers,
      }
    })
  }

  render() {
    const { admins, surveyors, estimators, customerServices, productions, openSectors } = this.state
    const { optionsClick, user } = this.props
    return (
      <StyledTeamView>
        <ResizeableSector
          tkey="admins"
          size="lg"
          title="Admin Team"
          smallTitle={`${admins.length} Members`}
          color="white"
          bordered={true}
          borderColor="primary"
        >
          <div className="rs-wrapper">
            {admins.length < 1 ? (
              <div className="no-employees">Sorry. There are no employees on this team.</div>
            ) : (
              <StyledTable>
                <thead>
                  <tr>
                    <th
                      className="displayname-th"
                      onClick={() => this.sortByColumn('displayName', 'admins')}
                    >
                      <span>Name</span> <Icon icon="upDown" />
                    </th>
                    <th className="title-th" onClick={() => this.sortByColumn('title', 'admins')}>
                      <span>Title</span> <Icon icon="upDown" />
                    </th>
                    <th onClick={() => this.sortByColumn('teamRole', 'admins')}>
                      <span>Team Role</span> <Icon icon="upDown" />
                    </th>
                    <th className="calendar-th" />
                    <th className="options-th" />
                  </tr>
                </thead>
                <tbody>
                  {admins.map(member => {
                    return (
                      <TableRow
                        key={member._id}
                        profileImage={member.profileImage}
                        displayName={member.displayName}
                        title={member.title}
                        teams={member.teams}
                        optionsClick={() => optionsClick(member._id)}
                        canEdit={user.teams.findIndex(team => team.name === 'Admin') > -1}
                      />
                    )
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </ResizeableSector>

        <ResizeableSector
          tkey="surveyors"
          size="lg"
          title="Survey Team"
          smallTitle={`${surveyors.length} Members`}
          color="white"
          bordered={true}
          borderColor="blue"
        >
          <div className="rs-wrapper">
            {surveyors.length < 1 ? (
              <div className="no-employees">Sorry. There are no employees on this team.</div>
            ) : (
              <StyledTable>
                <thead>
                  <tr>
                    <th
                      className="displayname-th"
                      onClick={() => this.sortByColumn('displayName', 'surveyors')}
                    >
                      <span>Name</span> <Icon icon="upDown" />
                    </th>
                    <th
                      className="title-th"
                      onClick={() => this.sortByColumn('title', 'surveyors')}
                    >
                      <span>Title</span> <Icon icon="upDown" />
                    </th>
                    <th onClick={() => this.sortByColumn('teamRole', 'surveyors')}>
                      <span>Team Role</span> <Icon icon="upDown" />
                    </th>
                    <th className="calendar-th">
                      <span>Calendars</span>
                    </th>
                    <th className="options-th" />
                  </tr>
                </thead>
                <tbody>
                  {surveyors.map(member => {
                    return (
                      <TableRow
                        key={member._id}
                        profileImage={member.profileImage}
                        displayName={member.displayName}
                        title={member.title}
                        teams={member.teams}
                        calendar={member.googleCalendar}
                        optionsClick={() => optionsClick(member._id)}
                        canEdit={
                          user.teams.findIndex(team => team.name === 'Admin') > -1 ||
                          (user.teams.findIndex(team => team.name === 'Survey') > -1 &&
                            user.teams[user.teams.findIndex(team => team.name === 'Survey')]
                              .role === 'Manager')
                        }
                      />
                    )
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </ResizeableSector>

        <ResizeableSector
          tkey="estimators"
          size="lg"
          title="Estimate Team"
          smallTitle={`${estimators.length} Members`}
          color="white"
          bordered={true}
          borderColor="purple"
        >
          <div className="rs-wrapper">
            {estimators.length < 1 ? (
              <div className="no-employees">Sorry. There are no employees on this team.</div>
            ) : (
              <StyledTable>
                <thead>
                  <tr>
                    <th
                      className="displayname-th"
                      onClick={() => this.sortByColumn('displayName', 'estimators')}
                    >
                      <span>Name</span> <Icon icon="upDown" />
                    </th>
                    <th
                      className="title-th"
                      onClick={() => this.sortByColumn('title', 'estimators')}
                    >
                      <span>Title</span> <Icon icon="upDown" />
                    </th>
                    <th onClick={() => this.sortByColumn('teamRole', 'estimators')}>
                      <span>Team Role</span> <Icon icon="upDown" />
                    </th>
                    <th className="calendar-th" />
                    <th className="options-th" />
                  </tr>
                </thead>
                <tbody>
                  {estimators.map(member => {
                    return (
                      <TableRow
                        key={member._id}
                        profileImage={member.profileImage}
                        displayName={member.displayName}
                        title={member.title}
                        teams={member.teams}
                        optionsClick={() => optionsClick(member._id)}
                        canEdit={
                          user.teams.findIndex(team => team.name === 'Admin') > -1 ||
                          (user.teams.findIndex(team => team.name === 'Estimate') > -1 &&
                            user.teams[user.teams.findIndex(team => team.name === 'Estimate')]
                              .role === 'Manager')
                        }
                      />
                    )
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </ResizeableSector>

        <ResizeableSector
          tkey="customerService"
          size="lg"
          title="Customer Service Team"
          smallTitle={`${customerServices.length} Members`}
          color="white"
          bordered={true}
          borderColor="pink"
        >
          <div className="rs-wrapper">
            {customerServices.length < 1 ? (
              <div className="no-employees">Sorry. There are no employees on this team.</div>
            ) : (
              <StyledTable>
                <thead>
                  <tr>
                    <th
                      className="displayname-th"
                      onClick={() => this.sortByColumn('displayName', 'customerServices')}
                    >
                      <span>Name</span> <Icon icon="upDown" />
                    </th>
                    <th
                      className="title-th"
                      onClick={() => this.sortByColumn('title', 'customerServices')}
                    >
                      <span>Title</span> <Icon icon="upDown" />
                    </th>
                    <th onClick={() => this.sortByColumn('teamRole', 'customerServices')}>
                      <span>Team Role</span>
                    </th>
                    <th className="calendar-th" />
                    <th className="options-th" />
                  </tr>
                </thead>
                <tbody>
                  {customerServices.map(member => {
                    return (
                      <TableRow
                        key={member._id}
                        profileImage={member.profileImage}
                        displayName={member.displayName}
                        title={member.title}
                        teams={member.teams}
                        optionsClick={() => optionsClick(member._id)}
                        canEdit={
                          user.teams.findIndex(team => team.name === 'Admin') > -1 ||
                          (user.teams.findIndex(team => team.name === 'CustomerService') > -1 &&
                            user.teams[
                              user.teams.findIndex(team => team.name === 'CustomerService')
                            ].role === 'Manager')
                        }
                      />
                    )
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </ResizeableSector>

        <ResizeableSector
          tkey="production"
          size="lg"
          title="Production Team"
          smallTitle={`${productions.length} Members`}
          color="white"
          bordered={true}
          borderColor="pink"
        >
          <div className="rs-wrapper">
            {productions.length < 1 ? (
              <div className="no-employees">Sorry. There are no employees on this team.</div>
            ) : (
              <StyledTable>
                <thead>
                  <tr>
                    <th
                      className="displayname-th"
                      onClick={() => this.sortByColumn('displayName', 'productions')}
                    >
                      <span>Name</span> <Icon icon="upDown" />
                    </th>
                    <th
                      className="title-th"
                      onClick={() => this.sortByColumn('title', 'productions')}
                    >
                      <span>Title</span> <Icon icon="upDown" />
                    </th>
                    <th onClick={() => this.sortByColumn('teamRole', 'productions')}>
                      <span>Team Role</span>
                    </th>
                    <th className="calendar-th" />
                    <th className="options-th" />
                  </tr>
                </thead>
                <tbody>
                  {productions.map(member => {
                    return (
                      <TableRow
                        key={member._id}
                        profileImage={member.profileImage}
                        displayName={member.displayName}
                        title={member.title}
                        teams={member.teams}
                        optionsClick={() => optionsClick(member._id)}
                        canEdit={
                          user.teams.findIndex(team => team.name === 'Admin') > -1 ||
                          (user.teams.findIndex(team => team.name === 'Production') > -1 &&
                            user.teams[user.teams.findIndex(team => team.name === 'Production')]
                              .role === 'Manager')
                        }
                      />
                    )
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </ResizeableSector>
      </StyledTeamView>
    )
  }
}

const StyledTeamView = styled.div`
  .no-employees {
    padding: 1em;
  }

  .displayname-th {
    width: 30%;
  }
  .title-th {
    width: 25%;
  }
  .teamrole-th {
    width: 20%;
  }
  /* .teamname-th {
    width: 15%;
  } */
  .calendar-th {
    width: 15%;
  }
  .options-th {
    width: 10%;
  }
`

export default TeamView
