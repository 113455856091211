import React, { Component } from 'react'
import styled from 'styled-components'
// import { createPdf } from './../../../helpers/files'

import OverviewChunk from './../../estimate/shared/OverviewChunk'
import Button from './../Button'
import Loader from './../Loader'

class Estimate extends Component {
  render() {
    const { project, estimate, pdfDownloading } = this.props
    return (
      <StyledEstimate>
        <div className="actions">
          <div className="print-pdf">
            <Button
              text="Download PDF"
              variant={pdfDownloading ? 'disabled' : ''}
              handleClick={async e => await this.props.downloadPDF()}
            />
            {pdfDownloading && <Loader />}
          </div>
        </div>
        <div id="printable-estimate-history">
          <OverviewChunk project={project} estimate={estimate} />
        </div>
      </StyledEstimate>
    )
  }
}

export default Estimate

const StyledEstimate = styled.div`
  .small-padding {
    padding: 0.5em 0.5em 0;
  }

  .header {
    h2 {
      font-size: 24px;
    }
  }

  table {
    th {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .totals {
    b {
      font-size: 14px;
      font-weight: 400;
    }
    div {
      font-size: 16px;
      font-weight: 600;
    }
  }
`
