import React, { Component } from 'react'
import styled from 'styled-components'
import { getNextStep } from './../utils'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Button from './../../sharedComponents/Button'
import MapContainer from './../../sharedComponents/MapContainer'
import Checkbox from './../../sharedComponents/Checkbox'

class InstallStart extends Component {
  state = {
    atLocation: false,
  }

  // handles the checkbox to confirm at location
  handleChange = e => {
    this.setState(prevState => {
      return {
        atLocation: !prevState.atLocation,
      }
    })
  }

  render() {
    const { project } = this.props
    const { additionalClientInfo } = project
    return (
      <StyledInstallStart>
        <Header title="Time for the installation!" />
        <Container>
          <div className="ss-inner">
            <div className="ss-address">
              <h2>{additionalClientInfo.address}</h2>
              <h2>
                {additionalClientInfo.city}, {additionalClientInfo.state} {additionalClientInfo.zip}
              </h2>
            </div>

            <div className="ss-map">
              <MapContainer
                hasMarker
                additionalClientInfo={this.props.project.additionalClientInfo}
              />
            </div>

            <div className="ss-bottom">
              <Checkbox
                name="atLocation"
                value="atLocation"
                checked={this.state.atLocation ? true : false}
                size="large"
                checkmark={true}
                text="I have reached the correct location listed above."
                handleChange={this.handleChange}
              />
              <Button
                text="Begin Installation"
                variant={this.state.atLocation ? 'lg' : 'lg disabled'}
                handleClick={() =>
                  this.state.atLocation
                    ? this.props.updateInstallation(this.props.project.installation._id, {
                        installationStatus: getNextStep(this.props.installationStatus),
                        lastStatus: Date.now(),
                        projectId: project._id,
                      })
                    : null
                }
              />
            </div>
          </div>
        </Container>
      </StyledInstallStart>
    )
  }
}

export default InstallStart

const StyledInstallStart = styled.div`
  .ss-inner {
  }

  .ss-address {
    margin-bottom: 1.5em;
    h2 {
      font-size: 20px;
      margin: 0;
      color: #434b51;
      /* line-height: 1; */
    }
  }

  .ss-map {
    position: relative;
    border: 1px solid #777;
    margin-bottom: 2em;
    width: 100%;
    height: 32em;
  }

  .ss-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .check-area {
    position: relative;
    display: flex;

    > div {
      position: relative;
      top: -5px;
      width: 12.5em;
      margin-left: 1em;
    }
  }
`
