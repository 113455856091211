import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { isToday, isPast } from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { colors } from './../../../helpers/foundation'
import '../../../vendor/react-datepicker.css'
import { openLinkInNewTab, isImage } from './../../../utils'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Sector from './../../sharedComponents/Sector'
import Icon from './../../sharedComponents/Icon'
import Button from './../../sharedComponents/Button'
import PermitInspectionFail from './PermitInspectionFail'
import AttachmentList from './../../sharedComponents/AttachmentList'

class PermitInspection extends Component {
  state = {
    inspectionDate: null,
    inspectionDateScheduled: false,
    inspectionReschedule: false,
  }

  handleInputChange = (inputName, dateObj) => {
    this.setState(prevState => ({
      [inputName]: dateObj,
    }))
  }

  dateScheduled = name => {
    this.setState(prevState => ({
      [name]: true,
      inspectionReschedule: false,
    }))
  }

  // this will allow the re-opening on the calendar to re select a date
  openReschedule = (whichDate, whichReschedule) => {
    // do we want reseting 'installationDate' to reset all Locates dates?
    this.setState(prevState => ({
      [whichDate]: moment(this.props[whichDate]),
      [whichReschedule]: true,
    }))
  }

  render() {
    const { installation } = this.props.project
    const isInspectionToday = isToday(this.props.inspectionDate)
    const isInspectionInPast = isPast(this.props.inspectionDate)

    // render fail component if status is fail
    if (!!this.props.inspectionStatus && this.props.inspectionStatus === 'fail') {
      return (
        <StyledPermitInspection>
          <PermitInspectionFail {...this.props} />
        </StyledPermitInspection>
      )
    }

    return (
      <StyledPermitInspection>
        <Header title="Schedule a permit inspection for the job." />
        <Container>
          {/* 3 bangs expects false */}
          <Sector title="Inspection Date">
            <div className="time-section">
              {!!!this.props.inspectionDate || this.state.inspectionReschedule ? (
                <DatePicker
                  selected={this.state.inspectionDate}
                  onChange={date => this.handleInputChange('inspectionDate', date)}
                  filterDate={this.isWeekday}
                  minDate={moment()}
                  inline
                >
                  <div className="datepicker-children">
                    <Button
                      text="Confirm"
                      variant={`lg ${!this.state.inspectionDate && 'disabled'}`}
                      fluid={true}
                      handleClick={date => {
                        if (this.state.inspectionDate) {
                          this.setState({ inspectionReschedule: false })
                          this.props.updatePostOps(installation._id, {
                            projectId: this.props.project._id,
                            lastStatus: Date.now(),
                            from: 'Inspection',
                            inspectionDate: this.state.inspectionDate.toDate(),
                          })
                        }
                      }}
                    />
                  </div>
                </DatePicker>
              ) : (
                <div
                  className="scheduled-date"
                  onClick={() => this.openReschedule('inspectionDate', 'inspectionReschedule')}
                >
                  <Icon icon="calendar" />
                  <span>{moment(this.props.inspectionDate).format('dddd, MMM D')}</span>
                </div>
              )}
            </div>

            {this.props.inspectionDate && (
              <div className="results">
                <h3>Inspection Results</h3>
                <div className="row">
                  <Button
                    text="Pass"
                    variant={`lg ${
                      !isInspectionToday && !isInspectionInPast ? 'disabled' : 'primary'
                    }`}
                    handleClick={() => {
                      if (isInspectionToday || isInspectionInPast) {
                        this.props.updatePostOps(installation._id, {
                          projectId: this.props.project._id,
                          lastStatus: Date.now(),
                          from: 'Inspection',
                          inspectionStatus: 'pass',
                        })
                        this.props.updateInstallation(installation._id, {
                          projectId: this.props.project._id,
                          lastStatus: Date.now(),
                          installationStatus:
                            this.props.project.paymentTerms !== '100' &&
                            this.props.project.paymentTerms !== '50/50'
                              ? 'Final Payment'
                              : 'Completed',
                        })
                      }
                    }}
                  />
                  <Button
                    text="Fail"
                    variant={`lg ${
                      !isInspectionToday && !isInspectionInPast ? 'disabled' : 'danger'
                    }`}
                    handleClick={() => {
                      if (isInspectionToday || isInspectionInPast) {
                        this.props.updatePostOps(installation._id, {
                          projectId: this.props.project._id,
                          lastStatus: Date.now(),
                          from: 'Inspection',
                          inspectionStatus: 'fail',
                        })
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </Sector>

          {/* show the inspection info if there is an inspection date, no inspection status */}
          {!!this.props.inspectionDate && this.props.inspectionStatus === 'failRevert' && (
            <Sector title="Failed Inspection Info">
              <div className="sector-inner">
                <div className="section">
                  <div className="bold">Remarks</div>
                  <div className="remarks-list">
                    {!!!this.props.inspectionRemarks.length ? (
                      <div className="weak">There are no remarks.</div>
                    ) : (
                      this.props.inspectionRemarks.map(remark => {
                        return (
                          <div key={remark._id} className="remark">
                            <div className="inner">{remark.text}</div>
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
                <div className="section">
                  <div className="bold">Attachments</div>
                  <AttachmentList attachments={this.props.inspectionFiles} />
                </div>
              </div>
            </Sector>
          )}
        </Container>
      </StyledPermitInspection>
    )
  }
}

const StyledPermitInspection = styled.div`
  .datepicker-children {
    padding: 0 0.566rem 0.566rem;
    button {
      margin-bottom: 0;
    }
  }

  .scheduled-date {
    cursor: pointer;
    .icon {
      position: relative;
      bottom: -1px;
    }
    > span {
      margin-left: 0.5em;
    }
  }

  .results {
    margin-top: 4em;
  }

  textarea {
    max-width: 26.25em;
    width: 100%;
    min-height: 9em;
    height: 9em;
  }

  .error {
    font-size: 0.875em;
    color: ${colors.danger};
  }

  .bold {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1em;
    margin-top: 2em;
  }

  .sector-inner {
    max-width: 51.625em;
    width: 100%;
  }

  .remarks-list,
  .attachments-list {
    margin: 0 0 2em;

    .weak {
      color: ${colors.grey2};
    }

    .remark,
    .attachment {
      border-bottom: 1px solid ${colors.grey6};
      padding: 1em 0;

      .inner {
        position: relative;
        display: flex;
        max-width: 40.125em;
        padding-left: 0.5em;
        width: 100%;
        .download {
          top: 1px;
          margin-right: 1em;
        }
      }

      &:first-child {
        border-top: 1px solid ${colors.grey6};
      }
      &:last-child {
        /* border-bottom: 0; */
      }
    }
  }

  .attachments-list {
    /* margin-top: 2em; */
    .attachment {
      font-size: 0.875em;
      cursor: pointer;
      &:hover {
        background-color: ${colors.grey7};
      }
    }
  }
`

export default PermitInspection
