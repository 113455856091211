export function getNextStep(currentStatus, paymentTerms) {
  switch (currentStatus) {
    case 'Schedule Installation':
      // if they chose 100 for payment term, they skip this and all other payments
      if (paymentTerms !== '100') {
        return 'Installation First Payment'
      } else {
        return 'Installation Prep'
      }
    case 'Installation First Payment':
      return 'Installation Prep'
    case 'Installation Prep':
      return 'Installation Pending'
    case 'Installation Pending':
      return 'In Progress'
    case 'In Progress':
      return 'Inspection'
    case 'Inspection':
      if (paymentTerms !== '100' && paymentTerms !== '50/50') {
        return 'Final Payment'
      } else {
        return 'Completed'
      }
    default:
      return ''
  }
}
