import axios from 'axios'
import { addProductionUrl } from '../../helpers/constants'
import { addItem } from './projects'

export const ATTEMPT_CREATE_PROJECT = 'app/employeeCreateProject/ATTEMPT_CREACT_PROJECT'
export const FAILED = 'app/employeeCreateProject/FAILED'
export const SUCCESS = 'app/employeeCreateProject/SUCCESS'
export const RESET = 'app/employeeCreateProject/RESET'
export const VALIDATE_ADDRESS = 'app/employeeCreateProject/VALIDATE_ADDRESS'

const initialState = {
  error: null,
  message: null,
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ATTEMPT_CREATE_PROJECT:
      return {
        ...state,
        loading: true,
        message: null,
        error: null,
      }
    case SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: null,
      }
    case FAILED:
      return {
        ...state,
        loading: false,
        message: null,
        error: action.error,
      }
    case RESET:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export function attemptProjectCreation() {
  return {
    type: ATTEMPT_CREATE_PROJECT,
  }
}

export function projectCreationFailed(error) {
  return {
    type: FAILED,
    error,
  }
}

export function projectCreated() {
  return {
    type: SUCCESS,
  }
}

export function reset() {
  return {
    type: RESET,
  }
}

// this no longer checks if the user exists?
export function createProject(fields) {
  return async dispatch => {
    dispatch(attemptProjectCreation())

    try {
      const response = await axios.post(`${addProductionUrl()}/api/projects`, {
        ...fields,
      })

      dispatch(projectCreated())

      // Add project to projects array
      dispatch(addItem(response.data))

      return response.data
    } catch (err) {
      dispatch(projectCreationFailed(err.response.data.message))
      return false
    }
  }
}
