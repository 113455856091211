import React, { Component } from 'react'
import styled from 'styled-components'
import fenceContour from './../../assets/fence_contour.png'
import fenceNoContour from './../../assets/fence_no_contour.png'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import RadioButton from './../sharedComponents/RadioButton'
import { Collection, CollectionItem } from './../sharedComponents/layouts/Collection'
import Notice from './../sharedComponents/Notice'

const StyledSurveyContour = styled.div`
  img {
    width: 100%;
    height: auto;
    margin: 1em 0;
  }
`

class SurveyContour extends Component {
  handleChange = status => {
    const { survey } = this.props

    this.props.updateSurveyServer(survey._id, {
      contour: status,
    })
  }

  render() {
    const { survey } = this.props
    return (
      <StyledSurveyContour>
        <Header title="Will the fence be contoured?" noBorderBottom />
        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <Notice text="Customer acknowledges that contour installations will have an allowance for space under fence." />
          <Collection>
            <CollectionItem key={1} columns={2}>
              <RadioButton
                id="multiStyle-1"
                name="fenceContour"
                label="No Contour"
                value="no"
                checked={survey.contour === 'isNo' ? false : true}
                handleChange={() => this.handleChange(false)}
                fit={true}
              />
              <img src={fenceNoContour} alt="" />
            </CollectionItem>
            <CollectionItem key={2} columns={2}>
              <RadioButton
                id="multiStyle-2"
                name="fenceContour"
                label="Contour"
                value="yes"
                checked={survey.contour ? true : false}
                handleChange={() => this.handleChange(true)}
                fit={true}
              />
              <img src={fenceContour} alt="" />
            </CollectionItem>
          </Collection>
        </Container>
      </StyledSurveyContour>
    )
  }
}

export default SurveyContour
