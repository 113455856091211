import {
  LOADING,
  MODAL_LOADING,
  ERROR,
  FETCH_MEMBERS,
  CHANGE_VIEW,
  ADD_MEMBER,
  UPDATE_MEMBER,
  REMOVE_MEMBER,
} from './types'

// const fakeMembers = [
//   // admins
//   {
//     _id: '5b588c51541a463b1e73bde1',
//     role: 'Contributor',
//     title: 'CEO',
//     teamRole: 'Manager',
//     teamName: 'Admin',
//     email: 'anthony@osceolafencesupply.com',
//     displayName: 'Anthony Paradiso',
//     profileImage: 'https://randomuser.me/api/portraits/men/25.jpg',
//   },
//   {
//     _id: '5b588c51541a463b1e73bde2',
//     role: 'Contributor',
//     title: 'General Manager',
//     teamRole: 'Member',
//     teamName: 'Admin',
//     email: 'clarke@osceolafencesupply.com',
//     displayName: 'Clarke Stresing',
//     profileImage: 'https://randomuser.me/api/portraits/men/8.jpg',
//   },
//   // surveyors
//   {
//     _id: '5b588c51541a463b1e73bde3',
//     role: 'Contributor',
//     title: 'Survey Operations Director',
//     teamRole: 'Manager',
//     teamName: 'Survey',
//     email: 'mike@osceolafencesupply.com',
//     displayName: 'Michael Vick',
//     profileImage: 'https://randomuser.me/api/portraits/men/4.jpg',
//   },
//   {
//     _id: '5b588c51541a463b1e73bde4',
//     role: 'Contributor',
//     title: 'Survey Specialist',
//     teamRole: 'Member',
//     teamName: 'Survey',
//     email: 'julio@osceolafencesupply.com',
//     displayName: 'Julio Alfredo',
//     profileImage: 'https://randomuser.me/api/portraits/men/30.jpg',
//   },
//   {
//     _id: '5b588c51541a463b1e73bde5',
//     role: 'Contributor',
//     title: 'Survey Specialist',
//     teamRole: 'Member',
//     teamName: 'Survey',
//     email: 'tom@osceolafencesupply.com',
//     displayName: 'Tom Brady',
//     profileImage: 'https://randomuser.me/api/portraits/men/81.jpg',
//   },
//   // estimate
//   {
//     _id: '5b588c51541a463b1e73bde6',
//     role: 'Contributor',
//     title: 'Estimation Operations Manager',
//     teamRole: 'Manager',
//     teamName: 'Estimate',
//     email: 'michael@osceolafencesupply.com',
//     displayName: 'Michael Jordan',
//     profileImage: 'https://randomuser.me/api/portraits/men/55.jpg',
//   },
//   {
//     _id: '5b588c51541a463b1e73bde7',
//     role: 'Contributor',
//     title: 'Estimation Specialist',
//     teamRole: 'Member',
//     teamName: 'Estimate',
//     email: 'tim@osceolafencesupply.com',
//     displayName: 'Tim Duncan',
//     profileImage: 'https://randomuser.me/api/portraits/men/80.jpg',
//   },
//   {
//     _id: '5b588c51541a463b1e73bde8',
//     role: 'Contributor',
//     title: 'Estimation Specialist',
//     teamRole: 'Member',
//     teamName: 'Estimate',
//     email: 'james@osceolafencesupply.com',
//     displayName: 'James Bond',
//     profileImage: 'https://randomuser.me/api/portraits/men/57.jpg',
//   },
// ]

export const loading = bool => ({
  type: LOADING,
  payload: {
    loading: bool,
  },
})

export const modalLoading = bool => ({
  type: MODAL_LOADING,
  payload: {
    modalLoading: bool,
  },
})

export const requestError = message => ({
  type: ERROR,
  payload: {
    message,
  },
})

// how to manipulate the reducer
export const fetchMembers = members => ({
  type: FETCH_MEMBERS,
  payload: {
    members,
  },
})

export const changeView = viewName => {
  return {
    type: CHANGE_VIEW,
    payload: {
      teamView: viewName === 'team' ? true : false,
    },
  }
}

export const addMember = newMember => ({
  type: ADD_MEMBER,
  payload: {
    newMember,
  },
})

export const updateMember = updatedMember => ({
  type: UPDATE_MEMBER,
  payload: {
    updatedMember,
  },
})

export const removeMember = memberId => ({
  type: REMOVE_MEMBER,
  payload: {
    memberId,
  },
})
