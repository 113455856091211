import React from 'react'
import { hideVisually } from 'polished'
import styled from 'styled-components'
import { colors } from '../../helpers/foundation'

const Radio = styled.div`
  display: block;
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 13.125em;
  width: 100%;
  margin-right: 0.5em;
  margin-bottom: 0.5em;

  ${props => {
    if (props.fit) {
      return `
        padding: ${props.columns && '0 0.5em'};
        width: ${props.columns === 2 ? '50%' : '100%'};

        label {
          width: 100%;
        }
      `
    }
  }}

  input[type='radio'] {
    ${hideVisually()};

    &:checked ~ label {
      border-color: ${colors.grey4};
      background-color: ${colors.grey4};
      color: ${colors.primary};
    }
  }

  label {
    display: inline-block;
    color: ${colors.grey4};
    border: 1px solid ${colors.lightGrey};
    border-radius: 4px;
    outline: 0;
    padding: 1em 1em;
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.15;
    cursor: pointer;
    text-transform: capitalize;
    transition: 0.15s ease-in-out;
    text-align: center;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      border-color: ${colors.grey4};
      background-color: ${colors.grey4};
      color: ${colors.primary};
    }
  }
`

class RadioButton extends React.Component {
  render() {
    const {
      checked,
      name,
      id,
      label,
      value,
      handleClick,
      handleEventClick,
      handleChange,
      fit,
      columns,
    } = this.props

    // decide which event handler to use
    let clickEvent = null
    if (handleClick) {
      clickEvent = e => handleClick()
    } else if (handleEventClick) {
      clickEvent = e => handleEventClick(e)
    }

    return (
      <Radio fit={fit} columns={columns}>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onClick={clickEvent}
          onChange={handleChange ? () => handleChange() : null}
        />
        <label htmlFor={id}>{label}</label>
      </Radio>
    )
  }
}

export default RadioButton
