import { connect } from 'react-redux'
import Teams from './../components/pages/Teams'
import { changeView } from './../redux/modules/teams/actions'
import {
  fetchMembers,
  addMember,
  updateMember,
  removeMember,
} from './../redux/modules/teams/operations'

import { withRouter } from 'react-router-dom'

const mapStateToProps = state => {
  return {
    loading: state.teams.loading,
    modalLoading: state.teams.modalLoading,
    error: state.teams.error,
    teamView: state.teams.teamView,
    members: state.teams.members,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchMembers: () => dispatch(fetchMembers()),
    addMember: data => dispatch(addMember(data)),
    updateMember: (memberId, data) => dispatch(updateMember(memberId, data)),
    removeMember: memberId => dispatch(removeMember(memberId)),
    changeView: viewName => dispatch(changeView(viewName)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Teams)
)
