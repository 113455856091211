import React, { Component } from 'react'
import styled from 'styled-components'
import Header from './../../../sharedComponents/Header'
import History from './../../../sharedComponents/History/'

const StyledEmployeeEstimateCompleted = styled.div`
  .history-view-container {
    width: 100%;
  }
`

class EmployeeEstimateCompleted extends Component {
  render() {
    const { project } = this.props

    return (
      <StyledEmployeeEstimateCompleted>
        <Header title="Project Completed." />
        <div className="history-view-container">
          <History
            openTab={2}
            project={project}
            survey={project.survey}
            estimate={project.estimate}
            installation={null}
          />
        </div>
      </StyledEmployeeEstimateCompleted>
    )
  }
}

export default EmployeeEstimateCompleted
