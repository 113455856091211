import React, { Component } from 'react'
import Button from '../sharedComponents/Button'
import Field from '../sharedComponents/Field'

export default class ResetPasswordForm extends Component {
  state = {
    password: '',
    confirmPassword: '',
    errors: {},
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState(
      {
        errors: this.validateFields(),
      },
      () => {
        const errors = Object.keys(this.state.errors)
          .map(error => {
            return this.state.errors[error]
          })
          .filter(err => {
            return err
          })

        if (errors.length === 0 && !this.props.loading) {
          this.props.resetPassword(this.state.password)
        }
      }
    )
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  validateFields = () => {
    const { password, confirmPassword } = this.state

    const errors = {
      password: password.length < 7 ? true : false,
      confirmPassword: password !== confirmPassword ? true : false,
    }

    return errors
  }

  render() {
    const { errors, password, confirmPassword } = this.state
    const { loading } = this.props

    const passwordValid = () => {
      return password.length >= 7 && password === confirmPassword
    }

    return (
      <form onSubmit={e => this.handleSubmit(e)} noValidate>
        <fieldset>
          <Field invalid={errors.password}>
            <label htmlFor="email">Password*</label>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={this.handleChange}
            />
            <span className="error">Password must be at least 7 characters.</span>
          </Field>

          <Field invalid={errors.confirmPassword}>
            <label htmlFor="email">Confirm Password*</label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={this.handleChange}
            />
            <span className="error">Passwords must match.</span>
          </Field>
        </fieldset>

        <Button
          type="submit"
          variant={loading || !passwordValid() ? 'disabled' : ''}
          text="Confirm"
        />
      </form>
    )
  }
}
