import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { colors, breakpoints } from './../../../../../helpers/foundation'

class DateTime extends Component {
  state = {
    time: '',
  }

  componentDidMount() {
    this.setState({
      time: moment().format('h:mm a'),
    })

    this.intervalId = setInterval(() => {
      this.updateTime()
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  updateTime = () => {
    this.setState({
      time: moment().format('h:mm a'),
    })
  }

  render() {
    return (
      <StyledDateTime>
        <span className="time">{this.state.time}</span>
        <span className="date">{moment().format('dddd, MMMM Do')}</span>
        <span className="year">{moment().format('YYYY')}</span>
      </StyledDateTime>
    )
  }
}

const StyledDateTime = styled.div`
  color: ${colors.grey};
  font-size: 1rem;
  text-align: center;
  line-height: 1;

  @media screen and (min-width: ${breakpoints.small}px) {
    font-size: 2rem;
  }

  > span {
    display: block;
    margin-bottom: 20px;

    @media screen and (min-width: ${breakpoints.small}px) {
      margin-bottom: 40px;
    }
  }

  .time {
    font-size: 3.2em;
  }

  .date {
    font-size: 1.2em;
    color: ${colors.grey6};
  }

  .year {
    font-size: 0.8em;
    color: ${colors.grey6};
  }
`

export default DateTime
