import { combineReducers } from 'redux'
import {
  LOADING,
  MODAL_LOADING,
  ERROR,
  FETCH_MEMBERS,
  CHANGE_VIEW,
  ADD_MEMBER,
  UPDATE_MEMBER,
  REMOVE_MEMBER,
} from './types'

const initState = {
  loading: false,
  modalLoading: false,
  error: null,
  teamView: true,
  members: [],
}

const teamsReducer = (state = initState, action) => {
  let memberIdx
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.payload.modalLoading,
      }
    case ERROR:
      return {
        ...state,
        error: action.payload.message,
      }
    case FETCH_MEMBERS:
      return {
        ...state,
        members: [...action.payload.members],
      }
    case CHANGE_VIEW:
      return {
        ...state,
        teamView: action.payload.teamView,
      }
    case ADD_MEMBER:
      return {
        ...state,
        error: null,
        members: [...state.members, action.payload.newMember],
      }
    case UPDATE_MEMBER:
      memberIdx = state.members.findIndex(member => member._id === action.payload.updatedMember._id)
      return {
        ...state,
        error: null,
        members: [
          ...state.members.slice(0, memberIdx),
          { ...action.payload.updatedMember },
          ...state.members.slice(memberIdx + 1),
        ],
      }
    case REMOVE_MEMBER:
      memberIdx = state.members.findIndex(member => member._id === action.payload.memberId)
      return {
        ...state,
        error: null,
        members: [...state.members.slice(0, memberIdx), ...state.members.slice(memberIdx + 1)],
      }
    default:
      return state
  }
}

export default teamsReducer
