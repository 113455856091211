import actions from './actions'
import axios from 'axios'
import shortid from 'shortid'
import { addMinutes } from 'date-fns'
import selectors from './selectors'
import { employeeAddMember, updateSurvey } from '../../redux/modules/projects'
import { customerConfirmTimeSuccess } from '../../redux/modules/customer'
import { addProductionUrl } from './../../helpers/constants'

const fetchQuote = token => async dispatch => {
  try {
    // fetchQuote(<loading>, <quote>, <quoteExists>, <quoteValid>)
    dispatch(actions.fetchQuote(true, null, true, true))

    const response = await axios.get(`${addProductionUrl()}/api/quotes/${token}`)

    if (!response.data.quote) {
      // quote does not exist
      dispatch(actions.fetchQuote(false, null, false, false))
    } else if (!response.data.quote.isValid) {
      // quote exists but is invalid
      dispatch(actions.fetchQuote(false, null, true, false))
    } else {
      // quote exists and is valid
      dispatch(actions.fetchQuote(false, response.data.quote, true, true))
    }
  } catch (err) {
    if (err && err.response && err.response.data) {
      if (!err.response.data.quote) {
        // quote does not exist
        dispatch(actions.fetchQuote(false, null, false, false))
      } else if (err.response.data.quote && !err.response.data.isValid) {
        // quote exists but is invalid
        dispatch(actions.fetchQuote(false, null, true, false))
      } else {
        // something else went wrong
        dispatch(actions.error(err))
      }
    }
  }
}

const getEvents = (start, end, project) => dispatch => {
  dispatch(actions.changeWeek(start, end))

  axios
    .get(`${addProductionUrl()}/api/schedule`, {
      params: {
        start,
        end,
        address: project.additionalClientInfo.address,
        city: project.additionalClientInfo.city,
        state: project.additionalClientInfo.state,
        zip: project.additionalClientInfo.zip,
      },
    })
    .then(res => {
      dispatch(actions.eventsReceived(res.data))
      return true
    })
    .catch(err => {
      dispatch(actions.error(err))
    })
}

const confirmTime = (slot, project, slots, user) => dispatch => {
  dispatch(actions.confirmingTime())

  return axios
    .post(`${addProductionUrl()}/api/schedule`, {
      slot,
      project,
      scheduledBy: user ? user.role : 'Customer',
      projectId: project._id,
    })
    .then(async res => {
      // Add the ofs employee of the slot to the survey team
      // Wait until employee is added
      await dispatch(employeeAddMember(project._id, slot.surveyor.id, 'survey'))

      dispatch(actions.timeConfirmed())
      dispatch(updateSurvey(res.data))

      if (user.role === 'Customer') {
        dispatch(customerConfirmTimeSuccess(project._id, res.data))
      }

      return res.data
    })
    .catch(err => {
      dispatch(actions.timeConfirmedError(err))

      if (err.response.status !== 400) {
        const slotsWithoutExisting = selectors.removeSlot(slots, slot)

        // Update the state to reflect slots without the already booked slot
        dispatch(actions.eventsReceived(slotsWithoutExisting))
      }

      throw err
    })
}

const getSurveyors = () => async dispatch => {
  try {
    const response = await axios.get(`${addProductionUrl()}/api/users/surveyors`)
    const surveyorsWithCalConnected = response.data.surveyors.filter(
      surveyor => surveyor.googleCalendar.connected
    )

    return surveyorsWithCalConnected
  } catch (err) {
    dispatch(actions.error(err))
  }
}

const scheduleCustomTime = (project, surveyor, startTime, user) => async dispatch => {
  try {
    if (user.role === 'Customer') {
      return false
    }

    const { address, city, state, zip, displayName } = surveyor
    const surveyorAddress = `${address}, ${city}, ${state} ${zip}`

    // // create slot object
    const slot = {
      id: shortid.generate(),
      summary: 'Custom generated slot.',
      location: surveyorAddress,
      start: { dateTime: startTime },
      end: { dateTime: addMinutes(startTime, 30) },
      generated: false,
      custom: true,
      surveyor: {
        id: surveyor._id,
        displayName: surveyor.displayName,
      },
    }

    const response = await axios.post(`${addProductionUrl()}/api/schedule`, {
      project,
      slot,
    })

    await dispatch(employeeAddMember(project._id, slot.surveyor.id, 'survey'))

    dispatch(actions.timeConfirmed())
    dispatch(updateSurvey(response.data))

    // return true
  } catch (err) {
    dispatch(actions.customTimeError(true))
    throw err
  }
}

export default {
  getEvents,
  confirmTime,
  getSurveyors,
  scheduleCustomTime,
  fetchQuote,
}
