import React, { Fragment } from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'

const StyledTableWithBigItems = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  .row {
    display: flex;
    justify-content: space-between;
    padding: 1.25em 1.5em;
    ${'' /* border-bottom: 1px solid #979797; */}

    &:last-child {
      ${'' /* border-bottom: 0; */}
    }

    &:nth-child(even) {
      background-color: ${colors.grey5};
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .item {
      margin-right: 1.5em;
      &:last-child {
        margin-right: 0;
      }
      .title {
        font-weight: 600;
      }
      .subtitle {
        font-size: 0.875em;
        color: ${colors.grey};
      }
    }
  }
`
// expects data to be { left: [], right: [] }
// where items in the arrays are in <div className="item"></div>
// and title and subtitle within the item if needed
export const TableWithBigItems = props => {
  const { data } = props
  return (
    <StyledTableWithBigItems>
      {data.map(row => {
        return (
          <div className="row">
            <div className="left">
              {row.left.map((item, idx) => {
                return <Fragment key={idx}>{item}</Fragment>
              })}
            </div>
            <div className="right">
              {row.right.map((item, idx) => {
                return <Fragment key={idx}>{item}</Fragment>
              })}
            </div>
          </div>
        )
      })}
    </StyledTableWithBigItems>
  )
}
