import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import schedulerOperations from './../redux/modules/scheduler/operations'
import schedulerActions from './../redux/modules/scheduler/actions'
import { createProject } from './../redux/modules/createProjectForm'
import { getCustomerByEmail } from './../redux/modules/customer/'
import { login } from './../redux/modules/auth/'

import Scheduler from './../components/Scheduler2/'

const mapStateToProps = state => {
  return {
    events: state.scheduler.events,
    createProjectState: state.createProjectForm,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEvents: (start, end, project, newMonth, newYear) =>
      dispatch(schedulerOperations.getEvents(start, end, project, newMonth, newYear)),
    selectTime: slot => dispatch(schedulerActions.selectTime(slot)),
    reset: () => dispatch(schedulerActions.reset()),
    confirmTime: (slot, project, slots, user) =>
      dispatch(schedulerOperations.confirmTime(slot, project, slots, user)),
    getSurveyors: () => dispatch(schedulerOperations.getSurveyors()),
    scheduleCustomTime: (projectId, surveyor, dateTime, user) =>
      dispatch(schedulerOperations.scheduleCustomTime(projectId, surveyor, dateTime, user)),
    fetchQuote: token => dispatch(schedulerOperations.fetchQuote(token)),
    createProject: (projectDetails, customerInfo) =>
      dispatch(createProject(projectDetails, customerInfo)),
    getCustomerByEmail: email => dispatch(getCustomerByEmail(email)),
    login: (email, password) => dispatch(login(email, password)),
    getSurveyors: () => dispatch(schedulerOperations.getSurveyors()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Scheduler)
)
