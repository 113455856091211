import React, { Component } from 'react'
import axios from 'axios'
import Field from './Field'
import Button from './Button'
import Fab from './Fab'
import Icon from './Icon'
import Loader from './Loader'
import { addProductionUrl } from './../../helpers/constants'

class BugTracker extends Component {
  state = {
    open: false,
    subject: '',
    details: '',
    loading: false,
    error: null,
    success: false,
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  toggleBugTracker = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  submitBug = e => {
    e.preventDefault()

    if (this.state.subject !== '' && this.state.details !== '') {
      this.setState({
        loading: true,
      })

      axios
        .post(`${addProductionUrl()}/api/bugs`, {
          subject: this.state.subject,
          details: this.state.details,
        })
        .then(res => {
          this.setState({
            loading: false,
            success: true,
            error: false,
            subject: '',
            details: '',
          })

          setTimeout(() => {
            this.setState({
              success: false,
            })
          }, 2000)
        })
        .catch(err => {
          this.setState({
            loading: false,
            error: err,
          })
        })
    }
  }

  render() {
    return (
      <Fab open={this.state.open}>
        <div className="reveal">
          <h4 className="title">Having issues?</h4>
          <p className="small">
            Please drop us a quick note letting us know what issues you're experiencing while using
            the app. The more detail you share, the faster we can help.
          </p>

          {!this.state.loading ? (
            <form className="form" onSubmit={this.submitBug}>
              <Field>
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={this.state.subject}
                  onChange={this.handleChange}
                  required
                />
              </Field>
              <Field>
                <label htmlFor="details">Details</label>
                <textarea
                  name="details"
                  value={this.state.details}
                  placeholder="Please be as detailed as possible."
                  onChange={this.handleChange}
                  required
                />
              </Field>
              {this.state.error && (
                <div className="message -error">Something wen't wrong. Sorry about that.</div>
              )}
              {this.state.success && (
                <div className="message -success">Thanks! We'll look into that.</div>
              )}
              <Button type="submit" text="Send" />
            </form>
          ) : (
            <Loader />
          )}
        </div>

        <button className="button" onClick={this.toggleBugTracker}>
          <Icon icon="question" color="primary" />
        </button>
      </Fab>
    )
  }
}

export default BugTracker
