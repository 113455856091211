import React from 'react'
import styled from 'styled-components'
import ResizeableSector from './ResizeableSector'

import Icon from './Icon'

const OverviewContainer = props => (
  <StyledOverviewContainer>
    <div className="header">
      <h2>{props.title}</h2>
      {!props.presentational && (
        <div className="edit" onClick={e => props.changeStepPointer(e, props.step)}>
          <Icon icon="pencil" />
        </div>
      )}
    </div>

    <div>{props.children}</div>
  </StyledOverviewContainer>
)

export default OverviewContainer

const StyledOverviewContainer = styled.div`
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 2em 0 3em;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border-bottom: 1px solid #ced4da;

    h2 {
      margin: 0;
      padding: 0;
    }

    .edit {
      margin-top: 6px;
      cursor: pointer;
      svg {
        cursor: pointer;
      }
    }
  }
`
