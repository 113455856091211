import React, { Component } from 'react'
import styled from 'styled-components'
import loadImage from 'blueimp-load-image'
import { dataURLToBlob } from './../../helpers/images'
import { toTitleCase, splitStringByCapital } from './../../utils'
import Header from './../sharedComponents/Header'
import Title from './../sharedComponents/Title'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import PhotoBox from './../sharedComponents/PhotoBox'
import RadioGroup from '../sharedComponents/RadioGroup'
import RadioButton from '../sharedComponents/RadioButton'
import ImageInput from './../sharedComponents/ImageInput'
import Thumbnails from './../sharedComponents/Thumbnails'
import Loader from './../sharedComponents/Loader'
import SettingsForm from '../../containers/settingsFormContainer'

class SurveyDetails extends Component {
  state = {
    large: {
      url: null,
      name: null,
    },
    thumbnail: {
      url: null,
      name: null,
    },
    activePhoto: null,
    activeNotes: '',
    current: '',
    loading: false,
    editing: false,
    editPhotoAdded: false,
    editPhotoIdx: null,
  }

  handleResize = (image, size, current) => {
    const imageUrl = image.toDataURL('image/jpeg')

    if (image.type === 'error') {
      console.log('Error loading image')
    } else {
      this.setState(
        {
          [size]: {
            ...this.state[size],
            url: imageUrl,
          },
          current,
        },
        () => {
          if (this.state.thumbnail.url && this.state.large.url) {
            this.savePhoto()
          }
        }
      )
    }
  }

  handleFile = (e, detailKey) => {
    const file = e.target.files ? e.target.files[0] : null

    loadImage(file, image => this.handleResize(image, 'large', detailKey), {
      maxWidth: 900,
      canvas: true,
      crop: true,
      orientation: true,
    })

    loadImage(file, image => this.handleResize(image, 'thumbnail', detailKey), {
      maxWidth: 100,
      canvas: true,
      crop: true,
      orientation: true,
    })

    this.setState(prevState => ({
      large: {
        ...prevState.large,
        name: file['name'],
      },
      thumbnail: {
        ...prevState.thumbnail,
        name: file['name'],
      },
    }))
  }

  // toggle Yes/No for details
  handleClick = detailKey => {
    const { survey } = this.props
    const currentDetail = survey.details[detailKey]
    const toUpdate = `details.${detailKey}`
    this.props.updateDetailsValue(
      survey._id,
      // send to the server
      {
        [toUpdate]: {
          ...currentDetail,
          selected: !currentDetail.selected,
        },
      },
      // update UI immediately
      {
        field: detailKey,
        value: !currentDetail.selected,
      },
      'details'
    )
  }

  // toggle Yes/No for additionalDetails
  handleAdditionalClick = (e, detailKey) => {
    const { survey } = this.props
    const toUpdate = `additionalDetails.${detailKey}`
    const currentDetail = survey.additionalDetails[detailKey]

    this.props.updateDetailsValue(
      survey._id,
      // send to the server
      {
        [toUpdate]: {
          ...currentDetail,
          answer: e.target.value,
        },
      },
      // update UI immediately
      {
        field: detailKey,
        value: e.target.value,
      },
      'additionalDetails'
    )
  }

  // for typing in the notes on edit
  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => {
      return {
        [name]: value,
      }
    })
  }

  close = () => {
    this.setState(prevState => {
      return {
        activePhoto: null,
        activeNotes: null,
        large: null,
        thumbnail: null,
        current: '',
        editing: false,
        editPhotoAdded: false,
        editPhotoIdx: null,
      }
    })
  }

  saveNotes = () => {
    const { survey } = this.props
    const { current, activeNotes, editPhotoIdx } = this.state

    const data = new FormData()
    data.append('type', 'details')
    data.append('field', `${current}.images.${editPhotoIdx}.notes`)
    data.append('notes', activeNotes)

    this.setState({
      loading: true,
    })

    this.props
      .uploadDetailsPhoto(
        survey._id,
        data,
        {
          field: current,
          photoIdx: editPhotoIdx,
          data: {
            notes: activeNotes,
          },
        },
        'notes'
      )
      .then(success => {
        this.close()

        this.setState({
          loading: false,
        })
      })
  }

  savePhoto = () => {
    const { survey } = this.props
    const { large, thumbnail, current } = this.state

    this.setState(prevState => {
      return { loading: true }
    })

    const data = new FormData()

    data.append('type', 'details')
    data.append('field', `${current}.images`)

    if (thumbnail.url) {
      data.append('small', dataURLToBlob(thumbnail.url), thumbnail.name)
    }

    if (large.url) {
      data.append('large', dataURLToBlob(large.url), large.name)
    }

    // append the project, so s3 can upload it to correct dir
    data.append('projectId', this.props.survey.project._id)

    // this will update the redux state with dataURIs instead of waiting for the server to respond
    this.props
      .uploadDetailsPhoto(
        survey._id,
        data,
        {
          field: current,
          data: {
            small: thumbnail.url,
            large: large.url,
            notes: '',
          },
        },
        'upload'
      )
      .then(success => {
        this.close()

        this.setState({
          loading: false,
        })
      })
  }

  editPhoto = (photoKey, photoId) => {
    const { survey } = this.props
    const detailImages = survey.details[photoKey]['images']
    const editPhotoIdx = detailImages.findIndex(img => img._id === photoId)
    const activePhoto = detailImages[editPhotoIdx]

    this.setState(prevState => {
      return {
        activePhoto: activePhoto.large,
        activeNotes: activePhoto.notes,
        editPhotoIdx,
        current: photoKey,
        editing: true,
      }
    })
  }

  deletePhoto = () => {
    const { survey } = this.props
    const { current, editPhotoIdx } = this.state
    const detailKey = `details.${current}.images`
    const detailAllPhotos = survey['details'][current]['images']

    const serverData = {
      [detailKey]: [
        ...detailAllPhotos.slice(0, editPhotoIdx),
        ...detailAllPhotos.slice(editPhotoIdx + 1),
      ],
    }

    // this.props
    //   .updateSurveyServer(survey._id, {
    //     [detailKey]: [
    //       ...detailAllPhotos.slice(0, editPhotoIdx),
    //       ...detailAllPhotos.slice(editPhotoIdx + 1),
    //     ],
    //   })
    //   .then(() => {
    //     this.close()
    //   })

    this.props
      .uploadDetailsPhoto(
        survey._id,
        serverData,
        {
          field: current,
          photoIdx: editPhotoIdx,
          data: {},
        },
        'delete'
      )
      .then(success => {
        this.close()
      })
  }

  render() {
    const { project, survey } = this.props
    const { activePhoto, editing, current, activeNotes, loading } = this.state
    const { additionalDetails } = survey
    let count = -1,
      secondCount = -1

    const formattedDetail = toTitleCase(splitStringByCapital(current ? current : ''))

    return (
      <StyledSurveyDetails>
        <PhotoBox
          presentational={false}
          header={formattedDetail}
          imageUrl={editing ? activePhoto : null}
          messages={activeNotes}
          editing={editing}
          close={this.close}
          saveNotes={this.saveNotes}
          deletePhoto={this.deletePhoto}
          handleChange={this.handleChange}
          loading={loading}
          type="notes"
        />
        <Header title="Will extra services be necessary?" noBorderBottom />
        <Container>
          <div className="inner">
            {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
            <div className="binary-opts">
              {Object.keys(survey.details).map((detailKey, idx) => {
                const detail = survey.details[detailKey]
                // count allows them all to have unique identifiers within [name]
                count += 2
                return (
                  <div className="mbl" key={detailKey}>
                    <div className="controls">
                      <Title size="20px" tag="h3" text={detail.question} />
                      <RadioGroup>
                        <RadioButton
                          id={`multiStyle-${count}`}
                          name={`multiStyle-${idx + 1}`}
                          label="No"
                          value="no"
                          checked={detail.selected ? false : true}
                          handleClick={e => this.handleClick(detailKey)}
                          handleChange={() => false}
                          itemId={`radio-${detailKey}`}
                          variant="lg"
                        />
                        <RadioButton
                          id={`multiStyle-${count + 1}`}
                          name={`multiStyle-${idx + 1}`}
                          label="Yes"
                          value="yes"
                          checked={detail.selected ? true : false}
                          handleClick={e => this.handleClick(detailKey)}
                          handleChange={() => false}
                          itemId={`radio-${detailKey}`}
                          variant="lg"
                        />
                        <ImageInput
                          id={`imageInput-${idx}`}
                          name={detailKey}
                          handleFile={e => this.handleFile(e, detailKey)}
                          variant="lg"
                          color={detail.selected ? 'primary' : 'grey6'}
                        />
                      </RadioGroup>
                    </div>
                    <Thumbnails
                      photos={detail.images}
                      detailKey={detailKey}
                      handleClick={this.editPhoto}
                    />
                    {loading && current === detailKey && <Loader />}
                  </div>
                )
              })}
            </div>
            <div className="multi-opts">
              <div className="mbl">
                <div className="mbm">
                  <Title size="20px" tag="h3" text="Is your contact info correct?" />
                </div>

                <SettingsForm
                  quickEdit={true}
                  user={project.customer}
                  additionalClientInfo={this.props.project.additionalClientInfo}
                />
              </div>

              <div className="mbl">
                <Title size="20px" tag="h3" text={additionalDetails.permits.question} />
                <RadioGroup>
                  <RadioButton
                    id={`multiStyle-permits-1`}
                    name={`multiStyle-permits-1`}
                    label="OFS"
                    value="OFS"
                    checked={additionalDetails.permits.answer === 'OFS' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'permits')}
                    handleChange={() => false}
                    itemId={`radio-permits`}
                  />
                  <RadioButton
                    id={`multiStyle-permits-2`}
                    name={`multiStyle-permits-2`}
                    label="Homeowner/Contract"
                    value="Homeowner/Contract"
                    checked={
                      additionalDetails.permits.answer === 'Homeowner/Contract' ? true : false
                    }
                    handleEventClick={e => this.handleAdditionalClick(e, 'permits')}
                    handleChange={() => false}
                    itemId={`radio-permits`}
                  />
                  <RadioButton
                    id={`multiStyle-permits-3`}
                    name={`multiStyle-permits-3`}
                    label="N/A"
                    value="N/A"
                    checked={additionalDetails.permits.answer === 'N/A' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'permits')}
                    handleChange={() => false}
                    itemId={`radio-permits`}
                  />
                </RadioGroup>
              </div>

              <div className="mbl">
                <Title
                  size="20px"
                  tag="h3"
                  text={additionalDetails.contoursSpaceAcknowledge.question}
                />
                <RadioGroup>
                  <RadioButton
                    id={`multiStyle-contoursSpaceAcknowledge-1`}
                    name={`multiStyle-contoursSpaceAcknowledge-1`}
                    label="No"
                    value="No"
                    checked={
                      additionalDetails.contoursSpaceAcknowledge.answer === 'No' ? true : false
                    }
                    handleEventClick={e =>
                      this.handleAdditionalClick(e, 'contoursSpaceAcknowledge')
                    }
                    handleChange={() => false}
                    itemId={`radio-contoursSpaceAcknowledge`}
                  />
                  <RadioButton
                    id={`multiStyle-contoursSpaceAcknowledge-2`}
                    name={`multiStyle-contoursSpaceAcknowledge-2`}
                    label="Yes"
                    value="Yes"
                    checked={
                      additionalDetails.contoursSpaceAcknowledge.answer === 'Yes' ? true : false
                    }
                    handleEventClick={e =>
                      this.handleAdditionalClick(e, 'contoursSpaceAcknowledge')
                    }
                    handleChange={() => false}
                    itemId={`radio-contoursSpaceAcknowledge`}
                  />
                </RadioGroup>
              </div>

              <div className="mbl">
                <Title size="20px" tag="h3" text={additionalDetails.hoaAprovalRequired.question} />
                <RadioGroup>
                  <RadioButton
                    id={`multiStyle-hoaAprovalRequired-1`}
                    name={`multiStyle-hoaAprovalRequired-1`}
                    label="No"
                    value="No"
                    checked={additionalDetails.hoaAprovalRequired.answer === 'No' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'hoaAprovalRequired')}
                    handleChange={() => false}
                    itemId={`radio-hoaAprovalRequired`}
                  />
                  <RadioButton
                    id={`multiStyle-hoaAprovalRequired-2`}
                    name={`multiStyle-hoaAprovalRequired-2`}
                    label="Yes"
                    value="Yes"
                    checked={additionalDetails.hoaAprovalRequired.answer === 'Yes' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'hoaAprovalRequired')}
                    handleChange={() => false}
                    itemId={`radio-hoaAprovalRequired`}
                  />
                  <RadioButton
                    id={`multiStyle-hoaAprovalRequired-3`}
                    name={`multiStyle-hoaAprovalRequired-3`}
                    label="N/A"
                    value="N/A"
                    checked={additionalDetails.hoaAprovalRequired.answer === 'N/A' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'hoaAprovalRequired')}
                    handleChange={() => false}
                    itemId={`radio-hoaAprovalRequired`}
                  />
                </RadioGroup>
              </div>

              <div className="mbl">
                <Title size="20px" tag="h3" text={additionalDetails.waitForApproval.question} />
                <RadioGroup>
                  <RadioButton
                    id={`multiStyle-waitForApproval-1`}
                    name={`multiStyle-waitForApproval-1`}
                    label="No"
                    value="No"
                    checked={additionalDetails.waitForApproval.answer === 'No' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'waitForApproval')}
                    handleChange={() => false}
                    itemId={`radio-waitForApproval`}
                  />
                  <RadioButton
                    id={`multiStyle-waitForApproval-2`}
                    name={`multiStyle-waitForApproval-2`}
                    label="Yes"
                    value="Yes"
                    checked={additionalDetails.waitForApproval.answer === 'Yes' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'waitForApproval')}
                    handleChange={() => false}
                    itemId={`radio-waitForApproval`}
                  />
                  <RadioButton
                    id={`multiStyle-waitForApproval-3`}
                    name={`multiStyle-waitForApproval-3`}
                    label="N/A"
                    value="N/A"
                    checked={additionalDetails.waitForApproval.answer === 'N/A' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'waitForApproval')}
                    handleChange={() => false}
                    itemId={`radio-waitForApproval`}
                  />
                </RadioGroup>
              </div>

              <div className="mbl">
                <Title size="20px" tag="h3" text={additionalDetails.gateSwingCorrect.question} />
                <RadioGroup>
                  <RadioButton
                    id={`multiStyle-gateSwingCorrect-1`}
                    name={`multiStyle-gateSwingCorrect-1`}
                    label="No"
                    value="No"
                    checked={additionalDetails.gateSwingCorrect.answer === 'No' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'gateSwingCorrect')}
                    handleChange={() => false}
                    itemId={`radio-gateSwingCorrect`}
                  />
                  <RadioButton
                    id={`multiStyle-gateSwingCorrect-2`}
                    name={`multiStyle-gateSwingCorrect-2`}
                    label="Yes"
                    value="Yes"
                    checked={additionalDetails.gateSwingCorrect.answer === 'Yes' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'gateSwingCorrect')}
                    handleChange={() => false}
                    itemId={`radio-gateSwingCorrect`}
                  />
                  <RadioButton
                    id={`multiStyle-gateSwingCorrect-3`}
                    name={`multiStyle-gateSwingCorrect-3`}
                    label="N/A"
                    value="N/A"
                    checked={additionalDetails.gateSwingCorrect.answer === 'N/A' ? true : false}
                    handleEventClick={e => this.handleAdditionalClick(e, 'gateSwingCorrect')}
                    handleChange={() => false}
                    itemId={`radio-gateSwingCorrect`}
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        </Container>
      </StyledSurveyDetails>
    )
  }
}

export default SurveyDetails

const StyledSurveyDetails = styled.div`
  .inner {
    max-width: 50em;
  }
  .multi-opts {
    border-top: 1px solid #eee;
    padding-top: 4em;
  }

  .contact-info {
    margin: 2.5em 0 1.5em;
    .info {
      margin-bottom: 0.5em;
      .sub {
        font-size: 0.875em;
      }
      .main {
        font-weight: 600;
      }
    }
  }
`
