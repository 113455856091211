import React, { Component } from 'react'
import isEmail from 'validator/lib/isEmail'
import Button from '../sharedComponents/Button'
import Field from '../sharedComponents/Field'

export default class ForgotPasswordForm extends Component {
  state = {
    email: '',
    errors: {},
  }

  handleSubmit = e => {
    e.preventDefault()

    const email = this.state.email

    this.setState(
      {
        errors: this.validateFields(email),
      },
      () => {
        const errors = Object.keys(this.state.errors)
          .map(error => {
            return this.state.errors[error]
          })
          .filter(err => {
            return err
          })

        if (errors.length === 0 && !this.props.loading) {
          this.props.requestPasswordReset(email)
        }
      }
    )
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  validateFields = email => {
    const errors = {
      email: !isEmail(email) ? true : false,
    }

    return errors
  }

  render() {
    const { errors, email } = this.state
    const { loading } = this.props

    return (
      <form onSubmit={e => this.handleSubmit(e)} noValidate>
        <fieldset>
          <Field invalid={errors.email}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={this.handleChange}
            />
            <span className="error">Please enter a valid email.</span>
          </Field>
        </fieldset>

        <Button
          type="submit"
          variant={loading || !isEmail(email) ? 'disabled' : ''}
          text="Send it"
        />
      </form>
    )
  }
}
