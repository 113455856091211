import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { colors } from './../../../helpers/foundation'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import Sector from './../../sharedComponents/Sector'
import Icon from './../../sharedComponents/Icon'

const InstallationPrep = props => {
  const { installation } = props.project

  return (
    <StyledInstallationPrep>
      <Header title="Your utility marking date is being scheduled." />
      <Container>
        <Notice text="Your priliminary installation date and utility markings have been scheduled! Our team will begin preparing the materials necessary for your installation." />
        <Sector title="Installation Date">
          <h2 className="install-date">
            {installation.installationDate
              ? format(installation.installationDate, 'dddd, MMM Do')
              : 'Date has not been selected yet.'}
          </h2>
          <div className="para">Your installation date is subject to change.</div>
          <div className="para">
            Outside factors such as weather may influence your installation date. We will be in
            contact with you on the days leading up to your installation to confirm the final plans.
          </div>
        </Sector>

        <Sector title="Utility Locates">
          <SectorItems>
            <SectorItem>
              <div className="flex">
                <div className="name">Water</div>
                {!installation.waterDate ? (
                  <div className="status">
                    <Icon icon="close" />
                    <span>Not Scheduled</span>
                  </div>
                ) : (
                  <div className="status">
                    <Icon icon="solidCheck" color="primary" />
                    <span>Scheduled</span>
                  </div>
                )}

                {installation.waterDate && (
                  <div className="scheduled-date">
                    <Icon icon="calendar" />
                    <span>{format(installation.waterDate, 'dddd, MMM Do')}</span>
                  </div>
                )}
              </div>
            </SectorItem>

            <SectorItem>
              <div className="flex">
                <div className="name">Electric</div>
                {!installation.electricDate ? (
                  <div className="status">
                    <Icon icon="close" />
                    <span>Not Scheduled</span>
                  </div>
                ) : (
                  <div className="status">
                    <Icon icon="solidCheck" color="primary" />
                    <span>Scheduled</span>
                  </div>
                )}

                {installation.electricDate && (
                  <div className="scheduled-date">
                    <Icon icon="calendar" />
                    <span>{format(installation.electricDate, 'dddd, MMM Do')}</span>
                  </div>
                )}
              </div>
            </SectorItem>

            <SectorItem>
              <div className="flex">
                <div className="name">Gas</div>
                {!installation.gasDate ? (
                  <div className="status">
                    <Icon icon="close" />
                    <span>Not Scheduled</span>
                  </div>
                ) : (
                  <div className="status">
                    <Icon icon="solidCheck" color="primary" />
                    <span>Scheduled</span>
                  </div>
                )}

                {installation.gasDate && (
                  <div className="scheduled-date">
                    <Icon icon="calendar" />
                    <span>{format(installation.gasDate, 'dddd, MMM Do')}</span>
                  </div>
                )}
              </div>
            </SectorItem>
          </SectorItems>
        </Sector>
      </Container>
    </StyledInstallationPrep>
  )
}

const StyledInstallationPrep = styled.div`
  .install-date {
    margin-bottom: 1.25em;
  }
  .para {
    max-width: 40em;
  }
`

const SectorItems = styled.div`
  width: 42em;
  margin-top: 1em;
  padding-bottom: 1em;
`

const SectorItem = styled.div`
  margin-bottom: ${props => props.installationScheduled && '1em'};

  .flex {
    display: flex;
    border-bottom: 1px solid ${colors.grey6};
    padding: 0.5em 0;
    > div {
      width: 33.3333333333333%;
    }
  }

  .time-section {
    padding-top: 1em;
  }

  .name {
    /* font-weight: 600; */
  }

  .status,
  .scheduled-date {
    position: relative;

    > span {
      margin-left: 0.5em;
    }
  }

  .status {
    .icon {
      position: relative;
      bottom: 1px;
    }
  }

  .scheduled-date {
    .icon {
      position: relative;
      bottom: -1px;
    }
  }
`

export default InstallationPrep
