import axios from 'axios'
import decode from 'jwt-decode'

export function getToken() {
  return localStorage.getItem('token')
}

export function setToken(token) {
  return localStorage.setItem('token', token)
}

export function removeToken() {
  return localStorage.removeItem('token')
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token)
    if (decoded.exp < Date.now() / 1000) {
      return true
    } else {
      return false
    }
  } catch (err) {
    return false
  }
}

export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

export function canAccess(project, user) {
  // allow admins all access
  if (user.role === 'Admin' || user.teams.findIndex(team => team.name === 'Admin') > -1) {
    return true
  }

  if (project.stage === 'survey') {
    if (
      ~user.teams.findIndex(team => team.name === 'Survey') &&
      user.teams[user.teams.findIndex(team => team.name === 'Survey')].role === 'Manager'
    ) {
      // let manager access project reguardless
      return true
    } else if (
      ~user.teams.findIndex(team => team.name === 'Survey') &&
      user.teams[user.teams.findIndex(team => team.name === 'Survey')].role === 'Member' &&
      ~project.members.survey.findIndex(
        member =>
          member._id === user._id || member._id === user || member === user._id || member === user
      )
    ) {
      // if member, make sure they are in project.members.survey
      return true
    } else {
      return false
    }
  }

  if (project.stage === 'estimate') {
    if (~user.teams.findIndex(team => team.name === 'Estimate')) {
      return true
    } else {
      return false
    }
  }

  return false
}

export const isOnTeam = (user, team) => {
  const teams = user.teams.filter(item => {
    return item.name === team
  })

  if (teams.length > 0) {
    return true
  }
}

export const isUserAdmin = user => {
  if (!user) {
    return false
  }
  if (user.role && user.role === 'Admin') {
    return true
  }
  if (user.teams && ~user.teams.findIndex(team => team.name === 'Admin')) {
    return true
  }
  return false
}

export const isUserSurveyor = (teams = []) => {
  const surveyTeam = teams.filter(team => {
    return team.name === 'Survey'
  })

  if (surveyTeam.length > 0) {
    return true
  }
}
