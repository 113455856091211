export const LOADING = 'app/installation/LOADING'
export const ERROR = 'app/installation/ERROR'
export const UPDATE_INSTALLATION = 'app/installation/UPDATE_INSTALLATION'
export const UPDATE_NESTED_INSTALLATION = 'app/installation/UPDATE_NESTED_INSTALLATION'
export const ADD_PHOTO_COMMENT = 'app/installation/ADD_PHOTO_COMMENT'
export const UPDATE_POSTOPS = 'app/installation/UPDATE_POSTOPS'
export const ADD_INSPECTION_REMARK = 'app/installation/ADD_INSPECTION_REMARK'
export const ADD_INSPECTION_FILE = 'app/installation/ADD_INSPECTION_FILE'
export const FETCH_INSTALLATION = 'app/installation/FETCH_INSTALLATION'
export const FETCH_POSTOPS = 'app/installation/FETCH_POSTOPS'
export const INSPECTION_FILE_LOADING = 'app/installation/INSPECTION_FILE_LOADING'
