import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { darken, hideVisually } from 'polished'
import { colors, breakpoints } from './../../helpers/foundation'

const StyledTabs = styled.div`
  display: inline-flex;
  border: 1px solid ${colors.lightGrey};
  > div,
  > a {
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
      border-right: 0;
    }
  }
`

const StyledTab = styled.div`
  padding: 20px 70px;
  cursor: pointer;
  font-weight: 600;

  ${props => {
    const state = {
      dark: {
        default: colors.grey2,
        active: colors.grey3,
      },
    }
    if (props.tabStyle === 'dark') {
      return `
        background-color: ${
          props.active ? state[props.tabStyle].active : state[props.tabStyle].default
        };
        color: #fff;
        &:hover,
        &.active {
          background-color: ${
            props.active
              ? darken(0.05, state[props.tabStyle].active)
              : darken(0.05, state[props.tabStyle].default)
          }
        }
      `
    }
    return '#fff'
  }}
`

const StyledLinkTab = styled(NavLink)`
  display: flex;
  padding: 20px 60px;
  cursor: pointer;
  background-color: #fafafa;
  border-right: 1px solid ${colors.lightGrey};

  .icon {
    margin-right: 10px;

    svg {
      margin-bottom: -4px;
    }
  }

  @media screen and (max-width: 1270px) {
    padding: 0.75em 1em;
    .text {
      ${hideVisually()};
      display: none;
    }

    .icon {
      margin-right: 0;
    }
  }

  ${props => {
    const state = {
      survey: {
        default: colors.blue,
        active: colors.blue,
      },
      estimate: {
        default: colors.purple,
        active: colors.purple,
      },
      paperwork: {
        default: colors.orange,
        active: colors.orange,
      },
      installation: {
        default: colors.pink,
        active: colors.pink,
      },
    }

    return `
      color: ${colors[props.color]};
      &.active,
      &:hover,
      &:active,
      &:focus {
        color: #fff;
        background-color: ${colors[props.color]};
      }
    `
  }}
`

export default props => {
  return (
    <StyledTabs {...props}>
      {props.tabs.map((tab, idx) => {
        if (props.type === 'link') {
          {
            /* route changing tab */
          }
          return (
            <StyledLinkTab
              key={idx}
              to={tab.link}
              color={tab.color}
              onClick={e =>
                props.sideEffectHandleClick ? props.sideEffectHandleClick(e) : undefined
              }
            >
              {tab.icon && <div className="icon">{tab.icon}</div>}
              <div className="text">{tab.name}</div>
            </StyledLinkTab>
          )
        } else {
          {
            /* non route changing */
          }
          return (
            <StyledTab
              tabStyle={props.tabStyle}
              active={tab.active}
              key={idx}
              onClick={e => props.tabsHandleClick(tab.name)}
            >
              {tab.name}
            </StyledTab>
          )
        }
      })}
    </StyledTabs>
  )
}
