import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Fuse from 'fuse.js'
import { debounce } from '../../utils'

import Icon from './../sharedComponents/Icon'
import { colors } from './../../helpers/foundation'

const SEARCH_TIMEOUT = 500 // milliseconds

let timeout = null

const Search = props => {
  const [value, setValue] = useState('')
  const isInitialMount = useRef(true)

  useEffect(() => {
    // this is used to not run when component is initially mounted
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (value === '') {
        // if backspaced to empty string
        clearTimeout(timeout)
        timeout = setTimeout(() => props.handleChange(props.items, props.quotes), SEARCH_TIMEOUT)
      } else {
        const options = {
          shouldSort: false,
          threshold: 0.2,
          location: 0,
          distance: 30,
          maxPatternLength: 32,
          minMatchCharLength: 4,
          keys: props.properties,
        }
        const projectsFuse = new Fuse(props.items, options)
        const projectsResults = projectsFuse.search(value)

        // conditionally use quotes, if not on survey no need to do this
        let quotesFuse = null
        let quotesResults = null
        if (props.quotes) {
          quotesFuse = new Fuse(props.quotes, options)
          quotesResults = quotesFuse.search(value)
        }

        // debounce the request so it only happens X seconds after finishing typing
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          props.handleChange(projectsResults, quotesResults)
        }, SEARCH_TIMEOUT)
      }
    }
  }, [value])

  return (
    <StyledSearch onSubmit={e => e.preventDefault()}>
      <input
        type="text"
        className="field"
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder="Search"
      />
      <button className="icon" type="submit">
        <Icon icon="search" />
      </button>
    </StyledSearch>
  )
}

const StyledSearch = styled.form`
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;

  > .field {
    background-color: ${colors.white};
    border-radius: 4px;
    font-size: 14px;
    flex: 1 1 auto;
    width: 100%;
    padding: 0.5em;
    border: 1px solid ${colors.grey6};
    outline: none;
    transition: 0.2s ease-out;

    &:focus {
      border-color: ${colors.primary};

      + .icon {
        g {
          stroke: ${colors.primary};
        }
      }
    }
  }

  > .icon {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: absolute;
    right: 0.5em;
    z-index: 2;

    g {
      transition: 0.2s ease-out;
    }
  }
`

export default Search
