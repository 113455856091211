import { combineReducers } from 'redux'
import { produce } from 'immer'
import { LOADING, ERROR, LOAD_GLOBAL_SETTINGS, UPDATE_TIMER, UPDATE_SURVEY_DT } from './types'

const initState = {
  loading: false,
  error: null,
  settingsLoaded: false,
  globalSettings: {
    surveyDt: 20,

    surveyTimers: {
      inProgress: {},
      toRevise: {},
      toReview: {},
    },
    estimateTimers: {
      toDo: {},
      inProgress: {},
      toRevise: {},
      toReview: {},
    },
    installationTimers: {
      scheduleInstallation: {},
      installationPrep: {},
      inProgress: {},
      inspection: {},
    },
  },
}

const globalSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING:
      return produce(state, draftState => {
        draftState.loading = action.payload.loading
      })
    case ERROR:
      return produce(state, draftState => {
        draftState.error = action.payload.error
        draftState.settingsLoaded = false
      })
    case LOAD_GLOBAL_SETTINGS:
      return produce(state, draftState => {
        draftState.globalSettings = { ...action.payload.globalSettings }
        draftState.settingsLoaded = true
      })
    case UPDATE_TIMER:
      return produce(state, draftState => {
        draftState.globalSettings[action.payload.timerGroup][action.payload.timerName] =
          action.payload.updatedTime
      })
    case UPDATE_SURVEY_DT:
      return produce(state, draftState => {
        draftState.globalSettings.surveyDt = action.payload.newTime
      })
    default:
      return state
  }
}

export default globalSettingsReducer
