import React, { Fragment, PureComponent } from 'react'
import { blobToDataURL } from './../../helpers/images'
import { PoseGroup } from 'react-pose'
import {
  openLinkInNewTab,
  download,
  isURL,
  downloadURL,
  downloadDataURI,
  isImage,
} from './../../utils'
import { regexes } from './../../helpers/foundation'

import ResizeableSector from './../sharedComponents/ResizeableSector'
import StyledTable from './../sharedComponents/StyledTable'
import Button from './../sharedComponents/Button'
import SecondaryButton from './../sharedComponents/SecondaryButton'
import Icon from './../sharedComponents/Icon'
import Reveal from './../sharedComponents/Reveal'
import Loader from './../sharedComponents/Loader'

class FilesTable extends PureComponent {
  // wanted to have each indv table contain its own state for menuOpen,
  // so when opening a menu it doesnt have to re-render everything, couldnt get it to work
  state = {
    hoaEmailText: '',
    hoaEmailValid: false,
  }

  handleFile = (e, fileid, whichArr) => {
    const file = e.target.files[0]
    this.props.fileUploading(fileid, true)
    blobToDataURL(file, dataUrl => {
      this.props
        .updateFile(
          this.props.projectId,
          this.props.paperworkId,
          fileid,
          { filename: file.name, filepath: dataUrl },
          whichArr
        )
        .then(success => this.props.fileUploading(fileid, false))
    })
  }

  fileMetaComponent = file => {
    return (
      <React.Fragment>
        {file.menuOpen && (
          <div className="menu">
            <PoseGroup>
              <Reveal key={1} orientation="left">
                <div className="inner" ref={node => (this.reveal = node)}>
                  <div className="menu-section">
                    {/* remove ability to 'approve' if it is already approved */}
                    {file.status !== 'approved' && file.filepath && (
                      <div
                        className="item"
                        onClick={() => {
                          this.props.toggleOpenMenu(file.id)
                          this.props
                            .updateFile(
                              this.props.projectId,
                              this.props.paperworkId,
                              file.id,
                              { status: 'approved' },
                              file.from
                            )
                            .then(success => true)
                        }}
                      >
                        <Icon icon="solidCheck" color="primary" />
                        <span>Mark as Approved</span>
                      </div>
                    )}
                    {/* remove ability to 'revise' if it is already revise */}
                    {file.status !== 'revise' && file.filepath && (
                      <div
                        className="item"
                        onClick={() => {
                          this.props.toggleOpenMenu(file.id)
                          this.props.openModal(file.from, 'revisionNotes', {
                            projectId: this.props.projectId,
                            paperworkId: this.props.paperworkId,
                            fileId: file.id,
                            status: { status: 'revise' },
                            from: file.from,
                          })
                        }}
                      >
                        <Icon icon="spinnerCounterClockwise" color="danger" />
                        <span>Mark to Revise</span>
                      </div>
                    )}
                  </div>

                  {/* remove ability to remove/replace file if it has been approved */}
                  {file.status !== 'approved' && (
                    <div className="menu-section">
                      <div
                        className="item"
                        onClick={e => {
                          this.props.toggleOpenMenu(file.id)
                          this.props.removeFile(this.props.paperworkId, file.id, file.from)
                        }}
                      >
                        <Icon icon="close" />
                        <span>Delete Row</span>
                      </div>

                      {file.filepath && (
                        <div
                          className="item"
                          onClick={e => {
                            this.props.toggleOpenMenu(file.id)
                            this.uploadNewFile(file)
                          }}
                        >
                          <Icon icon="arrowU" />
                          <span>Replace File</span>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="menu-section">
                    <div
                      className="item"
                      onClick={() => {
                        this.props.toggleOpenMenu(file.id)
                        // download the URI if it has recently been uploaded & data uri is saved
                        // in redux state, otherwise just download the file from AWS url
                        isURL(file.filepath)
                          ? downloadURL(file.filepath, file.filename)
                          : downloadDataURI(file.filepath, file.filename)
                      }}
                    >
                      <Icon icon="download" />
                      <span>Download File</span>
                    </div>
                  </div>
                </div>
              </Reveal>
            </PoseGroup>
          </div>
        )}
        <div className="dots" onClick={e => this.props.toggleOpenMenu(file.id)}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
        <div
          className="view-icon"
          onClick={e => {
            console.log('is img', isImage(file.filepath))
            openLinkInNewTab(file.filepath, isImage(file.filepath))
          }}
        >
          <Icon icon="eyeBall" />
        </div>
        <div className="filename">{file.filename}</div>
      </React.Fragment>
    )
  }

  uploadNewFile = file => {
    // create the input element
    const input = document.createElement('input')
    input.type = 'file'
    input.name = file.name
    input.classList.add('hidden-input')

    // add handler to handle the file and then remove the handler
    const handler = e => {
      this.handleFile(e, file.id, file.from)
      input.removeEventListener('change', handler, false)
    }
    input.addEventListener('change', handler, false)

    // add element to body, simulate click, then remove element from body
    document.body.appendChild(input)
    input.click()
    document.body.removeChild(input)
  }

  getStatusIcon = file => {
    if (file.status === 'approved') {
      return <Icon icon="solidCheck" color="primary" />
    } else if (file.status === 'revise') {
      return (
        <span
          className="revise-icon"
          onClick={e =>
            this.props.openModal(this.props.tkey, 'viewRevisionNotes', {
              projectId: this.props.projectId,
              paperworkId: this.props.paperworkId,
              fileId: file.id,
              status: { status: 'revise' },
              from: file.from,
              revisionNotes: file.revisionNotes,
            })
          }
        >
          <Icon icon="spinnerCounterClockwise" color="danger" />
        </span>
      )
    } else {
      return ''
    }
  }

  conditionalFileTd = file => {
    if (file.uploading) {
      return (
        <td>
          <Loader width="19" small={true} />
        </td>
      )
    } else if (file.filepath) {
      return <td className="flex-td">{this.fileMetaComponent(file)}</td>
    } else {
      return (
        <td className="flex-td">
          {/* if the file is from addtionalFiles, allow there to be a context menu that only contains 'Delete Row' */}
          {file.from === 'additionalFiles' && (
            <React.Fragment>
              <div className="dots ml" onClick={e => this.props.toggleOpenMenu(file.id)}>
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
              {file.menuOpen && (
                <div className="menu pl">
                  <PoseGroup>
                    <Reveal key={1} orientation="left">
                      <div className="inner" ref={node => (this.reveal = node)}>
                        <div className="menu-section">
                          <div
                            className="item"
                            onClick={e => {
                              this.props.toggleOpenMenu(file.id)
                              this.props.removeFile(this.props.paperworkId, file.id, file.from)
                            }}
                          >
                            <Icon icon="close" />
                            <span>Delete Row</span>
                          </div>
                        </div>
                      </div>
                    </Reveal>
                  </PoseGroup>
                </div>
              )}
            </React.Fragment>
          )}
          <div className="button-margin">
            <SecondaryButton tag="label" name={file.name}>
              <span>Choose File...</span>
              <input
                type="file"
                name={file.name}
                onChange={e => this.handleFile(e, file.id, file.from)}
              />
            </SecondaryButton>
          </div>
        </td>
      )
    }
  }

  getButtonState = () => {
    const { tkey, readyAndEmailSent, ready } = this.props
    if (tkey === 'hoa') {
      if (ready) {
        return 'primary'
      } else {
        return 'disabled'
      }
    }
    if (tkey === 'permit') {
      if (ready) {
        return 'primary'
      } else {
        return 'disabled'
      }
    }
  }

  render() {
    const { files, loading, ready, readyAndEmailSent, title, tkey, status } = this.props
    // choose which modal to open based upon the current status
    let modalToOpen
    if (status === 'To Send') {
      modalToOpen = 'confirmSend'
    } else if (status === 'Pending Approval') {
      modalToOpen = 'confirmApprove'
    }

    return (
      <ResizeableSector
        tkey={tkey}
        size="sm"
        padding="0.75em"
        title={title}
        color="white"
        bordered={true}
        noResize={true}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="rs-wrapper">
            <StyledTable>
              <thead>
                <tr>
                  <th className="filetype-th">File Type</th>
                  <th className="ourfiles-th">Our Files</th>
                  <th className="customerfiles-th">Customer Files</th>
                  <th className="status-th">Status</th>
                </tr>
              </thead>
              <tbody>
                {files.map(file => {
                  return (
                    <tr key={file.id}>
                      <td>{file.name}</td>
                      {file.responsible === 'ofs' ? (
                        this.conditionalFileTd(file)
                      ) : (
                        <td>
                          <span className="grey">N/A</span>
                        </td>
                      )}
                      {file.responsible === 'customer' ? (
                        this.conditionalFileTd(file)
                      ) : (
                        <td>
                          <span className="grey">N/A</span>
                        </td>
                      )}
                      <td>{this.getStatusIcon(file)}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan="100">
                    <SecondaryButton
                      tag="label"
                      name="addNewFile"
                      handleClick={e => this.props.openModal(tkey, 'newFile')}
                    >
                      <Icon icon="add" fill="transparent" />
                      <span>Add New File</span>
                    </SecondaryButton>
                  </td>
                </tr>
              </tbody>
            </StyledTable>
            <div className="action">
              {status === 'To Send' && (
                <Fragment>
                  <div className="action-row">
                    <div className="send-btn">
                      <Button
                        // variant={`lg ${tkey === 'hoa' && readyAndEmailSent ? '' : 'disabled'}`}
                        variant={`lg ${this.getButtonState()}`}
                        text={tkey === 'hoa' ? 'Send for HOA Approval' : 'Sent for Permit Approval'}
                        handleClick={e => {
                          if (tkey === 'hoa') {
                            if (ready) {
                              this.props.openModal(tkey, modalToOpen)
                            }
                          }
                          if (tkey === 'permit') {
                            if (ready) {
                              this.props.openModal(tkey, modalToOpen)
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              )}

              {status === 'Pending Approval' && (
                <Button
                  variant={`lg ${ready ? '' : 'disabled'}`}
                  text={`Mark as "${tkey === 'hoa' ? 'HOA Approved' : 'Permits Approved'}"`}
                  handleClick={e => (ready ? this.props.openModal(tkey, modalToOpen) : null)}
                />
              )}

              {status === 'Approved' && (
                <div className="check">
                  <Icon icon="solidCheck" color="primary" />
                </div>
              )}
            </div>
          </div>
        )}
      </ResizeableSector>
    )
  }
}

export default FilesTable
