import {
  ERROR,
  LOADING,
  MARK_NOTIFICATIONS_LOADED,
  TOGGLE_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
} from './types'
import { produce } from 'immer'

const initState = {
  error: null,
  loading: false,
  open: false,
  notifications: [],
  notificationsLoaded: false,
}

const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case ERROR:
      return produce(state, draftState => {
        draftState.error = action.payload.error
      })
    case LOADING:
      return produce(state, draftState => {
        draftState.loading = action.payload.loading
      })
    case MARK_NOTIFICATIONS_LOADED:
      return produce(state, draftState => {
        draftState.notificationsLoaded = true
      })
    case TOGGLE_NOTIFICATIONS:
      return produce(state, draftState => {
        draftState.open = action.payload.bool === null ? !state.open : action.payload.bool
      })
    case SET_NOTIFICATIONS:
      return produce(state, draftState => {
        draftState.notifications = action.payload.notifications
      })
    case ADD_NOTIFICATIONS:
      return produce(state, draftState => {
        draftState.notifications = [...action.payload.notifications, ...state.notifications]
      })
    default:
      return state
  }
}

export default notificationReducer
