import { connect } from 'react-redux'
import CreateProjectForm from '../components/sharedComponents/CreateProjectForm'
import { createProject } from '../redux/modules/createProjectForm'

const mapStateToProps = state => {
  return {
    loading: state.createProjectForm.loading,
    error: state.createProjectForm.error,
    message: state.createProjectForm.message,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createProject: (fields, customer) => dispatch(createProject(fields, customer)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectForm)
