import styled from 'styled-components'
import { colors } from '../../../../helpers/foundation'

const Tool = styled.button`
  background-color: ${props => (props.active ? colors.white : colors.lightGrey)};
  border: 0;
  color: ${props => props.color};
  display: inline-block;
  flex: 1 1 auto;
  font-size: 2em;
  padding: 0 0.5em;
  height: 56px;
  outline: none;
  user-select: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`

export default Tool
