import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { colors } from './../../../helpers/foundation'

import Card from './../Card'
import Tag from './../Tag'
import ResizeableSector from './../ResizeableSector'
import MapContainer from './../MapContainer'
import Loader from './../Loader'
import Actions from './Actions'

const Overview = props => {
  const { project } = props
  const { address, city, state, zip } = project.additionalClientInfo
  const date = moment(project.createdAt).format('MMMM D')
  return (
    <StyledOverview>
      {/* <div className="header">
        <Card variant="blend">
          <h2 className="title">{project.name}</h2>
          <p className="info">
            {address} <br />
            {city}, {state} {zip}
          </p>
          <Tag color={colors.blue}>{date}</Tag>
        </Card>
      </div> */}

      {project.customer && (
        <ResizeableSector color="white" title="Client Information">
          <div className="small-padding">
            <table>
              <thead />
              <tbody>
                <tr>
                  <td className="question">Name</td>
                  <td className="answer">{project.customer.displayName}</td>
                </tr>
                <tr>
                  <td className="question">Project Address</td>
                  <td className="answer">
                    {address} <br /> {city}, {state} {zip}
                  </td>
                </tr>
                <tr>
                  <td className="question">Phone</td>
                  <td className="answer">{project.customer.phone}</td>
                </tr>
                <tr>
                  <td className="question">Email</td>
                  <td className="answer">{project.customer.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </ResizeableSector>
      )}

      <div className="o-map">
        <MapContainer hasMarker additionalClientInfo={project.additionalClientInfo} />
      </div>

      <ResizeableSector color="white" title="Recent Activities">
        {props.projectActionsLoading ? <Loader /> : <Actions actions={props.projectActions} />}
      </ResizeableSector>
    </StyledOverview>
  )
}

export default Overview

const StyledOverview = styled.div`
  position: relative;
  .content {
    padding: 0;
  }

  .header {
    margin: 2em 0;
  }

  table {
    /* border-spacing: 10px; */
    td {
      padding: 0 2em 0.5em 0;
      &.question {
        font-weight: 600;
        vertical-align: top;
      }
      &.answer {
      }
    }
  }

  .o-map {
    position: relative;
    margin: 3em 0 1em;
    width: 100%;
    height: 26em;
    border: 1px solid ${colors.grey6};
  }
`
