import React, { Component } from 'react'
import styled from 'styled-components'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Button from './../sharedComponents/Button-NEW'
import MapContainer from './../sharedComponents/MapContainer'
import Checkbox from './../sharedComponents/Checkbox'

class SurveyStart extends Component {
  state = {
    atLocation: false,
  }

  startSurvey = () => {
    const { project, survey } = this.props
    if (!this.state.atLocation) {
      return false
    }
    this.props
      .updateSurveyServer(
        survey._id,
        {
          status: 'In Progress',
          currentStep: 1,
          projectId: project._id,
          lastStatus: Date.now(),
        },
        true, // update the project along with the survey
        project._id
      )
      .then(success => console.log('updated survey'))
  }

  // handles the checkbox to confirm at location
  handleChange = e => {
    this.setState(prevState => {
      return {
        atLocation: !prevState.atLocation,
      }
    })
  }

  render() {
    const { project } = this.props
    const { additionalClientInfo } = project
    return (
      <StyledSurveyStart>
        <Header title="Ready to begin Survey?" noBorderBottom={true} />
        <Container>
          <div className="ss-inner">
            <div className="ss-address">
              <div className="street">{additionalClientInfo.address}</div>
              <div className="city-state-zip">
                {additionalClientInfo.city}, {additionalClientInfo.state} {additionalClientInfo.zip}
              </div>
            </div>

            <div className="ss-map">
              <MapContainer
                hasMarker
                additionalClientInfo={this.props.project.additionalClientInfo}
              />
            </div>

            <div className="ss-bottom">
              <Checkbox
                name="atLocation"
                value="atLocation"
                checked={this.state.atLocation ? true : false}
                text="I have reached the correct location listed above."
                size="large"
                checkmark={true}
                handleChange={this.handleChange}
              />
              <Button
                text="Begin Survey"
                variant={this.state.atLocation ? 'lg dynamic' : 'lg dynamic disabled'}
                handleClick={this.startSurvey}
              />
            </div>
          </div>
        </Container>
      </StyledSurveyStart>
    )
  }
}

export default SurveyStart

const StyledSurveyStart = styled.div`
  .ss-address {
    margin-bottom: 2em;

    .street,
    .city-state-zip {
      font-size: 20px;
      margin: 0;
      color: #434b51;
    }
  }

  .ss-map {
    position: relative;
    border: 1px solid #777;
    margin-bottom: 2em;
    width: 100%;
    height: 25.3em;
  }

  .ss-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`
