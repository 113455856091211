import {
  error as _error,
  loading as _loading,
  markNotificationsLoaded as _markNotificationsLoaded,
  toggleNotifications as _toggleNotifications,
  setNotifications as _setNotifications,
  addNotifications as _addNotifications,
} from './actions'
import axios from 'axios'
import stream from 'getstream'

const baseUrl = process.env.REACT_APP_SERVER_URL

let streamClient = null
let notificationsFeed = null

const fetchStreamTokens = async () => {
  try {
    // fetch the tokens from server, and set the variables with tokens for each feed
    const response = await axios.get(`${baseUrl}/api/notifications/get-tokens`)
    const tokens = response.data
    // connect to stream
    streamClient = stream.connect(
      process.env.REACT_APP_STREAM_KEY,
      null,
      process.env.REACT_APP_STREAM_APP_ID
    )
    // connect notifications feed
    notificationsFeed = streamClient.feed('notifications', 'admin_3', tokens.notifications)
    return true
  } catch (err) {
    // TODO: handle error state with reducer
    console.log('error getting tokens', err)
    return false
  }
}

export const fetchNotifications = () => {
  return async dispatch => {
    try {
      dispatch(_loading(true))
      await fetchStreamTokens()

      // const notifications = await notificationsFeed.get()
      // NOTE: figure out which "Aggregation Format" will be best. AF groups the
      // notifications by certain criteria (currently each activity is its' own group)
      const notifications = await notificationsFeed.get({ mark_seen: true, limit: 30 })

      console.log('notifications', notifications)
      // do check here and see if there are duplication projects that are in the same stage, only show most recent

      // this is very specific to the "Aggregation Format" (which will group each activity)
      // into it's own "Notification Group"
      const notificationsWMeta = notifications.results.map(notification => {
        return {
          ...notification.activities[0],
          is_read: notification.is_read || false,
          is_seen: notification.is_seen || false,
        }
      })
      dispatch(_setNotifications(notificationsWMeta))
      dispatch(_markNotificationsLoaded())
      dispatch(_loading(false))

      // subscription will recieve and add notifications as they are added to getstream
      let subscription = notificationsFeed.subscribe(function(data) {
        // specific to current "Aggregation Format"
        dispatch(_addNotifications([{ ...data.new[0], is_read: false, is_seen: false }]))
      })

      return true
    } catch (err) {
      // TODO: handle error state with reducer
      console.log('error fetching notifications', err)
      return false
    }
  }
}
