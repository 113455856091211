import styled from 'styled-components'
import { darken, em } from 'polished'
import { colors } from '../../helpers/foundation'
import SelectArrow from '../../assets/icons/dd-icon.png'

const Field = styled.div`
  display: block;
  margin-bottom: 1.25em;

  input,
  textarea,
  select {
    color: ${colors.grey2};
    border: 1px solid ${props => (props.invalid ? colors.danger : colors.grey6)};
    padding: 1em ${em('14px')};
    ${props => {
      if (props.color) {
        return `border: 1px solid ${props.color} !important`
      }
    }}
    font-size: ${em('14px')};

    &:focus {
      border-color: ${props => (props.invalid ? darken(0.1, colors.danger) : colors.grey2)};
    }

    ::placeholder {
      color: ${colors.grey6};
    }
  }

  label {
    color: ${colors.grey2};
    margin-bottom: 8px;
  }

  > select {
    min-width: 12.5em;
    background: ${colors.white} url(${SelectArrow}) no-repeat;
    background-position: right 0.75em center;
    background-size: 12px;
  }

  > textarea {
    resize: vertical;
    min-height: 66px;
  }

  > .error {
    color: ${colors.danger};
    font-size: ${em('12px')};
    display: block;
    margin-top: 0.25em;
    margin-left: 0.5em;
    display: ${props => (props.invalid ? 'block' : 'none')};
  }

  > .small {
    color: ${colors.grey2};
    font-size: ${em('14px')};
    display: inline-block;
    margin-top: 0.5em;
    transition: 0.1s ease-in-out;

    &:hover,
    &:active {
      color: ${colors.base};
    }
  }
`

export default Field
