import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Notifications from '../components/navBar/components/Notifications'

import { toggleNotifications } from '../redux/modules/notifications/actions'
import {
  fetchNotifications,
  // setupNotificationWatch,
} from './../redux/modules/notifications/operations'

const mapStateToProps = state => {
  return {
    error: state.notifications.error,
    loading: state.notifications.loading,
    notificationsLoaded: state.notifications.notificationsLoaded,
    open: state.notifications.open,
    notifications: state.notifications.notifications,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleNotifications: bool => dispatch(toggleNotifications(bool)),
    fetchNotifications: () => dispatch(fetchNotifications()),
    // setupNotificationWatch: () => dispatch(setupNotificationWatch()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notifications)
)
