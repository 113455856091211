import React, { Component, PureComponent } from 'react'
import {
  getDate,
  addDays,
  getDay,
  getYear,
  isSameWeek,
  isBefore,
  subDays,
  isSameDay,
  isPast,
} from 'date-fns'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Scheduler from './../styles'

import Day from './Day'
import selectors from './../../../redux/modules/scheduler/selectors'
import Button from './../../sharedComponents/Button'

class Week extends Component {
  state = {
    currentDay: null,
  }

  componentDidMount = () => {
    if (this.props.isDayView) {
      // get day for current day
      this.setDay()
    }
  }

  componentWillUnmount = () => {
    if (this.props.isDayView) {
      // get day for current day
      this.setDay()
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // when changing from tablet+ view to tablet- view
    // or if SOW !== prev SOW (when changing month)
    // use current day if day is within same week as startOfWeek
    if (
      (!prevProps.isDayView && this.props.isDayView) ||
      (!isSameDay(prevProps.startOfWeek, this.props.startOfWeek) &&
        (this.state.currentDay !== null && this.state.currentDay > -1))
    ) {
      if (isSameWeek(this.props.startOfWeek, new Date())) {
        // will be 6 when current day is set from clicking previous day btn
        if (this.state.currentDay === 6) {
          this.setDay(null, true)
        } else {
          this.setDay(null)
        }
      } else {
        if (this.state.currentDay && this.state.currentDay === 6) {
          this.setDay(this.props.endOfWeek)
        } else {
          this.setDay(addDays(this.props.startOfWeek, 1))
        }
      }
    }
    // else if ((!prevProps.isDayView && this.props.isDayView) ||
    // (!isSameDay(prevProps.startOfWeek, this.props.startOfWeek) &&
    //   (this.state.currentDay !== null && this.state.currentDay > -1))) {}
  }

  setDay = (date, fromPreviousDay = false) => {
    date = date !== null && date !== undefined ? date : new Date()
    date = fromPreviousDay ? this.props.endOfWeek : date
    this.setState(prevState => ({ currentDay: getDay(date) }))
  }

  changeDay = amt => {
    let amount = null
    if (this.state.currentDay + amt > 6) {
      this.props.nextWeek()
      amount = 0
    }
    // need -1 to take into account we are not showing sunday
    if (this.state.currentDay + amt - 1 < 0) {
      this.props.lastWeek()
      amount = 6
    }

    // reset selected
    this.props.selectTime(null)

    this.setState(prevState => ({
      currentDay: amount === null ? prevState.currentDay + amt : amount,
    }))
  }

  getAbvr = day => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return days[day]
  }

  render() {
    const days = {
      // sun: selectors.organizeTimes(this.props.slots, 'Sunday'),
      mon: selectors.organizeTimes(this.props.slots, 'Monday'),
      tues: selectors.organizeTimes(this.props.slots, 'Tuesday'),
      weds: selectors.organizeTimes(this.props.slots, 'Wednesday'),
      thurs: selectors.organizeTimes(this.props.slots, 'Thursday'),
      fri: selectors.organizeTimes(this.props.slots, 'Friday'),
      sat: selectors.organizeTimes(this.props.slots, 'Saturday'),
    }

    const abvr = this.getAbvr(this.state.currentDay)

    const canGoPrevDay = isBefore(
      new Date(),
      subDays(addDays(this.props.startOfWeek, this.state.currentDay), 0)
    )

    return (
      <Scheduler.Week>
        {this.props.isDayView && (
          <div className="head">
            <div
              className={`change-day prev ${!canGoPrevDay ? 'disabled' : ''}`}
              onClick={e => {
                if (canGoPrevDay) {
                  this.changeDay(-1)
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="day">{`${abvr} ${getDate(
              addDays(this.props.startOfWeek, this.state.currentDay)
            )}`}</div>
            <div className="change-day prev" onClick={e => this.changeDay(1)}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        )}
        {/* <PoseGroup> */}
        {/* {((this.state.currentDay === 0 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={0}
            abvr={`Sun ${getDate(this.props.startOfWeek)}`}
            date={this.props.startOfWeek}
            isPast={
              isPast(this.props.startOfWeek) && !isSameDay(this.props.startOfWeek, new Date())
            }
            events={days.sun}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents} // for telling day column that items are loaded
            selectTime={this.props.selectTime}
            dayNum={0}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )} */}
        {((this.state.currentDay === 1 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={1}
            abvr={`Mon ${getDate(addDays(this.props.startOfWeek, 1))}`}
            date={addDays(this.props.startOfWeek, 1)}
            isPast={
              isPast(addDays(this.props.startOfWeek, 1)) &&
              !isSameDay(addDays(this.props.startOfWeek, 1), new Date())
            }
            events={days.mon}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents}
            selectTime={this.props.selectTime}
            dayNum={1}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )}
        {((this.state.currentDay === 2 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={2}
            abvr={`Tue ${getDate(addDays(this.props.startOfWeek, 2))}`}
            date={addDays(this.props.startOfWeek, 2)}
            isPast={
              isPast(addDays(this.props.startOfWeek, 2)) &&
              !isSameDay(addDays(this.props.startOfWeek, 2), new Date())
            }
            events={days.tues}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents}
            selectTime={this.props.selectTime}
            dayNum={2}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )}
        {((this.state.currentDay === 3 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={3}
            abvr={`Wed ${getDate(addDays(this.props.startOfWeek, 3))}`}
            date={addDays(this.props.startOfWeek, 3)}
            isPast={
              isPast(addDays(this.props.startOfWeek, 3)) &&
              !isSameDay(addDays(this.props.startOfWeek, 3), new Date())
            }
            events={days.weds}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents}
            selectTime={this.props.selectTime}
            dayNum={3}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )}
        {((this.state.currentDay === 4 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={4}
            abvr={`Thu ${getDate(addDays(this.props.startOfWeek, 4))}`}
            date={addDays(this.props.startOfWeek, 4)}
            isPast={
              isPast(addDays(this.props.startOfWeek, 4)) &&
              !isSameDay(addDays(this.props.startOfWeek, 4), new Date())
            }
            events={days.thurs}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents}
            selectTime={this.props.selectTime}
            dayNum={4}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )}
        {((this.state.currentDay === 5 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={5}
            abvr={`Fri ${getDate(addDays(this.props.startOfWeek, 5))}`}
            date={addDays(this.props.startOfWeek, 5)}
            isPast={
              isPast(addDays(this.props.startOfWeek, 5)) &&
              !isSameDay(addDays(this.props.startOfWeek, 5), new Date())
            }
            events={days.fri}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents}
            selectTime={this.props.selectTime}
            dayNum={5}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )}
        {((this.state.currentDay === 6 && this.props.isDayView) || !this.props.isDayView) && (
          <Day
            key={6}
            abvr={`Sat ${getDate(addDays(this.props.startOfWeek, 6))}`}
            date={addDays(this.props.startOfWeek, 6)}
            isPast={
              isPast(addDays(this.props.startOfWeek, 6)) &&
              !isSameDay(addDays(this.props.startOfWeek, 6), new Date())
            }
            events={days.sat}
            selectedTime={this.props.selectedTime}
            isCustomer={this.props.isCustomer}
            startOfWeek={this.props.startOfWeek}
            isLoaded={!this.props.loadingEvents}
            selectTime={this.props.selectTime}
            dayNum={6}
            year={getYear(this.state.currentDay)}
            changeDay={this.changeDay}
            isDayView={this.props.isDayView}
          />
        )}
        {/* </PoseGroup> */}
        {this.props.isDayView && this.props.selectedTime && (
          <div className="bottom-bar">
            <Button
              text="Book slot"
              variant="lg"
              fluid={true}
              // try to schedule time with the current user and their selected time
              handleClick={() =>
                this.props.openConfirmationModal(this.props.selectedTime, this.props.user)
              }
            />
          </div>
        )}
      </Scheduler.Week>
    )
  }
}

export default Week
