import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'


const CustomerEstimatePaymentReceived = props => {
  return (
    <StyledCustomerEstimatePaymentReceived>
      <Header title="Thank you for your payment!" />
      <Container>
        <Notice text="Your project payment has been accepted! An Osceola Fence Supply team member will be reaching out soon to help you schedule your installation." />
      </Container>
    </StyledCustomerEstimatePaymentReceived>
  )
}

export default CustomerEstimatePaymentReceived

const StyledCustomerEstimatePaymentReceived = styled.div``
