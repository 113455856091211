import React, { Component, PureComponent } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { zIndexes } from './../../helpers/foundation'
import { fadeUp } from './../../helpers/animations'

class Modal extends PureComponent {
  state = {
    contentHeight: 0,
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageLoaded) {
      if (prevProps.imageLoaded !== this.props.imageLoaded) {
        this.setState({
          contentHeight: this.modal.offsetHeight,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp, false)
    document.addEventListener('click', this.handleOutsideClick, false)
    window.addEventListener('resize', this.handleResize, false)

    this.setState({
      contentHeight: this.modal.offsetHeight,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false)
    document.removeEventListener('click', this.handleOutsideClick, false)
    window.removeEventListener('resize', this.handleResize, false)

    this.props.close()
  }

  handleResize = () => {
    this.setState({
      contentHeight: this.modal.offsetHeight,
    })
  }

  handleKeyUp = e => {
    const keys = {
      27: () => {
        e.preventDefault()
        this.props.close()
        window.removeEventListener('keyup', this.handleKeyUp, false)
      },
    }

    if (keys[e.keyCode]) {
      keys[e.keyCode]()
    }
  }

  handleOutsideClick = e => {
    if (e.target === this.overlay) {
      this.props.close()
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
  }

  render() {
    const { centered, width = 900, children, noPadding } = this.props
    const { contentHeight } = this.state

    return (
      <StyledModal
        innerRef={node => (this.overlay = node)}
        centered={centered}
        width={width}
        contentHeight={contentHeight}
        ariaModal="true"
        noPadding={noPadding ? noPadding : undefined}
        border={this.props.border ? this.props.border : null}
      >
        <div className="inner-modal" ref={node => (this.modal = node)}>
          {children}
        </div>
      </StyledModal>
    )
  }
}

export default Modal

const StyledModal = styled.div`
  animation: ${fadeUp} 0.2s ease-in-out forwards;
  background-color: ${rgba('#000', 0.6)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexes.modalOuter};
  overflow: auto;
  transition: 0.2s ease-in-out;

  .title {
    margin-bottom: ${props => (props.noPadding ? '0' : '1.5em')};
  }

  .inner-modal {
    background-color: #fff;
    border-radius: 4px;
    padding: ${props => (props.noPadding ? '0' : '2em')};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    height: auto;
    /* check if px being used, otherwise use whatever was passed in */
    max-width: ${props =>
      typeof props.width === 'string' && props.width.slice(-2) === 'px'
        ? `${props.width}px`
        : `${props.width}px`};
    width: calc(100% - 2em);
    margin: 1em 0;
    overflow: auto;

    @media only screen and (min-height: ${props => props.contentHeight}px) {
      top: 50%;
      margin-top: -${props => props.contentHeight / 2}px;
    }

    ${props => {
      if (props.centered) {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
        }
      }
    }};

    ${props => {
      if (props.border) {
        return `
        border: 1px solid ${props.border};
      `
      }
    }}
  }

  .close {
    text-align: right;
    padding: 0 0.25em;
    > div {
      cursor: pointer;
    }
  }

  .add-comment-body {
    padding: 0.25em 1.5em 1em;
    width: 100%;

    .colors-container {
      text-align: center;
      margin: 1em 0;
      .color {
        position: relative;
        display: inline-flex;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 2px;
        cursor: pointer;

        &.selected {
          border: 2px solid #111;
          top: -3px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .no-color {
        display: inline-flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        top: -5px;

        border: 1px solid #ccc;
        border-radius: 4px;
        justify-content: center;
        > div {
          cursor: pointer;
        }

        &.selected {
          top: -5px;
        }
      }
    }
  }

  #add-comment {
    width: 100%;
    height: 8em;
    resize: vertical;
  }

  .button-container {
    text-align: center;
  }
`
