import React from 'react'
import styled from 'styled-components'
import shortid from 'shortid'

import { zIndexes, colors } from './../../helpers/foundation'

const Thumbnails = ({ photos, detailKey, handleClick, ...props }) => {
  return (
    <StyledThumbnails marginTop={props.marginTop}>
      {/* handle both array and object version of thumbnails */}
      {photos instanceof Array ? (
        <React.Fragment>
          {photos.map(photo => {
            return (
              <Thumbnail
                key={photo._id || shortid.generate()}
                onClick={e => handleClick(detailKey, photo._id)}
                image={photo.small}
              />
            )
          })}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {Object.keys(photos).map(key => {
            return (
              <Thumbnail
                direction={key}
                key={key || shortid.generate()}
                onClick={e => handleClick(key)}
                image={photos[key].small}
              />
            )
          })}
        </React.Fragment>
      )}
    </StyledThumbnails>
  )
}

export default Thumbnails

const StyledThumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${props => `${props.marginTop} 0 0 -0.5em`};
`

const Thumbnail = styled.div`
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0.5em;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  height: 80px;
  width: 120px;
  position: relative;
  overflow: hidden;
  z-index: ${zIndexes.base};

  &:after {
    display: ${props => (props.direction ? 'inline-block' : 'none')};
    position: absolute;
    content: ' ';
    background-color: ${colors.primary};
    z-index: ${zIndexes.thumbnailDot};
    width: 12px;
    height: 12px;
    border-radius: 50%;

    ${props => {
      if (!props.direction) {
        return false
      }
      /* give the dots their proper position */
      switch (props.direction) {
        case 'frontLeft':
          return {
            bottom: '5%',
            left: '5%',
          }
        case 'frontCenter':
          return {
            bottom: '5%',
            right: '50%',
            transform: 'translateX(50%)',
          }
        case 'frontRight':
          return {
            bottom: '5%',
            right: '5%',
          }
        case 'centerRight':
          return {
            bottom: '50%',
            right: '5%',
            transform: 'translateY(50%)',
          }
        case 'backRight':
          return {
            top: '5%',
            right: '5%',
          }

        case 'backCenter':
          return {
            top: '5%',
            right: '50%',
            transform: 'translateX(50%)',
          }
        case 'backLeft':
          return {
            top: '5%',
            left: '5%',
          }
        case 'centerLeft':
          return {
            bottom: '50%',
            transform: 'translateY(50%)',
            left: '5%',
          }
        default:
          return {}
      }
    }};
  }
`
