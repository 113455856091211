import {
  loading as _loading,
  modalLoading as _modalLoading,
  requestError as _requestError,
  fetchMembers as _fetchMembers,
  addMember as _addMember,
  updateMember as _updateMember,
  removeMember as _removeMember,
} from './actions'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'

export const fetchMembers = () => {
  return async dispatch => {
    dispatch(_loading(true))
    try {
      const members = await axios.get(`${addProductionUrl()}/api/users?type=employees`)

      console.log('the members?', members)

      dispatch(_fetchMembers(members.data))
      dispatch(_loading(false))
    } catch (err) {
      dispatch(_requestError(err.response.data.message))
      dispatch(_loading(false))
    }
  }
}

export const addMember = data => {
  return async dispatch => {
    try {
      dispatch(_modalLoading(true))
      const newMember = await axios.post(`${addProductionUrl()}/api/users`, data)
      dispatch(_addMember(newMember.data))
      dispatch(_modalLoading(false))
      return true
    } catch (err) {
      dispatch(_requestError(err.response.data.message))
      dispatch(_modalLoading(false))
    }
  }
}

export const updateMember = (memberId, data) => {
  return async dispatch => {
    try {
      dispatch(_modalLoading(true))
      const updatedMember = await axios.patch(`${addProductionUrl()}/api/users/${memberId}`, data)
      dispatch(_updateMember(updatedMember.data))
      dispatch(_modalLoading(false))
      return true
    } catch (err) {
      dispatch(_requestError(err.response.data.message))
      dispatch(_modalLoading(false))
    }
  }
}

export const removeMember = (memberId, team) => {
  return async dispatch => {
    try {
      dispatch(_modalLoading(true))
      const deleteMessage = await axios.delete(`${addProductionUrl()}/api/users/${memberId}`)
      dispatch(_removeMember(memberId))
      dispatch(_modalLoading(false))
      return true
    } catch (err) {
      dispatch(_requestError(err.response.data.message))
      dispatch(_modalLoading(false))
    }
  }
}
