import React, { Component } from 'react'
import styled from 'styled-components'
import { colors, breakpoints, regexes } from './../../helpers/foundation'

import Button from './../sharedComponents/Button'
import Field from './../sharedComponents/Field'
import Modal from './../sharedComponents/Modal'
import Icon from './../sharedComponents/Icon'
import Checkbox from './../sharedComponents/Checkbox'
import Loader from './../sharedComponents/Loader'

class GatherFilesModal extends Component {
  state = {
    modalInfo: null,
  }

  // this will be called before the component mounts, and will
  // set modalInfo to the initial this.props.modalInfo
  static getDerivedStateFromProps = (props, state) => {
    if (props.modalBeingUsed && !state.modalInfo) {
      return {
        modalInfo: props.modalInfo,
      }
    } else {
      return null
    }
  }

  updateModalField = e => {
    const { name, value } = e.target
    const isToggle =
      name === 'confirmPosession' || name === 'confirmDelivery' || name === 'confirmRecieved'
    // console.log('huh?', isToggle, this.state.modalInfo[name], !this.state.modalInfo[name])
    this.setState(prevState => ({
      modalInfo: {
        ...prevState.modalInfo,
        [name]: isToggle ? !prevState.modalInfo[name] : value,
      },
    }))
  }

  updateHoaEmail = e => {
    const value = e.target.value

    this.setState(prevState => ({
      modalInfo: {
        ...prevState.modalInfo,
        hoaEmailText: value,
        hoaEmailValid: regexes.email.test(value),
      },
    }))
  }

  // sends hoa email
  sendHoaEmail = hoaEmail => {
    const email = this.state.modalInfo.hoaEmailText
    this.props.sendHoaEmail(this.props.paperworkId, email).then(success => {
      this.setState(prevState => ({
        modalInfo: {
          ...prevState.modalInfo,
          hoaEmailText: '',
          hoaEmailValid: false,
        },
      }))
    })
  }

  // decides which modal to use, newFile, confirmSend, confirmApprove
  chooseModal = () => {
    const { modalInfo } = this.state
    const { modalBeingUsed, modalExtra, hoaStatus, permitsStatus } = this.props

    const updateAndCloseModal = newStep => {
      let emailToSend = null
      // check if newStep is 'Pending Approval'
      if (newStep === 'Pending Approval') {
        // if mi.type is hoa, check if permitsStatus is past 'To Send'
        if (modalInfo.type === 'hoa') {
          if (permitsStatus === 'Pending Approval' || permitsStatus === 'Approved') {
            emailToSend = 'submittingSoon'
          }
        }
        // if mi.type is permit, check if hoaStatus is past 'To Send'
        if (modalInfo.type === 'permit') {
          if (hoaStatus === 'Pending Approval' || hoaStatus === 'Approved') {
            emailToSend = 'submittingSoon'
          }
        }
      }

      if (newStep === 'Approved') {
        if (modalInfo.type === 'hoa') {
          if (permitsStatus === 'Approved') {
            emailToSend = 'submitted'
          }
        }
        if (modalInfo.type === 'permit') {
          if (hoaStatus === 'Approved') {
            emailToSend = 'submitted'
          }
        }
      }

      this.props.updateStep(
        this.props.paperworkId,
        modalInfo.type === 'hoa' ? 'hoaStatus' : 'permitsStatus',
        newStep,
        emailToSend,
        this.props.projectId
      )
      this.props.closeModal()
    }

    if (modalBeingUsed === 'newFile') {
      return (
        <React.Fragment>
          <h3 className="title">New {modalInfo.type === 'hoa' ? 'HOA' : 'Permits'} File</h3>

          <Field>
            <label htmlFor="">File Name *</label>
            <input
              type="text"
              value={modalInfo.name}
              name="name"
              onChange={this.updateModalField}
              placeholder='ex. "Local Borough Permit"'
            />
          </Field>

          <div className="new-file-confirms">
            <div className="subhead">Can customer upload this file? *</div>
            <div className="confirm-item radio">
              <input
                type="radio"
                name="responsible"
                value="ofs"
                checked={modalInfo.responsible === 'ofs' ? true : false}
                onChange={this.updateModalField}
              />
              <div className="text">No, only OFS can upload the finished version of this file.</div>
            </div>
            <div className="confirm-item radio">
              <input
                type="radio"
                name="responsible"
                value="customer"
                onChange={this.updateModalField}
              />
              <div className="text">Yes, the customer is responsible for completing this file.</div>
            </div>
          </div>

          <Button
            text="Add this File"
            variant={`lg ${
              modalInfo.name.length > 2 && modalInfo.responsible.length > 0 ? null : 'disabled'
            }`}
            handleClick={e => {
              // modalInfo.type will contain the file 'type'
              this.props.addFile(
                this.props.paperworkId,
                modalInfo.name,
                modalInfo.type,
                modalInfo.responsible
              )
              this.props.closeModal()
            }}
          />
        </React.Fragment>
      )
    } else if (modalBeingUsed === 'confirmSend') {
      // could not have both of these in a condition in a terinary conditional, put here
      return (
        <div className="confirm-modal">
          <div className="title-sm">
            Marking this project for approval notifies the customer that their project is in the
            process of being approved. Do not mark a project for approval until the files have been
            submitted.
          </div>

          {/* HOA email form */}
          {modalInfo.type === 'hoa' && (
            <div className="hoa-email-input">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  if (!this.props.hoaEmailSending && this.state.modalInfo.hoaEmailValid) {
                    this.sendHoaEmail()
                  }
                }}
              >
                <Field>
                  <input
                    id="hoaEmail"
                    name="hoaEmail"
                    type="text"
                    placeholder="HOA email..."
                    onChange={this.updateHoaEmail}
                    value={this.state.modalInfo.hoaEmailText}
                    autoComplete="off"
                    disabled={this.props.hoaEmailSent ? true : false}
                  />
                  <Button
                    variant={`nm ${
                      this.props.hoaEmailSending || !this.state.modalInfo.hoaEmailValid
                        ? 'disabled'
                        : 'primary'
                    }`}
                    text="Send"
                    type="submit"
                  />
                </Field>
                {!this.props.readyAndEmailSent ? (
                  <div className="explain">* Send all approved documents to HOA</div>
                ) : (
                  <div className="explain">
                    <Icon icon="solidCheck" color="primary" /> <span>HOA email sent</span>
                  </div>
                )}
              </form>
              {this.props.hoaEmailSending && (
                <div className="loading-icon">
                  <Loader centered={false} width="29" small={true} />
                </div>
              )}
              {this.props.hoaEmailError && (
                <div className="error-msg">{this.props.hoaEmailError}</div>
              )}
            </div>
          )}

          <div className="confirm-item">
            <Checkbox
              name="confirmDelivery"
              value="confirmDelivery"
              checked={modalInfo.confirmDelivery ? true : false}
              text="I confirm that I have recieved and delivered the appropriate files to the permitting office—either digitally, by mail, or in person."
              handleChange={this.updateModalField}
            />
          </div>

          <Button
            text="Yes, Mark as Sent"
            variant={`lg ${
              modalInfo.confirmDelivery && this.props.hoaEmailSent ? null : 'disabled'
            }`}
            handleClick={e =>
              modalInfo.confirmDelivery && this.props.hoaEmailSent
                ? updateAndCloseModal('Pending Approval')
                : null
            }
          />
        </div>
      )
    } else if (modalBeingUsed === 'confirmApprove') {
      return (
        <div className="confirm-modal">
          <div className="title-sm">
            Marking this project as approved notifies the customer. Do not mark a project as
            approved until you have received actual approval!
          </div>

          <div className="confirm-item">
            <Checkbox
              name="confirmRecieved"
              value="confirmRecieved"
              checked={modalInfo.confirmRecieved ? true : false}
              text="I confirm that OFS has received approval for this project from the customer’s HOA."
              handleChange={this.updateModalField}
            />
          </div>

          <Button
            text="Yes, it's Approved"
            variant={`lg ${modalInfo.confirmRecieved ? null : 'disabled'}`}
            handleClick={e => (modalInfo.confirmRecieved ? updateAndCloseModal('Approved') : null)}
          />
        </div>
      )
    } else if (modalBeingUsed === 'revisionNotes') {
      return (
        <div className="revision-modal">
          <h3 className="title">File Revision</h3>
          <div className="strong">
            You’re marking this file as “To Revise.” Please provide clear notes indicating what
            needs to be fixed about this file.
          </div>

          <Field>
            <label htmlFor="">Revision Notes</label>
            <textarea
              name="revisionNotes"
              value={modalInfo.revisionNotes}
              onChange={this.updateModalField}
            />
          </Field>

          <Button
            text="Finish"
            variant="lg"
            handleClick={e => {
              this.props
                .updateFile(
                  modalExtra.projectId,
                  modalExtra.paperworkId,
                  modalExtra.fileId,
                  { ...modalExtra.status, revisionNotes: this.state.modalInfo.revisionNotes },
                  modalExtra.from
                )
                .then(success => true)
              this.props.closeModal()
            }}
          />
        </div>
      )
    } else if (modalBeingUsed === 'viewRevisionNotes') {
      return (
        <div className="revision-modal sm">
          <h3 className="title">File Revision</h3>
          <div className="strong">
            Revision Notes{' '}
            <span
              className="link-sm"
              onClick={e => {
                this.props.closeModal()
                this.props.openModal(modalExtra.from, 'revisionNotes', {
                  ...modalExtra,
                })
              }}
            >
              edit
            </span>
          </div>
          <div className="text">{modalExtra.revisionNotes}</div>

          <Button text="Finish" variant="lg" handleClick={e => this.props.closeModal()} />
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { modalBeingUsed } = this.props
    return (
      <StyledGatherFilesModal>
        <Modal
          width={
            modalBeingUsed === 'newFile' ||
            modalBeingUsed === 'revisionNotes' ||
            modalBeingUsed === 'viewRevisionNotes'
              ? '420'
              : '530'
          }
          continuousModal={false}
          centered={false}
          close={this.props.closeModal}
        >
          <div className="close">
            <Icon icon="close" handleClick={this.props.closeModal} />
          </div>
          {this.chooseModal()}
        </Modal>
      </StyledGatherFilesModal>
    )
  }
}

export default GatherFilesModal

const StyledGatherFilesModal = styled.div`
  .confirm-modal {
    .title-sm {
      font-weight: 600;
      margin: 1em 0 2em;
    }

    button {
      margin: 1em 0 0;
    }
  }

  .confirm-item {
    position: relative;
    display: flex;
    width: 22.25em;
    margin-bottom: 2em;
    font-size: 0.875em;

    input {
      /* position: relative;
      top: 6px;
      margin-right: 1em; */
    }

    &.radio {
      margin-bottom: 1em;
    }
  }

  .new-file-confirms {
    margin: 3em 0;
    .subhead {
      font-size: 0.875em;
      font-weight: 600;
      color: ${colors.grey2};
      margin-bottom: 1em;
    }
  }

  .revision-modal {
    .title {
      font-size: 1.5em;
      margin-bottom: 0;
    }

    textarea {
      margin-bottom: 2rem;
    }

    .link-sm {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 400;
      color: ${colors.grey};
      margin-left: 0.5em;
    }

    &.sm {
      .strong {
        font-size: 0.875em;
      }
      .text {
        font-size: 0.875rem;
        margin-bottom: 2rem;
        padding: 0.5em;
        border: 1px solid ${colors.grey7};
        border-radius: 4px;
      }
    }
  }

  .strong {
    font-weight: 600;
    margin: 2rem 0;
  }

  .row {
    display: flex;
  }

  .hoa-email-input {
    display: flex;
    position: relative;
    margin-bottom: 2em;
    align-items: center;
    @media screen and (max-width: ${breakpoints.medium}px) {
      margin-top: 1em;
      margin-left: 0;
    }

    form {
      > div {
        display: flex;
        align-items: flex-end;
        margin-bottom: 0.1em;
      }
    }

    .loading-icon,
    .error-msg {
      position: relative;
      margin-left: 0.5em;
      /* top: -1px; */
    }

    .loading-icon {
      img {
        margin-top: -8px;
      }
    }

    input {
      margin-right: 0.5em;
      border: 1px solid ${colors.grey};
    }

    .explain {
      font-size: 0.75em;
      color: ${colors.grey2};

      > span {
        margin-left: 0.5em;
      }
    }
  }
`
