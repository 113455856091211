import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'
import { colors } from './../../../helpers/foundation'

// import MediaCard from './../../sharedComponents/MediaCard'
import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import Sector from './../../sharedComponents/Sector'
import DateCard from './../../sharedComponents/DateCard'
import SecondaryButton from './../../sharedComponents/SecondaryButton'
import Icon from './../../sharedComponents/Icon'
import AttachmentList from './../../sharedComponents/AttachmentList'
import Loader from './../../sharedComponents/Loader'

class CustomerSurveyConfirmed extends Component {
  state = {
    attachmentLoading: false,
    attachments: [],
    attachmentError: false,
    attachmentUploaded: false,
  }

  componentDidMount = () => {
    const paperwork = this.props.project.paperwork

    // TODO: For legacy projects - prevents blank screen - could probably use a componentDidCatch
    if (!paperwork) {
      return
    }

    const siteSurveyIdx = paperwork.files.findIndex(file => file.name === 'Site Survey')

    if (
      paperwork.files[siteSurveyIdx] &&
      paperwork.files[siteSurveyIdx].filename &&
      paperwork.files[siteSurveyIdx].filepath
    ) {
      this.setState(prevState => ({
        attachments: [...prevState.attachments, paperwork.files[siteSurveyIdx]],
      }))
    }
  }

  handleUpload = async e => {
    try {
      const siteSurveyIdx = this.props.project.paperwork.files.findIndex(
        file => file.name === 'Site Survey'
      )
      const file = e.target.files[0]

      this.setState(prevState => ({ attachmentLoading: true, attachmentUploaded: false }))

      const formData = new FormData()
      formData.append('site-survey', file, file.name)
      formData.append('projectId', this.props.project._id)

      const result = await axios.post(
        `${addProductionUrl()}/api/surveys/${this.props.project.survey._id}/site-survey`,
        formData,
        {
          headers: {
            enctype: 'multipart/form-data',
          },
        }
      )

      this.setState(prevState => ({
        attachmentLoading: false,
        attachmentUploaded: true,
        attachments: [result.data.files[siteSurveyIdx]],
      }))
    } catch (err) {
      this.setState(prevState => ({
        attachmentError: 'We encountered an error with your upload. Please contact us.',
      }))
    }
  }

  render() {
    const { survey, members } = this.props.project
    const { attachmentLoading, attachments, attachmentError, attachmentUploaded } = this.state

    // console.log('huh', this.props.project)

    return (
      <StyledCustomerSurveyConfirmed>
        <Header title="Your site visit is confirmed!" />

        <Container>
          <Notice text="Thanks for confirming your appointment! Your surveyor will arrive at your property on the date listed below. They’ll give you a call about 30 minutes before they arrive." />

          {survey.event && (
            <Sector bordered={false} size="lg">
              <h2>Your site visit is scheduled for:</h2>
              <div className="card">
                <DateCard
                  day={moment(survey.event.start).format('dddd')}
                  date={moment(survey.event.start).format('MMM Do')}
                  from={moment(survey.event.start).format('h:mm a')}
                  to={moment(survey.event.end).format('h:mm a')}
                />
              </div>
            </Sector>
          )}

          <Sector bordered={false} size="lg">
            <h2>Upload your properties 'Site Survey'</h2>
            <div className="text">
              Upload your survey so we can accurately estimate and measure when we visit.
            </div>
            <div className="upload">
              <SecondaryButton tag="label">
                <Icon icon="upload" color="grey3" />
                <span>{attachments.length > 0 ? 'Replace' : 'Upload'}</span>
                <input type="file" name="upload" onChange={this.handleUpload} />
              </SecondaryButton>
            </div>

            <div className="attachments">
              <AttachmentList hideMessage={true} attachments={attachments} />

              {attachmentLoading && (
                <div className="attachment-loading">
                  <Loader />
                </div>
              )}
            </div>

            {attachmentUploaded && (
              <div className="success">Thank you, We have recieved your 'Site Survey'.</div>
            )}

            {attachmentError && <div className="error">{attachmentError}</div>}
          </Sector>
        </Container>
      </StyledCustomerSurveyConfirmed>
    )
  }
}

export default CustomerSurveyConfirmed

const StyledCustomerSurveyConfirmed = styled.div`
  .card {
    max-width: 215px;
  }

  .surveyor {
    max-width: 600px;
  }

  .upload {
    margin: 2em 0;
  }

  .attachments {
    max-width: 40em;
  }

  .success {
    color: ${colors.primary};
  }

  .error {
    color: ${colors.danger};
  }
`
