import { breakpoints, colors, actionBarSize } from '../../../helpers/foundation'
import { em } from 'polished'
import styled from 'styled-components'

export const Time = styled.button`
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
  background-color: ${props => (props.selected ? colors.grey4 : colors.white)};
  border: 1px solid;
  border-color: ${props => (props.featured ? colors.primary : colors.grey)};
  border-radius: 4px;
  display: block;
  line-height: 1.5;
  position: relative;
  width: 100%;
  padding: 1em;
  margin-bottom: 1em;
  overflow: hidden;
  outline: none;
  transition: 0.2s ease-in-out;

  > .icon {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  .small {
    color: ${props => (props.selected ? colors.grey6 : colors.grey2)};
    font-weight: 600;
    transition: 0.2s ease-in-out color;
  }

  .title {
    color: ${props => (props.selected ? colors.grey5 : colors.grey4)};
    font-size: ${em('20px')};
    font-weight: 600;
    transition: 0.2s ease-in-out color;
  }

  &:hover,
  &:focus {
    border-color: ${colors.primary};
    background-color: ${props => (props.selected ? colors.grey4 : colors.lightGrey)};
  }
`

export const Calendar = styled.div`
  position: relative;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  min-height: 100px;
  font-size: 0.75rem;

  @media only screen and (min-width: ${breakpoints.medium}px) {
    font-size: 1rem;
  }
`

export const Controls = styled.div`
  flex-grow: 1;
  .split {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .left {
      @media only screen and (min-width: ${breakpoints.medium}px) {
        flex: 0 1 200px;
        margin-right: 1em;
      }
    }

    .right {
      @media only screen and (min-width: ${breakpoints.medium}px) {
        flex: 0 1 250px;
      }
    }
  }

  button:last-child {
    margin-bottom: 0;
  }
`

export const FeaturedSlot = styled.div`
  position: relative;
  margin-bottom: 3em;
`

export const Title = styled.h1`
  font-size: ${em('24px')};
  margin-bottom: 0.5em;
`

export const Subtitle = styled.h3`
  font-size: ${em('16px')};
  margin-bottom: 0.25em;

  ${props => {
    if (props.marginTop) {
      return `
        margin-top: ${props.marginTop}em;
      `
    }
  }}

  ${props => {
    if (props.color) {
      return `
        color: ${colors[props.color]};
      `
    }
  }}

  &:last-child {
    margin-bottom: 0;
  }
`
