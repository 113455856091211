import React from 'react'
import styled from 'styled-components'
import Tabs from './../sharedComponents/Tabs'

export const MediumHeader = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 2rem;
`

const StyledBackgroundHeader = styled.div`
  position: relative;
  background-color: #fafafa;
  margin-bottom: 6em;
  .title {
    font-size: 1.5em;
    font-weight: 700;
    max-width: ${props => (props.width ? `${props.width}px` : 'auto')};
    margin: auto;
    padding: 3.75rem 1rem 4.125rem;
  }
  .push-bottom {
    position: absolute;
    bottom: -20%;
    width: 100%;
    .inner {
      max-width: 1600px;
      margin: auto;
      padding-left: 1em;
    }
  }
`

export const BackgroundHeader = props => {
  return (
    <StyledBackgroundHeader {...props}>
      <div className="title">{props.title}</div>
      {props.tabs && props.tabs.length > 0 && (
        <div className="push-bottom">
          <div className="inner">{props.tabs && props.tabs.length > 0 && <Tabs {...props} />}</div>
        </div>
      )}
    </StyledBackgroundHeader>
  )
}
