import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import Fuse from 'fuse.js'

import { breakpoints, navSize, zIndexes, colors } from './../../../../helpers/foundation'
import Field from './../../../sharedComponents/Field'
import Loader from './../../../sharedComponents/Loader'
import NotificationItem from './NotificationItem'

class Notifications extends Component {
  state = {
    searchText: '',
    notifications: [],
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleOutsideClick, false)

    // comment out notifications for now
    // if (!this.props.notificationsLoaded) {
    //   // gets notifications and setup handler to recieve real-time notifications
    //   this.props.fetchNotifications().then(success => {
    //     this.setState(prevState => ({ notifications: this.props.notifications }))
    //   })
    // }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.open !== this.props.open && this.props.open) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    if (prevState.searchText !== this.state.searchText) {
      if (this.state.searchText !== '') {
        // fuse options
        const options = {
          shouldSort: false,
          threshold: 0.6,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 2,
          keys: ['projectName', 'projectAddress'],
        }
        const fuse = new Fuse(this.state.notifications, options)
        const result = fuse.search(this.state.searchText)
        this.setState(prevState => ({ notifications: result }))
      } else {
        // for some reason, this version of setState works & this.setState(prevState => ) does not
        this.setState({ notifications: this.props.notifications })
      }
    }
  }

  handleOutsideClick = e => {
    if (this.container && !this.container.contains(e.target)) {
      this.props.toggleNotifications(false)
    }
  }

  handleSearch = e => {
    const value = e.target.value
    this.setState(prevState => ({ searchText: value }))
  }

  render() {
    const { loading, error, open, toggleNotifications } = this.props
    const { searchText, notifications } = this.state

    if (!open) {
      return null
    }

    return (
      <StyledNotifications innerRef={node => (this.container = node)}>
        <div className="carrot" />
        <div className="meta">
          <div className="inner">
            <div className="search">
              <Field color={colors.grey6}>
                <input
                  name="search-input"
                  type="text"
                  placeholder="search"
                  value={searchText}
                  onChange={e => this.handleSearch(e)}
                />
              </Field>
            </div>
          </div>
        </div>
        <div className="content">
          {loading ? (
            <Loader centered={true} />
          ) : (
            <Fragment>
              {notifications.length < 1 ? (
                <div className="msg">Nothing found.</div>
              ) : (
                <div className="notifications">
                  {notifications.map(notification => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      toggleNotifications={toggleNotifications}
                    />
                  ))}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </StyledNotifications>
    )
  }
}

const StyledNotifications = styled.div`
  position: absolute;
  right: -6em;
  top: calc(${navSize.large} - 1.5em);
  max-width: 22em;
  width: 22em;
  /* height: 80vh; */
  border: 4px solid red;
  z-index: ${zIndexes.notificationsComponent};
  background-color: ${colors.white};
  border: 1px solid ${colors.grey6};
  border-radius: 4px;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  color: ${colors.base};
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.medium}px) {
    top: calc(${navSize.small} - 1.25em);
    right: -4.25em;
    border: 4px solid blue;
  }

  .meta {
    .inner {
      display: flex;
      padding: 1em;
    }

    .search {
      flex-grow: 1;
    }
  }

  .carrot {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid ${colors.grey6};
    border-bottom-color: ${colors.white};
    border-right-color: ${colors.white};
    background-color: ${colors.white};
    vertical-align: middle;
    position: absolute;
    top: -5px;
    right: 7.85em;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;

    @media screen and (max-width: ${breakpoints.medium}px) {
      right: 5.15em;
    }
  }

  .content {
    height: 70vh;
    overflow-y: scroll;
  }

  .notifications {
  }

  .msg {
    padding: 1rem;
    font-size: ${em('14px')};
    color: ${colors.grey};
    border-top: 1px solid ${colors.lightGrey};
    /* text-align: center; */
  }
`

export default Notifications
