import React, { PureComponent } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'
import { coordsFromAddress } from './../../helpers/google/geocoding'
import mapStyles from './../../helpers/google/mapStyles'
import icon from './../../helpers/google/mapsMarker.png'

import Loader from './Loader'

class MapContainer extends PureComponent {
  _isMounted = false
  _map = null

  state = {
    lat: null,
    lng: null,
    selectedPlace: null,
    activeMarker: null,
    showingInfoWindow: false,
    zoom: 15,
  }

  componentDidMount = async () => {
    this._isMounted = true

    if (this._isMounted) {
      try {
        // set zoom if it is passed in
        const alsoPassIn = {}
        if (this.props.initialZoom) {
          alsoPassIn['zoom'] = this.props.initialZoom
        }

        // if coords have not been passed in, get them. otherwise used coords passed in
        if (!this.props.coords) {
          const {
            additionalClientInfo: { address, city, state },
          } = this.props

          const coords = await coordsFromAddress(address, city, state)
          if (this._isMounted) {
            this.setState(prevState => {
              return { ...coords, ...alsoPassIn }
            })
          }
        } else {
          this.setState(prevState => ({
            ...this.props.coords,
            ...alsoPassIn,
          }))
        }
      } catch (err) {
        // handle error getting coords here
        console.log('error getting coords!', err)
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  onMarkerClick = (props, marker, e) => {
    this.setState(prevState => {
      return {
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      }
    })
  }

  render() {
    const { lat, lng } = this.state
    const {
      additionalClientInfo: { address, city, state, zip },
    } = this.props

    return (
      <div className="map">
        <Map
          ref={map => (this._map = map)}
          google={this.props.google}
          // zoom={this.state.zoom}
          zoom={15}
          style={style}
          styles={mapStyles}
          initialCenter={{ lat: null, lng: null }}
          center={{ lat, lng }}
        >
          {this.props.hasMarker && (
            <Marker
              icon={icon}
              position={{ lat, lng }}
              name={`${address}, ${city}, ${state} ${zip}.`}
              onClick={this.onMarkerClick}
            />
          )}

          {this.props.hasMarker && (
            <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
              <div>
                <p>{`${address}, ${city}, ${state} ${zip}.`}</p>
              </div>
            </InfoWindow>
          )}
        </Map>
      </div>
    )
  }
}

const style = {
  width: '100%',
  height: '100%',
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  LoadingContainer: Loader,
})(MapContainer)
