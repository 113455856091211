import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

const ScheduleInstall = props => {
  return (
    <StyledScheduleInstall>
      <Header title="Your utility marking date is being scheduled." />
      <Container>
        <Notice>
          <div className="para">
            Your OFS team is working on scheduling a time to locate your property utilities. A city
            surveyor will arrive on your property to mark the locations of any underground utilities
            so that our installation team can avoid them. You do not need to be home during this
          </div>
          <div className="para">
            process. As soon as the dates have been chosen, we’ll let you know!
          </div>
        </Notice>
      </Container>
    </StyledScheduleInstall>
  )
}

const StyledScheduleInstall = styled.div`
  .para {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ScheduleInstall
