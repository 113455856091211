import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'

import Header from '../../sharedComponents/Header'
import Container from '../../sharedComponents/Container'
import Notice from '../../sharedComponents/Notice'
import FileCard from './components/FileCard'
// import Notice from '../../sharedComponents/Notice'

// // attach virtual prop 'from' so can be know if file is from files or additionalFiles and combine

class GatherFiles extends Component {
  state = {
    allFilesUploaded: false,
    allFilesMarkedApproved: false,
    hasRevision: false,
    filesSent: false,
  }

  static getDerivedStateFromProps = (props, state) => {
    let allApproved = true
    let allUploaded = true
    let hasRevision = false
    let filesSent = false
    const hoaRequired = props.project.survey.additionalDetails.hoaAprovalRequired.answer === 'Yes'

    const files = hoaRequired
      ? [...props.paperwork.files]
      : [...props.paperwork.files.filter(file => file.type !== 'hoa')]
    const additionalFiles = hoaRequired
      ? [...props.paperwork.additionalFiles]
      : [...props.paperwork.additionalFiles.filter(file => file.type !== 'hoa')]

    // get all files where the customer is responsible
    const filesWCustomer = [...files, ...additionalFiles].filter(
      file => file.responsible === 'customer'
    )

    // set flag to false for uploaded and/or approved
    filesWCustomer.forEach(paperwork => {
      if (paperwork.status === 'revise') {
        hasRevision = true
      }
      if (paperwork.status !== 'approved') {
        allApproved = false
      }
      if (!paperwork.filepath) {
        allUploaded = false
      }
    })

    if (
      hoaRequired
        ? props.paperworkHoaStatus !== 'To Send' && props.paperworkPermitsStatus !== 'To Send'
        : props.paperworkPermitsStatus !== 'To Send'
    ) {
      filesSent = true
    }

    return {
      allFilesUploaded: allUploaded,
      allFilesMarkedApproved: allApproved,
      hasRevision,
      filesSent,
    }
  }

  componentDidMount = () => {
    this.props.customerFetchPaperwork(
      this.props.project.paperwork._id
        ? this.props.project.paperwork._id
        : this.props.project.paperwork
    )
  }

  getStatusNotice = () => {
    if (this.state.filesSent) {
      return (
        <Notice text="Cross your fingers! We've submitted your project's permit files for approval. This process usually takes 7-10 business days." />
      )
    } else if (this.state.hasRevision) {
      return (
        <Notice
          text="Oops! It seems there was a problem with one or more of your documents."
          border={`1px solid ${colors.grey6}`}
        />
      )
    } else if (this.state.allFilesUploaded && this.state.allFilesMarkedApproved) {
      return (
        <Notice text="Your files look good! We’re prepping them for submission to the appropriate approval offices." />
      )
    } else if (this.state.allFilesUploaded) {
      return (
        <Notice text="Looks like you’ve uploaded everything for now. Hang tight while we review it! Normally we review uploads within 48 hours." />
      )
    } else {
      return null
    }
  }

  render() {
    const hoaRequired =
      this.props.project.survey.additionalDetails.hoaAprovalRequired.answer === 'Yes'
    const files = hoaRequired
      ? [...this.props.paperwork.files]
      : [...this.props.paperwork.files.filter(file => file.type !== 'hoa')]
    const additionalFiles = hoaRequired
      ? [...this.props.paperwork.additionalFiles]
      : [...this.props.paperwork.additionalFiles.filter(file => file.type !== 'hoa')]

    return (
      <StyledGatherFiles>
        <Header title="Please upload the required project paperwork." />
        <Container>
          {this.getStatusNotice()}
          {!this.state.filesSent && (
            <div className="flex-grid">
              {[
                ...files.map(file => {
                  file.from = 'files'
                  return file
                }),
                ...additionalFiles.map(file => {
                  file.from = 'additionalFiles'
                  return file
                }),
              ]
                .filter(file => file.responsible === 'customer')
                .map(file => {
                  return (
                    <FileCard
                      paperworkId={
                        this.props.project.paperwork._id
                          ? this.props.project.paperwork._id
                          : this.props.project.paperwork
                      }
                      projectId={this.props.project._id}
                      customerUpdateFile={this.props.customerUpdateFile}
                      key={file.id}
                      file={file}
                    />
                  )
                })}
            </div>
          )}
        </Container>
      </StyledGatherFiles>
    )
  }
}

export default GatherFiles

const StyledGatherFiles = styled.div`
  img {
    max-width: 800px;
    width: 100%;
    margin: auto;
  }

  .flex-grid {
    display: flex;
    margin: 0 -0.5em 0;
  }
`
