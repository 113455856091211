// NOTE: Currently only being used in survey settings form

import axios from 'axios'
import { addProductionUrl } from './../../helpers/constants'

export const ATTEMPTING_UPDATE = 'app/settingsForm/ATTEMPTING_UPDATE'
export const FAILED = 'app/settingsForm/FAILED'
export const SUCCESS = 'app/settingsForm/SUCCESS'

const initialState = {
  error: null,
  success: null,
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ATTEMPTING_UPDATE:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      }
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      }
    case FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export function attemptUpdate() {
  return {
    type: ATTEMPTING_UPDATE,
  }
}

export function updateFailed(error) {
  return {
    type: FAILED,
    error,
  }
}

export function updateSuccessful() {
  return {
    type: SUCCESS,
  }
}

export function updateUser(uid, data) {
  return dispatch => {
    dispatch(attemptUpdate())

    axios
      .patch(`${addProductionUrl()}/api/users/${uid}`, data, {
        headers: {
          enctype: 'multipart/form-data',
        },
      })
      .then(res => {
        dispatch(updateSuccessful())
      })
      .catch(error => {
        dispatch(updateFailed(error.response.data.message))
      })
  }
}
