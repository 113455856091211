import React, { Component } from 'react'
import styled from 'styled-components'
import loadImage from 'blueimp-load-image'
import { dataURLToBlob } from './../../helpers/images'
import { toTitleCase, splitStringByCapital } from './../../utils'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import PhotoBox from './../sharedComponents/PhotoBox'
import ImageInput from './../sharedComponents/ImageInput'
import Thumbnails from './../sharedComponents/Thumbnails'

import p_frontLeft from './../../assets/photoguide-front-left.png'
import p_front from './../../assets/photoguide-front.png'
import p_frontRight from './../../assets/photoguide-front-right.png'
import p_right from './../../assets/photoguide-right.png'
import p_backRight from './../../assets/photoguide-back-right.png'
import p_back from './../../assets/photoguide-back.png'
import p_backLeft from './../../assets/photoguide-back-left.png'
import p_left from './../../assets/photoguide-left.png'
import Loader from '../sharedComponents/Loader'

class SurveyPhotos extends Component {
  state = {
    directions: [
      'frontLeft',
      'frontCenter',
      'frontRight',
      'centerRight',
      'backRight',
      'backCenter',
      'backLeft',
      'centerLeft',
    ],
    currentDirection: 0,
    activeDirection: null,
    activeNotes: '',
    large: {
      url: null,
      name: null,
    },
    thumbnail: {
      url: null,
      name: null,
    },
    editing: false,
    loading: false,
  }

  componentDidMount() {
    this.setState({
      currentDirection: this.props.survey.photos ? Object.keys(this.props.survey.photos).length : 0,
    })
  }

  handleResize = (image, size) => {
    const imageUrl = image.toDataURL('image/jpeg')

    if (image.type === 'error') {
      console.log('Error loading image')
    } else {
      this.setState(
        {
          [size]: {
            ...this.state[size],
            url: imageUrl,
          },
        },
        () => {
          if (this.state.thumbnail.url && this.state.large.url) {
            this.savePhoto()
          }
        }
      )
    }
  }

  handleFile = e => {
    const file = e.target.files ? e.target.files[0] : null

    loadImage(file, image => this.handleResize(image, 'large'), {
      maxWidth: 900,
      canvas: true,
      crop: true,
      orientation: true,
    })

    loadImage(file, image => this.handleResize(image, 'thumbnail'), {
      maxWidth: 150,
      canvas: true,
      crop: true,
      orientation: true,
    })

    this.setState(prevState => ({
      large: {
        ...prevState.large,
        name: file['name'],
      },
      thumbnail: {
        ...prevState.thumbnail,
        name: file['name'],
      },
    }))
  }

  close = () => {
    this.setState({
      activeNotes: '',
      activeDirection: null,
      editing: false,
    })
  }

  saveNotes = () => {
    const { survey } = this.props
    const { directions, activeNotes, editing, currentDirection, activeDirection } = this.state

    const data = new FormData()

    data.append('type', 'notes')
    data.append('field', directions[activeDirection])
    data.append('notes', activeNotes)

    this.setState({
      loading: true,
    })

    this.props
      .updateDirectionalPhoto(survey._id, data, {
        field: editing ? directions[activeDirection] : directions[currentDirection],
        data: {
          small: survey.photos[directions[activeDirection]].small,
          large: survey.photos[directions[activeDirection]].large,
          notes: activeNotes,
        },
      })
      .then(res => {
        this.setState({
          loading: false,
          editing: false,
        })
      })
  }

  savePhoto = () => {
    const { survey } = this.props
    const {
      directions,
      large,
      thumbnail,
      editing,
      currentDirection,
      activeDirection,
      activeNotes,
    } = this.state

    this.setState({
      loading: true,
    })

    const data = new FormData()
    data.append('type', 'photos')

    if (editing) {
      data.append('field', directions[activeDirection])
    } else {
      data.append('field', directions[currentDirection])
    }

    if (thumbnail.url) {
      data.append('small', dataURLToBlob(thumbnail.url), thumbnail.name)
    }

    if (large.url) {
      data.append('large', dataURLToBlob(large.url), large.name)
    }

    data.append('notes', activeNotes)
    data.append('projectId', this.props.survey.project._id)

    // this will update the redux state with dataURIs instead of waiting for the server to respond
    this.props
      .uploadDirectionalPhoto(survey._id, data, {
        field: editing ? directions[activeDirection] : directions[currentDirection],
        data: {
          small: thumbnail.url,
          large: large.url,
          notes: activeNotes,
        },
      })
      .then(success => {
        this.setState({
          loading: false,
          currentDirection: editing ? Object.keys(survey.photos).length : currentDirection + 1,
          large: {
            url: null,
            name: null,
          },
          thumbnail: {
            url: null,
            name: null,
          },
        })
      })
  }

  editPhoto = photoKey => {
    const { photos } = this.props.survey
    const photo = photos[photoKey]

    this.setState({
      activeDirection: this.state.directions.indexOf(photoKey),
      activeNotes: photo.notes,
      editing: true,
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => {
      return {
        [name]: value,
      }
    })
  }

  getImage = () => {
    const { directions, currentDirection } = this.state
    switch (directions[currentDirection]) {
      case 'frontLeft':
        return p_frontLeft
      case 'frontCenter':
        return p_front
      case 'frontRight':
        return p_frontRight
      case 'centerRight':
        return p_right
      case 'backRight':
        return p_backRight
      case 'backCenter':
        return p_back
      case 'backLeft':
        return p_backLeft
      case 'centerLeft':
        return p_left
      // TODO: We will most likely want to add a graphic for when all photos are taken
      default:
        return ''
    }
  }

  render() {
    const {
      directions,
      currentDirection,
      activeDirection,
      activeNotes,
      editing,
      loading,
    } = this.state
    const { survey } = this.props
    const { photos } = this.props.survey

    const formattedActiveDirection = toTitleCase(
      splitStringByCapital(directions[activeDirection] ? directions[activeDirection] : '')
    )

    const formattedCurrentDirection = toTitleCase(
      splitStringByCapital(directions[currentDirection] ? directions[currentDirection] : '')
    )

    return (
      <StyledSurveyPhotos>
        <PhotoBox
          presentational={false}
          header={`${formattedActiveDirection} Side of the Property`}
          imageUrl={editing ? photos[directions[activeDirection]].large : null}
          messages={activeNotes}
          editing={editing}
          close={this.close}
          changePhoto={this.handleFile}
          saveNotes={this.saveNotes}
          handleChange={this.handleChange}
          loading={loading}
          type="notes"
        />
        <Header
          title={
            directions[currentDirection]
              ? `Photograph the ${formattedCurrentDirection} side of the Property.`
              : 'All photos taken'
          }
          noBorderBottom
        />
        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <div className="grid">
            {directions[currentDirection] && (
              <div className="mbm">
                {!loading && directions[currentDirection] && (
                  <ImageInput
                    id="imageInput1"
                    name={directions[currentDirection]}
                    handleFile={this.handleFile}
                    text="Upload file"
                    // variant="nm sameColorBorder"
                  />
                )}
              </div>
            )}
            {directions[currentDirection] && (
              <img className="direction-img" src={this.getImage()} alt="" />
            )}
            {photos && (
              <Thumbnails
                photos={photos}
                handleClick={this.editPhoto}
                marginTop={directions[currentDirection] ? '1em' : 0}
              />
            )}

            {loading && <Loader />}
          </div>
        </Container>
      </StyledSurveyPhotos>
    )
  }
}
export default SurveyPhotos

const StyledSurveyPhotos = styled.div`
  .direction-img {
    max-width: 496px;
    height: auto;
  }
`
