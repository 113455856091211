import {
  LOADING,
  ERROR,
  UPDATE_FILE,
  REMOVE_FILE,
  ADD_FILE,
  UPDATE_STEP,
  FETCH_PAPERWORK,
  UPDATE_PAPERWORK,
  HOA_EMAIL_SENDING,
  HOA_EMAIL_ERROR,
  HOA_EMAIL_SENT,
} from './types'

const initState = {
  loading: false,
  error: null,
  status: 'Gather Files',
  hoaStatus: 'To Send', // To Send, Pending Approval, Approved
  permitsStatus: 'To Send',
  paperwork: {
    files: [],
    additionalFiles: [],
  },
  hoaEmailSending: false,
  hoaEmailError: null,
  hoaEmailSent: false,
}

const paperworkReducer = (state = initState, action) => {
  let idx
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case ERROR:
      return {
        ...state,
        error: action.payload.message,
      }
    case UPDATE_FILE:
      idx = state.paperwork[action.payload.whichArr].findIndex(
        file => file.id === action.payload.fileid
      )
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          [action.payload.whichArr]: [
            ...state.paperwork[action.payload.whichArr].slice(0, idx),
            {
              ...state.paperwork[action.payload.whichArr][idx],
              ...action.payload.updates,
            },
            ...state.paperwork[action.payload.whichArr].slice(idx + 1),
          ],
        },
      }

    case REMOVE_FILE:
      idx = state.paperwork[action.payload.whichArr].findIndex(
        file => file.id === action.payload.fileid
      )
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          [action.payload.whichArr]: [
            ...state.paperwork[action.payload.whichArr].slice(0, idx),
            ...state.paperwork[action.payload.whichArr].slice(idx + 1),
          ],
        },
      }

    case ADD_FILE:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          additionalFiles: [
            ...state.paperwork.additionalFiles,
            {
              id: action.payload.fileId,
              name: action.payload.name,
              responsible: action.payload.responsible,
              filename: null,
              filepath: null,
              status: null,
              type: action.payload.type,
            },
          ],
        },
      }

    case UPDATE_STEP:
      return {
        ...state,
        [action.payload.which]: action.payload.newStep,
      }

    case FETCH_PAPERWORK:
      return {
        ...state,
        hoaStatus: action.payload.data.hoaStatus,
        permitsStatus: action.payload.data.permitsStatus,
        paperwork: {
          ...state.paperwork,
          files: action.payload.data.files,
          additionalFiles: action.payload.data.additionalFiles,
        },
        hoaEmailSent: action.payload.data.hoaEmailSent,
      }

    case UPDATE_PAPERWORK:
      return {
        ...state,
        ...action.payload.updates,
      }

    case HOA_EMAIL_SENDING:
      return {
        ...state,
        hoaEmailSending: action.payload.sending,
      }

    case HOA_EMAIL_ERROR:
      return {
        ...state,
        hoaEmailError: action.payload.msg,
      }

    case HOA_EMAIL_SENT:
      return {
        ...state,
        hoaEmailSent: true,
      }

    default:
      return state
  }
}

export default paperworkReducer
