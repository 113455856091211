import React, { Component } from 'react'
import styled from 'styled-components'

import Button from './../../../sharedComponents/Button'
import ImageInput from './../../../sharedComponents/ImageInput'
import Icon from './../../../sharedComponents/Icon'

class PictureBtn extends Component {
  shouldComponentUpdate = (nextProps, nextState) => {
    // check to see if the props being passed in havent changed, if props.photo
    // hasnt changed dont re render, other yes, re render
    return JSON.stringify(nextProps.photo) === JSON.stringify(this.props.photo) ? false : true
  }

  render() {
    const { photo, placement } = this.props
    let btnBg, iconFill

    if (photo && !photo.image && !photo.status) {
      btnBg = 'dark'
      iconFill = 'white'
    } else if (photo && photo.image && photo.status && photo.status === 'uploaded') {
      btnBg = 'dark'
      iconFill = 'primary'
    } else if (photo && photo.image && photo.status && photo.status === 'revise') {
      btnBg = 'danger'
      iconFill = 'white'
    } else if (photo && photo.image && photo.status && photo.status === 'approved') {
      btnBg = 'primary'
      iconFill = 'white'
    } else {
      btnBg = 'dark'
      iconFill = 'white'
    }

    // console.log('huhuhuhuh', btnBg, iconFill)

    return (
      <div className={`picture-btn ${placement}`}>
        <Button
          variant={`round-sm ${btnBg}`}
          icon={<Icon icon="camera" color={iconFill} />}
          handleClick={() => (this.props.handleClick ? this.props.handleClick() : null)}
        />
      </div>
    )
  }
}

export default PictureBtn
