import React from 'react'
import styled from 'styled-components'
import { toTitleCase } from './../../utils'

const StyledSurveyCard = styled.div`
  .inner {
    height: 100%;
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 4px 4px 0 0;
      height: auto;
      display: block;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      transition: 0.15s ease-out;
    }

    .title {
      font-size: 1rem;
      font-weight: 600;
      padding: 0.5rem;
      border: 1px solid #eee;
      border-radius: 0 0 4px 4px;
      line-height: 1.15;
      transition: 0.15s ease-out;
    }
  }

  .selected {
    img {
      border-top: 1px solid #94d82d;
      border-left: 1px solid #94d82d;
      border-right: 1px solid #94d82d;
    }

    .title {
      border: 1px solid #94d82d;
      background-color: #94d82d;
      color: #fff;
    }

    &:hover,
    &:focus {
      img {
        border-top: 1px solid #94d82d;
        border-left: 1px solid #94d82d;
        border-right: 1px solid #94d82d;
      }

      .title {
        border: 1px solid #94d82d;
        background-color: #94d82d;
        color: #fff;
      }
    }
  }

  &:hover,
  &:focus {
    img {
      border-top: 1px solid #adb5bd;
      border-left: 1px solid #adb5bd;
      border-right: 1px solid #adb5bd;
    }

    .title {
      border: 1px solid #adb5bd;
      background-color: #adb5bd;
      color: #fff;
    }
  }
`

const SurveyCard = props => {
  return (
    <StyledSurveyCard onClick={props.handleClick}>
      <div className={props.selected ? 'inner selected' : 'inner'}>
        <img src={props.img} alt={props.text} />
        <div className="title">{toTitleCase(props.text)}</div>
      </div>
    </StyledSurveyCard>
  )
}

export default SurveyCard
