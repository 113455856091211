import {
  loading as _loading,
  requestError as _requestError,
  loadGlobalSettings as _loadGlobalSettings,
  updateTimer as _updateTimer,
  updateSurveyDt as _updateSurveyDt,
} from './actions'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'

export function fetchGlobalSettings() {
  return async dispatch => {
    try {
      dispatch(_loading(true))
      const response = await axios.get(`${addProductionUrl()}/api/global-settings`)
      dispatch(_loadGlobalSettings(response.data))
      dispatch(_loading(false))
    } catch (err) {
      console.log('got err', err)
      dispatch(_requestError('Error loading Global Settings.'))
      dispatch(_loading(false))
    }
  }
}

export function updateTimer(timerGroup, timerName, updatedTime) {
  return async dispatch => {
    try {
      axios.patch(`${addProductionUrl()}/api/global-settings/timers`, {
        timerGroup,
        timerName,
        updatedTime,
      })
      dispatch(_updateTimer(timerGroup, timerName, updatedTime))
    } catch (err) {
      console.log('got err', err)
      dispatch(_requestError('Error loading Global Settings.'))
    }
  }
}

export function updateSurveyDt(newDt) {
  return async dispatch => {
    try {
      axios.patch(`${addProductionUrl()}/api/global-settings/surveyDt`, {
        newDt,
      })
      dispatch(_updateSurveyDt(newDt))
    } catch (err) {
      console.log('got err', err)
      dispatch(_requestError('Error loading Global Settings.'))
    }
  }
}
