import React, { Component, Fragment } from 'react'
import qs from 'qs'
import { isEmpty } from 'lodash'
import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  startOfMonth,
  getMonth,
  getDate,
} from 'date-fns'

import * as Scheduler from './styles'
import { colors, breakpoints } from './../../helpers/foundation'

import Header from './../sharedComponents/Header'
import Week from './components/Week'
import MetaRow from './components/MetaRow'
import MonthCalendar from './components/MonthCalendar'
import Button from './../sharedComponents/Button'
import Icon from './../sharedComponents/Icon'
import Loader from './../sharedComponents/Loader'
import Modal from './../sharedComponents/Modal'
import Panel from './../sharedComponents/Panel'
import ScheduleConfirmationModal from './components/ScheduleConfirmationModal'
import ProjectBoard from './../sharedComponents/layouts/ProjectBoard'
import CustomTimePanel from './components/CustomTimePanel'

// namespace so we can use styles as Scheduler.Something
class _Scheduler extends Component {
  state = {
    customTimeOpen: false,
    // will be loggedIn, userExists, userDoesNotExist
    // whenever this is set (string), expect modal to be open
    formOfConfirmation: null,
    quoteSelectedTime: null,
    // used to show loader while creating user/project/scheduling
    schedulingTime: false,
    // remove glitchy refresh of reloading times after time
    // already scheduled and while modal is still open
    timeScheduled: false,

    // might want to move this into redux
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: startOfWeek(new Date()),

    // for conditional to use within ProjectBoard
    pageWidth: 0,
    height: 0,

    // for small screens, state of calendar modal
    calendarModalOpen: false,

    // set to true when loaded, if is false will not animate skeleton slots
    initialLoad: false,
  }

  async componentDidMount() {
    try {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
      const { events, project } = this.props
      if (project && !this.props.isFromQuote) {
        // get events for project if it was created by OFS
        await this.props.getEvents(events.start, events.end, project)
      } else {
        const token = qs.parse(this.props.location.search.slice(1))['token'] || null
        await this.props.fetchQuote(token)
        if (this.props.events.quote) {
          // get the events, fake project data needed to get the datetimes
          await this.props.getEvents(events.start, events.end, {
            additionalClientInfo: {
              address: this.props.events.quote.address,
              city: this.props.events.quote.city,
              state: this.props.events.quote.state,
              zip: this.props.events.quote.zip,
            },
          })
        }
      }
      // set the start & end of week
      const start = startOfWeek(this.props.events.start, 1)
      const end = endOfWeek(this.props.events.end, 1)
      this.setState(prevState => ({
        startOfWeek: start,
        endOfWeek: end,
        initialLoad: true,
      }))
    } catch (err) {
      console.log('there was an err', err)
    }
  }

  updateWindowDimensions = () => {
    this.setState(prevState => ({ pageWidth: window.innerWidth, height: window.innerHeight }))
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions)
    this.props.reset()
    this.setState(prevState => ({
      startOfWeek: startOfWeek(new Date()),
      endOfWeek: startOfWeek(new Date()),
      schedulingTime: false,
    }))
  }

  lastWeek = () => {
    const { events, project } = this.props
    try {
      const currentStartDay = events.start

      const lastStartWeek = startOfWeek(subWeeks(currentStartDay, 1))
      const lastEndWeek = endOfWeek(subWeeks(currentStartDay, 1))

      let newMonth = null
      if (
        getMonth(lastStartWeek) !== getMonth(lastEndWeek) ||
        getMonth(lastStartWeek) !== events.calendar.currentMonth
      ) {
        newMonth = getMonth(lastStartWeek)
      }

      if (!events.loadingEvents) {
        this.setState(prevState => ({ startOfWeek: lastStartWeek, endOfWeek: lastEndWeek }))
        this.props.getEvents(
          lastStartWeek,
          lastEndWeek,
          !this.props.isFromQuote
            ? project
            : {
                additionalClientInfo: {
                  address: this.props.events.quote.address,
                  city: this.props.events.quote.city,
                  state: this.props.events.quote.state,
                  zip: this.props.events.quote.zip,
                },
              },
          newMonth
        )
      }
    } catch (err) {
      console.log('there was err', err)
    }
  }

  nextWeek = () => {
    const { events, project } = this.props
    try {
      const currentStartDay = events.start

      const nextStartWeek = startOfWeek(addWeeks(currentStartDay, 1))
      const nextEndWeek = endOfWeek(addWeeks(currentStartDay, 1))

      let newMonth = null
      if (getMonth(nextStartWeek) !== getMonth(nextEndWeek)) {
        newMonth = getMonth(nextEndWeek)
      }

      if (!events.loadingEvents) {
        this.setState(prevState => ({ startOfWeek: nextStartWeek, endOfWeek: nextEndWeek }))
        this.props.getEvents(
          nextStartWeek,
          nextEndWeek,
          !this.props.isFromQuote
            ? project
            : {
                additionalClientInfo: {
                  address: this.props.events.quote.address,
                  city: this.props.events.quote.city,
                  state: this.props.events.quote.state,
                  zip: this.props.events.quote.zip,
                },
              },
          newMonth
        )
        // this._projectBoard.resetBoard()
      }
    } catch (err) {
      console.log('there was err', err)
    }
  }

  changeMonth = (month, year) => {
    const { events, project } = this.props

    try {
      const monthDay = startOfMonth(new Date(year, month))

      const startWeek = startOfWeek(monthDay)
      const endWeek = endOfWeek(monthDay)

      if (!events.loadingEvents) {
        this.setState(prevState => ({ startOfWeek: startWeek, endOfWeek: endWeek }))
        this.props.getEvents(
          startWeek,
          endWeek,
          !this.props.isFromQuote
            ? project
            : {
                additionalClientInfo: {
                  address: this.props.events.quote.address,
                  city: this.props.events.quote.city,
                  state: this.props.events.quote.state,
                  zip: this.props.events.quote.zip,
                },
              },
          month,
          year
        )
      }
    } catch (err) {
      // console.log('there was err', err)
    }
  }

  scheduleTime = (selectedTime, project, user) => {
    const { history, confirmTime, events } = this.props

    confirmTime(selectedTime, project, events.slots, user).then(res => {
      this.setState(prevState => ({ timeScheduled: true }))
      if (user.role !== 'Customer') {
        history.push('/projects/surveys')
      }
    })
  }

  // when trying to schedule a time via get quote form
  openConfirmationModal = async (selectedTime, user = null) => {
    try {
      // is user logged in
      if (user && user.email === this.props.events.quote.email) {
        // create project
        this.setState(prevState => ({
          quoteSelectedTime: selectedTime,
          formOfConfirmation: 'loggedIn',
        }))
        // schedule time
      } else {
        // query params does not like plus aliasing
        const encodedEmail = btoa(this.props.events.quote.email)
        const existingUser = await this.props.getCustomerByEmail(encodedEmail)

        if (existingUser) {
          this.setState(prevState => ({
            quoteSelectedTime: selectedTime,
            formOfConfirmation: 'userExists',
          }))
        } else {
          this.setState(prevState => ({
            quoteSelectedTime: selectedTime,
            formOfConfirmation: 'userDoesNotExist',
          }))
        }
      }
    } catch (err) {
      console.log('there was err', err)
    }
  }

  closeScheduleConfirmationModal = () => {
    this.setState(prevState => ({
      formOfConfirmation: null,
    }))
  }

  toggleCalendarModal = tof => {
    this.setState(prevState => ({ calendarModalOpen: tof }))
  }

  toggleCustomTimeOpen = () => {
    this.setState(prevState => ({ customTimeOpen: !prevState.customTimeOpen }))
  }

  // returns 3 letter month abbreviation
  getMonthAbvr = (year, month) => format(new Date(year, month), 'MMM')
  getMonthFull = (year, month) => format(new Date(year, month), 'MMMM')

  render() {
    let address = '',
      city = '',
      state = '',
      zip = ''
    if (this.props.events.quote) {
      address = this.props.events.quote.address
      city = this.props.events.quote.city
      state = this.props.events.quote.state
      zip = this.props.events.quote.zip
    }
    if (!isEmpty(this.props.project)) {
      address = this.props.project.additionalClientInfo.address
      city = this.props.project.additionalClientInfo.city
      state = this.props.project.additionalClientInfo.state
      zip = this.props.project.additionalClientInfo.zip
    }

    // used when there is an error and we dont want to show certain actions in the MetaRow
    // const hideActions =
    //   this.props.events.error || !this.props.events.quoteExists || !this.props.events.quoteValid
    //     ? true
    //     : false
    // this was causing an error on production
    const hideActions = false

    return (
      <>
        <Header title="Select the time that would work best for your free on-site survey." />
        <Scheduler.Layout user={!isEmpty(this.props.user) ? true : false}>
          {this.state.formOfConfirmation && (
            <ScheduleConfirmationModal
              closeModal={this.closeScheduleConfirmationModal}
              formOfConfirmation={this.state.formOfConfirmation}
              quote={this.props.events.quote}
              selectedTime={this.props.events.selectedTime}
              scheduleTime={this.scheduleTime}
              login={this.props.login}
              history={this.props.history}
              user={this.props.user || null}
            />
          )}
          {this.props.user && this.props.user.role !== 'Customer' && (
            <Panel
              open={this.state.customTimeOpen}
              size="550"
              color={colors.grey5}
              attached={false}
              padded={true}
              position="right"
            >
              <CustomTimePanel
                toggleCustomTimeOpen={this.toggleCustomTimeOpen}
                events={this.props.events}
                getSurveyors={
                  this.props.user && this.props.user.role !== 'Customer'
                    ? this.props.getSurveyors
                    : () => {}
                }
                scheduleCustomTime={this.props.scheduleCustomTime}
                project={this.props.project}
                history={this.props.history}
                user={this.props.user}
              />
            </Panel>
          )}
          <Scheduler.Content>
            <Scheduler.Side hasPaddingTop={true}>
              <MonthCalendar
                {...this.props.events.calendar}
                changeMonth={this.changeMonth}
                hideActions={hideActions}
              />
              {address && (
                <div className="info">
                  <div className="address">
                    <div className="line">{address}</div>
                    <div className="line">
                      {city}, {state} {zip}
                    </div>
                  </div>
                </div>
              )}
              {this.props.user && this.props.user.role !== 'Customer' && (
                <div className="custom-time" onClick={this.toggleCustomTimeOpen}>
                  Use custom time
                </div>
              )}

              {this.props.isFromQuote && !(this.state.pageWidth < +breakpoints.medium) && (
                <div className="help">
                  <div className="head">Can't find a time? Contact us:</div>
                  <div>
                    <a href="mailto:info@osceolafencesupply.com">info@osceolafencesupply.com</a>
                  </div>
                  <div>(407) 744 0828</div>
                </div>
              )}

              {this.props.events.selectedTime && (
                <div className="actions">
                  {this.props.events.confirmingTime && !this.props.isFromQuote && (
                    <>
                      <Loader small={true} />
                      <br />
                    </>
                  )}
                  <Button
                    text="Cancel"
                    variant={`${this.props.events.confirmingTime ? 'disabled' : 'danger'} lg`}
                    fluid={true}
                    handleClick={e => this.props.selectTime(null)}
                  />
                  {this.props.user && this.props.project && !this.props.isFromQuote ? (
                    <Button
                      text="Schedule"
                      fluid={true}
                      handleClick={
                        this.state.loading
                          ? null
                          : () =>
                              this.scheduleTime(
                                this.props.events.selectedTime,
                                this.props.project,
                                this.props.user
                              )
                      }
                      variant={
                        this.state.loading || this.props.events.confirmingTime
                          ? 'disabled lg'
                          : 'primary lg'
                      }
                    />
                  ) : (
                    <Button
                      text="Schedule"
                      fluid={true}
                      handleClick={
                        this.state.loading
                          ? null
                          : () =>
                              this.openConfirmationModal(
                                this.props.events.selectedTime,
                                this.props.user
                              )
                      }
                      variant={
                        this.state.loading || this.props.events.confirmingTime
                          ? 'disabled lg'
                          : 'primary lg'
                      }
                    />
                  )}
                </div>
              )}
            </Scheduler.Side>
            <Scheduler.Main>
              <div className="above">
                <Button
                  text="Calendar"
                  icon={<Icon icon="calendar" color={hideActions ? 'grey2' : 'white'} />}
                  fluid={true}
                  variant={`lg ${hideActions ? 'disabled' : ''}`}
                  disabled={hideActions}
                  handleClick={e => {
                    if (!hideActions) {
                      this.toggleCalendarModal(true)
                    }
                  }}
                />
              </div>
              {this.props.isFromQuote && this.state.pageWidth < +breakpoints.medium && (
                <div className="small-help">
                  Can't find a time? Contact us at{' '}
                  <span>
                    <a href="mailto:info@osceolafencesupply.com">info@osceolafencesupply.com</a>
                  </span>{' '}
                  <span>or (407) 744 0828</span>
                </div>
              )}
              <MetaRow
                lastWeek={this.lastWeek}
                nextWeek={this.nextWeek}
                startOfWeek={this.state.startOfWeek}
                endOfWeek={this.state.endOfWeek}
                currentMonth={this.props.events.calendar.currentMonth}
                currentYear={this.props.events.calendar.currentYear}
                selectTime={this.props.selectTime} // for clearing when changing weeks
                getMonthAbvr={this.getMonthAbvr}
                getMonthFull={this.getMonthFull}
                resetBoard={
                  this._projectBoard && this._projectBoard.resetBoard
                    ? this._projectBoard.resetBoard
                    : () => {}
                }
                hideActions={hideActions}
              />

              <>
                {/* HANDLE ERRORS */}
                {!this.props.events.loadingEvents && this.props.events.slots.length < 1 ? (
                  <>
                    {(!this.props.events.quoteExists || !this.props.events.quoteValid) && (
                      <>
                        {/* quote does not exist in db */}
                        {!this.props.events.quoteExists && !this.props.events.quoteValid && (
                          <Scheduler.Error
                            className="no-slots"
                            isDayView={this.state.pageWidth < +breakpoints.medium}
                          >
                            <div className="center">
                              <Icon icon="bigClock" />
                              <div className="error-title">
                                Sorry, we are having trouble finding your quote.
                              </div>
                              <div className="error-message">
                                Shoot us an email at{' '}
                                <a href="mailto:info@osceolafencesupply.com">
                                  info@osceolafencesupply.com
                                </a>{' '}
                                or give us a call at (407) 744 0828.
                              </div>
                            </div>
                          </Scheduler.Error>
                        )}
                        {/* quote exists but has already been used */}
                        {this.props.events.quoteExists && !this.props.events.quoteValid && (
                          <Scheduler.Error
                            className="no-slots"
                            isDayView={this.state.pageWidth < +breakpoints.medium}
                          >
                            <div className="center">
                              <Icon icon="bigClock" />
                              <div className="error-title">
                                Sorry, it appears there is already a survey scheduled using this
                                quote.
                              </div>
                              <div className="error-message">
                                Shoot us an email at{' '}
                                <a href="mailto:info@osceolafencesupply.com">
                                  info@osceolafencesupply.com
                                </a>{' '}
                                or give us a call at (407) 744 0828.
                              </div>
                            </div>
                          </Scheduler.Error>
                        )}
                      </>
                    )}
                    {/* No slots exist for the week */}
                    {this.props.events.error &&
                      this.props.events.quoteExists &&
                      this.props.events.quoteValid && (
                        <Scheduler.Error
                          className="no-slots"
                          isDayView={this.state.pageWidth < +breakpoints.medium}
                        >
                          <div className="center">
                            <Icon icon="bigClock" />
                            <div className="error-title">No times available this week.</div>
                            <div className="error-message">
                              Sorry, we do not have any available times for the week of{' '}
                              {/* the current week */}
                              {this.getMonthAbvr(null, getMonth(this.state.startOfWeek))}{' '}
                              {getDate(addDays(this.state.startOfWeek, 1))} -{' '}
                              {this.getMonthAbvr(null, getMonth(this.state.endOfWeek))}{' '}
                              {getDate(this.state.endOfWeek)}.
                            </div>
                            <Button text="Next week" variant="lg" handleClick={this.nextWeek} />
                          </div>
                        </Scheduler.Error>
                      )}

                    {/* No slots */}
                    {!this.props.events.error &&
                      this.props.events.quoteExists &&
                      this.props.events.quoteValid && (
                        <Scheduler.Error
                          className="no-slots"
                          isDayView={this.state.pageWidth < +breakpoints.medium}
                        >
                          <div className="center">
                            <Icon icon="bigClock" />
                            <div className="error-title">No times available this week.</div>
                            <div className="error-message">
                              Sorry, we do not have any available times for the week of{' '}
                              {/* the current week */}
                              {this.getMonthAbvr(null, getMonth(this.state.startOfWeek))}{' '}
                              {getDate(addDays(this.state.startOfWeek, 1))} -{' '}
                              {this.getMonthAbvr(null, getMonth(this.state.endOfWeek))}{' '}
                              {getDate(this.state.endOfWeek)}.
                            </div>
                            <Button text="Next week" variant="lg" handleClick={this.nextWeek} />
                          </div>
                        </Scheduler.Error>
                      )}
                  </>
                ) : (
                  <ProjectBoard
                    hasBreakpoint={true}
                    breakpoint={1580}
                    currentWidth={this.state.pageWidth}
                    ref={c => (this._projectBoard = c)}
                  >
                    <Week
                      slots={this.props.events.slots}
                      user={this.props.user}
                      selectedTime={this.props.events.selectedTime}
                      isCustomer={
                        (this.props.user && this.props.user.role === 'Customer') || !this.props.user
                      }
                      // do not use sunday
                      startOfWeek={this.state.startOfWeek}
                      endOfWeek={this.state.endOfWeek}
                      loadingEvents={this.props.events.loadingEvents}
                      // loadingEvents={true}
                      selectTime={this.props.selectTime}
                      openConfirmationModal={this.openConfirmationModal}
                      isDayView={this.state.pageWidth < +breakpoints.medium}
                      lastWeek={this.lastWeek}
                      nextWeek={this.nextWeek}
                      initialLoad={this.state.initialLoad}
                    />
                  </ProjectBoard>
                )}
              </>
            </Scheduler.Main>
          </Scheduler.Content>
        </Scheduler.Layout>
        {this.state.calendarModalOpen && (
          <Modal
            width="80%"
            close={e => this.toggleCalendarModal(false)}
            open={e => this.toggleCalendarModal(true)}
          >
            <MonthCalendar
              {...this.props.events.calendar}
              changeMonth={this.changeMonth}
              forModal
              toggleCalendarModal={this.toggleCalendarModal}
            />
          </Modal>
        )}
      </>
    )
  }
}

export default _Scheduler
