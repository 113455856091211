import React, { Component } from 'react'
import { colors } from './../../../helpers/foundation'

import Card from './../../sharedComponents/Card'
import Tag from '../../sharedComponents/Tag'
import Reveal from '../../sharedComponents/Reveal'

const tagColor = {
  quote: `${colors.grey6}`,
  survey: `${colors.blue}`,
  estimate: `${colors.purple}`,
  paperwork: `${colors.orange}`,
  overdue: `${colors.danger}`,
  installation: `${colors.pink}`,
}

class QuoteProjectCard extends Component {
  state = {
    menuIsOpen: false,
    duplicateError: null,
  }

  // for the toggling of context menu
  componentDidMount() {
    document.addEventListener('mousedown', this.closeMenu)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeMenu)
  }

  openQuoteModal = e => {
    this.props.openModal({ isQuote: true, ...this.props })
  }

  toggleMenu = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
    })
  }

  closeMenu = e => {
    if (this.reveal && !this.reveal.contains(e.target)) {
      this.setState({
        menuIsOpen: false,
      })
    }
  }

  render() {
    const { compact, stage, item, user, isAdmin, fakeItem } = this.props
    const { menuIsOpen } = this.state

    const menu = (
      <Reveal key={item._id}>
        <div className="inner" ref={node => (this.reveal = node)}>
          <div className="item" onClick={this.openQuoteModal}>
            More Info
          </div>
          {isAdmin && (
            <div
              className="item"
              onClick={() => this.props.openDeleteProjectModal(item._id, { isQuote: true })}
            >
              Delete Quote
            </div>
          )}
        </div>
      </Reveal>
    )

    return (
      <Card
        link="#"
        compact={true}
        // isSimple={true}
        pointer={true}
        hasMenu={user.role !== 'Customer' ? true : false}
        menu={menu}
        menuIsOpen={menuIsOpen}
        // toggleMenu={this.toggleMenu}
        handleClick={this.toggleMenu}
        toggleMenu={this.toggleMenu}
        fakeItem={fakeItem}
      >
        <h2 className="title">{item.full_name}</h2>

        {!compact ? (
          <p className="info">
            {item.address} <br /> {item.city}, {item.state} {item.zip}
          </p>
        ) : (
          <p className="info">
            {item.address} {item.city}, {item.state} {item.zip}
          </p>
        )}

        <div className="footer flex-between">
          <div className="left">
            <Tag variant={fakeItem ? 'skeleton' : ''} color={tagColor['quote']}>
              Quote
            </Tag>
          </div>
        </div>
      </Card>
    )
  }
}

export default QuoteProjectCard
