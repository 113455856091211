// NOTE: Only being used in quick edit for survey

import { connect } from 'react-redux'
import SettingsForm from '../components/pages/Settings/SettingsForm'
import { updateUser } from '../redux/modules/settingsForm'

const mapStateToProps = state => {
  return {
    loading: state.settingsForm.loading,
    error: state.settingsForm.error,
    success: state.settingsForm.success,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (uid, data) => dispatch(updateUser(uid, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsForm)
