import {
  REQUESTING_PROJECT,
  ERROR_REQUESTING_PROJECT,
  UPDATE_ACTIVE_PROJECT,
  UPDATE_ACTIVE_PROJECT_PART,
  ERROR_UPDATING_PROJECT,
  UPDATING_PROJECT,
  UPDATE_STAGE_STATUS,
  CLEAR_PROJECT,
} from './types'

const initState = {
  requestingProject: false,
  requestProjectError: null,
  updatingProject: false,
  updateProjectError: null,
  project: null,
}

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case REQUESTING_PROJECT:
      return {
        ...state,
        requestingProject: true,
        requestProjectError: null,
      }
    case ERROR_REQUESTING_PROJECT:
      return {
        ...state,
        requestingProject: false,
        requestProjectError: action.payload.error,
      }
    case UPDATE_ACTIVE_PROJECT:
      return {
        ...state,
        requestingProject: false,
        updatingProject: false,
        requestProjectError: null,
        updateProjectError: null,
        project: {
          ...state.project,
          ...action.payload.project,
        },
      }
    case UPDATE_ACTIVE_PROJECT_PART:
      return {
        ...state,
        requestingProject: false,
        updatingProject: false,
        requestProjectError: null,
        updateProjectError: null,
        project: {
          ...state.project,
          [action.payload.field]: action.payload.data,
        },
      }
    case CLEAR_PROJECT:
      return {
        project: null,
      }
    case UPDATING_PROJECT:
      return {
        ...state,
        updatingProject: true,
        updateProjectError: null,
      }
    case ERROR_UPDATING_PROJECT:
      return {
        ...state,
        updatingProject: false,
        updateProjectError: action.payload.error,
      }
    case UPDATE_STAGE_STATUS:
      return {
        ...state,
        project: {
          ...state.project,
          [action.payload.stage]: {
            ...state.project[action.payload.stage],
            status: action.payload.status,
          },
        },
      }
    default:
      return state
  }
}

export default projectReducer
