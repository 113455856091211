import React, { Component } from 'react'
import styled from 'styled-components'

import Button from './../../../sharedComponents/Button'
import Message from './../../../sharedComponents/Message'
import Header from './../../../sharedComponents/Header'
import MediaCard from './../../../sharedComponents/MediaCard'
import Container from './../../../sharedComponents/Container'
import Sector from './../../../sharedComponents/Sector'

const StyledEmployeeAssignEstimator = styled.div`
  .surveyor {
    max-width: 600px;
  }

  select {
    display: block;
    background: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    font-size: 1.2em;
  }

  label {
    font-size: 1em;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }

  .form-group {
    display: block;
    margin-bottom: 20px;
  }
`

class EmployeeAssignEstimator extends Component {
  state = {
    estimator: [],
    selectedEstimator: 0,
    error: '',
  }

  componentDidMount = () => {
    this.props.employeeGetEmployees('Estimate').then(estimator => {
      if (estimator && estimator.length > 0) {
        this.setState(prevState => {
          return { estimator }
        })
      }
    })
  }

  handleChange = e => {
    e.preventDefault()
    const tarVal = e.target.value
    const estimator = this.state.estimator.findIndex(surveyor => surveyor.displayName === tarVal)

    this.setState(prevState => {
      return {
        selectedEstimator: estimator,
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { estimator, selectedEstimator } = this.state
    const member = estimator[selectedEstimator]

    this.props.employeeAddMember(this.props.project._id, member._id, 'estimate').then(res => {
      this.props.history.push('/projects/estimates')
    })
  }

  render() {
    const { selectedEstimator, estimator } = this.state
    const selected = estimator[selectedEstimator]

    return (
      <StyledEmployeeAssignEstimator>
        <Header title="Assign an Estimator" />

        <Container>
          <Sector>
            {this.state.error && <Message type="error" message={this.state.error} />}
            {estimator.length > 0 ? (
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="selectedEstimator">Estimator</label>
                  <select
                    name="selectedEstimator"
                    id="selectedEstimator"
                    value={selected.displayName}
                    onChange={this.handleChange}
                  >
                    {estimator.map(({ displayName, _id }, idx) => {
                      return (
                        <option
                          value={displayName}
                          key={_id}
                          defaultValue={idx === selectedEstimator ? true : false}
                        >
                          {displayName}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <Button type="submit" text="Add Estimator" />
              </form>
            ) : (
              ''
            )}
          </Sector>

          {estimator.length > 0 ? (
            <Sector>
              <div className="surveyor">
                <MediaCard
                  title={estimator[selectedEstimator].displayName}
                  description={estimator[selectedEstimator].bio}
                  portrait={estimator[selectedEstimator].profileImage}
                  alt={estimator[selectedEstimator].displayName}
                />
              </div>
            </Sector>
          ) : (
            ''
          )}
        </Container>
      </StyledEmployeeAssignEstimator>
    )
  }
}

export default EmployeeAssignEstimator
