import React, { Component } from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { addProductionUrl } from './../../../../helpers/constants'
import { navSize, breakpoints } from './../../../../helpers/foundation'
import isRole from './../../../../helpers/roles'

import Container from './../../../sharedComponents/Container'
import Sector from './../../../sharedComponents/Sector'
import DateTime from './components/DateTime'
import ProjectGraph from './components/ProjectGraph'
import Timers from './components/Timers/'
import DriveTime from './components/DriveTime/'

class AdminDashboard extends Component {
  _isMounted = false

  state = {
    counts: {},
    mounted: true,
  }

  componentDidMount() {
    this._isMounted = true

    axios.get(`${addProductionUrl()}/api/projects/count`).then(res => {
      this.setState(prevState => ({ counts: res.data }))
    })

    if (!this.props.settingsLoaded) {
      this.props.fetchGlobalSettings()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { projects, globalSettings, updateTimer, updateSurveyDt } = this.props

    return (
      <StyledOverview>
        <Container>
          <div className="left">
            <Sector bordered={true}>
              <DriveTime surveyDt={globalSettings.surveyDt} updateSurveyDt={updateSurveyDt} />
            </Sector>
            <Sector bordered={true}>
              <Timers
                surveyTimers={globalSettings.surveyTimers || {}}
                estimateTimers={globalSettings.estimateTimers || {}}
                paperworkTimers={globalSettings.paperworkTimers || {}}
                installationTimers={globalSettings.installationTimers || {}}
                updateTimer={updateTimer}
              />
            </Sector>
          </div>
          <div className="right">
            {!isEmpty(this.state.counts) && (
              <Sector color="#fff" bordered={true}>
                <ProjectGraph data={this.state.counts} />
              </Sector>
            )}
          </div>
        </Container>
      </StyledOverview>
    )
  }
}

const StyledOverview = styled.div`
  .left {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    padding: 0;

    @media screen and (min-width: ${breakpoints.large}px) {
      position: fixed;
      height: calc(100vh - ${navSize.large});
      /* height: auto; */
      top: ${navSize.large};
      left: 0;
      width: 50%;
      padding: 20px 20px 0 20px;
      overflow: scroll;

      > div {
        width: 100%;
      }
    }
  }

  .right {
    @media screen and (min-width: ${breakpoints.large}px) {
      margin-left: 50%;
    }
  }

  .new-customer-form {
    width: 100%;
    overflow: auto;
  }

  /* pulled from timergroup to be used in survey drive time */

  .inner-sector {
    font-size: ${em('14px')};
    .title {
      font-size: 1.15em;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 1em;
    }
  }
`

export default AdminDashboard
