import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

import Image from './images/site-survey.jpg'

const CustomerSurveyInProgress = props => {
  return (
    <StyledCustomerSurveyInProgress>
      <Header title="Your site visit is in progress!" />
      <Container>
        <Notice text="Your surveyor has indicated that your site survey is in progress." />

        <div className="centered">
          <img src={Image} alt="Site survey graphic" />
        </div>
      </Container>
    </StyledCustomerSurveyInProgress>
  )
}

export default CustomerSurveyInProgress

const StyledCustomerSurveyInProgress = styled.div`
  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
