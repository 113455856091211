import React, { Component } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import shortid from 'shortid'
import { coordsFromAddress } from '../../../helpers/google/geocoding'
import Drawer from './Drawer'
import { colors, breakpoints, actionBarSize, navSize } from '../../../helpers/foundation'
import { dataURLToBlob } from './../../../helpers/images'

import BlueprintCommentModal from './components/BlueprintCommentModal'
import BlueprintComments from './components/BlueprintComments'
import Button from './../../sharedComponents/Button-NEW'
import Header from './../../sharedComponents/Header'

const colorsArr = ['green', 'blue', 'orange', 'pink', 'purple']

class SurveyBlueprintTwo extends Component {
  _isMounted = false

  state = {
    drawerOpen: false,
    // drawerOpen: true,
    currentSketchImage: null,
    currentSketchData: null,
    commentOpen: false,
    isEditing: false,
    editingCommentId: null,
    editingCommentText: '',
    editingCommentHex: '',
  }

  componentDidMount = () => {
    const { survey } = this.props
    this._isMounted = true
    if (this._isMounted) {
      // if these props are coming through, set them to state
      if (survey.blueprint.sketch && survey.blueprint.lines) {
        this.setState(prevState => ({
          currentSketchImage: survey.blueprint.sketch,
          currentSketchData: JSON.parse(survey.blueprint.lines),
        }))
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.hasBackgroundImage) {
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  toggleDrawer = () => {
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen,
    }))
  }

  saveSketch = (imageDataURL, data) => {
    const { survey } = this.props
    this.setState(
      prevState => ({
        drawerOpen: false,
        currentSketchImage: imageDataURL,
        currentSketchData: data,
      }),
      () => {
        const img = dataURLToBlob(imageDataURL)
        const formData = new FormData()
        formData.append('sketch', img)
        formData.append('lines', JSON.stringify(data))
        formData.append('projectId', survey.project._id ? survey.project._id : survey.project)
        this.props.updateSurveyServer(survey._id, formData).then(success => true)
      }
    )
  }

  /*
  from previous blueprint component
  */

  toggleOpenComment = () => {
    this.setState(prevState => {
      return {
        commentOpen: !prevState.commentOpen,
      }
    })
  }

  handleChange = e => {
    const tarName = e.target.name,
      tarVal = e.target.value
    this.setState(prevState => {
      return {
        [tarName]: tarVal,
      }
    })
  }

  closeComment = () => {
    this.setState({
      commentOpen: false,
      isEditing: false,
    })
  }

  openEditComment = (e, id) => {
    const selectedComment = this.props.survey.blueprint.comments.find(comment => comment._id === id)
    this.setState(prevState => {
      return {
        commentOpen: true,
        isEditing: true,
        editingCommentId: id,
        editingCommentText: selectedComment.text,
        editingCommentHex: selectedComment.hexcode,
      }
    })
  }

  selectColor = (e, color) => {
    const { isEditing } = this.state
    this.setState(prevState => {
      return {
        [isEditing ? 'editingCommentHex' : 'newCommentHex']: color === '' ? '' : colors[color],
      }
    })
  }

  editCommentAction = action => {
    // action = 'cancel' || 'delete' || 'update'
    let promise,
      serverNeedUpdate = true
    const { editingCommentId, editingCommentText, editingCommentHex } = this.state

    if (action === 'cancel') {
      serverNeedUpdate = false
      if (editingCommentText === '') return
    }
    if (action === 'delete') {
      promise = this.props.surveyBlueprintCommentsDelete(this.props.survey._id, {
        _id: this.state.editingCommentId,
      })
    }
    if (action === 'update') {
      promise = this.props.surveyBlueprintCommentsUpdate(this.props.survey._id, {
        _id: editingCommentId,
        text: editingCommentText,
        hexcode: editingCommentHex,
        updatedAt: new Date(),
      })
    }

    this.setState(
      prevState => {
        return {
          isEditing: false,
          commentOpen: false,
          editingCommentId: null,
          editingCommentText: '',
          editingCommentHex: '',
        }
      },
      () => {
        if (serverNeedUpdate) {
          promise.then(success => console.log('successfully updated comments!'))
        }
      }
    )
  }

  addComment = () => {
    const { newCommentText, newCommentHex } = this.state

    // dont allow adding a comment to proceed if there is no text
    if (newCommentText.length < 1) return

    // do more checking for the data being passed in
    const newComment = {
      user: this.props.user._id,
      number: this.props.survey.blueprint.comments.length + 1,
      hexcode: newCommentHex || '', // change me eventually
      text: newCommentText,
      createdAt: new Date(),
      updatedAt: new Date(),
      _id: shortid.generate(), // temp id
    }

    this.props.surveyBlueprintCommentsAdd(this.props.survey._id, newComment).then(success => {
      if (!success) return
      this.setState(prevState => {
        return {
          newCommentText: '',
          commentOpen: false,
        }
      })
    })
  }

  render() {
    const { survey } = this.props
    const { drawerOpen, currentSketchImage, currentSketchData } = this.state

    // check if there is a blueprint
    let hasBackgroundImage = false
    if (survey.blueprint.lines) {
      const bpLines = JSON.parse(survey.blueprint.lines)
      if (
        bpLines.backgroundImage &&
        !isEmpty(bpLines.backgroundImage) &&
        bpLines.backgroundImage.src !==
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='
      ) {
        hasBackgroundImage = true
      }
    }

    return (
      <StyledSurveyBlueprintTwo>
        {drawerOpen && (
          <div className="drawer-container">
            <Drawer
              toggleDrawer={this.toggleDrawer}
              getSketchJson={this.getSketchJson}
              saveSketch={this.saveSketch}
              currentSketchData={currentSketchData}
              additionalClientInfo={this.props.project.additionalClientInfo}
              surveyId={this.props.survey._id}
              changeStaticGoogleMapZoomLevel={this.changeStaticGoogleMapZoomLevel}
              zoomLevel={this.props.survey.blueprint.zoomLevel}
              hasBackgroundImage={hasBackgroundImage}
            />
          </div>
        )}

        <BlueprintCommentModal
          {...this.state}
          toggleOpenComment={this.toggleOpenComment}
          handleChange={this.handleChange}
          colorsArr={colorsArr}
          selectColor={this.selectColor}
          editCommentAction={this.editCommentAction}
          addComment={this.addComment}
          closeComment={this.closeComment}
        />

        <FullWidthContainer>
          <div className="sketch-image-container">
            <Header title="Blueprint" noBorderBottom />
            <div className="btn-row">
              <Button
                handleClick={this.toggleDrawer}
                text="Draw"
                variant="nm primary sameColorBorder"
              />
              <Button
                variant="nm bordered"
                text="Add Comment"
                handleClick={this.toggleOpenComment}
              />
            </div>
            {currentSketchImage && currentSketchData && <img src={currentSketchImage} alt="" />}
          </div>
          <BlueprintComments
            comments={this.props.survey.blueprint.comments}
            user={this.props.user}
            openEditComment={this.openEditComment}
            toggleOpenComment={this.toggleOpenComment}
          />
        </FullWidthContainer>
      </StyledSurveyBlueprintTwo>
    )
  }
}

export default SurveyBlueprintTwo

const StyledSurveyBlueprintTwo = styled.div`
  .drawer-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #fff;
  }

  .bottom-actions {
    position: absolute;
    bottom: 0;

    width: 100%;
    background-color: ${colors.lightGrey};
    display: flex;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .top-actions {
    display: flex;
    justify-content: space-between;
    .save {
      padding: 1em 0.5em;
      background-color: ${colors.primary};
      color: ${colors.white};
      width: 7em;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
    }

    .notice {
      font-weight: 600;
      padding-top: 0.5em;
      padding-right: 1em;
    }
  }

  .colors {
    position: absolute;
    bottom: 80px;
    right: 0;
  }

  .map-options {
    position: absolute;
    bottom: 80px;
    left: 0;
  }

  .add-google-map-container {
    position: absolute;
    bottom: 80px;
    left: 0;
  }

  .sketch-image-container {
    height: auto;
    margin-left: auto;
    width: 100%;

    @media only screen and (max-width: ${breakpoints.medium}px) {
      height: auto;
      width: auto;
    }
  }

  .btn-row {
    padding: 1em;
    button:first-child {
      margin-right: 1.5em;
    }
  }
`

const FullWidthContainer = styled.div`
  position: relative;

  @media only screen and (min-width: ${breakpoints.medium}px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
  }
`
