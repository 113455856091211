import React, { Fragment } from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

const PermitInspection = props => {
  const { installation } = props.project
  return (
    <StyledPermitInspection>
      {installation.inspectionStatus === 'fail' || installation.inspectionStatus === 'failRevert' ?
      <Fragment>
        <Header title="Your project is undergoing revisions." />
        <Container>
          <Notice text="The permit inspection turned up some things that need to be adjusted. Don’t worry—we’re on it!" />
        </Container>
      </Fragment>
      : 
      <Fragment>
        <Header title="Your installation is complete! Your permit inspection is pending." />
        <Container>
          <Notice text="Your project is almost done! Sit tight while we wait for your permit inspection to be approved. This can take 7-14 business days. You do not need to be home when your installation is inspected." />
        </Container>
      </Fragment>
      }
      
    </StyledPermitInspection>
  )
}

const StyledPermitInspection = styled.div`
  .para {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default PermitInspection
