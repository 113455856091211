import React, { Component } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import axios from 'axios'
import brandmark from '../../assets/brandmark.svg'
import { addProductionUrl } from './../../helpers/constants'

import Loader from './../sharedComponents/Loader'
import Container from './../sharedComponents/Container'
import Button from './../sharedComponents/Button'

class CustomerSignup extends Component {
  state = {
    loading: false,
    customerData: {
      password: '',
      passwordConf: '',
      isValid: false,
    },
    errors: [],
    noQuoteError: null,
  }

  componentDidMount = () => {
    this.queryForQuote()
  }

  queryForQuote = () => {
    // TODO: Lets move this logic into a seperate redux module/container
    let quoteId = qs.parse(this.props.location.search.slice(1))['q'] || null
    const initState = quoteId
      ? { loading: true }
      : {
          loading: false,
          noQuoteError: 'Sorry, your quote was not found.',
        }

    this.setState(initState)

    if (!quoteId) {
      return false
    }

    axios
      .get(`${addProductionUrl()}/api/quotes/${quoteId}`)
      .then(res => {
        if (res.status === 200) {
          let quote = res.data.quote
          quote['password'] = ''
          quote['passwordConf'] = ''

          this.setState(prevState => {
            return {
              loading: false,
              customerData: res.data.quote,
            }
          })
        }
      })
      .catch(err => {
        this.setState(prevState => {
          return {
            loading: false,
            errors: [...prevState.errors, err.response.data.message],
          }
        })
      })
  }

  handleChange = e => {
    const tarName = e.target.name,
      tarVal = e.target.value
    this.setState(prevState => {
      return {
        customerData: {
          ...prevState.customerData,
          [tarName]: tarVal,
        },
      }
    })
  }

  submitCustomerSignup = e => {
    e.preventDefault()
    const { password, passwordConf } = this.state.customerData
    let errors = []

    if (password.length < 7) {
      errors.push('Password must be atleast 7 characters.')
    }

    if (password !== passwordConf) {
      errors.push('Password and password confirmation must match.')
    }

    if (errors.length > 0) {
      this.setState(prevState => {
        return { errors }
      })
    } else {
      // After getting a quote, finish creating account
      axios
        .post(`${addProductionUrl()}/api/customers/create`, this.state.customerData)
        .then(res => {
          this.props.history.push('/login')
        })
        .catch(err => {
          // console.log(err)
          this.setState(prevState => {
            return {
              loading: false,
              errors: [...prevState.errors, err.response.data.message],
            }
          })
        })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    if (this.state.noQuoteError) {
      return (
        <StyledCustomerSignup>
          <Container>
            <img className="logo" src={brandmark} alt="Osceola Fence Supply" />
            <div>{this.state.noQuoteError}</div>
          </Container>
        </StyledCustomerSignup>
      )
    }

    if (!this.state.customerData.isValid) {
      return (
        <StyledCustomerSignup>
          <Container>
            <img className="logo" src={brandmark} alt="Osceola Fence Supply" />
            <div>Sorry, this quote is no longer valid.</div>
          </Container>
        </StyledCustomerSignup>
      )
    }

    return (
      <StyledCustomerSignup>
        <Container>
          <img className="logo" src={brandmark} alt="Osceola Fence Supply" />

          <form onSubmit={this.submitCustomerSignup}>
            <div className="title">Welcome to OFS Online!</div>
            <div className="sub-title">
              Finish creating your account so that you can track the progress of your project with
              us.
            </div>
            <div>
              <div className="form-control">
                <label htmlFor="customer-password">Password *</label>
                <input
                  type="password"
                  id="customer-password"
                  name="password"
                  value={this.state.customerData.password}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-control">
                <label htmlFor="customer-password-conf">Password Confirmation *</label>
                <input
                  type="password"
                  id="customer-password-conf"
                  name="passwordConf"
                  value={this.state.customerData.passwordConf}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <Button type="submit" text="Create Account" />

            <div className="errors">
              {this.state.errors.map((error, idx) => {
                return (
                  <div className="error" key={idx}>
                    {error}
                  </div>
                )
              })}
            </div>
          </form>
        </Container>
      </StyledCustomerSignup>
    )
  }
}

const StyledCustomerSignup = styled.div`
  max-width: 420px;
  margin: 8em auto 0;

  .logo {
    display: block;
    width: 420px;
    height: auto;
    margin: 0 auto;
    padding: 20px;
  }

  hr {
    margin: 0;
  }

  form {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2em 1.5em;

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #94d82d;
      font-weight: 700;
      padding: 0;
      margin: 0;
      margin-bottom: 0.5em;
    }

    .sub-title {
      font-size: 20px;
      font-weight: 400;
      color: #868e96;
      margin-bottom: 2.5em;
    }

    .form-control {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 40px;
      }
    }

    label,
    input,
    textarea {
      display: block;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
    }

    textarea {
      resize: vertical;
    }

    label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .error {
    color: red;
  }
`

export default CustomerSignup
