import React, { Component } from 'react'
import styled from 'styled-components'
import { toTitleCase, splitStringByCapital } from './../../../utils'
import { em } from 'polished'
import { colors } from './../../../helpers/foundation'

import ResizeableSector from './../ResizeableSector'
import OptionCard from '../OptionCard'
import Button from './../Button'
import Loader from './../Loader'
import Icon from './../Icon'

class Survey extends Component {
  componentDidMount = () => {
    // const { survey } = this.props
    // // get { key: ..., image: ... } from all photos
    // const surveyPhotos = Object.keys(survey.photos).map(photoKey => {
    //   const photo = survey.photos[photoKey]
    //   return {
    //     key: `survey.photos.${photoKey}`,
    //     image: photo.large,
    //   }
    // })
    // console.log('survey photos', surveyPhotos)
    // this.props.context.setImages(surveyPhotos)
  }

  render() {
    const { survey, openPhotobox, isReview, pdfDownloading } = this.props

    return (
      <StyledSurvey isSidebar={!isReview}>
        {/* <div className="print-pdf">
          <Button
            text="Download PDF"
            variant={pdfDownloading ? 'disabled' : ''}
            handleClick={async e => await this.props.downloadPDF()}
          />
          {pdfDownloading && <Loader />}
        </div> */}
        <div>
          {survey.material && survey.material instanceof Object && (
            <ResizeableSector color="white" title="MATERIAL">
              <div className="rs-wrapper">
                <div className="grid-items">
                  <div className="grid-item">
                    <img src={survey.material.image} alt="" />
                    <div className="name">{survey.material.name}</div>
                  </div>
                </div>
              </div>
            </ResizeableSector>
          )}

          {survey.isMultiStyle && survey.ms_material && (
            <ResizeableSector color="white" title="SECOND MATERIAL">
              <div className="rs-wrapper">
                <div className="grid-items">
                  <div className="grid-item">
                    <img src={survey.ms_material.image} alt="" />
                    <div className="name">{survey.ms_material.name}</div>
                  </div>
                </div>
              </div>
            </ResizeableSector>
          )}

          {survey.style && survey.style instanceof Object && (
            <ResizeableSector color="white" title="STYLE">
              <div className="rs-wrapper">
                <div className="grid-items">
                  <div className="grid-item">
                    <img src={survey.style.image} alt="" />
                    <div className="name">{toTitleCase(survey.style.name)}</div>
                  </div>
                </div>
              </div>
            </ResizeableSector>
          )}

          {survey.ms_style && survey.ms_style instanceof Object && (
            <ResizeableSector color="white" title="SECOND STYLE">
              <div className="rs-wrapper">
                <div className="grid-items">
                  <div className="grid-item">
                    <img src={survey.ms_style.image} alt="" />
                    <div className="name">{survey.ms_style.name}</div>
                  </div>
                </div>
              </div>
            </ResizeableSector>
          )}

          {survey.options && survey.options.length > 0 && survey.options[0] instanceof Object && (
            <ResizeableSector color="white" title="OPTIONS">
              <div className="rs-wrapper">
                <div className="grid-items">
                  {survey.options.map(opt => {
                    return (
                      <div key={opt._id} className="grid-item">
                        <img src={opt.image} alt="" />
                        <div className="name">{toTitleCase(opt.name)}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </ResizeableSector>
          )}

          {survey.ms_options &&
            survey.ms_options.length > 0 &&
            survey.ms_options[0] instanceof Object && (
              <ResizeableSector color="white" title="SECOND OPTIONS">
                <div className="rs-wrapper">
                  <div className="grid-items">
                    {survey.ms_options.map(opt => {
                      return (
                        <div key={opt._id} className="grid-item">
                          <img src={opt.image} alt="" />
                          <div className="name">{toTitleCase(opt.name)}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </ResizeableSector>
            )}

          {survey.height && (
            <ResizeableSector color="white" title="HEIGHT">
              <div className="rs-wrapper">
                <div className="small-padding small-text bold">{survey.height} Feet</div>
              </div>
            </ResizeableSector>
          )}

          {survey.ms_height && (
            <ResizeableSector color="white" title="SECOND HEIGHT">
              <div className="rs-wrapper">
                <div className="small-padding small-text bold">{survey.ms_height} Feet</div>
              </div>
            </ResizeableSector>
          )}

          <ResizeableSector color="white" title="CONTOUR">
            <div className="rs-wrapper">
              <div className="small-padding small-text bold">{survey.contour ? 'Yes' : 'No'}</div>
            </div>
          </ResizeableSector>

          {survey.photos &&
            survey.photos.frontLeft.small &&
            survey.photos.frontLeft.small.length > 0 && (
              <ResizeableSector color="white" title="PROPERTY PHOTOS">
                <div className="rs-wrapper">
                  <div className="grid-items photos">
                    {Object.keys(survey.photos).map(photoKey => {
                      const photo = survey.photos[photoKey]
                      return (
                        <div
                          key={photoKey}
                          className="grid-item clickable photo"
                          onClick={e => this.props.context.setImage(photo.large)}
                        >
                          <OptionCard>
                            <div className="title">
                              {toTitleCase(splitStringByCapital(photoKey))}
                            </div>
                            <img className="media" src={photo.large} alt="" />
                            <div className="notes">
                              {photo.notes.length > 0 ? photo.notes : 'No notes.'}
                            </div>
                          </OptionCard>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </ResizeableSector>
            )}

          {Object.keys(survey.details).filter(detailKey => survey.details[detailKey].selected)
            .length > 0 && (
            <ResizeableSector color="white" title="SELECTED DETAILS">
              {Object.keys(survey.details)
                .filter(detailKey => survey.details[detailKey].selected)
                .map(detailKey => {
                  const detail = survey.details[detailKey]
                  return (
                    <div key={detailKey} className="rs-wrapper">
                      <b className="align">
                        <span className="margin-right">
                          <Icon icon="check" color="primary" />
                        </span>
                        <span>{toTitleCase(splitStringByCapital(detailKey))}</span>
                      </b>
                      <div className="grid-items details">
                        {detail.images.map((img, idx) => {
                          return (
                            <div
                              key={idx}
                              className="grid-item clickable detail"
                              onClick={e => this.props.context.setImage(img.large)}
                            >
                              <OptionCard>
                                <img src={img.large} alt="" />
                                <div className="notes">
                                  {img.notes && img.notes.length > 0 ? img.notes : 'No notes.'}
                                </div>
                              </OptionCard>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
            </ResizeableSector>
          )}

          <ResizeableSector color="white" title="ADDITIONAL DETAILS">
            {Object.keys(survey.additionalDetails).map(detailKey => {
              const detail = survey.additionalDetails[detailKey]
              return (
                <div key={detailKey} className="rs-wrapper">
                  <div className="small-padding">
                    <b>{detail.question}</b>
                    <div className="small-text">{detail.answer}</div>
                  </div>
                </div>
              )
            })}
          </ResizeableSector>

          {survey.blueprint && survey.blueprint.sketch.length > 0 && (
            <ResizeableSector color="white" title="BLUEPRINT SKETCH">
              <div className="rs-wrapper blueprint">
                <div className="sketch">
                  <img src={survey.blueprint.sketch} alt="" />
                </div>
                <div className="comments">
                  <div className="comments-title">Comments</div>
                  {survey.blueprint.comments.length < 1 ? (
                    <div>No comments</div>
                  ) : (
                    survey.blueprint.comments.map((comment, i) => {
                      const commentNumber = i + 1

                      return (
                        <div key={comment._id} className="comment">
                          <div
                            className="comment-head"
                            style={{
                              borderBottom: `1px solid ${
                                comment.hexcode === '' ? '#CED4DA' : comment.hexcode
                              }`,
                            }}
                          >
                            <div
                              className="number"
                              style={{
                                color: `${comment.hexcode === '' ? '#CED4DA' : comment.hexcode}`,
                              }}
                            >
                              {commentNumber < 10 ? `0${commentNumber}` : commentNumber}
                            </div>
                          </div>

                          <div className="text">{comment.text}</div>
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </ResizeableSector>
          )}

          {(survey.notes.homeownersRequest.answer.length > 0 ||
            survey.notes.salesmanNotes.answer.length > 0 ||
            survey.notes.specialConditions.answer.length > 0) && (
            <ResizeableSector color="white" title="ADDITIONAL NOTES">
              <div className="rs-wrapper">
                <div className="small-padding">
                  <b>{survey.notes.homeownersRequest.question}</b>
                  <div className="small-text">
                    {survey.notes.homeownersRequest.answer.length > 0
                      ? survey.notes.homeownersRequest.answer
                      : 'No additional notes.'}
                  </div>
                </div>
                <div className="small-padding">
                  <b>{survey.notes.salesmanNotes.question}</b>
                  <div className="small-text">
                    {survey.notes.salesmanNotes.answer.length > 0
                      ? survey.notes.salesmanNotes.answer
                      : 'No additional notes.'}
                  </div>
                </div>
                <div className="small-padding">
                  <b>{survey.notes.specialConditions.question}</b>
                  <div className="small-text">
                    {survey.notes.specialConditions.answer.length > 0
                      ? survey.notes.specialConditions.answer
                      : 'No additional notes.'}
                  </div>
                </div>
              </div>
            </ResizeableSector>
          )}
        </div>
      </StyledSurvey>
    )
  }
}

export default Survey

const StyledSurvey = styled.div`
  b {
    margin: 0;
    font-size: ${em('14px')};
    text-transform: uppercase;

    &.align {
      margin: 0 0.75em;
    }
  }

  .name {
    font-size: ${em('14px')};
    font-weight: 600;
    text-transform: uppercase;
  }

  .small-text {
    font-size: ${em('14px')};
    &.bold {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .margin-right {
    margin-right: ${em('10px')};
  }

  .clickable {
    cursor: pointer;
  }

  .photos, .details {
    margin-bottom: -1em !important;
  }

  .photo, .detail {
    margin-bottom: 1em !important;
  }

  .blueprint {
    display: block;
    padding: 0 1em;
    max-width: 50em;
    width: 100%;
    margin-bottom: 6em;
    
    .sketch {
      border: 1px solid ${colors.lightGrey};
    }

    .comments {
      /* padding: ${props => (props.isSidebar ? '1em 0 1em' : '0 1em')}; */
      max-width: 45em;
      width: 100%;
      margin-top: 1.5em;

      .comments-title {
        margin-bottom: 1em;
        font-size: 0.875em;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .comment {
      margin-bottom: 0.5em;

      .comment-head {
        position: relative;
        display: flex;
        /* justify-content: space-between; */

        .number {
          font-weight: 600;
          font-size: 1.2em;
        }

        .edit {
          position: relative;
          top: 4px;
          font-size: 0.9em;
          cursor: pointer;
          color: #ced4da;
        }
      }

      .text {
        font-size: 0.9em;
        color: #868e96;
      }
    }
  }
`
