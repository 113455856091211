import React from 'react'
import styled from 'styled-components'
import { blueprintColors as colors, colors as fcolors } from './../../../../helpers/foundation'

import Modal from './../../../sharedComponents/Modal'
import Icon from './../../../sharedComponents/Icon'
import Button from './../../../sharedComponents/Button'
import Field from './../../../sharedComponents/Field'

const BlueprintCommentModal = props => {
  return (
    <StyledBlueprintCommentModal>
      {props.commentOpen && (
        <Modal close={props.closeComment}>
          <div className="add-comment-container content">
            {/* close modal */}
            <div className="close">
              <Icon icon="close" color="grey" handleClick={props.closeComment} />
            </div>

            {/* add comment textarea */}
            <div className="add-comment-body">
              <Field>
                <textarea
                  name={props.isEditing ? 'editingCommentText' : 'newCommentText'}
                  id="add-comment"
                  value={props.isEditing ? props.editingCommentText : props.newCommentText}
                  onChange={e => props.handleChange(e)}
                />
              </Field>

              {/* list of colors to associate with comment */}
              <div className="colors-container">
                {props.colorsArr.map((color, idx) => {
                  const selected = props.isEditing ? props.editingCommentHex : props.newCommentHex
                  return (
                    <div
                      key={idx}
                      className={`color ${selected === colors[color] ? 'selected' : ''}`}
                      style={{ backgroundColor: colors[color] }}
                      onClick={e => props.selectColor(e, color)}
                    />
                  )
                })}
                {/* no color */}
                <div
                  className={`color no-color ${
                    (props.isEditing ? props.editingCommentHex : props.newCommentHex) === ''
                      ? 'selected'
                      : ''
                  }`}
                  onClick={e => props.selectColor(e, '')}
                >
                  <Icon icon="close" />
                </div>
              </div>

              <div className="button-container">
                <Button
                  text={props.isEditing ? 'Edit Comment' : 'Submit Comment'}
                  variant="sm"
                  handleClick={e =>
                    props.isEditing ? props.editCommentAction('update') : props.addComment(e)
                  }
                />

                {props.isEditing && (
                  <Button
                    text="Delete"
                    variant="sm danger"
                    handleClick={e => props.editCommentAction('delete')}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </StyledBlueprintCommentModal>
  )
}

export default BlueprintCommentModal

const StyledBlueprintCommentModal = styled.div`
  .add-comment-container {
    background-color: #fff;
    margin: 0 auto;
    border-radius: 4px;
  }
`
