import React, { Component } from 'react'
import styled from 'styled-components'
import ActionItem from './ActionItem'

class Actions extends Component {
  render() {
    return (
      <StyledActions>
        {this.props.actions.length < 1
          ? 'No actions yet.'
          : this.props.actions.map(action => {
              return <ActionItem key={action._id} action={action} />
            })}
      </StyledActions>
    )
  }
}

const StyledActions = styled.div`
  padding: 0.5em 1em;
`

export default Actions
