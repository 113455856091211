import React, { Component } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import PropTypes from 'prop-types'
import { colors, zIndexes } from '../../helpers/foundation'
import Icon from './Icon'

const Wrapper = styled.div`
  position: fixed;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndexes.alert};

  .inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25em;
    min-width: 300px;
    color: ${props => colors[props.status]};
    background-color: ${props => lighten(0.25, colors[props.status])};
    border-radius: 4px;
    font-weight: 600;
  }
`

const Control = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
`

class Alert extends Component {
  componentDidMount() {
    if (this.props.timer) {
      this.timerId = this.timer()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerId)
  }

  timer = () =>
    setTimeout(() => {
      this.props.dismiss()
    }, this.props.timer * 1000)

  render() {
    const { status = 'primary', dismiss } = this.props

    return (
      <Wrapper status={status}>
        <div className="inner">{this.props.children}</div>
        <Control icon="close" color={status} handleClick={dismiss} />
      </Wrapper>
    )
  }
}

Alert.propTypes = {
  status: PropTypes.oneOf(['danger', 'success', 'primary']),
  dismiss: PropTypes.func.isRequired,
  timer: PropTypes.number,
}

export default Alert
