import React, { Component } from 'react'
import styled from 'styled-components'
import { getTotals } from './../../../helpers/totals'
import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import ResizeableSector from './../../sharedComponents/ResizeableSector'
import { colors } from './../../../helpers/foundation'
// import { Link } from 'react-router-dom'

// import Checkout from './Checkout'
import Checkout from '../../sharedComponents/Checkout'
import Loader from '../../sharedComponents/Loader'
import OverviewChunk from './../../estimate/shared/OverviewChunk'
import Icon from '../../sharedComponents/Icon'
import Button from '../../sharedComponents/Button'

class CustomerEstimatePayment extends Component {
  state = {
    paymentLoading: false,
    paymentMessage: null,
    showingCashDesc: false,
  }

  componentDidMount = () => {
    const { project } = this.props

    // if (!project.payments || project.payments.length < 1) {
    //   const { parts, labors, services } = project.estimate
    //   const totals = getTotals(
    //     [Number(parts.total), Number(labors.total), Number(services.total)],
    //     0.07
    //   )

    //   // set default payment to 50/50
    //   const payments = [
    //     {
    //       order: 0,
    //       paid: false,
    //       cashTotal: (totals.cashTotal / 2).toFixed(2),
    //       creditTotal: (totals.creditTotal / 2).toFixed(2),
    //       chargeId: null,
    //     },
    //     {
    //       order: 1,
    //       paid: false,
    //       cashTotal: (totals.cashTotal / 2).toFixed(2),
    //       creditTotal: (totals.creditTotal / 2).toFixed(2),
    //       chargeId: null,
    //     },
    //   ]

    //   this.props.projectUpdate(project._id, {
    //     paymentTerms: '50/50',
    //     payments,
    //     currentPayment: 0,
    //   })
    // }
  }

  updatePaymentLoading = bool => {
    this.setState(prevState => ({ paymentLoading: bool }))
  }

  updatePaymentMessage = msg => {
    this.setState(prevState => ({ paymentMessage: msg }))
  }

  toggleOpenPayment = status => {
    this.setState({
      paymentLoading: status,
    })
  }

  updatePaymentType = type => {
    const { project } = this.props
    // const { estimate } = project

    this.setState(prevState => ({ showingCashDesc: type === 'cash' ? true : false }))
    this.props.projectUpdate(project._id, {
      paymentType: type,
    })
  }

  render() {
    const { project } = this.props
    const { paymentLoading, paymentMessage, showingCashDesc } = this.state

    let paymentCash = null,
      paymentCredit = null

    console.log('wgat tge', this.props)

    if (project.payments && project.payments.length > 0) {
      paymentCash = project.payments.find(payment => payment.order === 0).cashTotal
      paymentCredit = project.payments.find(payment => payment.order === 0).creditTotal
    }

    // console.log('cep props', this.props.project.paymentType)

    return (
      <StyledCustomerEstimatePayment>
        <div id="printable-estimate">
          <OverviewChunk project={project} estimate={project.estimate} />
        </div>
        <Header title="Please finalize your payment method." />

        <Container>
          <Notice
            text="Awesome! You’ve approved your project estimate. The only thing left is
        to decide how you’d like to pay. We accept check, cash, and credit card
        payment forms. We also offer financing options!"
          />

          <ResizeableSector title="Amount due at this time:" noResize={true}>
            <h2 className="total">${paymentCash}</h2>

            <Checkout
              description="First Fence Payment"
              amount={paymentCredit}
              customerEmail={project.customer.email}
              selected={this.props.project.paymentType === 'credit'}
              updatePaymentType={this.updatePaymentType}
              projectId={project._id}
              estimateId={project.estimate._id}
              paymentOrder={0}
              updatePaymentLoading={this.updatePaymentLoading}
              updatePaymentMessage={this.updatePaymentMessage}
              isEstimate={true}
              stageUpdate={this.props.estimateUpdateServer}
              history={this.props.history}
              // customerUpdateProjectStageStatus={this.props.customerUpdateProjectStageStatus}
            />

            <PaymentCard
              onClick={() => this.updatePaymentType('cash')}
              selected={this.props.project.paymentType === 'cash' || showingCashDesc}
            >
              <div className="inner">
                <div className="head">Cash or Check</div>
                <Icon icon="dollarBill" />
                <div className="text">Drop off your payment to us in person.</div>
              </div>
            </PaymentCard>

            {(this.props.project.paymentType === 'cash' || showingCashDesc) && (
              <div className="cash-desc">
                <div className="para">
                  Please hand-deliver your cash or check payment to our showroom location at:
                </div>

                <div className="para bold">
                  3319 13th Street <br />
                  St. Cloud, FL 34769
                </div>

                <div className="para">
                  Do not mail your cash payment! Osceola Fence Supply is not responsible for
                  payments that are lost or stolen in the mail.
                </div>

                <div className="para">
                  Once your payment is received, your installation can be scheduled. You may still
                  change your mind and pay by card as long as you have not already delivered your
                  payment in cash or check.
                </div>
              </div>
            )}

            {paymentLoading && <Loader />}
            {paymentMessage && (
              <div className={`message ${paymentMessage.type === 'error' ? 'error' : 'success'}`}>
                {paymentMessage.message}
              </div>
            )}
          </ResizeableSector>

          <ResizeableSector title="Financing Options">
            <Button variant="nm primary">
              <a href="http://osceolafencesupply.com/financing" target="_blank">
                Financing Information
              </a>
            </Button>
            {/* <div className="interest-lead">
              <div className="large-zero">0</div>
              <div className="text">
                INTEREST IF YOUR FENCE IS PAID IN FULL WITHIN THE NEXT 12
                MONTHS.*
              </div>
            </div> */}
            {/* <div>0% Interest if your fence is paid in full within the next 12 months.</div>

              <b>
                Osceola Fence Supply offers 12 months of interest-free financing through Synchrony
                Financial on fence purchases of $999 or more.
              </b>

              <div>
                Contact us at 407-744-0828 to apply, or download the paper application document.
              </div>

              <br />

              <div>DOWNLOAD CREDIT APPLICATION BTN</div>

              <br />
              <br />

              <div>
                Email your completed application file to financing@osceolafencesupply.com, drop it
                off at our showroom, or upload it below.
              </div>

              <br />

              <input type="file" />

              <br />
              <br />
              <br />

              <small>
                *No interest will be charged on the promo purchase if you pay the promo purchase
                amount in full within 12 months. If you do not, interest will be charged on the
                promo purchase from the purchase date. Depending on purchase amount, promotion
                length, and payment allocation, the required minimum monthly payments may or may not
                pay off purchase by end of promotional period. Regular account terms apply to
                non-promotional purchases and, after promotion ends, to promotional balance. For new
                accounts: Purchase APR is 29.99%. Minimum interest charge is $2. Existing
                cardholders should see their credit card agreement for their applicable terms.
                Subject to credit approval.
              </small> */}
          </ResizeableSector>
        </Container>
      </StyledCustomerEstimatePayment>
    )
  }
}

export default CustomerEstimatePayment

const StyledCustomerEstimatePayment = styled.div`
  padding-bottom: 5em;

  .title {
    margin-bottom: 0.5em;
    font-weight: 400;
  }

  .total {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 1.5em;
  }

  .interest-lead {
    display: flex;

    .large-zero {
      font-size: 12em;
      line-height: 0.5;
      color: ${colors.primary};
    }

    .text {
      font-size: 2em;
      line-height: 0.9;
      color: ${colors.grey};
      margin-left: 1.5em;
      width: 8em;
    }
  }

  .margin-right {
    margin-right: 1em;
  }

  #printable-estimate {
    display: none;
  }

  .cash-desc {
    width: 26.25em;
    display: inline-block;
    font-size: 0.875em;
    margin-left: 1em;

    .para {
      margin-bottom: 1em;

      &.bold {
        font-weight: 600;
      }
    }
  }

  .message {
    font-size: 1.25em;
    margin-top: 0.5em;
    &.success {
      color: ${colors.primary};
    }
    &.error {
      color: ${colors.danger};
    }
  }
`

const PaymentCard = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 12em;
  margin-right: 1em;
  text-align: center;
  vertical-align: top;

  .inner {
    background-color: #fff;
    border: ${props =>
      props.selected ? `1px solid ${colors.primary}` : `1px solid ${colors.grey6}`};
    border-radius: 4px;
    padding: 1em;
  }

  .head {
    color: ${colors.grey3};
    font-weight: 600;
    margin-bottom: 1em;
    color: ${props => props.selected && colors.primary};
  }
  .text {
    font-size: 0.875em;
    margin-top: 1em;
  }

  .desc {
    font-size: 0.75em;
    color: ${colors.grey};
    margin-top: 1em;
  }

  &:hover {
    .head {
      color: ${colors.primary};
    }
  }
`
