import axios from 'axios'
import { updateEstimate as projectsUpdateEstimate } from './../projects/'
import { updateCurrentProject } from './../project/actions'
import { updateCurrentProjectPart } from './../project/actions'
import { customerUpdateProjectEstimate } from './../customer/'
import { addProductionUrl } from './../../../helpers/constants'

export const ESTIMATE_LOADING = 'app/estimates/ESTIMATE_LOADING'
export const ESTIMATE_ERROR = 'app/estimates/ESTIMATE_ERROR'
export const UPDATE_ESTIMATE = 'app/estimates/UPDATE_ESTIMATE'

const initialState = {
  loading: false,
  messages: [],
  estimate: {
    _id: '',
    project: {},
    createdAt: '',
    status: '',
    blueprint: {
      image: '',
      lines: {},
      comments: [],
    },
    parts: [],
    labors: [],
    services: [],
    details: {
      permits: '',
      fenceContours: '',
      contoursSpaceAcknowledge: '',
      hoaAprovalRequired: '',
      waitForApproval: '',
      gateSwingCorrect: '',
    },
    formsAndPermits: {
      formOnFile: '',
      form: '',
      permitsOnFile: '',
      attachedPermits: [],
    },

    approvedEstimate: {
      name: '',
      path: '',
    },
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ESTIMATE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ESTIMATE_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case UPDATE_ESTIMATE:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          ...action.estimate,
        },
        loading: false,
      }
    default:
      return state
  }
}

export function estimateLoading() {
  return {
    type: ESTIMATE_LOADING,
  }
}

export function estimateError(err) {
  return {
    type: ESTIMATE_ERROR,
    err,
  }
}

export function estimateUpdate(estimate) {
  return {
    type: UPDATE_ESTIMATE,
    estimate,
  }
}

export function estimateGet(estimateId) {
  return dispatch => {
    dispatch(estimateLoading())
    return axios
      .get(`${addProductionUrl()}/api/estimates/${estimateId}`)
      .then(res => {
        if (res.status === 200) {
          dispatch(estimateUpdate(res.data.estimate))
          return true
        }
      })
      .catch(err => {
        throw err
      })
  }
}

export function estimateUpdateServer(estimateId, updates, isCustomer = false) {
  return async dispatch => {
    try {
      const from = updates.from || null
      const paymentAmount = updates.paymentAmount || null
      const paymentMethod = updates.paymentMethod || null
      delete updates.from
      delete updates.paymentAmount
      delete updates.paymentMethod
      // console.log('getting here', estimateId, updates, isCustomer)
      const estimate = await axios.patch(
        `${addProductionUrl()}/api/estimates/${estimateId}`,
        {
          ...updates,
          ...(from && { from }),
          ...(paymentAmount && { paymentAmount }),
          ...(paymentMethod && { paymentMethod }),
        },
        {
          headers: {
            enctype: 'multipart/form-data',
          },
        }
      )

      // console.log('returned estimate', estimate)
      dispatch(projectsUpdateEstimate(estimate.data))

      if (estimate.status === 200) {
        dispatch(estimateUpdate(estimate.data))
        if (isCustomer) {
          dispatch(customerUpdateProjectEstimate(estimate.data))
          dispatch(updateCurrentProjectPart('estimate', estimate.data))
        } else {
          dispatch(updateCurrentProjectPart('estimate', estimate.data))
        }
        return estimate.data
      }
    } catch (err) {
      // console.log('got estimate error', err)
      dispatch(estimateError(err))
      return false
    }
  }
}

export function estimateRenew(project, newExpiration, newDate) {
  return async dispatch => {
    try {
      const { estimate } = project
      const response = await axios.post(
        `${addProductionUrl()}/api/estimates/${estimate._id}/renew`,
        {
          newExpiration,
          newDate,
          customerName: project.customer.displayName,
          customerEmail: project.customer.email,
        }
      )
      dispatch(projectsUpdateEstimate(response.data))
      return true
    } catch (err) {
      // console.log('err', err)
      dispatch(estimateError(err.response.data.message))
    }
  }
}
