import React, { Component } from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import { hours, minutes } from '../../helpers/dateTime'
import { colors } from '../../helpers/foundation'
import Select from './Select'

const StyledTimePicker = styled.div`
  select {
    border: 1px solid ${colors.grey2};
    font-size: 16px;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
    width: auto;

    &:first-child {
      margin-left: 0;
    }
  }

  .seperator {
    display: inline-block;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
  }

  .error {
    color: ${colors.danger};
    font-size: ${em('12px')};
    display: block;
    margin-top: 0.25em;
  }
`

class TimePicker extends Component {
  constructor() {
    super()

    this.state = {
      fromHour: '',
      fromMinute: '',
      toHour: '',
      toMinute: '',
    }
  }

  componentDidMount() {
    if (this.props.startTime && this.props.endTime) {
      this.splitTimes(this.props.startTime, this.props.endTime)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startTime !== this.props.startTime || prevProps.endTime !== this.props.endTime) {
      this.splitTimes(this.props.startTime, this.props.endTime)
    }
  }

  splitTimes = (startTime, endTime) => {
    if (!startTime || !endTime) return

    const formattedStartTime = startTime.split(':')
    const formattedEndTime = endTime.split(':')

    this.setState({
      fromHour: formattedStartTime[0],
      fromMinute: formattedStartTime[1],
      toHour: formattedEndTime[0],
      toMinute: formattedEndTime[1],
    })
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const { fromHour, fromMinute, toHour, toMinute } = this.state

        const startTime = `${fromHour}:${fromMinute}`
        const endTime = `${toHour}:${toMinute}`

        this.props.updateTimes(startTime, endTime)
      }
    )
  }

  render() {
    const { fromHour, fromMinute, toHour, toMinute, error } = this.state

    return (
      <StyledTimePicker>
        <label>{this.props.title}</label>
        <fieldset>
          <Select
            name="fromHour"
            value={fromHour}
            handleChange={this.handleChange}
            options={hours}
          />
          <span>:</span>
          <Select
            name="fromMinute"
            value={fromMinute}
            handleChange={this.handleChange}
            options={minutes}
          />
          <span>AM</span>
          <span className="seperator">–</span>
          <Select name="toHour" value={toHour} handleChange={this.handleChange} options={hours} />:
          <Select
            name="toMinute"
            value={toMinute}
            handleChange={this.handleChange}
            options={minutes}
          />
          <span>PM</span>
        </fieldset>
        {error && <div className="error">{error}</div>}
      </StyledTimePicker>
    )
  }
}

export default TimePicker
