import React, { Component } from 'react'
import styled from 'styled-components'
import isEmpty from 'validator/lib/isEmpty'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'

import Title from './../../sharedComponents/Title'
import Field from './../../sharedComponents/Field'
import Button from './../../sharedComponents/Button'

const initialFields = {
  email: '',
  // password: '',
  phone: '',
  address: '',
  city: '',
  state: 'FL',
  zip: '',
  password: '',
  passwordConf: '',
}

class NonExistingUserConfirmation extends Component {
  state = {
    loading: false,
    fields: initialFields,
    canSubmit: false,
  }

  componentDidMount = () => {
    const { quote, user } = this.props
    this.setState(prevState => ({
      fields: {
        email: quote.email,
        address: quote.address,
        city: quote.city,
        state: quote.state,
        zip: quote.zip,
        phone: quote.phone,
        password: '',
        passwordConf: '',
      },
    }))
  }

  componentDidUpdate = (prevProps, prevState) => {
    // undisable the submit button if password & password conf match
    // and if both password & password conf are at least 5 chars
    if (
      prevState.fields.password !== this.state.fields.password ||
      prevState.fields.passwordConf !== this.state.fields.passwordConf
    ) {
      let canSubmit = false
      if (this.state.fields.password === this.state.fields.passwordConf) {
        if (this.state.fields.password.length > 5 && this.state.fields.passwordConf.length > 5) {
          canSubmit = true
        }
      }

      this.setState(prevState => ({
        canSubmit,
      }))
    }
  }

  handleChange = e => {
    let { name, value } = e.target
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }))
  }

  validateFields = () => {
    const { phone, address, city, state, zip } = this.state.fields

    let errors = []

    if (isEmpty(phone)) {
      errors.push("Phone can't be blank.")
    }
    if (isEmpty(address)) {
      errors.push("Address can't be blank.")
    }
    if (isEmpty(city)) {
      errors.push("City can't be blank.")
    }
    if (isEmpty(state)) {
      errors.push("State can't be blank.")
    }
    if (isEmpty(zip)) {
      errors.push("Zip can't be blank.")
    }

    return errors
  }

  handleSubmit = async e => {
    try {
      e.preventDefault()
      const { quote } = this.props
      const { phone, address, city, state, zip, password } = this.state.fields

      if (this.props.submitting || !this.state.canSubmit) {
        return false
      }

      // ensure all fields are valid
      const errors = this.validateFields()
      if (errors.length > 0) {
        this.props.setErrors(errors)
        this.props.setSubmitting(false)
        return false
      }

      this.props.setCustomerData({
        email: quote.email,
        quoteId: quote._id,
        phone,
        password,
        address,
        city,
        state,
        zip,
      })
    } catch (err) {
      console.log('error exists', err)
      this.setState(prevState => ({
        submitting: false,
        errors: ['Error create project.'],
      }))
    }
  }

  render() {
    return (
      <StyledNonExistingUserConfirmation>
        <Title text="Finish creating your account." tag="h3" size="24px" />
        <p>We need some additional information from you to finish creating your account.</p>

        <form
          onSubmit={e => {
            e.preventDefault()
            if (this.state.canSubmit && !this.props.submitting) {
              this.handleSubmit(e)
            }
          }}
        >
          <Field>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              value={this.state.fields.email}
              onChange={this.handleChange}
              disabled={true}
            />
          </Field>
          <Field>
            <label htmlFor="address">Address</label>
            <input
              name="address"
              type="text"
              value={this.state.fields.address}
              onChange={this.handleChange}
              disabled={true}
            />
          </Field>
          <div className="flex">
            <Field>
              <label htmlFor="city">City</label>
              <input
                name="city"
                type="text"
                value={this.state.fields.city}
                onChange={this.handleChange}
                disabled={true}
              />
            </Field>
            <Field>
              <label htmlFor="state">State</label>
              <input
                name="state"
                type="text"
                value={this.state.fields.state}
                onChange={this.handleChange}
                disabled={true}
              />
            </Field>
            <Field>
              <label htmlFor="zip">Zip</label>
              <input
                name="zip"
                type="text"
                value={this.state.fields.zip}
                onChange={this.handleChange}
                disabled={true}
              />
            </Field>
          </div>
          <Field>
            <label htmlFor="city">Phone</label>
            <input
              name="phone"
              type="text"
              value={this.state.fields.phone}
              onChange={this.handleChange}
            />
          </Field>
          <Field>
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              value={this.state.fields.password}
              onChange={this.handleChange}
              placeholder="Password must be at least 5 characters long..."
            />
          </Field>
          <Field>
            <label htmlFor="city">Password Confirm</label>
            <input
              name="passwordConf"
              type="password"
              value={this.state.fields.passwordConf}
              onChange={this.handleChange}
              placeholder="Password confirm must be at least 5 characters long..."
            />
          </Field>

          <Button
            text="Schedule Survey"
            fluid={true}
            variant={`lg ${(!this.state.canSubmit || this.props.submitting) && 'disabled'}`}
            disabled={this.state.canSubmit || !this.props.submitting ? false : true}
          />
        </form>
      </StyledNonExistingUserConfirmation>
    )
  }
}

const StyledNonExistingUserConfirmation = styled.div`
  .flex {
    display: flex;
    > div {
      margin-right: 0.5em;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        min-width: 50%;
      }
    }
  }

  button {
    margin-top: 1em;
  }
`

export default NonExistingUserConfirmation
