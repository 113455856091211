import React from 'react'
import styled from 'styled-components'
import { colors } from '../../helpers/foundation'
import Icon from '../sharedComponents/Icon'

const Step = ({ handleClick, num, title, status, isActive, isCurrent }) => {
  const statusBackgroundColor = () => {
    if (isActive) {
      return 'transparent'
    } else if ((status === 'complete' && handleClick) || isCurrent) {
      return colors.primary
    } else if (status === 'complete') {
      return colors.lightGrey
    } else {
      return 'transparent'
    }
  }

  const statusBorderColor = () => {
    if (isActive) {
      return colors.primary
    } else if ((status === 'complete' && handleClick) || isCurrent) {
      return colors.primary
    } else {
      return colors.grey6
    }
  }

  const statusColor = () => {
    if (isActive) {
      return colors.primary
    } else if ((status === 'complete' && handleClick) || isCurrent) {
      return colors.white
    } else {
      return colors.grey6
    }
  }

  const statusTitleColor = () => {
    if ((isActive && status !== 'complete') || isCurrent) {
      return colors.base
    } else if ((status === 'complete' && handleClick) || (status === 'complete' && isActive)) {
      return colors.primary
    } else if ((status === 'complete' && !handleClick) || status === 'incomplete') {
      return colors.grey2
    } else {
      return colors.grey3
    }
  }

  const statusIconColor = () => {
    if (status === 'complete') {
      return 'white'
    } else {
      return 'lightGrey'
    }
  }

  const StyledStep = styled.div`
    cursor: ${handleClick && !isActive ? 'pointer' : 'default'};
    display: flex;
    align-items: center;
    margin-bottom: 22.5px;

    &:last-child {
      .step-num {
        &::after {
          display: none;
        }
      }
    }

    .step-num {
      background-color: ${statusBackgroundColor()};
      color: ${statusColor()};
      border: 2px solid ${statusBorderColor()};
      border-radius: 50%;
      font-size: 20px;
      line-height: 1;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
      width: 32px;
      height: 32px;
      flex: 0 0 auto;
      text-align: center;
      position: relative;

      > .number {
        position: relative;
        top: -1px;
      }

      &::after {
        background-color: ${statusBorderColor()};
        content: '';
        position: absolute;
        right: 50%;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        height: 26px;
        width: 2px;
      }
    }

    .step-title {
      color: ${statusTitleColor()};
      position: relative;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  `
  return (
    <StyledStep onClick={handleClick ? e => handleClick(e, num) : null}>
      <div className="step-num">
        {status === 'complete' && !isActive ? (
          <Icon icon="check" color={statusIconColor()} />
        ) : (
          <span className="number">{num}</span>
        )}
      </div>
      <div>
        <div className="step-title">{title}</div>
      </div>
    </StyledStep>
  )
}

export default Step
