import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ProjectView from './../components/pages/Projects/ProjectView'
import { requestProject, updateProject } from './../redux/modules/project/operations'
import { updateStageStatus, clearProject } from '../redux/modules/project/actions'
import { employeeMarkSurvey } from './../redux/modules/projects'
import { estimateUpdateServer, estimateRenew } from './../redux/modules/estimate/'

const mapStateToProps = state => {
  return {
    requestingProject: state.project.requestingProject,
    requestProjectError: state.project.requestProjectError,
    updatingProject: state.project.updatingProject,
    updateProjectError: state.project.updateProjectError,
    project: state.project.project,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestProject: pid => dispatch(requestProject(pid)),
    updateProject: (pid, updates) => dispatch(updateProject(pid, updates)),
    resetCurrentProject: () => dispatch(clearProject()),
    employeeMarkSurvey: (project, type, notes) =>
      dispatch(employeeMarkSurvey(project, type, notes)),
    estimateUpdateServer: (estimateId, updates, isCustomer) =>
      dispatch(estimateUpdateServer(estimateId, updates, isCustomer)),
    estimateRenew: (project, newExpiration, newDate) =>
      dispatch(estimateRenew(project, newExpiration, newDate)),
    updateProjectStageStatus: (stage, status) => dispatch(updateStageStatus(stage, status)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProjectView)
)
