import React from 'react'
import Step from './../../sharedComponents/step'
import Sidebar from './Sidebar'

const InstallationSidebar = ({
  sidebarStateObj,
  stepStatus,
  stepPointer,
  currentStep,
  // updateCurrentStep,
}) => {
  return (
    <Sidebar>
      <div className="inner">
        <Step
          status={stepStatus(7, sidebarStateObj)}
          title="File Upload"
          num={1}
          isActive={stepPointer === 7}
          isCurrent={currentStep === 7}
        />
        {/* <Step
          status={stepStatus(8, sidebarStateObj)}
          title="File Review"
          num={2}
          isActive={stepPointer === 8}
          isCurrent={currentStep === 8}
        />
        <Step
          status={stepStatus(9, sidebarStateObj)}
          title="File Prep"
          num={3}
          isActive={stepPointer === 9}
          isCurrent={currentStep === 9}
        />
        <Step
          status={stepStatus(10, sidebarStateObj)}
          title="Pending Approval"
          num={4}
          isActive={stepPointer === 10}
          isCurrent={currentStep === 10}
        /> */}
      </div>
    </Sidebar>
  )
}

export default InstallationSidebar
