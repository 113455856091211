import React, { Component } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import { format } from 'date-fns'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isUserAdmin } from './../../helpers/auth'
import { breakpoints, colors } from './../../helpers/foundation'
import { withViewerContext } from '../../contexts/ViewerContext'
import Field from './Field'
import Modal from './Modal'
import Icon from './Icon'
import Button from './Button'
import SecondaryButton from './SecondaryButton'
import Loader from './../sharedComponents/Loader'
import ImageInput from './ImageInput'

class PhotoBox extends Component {
  state = {
    imageLoaded: false,
    message: '',
    error: '',
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageLoaded: false,
      })
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => {
      return {
        [name]: value,
      }
    })
  }

  // for Installation.InProgress
  handleSubmit = e => {
    const { message } = this.state
    // validation
    if (message.length < 3) {
      this.setState(prevState => ({
        error: 'Message must be at least 2 characters long.',
      }))
      return false
    }

    this.props.addPhotoComment(
      this.props.installationId,
      this.props.activeDirection,
      this.props.currentPhotoId,
      {
        // add all info for new comment here
        _id: shortid.generate(),
        // user.userId,
        userId: this.props.user._id,
        // user.userName,
        userName: this.props.user.displayName,
        // user.profileImage,
        profileImage: this.props.user.profileImage,
        // text,
        text: message,
        // date,
        date: new Date(),
      }
    )

    // do we want to do this before or after?
    this.setState(prevState => ({
      message: '',
      error: '',
    }))
  }

  render() {
    const {
      presentational,
      header,
      imageUrl,
      messages,
      photoStatus,
      saveNotes,
      close,
      editing,
      changePhoto,
      deletePhoto,
      handleChange,
      loading,
      type,
      width,
      divider,
      noPadding,
      handleFile,
      installationId,
      addPhotoComment,
      user,
    } = this.props

    const renderButtons = () => {
      if (!presentational) {
        if (type === 'notes') {
          if (deletePhoto) {
            return (
              <SecondaryButton variant="danger" handleClick={deletePhoto}>
                Delete
              </SecondaryButton>
            )
          } else {
            return (
              <SecondaryButton tag="label" variant="danger">
                Upload New Photo
                <input type="file" accept="image/jpeg" onChange={changePhoto} />
              </SecondaryButton>
            )
          }
        }
      }
    }

    // use helper to check if the logged in user is admin
    const isAdmin = this.props.user ? isUserAdmin(this.props.user) : false

    return (
      <StyledPhotoBox type={type} noPadding={noPadding} isAdmin={isAdmin}>
        {editing && (
          <Modal
            // close the modal, and make sure errors and message cleared
            //so if close then open up another direction, wont show prev data
            close={() => {
              this.setState({ error: '', message: '' })
              close()
            }}
            width={width ? width : undefined}
            imageLoaded={this.state.imageLoaded}
            noPadding={noPadding}
          >
            <div
              className="content"
              onClick={e => {
                if (!e.target.matches('#notes')) {
                  if (document.getElementById('notes')) {
                    document.getElementById('notes').blur()
                  }
                }
              }}
            >
              <div className="header">
                <h3>{!presentational ? `Editing ${header}` : header}</h3>

                <div className="close">
                  <Icon icon="close" handleClick={close} />
                </div>
              </div>

              {loading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <div className={`row ${divider ? 'divider' : ''}`}>
                    <div className="image">
                      <div
                        className="icon"
                        onClick={() =>
                          imageUrl && imageUrl.length
                            ? this.props.context.setImage(imageUrl)
                            : false
                        }
                      >
                        <FontAwesomeIcon icon={faSearchPlus} />
                      </div>
                      <img
                        onLoad={() => this.setState({ imageLoaded: true })}
                        src={imageUrl && imageUrl.length ? imageUrl : '//placehold.it/445x300'}
                        alt={header}
                        ref="image"
                      />
                      {type === 'chat' && (
                        <React.Fragment>
                          {!photoStatus && (
                            <ImageInput
                              variant="lg"
                              fluid={true}
                              handleFile={e => handleFile(e)}
                              text="Add Photo"
                            />
                          )}
                          {photoStatus === 'uploaded' && isAdmin && (
                            <div className="status-btns">
                              <div className="row">
                                <Button
                                  text="Approve"
                                  variant={`lg ${
                                    !this.props.safeToUpdateStatus ? 'disabled' : 'primary'
                                  }`}
                                  handleClick={() => {
                                    if (this.props.safeToUpdateStatus) {
                                      this.props.markPhotoStatus('approved')
                                    }
                                  }}
                                />
                                <Button
                                  text="Revise"
                                  variant={`lg ${
                                    !this.props.safeToUpdateStatus ? 'disabled' : 'danger'
                                  }`}
                                  handleClick={() => {
                                    if (this.props.safeToUpdateStatus) {
                                      this.props.markPhotoStatus('revise')
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {photoStatus && photoStatus === 'approved' && (
                            <div className="photo-status">
                              <Icon icon="solidCheck" color="primary" />
                              <span>APPROVED</span>
                            </div>
                          )}
                          {photoStatus && photoStatus === 'revise' && (
                            <React.Fragment>
                              <div className="photo-status">
                                <Icon icon="close" color="danger" />
                                <span>REVISE</span>
                              </div>
                              <div className="status-btns">
                                <div className="row">
                                  <Button
                                    text="Approve"
                                    fluid={true}
                                    variant={`lg ${
                                      !this.props.safeToUpdateStatus ? 'disabled' : 'primary'
                                    }`}
                                    handleClick={() => {
                                      if (this.props.safeToUpdateStatus) {
                                        this.props.markPhotoStatus('approved')
                                      }
                                    }}
                                  />
                                  <ImageInput
                                    variant="lg"
                                    color="white"
                                    fluid={true}
                                    handleFile={e => handleFile(e)}
                                    text="Re-upload Image"
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                    <div className="notes">
                      {presentational ? (
                        type === 'notes' ? (
                          <div>{messages}</div>
                        ) : (
                          <div>no notes</div>
                        )
                      ) : (
                        <React.Fragment>
                          {type === 'notes' && (
                            <React.Fragment>
                              <Field>
                                <label htmlFor="activeNotes">Notes</label>
                                <textarea
                                  id="activeNotes"
                                  name="activeNotes"
                                  value={messages}
                                  onChange={handleChange}
                                />
                              </Field>
                              <Button
                                type="submit"
                                text="Update Notes"
                                variant="primary"
                                handleClick={saveNotes}
                              />
                            </React.Fragment>
                          )}
                          {type === 'chat' && (
                            <div className="chat">
                              {this.props.messages.length < 1 ? (
                                <div className="messages">
                                  <div className="no-messages">There are no comments.</div>
                                </div>
                              ) : (
                                <div className="messages">
                                  {this.props.messages.map(msg => {
                                    return (
                                      <div className="message" key={msg._id}>
                                        <div className="row">
                                          <div className="profile-img">
                                            <img src={msg.profileImage} alt="" />
                                          </div>
                                          <div className="content">
                                            <div className="meta-row">
                                              <div className="user-name">{msg.userName}</div>
                                              <div className="date">
                                                {format(msg.date, 'h:mma - ddd, MMM DD')}
                                              </div>
                                            </div>
                                            <div className="meta-row">
                                              {msg.status && msg.status === 'approved' && (
                                                <div className="status">
                                                  <Icon icon="solidCheck" color="primary" />
                                                  <span>APPROVED</span>
                                                </div>
                                              )}
                                              {msg.status && msg.status === 'revise' && (
                                                <div className="status">
                                                  <Icon icon="close" color="danger" />
                                                  <span>REVISE</span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="text">{msg.text}</div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              )}

                              {/* add-comment and other */}
                              <div className="meta">
                                <div className="new-message">
                                  <div className="row">
                                    <div className="profile-img">
                                      <img src={user.profileImage} alt="" />
                                    </div>
                                    <textarea
                                      name="message"
                                      value={this.state.message}
                                      onChange={e => this.handleChange(e)}
                                    />
                                  </div>
                                  {this.state.error.length > 0 ? (
                                    <div className="error">{this.state.error}</div>
                                  ) : null}
                                  <div className="submit-btn">
                                    <SecondaryButton tag="label">
                                      <span>Post</span>
                                      <input type="submit" onClick={this.handleSubmit} />
                                    </SecondaryButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {renderButtons()}
                </React.Fragment>
              )}
            </div>
          </Modal>
        )}
      </StyledPhotoBox>
    )
  }
}

export default withViewerContext(PhotoBox)

const StyledPhotoBox = styled.div`
  .row {
    display: flex;
    z-index: 10;
    @media screen and (max-width: ${breakpoints.small}px) {
      display: block;
    }
  }

  .divider {
    > div:first-child {
      border-right: 1px solid #efefef;
    }
  }

  .btn-row {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;

    > button {
      margin-bottom: 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    z-index: 100;
    ${props => {
      if (props.noPadding) {
        return `
          padding: 1.5em 1.5em;
        `
      } else {
        return `
          padding-bottom: 1em;
        `
      }
    }}
    margin-bottom: ${props => (props.noPadding ? '0' : '1em')};
    border-bottom: 1px solid #efefef;
    h3 {
      margin-bottom: 0;
    }
  }

  .image {
    ${props => {
      if (props.noPadding) {
        return `
          padding: 1.5em;
        `
      } else {
        return `
          padding-bottom: 1em;
          padding-right: 1em;
        `
      }
    }};

    position: relative;
    flex-basis: ${props => (props.type && props.type === 'chat' ? '50%' : '60%')};

    @media screen and (max-width: ${breakpoints.small}px) {
      display: block;
      width: 100%;
    }

    > img {
      width: 100%;
      height: auto;
      border: 1px solid ${colors.lightGrey};
      border-radius: 4px;
    }

    > .icon {
      color: ${colors.primary};
      cursor: pointer;
      font-size: 1.5em;
      line-height: 1;
      position: absolute;
      top: .5em;
      left: .5em;
      z-index: 2;
    }
  }

  .status-btns {
    .row {
      display: flex;
      margin: 0 -0.25em;

      > button, > label {
        width: 50%;
        margin: 0 0.25em;
      }
    }

    &.no-flex {
      .row {
        display: block;
        margin: 0.25em 0;
      }
      > button {
        width: 100%;
        margin: 0;
      }
    }
  }

  .photo-status {
    font-size: 0.85em;
    font-weight: 600;
    margin-bottom: 4px;
    .icon {
      margin-right: 4px;
    }
  }

  .notes {
    flex-basis: ${props => (props.type && props.type === 'chat' ? '50%' : '40%')};
    ${props => {
      if (props.noPadding) {
        return `
          padding: 1.5em;
        `
      } else {
        return `
          padding-bottom: 1em;
          padding-right: 1em;
        `
      }
    }};
    .sub-title {
      color: ${colors.grey2};
      font-size: 0.9em;
      font-weight: 600;
    }

    @media screen and (max-width: ${breakpoints.small}px) {
      display: block;
      width: 100%;
      padding: 0;
    }
  }

  .chat {
    position: relative;
    height: 34em;

    .row {
      display: flex;
    }

    .profile-img {
      flex-basis: 10%;
      margin-right: 0.5em;
      max-width: 32px;
      max-height: 32px;
      img {
        border-radius: 4px;
        width: 32px;
        height: 32px;
        max-width: 32px;
        max-height: 32px;
      }
    }

    .messages {
      height: 75%;
      overflow: auto;
    }

    .message {
      margin-bottom: 2em;
      .content {
        flex-grow: 1;
        padding-left: 0.5em;
        border-left: 1px solid ${colors.lightGrey};
      }
      /* for username / date */
      .meta-row {
        display: flex;
        justify-content: space-between;
      }
      .user-name {
        font-weight: 600;
        margin-bottom: 4px;
      }
      .date {
        font-size: 0.75em;
        color: ${colors.grey};
        margin-right: 1em;
      }
      .status {
        font-size: 0.85em;
        font-weight: 600;
        margin-bottom: 4px;
        .icon {
          margin-right: 4px;
        }
      }
      .text {
        font-size: 0.875em;
      }
    }

    .meta {}

    .new-message {
      position: absolute;
      bottom: 0;
      width: 100%;

      textarea {
        flex-grow: 1;
        height: 6em;
        min-height: 6em;
        margin-left: 0.5em;
      }
    }

    .profile-img {
      flex-basis: 10%;
      margin-right: 0.5em;
      max-width: 32px;
      max-height: 32px;
      img {
        border-radius: 4px;
        width: 32px;
        height: 32px;
        max-width: 32px;
        max-height: 32px;
      }
    }

    .submit-btn {
      float: right;
      margin-top: 0.5em;
      clear: both;
    }
  }

  .error {
    font-size: 0.875em;
    color: ${colors.danger};
    float: right;
  }

  .close {
    cursor: pointer;
  }
`
