import styled from 'styled-components'
import { em } from 'polished'
import { colors } from '../../helpers/foundation'

const OptionCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
  /* border: 1px solid ${colors.grey6}; */
  background-color: #fff;
  &:hover {
    background-color: ${colors.grey7};
  }

  > .media {
    width: 100%;
  }

  > .title {
    padding: 0;
    font-weight: 600;
    font-size: ${em('14px')};
    text-transform: uppercase;
  }

  > .content {
    background-color: ${colors.grey5};
    color: ${colors.grey2};
    font-weight: 600;
  }

  > .notes {
    /* padding: ${em('4px')}; */
    font-size: ${em('14px')};
    width: 100%;
    color: ${colors.grey4};
  }
`

export default OptionCard
