import { LOADING, ERROR, LOAD_GLOBAL_SETTINGS, UPDATE_TIMER, UPDATE_SURVEY_DT } from './types'

export const loading = bool => ({
  type: LOADING,
  payload: {
    loading: bool,
  },
})

export const requestError = message => ({
  type: ERROR,
  payload: {
    message,
  },
})

export const loadGlobalSettings = globalSettings => ({
  type: LOAD_GLOBAL_SETTINGS,
  payload: {
    globalSettings,
  },
})

export const updateTimer = (timerGroup, timerName, updatedTime) => ({
  type: UPDATE_TIMER,
  payload: {
    timerGroup,
    timerName,
    updatedTime,
  },
})

export const updateSurveyDt = newTime => ({
  type: UPDATE_SURVEY_DT,
  payload: {
    newTime,
  },
})
