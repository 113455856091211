import React, { Component } from 'react'
import styled from 'styled-components'
import { isArray, isEmpty } from 'lodash'
import axios from 'axios'
import { addProductionUrl } from '../../../helpers/constants'
import { colors } from './../../../helpers/foundation'

import Modal from './../../sharedComponents/Modal'
import Icon from './../../sharedComponents/Icon'
import Loader from '../../sharedComponents/Loader'
import LoggedInConfirmation from './LoggedInConfirmation'
import ExistingUserConfirmation from './ExistingUserConfirmation'
import NonExistingUserConfirmation from './NonExistingUserConfirmation'
import UploadSiteSurvey from './UploadSiteSurvey'

class ScheduleConfirmationModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      submitted: false,
      message: '',
      errors: [],
      customerData: {},
      siteSurveyAttachment: null,
    }

    this.Modal = React.createRef()
  }

  setSubmitting = tf => {
    this.setState(prevState => ({ submitting: tf }))
  }

  setSubmitted = () => {
    this.setState(prevState => ({ submitted: true }))
  }

  setMessage = msg => {
    this.setState(prevState => ({ message: msg, errors: [] }))
  }

  setErrors = errors => {
    this.setState(prevState => ({
      // if errors passed in is arr spread it, otherwise just add it to the array
      // errors: isArray(errors) ? [...prevState.errors, ...errors] : [...prevState.errors, errors],
      errors,
    }))
  }

  setCustomerData = customerData => {
    // sets the data for a customer, needs to be persisted so promises can be chained
    this.setState(
      prevState => ({ customerData, errors: [], message: '' }),
      () => {
        this.Modal.handleResize()
      }
    )
  }

  uploadSiteSurveyAttachment = file => {
    console.log('uploading site survey attachment?', file)
    this.setState(prevState => ({
      siteSurveyAttachment: file,
    }))
  }

  handleLogin = async (email, password) => {
    try {
      this.setSubmitting(true)
      const response = await this.props.login(email, password)
      if (!response) throw 'Password is incorrect. Try again.'
      this.setSubmitting(false)
      return true
    } catch (err) {
      this.setErrors([err])
      this.setSubmitting(false)
      return false
    }
  }

  submitAndComplete = async (skipSiteSurvey = false, requiresLogin = true) => {
    try {
      let { customerData, siteSurveyAttachment, submitting } = this.state

      if (submitting) {
        return false
      }
      this.setSubmitting(true)

      // Create the customer (if needed) and the project and all project deps
      const customerResponse = await axios.post(
        `${addProductionUrl()}/api/customers/create`,
        customerData
      )

      const { user, project } = customerResponse.data

      // Only login here if there is no existing user, if
      // there is existing user it will have in that component
      if (requiresLogin) {
        await this.props.login(user.email, customerData.password)
      }

      // Schedule the time for the user
      this.props.scheduleTime(this.props.selectedTime, project, user)

      // If the user decides to skip uploading their site survey, this is
      // the end. Otherwise it will make the post request to the server
      if (!skipSiteSurvey) {
        const formData = new FormData()
        formData.append('site-survey', siteSurveyAttachment, siteSurveyAttachment.name)
        formData.append('projectId', project._id)

        await axios.post(
          `${addProductionUrl()}/api/surveys/${project.survey}/site-survey`,
          formData,
          {
            headers: {
              enctype: 'multipart/form-data',
            },
          }
        )
      }

      this.setSubmitting(false)
      this.setSubmitted(true)
      this.setMessage('Success!')

      setTimeout(() => {
        this.props.history.push(`/project/${project._id}/${project.slug}`)
      }, 2000)
    } catch (err) {
      this.setSubmitting(false)
      this.setErrors([err.message])
    }
  }

  render() {
    const { formOfConfirmation, quote, user } = this.props
    return (
      <StyledScheduleConfirmationModal>
        <Modal
          width={420}
          close={this.props.closeModal}
          centered={false}
          ref={Modal => {
            this.Modal = Modal
          }}
        >
          <div className="close">
            <Icon icon="close" handleClick={this.props.closeModal} />
          </div>

          {isEmpty(this.state.customerData) ? (
            // Need to gather the users data
            <>
              {formOfConfirmation === 'loggedIn' && (
                <LoggedInConfirmation
                  quote={quote}
                  selectedTime={this.props.selectedTime}
                  scheduleTime={this.props.scheduleTime}
                  login={this.props.login}
                  setSubmitting={this.setSubmitting}
                  setMessage={this.setMessage}
                  setErrors={this.setErrors}
                  history={this.props.history}
                  user={user}
                  setCustomerData={this.setCustomerData}
                  {...this.state}
                />
              )}
              {formOfConfirmation === 'userExists' && (
                <ExistingUserConfirmation
                  quote={quote}
                  selectedTime={this.props.selectedTime}
                  scheduleTime={this.props.scheduleTime}
                  login={this.props.login}
                  setSubmitting={this.setSubmitting}
                  setMessage={this.setMessage}
                  setErrors={this.setErrors}
                  history={this.props.history}
                  user={user}
                  setCustomerData={this.setCustomerData}
                  handleLogin={this.handleLogin}
                  {...this.state}
                />
              )}
              {formOfConfirmation === 'userDoesNotExist' && (
                <NonExistingUserConfirmation
                  quote={quote}
                  selectedTime={this.props.selectedTime}
                  scheduleTime={this.props.scheduleTime}
                  login={this.props.login}
                  setSubmitting={this.setSubmitting}
                  setMessage={this.setMessage}
                  setErrors={this.setErrors}
                  history={this.props.history}
                  user={user}
                  setCustomerData={this.setCustomerData}
                  {...this.state}
                />
              )}
            </>
          ) : (
            // Have the user upload their site survey
            <>
              <UploadSiteSurvey
                siteSurveyAttachment={this.state.siteSurveyAttachment}
                uploadSiteSurveyAttachment={this.uploadSiteSurveyAttachment}
                submitAndComplete={this.submitAndComplete}
                submitting={this.state.submitting}
                submitted={this.state.submitted}
                user={user || null}
              />
            </>
          )}

          {/* effects */}
          {this.state.submitting && (
            <div className="loader">
              <Loader width="30" small={true} />
            </div>
          )}
          {this.state.message && <div className="message">{this.state.message}</div>}
          {this.state.errors && this.state.errors.length > 0 && (
            <div className="errors">
              {this.state.errors.map((error, idx) => {
                return (
                  <div key={idx} className="error">
                    {error}
                  </div>
                )
              })}
            </div>
          )}
        </Modal>
      </StyledScheduleConfirmationModal>
    )
  }
}

const StyledScheduleConfirmationModal = styled.div`
  h3 {
    margin-bottom: 1em;
  }

  p {
    line-height: 1.15;
    margin-bottom: 1em;
  }

  .message,
  .loader,
  .error {
    text-align: right;
    margin-top: 0.5rem;
  }

  .loader {
    display: flex;
    justify-content: flex-end;
  }

  .message {
    color: ${colors.success};
  }

  .error {
    color: ${colors.danger};
    font-size: 0.875em;
  }
`

export default ScheduleConfirmationModal
