import { connect } from 'react-redux'
import AdminDashboard from './../components/pages/Projects/adminDashboard/'

import { fetchGlobalSettings, updateTimer, updateSurveyDt } from '../redux/modules/globalSettings/'

const mapStateToProps = state => {
  return {
    loading: state.globalSettings.loading,
    error: state.globalSettings.error,
    globalSettings: state.globalSettings.globalSettings,
    settingsLoaded: state.globalSettings.settingsLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
    updateTimer: (timerGroup, timerName, updatedTime) =>
      dispatch(updateTimer(timerGroup, timerName, updatedTime)),
    updateSurveyDt: newDt => dispatch(updateSurveyDt(newDt)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboard)
