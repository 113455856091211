import styled from 'styled-components'

const FieldGroup = styled.div`
  display: flex;
  margin-left: -10px;

  > div {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;

    &:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  select {
    min-width: auto;
  }
`

export default FieldGroup
