import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { breakpoints, colors } from './../../../helpers/foundation'
import { toTitleCase, splitStringByCapital } from './../../../utils'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'
import { withViewerContext } from './../../../contexts/ViewerContext'

import Overview from './Overview'
import Survey from './Survey'
import Estimate from './Estimate'
import Paperwork from './Paperwork'
import PhotoBox from './../../sharedComponents/PhotoBox'

class History extends PureComponent {
  state = {
    tabIdx: this.props.openTab || 0,
    viewingPhoto: null,
    viewingPhotoTitle: null,
    loading: false,
    pdfDownloading: false,
    projectActions: [],
    projectActionsLoading: false,
  }

  componentDidMount = () => {
    // get all actions for given project
    this.setState(prevState => ({ projectActionsLoading: true }))
    axios
      .get(`${addProductionUrl()}/api/actions/${this.props.project._id}`)
      .then(response => {
        if (response.data) {
          this.setState(prevState => ({
            projectActions: response.data,
            projectActionsLoading: false,
          }))
        }
      })
      .catch(err => {
        console.log('got err', err)
        this.setState(prevState => ({
          projectActionsLoading: false,
        }))
      })
  }

  changeTab = tabIdx => {
    this.setState(prevState => ({ tabIdx }))
  }

  openPhotobox = (type, key, id) => {
    const { photos, details } = this.props.survey
    let viewingPhoto, viewingPhotoTitle

    if (type === 'photos') {
      viewingPhoto = photos[key]
      viewingPhotoTitle = toTitleCase(splitStringByCapital(key))
    }

    if (type === 'details') {
      const detail = details[key]
      viewingPhoto = detail.images[detail.images.findIndex(d => d._id === id)]
      viewingPhotoTitle = toTitleCase(key)
    }

    this.setState(prevState => {
      return {
        viewingPhoto,
        viewingPhotoTitle,
      }
    })
  }

  closePhotobox = () => {
    this.setState(prevState => {
      return {
        viewingPhoto: null,
        viewingPhotoTitle: null,
      }
    })
  }

  downloadPDF = async () => {
    const { project } = this.props
    this.setState({ pdfDownloading: true })
    axios
      .get(`${addProductionUrl()}/api/pdfs/${project._id}?q=employee`, { responseType: 'blob' })
      .then(res => {
        // turn the blob into dataURL, set to a link, click it, then remove it
        // TODO: can probably use existing function from utils to do this
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${project.name}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link)
        this.setState({ pdfDownloading: false })
      })
      .catch(err => {
        this.setState({ pdfError: 'Error', pdfDownloading: false })
      })
  }

  render() {
    const {
      tabIdx,
      viewingPhoto,
      viewingPhotoTitle,
      loading,
      pdfDownloading,
      projectActions,
      projectActionsLoading,
    } = this.state
    const { project, survey, estimate, paperwork, isReview, context } = this.props

    return (
      <React.Fragment>
        <PhotoBox
          presentational={true}
          editing={viewingPhoto}
          header={viewingPhotoTitle && viewingPhotoTitle}
          imageUrl={viewingPhoto && viewingPhoto.large}
          notes={viewingPhoto && viewingPhoto.notes}
          close={this.closePhotobox}
          loading={loading}
        />
        <StyledHistoryView>
          <div className="persons">
            {[...project.members.survey, ...project.members.estimate].map((member, idx) => {
              return (
                <img
                  key={idx}
                  src={member.profileImage ? member.profileImage : '//placehold.it/24x24'}
                  className="image item"
                  alt=""
                />
              )
            })}
          </div>

          <div className="wrapper">
            <Tabs>
              <Tab className={tabIdx === 0 && 'selected'} onClick={e => this.changeTab(0)}>
                Overview
              </Tab>
              {survey && (
                <Tab className={tabIdx === 1 && 'selected'} onClick={e => this.changeTab(1)}>
                  Survey
                </Tab>
              )}

              {estimate && (
                <Tab className={tabIdx === 2 && 'selected'} onClick={e => this.changeTab(2)}>
                  Estimate
                </Tab>
              )}

              {(paperwork ||
                (project.paperwork &&
                  project.paperwork.files &&
                  project.paperwork.files.find(file => file.name === 'Site Survey').filepath)) && (
                <Tab className={tabIdx === 3 && 'selected'} onClick={e => this.changeTab(3)}>
                  Paperwork
                </Tab>
              )}
            </Tabs>

            <div className="content">
              {tabIdx === 0 && (
                <Overview
                  project={project}
                  projectActions={projectActions}
                  projectActionsLoading={projectActionsLoading}
                />
              )}
              {tabIdx === 1 && survey && (
                <Survey
                  isReview={isReview}
                  project={project}
                  survey={survey}
                  openPhotobox={this.openPhotobox}
                  downloadPDF={this.downloadPDF}
                  pdfDownloading={pdfDownloading}
                  context={context}
                />
              )}
              {tabIdx === 2 && estimate && (
                <Estimate
                  project={project}
                  estimate={estimate}
                  downloadPDF={this.downloadPDF}
                  pdfDownloading={pdfDownloading}
                />
              )}
              {tabIdx === 3 &&
                (paperwork ||
                  project.paperwork.files.find(file => file.name === 'Site Survey').filepath) && (
                  <Paperwork project={project} paperwork={paperwork || project.paperwork} />
                )}
            </div>
          </div>
        </StyledHistoryView>
      </React.Fragment>
    )
  }
}

export default withViewerContext(History)

const StyledHistoryView = styled.div`
  .persons {
    background-color: ${colors.grey5};
    padding-left: 1em;
  }

  .image {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  .item {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  .wrapper {
    padding: 0.5em 1em;
  }

  .grid-items {
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
    margin: 0 -0.5em;
  }

  .grid-item {
    margin-left: 0.25em;
    margin-right: 0.25em;
    margin-bottom: 0.5em;
    flex: 0 0 calc(100% - 1em);

    @media screen and (min-width: ${breakpoints.large}px) {
      flex: 0 0 calc(25% - 0.5em);
    }
    @media screen and (max-width: ${breakpoints.large}px) and (min-width: ${breakpoints.medium}px) {
      flex: 0 0 calc(25% - 0.5em);
    }
    @media screen and (max-width: ${breakpoints.medium}px) and (min-width: ${breakpoints.small}px) {
      flex: 0 0 calc(50% - 0.5em);
    }
    @media screen and (max-width: ${breakpoints.small}px) {
      flex: 0 0 calc(100% - 0.5em);
    }
  }

  .small-padding {
    padding: 0.5em 1em;
  }
`

const Tabs = styled.div`
  margin-bottom: 2em;
`

const Tab = styled.a`
  display: inline-block;
  margin-right: 1em;
  cursor: pointer;
  color: ${colors.grey};
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;

  &.selected {
    color: ${colors.primary};
  }

  &:last-child {
    margin-right: 0;
  }
`
