import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'
import { getNextStep } from './utils'

import Step from './../sharedComponents/step'
import Button from './../sharedComponents/Button'
import AntiMobileAction from './../sharedComponents/AntiMobileAction'

const steps = {
  'Schedule Installation': 1,
  'Installation First Payment': 2,
  'Installation Prep': 3,
  'Installation Pending': 4,
  'In Progress': 5,
  Inspection: 6,
  'Final Payment': 7,
  Completed: 8,
}

class Sidebar extends Component {
  stepStatus = num => {
    const { installationStatus } = this.props
    const currentStep = steps[installationStatus]
    return currentStep <= num ? 'incomplete' : 'complete'
  }

  // since using status instead of currentStep, need to know what the next step is

  render() {
    const { installationStatus } = this.props
    return (
      <StyledSidebar>
        <div className="sidebar-top">
          <div className="sidebar-inner">
            <Step
              status={this.stepStatus(1)}
              title="schedule install"
              num={1}
              isActive={steps[installationStatus] === 1}
              isCurrent={steps[installationStatus] === 1}
            />
            <Step
              status={this.stepStatus(2)}
              title="payment"
              num={2}
              isActive={steps[installationStatus] === 2}
              isCurrent={steps[installationStatus] === 2}
            />
            <Step
              status={this.stepStatus(3)}
              title="install prep"
              num={3}
              isActive={steps[installationStatus] === 3}
              isCurrent={steps[installationStatus] === 3}
            />
            <Step
              status={this.stepStatus(4)}
              title="installation pending"
              num={4}
              isActive={steps[installationStatus] === 4}
              isCurrent={steps[installationStatus] === 4}
            />
            <Step
              status={this.stepStatus(5)}
              title="in progress"
              num={5}
              isActive={steps[installationStatus] === 5}
              isCurrent={steps[installationStatus] === 5}
            />
            <Step
              status={this.stepStatus(6)}
              title="permit inspection"
              num={6}
              isActive={steps[installationStatus] === 6}
              isCurrent={steps[installationStatus] === 6}
            />
            <Step
              status={this.stepStatus(7)}
              title="final payment"
              num={7}
              isActive={steps[installationStatus] === 7}
              isCurrent={steps[installationStatus] === 7}
            />
            <Step
              status={this.stepStatus(8)}
              title="complete"
              num={8}
              isActive={steps[installationStatus] === 8}
              isCurrent={steps[installationStatus] === 8}
            />
          </div>
        </div>

        <AntiMobileAction>
          <div className="sidebar-action">
            <Button
              variant={`lg small-vert-padding ${this.props.canStepNext() ? null : 'disabled'}`}
              text="Continue"
              handleClick={() => {
                if (this.props.canStepNext()) {
                  this.props.stepNext()
                }
              }}
              fluid={true}
            />
          </div>
        </AntiMobileAction>
      </StyledSidebar>
    )
  }
}

const StyledSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .sidebar-top {
    flex-grow: 1;
  }

  .sidebar-header {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .sidebar-inner {
    padding: 1em;
  }

  .sidebar-action {
    border-top: 1px solid ${colors.lightGrey};
    flex-shrink: 0;
    padding: 1em;

    button:last-child {
      margin-bottom: 0;
    }
  }
`

export default Sidebar
