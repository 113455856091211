import styled from 'styled-components'
import { breakpoints } from './../../../helpers/foundation'

export const Collection = styled.div`
  margin: ${props => (props.isSurvey ? '0 -0.625em' : '0 -2em')};

  @media screen and (min-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: ${props => props.hasMaxWidth && '50em'};
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    display: block;
    margin: 0 -0.5em;
  }
`

export const CollectionItem = styled.div`
  margin-right: ${props => (props.isSurvey ? '0.625em' : '2em')};
  margin-left: ${props => (props.isSurvey ? '0.625em' : '2em')};
  margin-bottom: ${props => (props.isSurvey ? '1.75em' : '4em')};
  flex: 0 1
    ${props =>
      props.columns === 2
        ? `calc(50% - ${props.isSurvey ? '1.25em' : '1em'})`
        : `calc(33.33333% - ${props.isSurvey ? '1.25em' : '4em'})`};

  @media screen and (max-width: ${breakpoints.medium}px) {
    margin-left: 1em;
    margin-right: 1em;
    display: block;
  }
`
