import types from './types'

const changeWeek = (start, end) => ({
  type: types.CHANGE_WEEK,
  payload: {
    start,
    end,
  },
})

const eventsReceived = slots => ({
  type: types.EVENTS_RECEIVED,
  payload: {
    slots,
  },
})

const selectTime = slot => ({
  type: types.SELECT_TIME,
  payload: {
    slot,
  },
})

const confirmingTime = () => ({
  type: types.CONFIRMING_TIME,
})

const timeConfirmed = () => ({
  type: types.TIME_CONFIRMED,
})

const error = error => ({
  type: types.ERROR,
  payload: {
    error,
  },
})

const customTimeError = error => ({
  type: types.CUSTOM_TIME_ERROR,
  payload: {
    error,
  },
})

const timeConfirmedError = error => ({
  type: types.TIME_CONFIRMED_ERROR,
  payload: {
    error,
  },
})

const reset = () => ({
  type: types.RESET,
})

const fetchQuote = (loading, quote, quoteExists = true, quoteValid = true) => ({
  type: types.FETCH_QUOTE,
  payload: {
    loadingQuote: loading,
    quote,
    quoteExists,
    quoteValid,
  },
})

export default {
  changeWeek,
  eventsReceived,
  selectTime,
  confirmingTime,
  timeConfirmed,
  timeConfirmedError,
  error,
  customTimeError,
  reset,
  fetchQuote,
}
