import styled from 'styled-components'

const Bucket = styled.div`
  display: flex;
  align-items: center;

  > .media {
    flex-shrink: 0;
    margin-right: 1.5em;

    > img
      display: block
    }
  }

  > .content {
    flex-grow: 1;
  }
`

export default Bucket
