import React from 'react'
import styled from 'styled-components'
import FenceLoader from './../../assets/fence-loader.gif'

const StyledLoader = styled.img`
  align-self: center;
  max-width: 50px;
  margin: 20px auto;
  display: block;
  z-index: 2;
  user-select: none;

  ${props => {
    if (!props.small) {
      return `
        margin: 20px auto;
      `
    } else {
      return `
        margin: 0;
      `
    }
  }};

  ${props => {
    if (props.centered) {
      return `
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    }
  }};

  ${props => {
    if (props.width) {
      return `
        max-width: ${props.width}px;
      `
    } else {
      return `max-width: 50px;`
    }
  }};
`

const Loader = props => {
  return (
    <StyledLoader
      src={FenceLoader}
      alt="loading"
      centered={props.centered}
      width={props.width}
      small={props.small}
    />
  )
}

export default Loader
