import React, { Component } from 'react'
import styled from 'styled-components'
import { getTotals } from './../../../../helpers/totals'
import isRole from './../../../../helpers/roles'
import axios from 'axios'
import { addProductionUrl } from './../../../../helpers/constants'

import Header from './../../../sharedComponents/Header'
import Container from './../../../sharedComponents/Container'
import Notice from './../../../sharedComponents/Notice'
import Button from './../../../sharedComponents/Button'
import SidebarLayout from './../../../sharedComponents/layouts/SidebarLayout'
import History from './../../../sharedComponents/History'
import ResizeableSector from './../../../sharedComponents/ResizeableSector'
import NotPermitted from './../../../routeUtils/NotPermitted'
import Checkbox from './../../../sharedComponents/Checkbox'

class EmployeePendingPayment extends Component {
  state = {
    paymentConfirm: false,
  }

  handleSelect = e => {
    this.setState(prevState => ({ paymentConfirm: !prevState.paymentConfirm }))
  }

  confirm = () => {
    const { project } = this.props
    const totals = getTotals(
      [
        Number(project.estimate.parts.total),
        Number(project.estimate.labors.total),
        Number(project.estimate.services.total),
      ],
      0.07
    )

    let totalToUse = null
    if (project.paymentTerms === '100') {
      totalToUse = totals.cashTotal
      // totalToUse = (totals.cashTotal + 0).toFixed(2)
    } else if (project.paymentTerms === '50/50') {
      totalToUse = (totals.cashTotal / 2).toFixed(2)
    } else if (project.paymentTerms === '25/50/25') {
      totalToUse = (totals.cashTotal / 4).toFixed(2)
    }

    this.props
      .estimateUpdateServer(project.estimate._id, {
        status: 'Completed',
        from: 'Pending Payment',
        projectId: project._id,
        paymentAmount: totalToUse,
        paymentMethod: 'cash',
      })
      .then(success => {
        axios
          .post(`${addProductionUrl()}/api/projects/${project._id}/payment`, {
            paymentOrder: 0,
            paymentType: 'cash',
          })
          .then(success => this.props.history.push('/projects/estimates'))
      })
  }

  render() {
    const { paymentConfirm } = this.state
    const { project, user } = this.props
    const totals = getTotals(
      [
        Number(project.estimate.parts.total),
        Number(project.estimate.labors.total),
        Number(project.estimate.services.total),
      ],
      0.07
    )

    let totalToUse = null
    if (project.paymentTerms === '100') {
      totalToUse = totals.cashTotal
      // totalToUse = (totals.cashTotal + 0).toFixed(2)
    } else if (project.paymentTerms === '50/50') {
      totalToUse = (totals.cashTotal / 2).toFixed(2)
    } else if (project.paymentTerms === '25/50/25') {
      totalToUse = (totals.cashTotal / 4).toFixed(2)
    }

    if (!(isRole(user).admin || isRole(user).estimateManager)) {
      return (
        <NotPermitted history={this.props.history} toText="Estimate" toUrl="/projects/estimates" />
      )
    } else {
      return (
        <SidebarLayout
          history={
            <History project={project} survey={project.survey} estimate={project.estimate} />
          }
          statusComponent={
            <React.Fragment>
              <StyledEmployeePendingPayment>
                  <React.Fragment>
                    <Header title="Confirm reciept of payment." />
                    <Container>
                      <Notice>We are waiting for the Customer to make a payment. If the Customer doesn't handle it on their side, we can confirm their payment here.</Notice>
                      <ResizeableSector title="Amount due at this time:">
                        <h2 className="total">${totalToUse}</h2>

                        <div className="confirm">
                          <Checkbox
                            name="paymentConfirm"
                            value="paymentConfirm"
                            checked={paymentConfirm ? true : false}
                            checkmark={true}
                            text="I confirm that OFS has received payment for the amount listed above in the form of cash, check, or financing."
                            size="large"
                            handleChange={this.handleSelect}
                          />
                          <Button
                            text="Confirm"
                            variant={paymentConfirm ? 'lg primary' : 'lg disabled'}
                            fluid={true}
                            handleClick={this.confirm}
                          />
                        </div>
                      </ResizeableSector>
                    </Container>
                  </React.Fragment>
              </StyledEmployeePendingPayment>
            </React.Fragment>
          }
        />
      )
    }
  }
}

export default EmployeePendingPayment

const StyledEmployeePendingPayment = styled.div`
  .title {
    margin-bottom: 0.5em;
    font-weight: 400;
  }

  .total {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 2.5em;
  }

  .confirm {
    position: relative;
    width: 100%;
    max-width: 20em;
    margin-top: -1.5em;

    label {
      width: 100%;
      margin-bottom: 2.5em;
    }

    label::before {
      /* position: relative; */
      /* margin-bottom: 8px; */
      margin-right: 1em;
    }

    span {
      margin-top: 1.5em;
    }
  }
`
