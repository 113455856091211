import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

import Image from './images/estimate-review.png'

const CustomerEstimateReview = props => {
  return (
    <div>
      <StyledCustomerEstimateReview>
        <Header title="Your estimate is being reviewed." />
        <Container>
          <Notice text="We’ve put together your estimate, and now we’re just double-checking it to make sure it meets our accuracy standards. It’s almost ready!" />
          <div className="centered">
            <img src={Image} alt="Estimate creation graphic" />
          </div>
        </Container>
      </StyledCustomerEstimateReview>
    </div>
  )
}

export default CustomerEstimateReview

const StyledCustomerEstimateReview = styled.div`
  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
