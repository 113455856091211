import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { addProductionUrl } from './../../../helpers/constants'

import Title from './../../sharedComponents/Title'
import Field from './../../sharedComponents/Field'
import Button from './../../sharedComponents/Button'

const initialFields = {
  email: '',
  password: '',
}

class ExistingUserConfirmation extends Component {
  state = {
    fields: initialFields,
  }

  componentDidMount = () => {
    const { quote } = this.props
    this.setState(prevState => ({
      fields: {
        email: quote.email,
        password: '',
      },
    }))
  }

  handleChange = e => {
    let { name, value } = e.target
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }))
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { quote } = this.props
    const { email, password } = this.state.fields

    if (this.props.submitting) {
      return false
    }

    // pw must be at least 4 chars
    if (password.length < 4) {
      this.setErrors(['Password must be at least 4 characters'])
      return false
    }

    // show loader / disabled submit
    this.props.setSubmitting(true)

    // need to login before creating project,
    // do not want to create a project for each failed login
    try {
      const res = await this.props.login(email, password)
      if (!res) throw 'Password is incorrect. Try again.'
    } catch (err) {
      this.props.setErrors([err])
      this.props.setSubmitting(false)
      return false
    }

    let response
    try {
      // create the user & project
      response = await axios.post(`${addProductionUrl()}/api/customers/create`, {
        email,
        quoteId: quote._id,
      })
    } catch (err) {
      console.log('error creating project')
      this.props.setErrors(['Error creating project.'])
      this.props.setSubmitting(false)
      return false
    }

    const { user, project } = response.data

    this.props.scheduleTime(this.props.selectedTime, project, user)

    // set message
    this.props.setSubmitting(false)
    this.props.setMessage('Successfully scheduled time.')
    // clear pw field
    this.setState(prevState => ({ fields: { password: '' } }))

    // redirect after stall
    setTimeout(() => {
      this.props.history.push(`/project/${project._id}/${project.slug}`)
    }, 1500)
  }

  render() {
    return (
      <StyledExistingUserConfirmation>
        <Title text="Login to schedule your survey." tag="h3" size="24px" />
        <form onSubmit={this.handleSubmit}>
          <Field>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              value={this.state.fields.email}
              onChange={() => false}
              disabled={true}
            />
          </Field>
          <Field>
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              value={this.state.fields.password}
              onChange={this.handleChange}
              placeholder="Password must be at least 5 characters long..."
            />
          </Field>

          <Button
            text="Schedule Survey"
            fluid={true}
            variant={`lg ${this.props.submitting && 'disabled'}`}
            disabled={!this.props.submitting ? false : true}
          />
        </form>
      </StyledExistingUserConfirmation>
    )
  }
}

const StyledExistingUserConfirmation = styled.div`
  button {
    margin-top: 1em;
  }
`

export default ExistingUserConfirmation
