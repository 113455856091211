import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EmployeeCreateProjectForm from './../components/pages/Projects/EmployeeCreateProjectForm'
import { createProject } from '../redux/modules/employeeCreateProjectForm'

const mapStateToProps = state => {
  return {
    loading: state.employeeCreateProjectForm.loading,
    error: state.employeeCreateProjectForm.error,
    message: state.employeeCreateProjectForm.message,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createProject: fields => dispatch(createProject(fields)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmployeeCreateProjectForm)
)
