import React, { Component } from 'react'
import styled from 'styled-components'
import { getTotals } from './../../../helpers/totals'
import { breakpoints } from './../../../helpers/foundation'

import OverviewContainer from './../../sharedComponents/OverviewContainer'
import StyledTable from './../../sharedComponents/StyledTable'
import ImageInput from './../../sharedComponents/ImageInput'
import Loader from './../../sharedComponents/Loader'
import BlueprintComments from './../../survey/SurveyBlueprintTwo/components/BlueprintComments'

class EstimateOverviewChunk extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uploading: false,
      blueprintImage: props.estimate.officalBlueprint,
    }
  }

  handleBlueprintFile = e => {
    const { project } = this.props
    const file = e.target.files[0]
    const formData = new FormData()
    if (!file) return false

    this.setState({ uploading: true })

    formData.append('projectId', project._id)
    formData.append('officalBlueprint', file, 'officalBlueprint.jpg')

    this.props.estimateUpdateServer(this.props.estimate._id, formData).then(estimate => {
      this.setState({ uploading: false, blueprintImage: estimate.officalBlueprint })
    })
  }

  render() {
    const { uploading, blueprintImage } = this.state
    const { project } = this.props
    const { parts, labors, services, details, officalBlueprint } = this.props.estimate
    const totals = getTotals(
      [Number(parts.total), Number(labors.total), Number(services.total)],
      0.07
    )

    return (
      <StyledEstimateOverview>
        <div id="printable-estimate">
          {project.customer && (
            <OverviewContainer
              title="Client Information"
              changeStepPointer={this.props.changeStepPointer}
              step={2}
              presentational={true}
            >
              <div className="inner">
                <table className="customer-info">
                  <thead />
                  <tbody>
                    <tr>
                      <td className="question">Name</td>
                      <td className="answer">{project.customer.displayName}</td>
                    </tr>
                    <tr>
                      <td className="question">Email</td>
                      <td className="answer">{project.customer.email}</td>
                    </tr>
                    <tr>
                      <td className="question">Phone</td>
                      <td className="answer">{project.customer.phone}</td>
                    </tr>
                    <tr>
                      <td className="question">Project Address</td>
                      <td className="answer">
                        {project.additionalClientInfo.address} <br />{' '}
                        {project.additionalClientInfo.city}, {project.additionalClientInfo.state}{' '}
                        {project.additionalClientInfo.zip}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </OverviewContainer>
          )}

          {project.survey.blueprint.sketch && (
            <OverviewContainer title="Blueprint Sketch" presentational={true}>
              <div className="blueprint">
                <div className="blueprint-sketch">
                  <img src={project.survey.blueprint.sketch} alt="Blueprint sketch" />
                </div>

                {project.survey.blueprint.comments.length > 1 && (
                  <div className="blueprint-comments">
                    <BlueprintComments
                      comments={project.survey.blueprint.comments}
                      active={false}
                    />
                  </div>
                )}
              </div>
            </OverviewContainer>
          )}

          <OverviewContainer
            title="Official Blueprint"
            changeStepPointer={this.props.changeStepPointer}
            step={1}
            presentational={true}
          >
            <div className="inner">
              <div className="offical-blueprint">
                {blueprintImage && <img src={blueprintImage} alt="Official blueprint" />}
              </div>
              {project.estimate.status === 'In Progress' && (
                <div className="no-offical-blueprint">
                  {!blueprintImage && <b>Please upload the final blueprint.</b>}
                  <ImageInput
                    name="officalBlueprint"
                    text="Upload official blueprint"
                    handleFile={this.handleBlueprintFile}
                    variant="lg"
                  />
                  {uploading && <Loader />}
                </div>
              )}
            </div>
          </OverviewContainer>

          {parts && (
            <OverviewContainer
              title="Material Costs"
              changeStepPointer={this.props.changeStepPointer}
              step={2}
              presentational={true}
            >
              <StyledTable>
                <thead>
                  <tr>
                    {parts.columns.map((column, idx) => {
                      return <th key={idx}>{column.text}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {parts.data.map((row, idx) => {
                    return (
                      <tr key={idx}>
                        {row.data.map((item, idx) => {
                          return <td key={idx}>{item}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </StyledTable>
              <div className="totals-container">
                <div className="totals">
                  <b>Total</b>
                  <div>${parts.total}</div>
                </div>
              </div>
            </OverviewContainer>
          )}

          {labors && (
            <OverviewContainer
              title="Labor Costs"
              changeStepPointer={this.props.changeStepPointer}
              step={3}
              presentational={true}
            >
              <StyledTable>
                <thead>
                  <tr>
                    {['Labor Type', 'Qty.', 'Rate', 'Estimated Hours', 'Labor Cost ($)'].map(
                      (column, idx) => {
                        return <th key={idx}>{column}</th>
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  {labors.labors.map((labor, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{labor.name}</td>
                        <td>{labor.quantity}</td>
                        <td>{labor.rate}</td>
                        <td>{labor.hours}</td>
                        <td>{labor.cost}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </StyledTable>
              <div className="totals-container">
                <div className="totals">
                  <b>Total</b>
                  <div>${labors.total}</div>
                </div>
              </div>
            </OverviewContainer>
          )}

          {services && (
            <OverviewContainer
              title="Service Costs"
              changeStepPointer={this.props.changeStepPointer}
              step={4}
              presentational={true}
            >
              <StyledTable>
                <thead>
                  <tr>
                    {['Service', 'Qty.', 'Rate', 'Additional Costs ($)'].map((column, idx) => (
                      <th key={idx}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {services.services.map((service, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{service.text}</td>
                        <td>{service.quantity}</td>
                        <td>{service.rate}</td>
                        <td>{Number(service.quantity * service.rate).toFixed(2)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </StyledTable>
              <div className="totals-container">
                <div className="totals">
                  <b>Total</b>
                  <div>${services.total}</div>
                </div>
              </div>
            </OverviewContainer>
          )}

          <OverviewContainer
            title="Total"
            changeStepPointer={this.props.changeStepPointer}
            presentational={true}
          >
            <StyledTable>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Cost ($)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Material</td>
                  <td>{parts.total}</td>
                </tr>
                <tr>
                  <td>Labor</td>
                  <td>{labors.total}</td>
                </tr>
                <tr>
                  <td>Additional Services</td>
                  <td>{services.total}</td>
                </tr>
              </tbody>
            </StyledTable>
            <div className="totals-container">
              <div className="totals">
                <b>Cash Total</b>
                <div>${totals.cashTotal}</div>
                <br />
                <b>Credit Total</b>
                <div>${totals.creditTotal}</div>
              </div>
            </div>
          </OverviewContainer>
          {/* <OverviewContainer
            title="Details"
            changeStepPointer={this.props.changeStepPointer}
            step={5}
            presentational={true}
          >
            <StyledTable>
              <thead />
              <tbody>
                <tr>
                  <td>Who is handling permits for the project? </td>
                  <td>
                    <b>{details.permits}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Customer acknowledges and has been made aware that contour installations will
                    have an allowance for space under fence.{' '}
                  </td>
                  <td>
                    <b>{details.contoursSpaceAcknowledge}</b>
                  </td>
                </tr>
                <tr>
                  <td>HOA approval required? </td>
                  <td>
                    <b>{details.hoaAprovalRequired}</b>
                  </td>
                </tr>
                <tr>
                  <td>If YES, will homeowner wait approval? </td>
                  <td>
                    <b>{details.waitForApproval}</b>
                  </td>
                </tr>
                <tr>
                  <td>Gates shown swinging in correct direction? </td>
                  <td>
                    <b>{details.gateSwingCorrect}</b>
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          </OverviewContainer> */}
        </div>
        {this.props.children}
      </StyledEstimateOverview>
    )
  }
}

export default EstimateOverviewChunk

const StyledEstimateOverview = styled.div`
  padding-bottom: 4em;

  .totals-container {
    background-color: #f7f8f9;
    padding: 2em 1.5em;
    text-align: right;
  }

  .inner {
    padding: 1em;
  }

  .blueprint {
    @media screen and (min-width: ${breakpoints.medium}px) {
      display: flex;
    }
  }

  .blueprint-sketch {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 1.5em 0;
  }

  .blueprint-comments {
    @media screen and (min-width: ${breakpoints.medium}px) {
      flex: 0 0 350px;
      max-width: 40%;
      margin-left: auto;
    }
  }

  table.customer-info {
    td {
      padding: 0 2em 1em 0;

      &.question {
        font-weight: 600;
        vertical-align: top;
      }
    }
  }

  .no-offical-blueprint {
    b,
    input {
      display: block;
    }

    b {
      margin-bottom: 1em;
    }
  }
`
