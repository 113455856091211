import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { em } from 'polished'
import Container from './../../sharedComponents/Container'
import Sector from './../../sharedComponents/Sector'
import Title from './../../sharedComponents/Title'
import Icon from './../../sharedComponents/Icon'
import MainContent from './../../sharedComponents/layouts/MainContent'
import SettingsForm from './SettingsForm'
import ChangePasswordFormContainer from './../../../containers/changePasswordFormContainer'
import { isUserSurveyor } from '../../../helpers/auth'
import { colors, breakpoint } from '../../../helpers/foundation'
import ConnectGoogleCalendar from './ConnectGoogleCalendar'

const StyledSettings = styled.div`
  color: ${colors.grey3};
  margin-top: 40px;

  .grid {
    ${breakpoint('medium')} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 20px;
    }
  }

  .header {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .intro {
    padding-right: 40px;
    display: flex;
    align-items: center;
  }

  .option {
    font-size: ${em('14px')};
    margin-top: 20px;

    ${breakpoint('medium')} {
      margin-top: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: ${colors.grey};

      &:hover,
      &:active,
      &:focus {
        color: ${colors.primary};

        path,
        circle {
          stroke: ${colors.primary};
        }
      }
    }

    path,
    circle {
      transition: stroke 0.15s ease-in-out;
    }

    .text {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  .portrait {
    border-radius: 50%;
  }

  /* React Select */
  .rs-field__control--is-focused {
    border-color: ${colors.primary};
    box-shadow: none;

    &:hover {
      border-color: ${colors.primary};
    }
  }

  .rs-field__control {
    background-color: ${colors.white};
  }

  .rs-field__multi-value {
    background-color: ${colors.lightGrey};
  }
`
export default class Settings extends Component {
  render() {
    const { user, updateUser, userUpdated, loading, error } = this.props

    return (
      <MainContent>
        <Container width={880}>
          <StyledSettings>
            <div className="header">
              <div className="intro">
                <Title step={1} text="My Settings" />
              </div>
              <div className="option">
                <Link to="/profile">
                  <Icon icon="logout" /> <span className="text">Return to Profile</span>
                </Link>
              </div>
            </div>
            <div className="grid">
              <div className="column">
                <Sector size="lg" color={colors.white} bordered={true} title="Personal Information">
                  <SettingsForm
                    user={user}
                    updateUser={updateUser}
                    loading={loading}
                    error={error}
                  />
                </Sector>
              </div>
              <div className="column">
                {isUserSurveyor(user.teams) && (
                  <Sector size="lg" color={colors.white} bordered={true} title="Connected Accounts">
                    <ConnectGoogleCalendar
                      user={user}
                      userUpdated={userUpdated}
                      location={this.props.location}
                      history={this.props.history}
                    />
                  </Sector>
                )}
                <Sector size="lg" color={colors.white} bordered={true} title="My Password">
                  <ChangePasswordFormContainer />
                </Sector>
              </div>
            </div>
          </StyledSettings>
        </Container>
      </MainContent>
    )
  }
}
