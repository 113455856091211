import React, { Component } from 'react'
import { getYear, isPast } from 'date-fns'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Scheduler from './../styles'

class MonthCalendar extends Component {
  state = {
    viewingYear: getYear(new Date()),
  }

  changeYear = amt => {
    this.setState(prevState => ({
      viewingYear: prevState.viewingYear + amt,
    }))
  }

  getMonthClasses = month => {
    const { viewingYear } = this.state
    const { currentMonth, currentYear } = this.props

    // if nothing has been selected
    if (currentMonth === month && currentYear === viewingYear) {
      return `selected`
    }

    return ``
  }

  // returns true if the month is in the past, and false if the month is in the future
  isPastMonth = monthNum => {
    const { viewingYear } = this.state
    // in date std library, month goes Jan=1 whereas date-fns uses Jan=0, need to add 1
    return isPast(new Date(viewingYear, monthNum + 1))
  }

  render() {
    const canGoPrevYear = this.state.viewingYear - 1 >= getYear(new Date())

    return (
      // calendar for modal will have different styling
      <Scheduler.Calendar forModal={this.props.forModal ? true : false}>
        <div className="meta">
          <div
            className={`prev ${canGoPrevYear ? '' : 'disabled'}`}
            onClick={e => {
              if (canGoPrevYear) {
                this.changeYear(-1)
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className="curr">{this.state.viewingYear}</div>
          <div className="next" onClick={e => this.changeYear(1)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className="months">
          {/* keep in mind that the months for date-fns uses 0 as Jan */}
          <div
            className={`month ${this.getMonthClasses(0)} ${this.isPastMonth(0) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(0) && this.props.currentMonth !== 0) {
                  this.props.changeMonth(0, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Jan
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(1)} ${this.isPastMonth(1) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(1) && this.props.currentMonth !== 1) {
                  this.props.changeMonth(1, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Feb
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(2)} ${this.isPastMonth(2) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(2) && this.props.currentMonth !== 2) {
                  this.props.changeMonth(2, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Mar
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(3)} ${this.isPastMonth(3) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(3) && this.props.currentMonth !== 3) {
                  this.props.changeMonth(3, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Apr
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(4)} ${this.isPastMonth(4) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(4) && this.props.currentMonth !== 4) {
                  this.props.changeMonth(4, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              May
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(5)} ${this.isPastMonth(5) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(5) && this.props.currentMonth !== 5) {
                  this.props.changeMonth(5, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Jun
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(6)} ${this.isPastMonth(6) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(6) && this.props.currentMonth !== 6) {
                  this.props.changeMonth(6, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Jul
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(7)} ${this.isPastMonth(7) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(7) && this.props.currentMonth !== 7) {
                  this.props.changeMonth(7, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Aug
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(8)} ${this.isPastMonth(8) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(8) && this.props.currentMonth !== 8) {
                  this.props.changeMonth(8, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Sep
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(9)} ${this.isPastMonth(9) ? 'disabled' : ''}`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(9) && this.props.currentMonth !== 9) {
                  this.props.changeMonth(9, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Oct
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(10)} ${
              this.isPastMonth(10) ? 'disabled' : ''
            }`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(10) && this.props.currentMonth !== 10) {
                  this.props.changeMonth(10, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Nov
            </div>
          </div>
          <div
            className={`month ${this.getMonthClasses(11)} ${
              this.isPastMonth(11) ? 'disabled' : ''
            }`}
          >
            <div
              className="inner"
              onClick={e => {
                if (!this.isPastMonth(11) && this.props.currentMonth !== 11) {
                  this.props.changeMonth(11, this.state.viewingYear)
                  // close modal
                  if (this.props.forModal) {
                    this.props.toggleCalendarModal(false)
                  }
                }
              }}
            >
              Dec
            </div>
          </div>
        </div>
      </Scheduler.Calendar>
    )
  }
}

export default MonthCalendar
