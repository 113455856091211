import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from './../../../helpers/foundation'
import StarRatings from 'react-star-ratings'
import loadImage from 'blueimp-load-image'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import ResizeableSector from './../../sharedComponents/ResizeableSector'
import Field from './../../sharedComponents/Field'
import Button from './../../sharedComponents/Button'
import SecondaryButton from './../../sharedComponents/SecondaryButton'
import Icon from './../../sharedComponents/Icon'
import Loader from './../../sharedComponents/Loader'

class Completed extends PureComponent {
  state = {
    referals: '',
    rating: 0,
    comments: '',
    upload: '',
    feedbackLoading: false,
  }

  changeRating = newRating => {
    this.setState(prevState => ({ rating: newRating }))
  }

  handleInput = e => {
    const { name, value } = e.target
    this.setState(prevState => ({ [name]: value }))
  }

  handleUpload = e => {
    const file = e.target.files ? e.target.files[0] : null
    this.setState(prevState => ({ upload: file }))
  }

  submitReferals = () => {
    const { referals } = this.state
    console.log('submitting referals', referals)
    const formattedReferals = referals.split(',').map(referal => referal.trim())
    // do we want to run a regex here to make sure each item is actually an email?
    console.log('hihi', formattedReferals)
  }

  submitFeedback = () => {
    const { project } = this.props
    const { rating, comments, upload } = this.state

    this.setState(prevState => ({ feedbackLoading: true }))

    // create form data to be posted to server
    const formData = new FormData()
    formData.append('rating', rating) // FormData will turn this into a string
    formData.append('comments', comments)
    if (upload) {
      formData.append('upload', upload, upload.name)
    }

    this.props
      .submitFeedback(project._id, formData)
      .then(success => {
        console.log('success!')
        this.setState(prevState => ({
          feedbackLoading: false,
          rating: 0,
          comments: '',
          upload: '',
        }))
      })
      .catch(err => {
        // update state to show an error here
        console.log('got err', err)
        this.setState(prevState => ({ feedbackLoading: false }))
      })
  }

  render() {
    console.log('hehehehe', this.props)
    return (
      <StyledCompleted>
        <Header title="All finished!" />
        <Container>
          <Notice text="This project has been fully completed. Congrats!" />

          <div className="content">
            <ResizeableSector noResize={true} noTitle={true} margin="0em 0 1.5em">
              <div className="split">
                <div className="left">
                  <div className="inner">
                    <div className="title">Refer OFS and you could earn $50 cash!</div>
                    <div className="text">
                      Happy with your Osceola Fence Supply experience? If you refer a successful
                      installation to us, we’ll give you $50!
                    </div>
                  </div>
                </div>
                <div className="right">
                  <Field>
                    <label htmlFor="">Recipient Emails</label>
                    <input
                      type="text"
                      name="referals"
                      placeholder="seperate emails with commas"
                      value={this.state.referals}
                      onChange={this.handleInput}
                    />
                  </Field>
                  <Button
                    variant="lg primary"
                    fluid={true}
                    text="Send Referral Emails"
                    handleClick={this.submitReferals}
                  />
                </div>
              </div>
            </ResizeableSector>

            <ResizeableSector noResize={true} noTitle={true} margin="0em 0 1.5em">
              <div className="split">
                <div className="left">
                  <div className="inner">
                    <div className="title">Your Product Warranty</div>
                    <div className="text">
                      Your OFS installation comes with a 5 year workmanship warranty. Download your
                      warranty information to see specifics.
                    </div>
                  </div>
                </div>
                <div className="right align-bottom">
                  <Button
                    variant="lg primary lowercase"
                    fluid={true}
                    icon={<Icon icon="download" color="white" />}
                    text="ofs-warranty.pdf"
                  />
                </div>
              </div>
            </ResizeableSector>

            <ResizeableSector noResize={true} noTitle={true} margin="0em 0 1.5em">
              <div className="split">
                <div className="left">
                  <div className="inner">
                    <div className="title special">
                      <div className="line">Tell us how</div>
                      <div className="line">we did!</div>
                    </div>
                    <div className="text">
                      We’re always eager to hear feedback on our services and processes. Let us know
                      we did!
                    </div>
                  </div>
                </div>
                <div className="right bigger">
                  <div className="small-caption">Your rating *</div>
                  <div className="rating-container">
                    <StarRatings
                      rating={this.state.rating}
                      changeRating={this.changeRating}
                      isSelectable={true}
                      starRatedColor={`${colors.primary}`}
                      starHoverColor={`${colors.primary}`}
                      changeRating={this.changeRating}
                      numberOfStars={5}
                      starDimension="30px"
                      starSpacing="12px"
                      name="rating"
                    />
                  </div>
                  <div className="small-caption">Your comments *</div>
                  <Field>
                    <textarea
                      name="comments"
                      id="comments"
                      value={this.state.comments}
                      onChange={this.handleInput}
                    />
                  </Field>
                  <div className="small-caption">Share a photo of your installation *</div>
                  <div className="upload-container">
                    <SecondaryButton tag="label">
                      <Icon icon="upload" color="grey3" />
                      <span>Upload a File</span>
                      <input type="file" name="upload" onChange={this.handleUpload} />
                    </SecondaryButton>
                    <span className="upload-name">{this.state.upload.name}</span>
                  </div>

                  <Button
                    variant="primary uppercase wide"
                    text="Submit"
                    handleClick={this.submitFeedback}
                  />

                  {this.state.feedbackLoading && <Loader />}

                  {this.props.project.feedback && (
                    <div className="success">Thank you for your feedback.</div>
                  )}
                </div>
              </div>
            </ResizeableSector>

            {/* <div className="feedback">
              <p>is there feedback?</p>
              <div>{this.props.project.feedback && this.props.project.feedback.rating}</div>
              <div>{this.props.project.feedback && this.props.project.feedback.comments}</div>
              <div>{this.props.project.feedback && this.props.project.feedback.upload}</div>
            </div> */}
          </div>
        </Container>
      </StyledCompleted>
    )
  }
}

const StyledCompleted = styled.div`
  .split {
    display: flex;

    @media screen and (max-width: ${breakpoints.large}px) {
      display: block;
    }

    .left {
      width: 38em;
      max-width: 100%;

      @media screen and (max-width: ${breakpoints.large}px) {
        margin-bottom: 2em;
      }

      @media screen and (max-width: ${breakpoints.medium}px) {
        width: 100%;
      }

      .inner {
        width: 28em;
        max-width: 100%;
      }
    }

    .right {
      width: 19em;
      max-width: 100%;

      @media screen and (max-width: ${breakpoints.medium}px) {
        width: 100%;
      }

      &.bigger {
        width: 24em;
      }

      &.align-bottom {
        align-self: flex-end;
      }

      textarea {
        max-width: 24em;
      }
    }

    .title {
      font-size: 1.5em;
      margin-bottom: 0.5em;

      @media screen and (max-width: ${breakpoints.large}px) {
        font-size: 1.25em;
      }

      &.special {
        font-family: 'acumin-pro-extra-condensed', 'Impact', 'Roboto', 'Helvetica', 'Arial',
          sans-serif;
        position: relative;
        top: -10px;
        font-size: 2.75em;
        color: ${colors.primary};
        text-transform: uppercase;

        @media screen and (max-width: ${breakpoints.large}px) {
          font-size: 2em;
          margin-bottom: 1em;
        }

        .line {
          margin-bottom: -20px;
        }
      }
    }
  }

  .text {
    @media screen and (max-width: ${breakpoints.large}px) {
      font-size: 0.875em;
    }
  }

  .small-caption {
    font-size: 0.75em;
    font-family: 'Source Code Pro';
    color: ${colors.grey2};
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .rating-container {
    margin: 0 0 1rem;
  }

  .upload-container {
    margin-bottom: 1.5em;
  }

  .upload-name {
    font-size: 0.875em;
    color: ${colors.grey3};
    margin-left: 1em;
  }

  .success {
    color: ${colors.primary};
  }
`

export default Completed
