import React from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import { toTitleCase, splitStringByCapital } from './../../../../../../utils'
import { colors } from './../../../../../../helpers/foundation'

import Icon from './../../../../../sharedComponents/Icon'

const TimerGroup = props => {
  const { name, timerGroup, timers, editTimer, saveTimer, updateTime } = props
  return (
    <StyledTimerGroup>
      <div className="title">{name} timers</div>
      <div className="grid-items notranslate">
        {Object.keys(timers).map(timerKey => {
          const timer = timers[timerKey]
          return (
            <div key={timerKey} className="grid-item">
              <div className="name">{toTitleCase(splitStringByCapital(timerKey))}</div>
              <div className="time">
                <div className="time-row">
                  {!timer.editing ? (
                    <div className="edit" onClick={e => editTimer(timerGroup, timerKey)}>
                      <Icon icon="pencil" />
                    </div>
                  ) : (
                    <div className="save" onClick={e => saveTimer(timerGroup, timerKey)}>
                      <Icon icon="solidCheck" color="primary" />
                    </div>
                  )}
                  <div className="hour">
                    <input
                      type="number"
                      value={`${timer.hour < 10 ? `0${timer.hour}` : timer.hour}`}
                      min="0"
                      // max="12"
                      name={timerKey}
                      disabled={!timer.editing}
                      onChange={e => updateTime(e, timerGroup, 'hour')}
                    />{' '}
                    <span>hr</span>
                  </div>
                  <div className="minute">
                    <input
                      type="number"
                      value={`${timer.minute < 10 ? `0${timer.minute}` : timer.minute}`}
                      min="0"
                      max="50"
                      step="10"
                      name={timerKey}
                      disabled={!timer.editing}
                      onChange={e => updateTime(e, timerGroup, 'minute')}
                    />{' '}
                    <span>min</span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </StyledTimerGroup>
  )
}

const StyledTimerGroup = styled.div`
  font-size: ${em('14px')};
  margin-bottom: 1.5em;

  .title {
    font-size: 1.15em;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .grid-items {
    display: flex;
    flex-wrap: wrap;
  }

  .grid-item {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 1em;
  }

  .name {
    font-weight: 600;
    margin-bottom: 0.75em;
  }

  .time-row {
    display: flex;

    > div {
      margin-right: 0.5em;

      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .hour,
  .minute {
    position: relative;
    display: flex;

    span {
      position: relative;
      top: 2px;
    }
  }

  input[type='number'] {
    max-width: 48px;
    height: 24px;
    text-align: center;
    margin-right: 0.5em;
    padding: 4px 2px !important;

    &:disabled {
      background-color: transparent;
      border: 1px solid ${colors.grey6};
    }
  }

  .edit,
  .save {
    position: relative;
    top: 2px;
    cursor: pointer;

    svg {
      cursor: pointer;
    }
  }
`

export default TimerGroup
