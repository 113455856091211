import React, { Component } from 'react'
import styled from 'styled-components'
import { navSize, colors } from './../../../helpers/foundation'
import isRole from './../../../helpers/roles'

import Loader from './../../sharedComponents/Loader'
import NotFound from './../../routeUtils/notFound'
import Container from './../../sharedComponents/Container'
import TeamView from './TeamView'
import ListView from './ListView'
import Button from './../../sharedComponents/Button'
import Icon from './../../sharedComponents/Icon'
import Message from './../../sharedComponents/Message'
import TeamsModal from './TeamsModal/'

class Teams extends Component {
  state = {
    members: [],
    modalOpen: false,
    modalMode: null,
    isEditing: false,
    editingUser: null,
  }

  componentDidMount = () => {
    this.props.fetchMembers()
  }

  closeModal = () => {
    this.setState(prevState => ({
      modalOpen: false,
      modalMode: null,
      isEditing: false,
      editingUser: null,
    }))
  }

  optionsClick = memberId => {
    this.setState(prevState => ({
      modalOpen: true,
      modalMode: 'edit',
      isEditing: true,
      editingUser: this.props.members.find(member => member._id === memberId),
    }))
  }

  render() {
    const { modalOpen, modalMode, editingUser, isEditing } = this.state
    const {
      loading,
      modalLoading,
      user,
      error,
      members,
      teamView,
      changeView,
      addMember,
      updateMember,
      removeMember,
    } = this.props

    if (user.role === 'Customer') {
      return <NotFound />
    }

    if (loading) {
      return (
        <StyledTeams>
          <Loader centered={true} />
        </StyledTeams>
      )
    }

    return (
      <StyledTeams>
        <TeamsModal
          modalLoading={modalLoading}
          closeModal={this.closeModal}
          modalMode={modalMode}
          modalOpen={modalOpen}
          editingUser={editingUser}
          isEditing={isEditing}
          addMember={addMember}
          updateMember={updateMember}
          removeMember={removeMember}
          user={user}
        />
        <Container width="1320">
          <OptionsBar>
            {isRole(user).admin ||
            isRole(user).surveyManager ||
            isRole(user).estimateManager ||
            isRole(user).customerServiceManager ? (
              <Button
                variant="round"
                icon={<Icon icon="addLg" color="white" />}
                handleClick={() =>
                  this.setState(prevState => ({ modalOpen: true, modalMode: 'new' }))
                }
              />
            ) : (
              <div />
            )}

            <div className="view-actions">
              <Button
                text="Team View"
                variant={`nm ${teamView ? 'primary' : 'bordered-grey'}`}
                handleClick={() => changeView('team')}
              />
              <Button
                text="List View"
                variant={`nm ${!teamView ? 'primary' : 'bordered-grey'}`}
                handleClick={() => changeView('list')}
              />
            </div>
          </OptionsBar>

          {error && <Message type="error" message={error} />}

          {teamView ? (
            <TeamView
              modalOpen={modalOpen}
              isEditing={isEditing}
              members={members}
              optionsClick={this.optionsClick}
              user={user}
            />
          ) : (
            <ListView
              modalOpen={modalOpen}
              isEditing={isEditing}
              members={members}
              optionsClick={this.optionsClick}
              user={user}
            />
          )}
        </Container>
      </StyledTeams>
    )
  }
}

const StyledTeams = styled.div`
  padding-top: ${navSize.large};
  padding-bottom: 4em;

  table {
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    tbody {
      tr {
        td {
          font-size: 1em;
          padding: 1em;
          color: ${colors.grey3};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    thead {
      tr {
        th {
          padding: 1em;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            margin-right: 0.25em;
          }
        }
      }
    }
  }
`

const OptionsBar = styled.div`
  margin: 1em 0;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;

  .view-actions {
    position: relative;
    top: 0.5em;
  }
`

export default Teams
