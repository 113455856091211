import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'

import Modal from './../../sharedComponents/Modal'
import Icon from './../../sharedComponents/Icon'
import Title from './../../sharedComponents/Title'
import Field from './../../sharedComponents/Field'
import Button from './../../sharedComponents/Button'
import Loader from './../../sharedComponents/Loader'

class DeleteProjectModal extends Component {
  state = {
    deleteInput: '',
    canDelete: false,
    deleting: false,
    error: null,
  }

  handleInput = e => {
    const value = e.target.value

    this.setState(prevState => ({
      deleteInput: value,
      canDelete: value === 'delete' ? true : false,
    }))
  }

  delete = async e => {
    const { modalInfo } = this.props
    // if 'delete' typed in, show loader, delete the project, show message, close modal
    try {
      e.preventDefault()
      const { canDelete } = this.state
      if (!canDelete) {
        return false
      }

      this.setState(prevState => ({ deleting: true }))

      if (modalInfo.isQuote) {
        await this.props.deleteQuote(this.props.modalInfo.itemId)
      }
      if (!modalInfo.isQuote) {
        await this.props.deleteProject(this.props.modalInfo.itemId)
      }

      this.setState(prevState => ({
        deleteInput: '',
        deleting: false,
        message: 'Successfully deleted project.',
      }))

      setTimeout(() => {
        this.props.closeModal()
      }, 1500)
    } catch (err) {
      console.log('what is the error?', err)
      this.setState(prevState => ({
        error: `There was an error deleting the ${modalInfo.isQuote ? 'quote' : 'project'}.`,
        deleting: false,
      }))
    }
  }

  render() {
    const { modalInfo } = this.props
    const { canDelete, deleting, message, error } = this.state

    console.log('props?', this.props)

    return (
      <StyledDeleteProjectModal>
        <Modal width={360} close={this.props.closeModal} centered={false} border={colors.orange}>
          <div className="close">
            <Icon icon="close" handleClick={this.props.closeModal} />
          </div>
          <Title text="Are you sure?" tag="h3" size="24px" color={colors.orange} />
          <p>
            Deleting a {modalInfo.isQuote ? 'quote' : 'project'} will completely delete the{' '}
            {modalInfo.isQuote ? 'quote' : 'project'} and all items associated with it. A Deleted{' '}
            {modalInfo.isQuote ? 'quote' : 'project'} cannot be restored. Type "delete" below to
            delete the {modalInfo.isQuote ? 'quote' : 'project'}.
          </p>

          <div className="delete-confirm">
            <form onSubmit={this.delete}>
              <Field>
                <label htmlFor="deleteConfirm">
                  Type 'delete' to delete the {modalInfo.isQuote ? 'quote' : 'project'}
                </label>
                <input
                  name="deleteConfirm"
                  type="text"
                  onChange={this.handleInput}
                  autoComplete="off"
                  disabled={deleting ? true : false}
                />
              </Field>
              <Button
                text={`Delete ${modalInfo.isQuote ? 'quote' : 'project'}`}
                fluid={true}
                variant={`nm ${canDelete ? 'warning' : 'disabled'}`}
              />
            </form>
          </div>

          {deleting && <Loader />}
          {message && <div className="success">{message}</div>}
          {error && <div className="error">{error}</div>}
        </Modal>
      </StyledDeleteProjectModal>
    )
  }
}

const StyledDeleteProjectModal = styled.div`
  h3 {
    margin-bottom: 1em;
    /* margin-top: 1em; */
  }

  p {
    font-size: 0.875em;
    color: ${colors.grey3};
    margin-right: 1em;
    margin-bottom: 2em;
  }

  .success {
    color: ${colors.primary};
    margin-top: 1em;
  }

  .error {
    color: ${colors.danger};
    margin-top: 1em;
  }
`

export default DeleteProjectModal
