import {
  getToken,
  setToken,
  removeToken,
  isTokenExpired,
  setAuthorizationToken,
} from '../../../helpers/auth'
import decode from 'jwt-decode'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'

export const REQUEST_ERROR = 'app/auth/REQUEST_ERROR'
export const REQUEST_LOADING = 'app/auth/REQUEST_LOADING'
export const REQUEST_SUCCESS = 'app/auth/REQUEST_SUCCESS'
export const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS'
export const CHECK_AUTH_STATUS = 'app/auth/CHECK_AUTH_STATUS'
export const USER_UPDATED = 'app/auth/USER_UPDATED'
export const LOGOUT = 'app/auth/LOGOUT'

const initialState = {
  loading: false,
  error: null,
  user: null,
  loggedIn: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        user: action.user,
      }
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        error: null,
        loading: false,
        user: action.user,
      }
    case USER_UPDATED:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

export function requestFailed(error) {
  return {
    type: REQUEST_ERROR,
    error,
  }
}

export function requestLoading() {
  return {
    type: REQUEST_LOADING,
    loading: true,
  }
}

export function requestSuccess(user) {
  return {
    type: REQUEST_SUCCESS,
    user,
  }
}

export function userUpdated(user) {
  return {
    type: USER_UPDATED,
    payload: user,
  }
}

export function updateUser(uid, data) {
  return dispatch => {
    dispatch(requestLoading())

    axios
      .patch(`${addProductionUrl()}/api/users/${uid}`, data, {
        headers: {
          enctype: 'multipart/form-data',
        },
      })
      .then(res => {
        console.log('data', res.data)
        dispatch(requestSuccess(res.data))
      })
      .catch(error => {
        dispatch(requestFailed(error.response.data.message))
      })
  }
}

export function login(email, password) {
  return async dispatch => {
    dispatch(requestLoading())

    try {
      const user = await axios.post(
        `${addProductionUrl()}/api/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
            Cache: 'no-cache',
          },
        }
      )
      setToken(user.data.token)
      setAuthorizationToken(user.data.token)
      dispatch(loginSuccess(user.data.user))
      return true
    } catch (err) {
      dispatch(requestFailed(err.response.data.message))
    }
  }
}

export function logoutUser() {
  return dispatch => {
    removeToken()
    setAuthorizationToken()
    dispatch(logout())
  }
}

export function checkAuthStatus() {
  return async dispatch => {
    try {
      const token = getToken()

      if (!token) {
        // console.log('No valid token.')
      } else {
        const user = decode(token)

        if (isTokenExpired(token)) {
          dispatch(logoutUser())
        } else {
          const res = await axios.get(`${addProductionUrl()}/api/users/${user._id}`)
          dispatch(loginSuccess(res.data))
        }
      }
    } catch (err) {
      dispatch(requestFailed('Your session has expired, please login again.'))
    }
  }
}
