import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Step from './../sharedComponents/step'
import Button from './../sharedComponents/Button-NEW'
import { Card } from './../sharedComponents/Card-NEW'
import { colors, navSize, breakpoints } from './../../helpers/foundation'
import AntiMobileAction from '../sharedComponents/AntiMobileAction'

const StyledSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  /* position: fixed;
  width: 100%; */
  background-color: ${colors.grey5};

  .sidebar-top {
  }

  .sidebar-header {
  }

  .sidebar-inner {
    padding: 0.5em 1.25em 1.5em;
    overflow-y: auto;
    /* 100 vertical height - nav height - sidebar header height - action btn/next btn container size  */
    height: calc(100vh - ${navSize.large} - 127px - 84px);

    @media screen and (max-width: ${breakpoints.medium}px) {
      height: 100%;
    }
  }

  .sidebar-action {
    flex-shrink: 0;
    padding: 1em;

    button:last-child {
      margin-bottom: 0;
    }
  }

  .fixed-action {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`

class Sidebar extends Component {
  sidebarHeader = () => {
    const { project } = this.props
    const { address, city, state, zip } = project.additionalClientInfo

    return (
      <div className="sidebar-header">
        <Card variant="blend" noHeader customPadding="1.5em 1.25em 1em">
          <h2 className="title">{project.name}</h2>
          <p className="info">
            {address} <br />
            {city}, {state} {zip}
          </p>
        </Card>
      </div>
    )
  }

  stepStatus = num => {
    const currentStep = this.props.survey.currentStep
    return currentStep <= num ? 'incomplete' : 'complete'
  }

  clickStep = (e, idx) => {
    e.preventDefault()
    const currentStep = this.props.survey.currentStep
    if (idx > currentStep) {
      return false
    } else {
      this.props.changeStepPointer(idx)
    }
  }

  render() {
    const { project, survey } = this.props
    const { isMultiStyle, currentStep } = survey

    if (this.props.survey.status === 'To Do') {
      return <StyledSidebar>{this.sidebarHeader()}</StyledSidebar>
    }

    return (
      <StyledSidebar>
        <div className="sidebar-top">{this.sidebarHeader()}</div>

        <div className="sidebar-inner">
          <Step
            status={this.stepStatus(1)}
            title="material"
            num={1}
            handleClick={e => this.clickStep(e, 1)}
            isActive={this.props.stepPointer === 1}
            isCurrent={this.props.survey.currentStep === 1}
          />
          <Step
            projectId={project._id}
            slug={project.name}
            status={this.stepStatus(2)}
            title="style"
            num={2}
            handleClick={e => this.clickStep(e, 2)}
            isActive={this.props.stepPointer === 2}
            isCurrent={this.props.survey.currentStep === 2}
          />
          <Step
            projectId={project._id}
            slug={project.name}
            status={this.stepStatus(3)}
            title="options"
            num={3}
            handleClick={e => this.clickStep(e, 3)}
            isActive={this.props.stepPointer === 3}
            isCurrent={this.props.survey.currentStep === 3}
          />
          <Step
            projectId={project._id}
            slug={project.name}
            status={this.stepStatus(4)}
            title="height"
            num={4}
            handleClick={e => this.clickStep(e, 4)}
            isActive={this.props.stepPointer === 4}
            isCurrent={this.props.survey.currentStep === 4}
          />
          <Step
            projectId={project._id}
            slug={project.name}
            status={this.stepStatus(5)}
            title="multistyle"
            num={5}
            handleClick={e => this.clickStep(e, 5)}
            isActive={this.props.stepPointer === 5}
            isCurrent={this.props.survey.currentStep === 5}
          />

          {/* Handle adding the 4 redundant views if multiStyle is YES */}
          {isMultiStyle && (
            <React.Fragment>
              <Step
                projectId={project._id}
                slug={project.name}
                status={this.stepStatus(6)}
                title="material (2)"
                num={6}
                handleClick={e => this.clickStep(e, 6)}
                isActive={this.props.stepPointer === 6}
                isCurrent={this.props.survey.currentStep === 6}
              />

              {currentStep > 6 && (
                <React.Fragment>
                  <Step
                    projectId={project._id}
                    slug={project.name}
                    status={this.stepStatus(7)}
                    title="style (2)"
                    num={7}
                    handleClick={e => this.clickStep(e, 7)}
                    isActive={this.props.stepPointer === 7}
                    isCurrent={this.props.survey.currentStep === 7}
                  />
                  <Step
                    projectId={project._id}
                    slug={project.name}
                    status={this.stepStatus(8)}
                    title="options (2)"
                    num={8}
                    handleClick={e => this.clickStep(e, 8)}
                    isActive={this.props.stepPointer === 8}
                    isCurrent={this.props.survey.currentStep === 8}
                  />
                  <Step
                    projectId={project._id}
                    slug={project.name}
                    status={this.stepStatus(9)}
                    title="height (2)"
                    num={9}
                    handleClick={e => this.clickStep(e, 9)}
                    isActive={this.props.stepPointer === 9}
                    isCurrent={this.props.survey.currentStep === 9}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {/* handle the steps that will always be shown, either after material2 + its children, or after multistyle */}
          {((currentStep > 5 && !isMultiStyle) || currentStep > 6) && (
            <React.Fragment>
              <Step
                projectId={project._id}
                slug={project.name}
                status={this.stepStatus(isMultiStyle ? 10 : 6)}
                title="contour"
                num={isMultiStyle ? 10 : 6}
                handleClick={e => this.clickStep(e, isMultiStyle ? 10 : 6)}
                isActive={this.props.stepPointer === (isMultiStyle ? 10 : 6)}
                isCurrent={this.props.survey.currentStep === (isMultiStyle ? 10 : 6)}
              />

              <Step
                projectId={project._id}
                slug={project.name}
                status={this.stepStatus(isMultiStyle ? 11 : 7)}
                title="details"
                num={isMultiStyle ? 11 : 7}
                handleClick={e => this.clickStep(e, isMultiStyle ? 11 : 7)}
                isActive={this.props.stepPointer === (isMultiStyle ? 11 : 7)}
                isCurrent={this.props.survey.currentStep === (isMultiStyle ? 11 : 7)}
              />

              <Step
                projectId={project._id}
                slug={project.name}
                status={this.stepStatus(isMultiStyle ? 12 : 8)}
                title="blueprint"
                num={isMultiStyle ? 12 : 8}
                handleClick={e => this.clickStep(e, isMultiStyle ? 12 : 8)}
                isActive={this.props.stepPointer === (isMultiStyle ? 12 : 8)}
                isCurrent={this.props.survey.currentStep === (isMultiStyle ? 12 : 8)}
              />

              <Step
                projectId={project._id}
                slug={project.name}
                status={this.stepStatus(isMultiStyle ? 13 : 9)}
                title="photos"
                num={isMultiStyle ? 13 : 9}
                handleClick={e => this.clickStep(e, isMultiStyle ? 13 : 9)}
                isActive={this.props.stepPointer === (isMultiStyle ? 13 : 9)}
                isCurrent={this.props.survey.currentStep === (isMultiStyle ? 13 : 9)}
              />

              <Step
                projectId={project._id}
                slug={project.name}
                status={this.stepStatus(isMultiStyle ? 14 : 10)}
                title="notes"
                num={isMultiStyle ? 14 : 10}
                handleClick={e => this.clickStep(e, isMultiStyle ? 14 : 10)}
                isActive={this.props.stepPointer === (isMultiStyle ? 14 : 10)}
                isCurrent={this.props.survey.currentStep === (isMultiStyle ? 14 : 10)}
              />
            </React.Fragment>
          )}
        </div>

        {/* only show the 'Next' btn on sidebar when its not tablet or less in size */}
        <AntiMobileAction>
          {this.props.isLastStep ? (
            <div className="sidebar-action fixed-action">
              <Button
                selected={true}
                text="Complete"
                variant={`lg dynamic ${!this.props.isOnline && 'disabled'}`}
                fluid={true}
                handleClick={() => {
                  if (this.props.isOnline) {
                    this.props.openSurveyModal()
                  }
                }}
              />
            </div>
          ) : (
            <div className="sidebar-action fixed-action">
              <Button
                selected={true}
                text="Next"
                variant={this.props.canStepNext() ? 'lg dynamic' : 'lg dynamic disabled'}
                fluid={true}
                handleClick={this.props.changeStepPointer}
              />
            </div>
          )}
        </AntiMobileAction>
      </StyledSidebar>
    )
  }
}

export default Sidebar
