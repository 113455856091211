import React, { Component } from 'react'
import styled from 'styled-components'

import Step from './../sharedComponents/step'
import Button from './../sharedComponents/Button'
import { colors } from './../../helpers/foundation'
import AntiMobileAction from './../sharedComponents/AntiMobileAction'

const StyledSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .sidebar-top {
    flex-grow: 1;
  }

  .sidebar-header {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .sidebar-inner {
    padding: 1em;
  }

  .sidebar-action {
    border-top: 1px solid ${colors.lightGrey};
    flex-shrink: 0;
    padding: 1em;

    button:last-child {
      margin-bottom: 0;
    }
  }
`

class Sidebar extends Component {
  goToStep = (e, stepIdx) => {
    if (stepIdx > this.props.currentStep) {
      return false
    } else {
      this.props.changeStepPointer(e, stepIdx)
    }
  }

  stepStatus = num => {
    const currentStep = this.props.currentStep
    return currentStep <= num ? 'incomplete' : 'complete'
  }

  render() {
    const { estimate } = this.props
    return (
      <StyledSidebar>
        <div className="sidebar-top">
          <div className="sidebar-inner">
            <Step
              status={this.stepStatus(1)}
              title="parts list"
              num={1}
              handleClick={this.goToStep}
              isActive={this.props.stepPointer === 1}
              isCurrent={this.props.estimate.currentStep === 1}
            />
            <Step
              status={this.stepStatus(2)}
              title="labor needs"
              num={2}
              handleClick={this.goToStep}
              isActive={this.props.stepPointer === 2}
              isCurrent={this.props.estimate.currentStep === 2}
            />
            <Step
              status={this.stepStatus(3)}
              title="included services"
              num={3}
              handleClick={this.goToStep}
              isActive={this.props.stepPointer === 3}
              isCurrent={this.props.estimate.currentStep === 3}
            />
            <Step
              status={this.stepStatus(4)}
              title="final review"
              num={4}
              handleClick={this.goToStep}
              isActive={this.props.stepPointer === 4}
              isCurrent={this.props.estimate.currentStep === 4}
            />
          </div>
        </div>

        <AntiMobileAction>
          {this.props.stepPointer !== 0 &&
            (this.props.stepPointer !== 4 && (
              <div className="sidebar-action">
                <Button
                  variant={
                    this.props.canStepNext(this.props.estimate.currentStep) ? 'lg' : 'lg disabled'
                  }
                  text="Next"
                  handleClick={
                    this.props.canStepNext(this.props.estimate.currentStep)
                      ? this.props.stepNext
                      : null
                  }
                  fluid={true}
                />
              </div>
            ))}
        </AntiMobileAction>
      </StyledSidebar>
    )
  }
}

export default Sidebar
