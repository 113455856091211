import styled from 'styled-components'

const Container = styled.section`
  position: relative;
  padding: 1em;
  width: 100%;
  max-width: ${props => (props.width ? `${props.width}px` : 'none')};
  margin: auto;
`

export default Container
