import React, { Component } from 'react'
import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import Message from './../../sharedComponents/Message'
import Button from './../../sharedComponents/Button'
import Loader from './../../sharedComponents/Loader'

class ProjectArchived extends Component {
  restoreProject = () => {
    this.props.updateProject(this.props.project._id, {
      archived: false,
    })
  }

  render() {
    const { project, updatingProject, updateProjectError } = this.props

    return (
      <React.Fragment>
        <Header title={`${project.name} is archived.`} />
        <Container>
          {updatingProject && <Loader />}

          <Notice text="The project is currently archived but can be restored." />

          <Button text="Restore Project" handleClick={this.restoreProject} />

          {updateProjectError && <Message text={updateProjectError} type="error" />}
        </Container>
      </React.Fragment>
    )
  }
}

export default ProjectArchived
