import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'
import { em } from 'polished'

import ToggleSwitch from './../../sharedComponents/ToggleSwitch'
import Dropdown from '../../sharedComponents/Dropdown'
import Checkbox from '../../sharedComponents/Checkbox'
import Button from '../../sharedComponents/Button-NEW'

const Filters = ({
  _compactView,
  _archiveHidden,
  _showCreatedBy,
  _dateRange,
  _rememberSettings,
  clearFilterSettings,
  saveFilterSettings,
}) => {
  const [compactView, setCompactView] = useState(false)
  const [archiveHidden, setArchiveHidden] = useState(false)
  const [showCreatedBy, setShowCreatedBy] = useState('any')
  const [dateRange, setDateRange] = useState('any')
  const [rememberSettings, setRememberSettings] = useState(true)

  useEffect(() => {
    // compare state versus default values
    if (compactView !== _compactView) setCompactView(_compactView)
    if (archiveHidden !== _archiveHidden) setArchiveHidden(_archiveHidden)
    if (showCreatedBy !== _showCreatedBy) setShowCreatedBy(_showCreatedBy)
    if (dateRange !== _dateRange) setDateRange(_dateRange)
    if (rememberSettings !== _rememberSettings) setRememberSettings(_rememberSettings)
  }, [_compactView, _archiveHidden, _showCreatedBy, _dateRange])

  function applyFilters() {
    saveFilterSettings({ compactView, archiveHidden, showCreatedBy, dateRange, rememberSettings })
  }

  function clearFilters() {
    setCompactView(false)
    setArchiveHidden(false)
    setShowCreatedBy('any')
    setDateRange('any')
    setRememberSettings(false)
    clearFilterSettings()
  }

  return (
    <StyledFilters>
      <div className="carrot" />
      <Section>
        <div className="head">Misc</div>
        <div className="content">
          <ToggleSwitch
            text="Compact view"
            checked={compactView}
            handleChange={e => setCompactView(!compactView)}
          />
          <ToggleSwitch
            text="Hide archived"
            checked={archiveHidden}
            handleChange={e => setArchiveHidden(!archiveHidden)}
          />
        </div>
      </Section>
      <Section>
        <div className="head">Created by</div>
        <div className="content">
          <Dropdown
            defaultFirst={true}
            placeholder=" "
            handleChange={e => setShowCreatedBy(e.value)}
            selected={showCreatedBy}
            options={[
              {
                label: 'Any',
                value: 'any',
              },
              {
                label: 'Customer',
                value: 'customer',
              },
              {
                label: 'Employee',
                value: 'employee',
              },
            ]}
          />
        </div>
      </Section>
      <Section>
        <div className="head">Created within</div>
        <div className="content">
          <Dropdown
            defaultFirst={true}
            placeholder="The last..."
            handleChange={e => setDateRange(e.value)}
            selected={dateRange}
            options={[
              {
                label: 'Any',
                value: 'any',
              },
              {
                label: 'Last 120 days',
                value: '120',
              },
              {
                label: 'Last 90 days',
                value: '90',
              },
              {
                label: 'Last 60 days',
                value: '60',
              },
              {
                label: 'Last 30 days',
                value: '30',
              },
            ]}
          />
        </div>
      </Section>
      <Bottom>
        <div className="row">
          <Checkbox
            name="remember-settings"
            value="remember-settings"
            checked={rememberSettings}
            text="Remember settings for this board"
            handleChange={e => setRememberSettings(!rememberSettings)}
          />
        </div>
        <div className="row split">
          <Button variant="nm primary" text="Apply" handleClick={applyFilters} />
          <div className="clear-filters" onClick={clearFilters}>
            Clear filters
          </div>
        </div>
      </Bottom>
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  position: absolute;
  right: -0.65em;
  top: 2.25em;
  width: 22em;
  height: 30em;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey6};
  border-radius: 4px;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  z-index: 3;
  padding: 1.5em;

  .carrot {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid ${colors.grey6};
    border-bottom-color: ${colors.white};
    border-right-color: ${colors.white};
    background-color: ${colors.white};
    vertical-align: middle;
    position: absolute;
    top: -5px;
    right: 17px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
  }
`

const Section = styled.div`
  margin-bottom: 1em;
  .head {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.75em;
  }
`

const Bottom = styled.div`
  position: absolute;
  bottom: 1.5em;
  left: 1.5em;
  right: 1.5em;
  border-top: 1px solid ${colors.grey6};
  padding-top: 1.5em;

  .row {
    display: flex;
    &:first-child {
      margin-bottom: 1em;
    }
  }

  .split {
    justify-content: space-between;
    align-items: flex-end;
  }

  .clear-filters {
    font-size: ${em('12px')};
    color: ${colors.grey};
    cursor: pointer;
    user-select: none;
  }
`

export default Filters
