import React, { Component } from 'react'
import styled from 'styled-components'
import { isArray } from 'lodash'
import { colors } from './../../../helpers/foundation'

import Modal from './../../sharedComponents/Modal'
import Icon from './../../sharedComponents/Icon'
import Loader from '../../sharedComponents/Loader'
import LoggedInConfirmation from './LoggedInConfirmation'
import ExistingUserConfirmation from './ExistingUserConfirmation'
import NonExistingUserConfirmation from './NonExistingUserConfirmation'

class ScheduleConfirmationModal extends Component {
  state = {
    submitting: false,
    message: '',
    errors: [],
  }

  setSubmitting = tf => {
    this.setState(prevState => ({ submitting: tf }))
  }

  setMessage = msg => {
    this.setState(prevState => ({ message: msg, errors: [] }))
  }

  setErrors = errors => {
    this.setState(prevState => ({
      // if errors passed in is arr spread it, otherwise just add it to the array
      // errors: isArray(errors) ? [...prevState.errors, ...errors] : [...prevState.errors, errors],
      errors,
    }))
  }

  render() {
    const { formOfConfirmation, quote, user } = this.props
    return (
      <StyledScheduleConfirmationModal>
        <Modal width={420} close={this.props.closeModal} centered={false}>
          <div className="close">
            <Icon icon="close" handleClick={this.props.closeModal} />
          </div>

          {formOfConfirmation === 'loggedIn' && (
            <LoggedInConfirmation
              quote={quote}
              selectedTime={this.props.selectedTime}
              scheduleTime={this.props.scheduleTime}
              login={this.props.login}
              setSubmitting={this.setSubmitting}
              setMessage={this.setMessage}
              setErrors={this.setErrors}
              history={this.props.history}
              user={user}
              {...this.state}
            />
          )}
          {formOfConfirmation === 'userExists' && (
            <ExistingUserConfirmation
              quote={quote}
              selectedTime={this.props.selectedTime}
              scheduleTime={this.props.scheduleTime}
              login={this.props.login}
              setSubmitting={this.setSubmitting}
              setMessage={this.setMessage}
              setErrors={this.setErrors}
              history={this.props.history}
              user={user}
              {...this.state}
            />
          )}
          {formOfConfirmation === 'userDoesNotExist' && (
            <NonExistingUserConfirmation
              quote={quote}
              selectedTime={this.props.selectedTime}
              scheduleTime={this.props.scheduleTime}
              login={this.props.login}
              setSubmitting={this.setSubmitting}
              setMessage={this.setMessage}
              setErrors={this.setErrors}
              history={this.props.history}
              user={user}
              {...this.state}
            />
          )}
          {/* effects */}
          {this.state.submitting && <Loader width="25" small={true} />}
          {this.state.message && <div className="message">{this.state.message}</div>}
          {this.state.errors && this.state.errors.length > 0 && (
            <div className="errors">
              {this.state.errors.map((error, idx) => {
                return (
                  <div key={idx} className="error">
                    {error}
                  </div>
                )
              })}
            </div>
          )}
        </Modal>
      </StyledScheduleConfirmationModal>
    )
  }
}

const StyledScheduleConfirmationModal = styled.div`
  h3 {
    margin-bottom: 1em;
  }

  p {
    line-height: 1.15;
    margin-bottom: 1em;
  }

  .message {
    color: ${colors.success};
    font-size: 0.875em;
  }

  .error {
    color: ${colors.danger};
    font-size: 0.875em;
  }
`

export default ScheduleConfirmationModal
