import React, { Component } from 'react'
import isAfter from 'date-fns/is_after'

import ProjectBoard from './../sharedComponents/layouts/ProjectBoard'
import Button from './../sharedComponents/Button'
import Panel from './../sharedComponents/Panel'
import ProjectList from './../pages/Projects/ProjectList'
import Column from './../pages/Projects/Column'
import Loader from './../sharedComponents/Loader'
import CreateProjectFormContainer from './../../containers/createProjectFormContainer'

class CustomerDashboard extends Component {
  state = {
    panelIsOpen: false,
  }

  openPanel = () => {
    this.setState({
      panelIsOpen: true,
    })
  }

  closePanel = () => {
    this.setState({
      panelIsOpen: false,
    })
  }

  filterProjects = projects => {
    // Filter expired and archived
    const today = new Date()

    return projects.filter(project => {
      if (!project.archived) {
        if (project.estimate.expirationDate) {
          return isAfter(project.estimate.expirationDate, today)
        } else {
          return true
        }
      }
    })
  }

  filterProjectsByStage = stage => {
    return this.props.projects.filter(project => {
      return project.stage === stage
    })
  }

  render() {
    const { user, customer } = this.props

    if (this.props.loading) {
      return <Loader />
    } else {
      return (
        <React.Fragment>
          <Panel
            size="480"
            open={this.state.panelIsOpen}
            attached={true}
            padded={true}
            position="left"
          >
            <CreateProjectFormContainer customer={customer} closePanel={this.closePanel} />
            <Button handleClick={this.closePanel} text="Cancel" variant="danger" />
          </Panel>
          <ProjectBoard>
            <Column transparent={true}>
              <Button text="New Project" fluid={true} variant="lg" handleClick={this.openPanel} />
            </Column>

            <Column stage="survey" hasBorder={true}>
              <ProjectList
                title="Survey"
                items={this.filterProjects(this.filterProjectsByStage('survey'))}
                user={user}
                stage="survey"
              />
            </Column>
            <Column stage="estimate" hasBorder={true}>
              <ProjectList
                title="Estimation"
                items={this.filterProjects(this.filterProjectsByStage('estimate'))}
                user={user}
                stage="estimate"
              />
            </Column>
            <Column stage="paperwork" hasBorder={true}>
              <ProjectList
                title="Paperwork"
                items={this.filterProjects(this.filterProjectsByStage('paperwork'))}
                user={user}
                stage="paperwork"
              />
            </Column>
            <Column stage="installation" hasBorder={true}>
              <ProjectList
                title="Installation"
                items={this.filterProjects(this.filterProjectsByStage('installation'))}
                user={user}
                stage="installation"
              />
            </Column>
          </ProjectBoard>
        </React.Fragment>
      )
    }
  }
}

export default CustomerDashboard
