import React, { Component } from 'react'

import SidebarLayout from './../sharedComponents/layouts/SidebarLayout'
import Loader from './../sharedComponents/Loader'
import CustomerSurveyConfirmed from './survey/CustomerSurveyConfirmed'
import CustomerSurveyInProgress from './survey/CustomerSurveyInProgress'
import CustomerSurveyReview from './survey/CustomerSurveyReview'
import CustomerEstimateBeingCreated from './estimate/CustomerEstimateBeingCreated'
import CustomerEstimateReview from './estimate/CustomerEstimateReview'
import CustomerEstimateApprove from './estimate/CustomerEstimateApprove'
import SchedulerContainer from '../scheduler/Scheduler'
import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import CustomerEstimatePayment from './estimate/CustomerEstimatePayment'
import CustomerEstimatePaymentReceived from './estimate/CustomerEstimatePaymentReceived'
import GatherFiles from './install/GatherFiles'
import PrimarySidebar from './components/PrimarySidebar'
import PaperworkSidebar from './components/PaperworkSidebar'
import InstallationSidebar from './components/InstallationSidebar'
import ScheduleInstall from './install/ScheduleInstall'
import InstallationPrep from './install/InstallationPrep'
import InstallationInProgress from './install/InstallationInProgress'
import PermitInspection from './install/PermitInspection'
import FinalPayment from './install/FinalPayment'
import Completed from './install/Completed'
import FirstPayment from './install/FirstPayment'

class Customer extends Component {
  _isMounted = false

  state = {
    project: null,
    stepPointer: 0,
    currentStep: 0,
  }

  componentDidMount() {
    this._isMounted = true
    const { projectId, customer, items } = this.props
    let projects

    if (customer) {
      projects = customer.projects
    } else {
      projects = items
    }

    this.props.requestProject(projectId).then(success => {
      const projectIdx = projects.findIndex(proj => proj._id === projectId)
      const project = this.props.currentProject
      const stepsObj = this.getCurrentStep(project)

      if (this._isMounted) {
        this.setState({
          project,
          stepPointer: stepsObj.currentStep,
          currentStep: stepsObj.currentStep,
        })
      }
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    const { projectId, customer, items } = this.props

    let projects

    if (customer) {
      projects = customer.projects
    } else {
      projects = items
    }

    // Non customer view required this refresh
    const prevProjects =
      prevProps.customer && prevProps.customer.projects
        ? prevProps.customer.projects
        : prevProps.items

    const currProjects = projects

    if (prevProjects !== currProjects) {
      const projectIdx = projects.findIndex(proj => proj._id === projectId)
      const project = projects[projectIdx]
      const stepsObj = this.getCurrentStep(project)

      this.setState({
        project,
        stepPointer: stepsObj.currentStep,
        currentStep: stepsObj.currentStep,
      })
    }
  }

  updateCurrentStep = stepPointer => {
    if (stepPointer > this.state.currentStep) {
      return false
    } else {
      this.setState({
        stepPointer,
      })
    }
  }

  getCurrentStep = project => {
    if (!project) {
      return {
        currentStep: 0,
      }
    }

    const stage = project.stage
    let surveyStatus
    let estimateStatus
    let paperworkStatus
    let installationStatus

    if (project.survey) {
      surveyStatus = project.survey.status
    }

    if (project.estimate) {
      estimateStatus = project.estimate.status
    }

    if (project.paperwork) {
      paperworkStatus = project.paperwork.status
    }

    if (project.installation) {
      installationStatus = project.installation.installationStatus
    }

    let obj = {}

    // console.log('from customer index')
    // console.log('project', project)
    // console.log('stage?', stage)
    // console.log('survey status', surveyStatus)
    // console.log('estimate status', estimateStatus)
    // console.log('paperwork status', paperworkStatus)
    // console.log('installation status', installationStatus)

    switch (stage) {
      case 'survey':
        switch (surveyStatus) {
          case 'To Schedule':
            obj['currentStep'] = 0
            return obj
          case 'To Do':
            obj['currentStep'] = 1
            return obj
          case 'In Progress':
            obj['currentStep'] = 2
            return obj
          case 'To Review':
            obj['currentStep'] = 2
            return obj
          case 'To Revise':
            obj['currentStep'] = 2
            return obj
          case 'Completed':
            obj['currentStep'] = 3
            return obj
          default:
            obj['currentStep'] = 0
            return obj
        }
      case 'estimate':
        switch (estimateStatus) {
          case 'To Do':
            obj['currentStep'] = 3
            return obj
          case 'In Progress':
            obj['currentStep'] = 3
            return obj
          case 'To Review':
            obj['currentStep'] = 3
            return obj
          case 'To Revise':
            obj['currentStep'] = 3
            return obj
          case 'Pending Approval':
            obj['currentStep'] = 4
            return obj
          case 'Pending Payment':
            obj['currentStep'] = 5
            return obj
          case 'Completed':
            obj['currentStep'] = 6
            return obj
          default:
            obj['currentStep'] = 4
            return obj
        }
      case 'paperwork':
        switch (paperworkStatus) {
          case 'Gather Files':
            obj['currentStep'] = 7
            return obj
          case 'File Review':
            obj['currentStep'] = 8
            return obj
          case 'File Prep':
            obj['currentStep'] = 9
            return obj
          case 'Pending Approval':
            obj['currentStep'] = 10
            return obj
          default:
            obj['currentStep'] = 7
            return obj
        }
      case 'installation':
        switch (installationStatus) {
          case 'Schedule Installation':
            obj['currentStep'] = 11
            return obj
          case 'Installation First Payment':
            obj['currentStep'] = 12
            return obj
          case 'Installation Prep':
            obj['currentStep'] = 13
            return obj
          case 'Installation Pending':
            obj['currentStep'] = 14
            return obj
          case 'In Progress':
            obj['currentStep'] = 15
            return obj
          case 'ASDF': // TODO: change me bro
            obj['currentStep'] = 16
            return obj
          case 'Inspection':
            obj['currentStep'] = 16
            return obj
          case 'Final Payment':
            obj['currentStep'] = 17
            return obj
          case 'Completed':
            obj['currentStep'] = 18
            return obj
          default:
            return ''
        }
      default:
        return ''
    }
  }

  renderComponentBasedOnStatus = () => {
    const { stepPointer, project } = this.state
    const surveyStatus = project.survey.status
    const estimateStatus = project.estimate.status
    const { paperwork } = this.props

    // Go to top of page
    window.scrollTo(0, 0)

    // Handle Archived Project
    if (project.archived) {
      return <Header title="This Project is no longer active." />
    }

    // TODO: Handle routing to a non-existant project

    switch (stepPointer) {
      case 0:
        if (surveyStatus === 'To Schedule') {
          return (
            <React.Fragment>
              <Header title="Select the time that would work best for your free on-site survey." />
              <Container>
                <SchedulerContainer project={project} {...this.props} />
              </Container>
            </React.Fragment>
          )
        }
      case 1:
        return <CustomerSurveyConfirmed {...this.props} project={project} />
      case 2:
        if (surveyStatus === 'In Progress') {
          return <CustomerSurveyInProgress {...this.props} project={project} />
        } else {
          return <CustomerSurveyReview {...this.props} project={project} />
        }
      case 3:
        if (estimateStatus === 'To Do' || estimateStatus === 'In Progress') {
          return <CustomerEstimateBeingCreated {...this.props} project={project} />
        } else {
          return <CustomerEstimateReview {...this.props} project={project} />
        }
      case 4:
        return <CustomerEstimateApprove {...this.props} project={project} />
      case 5:
        return <CustomerEstimatePayment {...this.props} project={project} />
      case 6:
        return <CustomerEstimatePaymentReceived {...this.props} project={project} />
      case 7:
        return (
          <GatherFiles
            {...this.props}
            project={project}
            customerFetchPaperwork={this.props.customerFetchPaperwork}
            customerUpdateFile={this.props.customerUpdateFile}
          />
        )
      case 8:
        return (
          <GatherFiles
            {...this.props}
            project={project}
            customerFetchPaperwork={this.props.customerFetchPaperwork}
            customerUpdateFile={this.props.customerUpdateFile}
          />
        )
      case 9:
        return (
          <GatherFiles
            {...this.props}
            project={project}
            customerFetchPaperwork={this.props.customerFetchPaperwork}
            customerUpdateFile={this.props.customerUpdateFile}
          />
        )
      case 10:
        return (
          <GatherFiles
            {...this.props}
            project={project}
            customerFetchPaperwork={this.props.customerFetchPaperwork}
            customerUpdateFile={this.props.customerUpdateFile}
          />
        )
      case 11:
        return <ScheduleInstall {...this.props} project={project} />
      case 12:
        return <FirstPayment {...this.props} project={project} />
      case 13:
        return <InstallationPrep {...this.props} project={project} />
      case 14:
        return <InstallationInProgress {...this.props} project={project} />
      case 15:
        return <h1>hello world</h1>
      case 16:
        return <PermitInspection {...this.props} project={project} />
      case 17:
        return <FinalPayment {...this.props} project={project} />
      case 18:
        return <Completed {...this.props} project={project} />
      default:
        return ''
    }
  }

  stepStatus = (num, sidebarStateObj) => {
    if (sidebarStateObj.currentStep <= num) {
      return 'incomplete'
    } else {
      return 'complete'
    }
  }

  render() {
    const { project, stepPointer, currentStep } = this.state

    if (!project) {
      console.log('yes i am not getting project from customer index', project)
      return <Loader centered={true} />
    } else {
      const sidebarStateObj = this.getCurrentStep(project)

      return (
        <SidebarLayout statusComponent={this.renderComponentBasedOnStatus()}>
          {project.stage !== 'paperwork' && project.stage !== 'installation' && (
            <PrimarySidebar
              stepStatus={this.stepStatus}
              stepPointer={stepPointer}
              sidebarStateObj={sidebarStateObj}
              currentStep={currentStep}
              updateCurrentStep={this.updateCurrentStep}
            />
          )}

          {project.stage === 'paperwork' && (
            <PaperworkSidebar
              stepStatus={this.stepStatus}
              stepPointer={stepPointer}
              sidebarStateObj={sidebarStateObj}
              currentStep={currentStep}
              updateCurrentStep={this.updateCurrentStep}
            />
          )}

          {project.stage === 'installation' && (
            <InstallationSidebar
              stepStatus={this.stepStatus}
              stepPointer={stepPointer}
              sidebarStateObj={sidebarStateObj}
              currentStep={currentStep}
              updateCurrentStep={this.updateCurrentStep}
            />
          )}
        </SidebarLayout>
      )
    }
  }
}

export default Customer
