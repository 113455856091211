export const dataURLToBlob = function(dataURL) {
  let BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    let parts = dataURL.split(',')
    let contentType = parts[0].split(':')[1]
    let raw = parts[1]

    return new Blob([raw], { type: contentType })
  }

  let parts = dataURL.split(BASE64_MARKER)
  let contentType = parts[0].split(':')[1]
  let raw = window.atob(parts[1])
  let rawLength = raw.length

  let uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

export const resizeImage = (file, size = 200, cb) => {
  const { name, type } = file

  if (type.match(/image.*/)) {
    // Load the image
    const reader = new FileReader()

    reader.onload = readerEvent => {
      const image = new Image()

      image.onload = imageEvent => {
        // Resize the image
        let canvas = document.createElement('canvas')
        let max_size = size
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width
            width = max_size
          }
        } else {
          if (height > max_size) {
            width *= max_size / height
            height = max_size
          }
        }
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)

        let dataUrl = canvas.toDataURL('image/jpeg')

        let resizedImage = {
          blob: dataURLToBlob(dataUrl),
          dataUrl,
          name,
        }

        cb(resizedImage)
      }
      image.src = readerEvent.target.result
    }

    reader.readAsDataURL(file)
  } else {
    return false
  }
}

export function blobToDataURL(blob, callback) {
  var a = new FileReader()
  a.onload = function(e) {
    callback(e.target.result)
  }
  a.readAsDataURL(blob)
}
