import React, { Component } from 'react'
import styled from 'styled-components'
import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import SurveyOptionsSelect from './SurveyOptionsSelect'

import Modal from './../sharedComponents/Modal'
import Button from './../sharedComponents/Button-NEW'
import Notice from './../sharedComponents/Notice'

class SurveyMaterial extends Component {
  state = {
    promptOpen: false,
    warningText: '',
    propsMaterialResetParam: null,
    newMaterial: null,
  }
  handleClick = itemId => {
    const { isMsStep, survey, stepPointer } = this.props

    // dont reset if they click the current material they already have selected
    const materialId = survey[isMsStep ? 'ms_material' : 'material']
    if (itemId === materialId) {
      return false
    }

    // reset material 2
    if (survey.isMultiStyle && survey.currentStep > 6 && stepPointer !== 1 && survey.ms_material) {
      this.setState(prevState => {
        return {
          promptOpen: true,
          warningText:
            'Changing the material will reset the multi-style Material, Style, Options, and Height but leave the first Material, Style, Options, and Height.',
          propsMaterialResetParam: 'm2',
          newMaterial: itemId,
        }
      })
      // reset material 1 & 2
    } else if (
      survey.isMultiStyle &&
      survey.currentStep > 6 &&
      stepPointer === 1 &&
      survey.material &&
      survey.ms_material
    ) {
      this.setState(prevState => {
        return {
          promptOpen: true,
          warningText: 'Changing the material will reset the entire survey.',
          propsMaterialResetParam: 'both',
          newMaterial: itemId,
        }
      })
      // reset material 1
    } else if (!survey.isMultiStyle && survey.currentStep > 1) {
      this.setState(prevState => {
        return {
          promptOpen: true,
          warningText:
            'Changing the material will reset the Material, Styles, Options, and Height for this Material.',
          propsMaterialResetParam: 'm1',
          newMaterial: itemId,
        }
      })
    } else {
      // handle selecting a material
      const updateObj = {}
      if (this.props.isMsStep) {
        updateObj['ms_material'] = itemId
      } else {
        updateObj['material'] = itemId
      }
      this.props
        .updateSurveyServer(this.props.survey._id, updateObj)
        .then(success => console.log('updated survey material'))
    }
  }

  cancelChangingMaterial = () => {
    console.log('Canceling changing the material')
    this.setState(prevState => {
      return {
        promptOpen: false,
        warningText: '',
        propsMaterialResetParam: null,
      }
    })
  }

  continueChangingMaterial = () => {
    const materialResetParam = this.state.propsMaterialResetParam
    const newMaterial = this.state.newMaterial
    this.setState(
      prevState => {
        return {
          promptOpen: false,
          warningText: '',
          propsMaterialResetParam: null,
          newMaterial: null,
        }
      },
      () => {
        // from redux
        this.props.resetMaterialAndChildren(this.props.survey._id, materialResetParam, newMaterial)
      }
    )
  }

  // expect 'material' or 'ms_material'

  render() {
    const { promptOpen } = this.state
    const { isMsStep, survey } = this.props

    return (
      <StyledSurveyMaterial>
        {promptOpen && (
          <Modal close={this.cancelChangingMaterial} width={456} centered={true}>
            <div>
              <h3 className="modal-title">{this.state.warningText}</h3>
              <div className="options btn-row">
                <Button
                  variant="bordered-danger lg"
                  text="Cancel"
                  handleClick={this.cancelChangingMaterial}
                />
                <Button
                  variant="primary lg"
                  text="Continue"
                  handleClick={this.continueChangingMaterial}
                />
              </div>
            </div>
          </Modal>
        )}
        <Header title="Choose a material for you fence." noBorderBottom />
        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <SurveyOptionsSelect
            items={this.props._materials}
            handleClick={this.handleClick}
            survey={this.props.survey}
            columns={2}
            field={isMsStep ? 'ms_material' : 'material'}
          />
        </Container>
      </StyledSurveyMaterial>
    )
  }
}

export default SurveyMaterial

const StyledSurveyMaterial = styled.div`
  /* modal styles */
  .modal-title {
    text-align: left;
    font-size: 20px;
    margin-bottom: 40px;
  }

  .btn-row {
    display: flex;
    > button {
      width: 50%;
      &:first-child {
        margin-right: 0.5em;
      }
    }
  }
`
