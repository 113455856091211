import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import Button from './../sharedComponents/Button'
import Container from './../sharedComponents/Container'
import MainContent from './../sharedComponents/layouts/MainContent'
import image from './../../assets/404.jpg'

const Styled404 = styled.div`
  text-align: center;
  margin: 40px 0;

  img {
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 40px;
  }
`

class NotFound extends Component {
  render() {
    return (
      <MainContent>
        <Container>
          <Styled404>
            <img src={image} alt="404 not found" />
            <h1 className="title">There's nothing here!</h1>
            <Button text="Take me home" handleClick={() => this.props.history.push('/')} />
          </Styled404>
        </Container>
      </MainContent>
    )
  }
}

export default withRouter(NotFound)
