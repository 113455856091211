import React from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'

const ToggleSwitch = props => {
  return (
    <StyledToggleSwitch>
      <label className="switch">
        <input type="checkbox" checked={props.checked} onChange={props.handleChange} />
        <span className="slider" />
      </label>
      <div className="text" onClick={props.handleChange}>
        {props.text}
      </div>
    </StyledToggleSwitch>
  )
}

const StyledToggleSwitch = styled.div`
  display: flex;
  margin-bottom: 0.3em;
  margin-bottom: 4px;

  label {
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
  }

  .switch {
    position: relative;
    display: flex;
    width: 2em;
    height: 1em;
    border: 1px solid ${colors.grey6};
    border-radius: 2px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    &:before {
      position: absolute;
      content: '';
      height: 10.5px;
      width: 10.5px;
      left: 1px;
      bottom: 0.75px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      background-color: ${colors.grey6};
      border-radius: 2px;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${colors.primary};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(12.75px);
    -ms-transform: translateX(12.75px);
    transform: translateX(12.75px);
    background-color: ${colors.primary};
  }

  .text {
    position: relative;
    margin-left: 0.5em;
    top: -0.3em;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
`

export default ToggleSwitch
