import { connect } from 'react-redux'
import ChangePasswordForm from '../components/pages/Settings/ChangePasswordForm'
import { changePassword } from '../redux/modules/changePasswordForm'

const mapStateToProps = state => {
  return {
    inProgress: state.changePasswordForm.inProgress,
    error: state.changePasswordForm.error,
    message: state.changePasswordForm.message,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (currentPassword, newPassword) =>
      dispatch(changePassword(currentPassword, newPassword)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordForm)
