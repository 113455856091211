import React, { Component } from 'react'
import styled from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import { colors } from './../../../../helpers/foundation'

import Modal from './../../../sharedComponents/Modal'
import New from './New'
import Edit from './Edit'
import Loader from '../../../sharedComponents/Loader'

const initialState = {
  step: 0,
  _id: null,
  email: '',
  displayName: '',
  title: '',
  profileImage: '',
  startTime: '',
  endTime: '',
  address: '',
  city: '',
  state: 'FL',
  zip: '',
  teams: [{ name: '', role: '' }],
  errors: {},
  removingUser: false,
  removeConfirmText: '',
}

class TeamsModal extends Component {
  state = initialState

  // set the editingUsers' info to state when it is passed in from index
  componentDidUpdate = prevProps => {
    const { modalOpen, isEditing, editingUser } = this.props

    if (modalOpen && !prevProps.isEditing && isEditing) {
      this.setState(prevState => ({
        _id: editingUser._id,
        email: editingUser.email,
        displayName: editingUser.displayName,
        startTime: editingUser.startTime,
        endTime: editingUser.endTime,
        address: editingUser.address,
        city: editingUser.city,
        state: 'FL',
        zip: editingUser.zip,
        title: editingUser.title,
        profileImage: editingUser.profileImage,
        teams: editingUser.teams,
      }))
    }
  }

  updateTimes = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => ({
      [name]: value,
    }))
  }

  handleSelect = (e, idx) => {
    const { name, value } = e.target
    this.setState(prevState => ({
      teams: [
        ...prevState.teams.slice(0, idx),
        (prevState.teams[idx] = { ...prevState.teams[idx], [name]: value }),
        ...prevState.teams.slice(idx + 1),
      ],
    }))
  }

  addTeam = () => {
    this.setState(prevState => ({ teams: [...prevState.teams, { name: '', role: '' }] }))
  }

  removeTeam = teamIdx => {
    this.setState(prevState => ({
      teams: [...prevState.teams.slice(0, teamIdx), ...prevState.teams.slice(teamIdx + 1)],
    }))
  }

  validateField = (e, field) => {
    // TODO: for some reason, adding the timeout fixes the issue of the onClick not firing right away when having cursor inside an input
    setTimeout(() => {
      const { email, displayName, title } = this.state

      const errors = {}
      if (field === 'email') {
        errors.email = !isEmail(email) || isEmpty(email) ? true : false
      }
      if (field === 'displayName') {
        errors.displayName = displayName.length < 3 ? true : false
      }
      if (field === 'title') {
        errors.title = isEmpty(title) ? true : false
      }
      this.setState(prevState => ({ errors: { ...prevState.errors, ...errors } }))
    }, 100)
  }

  // returns true if all fields valid, for stepping next on step=0
  validFields = () => {
    const { email, displayName, title, teams } = this.state
    let result = false

    if (
      isEmail(email) &&
      !isEmpty(email) &&
      (!isEmpty(displayName) && displayName.length >= 3) &&
      (!isEmpty(title) && title.length > 1) &&
      teams.every(team => team.name !== '' && team.role !== '')
    ) {
      result = true
    }

    return result
  }

  // go to a step if passed in, otherwise step + 1
  goToStep = desiredStep => {
    this.setState(prevState => ({
      step: desiredStep !== undefined ? desiredStep : prevState.step + 1,
    }))
  }

  removeUser = () => {
    // const { editingUser } = this.props
    this.setState(prevState => ({ step: prevState.step + 1, removingUser: true }))
  }

  // close modal and reset state, eventually api POST request will happen here
  finishAction = (type, team) => {
    const { step, errors, removingUser, removeConfirmText, ...userData } = this.state
    let promise

    if (type === 'new') {
      // temp manipulate the teams
      promise = this.props.addMember(
        {
          ...userData,
        },
        team
      )
    }
    if (type === 'edit') {
      promise = this.props.updateMember(
        userData._id,
        {
          ...userData,
        },
        team
      )
    }
    if (type === 'remove') {
      promise = this.props.removeMember(userData._id, team)
    }

    promise.then(() => {
      this.setState(initialState)
      this.props.closeModal()
    })
  }

  closeAndClearData = () => {
    this.props.closeModal()
    // for some reason, props on the teams array will persist when just using initialState
    this.setState({ ...initialState, teams: [{ name: '', role: '' }] })
  }

  render() {
    const {
      step,
      _id,
      email,
      displayName,
      profileImage,
      startTime,
      endTime,
      address,
      city,
      state,
      zip,
      title,
      teams,
      errors,
      removingUser,
      removeConfirmText,
    } = this.state
    const {
      modalLoading,
      modalOpen,
      modalMode,
      // addMember,
      // updateMember,
      removeMember,
      user,
    } = this.props

    if (modalOpen && modalMode === 'new') {
      return (
        <StyledTeamsModal>
          <Modal
            width={step === 2 ? 420 : 800}
            close={this.closeAndClearData}
            continuousModal={true}
            centered={false}
          >
            {modalLoading ? (
              <Loader />
            ) : (
              <New
                step={step}
                email={email}
                displayName={displayName}
                title={title}
                teams={teams}
                errors={errors}
                startTime={startTime}
                endTime={endTime}
                address={address}
                city={city}
                state={state}
                zip={zip}
                updateTimes={this.updateTimes}
                closeModal={this.closeAndClearData}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                addTeam={this.addTeam}
                removeTeam={this.removeTeam}
                validateField={this.validateField}
                validFields={this.validFields}
                goToStep={this.goToStep}
                finishAction={this.finishAction}
                user={user}
              />
            )}
          </Modal>
        </StyledTeamsModal>
      )
    } else if (modalOpen && modalMode === 'edit') {
      // console.log('ID', _id)
      return (
        <StyledTeamsModal>
          <Modal
            width={step === 2 ? 420 : 800}
            close={this.closeAndClearData}
            continuousModal={true}
            centered={false}
          >
            {modalLoading ? (
              <Loader />
            ) : (
              <Edit
                step={step}
                _id={_id}
                email={email}
                displayName={displayName}
                title={title}
                profileImage={profileImage}
                teams={teams}
                errors={errors}
                startTime={startTime}
                endTime={endTime}
                address={address}
                city={city}
                state={state}
                zip={zip}
                updateTimes={this.updateTimes}
                removingUser={removingUser}
                removeConfirmText={removeConfirmText}
                closeModal={this.closeAndClearData}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                addTeam={this.addTeam}
                removeTeam={this.removeTeam}
                validateField={this.validateField}
                validFields={this.validFields}
                goToStep={this.goToStep}
                removeUser={this.removeUser}
                finishAction={this.finishAction}
                removeMember={removeMember}
                user={user}
              />
            )}
          </Modal>
        </StyledTeamsModal>
      )
    } else {
      return false
    }
  }
}

export default TeamsModal

const StyledTeamsModal = styled.div`
  position: relative;

  h3.title {
    font-size: 1.5em;
  }

  .detail {
    margin-bottom: 0.5em;
  }

  .detail-title {
    font-weight: 700;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;

    &.green {
      color: ${colors.primary};
    }

    &.red {
      color: ${colors.danger};
    }

    .profile-image,
    h3 {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 0;
    }

    h3 {
      position: relative;
      /* top: 0.25em; */
      font-size: 1.5em;
    }

    .profile-image {
      margin-right: 1em;
    }
  }

  .title > .left {
    display: flex;
    align-items: center;
  }

  .bottom {
    margin-top: 1em;
  }

  .options {
    margin-top: 3em;
    display: flex;
    justify-content: space-between;

    .cancel,
    .edit {
      display: inline-block;
      position: relative;
      top: 1.9em;
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.grey};
      font-size: 0.875em;
    }

    .cancel {
      margin-right: 2em;
    }

    .edit {
      position: relative;
      text-decoration: none;
      svg {
        position: relative;
        top: 2px;
      }
      span {
        position: relative;
        text-decoration: underline;
      }
    }
  }

  .remove {
    position: relative;
    top: 0.75em;
    cursor: pointer;
    text-decoration: underline;
    color: ${colors.grey};
    font-size: 0.875em;
  }

  /* select {
    width: 100%;
  } */

  .add-team {
    position: relative;
    font-size: 0.75em;
    color: ${colors.grey2};
    cursor: pointer;
    margin-top: 1em;

    svg {
      cursor: pointer;
    }

    span {
      position: relative;
      top: -0.1em;
      margin-left: 0.25em;
    }
  }

  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .user-team {
    margin-bottom: 0.5em;
  }

  .margin-vertical {
    margin: 3em 0;
  }

  .confirm-container {
    margin-bottom: 1em;
    .flex {
      display: flex;
    }

    input {
      width: 70%;
      margin-right: 1em;
    }

    button {
      padding: 0.3em 0.6em;
      line-height: 1.5;
      margin-bottom: 0;
      width: 30%;
    }
  }
`

const Grid = styled.div`
  display: flex;
`

const Column = styled.div`
  width: 50%;

  h4 {
    font-size: 1.25em;
  }

  &:first-child {
    padding: ${props => (props.gutter && props.gutter === 'small' ? '0 0.25em 0 0' : '0 1em 0 0')};
  }

  &:last-child {
    padding: ${props => (props.gutter && props.gutter === 'small' ? '0 0 0 0.25em' : '0 0 0 1em')};
  }
`
