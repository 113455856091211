import React, { Fragment } from 'react'
import { format } from 'date-fns'
import * as Scheduler from './index'
import selectors from './../selectors'
import Column from '../../pages/Projects/Column'

const Day = ({ day, events, selectTime, isCustomer }) => (
  <Column transparent={true}>
    <h3>{day}</h3>
    {selectors.organizeTimes(events.slots, day).map(slot => {
      const displayedDt = (slot.rawDt / 60).toFixed(2)
      return (
        <Scheduler.Time
          key={slot.id}
          onClick={() => selectTime(slot)}
          selected={events.selectedTime && slot.id === events.selectedTime.id}
        >
          <div className="header">
            <div className="title">
              {format(slot.start.dateTime, 'h:mma')} - {format(slot.end.dateTime, 'h:mma')}
            </div>

            {!isCustomer && (
              <Fragment>
                <span className="small">Estimated Drive Time – {displayedDt || 0} mins</span>
                <br />
                <span className="small">Surveyor – {slot.surveyor.displayName}</span>
              </Fragment>
            )}
          </div>
        </Scheduler.Time>
      )
    })}
  </Column>
)

export default Day
