import React, { Component } from 'react'
import styled from 'styled-components'
import { splitStringByCapital, toTitleCase } from './../../utils'
import { colors } from './../../helpers/foundation'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import StyledTable from './../sharedComponents/StyledTable'
// import Icon from './../sharedComponents/Icon'
import Button from './../sharedComponents/Button'
import Thumbnails from './../sharedComponents/Thumbnails'
import PhotoBox from './../sharedComponents/PhotoBox'

class EstimateExtraServices extends Component {
  state = {
    columns: ['Service', 'Qty.', 'Rate', 'Survey Info', 'Additional Costs ($)', ''],
    // estimate.services.services with 'open' virtual prop attached
    services:
      this.props.estimate.services.services.map(service => {
        service.open = false
        return service
      }) || [],
    total: this.props.estimate.services.total || '0',
    viewingPhoto: null,
    viewinhPhotoTitle: null,
  }

  // add virtual delete button onto the service object
  servicesWithMeta = () => {
    const services = this.state.services.map(service => {
      // icon for deleting a service

      const serviceWithCost = {
        ...service,
        cost: (service.quantity * service.rate).toFixed(2),
      }

      const del = (
        <div className="meta-actions">
          <div className="clickable delete" onClick={e => this.removeService(e, service._id)}>
            {/* <Icon icon="close" color="danger" />{' '} */}
            <div className="small">Delete</div>
          </div>
        </div>
      )

      return {
        ...serviceWithCost,
        del,
      }
    })
    return services
  }

  // add a new blank service into services.services
  addService = () => {
    const { estimate } = this.props
    const newService = {
      name: '',
      text: '',
      rate: '',
      quantity: '',
      images: [],
    }
    const newServices = [...estimate.services.services, newService]

    // need to update the server with the new service first
    // because when setting the state, we will need the id (for DOM methods)
    // generated from the server creating a new one
    this.props
      .estimateUpdateServer(estimate._id, {
        'services.services': newServices,
      })
      .then(success => {
        this.setState(prevState => {
          return {
            // need the new service to have _id
            services: this.props.estimate.services.services,
          }
        })
      })
  }

  removeService = (e, serviceId) => {
    const { services } = this.state
    const serviceIdx = services.findIndex(service => service._id === serviceId)

    // get the new total with the service being removed excluded
    let total = 0
    services.forEach((service, idx) => {
      if (idx !== serviceIdx) {
        total += Number((service.quantity * service.rate).toFixed(2))
      }
    })

    this.setState(
      prevState => {
        return {
          total: total.toFixed(2),
          services: [
            ...prevState.services.slice(0, serviceIdx),
            ...prevState.services.slice(serviceIdx + 1),
          ],
        }
      },
      () => {
        const { total, services } = this.state
        this.props
          .estimateUpdateServer(this.props.estimate._id, {
            services: {
              total,
              services,
            },
          })
          .then(success => console.log('successfully updated services'))
      }
    )
  }

  // update immediately is used so you can type in a new name
  // for a new service and it wont be updated until blur
  updateService = (e, serviceId) => {
    const tarName = e.target.name,
      tarVal = e.target.value
    const serviceIdx = this.state.services.findIndex(s => s._id === serviceId)

    // prevent error of duplicating the services
    if (serviceIdx < 0) {
      console.log('could not find the service')
      return false
    }

    // dont allow alphabet cars unless the field being updated is 'name'
    if (tarName !== 'name' && isNaN(Number(tarVal))) return false

    const newServiceInfo = {
      ...this.state.services[serviceIdx],
      [tarName]: tarVal,
    }

    // get the total by adding up all services totals, including the new values
    let total = 0
    if (newServiceInfo.quantity && newServiceInfo.rate) {
      this.state.services.forEach((service, idx) => {
        if (serviceIdx === idx) {
          total += Number((newServiceInfo.quantity * newServiceInfo.rate).toFixed(2))
        } else {
          total += Number((service.quantity * service.rate).toFixed(2))
        }
      })
    }

    this.setState(
      prevState => {
        return {
          total: total > 0 ? total.toFixed(2) : prevState.total,
          services: [
            ...prevState.services.slice(0, serviceIdx),
            newServiceInfo,
            ...prevState.services.slice(serviceIdx + 1),
          ],
        }
      },
      () => {
        const { total, services } = this.state
        this.props
          .estimateUpdateServer(this.props.estimate._id, {
            services: {
              total,
              services,
            },
          })
          .then(success => console.log('successfully updated services'))
      }
    )
  }

  // open/close the image row for a given service
  toggleImageRow = serviceId => {
    const { services } = this.state
    const serviceIdx = services.findIndex(service => service._id === serviceId)

    // only update the service if it was found
    if (serviceIdx >= 0) {
      this.setState(prevState => {
        return {
          services: [
            ...prevState.services.slice(0, serviceIdx),
            {
              ...prevState.services[serviceIdx],
              open: !prevState.services[serviceIdx].open,
            },
            ...prevState.services.slice(serviceIdx + 1),
          ],
        }
      })
    }
  }

  // get idx of the detail, then of the image for the
  // detail and set viewingPhoto to the photo clicked on
  viewPhoto = (detailKey, imageId) => {
    const { services } = this.state
    const detailIdx = services.findIndex(detail => detail.name === detailKey)
    const imageIdx = this.state.services[detailIdx]['images'].findIndex(
      image => image._id === imageId
    )

    this.setState(prevState => {
      return {
        viewingPhoto: prevState.services[detailIdx]['images'][imageIdx],
        viewingPhotoTitle: `${prevState.services[detailIdx]['text']}`,
      }
    })
  }

  closePhoto = () => {
    this.setState(prevState => {
      return {
        viewingPhoto: null,
        viewingPhotoTitle: null,
      }
    })
  }

  render() {
    const { estimate } = this.props
    const { viewingPhoto, viewingPhotoTitle, loading } = this.state

    return (
      <StyledEstimateExtraServices>
        <PhotoBox
          presentational={true}
          editing={viewingPhoto}
          header={viewingPhotoTitle && viewingPhotoTitle}
          imageUrl={viewingPhoto && viewingPhoto.large}
          notes={viewingPhoto && viewingPhoto.notes}
          close={this.closePhoto}
          loading={loading}
        />
        <Header title="Review the extra services that we'll be performing." />
        <Container>
          {estimate.revisionNotes && <Notice text={estimate.revisionNotes} />}
          <StyledTable>
            <thead>
              <tr>
                {this.state.columns.map((column, idx) => {
                  return <th key={idx}>{column}</th>
                })}
              </tr>
            </thead>
            <tbody className="striped-every-two">
              {/* return the 2 rows per service, with delete button for each */}
              {this.servicesWithMeta().map((service, idx) => {
                return (
                  <React.Fragment key={service._id}>
                    <tr>
                      <td>
                        {service.name ? (
                          toTitleCase(splitStringByCapital(service.name))
                        ) : (
                          <input
                            type="text"
                            name="name"
                            className="editable-td"
                            placeholder="Name"
                            // value={service.name}
                            // onChange={}
                            // only will update when the field is blurred
                            onBlur={e => {
                              this.updateService(e, service._id)
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          name="quantity"
                          className="editable-td"
                          value={service.quantity}
                          onChange={e => this.updateService(e, service._id)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="rate"
                          className="editable-td"
                          value={service.rate}
                          onChange={e => this.updateService(e, service._id)}
                        />
                      </td>
                      <td>
                        {!service.images || service.images.length < 1 ? (
                          <div>No Images.</div>
                        ) : (
                          <div
                            className={`view-survey-info ${service.open ? 'open' : null}`}
                            onClick={e => this.toggleImageRow(service._id)}
                          >
                            {service.open ? 'Hide' : 'Show'} Survey Photos
                          </div>
                        )}
                      </td>
                      <td>{!service.cost || service.cost === '' ? 0 : service.cost}</td>
                      <td>{service.del}</td>
                    </tr>
                    {service.open && (
                      <ThumbnailsRow open={service.open}>
                        <td colSpan="6">
                          <Thumbnails
                            marginTop="0"
                            photos={service.images}
                            detailKey={service.name}
                            handleClick={this.viewPhoto}
                          />
                        </td>
                      </ThumbnailsRow>
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </StyledTable>

          {Number(this.state.total) > 0 && (
            <div className="totals-container">
              <div className="totals">
                <b>Total: </b>
                <div>{this.state.total}</div>
              </div>
            </div>
          )}

          <Button text="Add Service" handleClick={this.addService} />
        </Container>
      </StyledEstimateExtraServices>
    )
  }
}

export default EstimateExtraServices

const StyledEstimateExtraServices = styled.div`
  .totals-container {
    background-color: #f7f8f9;
    padding: 2em 1.5em;
    text-align: right;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: ${colors.base};
  }

  .view-survey-info {
    border: 1px solid #868e96;
    border-radius: 4px;
    width: 140px;
    text-align: center;
    cursor: pointer;

    &.open {
      background-color: #868e96;
      color: #fff;
    }
  }

  button {
    margin-top: 2em;
  }
`

const ThumbnailsRow = styled.tr`
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`
