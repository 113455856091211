import styled from 'styled-components'
import { breakpoints } from './../../../helpers/foundation'

export const Collection = styled.div`
  margin: 0 -0.5em;

  @media screen and (min-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50em;
  }
`

export const CollectionItem = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  flex: 0 1 ${props => (props.columns === 2 ? 'calc(50% - 1em)' : 'calc(33.33333% - 1em)')};
`
