import {
  LOADING,
  ERROR,
  UPDATE_INSTALLATION,
  UPDATE_NESTED_INSTALLATION,
  ADD_PHOTO_COMMENT,
  UPDATE_POSTOPS,
  ADD_INSPECTION_REMARK,
  ADD_INSPECTION_FILE,
  FETCH_INSTALLATION,
  FETCH_POSTOPS,
  INSPECTION_FILE_LOADING,
} from './types'

export const loading = bool => ({
  type: LOADING,
  payload: {
    loading: bool,
  },
})

export const requestError = message => ({
  type: ERROR,
  payload: {
    message,
  },
})

export const updateInstallation = (installationId, updates) => ({
  type: UPDATE_INSTALLATION,
  payload: {
    installationId,
    updates,
  },
})

export const updateNestedInstallation = (installationId, field, itemId, updates) => ({
  type: UPDATE_NESTED_INSTALLATION,
  payload: {
    installationId,
    field,
    itemId,
    updates,
  },
})

export const addPhotoComment = (installationId, direction, photoId, comment) => ({
  type: ADD_PHOTO_COMMENT,
  payload: {
    installationId,
    direction,
    photoId,
    comment,
  },
})

export const updatePostOps = (installationId, updates) => ({
  type: UPDATE_POSTOPS,
  payload: {
    installationId,
    updates,
  },
})

export const addInspectionRemark = (installationId, remark) => ({
  type: ADD_INSPECTION_REMARK,
  payload: {
    installationId,
    remark,
  },
})

export const addInspectionFile = (installationId, fileObj) => ({
  type: ADD_INSPECTION_FILE,
  payload: {
    installationId,
    fileObj,
  },
})

export const fetchInstallation = installation => ({
  type: FETCH_INSTALLATION,
  payload: {
    installation,
  },
})

export const fetchPostOps = postOps => ({
  type: FETCH_POSTOPS,
  payload: {
    postOps,
  },
})

export const inspectionFileLoading = bool => ({
  type: INSPECTION_FILE_LOADING,
  payload: {
    loading: bool,
  },
})
