import React from 'react'
import styled from 'styled-components'

const InfoTable = props => {
  const { data } = props
  const keys = Object.keys(data)
  const vals = Object.values(data)
  return (
    <StyledInfoTable>
      <div className="flex">
        <div className="keys">
          {keys.map((key, idx) => (
            <div key={idx} className="key">
              {key}
            </div>
          ))}
        </div>
        <div className="values">
          {vals.map((val, idx) => (
            <div key={idx} className="val">
              {val}
            </div>
          ))}
        </div>
      </div>
    </StyledInfoTable>
  )
}

export default InfoTable

const StyledInfoTable = styled.div`
  .flex {
    display: flex;
  }

  .keys {
    margin-right: 6em;
  }

  .key,
  .val {
    margin-bottom: 1.5em;
  }

  .key {
    font-weight: 700;
  }
`
