// checks roles and returns boolean weather or not they are a given role
export function isRole(user) {
  return {
    // Admin team
    admin: user.teams.findIndex(team => team.name === 'Admin') > -1,
    adminManager:
      user.teams.findIndex(team => team.name === 'Admin') > -1 &&
      user.teams[user.teams.findIndex(team => team.name === 'Admin')].role === 'Manager',
    // Survey Team
    survey: user.teams.findIndex(team => team.name === 'Survey') > -1,
    surveyManager:
      user.teams.findIndex(team => team.name === 'Survey') > -1 &&
      user.teams[user.teams.findIndex(team => team.name === 'Survey')].role === 'Manager',
    // Estimate team
    estimate: user.teams.findIndex(team => team.name === 'Estimate') > -1,
    estimateManager:
      user.teams.findIndex(team => team.name === 'Estimate') > -1 &&
      user.teams[user.teams.findIndex(team => team.name === 'Estimate')].role === 'Manager',
    customerService: user.teams.findIndex(team => team.name === 'CustomerService') > -1,
    customerServiceManager:
      user.teams.findIndex(team => team.name === 'CustomerService') > -1 &&
      user.teams[user.teams.findIndex(team => team.name === 'CustomerService')].role === 'Manager',
    production: user.teams.findIndex(team => team.name === 'Production') > -1,
    productionManager:
      user.teams.findIndex(team => team.name === 'Production') > -1 &&
      user.teams[user.teams.findIndex(team => team.name === 'Production')].role === 'Manager',
  }
}

// for now there is nothing else
export default isRole
