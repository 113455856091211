import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../helpers/foundation'
import Icon from './Icon'

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;

  + label {
    position: relative;
    top: -4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${colors.base};
    font-weight: 400;
    user-select: none;
  }

  + label,
  + span {
    position: relative;
    cursor: pointer;
    padding: 0;
    flex: 0 0 auto;
    max-width: 100%;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: text-top;
      width: ${props => (props.size === 'large' ? '32px' : '20px')};
      height: ${props => (props.size === 'large' ? '32px' : '20px')};
      margin-right: 10px;
      flex: 0 0 auto;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid ${colors.grey6};
    }
  }

  &:focus + label::before,
  &:focus + span::before {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
  }

  &:checked + label::after,
  &:checked + span::after {
    content: '';
    position: absolute;
    left: 2px;
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    border-radius: 4px;
    width: calc(${props => (props.size === 'large' ? '32px' : '20px')} - 4px);
    height: calc(${props => (props.size === 'large' ? '32px' : '20px')} - 4px);
  }
`

const Text = styled.span`
  flex: 1 1 auto;
`

const CheckboxIcon = styled(Icon)`
  position: absolute;
  width: 32px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  svg {
    width: 20px;
    height: 16px;
  }
`

const Checkbox = ({ size, name, value, checked, handleChange, text, checkmark }) => (
  <Fragment>
    <StyledCheckbox
      type="checkbox"
      id={name}
      name={name}
      value={value}
      checked={checked}
      size={size}
      onChange={e => {
        if (handleChange) {
          handleChange(e)
        }
      }}
    />
    <label htmlFor={name}>
      <Fragment>
        {text && <Text>{text}</Text>}
        {checkmark && value && size === 'large' && <CheckboxIcon icon="solidCheck" color="white" />}
      </Fragment>
    </label>
  </Fragment>
)

Checkbox.propTypes = {
  size: PropTypes.oneOf(['large']),
  name: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.string,
  checkmark: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default Checkbox
