import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'

import Step from './../sharedComponents/step'
import Button from './../sharedComponents/Button'
import AntiMobileAction from './../sharedComponents/AntiMobileAction'

class Sidebar extends Component {
  render() {
    return (
      <StyledSidebar>
        <div className="sidebar-top">
          <div className="sidebar-inner">
            <Step
              status="incomplete"
              title="file upload"
              num={1}
              handleClick={() => console.log('clicked')}
              isActive={true}
              isCurrent={true}
            />
          </div>
        </div>

        <AntiMobileAction>
          <div className="sidebar-action">
            <Button
              variant={`lg small-vert-padding ${this.props.canStepNext ? null : 'disabled'}`}
              text="Schedule Install"
              handleClick={() => (this.props.canStepNext ? this.props.scheduleInstall() : null)}
              fluid={true}
            />
          </div>
        </AntiMobileAction>
      </StyledSidebar>
    )
  }
}

const StyledSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .sidebar-top {
    flex-grow: 1;
  }

  .sidebar-header {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .sidebar-inner {
    padding: 1em;
  }

  .sidebar-action {
    border-top: 1px solid ${colors.lightGrey};
    flex-shrink: 0;
    padding: 1em;

    button:last-child {
      margin-bottom: 0;
    }
  }
`

export default Sidebar
