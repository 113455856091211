import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { getTotals } from './../../../helpers/totals'
import { addProductionUrl } from './../../../helpers/constants'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import ResizeableSector from './../../sharedComponents/ResizeableSector'
import Button from './../../sharedComponents/Button'
import Checkbox from './../../sharedComponents/Checkbox'
import Notice from './../../sharedComponents/Notice'

class ConfirmPayment extends Component {
  state = {
    paymentConfirm: false,
  }

  handleSelect = e => {
    this.setState(prevState => ({ paymentConfirm: !prevState.paymentConfirm }))
  }

  confirm = () => {
    const { project } = this.props

    const allPromise = Promise.all([
      this.props.updateInstallation(this.props.project.installation._id, {
        installationStatus: 'Completed',
      }),
      axios.post(`${addProductionUrl()}/api/projects/${project._id}/payment`, {
        paymentOrder: 2,
        paymentType: 'cash',
      }),
    ])

    allPromise.then(arr => {
      console.log('all done?', arr)
    })
  }

  render() {
    const { paymentConfirm } = this.state
    const { project } = this.props
    const { taxTotal, cashTotal, creditTotal } = getTotals(
      [
        Number(project.estimate.parts.total),
        Number(project.estimate.labors.total),
        Number(project.estimate.services.total),
      ],
      0.07
    )

    return (
      <StyledConfirmPayment>
        <Header title="Confirm receipt of payment." />
        <Container>
          <Notice>
            We are waiting for the Customer to make a payment. If the Customer doesn't handle it on
            their side, we can confirm their payment here.
          </Notice>
          <ResizeableSector title="Amount due at this time:">
            {/* this will only be used if 25/50/25 was selected so we can assume what the value will be */}
            <h2 className="total">${(creditTotal / 4).toFixed(2)}</h2>

            <div className="confirm">
              <Checkbox
                name="paymentConfirm"
                value="paymentConfirm"
                checked={paymentConfirm ? true : false}
                checkmark={true}
                text="I confirm that OFS has received payment for the amount listed above in the form of cash, check, or financing."
                size="large"
                handleChange={this.handleSelect}
              />
              <Button
                text="Confirm"
                variant={paymentConfirm ? 'lg primary' : 'lg disabled'}
                fluid={true}
                handleClick={this.confirm}
              />
            </div>
          </ResizeableSector>
        </Container>
      </StyledConfirmPayment>
    )
  }
}

const StyledConfirmPayment = styled.div`
  position: relative;

  .title {
    margin-bottom: 0.5em;
    font-weight: 400;
  }

  .total {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 2.5em;
  }

  .confirm {
    position: relative;
    width: 100%;
    max-width: 20em;
    margin-top: -1.5em;

    label {
      width: 100%;
      margin-bottom: 2.5em;
    }

    label::before {
      /* position: relative; */
      /* margin-bottom: 8px; */
      margin-right: 1em;
    }

    span {
      margin-top: 1.5em;
    }
  }
`

export default ConfirmPayment
