import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import '../../../vendor/react-datepicker.css'
import { colors } from './../../../helpers/foundation'
import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Sector from './../../sharedComponents/Sector'
import Icon from './../../sharedComponents/Icon'
import Button from './../../sharedComponents/Button'
import { chownSync } from 'fs'

class ScheduleInstallation extends Component {
  state = {
    installationDate: null,
    waterDate: null,
    electricDate: null,
    gasDate: null,
    installationDateScheduled: false,
    waterDateScheduled: false,
    electricDateScheduled: false,
    gasDateScheduled: false,

    installationReschedule: false,
    waterReschedule: false,
    electricReschedule: false,
    gasReschedule: false,
  }

  handleInputChange = (inputName, dateObj) => {
    this.setState(prevState => ({
      [inputName]: dateObj,
    }))
  }

  dateScheduled = name => {
    this.setState(prevState => ({
      [name]: true,
    }))
  }

  // this will allow the re-opening on the calendar to re select a date
  openReschedule = (whichDate, whichReschedule) => {
    // do we want reseting 'installationDate' to reset all Locates dates?
    this.setState(prevState => ({
      [whichDate]: moment(this.props[whichDate]),
      [whichReschedule]: !prevState[whichReschedule],
    }))
  }

  render() {
    const { installationDate, waterDate, electricDate, gasDate } = this.state
    const { installation } = this.props.project

    // get the date from water, electric, gas that is the furthest into future
    let maxDate = null
    if (this.props.waterDate && this.props.electricDate && this.props.gasDate) {
      const moms = [this.props.waterDate, this.props.electricDate, this.props.gasDate].map(d =>
        moment(d)
      )
      maxDate = moment.max(moms)
    }

    // to let scheduling of installation know if all locates are scheduled
    let utilsScheduled = null
    if (this.props.waterDate && this.props.electricDate && this.props.gasDate) {
      utilsScheduled = true
    } else {
      utilsScheduled = false
    }

    return (
      <StyledScheduleInstallation>
        <Header title="Schedule a preliminary date for the installation and utility locates." />
        <Container>
          {/* installation date picker */}
          <Sector title="Utility Locates">
            <SectorItems>
              {/* locates for water */}
              <SectorItem itemScheduled={!!this.props.waterDate}>
                <div
                  className="flex"
                  onClick={() => this.openReschedule('waterDate', 'waterReschedule')}
                >
                  <div className="name">Water</div>
                  {!this.props.waterDate ? (
                    <div className="status">
                      <Icon icon="close" />
                      <span>Not Scheduled</span>
                    </div>
                  ) : (
                    <div className="status">
                      <Icon icon="solidCheck" color="primary" />
                      <span>Scheduled</span>
                    </div>
                  )}
                  <div className="scheduled-date">
                    {!!this.props.waterDate && (
                      <React.Fragment>
                        <Icon icon="calendar" />
                        <span>{moment(this.props.waterDate).format('dddd, MMM D')}</span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {(!this.props.waterDate || this.state.waterReschedule) && (
                  <div className="time-section notranslate">
                    <DatePicker
                      selected={waterDate}
                      onChange={date => this.handleInputChange('waterDate', date)}
                      // filterDate={this.isWeekday}
                      minDate={moment()}
                      // maxDate={moment(this.props.installationDate)}
                      inline
                      highlightDates={[
                        {
                          'react-datepicker__day--highlighted-installation-date': [
                            moment(this.props.installationDate),
                          ],
                        },
                      ]}
                    >
                      <div className="datepicker-children">
                        <Button
                          text="Confirm"
                          variant={`lg ${!waterDate && 'disabled'}`}
                          fluid={true}
                          handleClick={date => {
                            if (waterDate) {
                              this.setState({ waterReschedule: false })
                              this.props.updateInstallation(installation._id, {
                                waterDate: waterDate.toDate(),
                              })
                            }
                          }}
                        />
                      </div>
                    </DatePicker>
                  </div>
                )}
              </SectorItem>

              {/* locates for electric */}
              <SectorItem itemScheduled={!!this.props.electricDate}>
                <div
                  className="flex"
                  onClick={() => this.openReschedule('electricDate', 'electricReschedule')}
                >
                  <div className="name">Electric</div>
                  {!this.props.electricDate ? (
                    <div className="status">
                      <Icon icon="close" />
                      <span>Not Scheduled</span>
                    </div>
                  ) : (
                    <div className="status">
                      <Icon icon="solidCheck" color="primary" />
                      <span>Scheduled</span>
                    </div>
                  )}
                  <div className="scheduled-date">
                    {!!this.props.electricDate && (
                      <React.Fragment>
                        <Icon icon="calendar" />
                        <span>{moment(this.props.electricDate).format('dddd, MMM D')}</span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {(!this.props.electricDate || this.state.electricReschedule) && (
                  <div className="time-section notranslate">
                    <DatePicker
                      selected={electricDate}
                      onChange={date => this.handleInputChange('electricDate', date)}
                      filterDate={this.isWeekday}
                      minDate={moment()}
                      inline
                      className="notranslate"
                      highlightDates={[
                        {
                          'react-datepicker__day--highlighted-installation-date': [
                            moment(this.props.installationDate),
                          ],
                        },
                      ]}
                    >
                      <div className="datepicker-children">
                        <Button
                          text="Confirm"
                          variant={`lg ${!electricDate && 'disabled'}`}
                          fluid={true}
                          handleClick={date => {
                            if (electricDate) {
                              this.setState({ electricReschedule: false })
                              this.props.updateInstallation(installation._id, {
                                electricDate: electricDate.toDate(),
                              })
                            }
                          }}
                        />
                      </div>
                    </DatePicker>
                  </div>
                )}
              </SectorItem>

              {/* locates for gas */}
              <SectorItem itemScheduled={!!this.props.gasDate}>
                <div
                  className="flex"
                  onClick={() => this.openReschedule('gasDate', 'gasReschedule')}
                >
                  <div className="name">Gas</div>
                  {!this.props.gasDate ? (
                    <div className="status">
                      <Icon icon="close" />
                      <span>Not Scheduled</span>
                    </div>
                  ) : (
                    <div className="status">
                      <Icon icon="solidCheck" color="primary" />
                      <span>Scheduled</span>
                    </div>
                  )}
                  <div className="scheduled-date">
                    {!!this.props.gasDate && (
                      <React.Fragment>
                        <Icon icon="calendar" />
                        <span>{moment(this.props.gasDate).format('dddd, MMM D')}</span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {(!this.props.gasDate || this.state.gasReschedule) && (
                  <div className="time-section notranslate">
                    <DatePicker
                      selected={gasDate}
                      onChange={date => this.handleInputChange('gasDate', date)}
                      filterDate={this.isWeekday}
                      minDate={moment()}
                      inline
                      highlightDates={[
                        {
                          'react-datepicker__day--highlighted-installation-date': [
                            moment(this.props.installationDate),
                          ],
                        },
                      ]}
                    >
                      <div className="datepicker-children">
                        <Button
                          text="Confirm"
                          variant={`lg ${!gasDate && 'disabled'}`}
                          fluid={true}
                          handleClick={date => {
                            if (gasDate) {
                              this.setState({ gasReschedule: false })
                              this.props.updateInstallation(installation._id, {
                                gasDate: gasDate.toDate(),
                              })
                            }
                          }}
                        />
                      </div>
                    </DatePicker>
                  </div>
                )}
              </SectorItem>
            </SectorItems>
          </Sector>

          <Sector title="Installation">
            <div className="time-section notranslate">
              {(utilsScheduled && !this.props.installationDate) ||
              this.state.installationReschedule ? (
                <DatePicker
                  selected={installationDate}
                  onChange={date => this.handleInputChange('installationDate', date)}
                  filterDate={this.isWeekday}
                  minDate={maxDate}
                  inline
                >
                  <div className="datepicker-children">
                    <Button
                      text="Confirm"
                      variant={`lg ${!installationDate && 'disabled'}`}
                      fluid={true}
                      handleClick={date => {
                        this.setState({ installationReschedule: false })
                        if (installationDate) {
                          this.props.updateInstallation(installation._id, {
                            installationDate: installationDate.toDate(),
                            lastStatus: Date.now(),
                          })
                        }
                      }}
                    />
                  </div>
                </DatePicker>
              ) : (
                <div
                  className="scheduled-date"
                  onClick={() => {
                    if (utilsScheduled) {
                      this.openReschedule('installationDate', 'installationReschedule')
                    }
                  }}
                >
                  {!this.props.installationDate ? (
                    <div className="status">
                      <Icon icon="close" />
                      <span>Not Scheduled</span>
                    </div>
                  ) : (
                    <div className="status">
                      <Icon icon="solidCheck" color="primary" />
                      <span>Scheduled</span>
                      <div className="status">
                        <Icon icon="calendar" />
                        <span>{moment(this.props.gasDate).format('dddd, MMM D')}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Sector>
        </Container>
      </StyledScheduleInstallation>
    )
  }
}

const StyledScheduleInstallation = styled.div`
  .datepicker-children {
    padding: 0 0.566rem 0.566rem;
    button {
      margin-bottom: 0;
    }
  }

  .scheduled-date {
    cursor: pointer;
    color: ${props => {
      if (!props.utilsScheduled) {
        return colors.grey
      }
      return ''
    }};

    .icon {
      position: relative;
      bottom: -1px;
      margin-right: 0.5em;
    }

    .status {
      .icon {
        position: relative;
        bottom: 1px;
      }
    }

    .scheduled-date {
      > span {
        margin-left: 0.5em;
      }
      .icon {
        position: relative;
        bottom: -1px;
      }
    }
  }
`

const SectorItems = styled.div`
  width: 42em;
  margin-top: 1em;
`

const SectorItem = styled.div`
  margin-bottom: ${props => props.installationScheduled && '1em'};

  .flex {
    display: flex;
    border-bottom: 1px solid ${colors.grey6};
    padding: 0.5em 0;
    margin: 0.5em 0;
    cursor: pointer;
    > div {
      width: 33.3333333333333%;
    }
  }

  .time-section {
    padding-top: 1em;
  }

  .name {
    font-weight: 600;
    color: ${props => {
      if (props.itemScheduled) {
        return colors.grey
      }
      return ''
    }};
  }

  .status,
  .scheduled-date {
    position: relative;

    > span {
      margin-left: 0.5em;
      color: ${props => {
        if (props.itemScheduled) {
          return colors.grey
        }
        return ''
      }};
    }
  }

  .status {
    .icon {
      position: relative;
      bottom: 1px;
    }
  }

  .scheduled-date {
    .icon {
      position: relative;
      bottom: -1px;
    }
  }
`

export default ScheduleInstallation
