import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { PoseGroup } from 'react-pose'

import Icon from './../sharedComponents/Icon'
import Reveal from './../sharedComponents/Reveal'
import { colors, breakpoints } from './../../helpers/foundation'
import placeholder from './../../assets/placeholder.jpg'

class NavReveal extends Component {
  state = {
    menuIsOpen: false,
  }

  handleClick = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
    })
  }

  handleLogout = () => {
    this.props.logoutUser()
  }

  render() {
    const { user } = this.props
    const { menuIsOpen } = this.state

    return (
      <UserMenu menuIsOpen={this.state.menuIsOpen}>
        <div className="wrapper">
          <Link to="/profile">
            <img className="portrait" src={user.profileImage || placeholder} alt={user.name} />
          </Link>
          <div className="menu">
            <button className="action" onClick={this.handleClick}>
              <svg width="7" height="5" viewBox="0 0 7 5" xmlns="http://www.w3.org/2000/svg">
                <g
                  id="01-Material"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(-989 -53)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <g className="action-arrow" id="Survey-Title" stroke="#ADB5BD">
                    <g id="Dropdown-Icon" transform="translate(984 47)">
                      <polyline id="Page-1" points="5 7 8.5 10 12 7" />
                    </g>
                  </g>
                </g>
              </svg>
            </button>
            <PoseGroup>
              {menuIsOpen && (
                <Reveal key="nav-menu">
                  <div className="inner">
                    <div className="item">
                      <div onClick={this.handleClick}>
                        <NavLink className="link" exact={true} to="/settings">
                          <Icon icon="settings" color="grey3" />
                          <span className="text">Settings</span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="item">
                      <div className="link" onClick={this.handleLogout}>
                        <Icon icon="logout" color="grey3" />
                        <span className="text">Logout</span>
                      </div>
                    </div>
                  </div>
                </Reveal>
              )}
            </PoseGroup>
          </div>
        </div>
      </UserMenu>
    )
  }
}

const UserMenu = styled.div`
  position: relative;
  padding: 10px 0 10px 20px;

  @media only screen and (min-width: ${breakpoints.medium}px) {
    padding: 10px 0 10px 30px;
  }

  .wrapper {
    position: relative;
  }

  .menu {
    position: relative;
  }

  .portrait {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: relative;

    @media only screen and (min-width: ${breakpoints.medium}px) {
      min-width: 80px;
      height: 80px;
    }
  }

  ${Reveal} {
    top: 10px;
    right: -10px;
    z-index: 9;

    .item {
      display: block;
      background-color: #fff;
      padding: 0;

      .link {
        color: ${colors.grey3};
        cursor: pointer;
        font-size: 14px;
        padding: 15px;
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        transition: 0.2s background-color ease-in-out;

        &:hover {
          background-color: ${darken(0.05, '#fff')};
        }

        &.active {
          background-color: ${colors.grey5};
          color: ${colors.grey3};

          svg {
            path,
            circle {
              stroke: ${colors.primary};
            }
          }
        }
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }

  .action {
    position: absolute;
    border: 0;
    bottom: 0;
    cursor: pointer;
    right: 0;
    padding: 0;
    margin: 0;
    background: none;
    outline: 0;
    border-radius: 50%;
    border-bottom: 2px solid ${props => (props.menuIsOpen ? colors.primary : '#fff')};
    background-color: ${props => (props.menuIsOpen ? '#fff' : colors.primary)};
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: ${breakpoints.medium}px) {
      width: 20px;
      height: 20px;
    }

    svg {
      transition: 0.1s ease-in-out;
      transform: rotate(${props => (props.menuIsOpen ? '0deg' : '180deg')});
    }

    .action-arrow {
      stroke: ${props => (props.menuIsOpen ? colors.primary : '#fff')};
    }

    &:hover {
      background-color: ${props =>
        props.menuIsOpen ? darken(0.05, '#fff') : darken(0.05, colors.primary)};
    }
  }
`

export default NavReveal
