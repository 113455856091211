import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { lighten } from 'polished'
import { colors, breakpoints } from './../../../helpers/foundation'

const StyledNavItem = styled(NavLink)`
  position: relative;
  border-bottom: 4px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  text-decoration: none;
  transition: 0.15s ease-in-out;
  padding: 1em;

  @media screen and (min-width: ${breakpoints.medium}px) {
    padding: 2em;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: ${lighten(0.15, colors.grey4)};
    border-color: ${colors.primary};
    color: ${colors.primary};
    .icon {
      > * {
        color: ${colors.primary};
      }
    }
  }

  &.active {
    background-color: ${colors.grey3};
  }

  .text {
    margin-left: 12px;
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    margin-bottom: -6px;
  }
`

const NavItem = ({ icon, text, url, mobile, handleClick, isActive, exact, ...props }) => {
  return (
    <StyledNavItem
      exact={exact ? true : false}
      to={url}
      text={text}
      onClick={() => (handleClick ? handleClick() : null)}
      className={isActive ? 'active' : null}
      isActive={
        props.isProject
          ? (match, location) =>
              location.pathname.startsWith('/projects') || location.pathname.startsWith('/project')
          : undefined
      }
    >
      <div className="icon">{icon}</div>
      {!mobile && text && <div className="text">{text}</div>}
    </StyledNavItem>
  )
}

export default NavItem
