import React, { Component } from 'react'
import SurveyCard from './SurveyCard'
import { Collection, CollectionItem } from './../sharedComponents/layouts/Collection-NEW'
import { MediumCard } from '../sharedComponents/Card-NEW'

class SurveyOptionsSelect extends Component {
  render() {
    // console.log('field?', this.props.survey[this.props.field])
    return (
      <Collection hasMaxWidth isSurvey>
        {this.props.items.map(item => {
          return (
            <CollectionItem key={item._id} columns={this.props.columns} isSurvey>
              <MediumCard
                id={item.id}
                isSurvey={true}
                field={this.props.field}
                img={item.image}
                text={item.name}
                // includes works on both strings and arrays
                selected={
                  this.props.survey[this.props.field]
                    ? this.props.survey[this.props.field].includes(item._id)
                    : ''
                }
                handleClick={e => this.props.handleClick(item._id)}
                selectable={true}
              />
            </CollectionItem>
          )
        })}
      </Collection>
    )
  }
}

export default SurveyOptionsSelect
