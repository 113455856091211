import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'
import store from 'store'

import Header from './../../sharedComponents/Header'
import ResizeableSector from './../../sharedComponents/ResizeableSector'
import StyledTable from './../../sharedComponents/StyledTable'
import Checkbox from './../../sharedComponents/Checkbox'

class InstallPrep extends Component {
  _isMounted = false

  state = {
    installParts: [],
  }

  componentDidMount = () => {
    this._isMounted = true

    if (this._isMounted) {
      this.populateStateFromLS()
    }
  }

  componentDidUpdate = (nextProps, nextState) => {
    // check to see if all parts have been loaded
    if (JSON.stringify(nextState.installParts) !== JSON.stringify(this.state.installParts)) {
      // will run when allPartsLoaded has been set to true, and you uncheck a box, resets allPartsLoaded
      if (!this.state.installParts.every(part => !!part.loaded) && this.props.allPartsLoaded) {
        this.props.updateInstallation(this.props.project.installation._id, {
          allPartsLoaded: false,
        })
      }
    }
  }

  populateStateFromLS = () => {
    const { project } = this.props
    // load installation parts into LS from DB's parts
    if (!store.get(`installParts-${project._id}`)) {
      store.set(
        `installParts-${project._id}`,
        this.props.parts.map(part => {
          return {
            ...part,
            loaded: false,
          }
        })
      )
    }
    // set state with the parts loaded into LS
    const installParts = store.get(`installParts-${project._id}`)
    if (this._isMounted) {
      this.setState(prevState => ({ installParts }))
    }
  }

  changePartLoaded = partId => {
    const { project } = this.props
    const partIdx = this.state.installParts.findIndex(part => part._id === partId)

    // update the parts' loaded state within LS
    const installParts = store.get(`installParts-${project._id}`)
    store.set(`installParts-${project._id}`, [
      ...installParts.slice(0, partIdx),
      {
        ...installParts[partIdx],
        loaded: !installParts[partIdx].loaded,
      },
      ...installParts.slice(partIdx + 1),
    ])

    // update the parts loaded state within state
    this.setState(prevState => ({
      installParts: [
        ...prevState.installParts.slice(0, partIdx),
        {
          ...prevState.installParts[partIdx],
          loaded: !prevState.installParts[partIdx].loaded,
        },
        ...prevState.installParts.slice(partIdx + 1),
      ],
    }))
  }

  render() {
    return (
      <StyledInstallPrep>
        <Header title="Gather the required materials for this job." />
        <FullWidthContainer>
          <ResizeableSector title="Material Checklist" color="white" noResize={true}>
            <StyledTable>
              <thead>
                <tr>
                  <th>Qty.</th>
                  <th>Part #</th>
                  <th>Description</th>
                  <th>Loaded</th>
                </tr>
              </thead>
              <tbody>
                {this.state.installParts.map(part => {
                  return (
                    <tr key={part._id}>
                      <td>{part.quantity}</td>
                      <td>{part.partNumber}</td>
                      <td>{part.description}</td>
                      <td>
                        <Checkbox
                          name={part._id}
                          value={part._id}
                          checked={part.loaded ? true : false}
                          size="large"
                          checkmark={true}
                          handleChange={() => this.changePartLoaded(part._id)}
                        />
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan="100" className="confirm-td">
                    {/* is every parts.loaded = true */}
                    {this.state.installParts.every(part => !!part.loaded) ? (
                      <div className="all-parts-loaded-row">
                        <Checkbox
                          name="allPartsLoaded"
                          value="allPartsLoaded"
                          checked={this.props.allPartsLoaded ? true : false}
                          size="large"
                          checkmark={true}
                          text="Yes, I have located the required parts and put them on the truck."
                          handleChange={() =>
                            this.props.updateInstallation(this.props.project.installation._id, {
                              allPartsLoaded: !this.props.allPartsLoaded,
                            })
                          }
                        />
                      </div>
                    ) : (
                      <div className="all-parts-not-loaded" />
                    )}
                  </td>
                </tr>
                {/* will be true when every part.loaded = true */}
              </tbody>
            </StyledTable>
          </ResizeableSector>
        </FullWidthContainer>
      </StyledInstallPrep>
    )
  }
}

const StyledInstallPrep = styled.div``

const FullWidthContainer = styled.div`
  position: relative;
  margin: 0 0 3.5em;

  h2.title {
    font-size: 1.25rem;
    padding: 0.5em 0.75em;
    margin-top: 0;
  }

  .confirm-td {
    height: 62px;
    min-height: 62px;
    span {
      font-size: 1rem;
      color: ${colors.base};
      margin-left: 1em;
    }
  }
`

export default InstallPrep
