import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { colors } from './../../helpers/foundation'

const Message = ({ message, type, children }) => {
  let color

  switch (type) {
    case 'error':
      color = colors.danger
      break
    case 'success':
      color = colors.success
      break
    default:
      color = colors.base
  }

  const StyledNotice = styled.div`
    color: ${color};
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 1em;

    a {
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        color: ${colors.primary};
      }
    }
  `

  return <StyledNotice>{message || children}</StyledNotice>
}

export default Message
