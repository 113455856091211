import React from 'react'
import { createSkeletonProvider } from '@trainline/react-skeletor'
import shortid from 'shortid'
import { addDays, getDate } from 'date-fns'

import Slot from './Slot'

const Slots = props => {
  return (
    <>
      {props.events.map(event => {
        return (
          <Slot
            key={event.id}
            event={event}
            isCustomer={props.isCustomer}
            selectTime={props.selectTime}
            selectedTime={props.selectedTime}
          />
        )
      })}
    </>
  )
}

const generateFakeSlots = (startOfWeek, dayNum) => {
  let slots = []

  // let amt = Math.floor(Math.random() * 6)
  // update possibles array without current chosen

  for (let i = 0; i < 3; i++) {
    slots.push({
      id: shortid.generate(),
      calendarId: shortid.generate(),
      dt: 0,
      rawDt: 0,
      start: {
        dateTime: getDate(addDays(startOfWeek, dayNum)),
      },
      end: {
        dateTime: getDate(addDays(startOfWeek, dayNum)),
      },
      location: '',
      summary: 'App generated slot for Julio',
      surveyor: { id: '', displayName: '' },
      valid: true,
      fakeTime: true,
    })
  }

  return slots
}

export default createSkeletonProvider(
  // need to create a generator to generate random times for the given week
  props => ({
    isCustomer: props.isCustomer,
    // events: [],
    events: generateFakeSlots(props.startOfWeek, props.dayNum),
  }),
  props => !props.isLoaded,
  {
    backgroundColor: 'grey',
    color: 'grey',
    borderColor: 'grey',
    cursor: 'default',
  }
)(Slots)
