import styled from 'styled-components'
import { darken } from 'polished'
// import { colors } from '../../../../helpers/foundation'

const Color = styled.button`
  background-color: ${props => props.color};
  display: block;
  height: 3em;
  width: 3em;
  margin: 1em;
  border: 5px solid ${props => (props.active ? darken(0.2, props.color) : 'transparent')};
  border-radius: 4px;
  outline: none;
  user-select: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`

export default Color
