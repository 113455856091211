import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import store from 'store'

import { getNextStep } from './utils'
import Sidebar from './Sidebar'
import SidebarLayout from './../sharedComponents/layouts/SidebarLayout'
import NotFound from './../routeUtils/notFound'
import History from './../sharedComponents/History/'
import Button from './../sharedComponents/Button'
import MobileAction from '../sharedComponents/MobileAction'
import ScheduleInstallation from './preOps/ScheduleInstallation'
import InstallPrep from './delivery/InstallPrep'
import InstallStart from './delivery/InstallStart'
import InProgress from './delivery/InProgress/'
import PermitInspection from './postOps/PermitInspection'
import InstallationFirstPayment from './preOps/InstallationFirstPayment'
import ConfirmPayment from './postOps/ConfirmPayment'
import Completed from './postOps/Completed'
import Loader from './../sharedComponents/Loader'

class Installation extends Component {
  // need to update parts from estimate to be used here
  componentDidMount = () => {
    // fetch install data when server hooked up
    this.props.fetchInstallation(this.props.project.installation._id).then(success => {
      // if parts havent been populated from estimate, do so
      if (!this.props.parts || !this.props.parts.length) {
        const estimateParts = this.props.project.estimate.parts.data
        const installationParts = estimateParts.map(part => {
          // estimate.parts = [ { a: ..., data: [ <THIS BEING USED> ] } ]
          return {
            _id: shortid.generate(),
            quantity: part.data[0],
            partNumber: part.data[1],
            description: part.data[2],
          }
        })
        this.props.updateInstallation(this.props.project.installation._id, {
          parts: installationParts,
        })
      }
    })
  }

  renderComponentsDynamically = () => {
    // this will eventually need to be checking the status
    switch (this.props.installationStatus) {
      case 'Schedule Installation':
        return <ScheduleInstallation {...this.props} />
      case 'Installation First Payment':
        return <InstallationFirstPayment {...this.props} />
      case 'Installation Prep':
        return <InstallPrep {...this.props} />
      case 'Installation Pending':
        return <InstallStart {...this.props} />
      case 'In Progress':
        return <InProgress {...this.props} />
      case 'Inspection':
        return <PermitInspection {...this.props} />
      case 'Final Payment':
        return <ConfirmPayment {...this.props} />
      case 'Completed':
        return <Completed {...this.props} />
      default:
        return <NotFound />
    }
  }

  canStepNext = () => {
    const {
      installationStatus,
      installationDate,
      waterDate,
      electricDate,
      gasDate,
      allPartsLoaded,
      photos,
    } = this.props

    // will need to be changed to take into account backend
    const steps = {
      'Schedule Installation': !!installationDate && !!waterDate && !!electricDate && !!gasDate,
      'Installation Prep': allPartsLoaded,
      // check if all are approved
      'In Progress':
        photos.rearLeft.status === 'approved' &&
        photos.rearCenter.status === 'approved' &&
        photos.rearRight.status === 'approved' &&
        photos.centerLeft.status === 'approved' &&
        photos.centerRight.status === 'approved' &&
        photos.frontLeft.status === 'approved' &&
        photos.frontCenter.status === 'approved' &&
        photos.frontRight.status === 'approved',
    }
    return steps[installationStatus]
  }

  stepNext = () => {
    const { project, installationStatus } = this.props

    const initInstallationStatus = installationStatus

    // need to check if it is in 'Installation prep' because we need to remove item from LS
    if (installationStatus === 'Installation Prep') {
      store.remove(`installParts-${project._id}`)
    }
    // // update installation status
    this.props.updateInstallation(this.props.project.installation._id, {
      installationStatus: getNextStep(
        this.props.installationStatus,
        this.props.project.paymentTerms
      ),
      lastStatus: Date.now(),
      // need to add the dates onto the request so they can be shown on audit page / history
      ...(initInstallationStatus === 'Schedule Installation' && {
        datesToRemove: {
          installationDate: this.props.installationDate,
          waterDate: this.props.waterDate,
          electricDate: this.props.electricDate,
          gasDate: this.props.gasDate,
        },
      }),
      // for audit
      projectId: project._id,
    })
  }

  render() {
    const { project, installationStatus } = this.props
    return (
      <StyledInstallation>
        <SidebarLayout
          statusComponent={
            this.props.loading ? (
              <Loader centered={true} />
            ) : (
              <Fragment>
                <MobileAction>
                  <Button
                    variant={`lg ${this.canStepNext() ? null : 'disabled'}`}
                    text="Continue"
                    handleClick={() => {
                      if (this.canStepNext()) {
                        this.stepNext()
                      }
                    }}
                    fluid={true}
                  />
                </MobileAction>
                {this.renderComponentsDynamically()}
              </Fragment>
            )
          }
          history={
            <History
              project={project}
              survey={project.survey}
              estimate={project.estimate}
              paperwork={project.paperwork}
            />
          }
        >
          <Sidebar
            installation={this.props.project.installation}
            updateInstallation={this.props.updateInstallation}
            canStepNext={this.canStepNext}
            stepNext={this.stepNext}
            installationStatus={this.props.installationStatus}
          />
        </SidebarLayout>
      </StyledInstallation>
    )
  }
}

const StyledInstallation = styled.div``

export default Installation
