import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { getTotals } from './../../../helpers/totals'
import { colors } from './../../../helpers/foundation'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import ResizeableSector from './../../sharedComponents/ResizeableSector'
import Checkout from './../../sharedComponents/Checkout'
import Loader from './../../sharedComponents/Loader'
import Icon from './../../sharedComponents/Icon'

class FinalPayment extends Component {
  state = {
    paymentLoading: false,
    paymentMessage: null,
    showingCashDesc: false,
  }

  toggleOpenPayment = status => {
    this.setState({
      paymentLoading: status,
    })
  }

  updatePaymentLoading = bool => {
    this.setState(prevState => ({ paymentLoading: bool }))
  }

  updatePaymentMessage = msg => {
    this.setState(prevState => ({ paymentMessage: msg }))
  }

  updatePaymentType = type => {
    const { project } = this.props
    // const { estimate } = project

    this.setState(prevState => ({ showingCashDesc: true }))
    this.props.projectUpdate(project._id, {
      finalPaymentType: type,
    })
  }

  render() {
    const { project } = this.props
    const { installation } = project
    const { paymentLoading, paymentMessage, showingCashDesc } = this.state

    let paymentCash = null,
      paymentCredit = null

    if (project.payments && project.payments.length > 0) {
      try {
        paymentCash = project.payments.find(payment => payment.order === 2).cashTotal
        paymentCredit = project.payments.find(payment => payment.order === 2).creditTotal
      } catch (err) {
        paymentCash = 0
        paymentCredit = 0
      }
    } else {
      paymentCash = 0
      paymentCredit = 0
    }

    return (
      <StyledFinalPayment>
        <Header title="We're almost done!" />
        <Container>
          <Notice text="Awesome! Your project is complete, and all that’s left is to settle any final payments that may be applicable. Your final bill is listed below." />

          <ResizeableSector title="Amount due at this time:" noResize={true}>
            <h2 className="total">${paymentCash}</h2>

            {project.paymentType === 'credit' && (
              <Checkout
                description="Final Fence Payment"
                amount={paymentCredit}
                customerEmail={project.customer.email}
                selected={this.props.project.paymentType === 'credit'}
                updatePaymentType={this.updatePaymentType}
                projectId={project._id}
                estimateId={project.estimate._id}
                installationId={
                  project.installation && project.installation._id
                    ? project.installation._id
                    : project.installation
                }
                installationStatus={project.installation.installationStatus}
                paymentOrder={2}
                updatePaymentLoading={this.updatePaymentLoading}
                updatePaymentMessage={this.updatePaymentMessage}
                isEstimate={false}
                stageUpdate={this.props.updateInstallation}
                history={this.props.history}
                // customerUpdateProjectStageStatus={this.props.customerUpdateProjectStageStatus}
              />
            )}

            {this.props.project.paymentType === 'cash' && (
              <PaymentCard
                onClick={() => this.updatePaymentType('cash')}
                selected={this.props.project.paymentType === 'cash' || showingCashDesc}
              >
                <div className="inner">
                  <div className="head">Cash or Check</div>
                  <Icon icon="dollarBill" />
                  <div className="text">Drop off your payment to us in person.</div>
                </div>
              </PaymentCard>
            )}

            {(this.props.project.paymentType === 'cash' || this.state.showingCashDesc) && (
              <div className="cash-desc">
                <div className="para">
                  Please hand-deliver your cash or check payment to our showroom location at:
                </div>

                <div className="para bold">
                  3319 13th Street <br />
                  St. Cloud, FL 34769
                </div>

                <div className="para">
                  Do not mail your cash payment! Osceola Fence Supply is not responsible for
                  payments that are lost or stolen in the mail.
                </div>

                <div className="para">
                  Once your payment is received, your installation can be scheduled. You may still
                  change your mind and pay by card as long as you have not already delivered your
                  payment in cash or check.
                </div>
              </div>
            )}

            {paymentLoading && <Loader />}
            {paymentMessage && (
              <div className={`message ${paymentMessage.type === 'error' ? 'error' : 'success'}`}>
                {paymentMessage.message}
              </div>
            )}
          </ResizeableSector>
        </Container>
      </StyledFinalPayment>
    )
  }
}

const StyledFinalPayment = styled.div`
  padding-bottom: 5em;

  .title {
    margin-bottom: 0.5em;
    font-weight: 400;
  }

  .total {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 1.5em;
  }

  .interest-lead {
    display: flex;

    .large-zero {
      font-size: 12em;
      line-height: 0.5;
      color: ${colors.primary};
    }

    .text {
      font-size: 2em;
      line-height: 0.9;
      color: ${colors.grey};
      margin-left: 1.5em;
      width: 8em;
    }
  }

  .margin-right {
    margin-right: 1em;
  }

  #printable-estimate {
    display: none;
  }

  .cash-desc {
    width: 26.25em;
    display: inline-block;
    font-size: 0.875em;
    margin-left: 1em;

    .para {
      margin-bottom: 1em;

      &.bold {
        font-weight: 600;
      }
    }
  }
`

const PaymentCard = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 12em;
  margin-right: 1em;
  text-align: center;
  vertical-align: top;

  .inner {
    background-color: #fff;
    border: ${props =>
      props.selected ? `1px solid ${colors.primary}` : `1px solid ${colors.grey6}`};
    border-radius: 4px;
    padding: 1em;
  }

  .head {
    color: ${colors.grey3};
    font-weight: 600;
    margin-bottom: 1em;
    color: ${props => props.selected && colors.primary};
  }
  .text {
    font-size: 0.875em;
    margin-top: 1em;
  }

  .desc {
    font-size: 0.75em;
    color: ${colors.grey};
    margin-top: 1em;
  }

  &:hover {
    .head {
      color: ${colors.primary};
    }
  }
`

export default FinalPayment
