import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { isAfter, format, differenceInCalendarDays } from 'date-fns'
import axios from 'axios'
import { breakpoints, colors } from './../../../helpers/foundation'
import { toTitleCase } from './../../../utils'
import { getTotals } from './../../../helpers/totals'
import { addProductionUrl } from './../../../helpers/constants'

import vinylHero from './../../../assets/vinylfencing-hero.jpg'
import woodHero from './../../../assets/woodfencing-hero.jpg'
import aluminumHero from './../../../assets/aluminumfencing-hero.jpg'
import chainlinkHero from './../../../assets/chainlinkfencing-hero.jpg'

import Button from './../../sharedComponents/Button'
import OverviewContainer from './../../sharedComponents/OverviewContainer'
import StyledTable from './../../sharedComponents/StyledTable'
import Message from './../../sharedComponents/Message'
import Modal from './../../sharedComponents/Modal'
import Field from './../../sharedComponents/Field'
import Icon from './../../sharedComponents/Icon'
import SecondaryButton from './../../sharedComponents/SecondaryButton'
import Loader from './../../sharedComponents/Loader'

import paymentStep1 from './../assets/Payment-Step1.png'
import paymentStep2 from './../assets/Payment-Step2.png'
import paymentStep3 from './../assets/Payment-Step3.png'

const banners = {
  Vinyl: vinylHero,
  Wood: woodHero,
  Aluminum: aluminumHero,
  'Chain Link': chainlinkHero,
}

class CustomerEstimateApprove extends Component {
  state = {
    agree: false,
    error: null,
    revisionModalOpen: false,
    denialModalOpen: false,
    revisionNotes: '',
    denialNotes: '',
    uploading: false,
    pdfLoading: false,
    pdfError: null,
  }

  updateField = e => {
    const tarName = e.target.name,
      tarVal = e.target.value
    this.setState(prevState => {
      return {
        [tarName]: tarName === 'agree' ? !prevState.agree : tarVal,
      }
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => ({ [name]: value }))
  }

  submitApproval = () => {
    const { agree } = this.state
    const { estimate } = this.props.project
    if (agree) {
      console.log('EUS', this.props.estimateUpdateServer)
      this.props
        .estimateUpdateServer(
          estimate._id,
          {
            status: 'Pending Payment',
            from: 'Pending Approval',
            projectId: this.props.project._id,
          },
          true
        )
        .then(success => console.log('update success'))
    } else {
      this.setState({
        error: 'Please check the box above to continue.',
      })
    }
  }

  openRevisionModal = () => {
    this.setState({
      revisionModalOpen: true,
    })
  }

  openDenialModal = () => {
    this.setState({
      denialModalOpen: true,
    })
  }

  sendEstimateForRevisions = e => {
    e.preventDefault()

    const { estimate } = this.props.project

    if (this.state.revisionNotes !== '') {
      this.props.estimateUpdateServer(
        estimate._id,
        {
          status: 'To Revise',
          customerRevisionNotes: this.state.revisionNotes,
        },
        true
      )
    }
  }

  denyEstimate = e => {
    e.preventDefault()

    if (this.state.denialNotes !== '') {
      this.props.estimateUpdateServer(
        this.props.project.estimate._id,
        {
          customerDenialNotes: this.state.denialNotes,
        },
        true
      )

      this.props.customerUpdateProject(this.props.project._id, {
        archived: true,
      })
    }
  }

  closeModal = () => {
    this.setState({
      revisionModalOpen: false,
      denialModalOpen: false,
      revisionNotes: '',
      denialNotes: '',
    })
  }

  requestRenewEstimate = () => {
    this.props.customerRequestEstimateRenewal(this.props.project)
  }

  handleUpload = async e => {
    try {
      this.setState(prevState => ({ uploading: true }))
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('approvedEstimate', file, file.name)
      formData.append('projectId', this.props.project._id)

      await this.props.estimateUpdateServer(this.props.project.estimate._id, formData, false)
      this.setState(prevState => ({ uploading: false }))
    } catch (err) {
      console.log('error uploading file', err)
    }
  }

  downloadPDF = async () => {
    try {
      const { project, isEmployee } = this.props

      // show pdf loader
      this.setState({ pdfLoading: true })

      // url with either ?q=employee or ?q=customer
      const beUrl = `${addProductionUrl()}/api/pdfs/${project._id}?q=${
        isEmployee ? 'employee' : 'customer'
      }`

      const response = await axios.get(beUrl, {
        responseType: 'blob',
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.props.project.name}_estimate.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(link)

      // hide loader
      this.setState({ pdfLoading: false })
    } catch (err) {
      this.setState({ pdfError: 'Error', pdfLoading: false })
    }
  }

  choosePaymentTerms = () => {
    const { project } = this.props
    const totals = getTotals(
      [
        Number(project.estimate.parts.total),
        Number(project.estimate.labors.total),
        Number(project.estimate.services.total),
      ],
      0.07
    )

    if (project.paymentTerms === '100') {
      return (
        <OverviewContainer title="Payment Terms" presentational={true}>
          <div className="grid-items terms-grid extra-padding">
            <div className="desc">
              <div className="head">100</div>
              <div className="text">
                This project will be paid in full once the estimate is approved.
                <div className="disclaimer">
                  Note: The amounts will be slightly higher when paying with credit.
                </div>
              </div>
            </div>
            <div className="graphics">
              <div className="item">
                <img src={paymentStep1} alt="" />
                <div className="text">
                  <div className="head">Accept Estimate</div>
                  <div className="para">100% due</div>
                  <div className="para">
                    ($
                    {totals.cashTotal})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OverviewContainer>
      )
    } else if (project.paymentTerms === '50/50') {
      return (
        <OverviewContainer title="Payment Terms" presentational={true}>
          <div className="grid-items terms-grid extra-padding">
            <div className="desc">
              <div className="head">50/50</div>
              <div className="text">
                This project will be paid in pieces as it makes its way through our process. 50%
                will be due up front once you accept this quote. The final 50% will be due upon
                scheduling your installation.
                <div className="disclaimer">
                  Note: The amounts will be slightly higher when paying with credit.
                </div>
              </div>
            </div>
            <div className="graphics">
              <div className="item">
                <img src={paymentStep1} alt="" />
                <div className="text">
                  <div className="head">Accept Estimate</div>
                  <div className="para">50% due</div>
                  <div className="para">
                    ($
                    {(totals.cashTotal / 2).toFixed(2)})
                  </div>
                </div>
              </div>
              <div className="arrow">
                <Icon icon="arrowRight" />
              </div>
              <div className="item">
                <img src={paymentStep2} alt="" />
                <div className="text">
                  <div className="head">Schedule Installation</div>
                  <div className="para">50% due</div>
                  <div className="para">
                    ($
                    {(totals.cashTotal / 2).toFixed(2)})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OverviewContainer>
      )
    } else if (project.paymentTerms === '25/50/25') {
      return (
        <OverviewContainer title="Payment Terms" presentational={true}>
          <div className="grid-items terms-grid extra-padding">
            <div className="desc">
              <div className="head">25/50/25</div>
              <div className="text">
                This project will be paid in pieces as it makes its way through our process. 25%
                will be due up front once you accept this quote. 50% will be due upon scheduling
                your installation, and the remaining 25% will be due once the project is complete
                and approved.
                <div className="disclaimer">
                  Note: The amounts will be slightly higher when paying with credit.
                </div>
              </div>
            </div>
            <div className="graphics">
              <div className="item">
                <img src={paymentStep1} alt="" />
                <div className="text">
                  <div className="head">Accept Estimate</div>
                  <div className="para">25% due</div>
                  <div className="para">
                    ($
                    {(totals.cashTotal / 4).toFixed(2)})
                  </div>
                </div>
              </div>
              <div className="arrow">
                <Icon icon="arrowRight" />
              </div>
              <div className="item">
                <img src={paymentStep2} alt="" />
                <div className="text">
                  <div className="head">Schedule Installation</div>
                  <div className="para">50% due</div>
                  <div className="para">
                    ($
                    {(totals.cashTotal / 2).toFixed(2)})
                  </div>
                </div>
              </div>
              <div className="arrow">
                <Icon icon="arrowRight" />
              </div>
              <div className="item">
                <img src={paymentStep3} alt="" />
                <div className="text">
                  <div className="head">Complete Project</div>
                  <div className="para">25% due</div>
                  <div className="para">
                    ($
                    {(totals.cashTotal / 4).toFixed(2)})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OverviewContainer>
      )
    }
  }

  render() {
    const { project } = this.props
    const {
      revisionModalOpen,
      denialModalOpen,
      revisionNotes,
      denialNotes,
      pdfLoading,
      pdfError,
    } = this.state
    const { survey, estimate } = project

    const totals = getTotals(
      [
        Number(project.estimate.parts.total),
        Number(project.estimate.labors.total),
        Number(project.estimate.services.total),
      ],
      0.07
    )

    const isExpired = isAfter(new Date(), estimate.expirationDate)

    return (
      <StyledCustomerEstimateApprove
        banner={banners[project.survey.material.name]}
        blueprint={project.survey.blueprint}
        isCustomer={this.props.user.role === 'Customer'}
      >
        {revisionModalOpen && (
          <Modal width={420} close={this.closeModal} open={this.openRevisionModal}>
            <div className="modal-header">
              <div className="close">
                <Icon icon="close" handleClick={this.closeModal} />
              </div>
              <h3 className="title">
                We want to help. Please tell us why you want this estimate revised.
              </h3>
            </div>
            <form onSubmit={this.sendEstimateForRevisions}>
              <Field>
                <label htmlFor="reviseModalNotes">Revision Details</label>
                <textarea
                  name="revisionNotes"
                  value={revisionNotes}
                  onChange={this.handleChange}
                  required
                />
              </Field>
              <Button text="Send for Revisions" type="submit" variant="secondary" />
            </form>
          </Modal>
        )}

        {denialModalOpen && (
          <Modal width={420} close={this.closeModal} open={this.openDenialModal}>
            <div className="modal-header">
              <div className="close">
                <Icon icon="close" handleClick={this.closeModal} />
              </div>
              <h3 className="title">We're sad to see you go.</h3>
            </div>
            <form onSubmit={this.denyEstimate}>
              <Field>
                <label htmlFor="reviseModalNotes">
                  Why have you decided to cancel this project?
                </label>
                <textarea
                  name="denialNotes"
                  value={denialNotes}
                  onChange={this.handleChange}
                  required
                />
              </Field>
              <Button text="Cancel Project" variant="danger" type="submit" />
            </form>
          </Modal>
        )}

        {this.props.user.role === 'Customer' && (
          <div className="banner">
            <h1>
              <div>Your new </div>
              <div>{project.survey.material.name} Fence</div>
            </h1>
          </div>
        )}

        <div className="container">
          <div className="pdf-download">
            <Button text="Download PDF" handleClick={async e => await this.downloadPDF()} />
            {pdfLoading && <Loader />}
            {pdfError && <div className="error">{pdfError}</div>}
          </div>

          <div className="agreement-container">
            <OverviewContainer title="Your Fence Options" presentational={true}>
              <div className="grid-items">
                {[project.survey.style, ...project.survey.options].map((opt, idx) => {
                  return (
                    <div key={idx} className="grid-item">
                      <img src={opt.image} alt="" />
                      <div className="name">{toTitleCase(opt.name)}</div>
                    </div>
                  )
                })}
              </div>
            </OverviewContainer>

            <OverviewContainer title="Site Blueprint" presentational={true}>
              <div className="grid-items blueprint-grid">
                <img src={project.estimate.officalBlueprint} alt="" />
              </div>
            </OverviewContainer>

            <OverviewContainer title="Project Details" presentational={true}>
              <StyledTable>
                <thead />
                <tbody>
                  {Object.keys(survey.additionalDetails).map(detailKey => {
                    const detail = survey.additionalDetails[detailKey]
                    return (
                      <tr key={detailKey}>
                        <td>{detail.question}</td>
                        <td>{detail.answer}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </StyledTable>
            </OverviewContainer>

            <OverviewContainer title="Total" presentational={true}>
              <div className="grid-items totals-grid">
                <div className="amounts">
                  <div className="amount">${totals.cashTotal}</div>
                  <div className="amount">${totals.creditTotal}</div>
                </div>
                <div className="fields">
                  <div className="field">Cash Total</div>
                  <div className="field">Credit Total</div>
                </div>
              </div>
            </OverviewContainer>

            {/* decide which payment graphic to show */}
            {this.choosePaymentTerms()}

            {project.estimate.status === 'Pending Approval' && (
              <React.Fragment>
                {isExpired ? (
                  <React.Fragment>
                    {estimate.expirationDateExtensionRequested ? (
                      <div>
                        Your request has been sent. You will recieve an email containing weather or
                        not we have accepted your request.
                      </div>
                    ) : (
                      <div className="expired">
                        <div className="point">
                          <b>
                            Your estimate has expired on{' '}
                            {format(estimate.expirationDate, 'MM/DD/YY [at] hh:mm a')}.
                          </b>
                          <div className="text">
                            If you would like to request your estimate be renewed, click the link
                            below.
                          </div>
                          <br />
                          <Button
                            text="Renew Estimate"
                            variant="nm"
                            handleClick={this.requestRenewEstimate}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div className="accept">
                    <div className="point">
                      <b>Warranty</b>
                      <div>5-year workmanship warranty. Lifetime product warranty.</div>
                    </div>
                    <div className="point">
                      <b>Payment Terms</b>
                      {project.paymentTerms === '100' && <div>100% up-front.</div>}
                      {project.paymentTerms === '50/50' && (
                        <div>50% up-front deposit &amp; 50% before installation.</div>
                      )}
                      {project.paymentTerms === '25/50/25' && (
                        <div>
                          25% up-front deposit &amp; 50% before installation. &amp; 25% upon
                          completion.
                        </div>
                      )}
                      <div />
                    </div>
                    <div className="point">
                      <b>Estimate Expiration</b>
                      <div>
                        This estimate expires after{' '}
                        {differenceInCalendarDays(project.estimate.expirationDate, new Date())}
                        -days.
                      </div>
                    </div>

                    {this.props.user.role === 'Customer' && (
                      <Fragment>
                        <div className="move-left">
                          <div className="point">
                            <input
                              type="checkbox"
                              name="agree"
                              id="agree"
                              value={this.state.agree}
                              onChange={this.updateField}
                            />
                            <label htmlFor="agree">
                              <div className="text">
                                I acknowledge that the products and services outlined in this
                                estimate are correct and that I have read and understand the terms
                                and conditions.
                              </div>
                            </label>
                          </div>
                        </div>

                        {this.state.error && <Message message={this.state.error} type="error" />}

                        <div className="point">
                          <Button
                            text="Approve Estimate"
                            variant={`lg ${this.state.agree ? '' : 'disabled'}`}
                            fluid={true}
                            handleClick={this.submitApproval}
                          />

                          <div className="less-apparent">
                            <b>Or...</b>
                            <div className="link-btn" onClick={this.openRevisionModal}>
                              Revise Estimate
                            </div>
                            <div className="link-btn" onClick={this.openDenialModal}>
                              Deny Estimate
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {this.props.user.role !== 'Customer' && (
                      <div className="paperwork-signed">
                        <b>Has paperwork been signed?</b>

                        <div className="upload">
                          <SecondaryButton tag="label">
                            <Icon icon="upload" color="grey3" />
                            <span>
                              {this.props.estimate.approvedEstimate.name &&
                              this.props.estimate.approvedEstimate.path
                                ? 'Replace'
                                : 'Upload'}
                            </span>
                            <input type="file" name="upload" onChange={this.handleUpload} />{' '}
                          </SecondaryButton>
                          <span>
                            <a href={this.props.estimate.approvedEstimate.path}>
                              {this.props.estimate.approvedEstimate.name}
                            </a>
                          </span>
                        </div>

                        {this.state.uploading && <Loader />}

                        <Button
                          text="Mark Approved"
                          // probably need to check if they have correct roles here
                          variant={`lg`}
                          fluid={true}
                          handleClick={this.props.markApproved}
                        />
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </StyledCustomerEstimateApprove>
    )
  }
}

export default CustomerEstimateApprove

const StyledCustomerEstimateApprove = styled.div`
  padding-bottom: 8em;
  clear: both;

  .banner {
    position: relative;
    height: 38em;
    background-image: url(${props => props.banner});
    background-size: cover;
    color: #fff;

    @media screen and (max-width: ${breakpoints.medium}px) {
      height: 28em;
    }

    h1 {
      position: absolute;
      bottom: 0.5em;
      left: 0.5em;
      text-transform: uppercase;
      font-size: 5em;
      margin: 0;
      padding: 0;
      line-height: 1;

      @media screen and (max-width: ${breakpoints.medium}px) {
        font-size: 2.5em;
      }
    }
  }

  .container {
    ${props => {
      if (props.isCustomer) {
        return `
          padding: 1.25em 2.5em;

          @media screen and (max-width: ${breakpoints.medium}px) {
            padding: 0.75em 1.25em;
          }
        `
      }
    }}
  }

  .grid-items {
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
    margin: 0 -0.5em;

    &.blueprint-grid {
      padding: 0;
      margin: 0;
      flex-wrap: nowrap;

      @media screen and (max-width: ${breakpoints.large}px) {
        display: block;
      }
    }

    &.totals-grid {
      flex-flow: row-reverse;
      padding: 1em;
      margin: 0;
    }

    &.terms-grid {
      padding: 2em;
      display: flex;
      justify-content: space-between;

      .desc {
        padding: 1em 2em;
        width: 26.25em;
        .head {
          font-size: 1.25em;
          margin-bottom: 0.5em;
          font-weight: 600;
        }
        .text {
          font-size: 14px;
          color: ${colors.grey2};
        }
      }

      .graphics {
        display: flex;
        padding-right: 3em;
        margin-top: 0.5em;

        .item {
          text-align: center;
          img {
            width: 6.125em;
            height: 6.125em;
          }
          .text {
            .head {
              color: ${colors.grey3};
              font-weight: 600;
            }
            .para {
              font-size: 0.75em;
              color: ${colors.grey2};
              font-family: 'source-code-pro';
            }
          }
        }

        .arrow {
          margin: 2em 3em 0 3em;
        }
      }

      @media screen and (max-width: ${breakpoints.large}px) {
        .desc {
          margin: 0 auto;
        }

        .graphics {
          display: block;
          margin: 0 auto;

          .item {
            margin: 2em auto;
          }

          .arrow {
            display: none;
          }
        }
      }
    }
  }

  .grid-item {
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    flex: 0 0 calc(100% - 1em);
    border: 1px solid #e9ecef;
    border-radius: 4px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .name {
      font-size: 0.85em;
      padding: 4px;
      font-weight: 600;
      border-top: 1px solid #e9ecef;
    }

    @media screen and (min-width: ${breakpoints.large}px) {
      flex: 0 0 calc(16.6666666667% - 1em);
    }
    @media screen and (max-width: ${breakpoints.large}px) and (min-width: ${breakpoints.medium}px) {
      flex: 0 0 calc(25% - 1em);
    }
    @media screen and (max-width: ${breakpoints.medium}px) and (min-width: ${breakpoints.small}px) {
      flex: 0 0 calc(50% - 1em);
    }
    @media screen and (max-width: ${breakpoints.small}px) {
      flex: 0 0 calc(100% - 1em);
    }
  }

  .blueprint-container {
    width: 100%;
    max-width: ${props => `${props.blueprint.width}px`};
    height: ${props => `${props.blueprint.height}px`};
  }

  .comments-container {
    /* flex-basis: 32em; */
    flex-grow: 1;
    padding: 0.5em;
    overflow-y: scroll;

    @media screen and (max-width: ${breakpoints.large}px) {
      min-height: 16em;
    }
  }

  .fields {
    text-align: left;
  }

  .terms {
    color: ${colors.grey2};
    font-size: 13px;
    padding: 1rem;
  }

  .amounts {
    margin-left: 5em;
    text-align: right;
    font-family: 'source-code-pro';
  }

  .fields .field,
  .amounts .amount {
    margin-bottom: 0.5em;
  }

  .accept {
    position: relative;
    width: 100%;
    max-width: 19.2em;
    margin-bottom: 8em;
    float: right;
    font-size: 14px;

    .point {
      margin-bottom: 1.5em;
      b {
        margin-bottom: 5px;
      }
      div {
        color: #868e96;
      }
    }

    .move-left {
      position: relative;
      left: -2em;
    }

    input[type='checkbox'] {
      vertical-align: top;

      & + label {
        display: inline-block;
        margin-left: 2em;
        margin-top: -1.9em;
        max-width: 32em;
      }
    }
  }

  .expired {
    a {
      font-size: 20px;
    }
  }

  .modal-header {
    .close {
      text-align: right;
    }
  }

  .less-apparent {
    margin-top: 1em;
  }

  .link-btn {
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    margin: 0.25em 0;
  }

  .paperwork-signed {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${colors.lightGrey};
    .upload {
      margin: 1em 0 2em;
    }

    .upload {
      position: relative;
      > span {
        position: relative;
        top: -3px;
        margin-left: 1em;
      }
    }
  }

  .disclaimer {
    margin-top: 1em;
    font-weight: 600;
  }
`
