import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../helpers/foundation'
import { isUserSurveyor } from '../../../../helpers/auth'

import Icon from './../../../sharedComponents/Icon'

const TableRow = ({ profileImage, displayName, title, teams, calendar, ...props }) => {
  return (
    <StyledTableRow
      teamName={teams.length > 0 ? teams[0].name : null}
      userImageBorder={props.listView}
      multiRow={teams.length > 1}
    >
      <td className="user-name-image">
        <img src={profileImage ? profileImage : '//placehold.it/60x60'} alt="" />
        <span>{displayName}</span>
      </td>
      <td className="user-title">{title ? title : 'No title provided.'}</td>
      {props.listView && (
        <td className="user-team">
          {teams.map((team, idx) => (
            <div key={idx} className="block">
              {team.name}
            </div>
          ))}
        </td>
      )}
      <td className="user-role">
        {teams.map((team, idx) => (
          <div key={idx} className="block">
            {team.role}
          </div>
        ))}
      </td>

      <td className="user-calendar">
        {isUserSurveyor(teams) ? (calendar.connected ? 'Yes' : 'No') : null}
      </td>

      {props.canEdit ? (
        <td className="user-options">
          <Icon icon="settings" handleClick={props.optionsClick} />
        </td>
      ) : (
        <td className="user-options" />
      )}
    </StyledTableRow>
  )
}

const StyledTableRow = styled.tr`
  vertical-align: ${props => (props.multiRow ? 'top' : 'middle')};

  .user-name-image {
    position: relative;
    img {
      width: 2.375em;
      height: 2.375em;
      border-radius: 50%;
      border: ${props => {
        if (props.teamName === 'Admin' && props.userImageBorder)
          return `3px solid ${colors.primary}`
        if (props.teamName === 'Survey' && props.userImageBorder) return `3px solid ${colors.blue}`
        if (props.teamName === 'Estimate' && props.userImageBorder)
          return `3px solid ${colors.purple}`
        if (props.teamName === 'CustomerService' && props.userImageBorder)
          return `3px solid ${colors.pink}`
        return `3px solid ${colors.grey}`
      }};
    }

    span {
      position: relative;
      vertical-align: top;
      top: ${props => (props.multiRow ? '0' : '0.7em')};
      font-weight: 600;
      margin-left: 1.5em;
    }
  }

  .user-title {
    padding: auto 0;
  }

  .user-team,
  .user-role {
    .block {
      margin-bottom: 0.5em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .user-options {
    position: relative;
    /* right: 10px; */
    width: 2em;
    text-align: right;
    svg {
      cursor: pointer;
    }
  }

  .v-align-top {
    vertical-align: top;
  }
`

export default TableRow
