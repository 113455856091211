import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { colors } from './../../helpers/foundation'
import { addProductionUrl } from './../../helpers/constants'

import Icon from './Icon'
import ofsThumb from './../../assets/ofs-thumb.jpg'

class Checkout extends Component {
  _stripeHandler = null
  _isMounted = false

  state = {
    loading: true,
    stripeLoading: false,
  }

  componentDidMount = () => {
    this._isMounted = true
    // this will wait for stripe script to be loaded
    this.loadStripe(() => {
      // once the stripe script is loaded, configure stripe
      this.configureStripe()
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    if (this._stripeHandler) {
      this._stripeHandler.close()
      // this._stripeHandler = null
      // probably want to remove stripe script from the DOM here
    }
  }

  // wait for the stripe script to be loaded
  loadStripe = onload => {
    if (this._isMounted) {
      if (!window.StripeCheckout) {
        const script = document.createElement('script')
        script.onload = function() {
          console.info('Stripe script loaded')
          onload()
        }
        script.src = 'https://checkout.stripe.com/checkout.js'
        document.head.appendChild(script)
      } else {
        onload()
      }
    }
  }

  configureStripe = () => {
    // configure stripe, to be .open() 'd
    this._stripeHandler = window.StripeCheckout.configure({
      key: process.env.REACT_APP_STRIPE_KEY_TEST,
      image: 'https://s3.us-east-2.amazonaws.com/osceolafencesupply/appAssets/ofs-thumb.jpg',
      locale: 'auto',
      token: async token => {
        // this is where the server request happens
        try {
          this.props.updatePaymentLoading(true)
          const response = await axios.post(
            `${addProductionUrl()}/api/projects/${this.props.projectId}/payment`,
            {
              description: this.props.description,
              source: token.id,
              currency: 'USD',
              amount: Math.round(this.props.amount * 100),
              email: this.props.customerEmail,
              paymentOrder: this.props.paymentOrder,
              from: 'Pending Payment',
              projectId: this.props.projectId,
            }
          )
          this.props.updatePaymentLoading(false)
          this.props.updatePaymentMessage({ type: 'success', message: 'Payment success.' })
          // stall for visual effect
          setTimeout(() => {
            // handle updating estimate if its in estimate stage
            if (this.props.isEstimate) {
              this.props
                .stageUpdate(
                  this.props.estimateId,
                  {
                    from: 'Pending Payment',
                    status: 'Completed',
                    paymentAmount: this.props.amount,
                    paymentMethod: 'credit',
                    projectId: this.props.projectId,
                  },
                  true
                )
                .then(success => {
                  return true
                })
            } else {
              // handle updating the installation if in installation stage
              this.props.stageUpdate(
                this.props.installationId,
                {
                  installationStatus:
                    this.props.installationStatus === 'Installation First Payment'
                      ? 'Installation Prep'
                      : 'Completed',
                  paymentAmount: this.props.amount,
                  paymentMethod: 'credit',
                  projectId: this.props.projectId,
                  ...(this.props.installationStatus === 'Final Payment' && {
                    from: 'Final Payment',
                  }),
                },
                this.props.projectId,
                true
              )
            }
          }, 2000)
        } catch (err) {
          console.log('there was an err', err)
          this.props.updatePaymentLoading(false)
          this.props.updatePaymentMessage({
            type: 'error',
            message: 'There was an error. Please give us a call.',
          })
        }
      },
    })
  }

  onStripeUpdate = e => {
    // this is what will be loaded in the Checkout panel
    this._stripeHandler.open({
      name: 'Osceola Fence Supply',
      amount: this.props.amount * 100,
      description: this.props.description,
      email: this.props.customerEmail,
      panelLabel: 'Pay',
      allowRememberMe: false,
    })
    e.preventDefault()
  }

  render() {
    const { stripeLoading, loading } = this.state
    return (
      <PaymentCard
        onClick={e => {
          if (this.props.paymentOrder === 0) {
            this.props.updatePaymentType('credit')
          }
          this.onStripeUpdate(e)
        }}
        selected={this.props.selected === 'credit'}
      >
        <div className="inner">
          <div className="head">Credit</div>
          <Icon icon="creditCard" />
          <div className="text">Processing fee and tax will be added.</div>
        </div>
      </PaymentCard>
    )
  }
}

const StyledCheckout = styled.div``

const PaymentCard = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 12em;
  margin-right: 1em;
  text-align: center;
  vertical-align: top;

  .inner {
    background-color: #fff;
    border: ${props =>
      props.selected ? `1px solid ${colors.primary}` : `1px solid ${colors.grey6}`};
    border-radius: 4px;
    padding: 1em;
  }

  .head {
    color: ${colors.grey3};
    font-weight: 600;
    margin-bottom: 1em;
    color: ${props => props.selected && colors.primary};
  }
  .text {
    font-size: 0.875em;
    margin-top: 1em;
  }

  .desc {
    font-size: 0.75em;
    color: ${colors.grey};
    margin-top: 1em;
  }

  &:hover {
    .head {
      color: ${colors.primary};
    }
  }

  .Button {
  }
`

export default Checkout
