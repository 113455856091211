import React from 'react'
import styled from 'styled-components'
import { colors } from './../../../../helpers/foundation'

const Progress = ({ steps, currentStep }) => {
  const Dot = styled.div`
    display: inline-block;
    height: 11px;
    width: 11px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${props =>
      currentStep === props.idx ? colors.grey : colors.lightGrey};
  `

  var dots = []
  for (var i = 1; i <= steps; i++) {
    dots.push(<Dot idx={i} key={i} />)
  }

  return <div>{dots}</div>
}

export default Progress
