import { format } from 'date-fns'

const organizeTimes = (slots, day) => {
  const slotsByDay = slots.filter(slot => {
    return format(slot.start.dateTime, 'dddd') === day
  })

  const sortedTimes = slotsByDay.sort((a, b) => {
    return a.dt - b.dt || new Date(a.start.dateTime) - new Date(b.start.dateTime)
  })

  return sortedTimes
}

const removeSlot = (slots, slotToBeRemoved) => {
  const updatedSlots = slots.filter(slot => {
    return slot.id !== slotToBeRemoved.id
  })

  return updatedSlots
}

export default {
  organizeTimes,
  removeSlot,
}
