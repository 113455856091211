import React from 'react'
import { format } from 'date-fns'

const map = {
  SURVEY_SCHEDULED: action => (
    <>
      The survey has been scheduled for {action.info.surveyor} on{' '}
      {format(action.info.start, 'MM/DD/YY')} from {format(action.info.start, 'hh:mma')} -{' '}
      {format(action.info.end, 'hh:mma')}.
    </>
  ),
  SURVEY_START: action => <>{action.user.displayName} has started the survey.</>,
  SURVEY_COMPLETE: action => (
    <>{action.user.displayName} has completed the survey. It is now pending review.</>
  ),
  SURVEY_REVIEWED: action => <>Survey has been reviewed and Estimate is ready to begin.</>,
  SURVEY_REVISE: action => <>There were issues with they survey, revisions are to be made.</>,
  ESTIMATE_START: action => <>Estimate started.</>,
  ESTIMATE_TO_REVIEW: action => <>Estimate has been completed and is pending review.</>,
  ESTIMATE_REVISE: action => <>There were issues with they estimate, revisions are to be made.</>,
  ESTIMATE_PENDING_APPROVAL: action => <>Estimate has been reviewed and is pending approval.</>,
  ESTIMATE_PENDING_PAYMENT: action => <>Estimate has been approved and is pending payment.</>,
  ESTIMATE_COMPLETE: action => (
    <>
      {action.info && action.info.amount ? (
        <>
          Estimate has been completed. Customer paid a total of {action.info.amount} with{' '}
          {action.info.method}.
        </>
      ) : (
        <>Estimate has been completed.</>
      )}
    </>
  ),
  PAPERWORK_PERMITS_SENT: action => <>Permit files have been sent.</>,
  PAPERWORK_PERMITS_APPROVED: action => <>Permit files have been approved.</>,
  PAPERWORK_HOA_SENT: action => <>HOA files have been sent.</>,
  PAPERWORK_HOA_APPROVED: action => <>HOA files have been approved.</>,
  INSTALLATION_SCHEDULED: action => (
    <>
      <div>Installation dates have been scheduled.</div>
      <ul>
        <li>Water Locates Date: {format(action.info.waterDate, 'MM/DD/YY @ hh:mma')}.</li>
        <li>Electric Locates Date: {format(action.info.electricDate, 'MM/DD/YY @ hh:mma')}.</li>
        <li>Gas Locates Date: {format(action.info.gasDate, 'MM/DD/YY @ hh:mma')}.</li>
        <li>Installation Date: {format(action.info.installationDate, 'MM/DD/YY @ hh:mma')}.</li>
      </ul>
    </>
  ),
  INSTALLATION_FIRST_PAYMENT: action => (
    <>
      Pre-installation payment has been made for ${action.info.amount}. Installation materials need
      to be prepped.
    </>
  ),
  INSTALLATION_PREPPED: action => (
    <>Installation material have been prepped. Installation is pending start.</>
  ),
  INSTALLATION_STARTED: action => <>Installation started.</>,
  INSTALLATION_FINISHED: action => <>Installation has been completed.</>,
  INSTALLATION_INSPECTION_SCHEDULED: action => (
    <>Inspection scheduled for {format(action.info.inspectionDate, 'MM/DD/YY')}.</>
  ),
  INSTALLATION_INSPECTION_APPROVED: action => <>Inspection has passed.</>,
  INSTALLATION_INSPECTION_DENIED: action => <>Inspection has failed.</>,
  INSTALLATION_SECOND_PAYMENT: action => (
    <>
      Final payment has been made. Customer paid a total of {action.info.amount} with{' '}
      {action.info.method}.
    </>
  ),
}

export default map
