import axios from 'axios'
import { customerAddProject } from './customer'
import { addProductionUrl } from '../../helpers/constants'

export const ATTEMPT_CREATE_PROJECT = 'app/createProjectForm/ATTEMPT_CREACT_PROJECT'
export const FAILED = 'app/createProjectForm/FAILED'
export const SUCCESS = 'app/createProjectForm/SUCCESS'
export const RESET = 'app/createProjectForm/RESET'

const initialState = {
  error: null,
  message: null,
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ATTEMPT_CREATE_PROJECT:
      return {
        ...state,
        loading: true,
        message: null,
        error: null,
      }
    case SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        error: null,
      }
    case FAILED:
      return {
        ...state,
        loading: false,
        message: null,
        error: action.error,
      }
    case RESET:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export function attemptProjectCreation() {
  return {
    type: ATTEMPT_CREATE_PROJECT,
  }
}

export function projectCreationFailed(error) {
  return {
    type: FAILED,
    error,
  }
}

export function projectCreated() {
  return {
    type: SUCCESS,
  }
}

export function reset() {
  return {
    type: RESET,
  }
}

export function createProject(projectDetails, customerInfo) {
  return dispatch => {
    dispatch(attemptProjectCreation())

    const { _id } = customerInfo

    axios
      .post(`${addProductionUrl()}/api/projects`, {
        additionalClientInfo: {
          ...projectDetails,
        },
        customer: _id,
      })
      .then(res => {
        dispatch(projectCreated())

        // Fire action in customer redux module
        dispatch(customerAddProject(res.data))
      })
      .catch(error => {
        dispatch(projectCreationFailed(error.response.data.message))
      })
  }
}
