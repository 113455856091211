import React, { Component } from 'react'
import axios from 'axios'
import qs from 'qs'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
import Button from '../../sharedComponents/Button'
import TextLink from '../../sharedComponents/TextLink'
import Split from '../../sharedComponents/Split'
import Icon from '../../sharedComponents/Icon'
import Message from '../../sharedComponents/Message'
import Field from '../../sharedComponents/Field'
import Modal from '../../sharedComponents/Modal'
import { addProductionUrl } from '../../../helpers/constants'

class ConnectGoogleCalendar extends Component {
  constructor() {
    super()

    this.state = {
      calendars: [],
      selectedCalendars: [],
      error: null,
      loadingCalendars: false,
      disconnectingCalendar: false,
    }
  }

  componentDidMount() {
    const { user, userUpdated, location, history } = this.props
    const code = qs.parse(location.search.slice(1))['code'] || null

    if (code && !user.googleCalendar.connected) {
      axios
        .post(`${addProductionUrl()}/api/users/${user._id}/calendars/link`, {
          code,
        })
        .then(res => {
          userUpdated(res.data)
        })
        .catch(err => {
          this.setState({
            error: 'Invalid Code',
          })

          history.push({
            location: '/settings',
            search: '',
          })
        })
    }

    if (user.googleCalendar.connected) {
      if (code) {
        history.push({
          location: '/settings',
          search: '',
        })
      }

      this.getCalendars()
    }
  }

  cleanCalendarData = calendars => {
    return calendars.map(({ summary, id }) => {
      return {
        label: summary,
        value: id,
      }
    })
  }

  getCalendars = () => {
    const { user } = this.props

    this.setState({
      loadingCalendars: true,
    })

    axios
      .get(`${addProductionUrl()}/api/users/${user._id}/calendars`)
      .then(res => {
        const selectedCalendars = this.cleanCalendarData(res.data).filter(calendar => {
          return user.googleCalendar.calendars.indexOf(calendar.value) > -1
        })

        this.setState({
          calendars: this.cleanCalendarData(res.data),
          selectedCalendars,
          loadingCalendars: false,
        })
      })
      .catch(err => {
        this.setState({
          error: err.response.data.message,
          loadingCalendars: false,
        })
      })
  }

  linkCalendar = () => {
    // Link google calendar to this user account
    axios
      .post(`${addProductionUrl()}/api/users/${this.props.user._id}/calendars/link`)
      .then(res => {
        if (res.data) {
          window.location = res.data
        }
      })
      .catch(err => {
        this.setState({
          error: err.response.data.message,
        })
      })
  }

  unlinkCalendar = () => {
    axios
      .post(`${addProductionUrl()}/api/users/${this.props.user._id}/calendars/unlink`)
      .then(res => {
        this.props.userUpdated(res.data)
      })
      .catch(err => {
        this.setState({
          error: err.response.data.message,
        })
      })
  }

  handleChange = calendars => {
    const { user } = this.props

    this.setState({
      calendarSaved: false,
    })

    axios
      .post(`${addProductionUrl()}/api/users/${user._id}/calendars`, {
        calendars: calendars.map(calendar => {
          return calendar.value
        }),
      })
      .then(res => {
        const selectedCalendars = this.state.calendars.filter(calendar => {
          return res.data.indexOf(calendar.value) > -1
        })

        this.setState({
          calendarSaved: true,
          selectedCalendars,
        })
      })
      .catch(err => {
        this.setState({
          error: err.response.data.message,
        })
      })
  }

  closeDisconnectCalendarModal = () => {
    this.setState({
      disconnectingCalendar: false,
    })
  }

  openDisconnectCalendarModal = e => {
    e.preventDefault()

    this.setState({
      disconnectingCalendar: true,
    })
  }

  render() {
    const { user } = this.props
    const {
      calendars,
      error,
      loadingCalendars,
      calendarSaved,
      selectedCalendars,
      disconnectingCalendar,
    } = this.state

    return (
      <div>
        {error && <Message message={error} type="error" />}
        {!user.googleCalendar.connected ? (
          <React.Fragment>
            <Button text="Connect Google Calendar" handleClick={this.linkCalendar} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {disconnectingCalendar && (
              <Modal centered={true} width={600} close={this.closeDisconnectCalendarModal}>
                <h3 className="title">
                  Are you sure you want to unlink your google account? Your calendars will no longer
                  be connected to the scheduler.
                </h3>
                <div>
                  <Button
                    text="Stay connected"
                    variant="primary"
                    handleClick={this.closeDisconnectCalendarModal}
                  />
                  <Button
                    text="Disconnect all my calendars"
                    variant="danger"
                    handleClick={this.unlinkCalendar}
                  />
                </div>
              </Modal>
            )}
            <div className="mbm">
              <Split>
                <div className="small">
                  <Icon icon="solidCheck" color="primary" /> Google Calendar -{' '}
                  <span>{user.googleCalendar.email}</span>
                </div>
                <TextLink
                  href="#"
                  color="danger"
                  onClick={e => this.openDisconnectCalendarModal(e)}
                >
                  Disconnect
                </TextLink>
              </Split>
            </div>

            <Field invalid={selectedCalendars.length < 1}>
              <label>Calendars</label>
              <Select
                options={calendars}
                className="rs-field"
                isMulti={true}
                isClearable={false}
                isLoading={loadingCalendars}
                onChange={this.handleChange}
                value={selectedCalendars}
                placeholder="Select Calendars..."
              />
              <span className="error">Please connect at least 1 calendar.</span>
            </Field>

            {calendarSaved && <Message message="Calendars Saved" type="success" />}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withRouter(ConnectGoogleCalendar)
