import {
  loading as _loading,
  updateInstallation as _updateInstallation,
  updateNestedInstallation as _updateNestedInstallation,
  addPhotoComment as _addPhotoComment,
  updatePostOps as _updatePostOps,
  addInspectionRemark as _addInspectionRemark,
  addInspectionFile as _addInspectionFile,
  fetchInstallation as _fetchInstallation,
  fetchPostOps as _fetchPostOps,
  inspectionFileLoading as _inspectionFileLoading,
} from './actions'
import { updateStageStatus } from './../project/actions'
import {
  customerUpdateProjectInstallation as _customerUpdateInstallation,
  customerUpdateProject as _customerUpdateProject,
} from './../customer/'
import axios from 'axios'
import { addProductionUrl } from './../../../helpers/constants'
import { dataURLToBlob } from '../../../helpers/images'
import shortid from 'shortid'
import { isImage } from './../../../utils'

export const fetchInstallation = installationId => {
  return async dispatch => {
    dispatch(_loading(true))
    try {
      const response = await axios.get(`${addProductionUrl()}/api/installations/${installationId}`)

      const {
        inspectionDate,
        inspectionStatus,
        inspectionRemarks,
        inspectionFiles,
        ...installationData
      } = response.data

      dispatch(_fetchInstallation(installationData))
      dispatch(
        _fetchPostOps({ inspectionDate, inspectionStatus, inspectionRemarks, inspectionFiles })
      )
      dispatch(_loading(false))
      return true
    } catch (err) {
      console.log('got err', err)
    }
  }
}

export const updateInstallation = (
  installationId,
  updates,
  projectId = null,
  isCustomer = false
) => {
  return async dispatch => {
    try {
      const lastStatus = updates.lastStatus || null
      const from = updates.from || null
      const datesToRemove = updates.datesToRemove || null
      const projectId = updates.projectId || null
      const paymentAmount = updates.paymentAmount || null
      const paymentMethod = updates.paymentMethod || null
      delete updates.from
      delete updates.datesToRemove
      delete updates.projectId
      delete updates.paymentAmount
      delete updates.paymentMethod
      dispatch(_updateInstallation(installationId, updates))
      if ('installationStatus' in updates) {
        if (!isCustomer) {
          dispatch(updateStageStatus('installation', updates.installationStatus))
        }
      }

      // update project for customer, if from customer view
      if (isCustomer) {
        dispatch(_customerUpdateInstallation(projectId, updates))
      }

      const response = await axios.patch(
        `${addProductionUrl()}/api/installations/${installationId}`,
        {
          ...updates,
          // conditionally add props to request
          ...(lastStatus && { lastStatus }),
          ...(from && { from }),
          ...(datesToRemove && { datesToRemove }),
          ...(projectId && { projectId }),
          ...(paymentAmount && { paymentAmount }),
          ...(paymentMethod && { paymentMethod }),
        }
      )
    } catch (err) {
      console.log('got err', err)
    }
  }
}

// for installPrep, adding a photo in InProgress
export const updateNestedInstallation = (projectId, installationId, field, itemId, updates) => {
  return async dispatch => {
    try {
      let toUpdate
      // update instantly on frontend
      dispatch(_updateNestedInstallation(installationId, field, itemId, updates))

      if ('image' in updates) {
        toUpdate = new FormData()
        toUpdate.append('type', 'directionImage')
        toUpdate.append('projectId', projectId)
        toUpdate.append('field', `${field[0]}.${field[1]}`)
        toUpdate.append('image', dataURLToBlob(updates.image), updates.imageName)
        toUpdate.append('status', updates.status)
      } else {
        console.log('only updating status?', updates.status, field)
        toUpdate = { ...updates, field: `${field[0]}.${field[1]}`, type: 'updateStatus' }
        console.log('TO UPDATE FOR STATUS: ', toUpdate)
      }

      await axios.patch(`${addProductionUrl()}/api/installations/${installationId}`, toUpdate, {
        headers: {
          enctype: 'multipart/form-data',
        },
      })

      return true
    } catch (err) {
      console.log('got err', err)
    }
  }
}

export const addPhotoComment = (installationId, direction, photoId, comment) => {
  return async dispatch => {
    try {
      let toUpdate
      dispatch(_addPhotoComment(installationId, direction, photoId, comment))

      toUpdate = { type: 'addPhotoComment', field: `photos.${direction}`, photoId, comment }
      await axios.patch(`${addProductionUrl()}/api/installations/${installationId}`, toUpdate, {
        headers: {
          enctype: 'multipart/form-data',
        },
      })
    } catch (err) {
      console.log('got err', err)
    }
  }
}

export const updatePostOps = (installationId, updates) => {
  return async dispatch => {
    const projectId = updates.projectId || null
    const lastStatus = updates.lastStatus || null
    const from = updates.from || null
    delete updates.projectId
    delete updates.lastStatus
    delete updates.from
    try {
      dispatch(_updatePostOps(installationId, updates))

      await axios.patch(`${addProductionUrl()}/api/installations/${installationId}`, {
        ...updates,
        // conditionally add props to request
        ...(projectId && { projectId }),
        ...(lastStatus && { lastStatus }),
        ...(from && { from }),
      })
    } catch (err) {
      console.log('got err', err)
    }
  }
}

export const addInspectionRemark = (installationId, remark) => {
  return async dispatch => {
    try {
      let toUpdate
      dispatch(_addInspectionRemark(installationId, remark))

      toUpdate = { type: 'addInspectionRemark', remark: { _id: shortid.generate(), ...remark } }
      await axios.patch(`${addProductionUrl()}/api/installations/${installationId}`, toUpdate)
    } catch (err) {
      console.log('got err', err)
    }
  }
}

export const addInspectionFile = (projectId, installationId, fileObj) => {
  return async dispatch => {
    try {
      let toUpdate
      if (isImage(updateInstallation.filepath)) {
        dispatch(_addInspectionFile(installationId, fileObj))
      } else {
        // console.log('uhhh i should be running?')
        dispatch(_inspectionFileLoading(true))
      }

      toUpdate = new FormData()
      toUpdate.append('type', 'addInspectionAttachment')
      toUpdate.append('projectId', projectId)
      // not always image, expecting the file to be 'image' on backend
      toUpdate.append('image', dataURLToBlob(fileObj.filepath), fileObj.name)
      toUpdate.append('imageName', fileObj.name)
      // generate _id here so it can be referenced if it is not an image to upd state
      const genId = shortid.generate()
      toUpdate.append('fileId', genId)

      const response = await axios.patch(
        `${addProductionUrl()}/api/installations/${installationId}`,
        toUpdate,
        {
          headers: {
            enctype: 'multipart/form-data',
          },
        }
      )

      // if it is not an image, we want the state to be updated after the file was uploaded to s3
      if (!isImage(updateInstallation.filepath)) {
        const addedAttachment =
          response.data.inspectionFiles[
            response.data.inspectionFiles.findIndex(file => file._id === genId)
          ]
        dispatch(
          _addInspectionFile(installationId, {
            name: addedAttachment.name,
            filepath: addedAttachment.filepath,
            date: fileObj.date,
          })
        )
        dispatch(_inspectionFileLoading(false))
      }
    } catch (err) {
      console.log('got err', err)
    }
  }
}

// doesnt do anything to state but figured it could be extracted out of the component logic
export const submitFeedback = (projectId, data) => {
  return async dispatch => {
    try {
      console.log('about to submit?', projectId, data)
      const response = await axios.post(
        `${addProductionUrl()}/api/projects/${projectId}/feedback`,
        data,
        {
          headers: {
            enctype: 'multipart/form-data',
          },
        }
      )

      const feedback = response.data.feedback

      dispatch(_customerUpdateProject(projectId, { feedback }))
      return true
    } catch (err) {
      throw err
    }
  }
}
