export const days = [
  'Weekdays',
  'Weekends',
  'Sundays',
  'Mondays',
  'Tuesdays',
  'Wednesdays',
  'Thursdays',
  'Fridays',
  'Saturdays',
]

export const hours = ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const minutes = ['', '00', '15', '30', '45']

export const tod = ['AM', 'PM']
