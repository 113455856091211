import React from 'react'
import styled from 'styled-components'
import { em } from 'polished'

const Question = styled.div`
  margin: ${em('50px')} 0;

  .title {
    font-size: ${em('16px')};
  }

  .content {
    font-size: ${em('14px')};
  }
`

const SurveyFaqs = () => {
  return (
    <div>
      <Question>
        <h3 className="title">Survey FAQs</h3>
        <p className="content">Survey Faqs will be here in the future.</p>
      </Question>
    </div>
  )
}

export default SurveyFaqs
