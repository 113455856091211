import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import Loader from '../sharedComponents/Loader'
import Message from '../sharedComponents/Message'
import Button from '../sharedComponents/Button'
import Field from '../sharedComponents/Field'

export default class Login extends Component {
  state = {
    loggedIn: false,
    email: '',
    password: '',
    errors: {},
  }

  componentDidMount() {
    this.setState({
      loggedIn: this.props.loggedIn,
    })
  }

  componentWillReceiveProps({ loggedIn }) {
    this.setState({
      loggedIn,
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const email = this.state.email
    const password = this.state.password

    this.setState(
      {
        errors: this.validateFields(email, password),
      },
      () => {
        const errors = Object.keys(this.state.errors)
          .map(error => {
            return this.state.errors[error]
          })
          .filter(err => {
            return err
          })

        if (errors.length === 0 && !this.props.loading) {
          this.props.login(email, password)
        }
      }
    )
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  validateFields = (email, password) => {
    const errors = {
      email: !isEmail(email) ? true : false,
      password: isEmpty(password) ? true : false,
    }

    return errors
  }

  render() {
    const { loading, error, location } = this.props
    const { errors } = this.state

    if (!location.state) return <Redirect to="/" />

    // TODO: Still causing some issues, for example when you login as another account it redirects you the last from.state
    if (this.state.loggedIn) {
      if (location.state.from) {
        return <Redirect to={location.state.from} />
      } else {
        return <Redirect to="/" />
      }
    }

    return (
      <form onSubmit={e => this.handleSubmit(e)} noValidate>
        <fieldset>
          <Field invalid={errors.email}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <span className="error">Please enter a valid email.</span>
          </Field>
          <Field invalid={errors.password}>
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <span className="error">Please enter your password.</span>
            <Link to="/forgot-password" className="small">
              Forgot your password
            </Link>
          </Field>
        </fieldset>

        <Button type="submit" variant={loading ? 'disabled lg' : 'primary lg'} text="Login" />

        {error && <Message type="error" message={error} />}

        {loading ? <Loader /> : ''}
      </form>
    )
  }
}
