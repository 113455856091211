import { connect } from 'react-redux'
import Projects from './../components/pages/Projects/'
import {
  itemsFetchData,
  itemsFetchQuotes,
  addItem,
  employeeAddMember,
  employeeGetEmployees,
  duplicateProject,
  projectUpdate,
  // employeeMarkSurvey,
  // getPopulatedProject,
  // setCurrentProject,
  projectUpdateStage,
  deleteProject,
  deleteQuote,
  resetStagesFetched,
} from '../redux/modules/projects'
// use this for when trying to change state from To Review to To Review / Completed...
import { estimateUpdateServer, estimateRenew } from '../redux/modules/estimate/'
import { fetchGlobalSettings } from './../redux/modules/globalSettings/'

const mapStateToProps = state => {
  return {
    isLoading: state.projects.loading,
    hasErrored: state.projects.error,
    items: state.projects.items,
    stagesFetched: state.projects.stagesFetched,
    quotes: state.projects.quotes,
    // currentProject: state.projects.currentProject,
    globalSettings: state.globalSettings.globalSettings,
    globalSettingsLoaded: state.globalSettings.settingsLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchData: stage => dispatch(itemsFetchData(stage)),
    fetchQuotes: () => dispatch(itemsFetchQuotes()),
    addItem: () => dispatch(addItem()),
    duplicateProject: projectId => dispatch(duplicateProject(projectId)),
    employeeAddMember: (projectId, memberId, type) =>
      dispatch(employeeAddMember(projectId, memberId, type)),
    // employeeMarkSurvey: (projectId, surveyId, type, notes, updateProject) =>
    //   dispatch(employeeMarkSurvey(projectId, surveyId, type, notes, updateProject)),
    employeeGetEmployees: typeOfEmployee => dispatch(employeeGetEmployees(typeOfEmployee)),
    estimateUpdateServer: (estimateId, updates, isCustomer) =>
      dispatch(estimateUpdateServer(estimateId, updates, isCustomer)),
    estimateRenew: (project, newExpiration, newDate) =>
      dispatch(estimateRenew(project, newExpiration, newDate)),
    // getPopulatedProject: projectId => dispatch(getPopulatedProject(projectId)),
    projectUpdate: (projectId, updates) => dispatch(projectUpdate(projectId, updates)),
    // setCurrentProject: project => dispatch(setCurrentProject(project)),
    projectUpdateStage: (projectId, newStage) => dispatch(projectUpdateStage(projectId, newStage)),
    deleteProject: projectId => dispatch(deleteProject(projectId)),
    deleteQuote: quoteId => dispatch(deleteQuote(quoteId)),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
    resetStagesFetched: () => dispatch(resetStagesFetched()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects)
