import axios from 'axios'
import produce from 'immer'
// import shortid from 'shortid'
// import { updateSurveyPart as projectUpdateSurveyPart } from './../projects/'
import { updateProject } from '../project/operations'
import { addProductionUrl } from './../../../helpers/constants'
import { getToken } from './../../../helpers/auth'

const SURVEY_LOADING = 'app/survey/SURVEY_LOADING'
const SURVEY_ERROR = 'app/survey/SURVEY_ERROR'
const INIT_SURVEY_SUCCESS = 'app/survey/INIT_SURVEY_SUCCESS'
const UPDATE_SURVEY = 'app/survey/UPDATE_SURVEY'
const UPDATE_SURVEY_OFFLINE = 'app/survey/UPDATE_SURVEY_OFFLINE'
const UPDATE_DIRECTIONAL_PHOTO = 'app/survey/UPDATE_DIRECTIONAL_PHOTO'
const UPDATE_DIRECTIONAL_PHOTO_OFFLINE = 'app/survey/UPDATE_DIRECTIONAL_PHOTO_OFFLINE'
const UPDATE_DETAILS_PHOTO = 'app/survey/UPDATE_DETAILS_PHOTO'
const UPDATE_DETAILS_PHOTO_OFFLINE = 'app/survey/UPDATE_DETAILS_PHOTO_OFFLINE'

const UPDATE_DETAILS_VALUE = 'app/survey/UPDATE_DETAILS_VALUE'
const UPDATE_DETAILS_VALUE_OFFLINE = 'app/survey/UPDATE_DETAILS_VALUE_OFFLINE'

const UPDATE_BLUEPRINT_COMMENTS = 'app/survey/UPDATE_BLUEPRINT_COMMENTS'
const UPDATE_BLUEPRINT_COMMENTS_OFFLINE = 'app/survey/UPDATE_BLUEPRINT_COMMENTS_OFFLINE'

const initialState = {
  loading: false,
  error: null,

  _materials: [],
  _styles: [],
  _options: [],

  survey: {
    status: '',
    project: {},
    material: '',
    style: '',
    options: [],
    height: '',
    isMultiStyle: false,
    ms_material: '',
    ms_style: '',
    ms_options: [],
    ms_height: '',

    photos: {},

    details: {
      tearRemove: {},
      core: {},
      clearing: {},
      plate: {},
      haulDirt: {},
      brackets: {},
      roots: {},
      bobcat: {},
      contour: {},
      poolcode: {},
    },

    blueprint: {
      image: '',
      lines: '',
      width: null,
      height: null,
      comments: [],
    },

    homeownersRequest: '',
    salesmanNotes: '',
    specialConditions: '',
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SURVEY_LOADING:
      return {
        ...state,
        loading: action.bool,
      }

    case SURVEY_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case UPDATE_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          ...action.updates,
        },
      }

    case UPDATE_SURVEY_OFFLINE:
      return {
        ...state,
        survey: {
          ...state.survey,
          ...action.meta.updates,
        },
      }

    case INIT_SURVEY_SUCCESS:
      return {
        ...state,
        _materials: action._materials,
        _styles: action._styles,
        _options: action._options,
        survey: action.survey,
      }

    case UPDATE_DIRECTIONAL_PHOTO:
      return produce(state, draftState => {
        // add the photos obj if currently on first one
        if (action.field === 'frontLeft') {
          draftState.survey.photos = {}
        }
        console.log('yes i am updating', action)
        draftState.survey.photos[action.field] = action.photoData
      })

    case UPDATE_DIRECTIONAL_PHOTO_OFFLINE:
      return produce(state, draftState => {
        // add the photos obj if currently on first one
        if (action.meta.field === 'frontLeft') {
          draftState.survey.photos = {}
        }
        draftState.survey.photos[action.meta.field] = action.meta.photoData
      })

    case UPDATE_DETAILS_VALUE:
      return produce(state, draftState => {
        if (action.which === 'details') {
          draftState.survey[action.which][action.field].selected = action.selected
        }
        if (action.which === 'additionalDetails') {
          draftState.survey[action.which][action.field].answer = action.selected
        }
      })
    case UPDATE_DETAILS_VALUE_OFFLINE:
      return produce(state, draftState => {
        if (action.meta.which === 'details') {
          draftState.survey[action.meta.which][action.meta.field].selected = action.meta.selected
        }
        if (action.meta.which === 'additionalDetails') {
          draftState.survey[action.meta.which][action.meta.field].answer = action.meta.selected
        }
      })

    case UPDATE_DETAILS_PHOTO:
      return produce(state, draftState => {
        // handle updates notes of a detail photo
        if (action.which === 'notes') {
          // console.log('uhhhh the action', action)
          draftState.survey.details[action.field].images[action.photoIdx].notes = action.data.notes
        }
        // handle uploading a new photo for a detail
        if (action.which === 'upload') {
          draftState.survey.details[action.field].images.push(action.data)
        }
        // handle removing a photo for a detail
        if (action.which === 'delete') {
          draftState.survey.details[action.field].images = [
            ...draftState.survey.details[action.field].images.slice(0, action.photoIdx),
            ...draftState.survey.details[action.field].images.slice(action.photoIdx + 1),
          ]
        }
      })
    case UPDATE_DETAILS_PHOTO_OFFLINE:
      return produce(state, draftState => {
        // // handle updates notes of a detail photo
        // if (action.meta.which === 'notes') {
        //   draftState.survey.details[action.meta.field].images[action.meta.photoIdx].notes =
        //     action.meta.data.notes
        // }
        // // handle uploading a new photo for a detail
        // if (action.meta.which === 'upload') {
        //   draftState.survey.details[action.meta.field].images.push(action.meta.data)
        // }
        // // handle removing a photo for a detail
        // if (action.meta.which === 'delete') {
        //   draftState.survey.details[action.meta.field].images = [
        //     ...draftState.survey.details[action.meta.field].images.slice(0, action.meta.photoIdx),
        //     ...draftState.survey.details[action.meta.field].images.slice(action.meta.photoIdx + 1),
        //   ]
        // }
      })

    case UPDATE_BLUEPRINT_COMMENTS:
      return produce(state, draftState => {
        // handle adding a blueprint comment
        if (action.which === 'add') {
          draftState.survey.blueprint.comments.push(action.comment)
        }
        // handle updating a blueprint comment
        if (action.which === 'update') {
          const commentId = draftState.survey.blueprint.comments.findIndex(
            comment => comment._id === action.comment._id
          )
          draftState.survey.blueprint.comments = [
            ...draftState.survey.blueprint.comments.slice(0, commentId),
            {
              ...draftState.survey.blueprint.comments[commentId],
              ...action.comment,
            },
            ...draftState.survey.blueprint.comments.slice(commentId + 1),
          ]
        }
        // handle deleting a blueprint comment
        if (action.which === 'delete') {
          const commentId = draftState.survey.blueprint.comments.findIndex(
            comment => comment._id === action.comment._id
          )
          draftState.survey.blueprint.comments = [
            ...draftState.survey.blueprint.comments.slice(0, commentId),
            ...draftState.survey.blueprint.comments.slice(commentId + 1),
          ]
        }
      })
    case UPDATE_BLUEPRINT_COMMENTS_OFFLINE:
      // for some reason, this was making it buggy. removing it makes it work again
      return produce(state, draftState => {})
    default:
      return state
  }
}

export function surveyLoading(bool) {
  return {
    type: SURVEY_LOADING,
    bool,
  }
}

export function surveyError(error) {
  return {
    type: SURVEY_ERROR,
    error,
  }
}

export function updateSurvey(surveyId, updates) {
  return {
    type: UPDATE_SURVEY,
    updates,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${addProductionUrl()}/api/surveys/${surveyId}`,
          method: 'PATCH',
          json: updates,
          headers: { Authorization: `Bearer ${getToken()}` },
        },
        // action to dispatch when effect succeeds:
        commit: { type: UPDATE_SURVEY_OFFLINE, meta: { updates } },
        // action to dispatch if network action fails permanently:
        rollback: { type: UPDATE_SURVEY_OFFLINE, meta: { updates } },
      },
    },
  }
}

export function _updateDirectionalPhoto(surveyId, formData, stateData) {
  return {
    type: UPDATE_DIRECTIONAL_PHOTO,
    field: stateData.field,
    photoData: stateData.data,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${addProductionUrl()}/api/surveys/${surveyId}`,
          method: 'PATCH',
          json: formData,
          headers: { Authorization: `Bearer ${getToken()}`, enctype: 'multipart/form-data' },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_DIRECTIONAL_PHOTO_OFFLINE,
          meta: { field: stateData.field, photoData: stateData.data },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: UPDATE_DIRECTIONAL_PHOTO_OFFLINE,
          meta: { field: stateData.field, photoData: stateData.data },
        },
      },
    },
  }
}

export function updateDetailsValue(surveyId, formData, stateData, which) {
  return {
    type: UPDATE_DETAILS_VALUE,
    field: stateData.field,
    selected: stateData.value,
    which,

    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${addProductionUrl()}/api/surveys/${surveyId}`,
          method: 'PATCH',
          json: formData,
          headers: { Authorization: `Bearer ${getToken()}`, enctype: 'multipart/form-data' },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_DETAILS_VALUE_OFFLINE,
          meta: { field: stateData.field, selected: stateData.value, which },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: UPDATE_DETAILS_VALUE_OFFLINE,
          meta: { field: stateData.field, selected: stateData.value, which },
        },
      },
    },
  }
}

// adds detail photo or updates its' notes
export function updateDetailsPhoto(surveyId, formData, stateData, which) {
  return {
    type: UPDATE_DETAILS_PHOTO,
    field: stateData.field,
    data: stateData.data,
    ...(stateData.photoIdx !== undefined && { photoIdx: stateData.photoIdx }),
    which,

    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${addProductionUrl()}/api/surveys/${surveyId}`,
          method: 'PATCH',
          json: formData,
          headers: { Authorization: `Bearer ${getToken()}`, enctype: 'multipart/form-data' },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_DETAILS_VALUE_OFFLINE,
          meta: {
            field: stateData.field,
            data: stateData.data,
            ...(stateData.photoIdx !== undefined && { photoIdx: stateData.photoIdx }),
            which,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: UPDATE_DETAILS_VALUE_OFFLINE,
          meta: {
            field: stateData.field,
            data: stateData.data,
            ...(stateData.photoIdx !== undefined && { photoIdx: stateData.photoIdx }),
            which,
          },
        },
      },
    },
  }
}

export function updateBlueprintComments(surveyId, comment, which) {
  let effectMethod = null

  const { _id, ...restComment } = comment
  let jsonToSend = {}

  if (which === 'add') {
    effectMethod = 'POST'
    jsonToSend = { comment: restComment }
  }
  if (which === 'update') {
    effectMethod = 'PATCH'
    jsonToSend = { comment }
  }
  if (which === 'delete') {
    effectMethod = 'DELETE'
    jsonToSend = { _id }
  }

  return {
    type: UPDATE_BLUEPRINT_COMMENTS,
    comment,
    which,

    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${addProductionUrl()}/api/surveys/${surveyId}/comments`,
          method: effectMethod,
          json: jsonToSend,
          headers: { Authorization: `Bearer ${getToken()}`, enctype: 'multipart/form-data' },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_BLUEPRINT_COMMENTS_OFFLINE,
          meta: {
            comment,
            which,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: UPDATE_BLUEPRINT_COMMENTS_OFFLINE,
          meta: {
            comment,
            which,
          },
        },
      },
    },
  }
}

export function initSurvey(projectId) {
  return async dispatch => {
    dispatch(surveyLoading(true))
    try {
      const results = await Promise.all([
        axios.get(`${addProductionUrl()}/api/surveys/${projectId}`),
        axios.get(`${addProductionUrl()}/api/surveys/materials`),
        axios.get(`${addProductionUrl()}/api/surveys/styles`),
        axios.get(`${addProductionUrl()}/api/surveys/options`),
      ])

      const resultsObj = {}
      results.forEach((result, i) => {
        const keys = ['survey', '_materials', '_styles', '_options']
        resultsObj[keys[i]] = result.status !== 200 ? null : result.data
      })

      dispatch(initSurveySuccess(resultsObj))
      dispatch(surveyLoading(false))
      return true
    } catch (err) {
      dispatch(surveyError(err.response.data.message))
      dispatch(surveyLoading(false))
      return false
    }
  }
}

export function initSurveySuccess(resultsObj) {
  return {
    type: INIT_SURVEY_SUCCESS,
    survey: resultsObj.survey,
    _materials: resultsObj._materials,
    _styles: resultsObj._styles,
    _options: resultsObj._options,
  }
}

export function updateSurveyServer(surveyId, updates, update = false, projectId = null) {
  return async dispatch => {
    try {
      if (update && projectId) {
        dispatch(updateProject(projectId, updates.status))
      }

      dispatch(updateSurvey(surveyId, updates))
      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function resetMaterialAndChildren(surveyId, resetType, newMaterial = null) {
  return async dispatch => {
    // resetType=m1 => change only the first material
    // resetType=m2 => change only the second material
    // resetType=both => change the first and second materials
    try {
      let resetObj = {}
      if (resetType === 'm1') {
        resetObj.style = null
        resetObj.options = []
        resetObj.height = null
        resetObj.currentStep = 1
        resetObj.material = newMaterial
        resetObj.isMultiStyle = false
        // might as well set the MS stuff also, bug where after you are past
        // step 6, go and change the multiStyle to false, and then go back
        // to step one, it will reset M1 but not the M2 stuff that was set
        resetObj.ms_style = null
        resetObj.ms_options = []
        resetObj.ms_height = null
        resetObj.ms_material = null
      } else if (resetType === 'm2') {
        resetObj.ms_style = null
        resetObj.ms_options = []
        resetObj.ms_height = null
        resetObj.currentStep = 6
        resetObj.ms_material = newMaterial
      } else if (resetType === 'both') {
        resetObj.style = null
        resetObj.options = []
        resetObj.height = null
        resetObj.currentStep = 1
        resetObj.material = newMaterial
        resetObj.isMultiStyle = false
        resetObj.ms_style = null
        resetObj.ms_options = []
        resetObj.ms_height = null
        resetObj.ms_material = null
      } else if (resetType === 'ms') {
        // reset for when changing from MS to no-MS
        resetObj.isMultiStyle = false
        resetObj.ms_style = null
        resetObj.ms_options = []
        resetObj.ms_height = null
        resetObj.currentStep = 5
        resetObj.ms_material = null
      } else {
        throw Error('Something went wrong')
      }

      // const survey = await axios.patch(`${addProductionUrl()}/api/surveys/${surveyId}`, resetObj)

      dispatch(updateSurvey(surveyId, resetObj))
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function surveyBlueprintCommentsAdd(surveyId, comment) {
  return async dispatch => {
    try {
      dispatch(updateBlueprintComments(surveyId, comment, 'add'))
      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function surveyBlueprintCommentsUpdate(surveyId, comment) {
  return async dispatch => {
    try {
      dispatch(updateBlueprintComments(surveyId, comment, 'update'))
      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function surveyBlueprintCommentsDelete(surveyId, comment) {
  return async dispatch => {
    try {
      dispatch(updateBlueprintComments(surveyId, comment, 'delete'))
      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function uploadDirectionalPhoto(surveyId, formData, stateData) {
  return async dispatch => {
    try {
      dispatch(_updateDirectionalPhoto(surveyId, formData, stateData))
      // send request to backend behind the scences, do not want to await on it
      // because without it, we can return true and instantly update date where its being used

      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function updateDirectionalPhoto(surveyId, formData, stateData) {
  return async dispatch => {
    try {
      dispatch(_updateDirectionalPhoto(surveyId, formData, stateData))
      // send request to backend behind the scences, do not want to await on it
      // because without it, we can return true and instantly update date where its being used

      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

// for updating details Yes/No
export function changeDetailsValue(surveyId, formData, stateData, which) {
  return async dispatch => {
    try {
      dispatch(updateDetailsValue(surveyId, formData, stateData, which))
      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}

export function uploadDetailsPhoto(surveyId, formData, stateData, which) {
  return async dispatch => {
    try {
      dispatch(updateDetailsPhoto(surveyId, formData, stateData, which))
      return true
    } catch (err) {
      dispatch(surveyError(err))
    }
  }
}
