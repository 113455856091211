import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { addProductionUrl } from './../../../helpers/constants'

import Title from './../../sharedComponents/Title'

class LoggedInConfirmation extends Component {
  componentDidMount = async () => {
    const { quote } = this.props

    this.props.setCustomerData({
      email: this.props.user.email,
      quoteId: quote._id,
    })
  }

  render() {
    return (
      <StyledLoggedInConfirmation>
        <Title text="Thank you. Scheduling your survey." tag="h3" size="24px" />
      </StyledLoggedInConfirmation>
    )
  }
}

const StyledLoggedInConfirmation = styled.div``

export default LoggedInConfirmation
