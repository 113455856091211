import React, { Component } from 'react'
import styled from 'styled-components'
import { breakpoints, navSize, controlsSize, colors } from './../../../helpers/foundation'

const initState = {
  pos: 0,
  dragging: false,
  currentX: 0,
  width: null,
  stage: '',
}

class ProjectBoard extends Component {
  state = initState

  componentDidMount() {
    if (this.props.stage !== '') {
      window.addEventListener('mousemove', this.handleMouseMove)
      window.addEventListener('mouseup', this.handleMouseUp)

      this.setState({
        ...(this.refs.container && { width: this.refs.container.offsetWidth }),
        // width: this.refs.container.offsetWidth,
        stage: this.props.stage,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseDown = e => {
    if (e.button !== 0 || e.target.closest('form')) return

    const containerWidth = this.refs.container.offsetWidth
    const wrapperLeft = this.refs.wrapper.scrollLeft

    this.setState({
      dragging: true,
      width: containerWidth,
      currentX: e.pageX + wrapperLeft,
      pageX: e.pageX,
    })

    e.stopPropagation()
    e.preventDefault()
  }

  handleMouseUp = e => {
    this.setState({ dragging: false })
    e.stopPropagation()
    e.preventDefault()
  }

  handleMouseMove = e => {
    const { dragging, currentX, pos } = this.state

    if (!dragging) return

    this.setState(
      {
        pos: -e.pageX + currentX,
      },
      () => {
        this.refs.wrapper.scrollLeft = pos
      }
    )

    e.stopPropagation()
    e.preventDefault()
  }

  resetBoard = () => {
    this.setState(initState, () => {
      if (this.refs.wrapper) {
        this.refs.wrapper.scrollLeft = 0
      }
    })
  }

  render() {
    const { dragging } = this.state

    if (this.props.hasBreakpoint && this.props.breakpoint) {
      if (this.props.currentWidth > this.props.breakpoint) {
        return <div>{this.props.children}</div>
      } else {
        return (
          <SchedulerLayout dragging={dragging} alternate={this.props.alternate}>
            <div className="wrapper" ref="container" onMouseDown={this.handleMouseDown}>
              <div className="container" ref="wrapper">
                {this.props.children}
              </div>
            </div>
          </SchedulerLayout>
        )
      }
    }
    return (
      <ColumnsLayout dragging={dragging} alternate={this.props.alternate}>
        <div className="wrapper" ref="wrapper" onMouseDown={this.handleMouseDown}>
          <div className="container" ref="container">
            {this.props.children}
          </div>
        </div>
      </ColumnsLayout>
    )
  }
}

export const ColumnsLayout = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  height: calc(100vh - ${navSize.small});

  @media screen and (min-width: ${breakpoints.small}px) {
    height: calc(100vh - ${navSize.small});
  }

  @media screen and (min-width: ${breakpoints.medium}px) {
    height: calc(100vh - ${navSize.large});
  }

  .wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    position: relative;
    height: 100%;
    margin: 20px;

    /* Scrollbars */
    ::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }
    ::-webkit-scrollbar-track-piece {
      background: ${colors.lightGrey};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background: ${colors.grey};
      border-radius: 4px;
    }
  }

  .container {
    height: 100%;
    display: flex;
    margin-bottom: 10px;
  }

  /* TODO: Split into component */
  .message {
    color: #ced4da;
    font-size: 24px;
    text-align: center;
    line-height: 1.15;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const SchedulerLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 76px);
  /* width of fixed week */
  /* min-width: 82.8125em; */
  position: relative;
  overflow: auto;

  .wrapper {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.65em;
    overflow-y: hidden;

    @media screen and (max-width: ${breakpoints.medium}px) {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  .container {
    position: relative;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;

    /* Scrollbars */
    ::-webkit-scrollbar {
      height: 12px;
      width: 12px;
      padding: 0 1em;
    }
    ::-webkit-scrollbar-track-piece {
      background: ${colors.lightGrey};
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background: ${colors.grey};
    }

    @media screen and (max-width: ${breakpoints.medium}px) {
      overflow: hidden;
    }
  }

  /* TODO: Split into component */
  .message {
    color: #ced4da;
    font-size: 24px;
    text-align: center;
    line-height: 1.15;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export default ProjectBoard
