import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import qs from 'qs'
import operations from './operations'
import { createProject } from './../../redux/modules/createProjectForm'
import { getCustomerByEmail } from './../../redux/modules/customer/'
import { login } from './../../redux/modules/auth/'
import actions from './actions'
import { isCurrentWeek } from './utils'
import format from 'date-fns/format'
import addWeeks from 'date-fns/add_weeks'
import subWeeks from 'date-fns/sub_weeks'
import { startOfWeek, endOfWeek } from 'date-fns'
import { breakpoint, navSize, colors } from './../../helpers/foundation'

import Loader from '../sharedComponents/Loader'
import Message from '../sharedComponents/Message'
import Button from '../sharedComponents/Button'
import Panel from '../sharedComponents/Panel'
import Container from '../sharedComponents/Container'
import Column from '../pages/Projects/Column'
import ProjectBoard, { ColumnsLayout } from '../sharedComponents/layouts/ProjectBoard'
import * as Scheduler from './components'
import Day from './components/Day'
import CustomTimePanel from './components/CustomTimePanel'
import Modal from './../sharedComponents/Modal'
import ScheduleConfirmationModal from './components/ScheduleConfirmationModal'

const ModifiedProjectBoard = styled.div`
  ${ColumnsLayout} {
    height: calc(100vh - ${navSize.small} - 100px);

    ${breakpoint('medium')} {
      height: calc(100vh - ${navSize.large} - 100px);
    }
  }

  ${Column} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

class SchedulerContainer extends Component {
  state = {
    customTimeOpen: false,
    // will be loggedIn, userExists, userDoesNotExist
    // whenever this is set (string), expect modal to be open
    formOfConfirmation: null,
    quoteSelectedTime: null,
    // used to show loader while creating user/project/scheduling
    schedulingTime: false,
    // remove glitchy refresh of reloading times after time
    // already scheduled and while modal is still open
    timeScheduled: false,

    startOfWeek: null,
    endOfWeek: null,
  }

  async componentDidMount() {
    try {
      const { events, project } = this.props

      if (project && !this.props.isFromQuote) {
        // get events for project if it was created by OFS
        await this.props.getEvents(events.start, events.end, project)
      } else {
        const token = qs.parse(this.props.location.search.slice(1))['token'] || null
        await this.props.fetchQuote(token)

        if (this.props.events.quote) {
          // get the events, fake project data needed to get the datetimes
          await this.props.getEvents(events.start, events.end, {
            additionalClientInfo: {
              address: this.props.events.quote.address,
              city: this.props.events.quote.city,
              state: this.props.events.quote.state,
              zip: this.props.events.quote.zip,
            },
          })
        }
      }
    } catch (err) {
      console.log('there was an err', err)
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  scheduleTime = (selectedTime, project, user) => {
    const { history, confirmTime, events } = this.props

    confirmTime(selectedTime, project, events.slots, user).then(res => {
      this.setState(prevState => ({ timeScheduled: true }))
      if (user.role !== 'Customer') {
        history.push('/projects/surveys')
      }
    })
  }

  // when trying to schedule a time via get quote form
  openConfirmationModal = async (selectedTime, user = null) => {
    try {
      // is user logged in
      if (user && user.email === this.props.events.quote.email) {
        // create project
        this.setState(prevState => ({
          quoteSelectedTime: selectedTime,
          formOfConfirmation: 'loggedIn',
        }))
        // schedule time
      } else {
        // query params does not like plus aliasing
        const encodedEmail = btoa(this.props.events.quote.email)
        const existingUser = await this.props.getCustomerByEmail(encodedEmail)

        if (existingUser) {
          this.setState(prevState => ({
            quoteSelectedTime: selectedTime,
            formOfConfirmation: 'userExists',
          }))
        } else {
          this.setState(prevState => ({
            quoteSelectedTime: selectedTime,
            formOfConfirmation: 'userDoesNotExist',
          }))
        }
      }
    } catch (err) {}
  }

  closeScheduleConfirmationModal = () => {
    this.setState(prevState => ({
      formOfConfirmation: null,
    }))
  }

  lastWeek = () => {
    const { events, project } = this.props
    const currentStartDay = events.start

    const lastStartWeek = startOfWeek(subWeeks(currentStartDay, 1))
    const lastEndWeek = endOfWeek(subWeeks(currentStartDay, 1))

    if (!events.loadingEvents) {
      this.props.getEvents(
        lastStartWeek,
        lastEndWeek,
        !this.props.isFromQuote
          ? project
          : {
              additionalClientInfo: {
                address: this.props.events.quote.address,
                city: this.props.events.quote.city,
                state: this.props.events.quote.state,
                zip: this.props.events.quote.zip,
              },
              startOfWeek: lastStartWeek,
              endOfWeek: lastEndWeek,
            }
      )
    }
  }

  nextWeek = () => {
    const { events, project } = this.props
    const currentStartDay = events.start

    const nextStartWeek = startOfWeek(addWeeks(currentStartDay, 1))
    const nextEndWeek = endOfWeek(addWeeks(currentStartDay, 1))

    if (!events.loadingEvents) {
      this.props.getEvents(
        nextStartWeek,
        nextEndWeek,
        !this.props.isFromQuote
          ? project
          : {
              additionalClientInfo: {
                address: this.props.events.quote.address,
                city: this.props.events.quote.city,
                state: this.props.events.quote.state,
                zip: this.props.events.quote.zip,
              },
              startOfWeek: nextEndWeek,
              endOfWeek: nextEndWeek,
            }
      )
    }
  }

  toggleCustomTimeOpen = () => {
    this.setState(prevState => ({ customTimeOpen: !prevState.customTimeOpen }))
  }

  render() {
    const {
      history,
      events,
      selectTime,
      project,
      user,
      getSurveyors,
      scheduleCustomTime,
      createProjectState,
    } = this.props
    const loading = events.loadingEvents || events.confirmingTime
    const formattedStartWeek = format(events.start, 'MMMM Do')
    const formattedEndWeek = format(events.end, 'MMMM Do')
    const { customTimeOpen } = this.state

    // these can come in from either project or quote
    let address = ''
    let city = ''
    let state = ''
    let zip = ''
    let location = ''
    // if not from 'Get a Quote'
    if (user && !this.props.isFromQuote) {
      address = project.additionalClientInfo.address
      city = project.additionalClientInfo.city
      state = project.additionalClientInfo.state
      zip = project.additionalClientInfo.zip
      location = `${address}, ${city}, ${state} ${zip}`
    } else {
      if (this.props.events.quote) {
        address = this.props.events.quote.address
        city = this.props.events.quote.city
        state = this.props.events.quote.state
        zip = this.props.events.quote.zip
        location = `${address}, ${city}, ${state} ${zip}`
      }
    }

    // basic checking if the quote is valid
    if (!this.props.events.quoteExists) {
      return (
        <Container>
          <div>No quote exists.</div>
        </Container>
      )
    }

    if (this.props.events.quoteExists && !this.props.events.quoteValid) {
      return (
        <Container>
          <div>This quote is invalid.</div>
        </Container>
      )
    }

    if (this.state.timeScheduled) {
      return <Container />
    }

    // if (events)
    return (
      <React.Fragment>
        {this.state.formOfConfirmation && (
          <ScheduleConfirmationModal
            closeModal={this.closeScheduleConfirmationModal}
            formOfConfirmation={this.state.formOfConfirmation}
            quote={this.props.events.quote}
            selectedTime={this.props.events.selectedTime}
            scheduleTime={this.scheduleTime}
            login={this.props.login}
            history={this.props.history}
            user={user || null}
          />
        )}
        {!this.props.isFromQuote && user && user.role !== 'Customer' && (
          <Panel
            open={customTimeOpen}
            size="550"
            color={colors.grey5}
            attached={false}
            padded={true}
            position="right"
          >
            <CustomTimePanel
              toggleCustomTimeOpen={this.toggleCustomTimeOpen}
              events={events}
              getSurveyors={getSurveyors}
              scheduleCustomTime={scheduleCustomTime}
              project={project}
              history={history}
              user={user}
            />
          </Panel>
        )}
        <Scheduler.Calendar>
          <Scheduler.Header>
            <div>
              <Scheduler.Title>
                Showing week of {formattedStartWeek} - {formattedEndWeek}
              </Scheduler.Title>

              {user && !this.props.isFromQuote ? (
                <Fragment>
                  <Scheduler.Subtitle>Schedule survey for {project.name}</Scheduler.Subtitle>
                  <Scheduler.Subtitle>Located at {location}</Scheduler.Subtitle>
                  {user && user.role === 'Customer' && (
                    <Scheduler.Subtitle marginTop="1" color="primary">
                      Cant find a time that works for you? Call our office at (407) 744-0828.
                    </Scheduler.Subtitle>
                  )}
                </Fragment>
              ) : null}

              {events.error && (
                <Message
                  message={events.error && events.error.response.data.message}
                  type="error"
                />
              )}

              {events.confirmTimeError && (
                <Message
                  message={events.confirmTimeError && events.confirmTimeError.response.data.message}
                  type="error"
                />
              )}
            </div>

            {!events.error && (
              <Scheduler.Controls>
                <div className="split">
                  <div className="left">
                    {!this.props.isFromQuote && user && user.role !== 'Customer' && (
                      <Button
                        text="Custom"
                        fluid={true}
                        handleClick={this.toggleCustomTimeOpen}
                        variant="secondary"
                      />
                    )}
                  </div>
                  <div className="right">
                    {!isCurrentWeek(events.start) && !events.selectedTime && (
                      <Button
                        text="Last Week"
                        fluid={true}
                        handleClick={this.lastWeek}
                        variant={loading ? 'disabled' : 'secondary'}
                      />
                    )}

                    {!events.selectedTime && (
                      <Button
                        text="Next Week"
                        fluid={true}
                        handleClick={this.nextWeek}
                        variant={events.loadingEvents ? 'disabled' : 'secondary'}
                      />
                    )}

                    {events.selectedTime && (
                      <React.Fragment>
                        <Button
                          text="Cancel"
                          fluid={true}
                          handleClick={loading ? null : () => selectTime(null)}
                          variant={loading ? 'disabled' : 'secondary'}
                        />

                        {user && project && !this.props.isFromQuote ? (
                          <Button
                            text="Confirm my time"
                            fluid={true}
                            handleClick={
                              loading
                                ? null
                                : () => this.scheduleTime(events.selectedTime, project, user)
                            }
                            variant={loading ? 'disabled' : 'primary'}
                          />
                        ) : (
                          <Button
                            text="Choose this time"
                            fluid={true}
                            handleClick={
                              loading
                                ? null
                                : () => this.openConfirmationModal(events.selectedTime, user)
                            }
                            variant={loading ? 'disabled' : 'primary'}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </Scheduler.Controls>
            )}
          </Scheduler.Header>

          <ModifiedProjectBoard>
            <ProjectBoard>
              {events.loadingEvents || events.confirmingTime ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <Day
                    day="Sunday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                  <Day
                    day="Monday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                  <Day
                    day="Tuesday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                  <Day
                    day="Wednesday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                  <Day
                    day="Thursday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                  <Day
                    day="Friday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                  <Day
                    day="Saturday"
                    events={events}
                    selectTime={selectTime}
                    isCustomer={(user && user.role === 'Customer') || !user}
                  />
                </React.Fragment>
              )}
            </ProjectBoard>
          </ModifiedProjectBoard>
        </Scheduler.Calendar>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    events: state.scheduler.events,
    createProjectState: state.createProjectForm,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEvents: (start, end, project) => dispatch(operations.getEvents(start, end, project)),
    selectTime: slot => dispatch(actions.selectTime(slot)),
    reset: () => dispatch(actions.reset()),
    confirmTime: (slot, project, slots, user) =>
      dispatch(operations.confirmTime(slot, project, slots, user)),
    getSurveyors: () => dispatch(operations.getSurveyors()),
    scheduleCustomTime: (projectId, surveyor, dateTime, user) =>
      dispatch(operations.scheduleCustomTime(projectId, surveyor, dateTime, user)),
    fetchQuote: token => dispatch(operations.fetchQuote(token)),
    createProject: (projectDetails, customerInfo) =>
      dispatch(createProject(projectDetails, customerInfo)),
    getCustomerByEmail: email => dispatch(getCustomerByEmail(email)),
    login: (email, password) => dispatch(login(email, password)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerContainer)
