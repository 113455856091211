import axios from 'axios'
import slugify from 'slugify'
import { produce } from 'immer'
import { addProductionUrl } from '../../../helpers/constants'

export const CONFIRM_TIME_SUCCESS = 'app/customer/CONFIRM_TIME_SUCCESS'
export const CONFIRM_TIME_FAILURE = 'app/customer/CONFIRM_TIME_FAILURE'
export const CUSTOMER_UPDATE_PROJECT = 'app/customer/CUSTOMER_UPDATE_PROJECT'
export const CUSTOMER_UPDATE_PROJECT_STAGE_STATUS =
  'app/customer/CUSTOMER_UPDATE_PROJECT_STAGE_STATUS'
export const CUSTOMER_UPDATE_PROJECT_ESTIMATE = 'app/customer/CUSTOMER_UPDATE_PROJECT_ESTIMATE'
export const CUSTOMER_UPDATE_PROJECT_INSTALLATION =
  'app/customer/CUSTOMER_UPDATE_PROJECT_INSTALLATION'

// Move to user reducer
export const HIDE_TUTORIAL = 'app/customer/HIDE_TUTORIAL'

// Move to project reducer
export const UPDATE_PROJECT = 'app/customer/UPDATE_PROJECT'
export const ADD_PROJECT = 'app/customer/ADD_PROJECT'

// Redundant
export const DATA_LOADING = 'app/customer/DATA_LOADING'
export const FETCH_DATA_SUCCESS = 'app/customer/FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILURE = 'app/customer/FETCH_DATA_FAILURE'

const initialState = {
  loading: false,
  messages: [],
  customer: {
    showTutorial: false,
    _id: '',
    createdAt: '',
    email: '',
    displayName: '',
    profileImage: '',
    role: '',
    projects: [],
  },
}

export default function reducer(state = initialState, action = {}) {
  const projectIdx = state.customer.projects.findIndex(proj => proj._id === action.projectId)

  switch (action.type) {
    case ADD_PROJECT:
      return {
        ...state,
        customer: {
          ...state.customer,
          projects: [...state.customer.projects, action.project],
        },
      }
    case DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        customer: action.user,
        loading: false,
      }
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        message: [...state.messages, { type: 'error', message: action.err }],
        loading: false,
      }
    case CONFIRM_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: {
          ...state.customer,
          projects: [
            ...state.customer.projects.slice(0, projectIdx),
            {
              ...state.customer.projects[projectIdx],
              survey: action.survey,
            },
            ...state.customer.projects.slice(projectIdx + 1),
          ],
        },
      }
    case CONFIRM_TIME_FAILURE:
      return {
        ...state,
        messages: [...state.messages, { type: 'error', message: action.err }],
      }
    case HIDE_TUTORIAL:
      return {
        ...state,
        customer: {
          ...state.customer,
          showTutorial: false,
        },
      }
    case CUSTOMER_UPDATE_PROJECT_ESTIMATE:
      return {
        ...state,
        customer: {
          ...state.customer,
          projects: [
            ...state.customer.projects.slice(0, projectIdx),
            {
              ...state.customer.projects[projectIdx],
              estimate: action.estimate,
            },
            ...state.customer.projects.slice(projectIdx + 1),
          ],
        },
      }
    case CUSTOMER_UPDATE_PROJECT_INSTALLATION:
      return {
        ...state,
        customer: {
          ...state.customer,
          projects: [
            ...state.customer.projects.slice(0, projectIdx),
            {
              ...state.customer.projects[projectIdx],
              installation: {
                ...state.customer.projects[projectIdx].installation,
                ...action.updates,
              },
            },
            ...state.customer.projects.slice(projectIdx + 1),
          ],
        },
      }
    case CUSTOMER_UPDATE_PROJECT:
      return {
        ...state,
        customer: {
          ...state.customer,
          projects: [
            ...state.customer.projects.slice(0, projectIdx),
            {
              ...state.customer.projects[projectIdx],
              ...action.updates,
            },
            ...state.customer.projects.slice(projectIdx + 1),
          ],
        },
      }
    case CUSTOMER_UPDATE_PROJECT_STAGE_STATUS:
      return produce(state, draftState => {
        const projectId = draftState.customer.projects.findIndex(
          project => project._id === action.projectId
        )
        if (projectId > -1) {
          draftState.customer.projects[projectId].stage = action.stage
          if (action.status) {
            draftState.customer.projects[projectId][
              draftState.customer.projects[projectId].stage
            ].status = action.status
          }
        }
      })
    default:
      return state
  }
}

// for approving estimate / payment
export function customerUpdateProjectEstimate(estimate) {
  return {
    type: CUSTOMER_UPDATE_PROJECT_ESTIMATE,
    // check if its populated first
    projectId: estimate.project._id ? estimate.project._id : estimate.project,
    estimate,
  }
}

export function customerUpdateProjectInstallation(projectId, updates) {
  console.log('should be updating from cust mod', projectId, updates)
  return {
    type: CUSTOMER_UPDATE_PROJECT_INSTALLATION,
    projectId: projectId,
    updates,
  }
}

export function customerAddProject(project) {
  return {
    type: ADD_PROJECT,
    project,
  }
}

// Currently used to deny estimate
export function customerUpdateProject(projectId, updates) {
  return {
    type: CUSTOMER_UPDATE_PROJECT,
    projectId,
    updates,
  }
}

export function customerUpdateProjectStageStatus(projectId, stage, status) {
  return {
    type: CUSTOMER_UPDATE_PROJECT_STAGE_STATUS,
    projectId,
    stage,
    status,
  }
}

export function customerDataLoading() {
  return {
    type: DATA_LOADING,
  }
}

export function customerFetchDataSuccess(user) {
  return {
    type: FETCH_DATA_SUCCESS,
    user,
  }
}

export function customerFetchDataFailure(err) {
  return {
    type: FETCH_DATA_FAILURE,
    err,
  }
}

export function customerConfirmTimeSuccess(projectId, survey) {
  return {
    type: CONFIRM_TIME_SUCCESS,
    projectId,
    survey,
  }
}

export function customerConfirmTimeFailure(err) {
  return {
    type: CONFIRM_TIME_FAILURE,
    err,
  }
}

export function hideTutorial() {
  return {
    type: HIDE_TUTORIAL,
  }
}

export function customerFetchData(customerId) {
  return dispatch => {
    dispatch(customerDataLoading())
    // might want to just use /users/:uid ? if there is any other case where a users data needs to be retrieved
    axios
      .get(`${addProductionUrl()}/api/customers/${customerId}`)
      .then(res => {
        if (res.status === 200 && res.data.user) {
          dispatch(customerFetchDataSuccess(res.data.user))
        } else {
          throw 'Could not retrieve your projects'
        }
      })
      .catch(err => {
        dispatch(
          customerFetchDataFailure(
            err.response ? err.response.data.message : "Something wen't wrong"
          )
        )
      })
  }
}

export function updateProject(projectId, updates) {
  return async dispatch => {
    try {
      dispatch(customerDataLoading())

      const response = await axios.patch(`${addProductionUrl()}/api/projects/${projectId}`, updates)

      dispatch(customerFetchData(response.data.project.customer._id))

      return
    } catch (err) {
      dispatch(customerFetchDataFailure(err.response.data.message))

      throw err
    }
  }
}

export function customerHideTutorial(customerId) {
  return async dispatch => {
    try {
      const {
        data: { customer },
      } = await axios.patch(`${addProductionUrl()}/api/customers/${customerId}`, {
        showTutorial: false,
      })

      const firstProject = `/project/${customer.projects[0]}/${slugify(
        `Fence-${customer.displayName}-01`
      )}`

      dispatch(hideTutorial())

      return firstProject
    } catch (err) {
      throw err
    }
  }
}

export function customerConfirmTime(projectId, surveyId) {
  return dispatch => {
    dispatch(customerDataLoading())

    axios
      .patch(`${addProductionUrl()}/api/surveys/${surveyId}`, {
        status: 'To Do',
      })
      .then(res => {
        if (res.status === 200) {
          const updatedSurvey = res.data

          dispatch(customerConfirmTimeSuccess(projectId, updatedSurvey))
        }
      })
      .catch(err => {
        dispatch(customerConfirmTimeFailure(err))
      })
  }
}

export function customerRequestEstimateRenewal(project) {
  return async dispatch => {
    try {
      const { customer, estimate } = project
      const response = await axios.post(
        `${addProductionUrl()}/api/estimates/${estimate._id}/renew`,
        {
          customer,
          expiration: estimate.expirationDate,
          projectName: project.name,
        }
      )
      // console.log('UHHH', response.data)
      dispatch(customerUpdateProjectEstimate(response.data))
    } catch (err) {
      console.log('ERROR', err)
    }
  }
}

// does not set any of state, returns user or null
export function getCustomerByEmail(email) {
  return async dispatch => {
    try {
      const response = await axios.get(`${addProductionUrl()}/api/users/by-email?e=${email}`)
      return response.data
    } catch (err) {
      console.log('there was an err', err)
    }
  }
}
