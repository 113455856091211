import {
  ERROR,
  LOADING,
  MARK_NOTIFICATIONS_LOADED,
  TOGGLE_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
} from './types'

export const error = message => ({
  type: ERROR,
  payload: {
    error: message,
  },
})

export const loading = bool => ({
  type: LOADING,
  payload: {
    loading: bool,
  },
})

export const markNotificationsLoaded = () => ({
  type: MARK_NOTIFICATIONS_LOADED,
  payload: {},
})

export const toggleNotifications = (bool = null) => ({
  type: TOGGLE_NOTIFICATIONS,
  payload: {
    bool,
  },
})

export const setNotifications = notifications => ({
  type: SET_NOTIFICATIONS,
  payload: {
    notifications,
  },
})

export const addNotifications = notifications => ({
  type: ADD_NOTIFICATIONS,
  payload: {
    notifications,
  },
})
