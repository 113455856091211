import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { colors, breakpoints, breakpoint } from './../../../../../helpers/foundation'
import actionsMap from './../../../../../helpers/actionsMap'

import { AlarmWithBackground } from './../../../../sharedComponents/StyledIcons'
import { MediumHeader } from './../../../../sharedComponents/Header-NEW'
import Container from './../../../../sharedComponents/Container'
import InfoTable from './../../../../sharedComponents/InfoTable'
import MapContainer from './../../../../sharedComponents/MapContainer'
import Loader from './../../../../sharedComponents/Loader'
import { TableWithBigItems } from './../../../../sharedComponents/Table'

const ReviewOverview = props => {
  const { project, projectActionsLoading, projectActions } = props
  const { additionalClientInfo } = project

  let data = []
  if (projectActions) {
    // projectActions in a way so it can be passed into TableWithBigHeadings
    // [ { left: [ <item>, ... ], right: [  ] } ]
    data = [...projectActions.survey].map(row => {
      return {
        left: [
          <div className="item">
            <AlarmWithBackground />
          </div>,
          <div className="item">
            <div className="title">
              {row.type
                .split('_')
                .map(type => type.charAt(0).toUpperCase() + type.slice(1).toLowerCase())
                .join(' ')}
            </div>
            <div className="subtitle">
              by {row.user.displayName} on {format(row.createdAt, 'MM/DD/YY @ h:mma')}
            </div>
          </div>,
        ],
        right: [],
      }
    })
  }

  return (
    <StyledReviewOverview>
      <Container width="1600">
        <div className="section">
          <div className="split">
            <div className="left">
              <MediumHeader>Client Information</MediumHeader>
              <InfoTable
                data={{
                  Name: project.name,
                  'Project Address': `${additionalClientInfo.address} ${
                    additionalClientInfo.city
                  }, ${additionalClientInfo.state} ${additionalClientInfo.zip}`,
                  Phone: project.customer.displayName,
                  Email: project.customer.email,
                }}
              />
            </div>
            <div className="right">
              <div className="map">
                <MapContainer hasMarker additionalClientInfo={project.additionalClientInfo} />
              </div>
            </div>
          </div>
        </div>
        <div className="bordered-section no-bottom">
          <MediumHeader>Recent Activities</MediumHeader>
          {projectActionsLoading ? <Loader /> : <TableWithBigItems data={data} />}
        </div>
      </Container>
    </StyledReviewOverview>
  )
}

export default ReviewOverview

const StyledReviewOverview = styled.div`
  ${breakpoint('medium')} {
    .split {
      display: flex;
      .left {
        width: 45%;
        padding-right: 1em;
      }
      .right {
        width: 55%;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.large}px) {
    .keys {
      margin-right: 1em;
    }
  }

  .map {
    position: relative;
    margin-bottom: 2em;
    width: 100%;
    height: 27.5em;
  }
`
