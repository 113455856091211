import React from 'react'
import { addDays, getDate, getMonth, format, subWeeks, isFuture } from 'date-fns'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Scheduler from './../styles'

const MetaRow = props => {
  // see if the sun and sat are different months
  const startAndEndDifferent = () => {
    const start = getMonth(addDays(props.startOfWeek, 1))
    // const start = addDays(props.startOfWeek, 1)
    const end = getMonth(props.endOfWeek)
    // if start and end are not the same, get months for both and show them as abvr
    if (start !== end) {
      return true
    }
    return false
  }

  // returns true or false based on weather or not the previous week is before today
  const canGoPrevWeek = isFuture(subWeeks(props.endOfWeek, 1))

  return (
    <Scheduler.MetaRow>
      <div className="left">
        {!startAndEndDifferent() ? (
          <div className="month">
            {props.getMonthFull(props.currentYear, getMonth(addDays(props.startOfWeek, 1)))}
          </div>
        ) : (
          <div className="month">
            {props.getMonthFull(props.currentYear, getMonth(addDays(props.startOfWeek, 1)))} -{' '}
            {props.getMonthFull(props.currentYear, getMonth(props.endOfWeek))}
          </div>
        )}
      </div>
      <div className="right">
        {!startAndEndDifferent() ? (
          <span>
            {props.getMonthAbvr(props.currentYear, getMonth(addDays(props.startOfWeek, 1)))}{' '}
            {getDate(addDays(props.startOfWeek, 1))} - {getDate(props.endOfWeek)}
          </span>
        ) : (
          <span>
            {props.getMonthAbvr(props.currentYear, getMonth(addDays(props.startOfWeek, 1)))}{' '}
            {getDate(addDays(props.startOfWeek, 1))} -{' '}
            {props.getMonthAbvr(props.currentYear, getMonth(props.endOfWeek))}{' '}
            {getDate(props.endOfWeek)}
          </span>
        )}
        <div className="actions">
          <div
            className={`previous-week ${canGoPrevWeek && !props.hideActions ? '' : 'disabled'}`}
            onClick={e => {
              if (!props.hideActions) {
                if (canGoPrevWeek) {
                  props.lastWeek()
                  // clear times
                  props.selectTime(null)
                  props.resetBoard()
                }
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className="divider" />
          <div
            className={`next-week ${!props.hideActions ? '' : 'disabled'}`}
            onClick={e => {
              if (!props.hideActions) {
                props.nextWeek()
                props.selectTime(null)
                props.resetBoard()
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </Scheduler.MetaRow>
  )
}

export default MetaRow
