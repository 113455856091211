const CHANGE_WEEK = 'app/scheduler/CHANGE_WEEK'
const EVENTS_RECEIVED = 'app/scheduler/EVENTS_RECEIVED'
const SELECT_TIME = 'app/scheduler/SELECT_TIME'
const CONFIRMING_TIME = 'app/scheduler/CONFIRMING_TIME'
const TIME_CONFIRMED = 'app/scheduler/TIME_CONFIRMED'
const TIME_CONFIRMED_ERROR = 'app/scheduler/TIME_CONFIRMED_ERROR'
const ERROR = 'app/scheduler/ERROR'
const CUSTOM_TIME_ERROR = 'app/scheduler/CUSTOM_TIME_ERROR'
const RESET = 'app/scheduler/RESET'
const FETCH_QUOTE = 'app/scheduler/FETCH_QUOTE'
const CHANGE_MONTH = 'app/scheduler/CHANGE_MONTH'

export default {
  CHANGE_WEEK,
  EVENTS_RECEIVED,
  SELECT_TIME,
  CONFIRMING_TIME,
  TIME_CONFIRMED,
  TIME_CONFIRMED_ERROR,
  ERROR,
  CUSTOM_TIME_ERROR,
  RESET,
  FETCH_QUOTE,
  CHANGE_MONTH,
}
