import React from 'react'
import Step from './../../sharedComponents/step'
import Sidebar from './Sidebar'

const PrimarySidebar = ({
  sidebarStateObj,
  stepStatus,
  stepPointer,
  currentStep,
  updateCurrentStep,
}) => {
  return (
    <Sidebar>
      <div className="inner">
        <Step
          status={stepStatus(0, sidebarStateObj)}
          title="select your times"
          num={1}
          isActive={stepPointer === 0}
          isCurrent={currentStep === 0}
        />
        <Step
          status={stepStatus(1, sidebarStateObj)}
          title="schedule confirmed"
          num={2}
          isActive={stepPointer === 1}
          isCurrent={currentStep === 1}
        />
        <Step
          status={stepStatus(2, sidebarStateObj)}
          title="site visit"
          num={3}
          isActive={stepPointer === 2}
          isCurrent={currentStep === 2}
        />
        <Step
          status={stepStatus(3, sidebarStateObj)}
          title="estimation creation"
          num={4}
          isActive={stepPointer === 3}
          isCurrent={currentStep === 3}
        />
        <Step
          status={stepStatus(4, sidebarStateObj)}
          title={currentStep > 4 ? 'View Estimate' : 'Approve Estimate'}
          num={5}
          isActive={stepPointer === 4}
          isCurrent={currentStep === 4}
          handleClick={() => updateCurrentStep(4)}
        />
        <Step
          status={stepStatus(5, sidebarStateObj)}
          title="payment method"
          num={6}
          isActive={stepPointer === 5}
          isCurrent={currentStep === 5}
          handleClick={currentStep === 5 ? () => updateCurrentStep(5) : false}
        />
        <Step
          status={stepStatus(6, sidebarStateObj)}
          title="Payment Complete"
          num={7}
          isActive={stepPointer === 6}
          isCurrent={currentStep === 6}
          handleClick={() => updateCurrentStep(6)}
        />
        <Step
          status={stepStatus(7, sidebarStateObj)}
          title="Installation"
          num={8}
          isActive={stepPointer === 7}
          isCurrent={currentStep === 7}
          handleClick={() => updateCurrentStep(7)}
        />
      </div>
    </Sidebar>
  )
}

export default PrimarySidebar
