import styled from 'styled-components'
import { darken, rgba } from 'polished'
import posed from 'react-pose'
import { colors, zIndexes } from '../../helpers/foundation'

const AnimatedMenu = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    delay: 100,
    transition: {
      duration: 150,
    },
  },
  exit: {
    opacity: 0,
    y: 5,
    transition: {
      duration: 150,
    },
  },
})

const Reveal = styled(AnimatedMenu)`
  position: absolute;
  width: 190px;

  /* This is changing based on the parent */
  top: 40px;

  ${props => {
    // check if left orientation (used in GatherFiles)
    if (props.orientation && props.orientation === 'left') return 'left: -15px'
    return 'right: -5px'
  }};

  z-index: ${zIndexes.revealMenu};

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid ${colors.grey6};
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #fff;
    vertical-align: middle;
    position: absolute;
    top: -3px;
    ${props => {
      // check if left orientation (used in GatherFiles)
      if (props.orientation && props.orientation === 'left') return 'left: 15px'
      return 'right: 15px'
    }};
    transform: rotate(45deg);
    z-index: 2;
  }

  .inner {
    border-radius: 4px;
    border: 1px solid ${colors.grey6};
    background-color: #fff;
    box-shadow: 0 2px 4px 0 ${rgba('#000000', 0.2)};
    overflow: hidden;
    position: relative;
  }

  .item {
    color: ${colors.grey3};
    cursor: pointer;
    font-size: 14px;
    padding: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    transition: 0.2s background-color ease-in-out;

    &:hover {
      background-color: ${darken(0.05, '#fff')};
    }

    &.error {
      color: ${colors.danger};
      &:hover {
        background-color: #fff;
      }
    }

    /* .pdf-loader {
      width: 1em;
      height: 1em;
    } */
  }
`

export default Reveal
