import styled from 'styled-components'
import { darken, em } from 'polished'
import { colors } from '../../helpers/foundation'

const TextLink = styled.a`
  color: ${props => colors[props.color]};
  display: inline-block;
  vertical-align: middle;
  font-size: ${em('16px')};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: ${props => darken(0.2, colors[props.color])};
  }

  &:hover {
    text-decoration: underline;
  }
`

export default TextLink
