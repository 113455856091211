import React, { Component } from 'react'
import Button from './../../sharedComponents/Button'
import Message from './../../sharedComponents/Message'
import Loader from './../../sharedComponents/Loader'
import Field from './../../sharedComponents/Field'
import Form from './../../sharedComponents/Form'

class ChangePasswordForm extends Component {
  state = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
    error: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.message !== null) {
      this.setState({
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
        error: false,
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { repeatPassword, newPassword, currentPassword } = this.state

    if (repeatPassword === '' || newPassword === '' || currentPassword === '') {
      this.setState({
        error: 'Please fill out all required fields.',
      })
    } else if (repeatPassword !== newPassword) {
      this.setState({
        error: 'Passwords do not match.',
      })
    } else if (currentPassword === newPassword) {
      this.setState({
        error: 'Your current password is already set to that.',
      })
    } else {
      this.setState({
        error: false,
      })

      if (!this.props.inProgress) {
        this.props.changePassword(currentPassword, newPassword)
      }
    }
  }

  render() {
    const { inProgress, error, message } = this.props

    if (inProgress) {
      return <Loader />
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        {message && <Message type="success" message={message} />}

        {this.state.error || error ? (
          <Message type="error" message={this.state.error || error} />
        ) : (
          ''
        )}

        <fieldset>
          <Field>
            <label htmlFor="currentPassword">Old Password</label>
            <input
              type="password"
              name="currentPassword"
              onChange={this.handleChange}
              value={this.state.currentPassword}
            />
          </Field>
          <Field>
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              name="newPassword"
              onChange={this.handleChange}
              value={this.state.newPassword}
            />
          </Field>
          <Field>
            <label htmlFor="repeatPassword">Confirm New Password</label>
            <input
              type="password"
              name="repeatPassword"
              onChange={this.handleChange}
              value={this.state.repeatPassword}
            />
          </Field>
        </fieldset>
        <div className="tar">
          <Button type="submit" variant={inProgress ? 'disabled' : ''} text="Change Password" />
        </div>
      </Form>
    )
  }
}

export default ChangePasswordForm
