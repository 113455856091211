import {
  REQUESTING_PROJECT,
  ERROR_REQUESTING_PROJECT,
  UPDATE_ACTIVE_PROJECT,
  UPDATE_ACTIVE_PROJECT_PART,
  ERROR_UPDATING_PROJECT,
  UPDATING_PROJECT,
  UPDATE_STAGE_STATUS,
  CLEAR_PROJECT,
} from './types'

export const requestingProject = () => ({
  type: REQUESTING_PROJECT,
  payload: {
    requestingProject: true,
  },
})

export const requestProjectError = error => ({
  type: ERROR_REQUESTING_PROJECT,
  payload: {
    requestProjectError: error,
  },
})

export const updatingProject = () => ({
  type: UPDATING_PROJECT,
  payload: {
    updatingProject: true,
  },
})

export const updateProjectError = error => ({
  type: ERROR_UPDATING_PROJECT,
  payload: {
    updateProjectError: error,
  },
})

export const updateCurrentProject = (project = null) => ({
  type: UPDATE_ACTIVE_PROJECT,
  payload: {
    project,
  },
})

export const updateCurrentProjectPart = (field, data) => ({
  type: UPDATE_ACTIVE_PROJECT_PART,
  payload: {
    field,
    data,
  },
})

export const updateStageStatus = (stage, status) => ({
  type: UPDATE_STAGE_STATUS,
  payload: {
    stage,
    status,
  },
})

export const clearProject = () => ({
  type: CLEAR_PROJECT,
})
