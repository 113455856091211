import React, { Component, Fragment } from 'react'
import { Detector } from 'react-detect-offline'

import SurveyStart from './SurveyStart'
import Sidebar from './Sidebar'
import SurveyMaterial from './SurveyMaterial'
import SurveyStyle from './SurveyStyle'
import SurveyOptions from './SurveyOptions'
import SurveyHeight from './SurveyHeight'
import SurveyMultiStyle from './SurveyMultiStyle'
import SurveyContour from './SurveyContour'
import SurveyPhotos from './SurveyPhotos'
import SurveyDetails from './SurveyDetails'
import SurveyNotes from './SurveyNotes'
import Loader from './../sharedComponents/Loader'
import SidebarLayout from './../sharedComponents/layouts/SidebarLayout'
import Button from './../sharedComponents/Button'
import SurveyFaqs from './../faqs/SurveyFaqs'
import History from '../sharedComponents/History'
import MobileAction from '../sharedComponents/MobileAction'
import SurveyBlueprintTwo from './SurveyBlueprintTwo/'
import { ViewerConsumer, withViewerContext } from './../../contexts/ViewerContext'
import Viewer from './../sharedComponents/Viewer'
import Alert from './../sharedComponents/Alert'

class Survey extends Component {
  _isMounted = false

  state = {
    // this will represent the step user is currently on
    // the props.currentStep will be the 'furthest step'
    stepPointer: 0,
    multiStyleFlag: false,
    isLastStep: false,
    completeSurveyModalOpen: false,
    failed: false,
  }

  componentDidMount = () => {
    this._isMounted = true

    if (this._isMounted) {
      this.props.initSurvey(this.props.project._id).then(success => {
        // this will set the stepPointer / current view to be what the furthest step is
        if (success) {
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                stepPointer: this.props.survey.currentStep,
                isLastStep:
                  (!this.props.survey.isMultiStyle && this.props.survey.currentStep === 10) ||
                  (this.props.survey.isMultiStyle && this.props.survey.currentStep === 14)
                    ? true
                    : false,
              }
            })
          }
        } else {
          if (this._isMounted) {
            // handle unsuccessful / 401 request errors
            this.setState(prevState => ({ failed: true }))
          }
        }
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  // this handles when page refresh when it is last step, sets isLastStep=true
  static getDerivedStateFromProps(props, state) {
    const { survey } = props
    const { stepPointer } = state
    // if on the last step, set isLastStep = true
    return (!survey.isMultiStyle && stepPointer === 10) ||
      (survey.isMultiStyle && stepPointer === 14)
      ? { ...state, isLastStep: true }
      : null
  }

  changeStepPointer = desiredStep => {
    const { project, survey } = this.props
    const { currentStep } = survey
    const { stepPointer } = this.state
    const isMsStep = survey.isMultiStyle && stepPointer > 5 ? true : false

    // Go to top of page
    window.scrollTo(0, 0)

    // no desired step means they hit 'Next'
    if (!desiredStep) {
      if (stepPointer === currentStep) {
        // make sure validations are met
        if (this.canStepNext()) {
          // update the survey.currentStep & stepPointer
          this.props
            .updateSurveyServer(
              survey._id,
              // pass in multiStyle prop to be used by the logger
              !survey.isMultiStyle
                ? {
                    currentStep: currentStep + 1,
                    projectId: project._id,
                    from: survey.status,
                  }
                : {
                    currentStep: currentStep + 1,
                    projectId: project._id,
                    from: survey.status,
                    multiStyle: true,
                  }
            )
            .then(success => {
              this.setState(prevState => {
                return {
                  stepPointer: prevState.stepPointer + 1,
                  isLastStep:
                    (!isMsStep && prevState.stepPointer + 1 === 10) ||
                    (isMsStep && prevState.stepPointer + 1 === 14)
                      ? true
                      : false,
                }
              })
            })
        }
      } else if (stepPointer < currentStep) {
        this.setState(prevState => {
          return {
            stepPointer: prevState.stepPointer + 1,
            isLastStep:
              (!isMsStep && prevState.stepPointer + 1 === 10) ||
              (isMsStep && prevState.stepPointer + 1 === 14)
                ? true
                : false,
          }
        })
      }
    } else {
      // console.log('CHANGING TO LAST STEP?')
      this.setState(prevState => {
        return {
          stepPointer: desiredStep,
          isLastStep:
            (!isMsStep && desiredStep === 10) || (isMsStep && desiredStep === 14) ? true : false,
        }
      })
    }
  }

  canStepNext = () => {
    const { survey, _materials, _styles, _options } = this.props
    const { currentStep } = survey
    const { stepPointer } = this.state

    // if moved to a step that is behind the current step can always move forward
    if (stepPointer < currentStep) {
      return true
      // if (stepPointer !== 3 || (isMsStep && stepPointer !== 8)) return true
    }

    const steps = {
      materialAndMsMaterial(isMs) {
        return survey[isMs ? 'ms_material' : 'material'] ? true : false
      },
      styleAndMsStyle(isMs) {
        return survey[isMs ? 'ms_style' : 'style'] ? true : false
      },
      optionsAndMsOptions(isMs) {
        try {
          const materialName = _materials.find(
            material => material._id === survey[isMs ? 'ms_material' : 'material']
          ).name
          // T/F
          const styleIsPicket = _styles.find(
            style => style._id === survey[isMs ? 'ms_style' : 'style']
          ).picket

          const materialSelectOneOpts = {}
          // add the select one option groups to obj, they will
          // will be different depending on weather or not the style is picket
          materialSelectOneOpts['Vinyl'] = styleIsPicket
            ? ['VP', 'VPC', 'VGC', 'VC']
            : ['VP', 'VGC', 'VC']
          materialSelectOneOpts['Wood'] = styleIsPicket ? ['WC', 'WP'] : ['WC']
          materialSelectOneOpts['Aluminum'] = styleIsPicket ? ['APC', 'AC'] : ['AC']
          // chain-link does not have picket opts
          materialSelectOneOpts['Chain Link'] = ['CLSO', 'CLC']
          // copy selectOne opts array for the current material/style
          const current = materialSelectOneOpts[materialName]
          let count = current.length

          // loop over all options selected (_id's)
          survey[isMs ? 'ms_options' : 'options'].forEach(optId => {
            // find full object id from _options with given opt_id
            const option = _options[_options.findIndex(opt => opt._id === optId)]
            // since there will only be 1 of the selectOne's, if there
            // is one, decrement the count, expecting count = 0 if all selected
            if (current.includes(option.codeId)) {
              count--
            }
          })
          // count will only be 0 if all selectOpts have been satisfied
          return count <= 0 ? true : false
        } catch (err) {
          // throws error here, if there are no material/style select
          // it will throw cant find X of undefined...
          return false
        }
      },
      photos() {
        try {
          const photosKeys = Object.keys(survey.photos)
          return photosKeys.length === 8 ? true : false
        } catch (err) {
          return false
        }
      },
    }

    const stepValidations = !survey.isMultiStyle
      ? [
          null,
          steps.materialAndMsMaterial(false),
          steps.styleAndMsStyle(false),
          steps.optionsAndMsOptions(false),
          true, // height
          true, // isMultiStyle
          true, // contour
          true, // details
          true, // blueprint
          steps.photos(),
          true, // notes
        ]
      : [
          null,
          steps.materialAndMsMaterial(false),
          steps.styleAndMsStyle(false),
          steps.optionsAndMsOptions(false),
          true, // height
          true, // isMultiStyle
          steps.materialAndMsMaterial(true),
          steps.styleAndMsStyle(true),
          steps.optionsAndMsOptions(true),
          true, // msHeight
          true, // contour
          true, // details
          true, // blueprint
          steps.photos(),
          true, // notes
        ]

    return stepValidations[stepPointer]
  }

  renderComponentsDynamically = isOnline => {
    const {
      survey,
      project,
      updateSurveyServer,
      // updateCustomer,
      resetMaterialAndChildren,
      surveyBlueprintCommentsAdd,
      surveyBlueprintCommentsUpdate,
      surveyBlueprintCommentsDelete,
      uploadDirectionalPhoto,
      updateDirectionalPhoto,
      uploadDetailsPhoto,
      updateDetailsValue,
    } = this.props
    const { stepPointer, completeSurveyModalOpen } = this.state

    // decides whether or not the current view is a multistyle view or not
    const isMsStep = survey.isMultiStyle && stepPointer >= 6 ? true : false

    if (survey.status === 'To Do') {
      return (
        <SurveyStart survey={survey} project={project} updateSurveyServer={updateSurveyServer} />
      )
    }

    switch (stepPointer) {
      case 1:
        return (
          <SurveyMaterial
            survey={survey}
            updateSurveyServer={updateSurveyServer}
            _materials={this.props._materials}
            stepPointer={this.state.stepPointer} // to do multistyle stuff
            // this determines whether or not the component is on a re-useable step
            isMsStep={isMsStep}
            resetMaterialAndChildren={resetMaterialAndChildren}
          />
        )
      case 2:
        return (
          <SurveyStyle
            survey={survey}
            _styles={this.props._styles.filter(item => item.material === survey.material)}
            updateSurveyServer={updateSurveyServer}
            isMsStep={isMsStep}
          />
        )
      case 3:
        return (
          <SurveyOptions
            survey={survey}
            _styles={this.props._styles}
            _options={this.props._options.filter(item => item.material === survey.material)}
            updateSurveyServer={updateSurveyServer}
            isMsStep={isMsStep}
          />
        )
      case 4:
        return (
          <SurveyHeight
            survey={survey}
            selectedMaterial={this.props._materials.find(
              material => material._id === survey.material
            )}
            updateSurveyServer={updateSurveyServer}
            isMsStep={isMsStep}
          />
        )
      case 5:
        return (
          <SurveyMultiStyle
            survey={survey}
            updateSurveyServer={updateSurveyServer}
            resetMaterialAndChildren={resetMaterialAndChildren}
          />
        )

      // Handle whether or not isMultiStyle here!
      case 6:
        if (survey.isMultiStyle) {
          return (
            <SurveyMaterial
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              _materials={this.props._materials}
              stepPointer={this.state.stepPointer} // to do multistyle stuff
              // this determines whether or not the component is on a re-useable step
              isMsStep={isMsStep}
              resetMaterialAndChildren={resetMaterialAndChildren}
            />
          )
        } else {
          return <SurveyContour survey={survey} updateSurveyServer={updateSurveyServer} />
        }
      case 7:
        if (survey.isMultiStyle) {
          return (
            <SurveyStyle
              survey={survey}
              _styles={this.props._styles.filter(
                // notice ms_material here instead of material
                item => item.material === survey.ms_material
              )}
              updateSurveyServer={updateSurveyServer}
              isMsStep={isMsStep}
            />
          )
        } else {
          return (
            <SurveyDetails
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              // updateCustomer={updateCustomer}
              project={project}
              uploadDetailsPhoto={uploadDetailsPhoto}
              updateDetailsValue={updateDetailsValue}
            />
          )
        }
      case 8:
        if (survey.isMultiStyle) {
          return (
            <SurveyOptions
              survey={survey}
              _styles={this.props._styles}
              _options={this.props._options.filter(item => item.material === survey.ms_material)}
              updateSurveyServer={updateSurveyServer}
              isMsStep={isMsStep}
            />
          )
        } else {
          return (
            <SurveyBlueprintTwo
              user={this.props.user}
              project={project}
              survey={survey}
              handleError={this.props.handleError}
              updateSurveyServer={updateSurveyServer}
              surveyBlueprintCommentsAdd={surveyBlueprintCommentsAdd}
              surveyBlueprintCommentsUpdate={surveyBlueprintCommentsUpdate}
              surveyBlueprintCommentsDelete={surveyBlueprintCommentsDelete}
            />
          )
        }
      case 9:
        if (survey.isMultiStyle) {
          return (
            <SurveyHeight
              survey={survey}
              selectedMaterial={this.props._materials.find(
                material => material._id === survey.ms_material
              )}
              updateSurveyServer={updateSurveyServer}
              isMsStep={isMsStep}
            />
          )
        } else {
          return (
            <SurveyPhotos
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              uploadDirectionalPhoto={uploadDirectionalPhoto}
              updateDirectionalPhoto={updateDirectionalPhoto}
            />
          )
        }
      case 10:
        if (survey.isMultiStyle) {
          return <SurveyContour survey={survey} updateSurveyServer={updateSurveyServer} />
        } else {
          return (
            <SurveyNotes
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              completeSurveyModalOpen={completeSurveyModalOpen}
              closeSurveyModal={this.closeSurveyModal}
              completeSurvey={this.completeSurvey}
              completeSurveyAndCreateNew={this.completeSurveyAndCreateNew}
              isOnline={isOnline}
            />
          )
        }
      case 11:
        if (survey.isMultiStyle) {
          return (
            <SurveyDetails
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              project={project}
              uploadDetailsPhoto={uploadDetailsPhoto}
              updateDetailsValue={updateDetailsValue}
            />
          )
        } else {
          return <div>Something went wrong.</div>
        }
      case 12:
        if (survey.isMultiStyle) {
          return (
            <SurveyBlueprintTwo
              user={this.props.user}
              project={project}
              survey={survey}
              handleError={this.props.handleError}
              updateSurveyServer={updateSurveyServer}
              surveyBlueprintCommentsAdd={surveyBlueprintCommentsAdd}
              surveyBlueprintCommentsUpdate={surveyBlueprintCommentsUpdate}
              surveyBlueprintCommentsDelete={surveyBlueprintCommentsDelete}
            />
          )
        } else {
          return <div>Something went wrong.</div>
        }
      case 13:
        if (survey.isMultiStyle) {
          return (
            <SurveyPhotos
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              uploadDirectionalPhoto={uploadDirectionalPhoto}
              updateDirectionalPhoto={updateDirectionalPhoto}
            />
          )
        } else {
          return <div>Something went wrong.</div>
        }
      case 14:
        if (survey.isMultiStyle) {
          return (
            <SurveyNotes
              survey={survey}
              updateSurveyServer={updateSurveyServer}
              completeSurveyModalOpen={completeSurveyModalOpen}
              closeSurveyModal={this.closeSurveyModal}
              completeSurvey={this.completeSurvey}
              completeSurveyAndCreateNew={this.completeSurveyAndCreateNew}
              isOnline={isOnline}
            />
          )
        } else {
          return <div>Something went wrong.</div>
        }
      default:
        return ''
    }
  }

  openSurveyModal = () => {
    this.setState({
      completeSurveyModalOpen: true,
    })
  }

  closeSurveyModal = () => {
    this.setState({
      completeSurveyModalOpen: false,
    })
  }

  completeSurvey = (newProject = null) => {
    const { project, survey } = this.props

    this.props
      .updateSurveyServer(
        survey._id,
        {
          status: 'To Review',
          projectId: project._id,
          from: survey.status,
          lastStatus: Date.now(),
        },
        true, // update the project along with the survey
        project._id
      )
      .then(success => {
        this.props.history.push('/projects/surveys')
      })
  }

  completeSurveyAndCreateNew = () => {
    const { project } = this.props
    this.props
      .duplicateProject(project._id, {
        projectId: project._id,
      })
      .then(newProject => {
        this.completeSurvey(newProject)
      })
  }

  render() {
    const { failed } = this.state
    const { loading, error, handleError, project, survey } = this.props

    // if (loading) {
    //   return <Loader centered={true} />
    // }

    // handle if 401 / failed /api/surveys/:id request
    if (failed && !loading) {
      return (
        <div>
          <h1>Something went wrong and the request failed.</h1>
        </div>
      )
    }

    return (
      <Detector
        render={({ online }) => {
          return (
            <Fragment>
              <ViewerConsumer>
                {/* sets the viewer for images being viewed from the history sidebar */}
                {({ currentImage, unsetImage }) => {
                  if (currentImage) {
                    return <Viewer currentImage={currentImage} unsetImage={unsetImage} />
                  }
                }}
              </ViewerConsumer>
              <SidebarLayout
                noBorder
                statusComponent={
                  <Fragment>
                    <MobileAction>
                      {this.state.isLastStep ? (
                        <Button
                          text="Complete Survey"
                          variant="lg"
                          fluid={true}
                          handleClick={this.openSurveyModal}
                        />
                      ) : (
                        <Button
                          text="Next"
                          variant={this.canStepNext() ? 'lg' : 'lg disabled'}
                          fluid={true}
                          handleClick={this.changeStepPointer}
                        />
                      )}
                    </MobileAction>
                    {/* show alert when internet connection has been lost */}
                    <Fragment>
                      {/* render the component being shown */}
                      {this.renderComponentsDynamically(online)}
                      {!online && (
                        <Alert timer={5000} status="danger" dismiss={() => false}>
                          <small>
                            You have lost internet connect. Data will be synced when you regain
                            connection.
                          </small>
                        </Alert>
                      )}
                    </Fragment>
                  </Fragment>
                }
                faqs={<SurveyFaqs />}
                error={error}
                handleError={handleError}
                history={
                  <History project={project} survey={survey} estimate={null} paperwork={null} />
                }
              >
                <Sidebar
                  project={project}
                  survey={survey}
                  stepPointer={this.state.stepPointer}
                  changeStepPointer={this.changeStepPointer}
                  canStepNext={this.canStepNext}
                  isLastStep={this.state.isLastStep}
                  openSurveyModal={this.openSurveyModal}
                  isOnline={online}
                />
              </SidebarLayout>
            </Fragment>
          )
        }}
      />
    )
  }
}

export default Survey
