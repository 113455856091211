import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import InfoStep from './components/InfoStep'
import Progress from './components/Progress'
import Button from './../../sharedComponents/Button'
import MainContent from './../../sharedComponents/layouts/MainContent'
import Step1Image from './images/ofs-online.jpg'
import Step2Image from './images/surveyor-card.jpg'
import Step3Image from './images/cloud.jpg'

const STEPS = 3

const StyledTutorial = styled.div`
  text-align: center;
  padding-bottom: 2em;

  .controls {
    margin-top: 60px;
  }

  .progress {
    margin-top: 50px;
  }
`

class CustomerTutorial extends Component {
  state = {
    step: 1,
  }

  nextStep = () => {
    // check if is on last step
    if (this.state.step === STEPS) {
      this.props.customerHideTutorial(this.props.customerId).then(projectUrl => {
        this.props.history.push(projectUrl)
      })
    } else {
      this.setState(prevState => {
        return {
          step: prevState.step + 1,
        }
      })
    }
  }

  render() {
    return (
      <StyledTutorial>
        <MainContent>
          {this.state.step === 1 && (
            <InfoStep
              image={Step1Image}
              alt="welcome to OFS Online"
              title="You're In"
              text="Welcome to OFS Online! Here you can track the progress of your project from start to finish."
            />
          )}

          {this.state.step === 2 && (
            <InfoStep
              image={Step2Image}
              alt="project information"
              title="Stay informed."
              text="You’ll get to see who will be performing certain tasks (like your site survey) so that you’re never caught by surprise."
            />
          )}

          {this.state.step === 3 && (
            <InfoStep
              image={Step3Image}
              alt="documents saved online"
              title="Upload key documents."
              text="Forget snail mail! You can upload HOA approvals and city permits easily. Ready to get started?"
            />
          )}

          <div className="controls">
            <Button
              handleClick={this.nextStep}
              text={this.state.step === STEPS ? "Let's Go!" : 'Next'}
            />

            <div className="progress">
              <Progress steps={STEPS} currentStep={this.state.step} />
            </div>
          </div>
        </MainContent>
      </StyledTutorial>
    )
  }
}

export default withRouter(CustomerTutorial)
