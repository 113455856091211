import React, { Component, Fragment } from 'react'
// import { createPdf } from './../../../../helpers/files'
import axios from 'axios'
import isRole from './../../../../helpers/roles'
import { addProductionUrl } from './../../../../helpers/constants'

import Header from './../../../sharedComponents/Header'
import Container from './../../../sharedComponents/Container'
import OverviewChunk from './../../../estimate/shared/OverviewChunk'
import Button from './../../../sharedComponents/Button'
import SidebarLayout from './../../../sharedComponents/layouts/SidebarLayout'
import History from './../../../sharedComponents/History'
import Loader from './../../../sharedComponents/Loader'
import Modal from './../../../sharedComponents/Modal'
import Field from './../../../sharedComponents/Field'
import NotPermitted from './../../../routeUtils/NotPermitted'
import { ViewerConsumer, withViewerContext } from './../../../../contexts/ViewerContext'
import Viewer from './../../../sharedComponents/Viewer'

class EmployeeEstimateReview extends Component {
  state = {
    pdfLoading: false,
    reviseModalOpen: false,
    reviseModalNotes: '',
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(prevState => ({ [name]: value }))
  }

  markToRevise = () => {
    const { reviseModalNotes } = this.state
    const { project } = this.props
    this.props
      .estimateUpdateServer(project.estimate._id, {
        status: 'To Revise',
        revisionNotes: reviseModalNotes,
        lastStatus: Date.now(),
        from: 'To Review',
        projectId: project._id,
      })
      .then(success => {
        this.props.history.push('/projects/estimates')
      })
  }

  markCompleted = () => {
    const { project } = this.props
    this.props
      .estimateUpdateServer(this.props.project.estimate._id, {
        status: 'Pending Approval',
        revisionNotes: '',
        completedOn: Date.now(),
        lastStatus: Date.now(),
        from: 'To Review',
        projectId: project._id,
      })
      .then(success => {
        this.props.history.push('/projects/estimates')
      })
  }

  closeAndClearData = () => {
    this.setState({ reviseModalOpen: false, reviseModalNotes: '' })
  }

  downloadPDF = async () => {
    try {
      const { project } = this.props

      this.setState({ pdfLoading: true })

      const response = await axios.get(`${addProductionUrl()}/api/pdfs/${project._id}?q=employee`, {
        responseType: 'blob',
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.props.project.name}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(link)
      this.setState({ pdfLoading: false })
    } catch (err) {
      this.setState({ pdfError: 'Error', pdfLoading: false })
    }
  }

  render() {
    const { pdfLoading, reviseModalOpen, reviseModalNotes } = this.state
    const { project, user } = this.props

    if (!(isRole(user).admin || isRole(user).estimateManager)) {
      return (
        <NotPermitted history={this.props.history} toText="Estimates" toUrl="/projects/estimates" />
      )
    } else {
      return (
        <Fragment>
          <ViewerConsumer>
            {/* sets the viewer for images being viewed from the history sidebar */}
            {({ currentImage, unsetImage }) => {
              if (currentImage) {
                return <Viewer currentImage={currentImage} unsetImage={unsetImage} />
              }
            }}
          </ViewerConsumer>
          <SidebarLayout
            history={
              <History project={project} survey={project.survey} estimate={project.estimate} />
            }
            statusComponent={
              <React.Fragment>
                {reviseModalOpen && (
                  <Modal
                    width={420}
                    close={this.closeAndClearData}
                    open={reviseModalOpen}
                    centered={false}
                  >
                    <h3 className="title">Tell us why?</h3>
                    <Field>
                      <label htmlFor="reviseModalNotes">Notes</label>
                      <textarea
                        name="reviseModalNotes"
                        value={reviseModalNotes}
                        onChange={this.handleChange}
                      />
                    </Field>
                    <Button
                      text="Submit"
                      fluid={true}
                      variant="primary"
                      handleClick={this.markToRevise}
                    />
                  </Modal>
                )}
                <Header title={`Review Estimate for ${project.name}`} />
                <Container>
                  <div className="actions">
                    <div className="print-pdf">
                      <Button
                        text="Download PDF"
                        variant={pdfLoading ? 'disabled' : ''}
                        handleClick={async e => {
                          this.setState({ pdfLoading: true })
                          await this.downloadPDF()
                          this.setState({ pdfLoading: false })
                        }}
                      />
                      {pdfLoading && <Loader />}
                    </div>
                  </div>
                  <OverviewChunk
                    project={project}
                    estimate={project.estimate}
                    presentational={true}
                  >
                    <div className="options">
                      <Button
                        variant="bordered nm"
                        text="Mark To Revise"
                        handleClick={e => this.setState({ reviseModalOpen: true })}
                      />
                      <Button text="Approve" handleClick={this.markCompleted} />
                    </div>
                  </OverviewChunk>
                </Container>
              </React.Fragment>
            }
          />
        </Fragment>
      )
    }
  }
}

export default EmployeeEstimateReview
