import React from 'react'
import { getDate, getMonth, format, isBefore, subWeeks, subDays } from 'date-fns'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Scheduler from './../styles'
import Icon from './../../sharedComponents/Icon'
import Button from './../../sharedComponents/Button'

import Slots from './Slots'

const Day = props => {
  return (
    <Scheduler.Day {...props}>
      {!props.isDayView && (
        <div className="head">
          <div className="change-day prev" onClick={e => props.changeDay(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className="day">{props.abvr}</div>
          <div className="change-day prev" onClick={e => props.changeDay(1)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      )}
      {/* remove idx and use event id */}
      {props.isLoaded && (!props.events || props.events.length < 1) && props.isDayView ? (
        <Scheduler.Error className="no-slots" isDayView={props.isDayView}>
          <div className="center">
            <Icon icon="bigClock" />
            <div className="error-title">Sorry, No times available on this day.</div>
            <div className="error-message">Would you like to check the next day?</div>
            <Button text="Next day" variant="lg" handleClick={e => props.changeDay(1)} />
          </div>
        </Scheduler.Error>
      ) : (
        <Scheduler.Slots {...props}>
          <Slots
            events={props.events}
            isCustomer={props.isCustomer}
            isLoaded={props.isLoaded}
            startOfWeek={props.startOfWeek}
            dayNum={props.dayNum}
            selectTime={props.selectTime}
            selectedTime={props.selectedTime}
          />
        </Scheduler.Slots>
      )}
    </Scheduler.Day>
  )
}

export default Day
