import React, { Component } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import Button from './../sharedComponents/Button'
import Icon from './../sharedComponents/Icon'
import StyledTable from './../sharedComponents/StyledTable'

// TODO: Replace with real data
const fakeLabors = [
  {
    id: 0,
    name: 'Laborer # 0',
    rate: '12.50',
  },
  {
    id: 1,
    name: 'Laborer # 1',
    rate: '20.00',
  },
  {
    id: 2,
    name: 'Laborer # 2',
    rate: '17.75',
  },
  {
    id: 3,
    name: 'Laborer # 3',
    rate: '45.00',
  },
  {
    id: 4,
    name: 'Laborer # 4',
    rate: '32.25',
  },
]

class EstimateLaborNeeds extends Component {
  state = {
    columns: ['Labor Type', 'Qty.', 'Rate', 'Estimated Hours', 'Labor Cost ($)', ''],
    labors: [],
    total: 0,
  }

  componentDidMount() {
    this.setState({
      labors: this.props.estimate.labors.labors,
      total: this.props.estimate.labors.total,
    })
  }

  // add the delete button to each row, dont want it to be on state
  laborsWithMeta = () => {
    const labors = this.state.labors.map(labor => {
      // icon for deleting a labor
      const del = (
        <div className="meta-actions">
          <div className="clickable delete" onClick={e => this.removeLabor(e, labor.id)}>
            <div className="small">Delete</div>
          </div>
        </div>
      )

      return {
        ...labor,
        del,
      }
    })
    return labors
  }

  addLabor = () => {
    this.setState(
      prevState => {
        return {
          labors: [
            ...prevState.labors,
            {
              id: shortid.generate(),
              name: '',
              quantity: '0',
              rate: '0',
              hours: '0',
              cost: '0',
            },
          ],
        }
      },
      () => {
        const { labors, total } = this.state
        this.props.estimateUpdateServer(this.props.estimate._id, {
          labors: {
            total,
            labors,
          },
        })
      }
    )
  }

  removeLabor = (e, laborId) => {
    const laborIdx = this.state.labors.findIndex(l => l.id === laborId)
    let total = 0
    // calc new total
    this.state.labors.forEach((labor, idx) => {
      if (idx !== laborIdx) {
        total += Number(labor.cost)
      }
    })

    this.setState(
      prevState => {
        return {
          total: total.toFixed(2),
          labors: [...prevState.labors.slice(0, laborIdx), ...prevState.labors.slice(laborIdx + 1)],
        }
      },
      () => {
        const { total, labors } = this.state
        this.props.estimateUpdateServer(this.props.estimate._id, {
          labors: {
            total,
            labors,
          },
        })
      }
    )
  }

  saveLaborField = laborId => {
    const { total, labors } = this.state

    // TODO: Check that data is different before saving

    this.props.estimateUpdateServer(this.props.estimate._id, {
      labors: {
        total,
        labors,
      },
    })
  }

  updateLabor = (e, laborId) => {
    // if its laborType, fill in the rate
    const tarName = e.target.name,
      tarVal = e.target.value
    let newLaborInfo
    // let shouldUpdateServer = false

    // the index in the array for the labors id (shortid)
    const laborIdx = this.state.labors.findIndex(l => l.id === laborId)

    if (tarName === 'laborType') {
      const fakeLabor = fakeLabors.find(l => l.name === tarVal)
      newLaborInfo = {
        ...this.state.labors[laborIdx],
        name: fakeLabor.name,
        rate: fakeLabor.rate,
      }
    } else {
      if (isNaN(Number(tarVal))) return false // dont allow alphabet chars
      newLaborInfo = {
        ...this.state.labors[laborIdx],
        [tarName]: tarVal,
      }
    }

    let total = 0
    // also need to check if all fields filled out, calculate the cost - big ol' if
    // checks whats being updating, and checks to see if the other fields are filled out
    // if they are, calculate the total cost
    if (newLaborInfo.quantity && newLaborInfo.rate && newLaborInfo.hours) {
      // no need to update the state for every input change, only update on row completion
      // shouldUpdateServer = true
      const cost = (newLaborInfo.quantity * newLaborInfo.rate * newLaborInfo.hours).toFixed(2)
      newLaborInfo.cost = cost

      this.state.labors.forEach((labor, idx) => {
        if (laborIdx === idx) {
          total += Number(cost)
        } else {
          total += Number(labor.cost)
        }
      })
    }

    this.setState(prevState => {
      return {
        total: total > 0 ? total.toFixed(2) : prevState.total,
        labors: [
          ...prevState.labors.slice(0, laborIdx),
          newLaborInfo,
          ...prevState.labors.slice(laborIdx + 1),
        ],
      }
    })
  }

  render() {
    const { labors } = this.state
    const { estimate } = this.props
    return (
      <StyledEstimateLaborNeeds laborsPresent={labors.length > 0}>
        <Header title="Choose the labor needs for this project." />
        <Container>
          {estimate.revisionNotes && <Notice text={estimate.revisionNotes} />}
          {this.state.labors.length > 0 && (
            <StyledTable>
              <thead>
                <tr>
                  {this.state.columns.map((text, idx) => {
                    return <th key={idx}>{text}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {this.laborsWithMeta().map((labor, idx) => {
                  return (
                    <tr key={labor.id}>
                      <td>
                        <select
                          name="laborType"
                          id="laborType"
                          onChange={e => this.updateLabor(e, labor.id)}
                          value={labor.name}
                        >
                          <option value="">Select</option>
                          {fakeLabors.map((labor, idx) => {
                            return (
                              <option value={labor.name} key={idx}>
                                {labor.name}
                              </option>
                            )
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="quantity"
                          className="editable-td"
                          value={labor.quantity}
                          onChange={e => this.updateLabor(e, labor.id)}
                          onBlur={() => this.saveLaborField(labor.id)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="rate"
                          className="editable-td"
                          value={labor.rate}
                          onChange={e => this.updateLabor(e, labor.id)}
                          onBlur={() => this.saveLaborField(labor.id)}
                        />{' '}
                      </td>
                      <td>
                        <input
                          type="text"
                          name="hours"
                          className="editable-td"
                          value={labor.hours}
                          onChange={e => this.updateLabor(e, labor.id)}
                          onBlur={() => this.saveLaborField(labor.id)}
                        />
                      </td>
                      <td>{labor.cost === '' ? '0' : labor.cost}</td>
                      <td>{labor.del}</td>
                    </tr>
                  )
                })}
              </tbody>
            </StyledTable>
          )}
          {Number(this.state.total) > 0 && (
            <div className="totals-container">
              <div className="totals">
                <b>Total: </b>
                <div>{this.state.total}</div>
              </div>
            </div>
          )}

          <Button text="Add Labor" handleClick={this.addLabor} />
        </Container>
      </StyledEstimateLaborNeeds>
    )
  }
}

export default EstimateLaborNeeds

const StyledEstimateLaborNeeds = styled.div`
  .totals-container {
    background-color: #f7f8f9;
    padding: 2em 1.5em;
    text-align: right;
  }

  button {
    margin-top: ${props => (props.laborsPresent ? '2em' : '0')};
  }
`
