import React from 'react'
import styled from 'styled-components'

import Icon from './../sharedComponents/Icon'
import { breakpoints, colors, zIndexes, actionBarSize } from './../../helpers/foundation'

const StyledActionBar = styled.div`
  background-color: ${colors.grey5};
  border-top: 1px solid ${colors.lightGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-height: ${actionBarSize.small};
  padding: 1em;
  transition: 0.2s ease-out;
  z-index: ${zIndexes.actionBar};

  @media screen and (min-width: ${breakpoints.medium}px) {
    border-left: 1px solid ${colors.lightGrey};
    width: ${props => (props.steps && props.stepsOpen ? 'calc(100% - 240px)' : '100%')};
    transform: translateX(${props => (props.steps && props.stepsOpen ? '240px' : '0px')});
    margin-left: ${props =>
      props.historyOpen && !props.stepsOpen
        ? '700px'
        : props.stepsOpen && props.historyOpen
        ? '460px'
        : '0'};
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .action {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 20px;
    line-height: 1;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
`

const ActionBar = ({
  faqsOpen,
  stepsOpen,
  historyOpen,
  faqs,
  history,
  steps,
  toggleSteps,
  toggleFaqs,
  toggleHistory,
}) => {
  return (
    <StyledActionBar
      steps={steps}
      stepsOpen={stepsOpen}
      faqsOpen={faqsOpen}
      historyOpen={historyOpen}
    >
      <div className="inner">
        {history && (
          <div className="action">
            <Icon icon="history" action={historyOpen} handleClick={() => toggleHistory()} />
          </div>
        )}

        {steps && (
          <div className="action">
            <Icon icon="timeline" action={stepsOpen} handleClick={() => toggleSteps()} />
          </div>
        )}

        {faqs && (
          <div className="action">
            <Icon icon="question" action={faqsOpen} handleClick={() => toggleFaqs()} />
          </div>
        )}
      </div>
    </StyledActionBar>
  )
}

export default ActionBar
