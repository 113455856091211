import styled from 'styled-components'
import { colors } from './../../../helpers/foundation'

const listColor = {
  survey: `${colors.blue}`,
  estimate: `${colors.purple}`,
  paperwork: `${colors.orange}`,
  installation: `${colors.pink}`,
}

const Column = styled.div`
  background-color: ${props => (props.transparent ? 'transparent' : colors.white)};
  ${({ hasBorder, stage }) => hasBorder && `border-top: 5px solid ${listColor[stage]}`};
  border-radius: 4px 4px 0 0;
  display: inline-block;
  padding: ${props => (props.transparent ? '0' : `0.25em 0.25em 0`)};
  flex: 0 0 auto;
  margin-left: ${props => (props.isFirst ? 0 : '10px')};
  margin-right: 10px;
  vertical-align: top;
  width: 360px;

  &:first-child {
    margin-left: 0;
  }
`
export default Column
