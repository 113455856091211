import React from 'react'
import LoginForm from '../../auth/LoginForm'
import FormLayout from '../../sharedComponents/layouts/FormLayout'
import background from './images/ofs-background.jpg'

const Login = props => {
  return <FormLayout background={background} form={<LoginForm {...props} />} />
}

export default Login
