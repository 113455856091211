import styled from 'styled-components'
import { colors } from '../../../helpers/foundation'

const Sidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > .inner {
    padding: 1em;
  }

  .sidebar-top {
    flex-grow: 1;
  }

  .sidebar-header {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .sidebar-action {
    background-color: ${colors.white};
    border-top: 1px solid ${colors.lightGrey};
    flex-shrink: 0;
    padding: 1em;

    button:last-child {
      margin-bottom: 0;
    }
  }
`

export default Sidebar
