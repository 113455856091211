import React, { Component } from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import { toTitleCase, splitStringByCapital } from './../../../../../../utils'
import { colors } from './../../../../../../helpers/foundation'

import Icon from './../../../../../sharedComponents/Icon'

const INPUT_MIN = 10 // 10 minutes
const INPUT_MAX = 100 // 100 minutes

class DriveTime extends Component {
  state = {
    editing: false,
    surveyDt: 0,
  }

  componentDidMount = () => {
    this.setState(prevState => ({
      surveyDt: this.props.surveyDt,
    }))
  }

  editTimer = e => {
    this.setState(prevState => ({
      editing: !prevState.editing,
    }))
  }

  updateTimer = e => {
    const { value } = e.target
    this.setState(prevState => ({
      surveyDt: value,
    }))
  }

  saveTimer = e => {
    let dontSave = false
    if (this.state.surveyDt === this.props.surveyDt) {
      dontSave = true
    }

    if (!dontSave) {
      let value = this.state.surveyDt
      if (Number(this.state.surveyDt) > 100) {
        value = 100
      }
      if (Number(this.state.surveyDt) < 10) {
        value = 10
      }
      this.setState(prevState => ({
        editing: false,
        surveyDt: value,
      }))
      this.props.updateSurveyDt(value)
    } else {
      this.setState(prevState => ({
        editing: false,
      }))
    }
  }

  render() {
    const { editing } = this.state

    return (
      <StyledDriveTime>
        <div className="title">{toTitleCase(splitStringByCapital('Survey drive time'))}</div>
        <div className="time notranslate">
          <div className="name">{toTitleCase(splitStringByCapital('Drive time'))}</div>
          <div className="time-row">
            {!editing ? (
              <div className="edit" onClick={this.editTimer}>
                <Icon icon="pencil" />
              </div>
            ) : (
              <div className="save" onClick={this.saveTimer}>
                <Icon icon="solidCheck" color="primary" />
              </div>
            )}
            <div className="minutes">
              <input
                type="number"
                value={this.state.surveyDt}
                min={INPUT_MIN}
                step="10"
                max={INPUT_MAX}
                name="surveyDt"
                disabled={!editing}
                onChange={this.updateTimer}
              />{' '}
              <span>mins</span>
            </div>
          </div>
        </div>
      </StyledDriveTime>
    )
  }
}

const StyledDriveTime = styled.div`
  font-size: ${em('14px')};

  .title {
    font-size: 1.15em;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .name {
    font-weight: 600;
    margin-bottom: 0.75em;
  }

  .time-row {
    display: flex;
    width: 25%;

    > div {
      margin-right: 0.5em;

      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .minutes {
    position: relative;
    display: flex;

    span {
      position: relative;
      top: 2px;
    }
  }

  input[type='number'] {
    max-width: 48px;
    height: 24px;
    text-align: center;
    margin-right: 0.5em;
    padding: 4px 2px !important;

    &:disabled {
      background-color: transparent;
      border: 1px solid ${colors.grey6};
    }
  }

  .edit,
  .save {
    position: relative;
    top: 2px;
    cursor: pointer;

    svg {
      cursor: pointer;
    }
  }
`

export default DriveTime
