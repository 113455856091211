import babyparse from 'babyparse'
import { dataURLToBlob } from './helpers/images'

export const sortByDate = (order = 'dsc', group = []) => {
  return group.sort((a, b) => {
    if (order === 'dsc') {
      return new Date(a.createdAt) - new Date(b.createdAt)
    }
    if (order === 'asc') {
      return new Date(b.createdAt) - new Date(a.createdAt)
    }
  })
}

export const parseCsv = file => {
  let reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.readAsText(file)
    reader.onload = () => {
      const csvStr = reader.result
      const parsed = babyparse.parse(csvStr)
      if (parsed.errors.length > 0) {
        reject('Error parsing the csv')
      } else {
        resolve(parsed.data)
      }
    }
  })
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function splitStringByCapital(str) {
  return str.split(/(?=[A-Z])/).join(' ')
}

export function debounce(func, wait, immediate) {
  var timeout

  return function executedFunction() {
    var context = this
    var args = arguments

    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

// this will be need to handled better for when viewing a PDF after it has first been uploaded
// google viewer wont be able to handle the blob url (localhost:3000/asdasdad) or a dataURI
export function openLinkInNewTab(url, isImage) {
  if (isImage) {
    const toRender = `<img src="${url}" alt="" />`
    const x = window.open()
    x.document.open()
    x.document.write(toRender)
    x.document.close()
  } else {
    const blobAnchor = document.createElement('a')
    blobAnchor.href = `https://docs.google.com/viewer?url=${url}&embedded=false`
    blobAnchor.target = '_blank'
    blobAnchor.click()
    // fetch(url, { responseType: 'stream' })
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const blobURL = URL.createObjectURL(blob)
    //     // this works for only
    // const blobAnchor = document.createElement('a')
    // blobAnchor.href = `https://docs.google.com/viewer?url=${url}&embedded=false`
    // blobAnchor.click()
    //   })
  }
}

export function downloadURL(url, name) {
  var blobAnchor = document.createElement('a')
  blobAnchor.href = url
  blobAnchor.download = name
  const handler = e => blobAnchor.removeEventListener('click', handler, false)
  blobAnchor.addEventListener('click', handler, false)

  blobAnchor.click()
}

// really cool solution for download files in chrome, which limits the file size
// to 2MB, and will throw Network Error if it is > 2MB. Turns dataurl into blob
export function downloadDataURI(uri, name) {
  const dataURI = uri
  const blob = dataURLToBlob(dataURI)
  const blobURL = URL.createObjectURL(blob)
  const blobAnchor = document.createElement('a')
  blobAnchor.href = blobURL
  blobAnchor.download = name

  blobAnchor.onclick = function() {
    requestAnimationFrame(function() {
      URL.revokeObjectURL(blobURL)
    })
  }

  blobAnchor.click()
}

export function download() {}

// test weather it is URL or data URI
export function isURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return pattern.test(str)
}

export function isImage(url) {
  const re = /(?:\.([^.]+))?$/

  if (isURL(url)) {
    if (re.exec(url)[1] === 'jpg') {
      return true
    } else if (re.exec(url)[1] === 'jpeg') {
      return true
    } else if (re.exec(url)[1] === 'png') {
      return true
    } else {
      return false
    }
  } else {
    let filetype = url.slice(url.indexOf(':'), url.indexOf(';')).split('/')[1]
    if (filetype === 'jpg') {
      return true
    } else if (filetype === 'jpeg') {
      return true
    } else if (filetype === 'png') {
      return true
    } else {
      return false
    }
  }
}

// for example, for timers need to add [editing] k/v to surveyTimers: { inProgress: { hour: X, minute: Y, editing: false } }
export function addPropertyToObjectsOfObject(obj, kv) {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    newObj[key] = { ...obj[key], ...kv }
  })
  return newObj
}
