import React, { Component, Fragment } from 'react'

import SidebarLayout from './../sharedComponents/layouts/SidebarLayout'
import History from './../sharedComponents/History/'
import Sidebar from './Sidebar'
import GatherFiles from './GatherFiles'
import Button from './../sharedComponents/Button'
import MobileAction from './../sharedComponents/MobileAction'

class Paperwork extends Component {
  state = {
    sidebarOpen: true,
  }

  componentDidMount = () => {
    this.props.fetchPaperwork(this.props.project.paperwork._id)
  }

  toggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    })
  }

  scheduleInstall = () => {
    // this.props.projectUpdateStage(this.props.project._id, 'installation')
    this.props
      .updatePaperwork(this.props.project.paperwork._id, {
        status: 'Completed',
        lastStatus: Date.now(),
      })
      .then(success => {
        this.props
          .updateProject(this.props.project._id, { stage: 'installation' })
          .then(success => {
            this.props.history.push('/projects/installation')
          })
      })
  }

  render() {
    const { project } = this.props
    const hoaRequired = project.survey.additionalDetails.hoaAprovalRequired.answer === 'Yes'

    return (
      <SidebarLayout
        statusComponent={
          <Fragment>
            <MobileAction>
              <Button
                variant={`lg small-vert-padding ${this.props.canStepNext ? null : 'disabled'}`}
                text="Schedule Install"
                handleClick={() => (this.props.canStepNext ? this.props.scheduleInstall() : null)}
                fluid={true}
              />
            </MobileAction>
            <GatherFiles
              {...this.props}
              hoaRequired={hoaRequired}
              sendHoaEmail={this.props.sendHoaEmail}
            />
          </Fragment>
        }
        history={
          <History
            project={project}
            survey={project.survey}
            estimate={project.estimate}
            paperwork={project.paperwork}
          />
        }
      >
        <Sidebar
          paperwork={this.props.paperwork}
          canStepNext={
            hoaRequired
              ? this.props.hoaStatus === 'Approved' && this.props.permitsStatus === 'Approved'
              : this.props.permitsStatus === 'Approved'
          }
          scheduleInstall={this.scheduleInstall}
          hoaRequired={hoaRequired}
        />
      </SidebarLayout>
    )
  }
}

export default Paperwork
