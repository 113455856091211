import React, { Component } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import ReactPanZoom from '@ajainarayanan/react-pan-zoom'
import { faSearchPlus, faSearchMinus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zIndexes } from './../../helpers/foundation'
import { fadeUp } from './../../helpers/animations'

class Viewer extends Component {
  state = {
    zoom: 1,
  }

  zoomIn = () => {
    this.setState(prevState => ({ zoom: prevState.zoom + 0.2 }))
  }

  zoomOut = () => {
    this.setState(prevState => ({ zoom: prevState.zoom - 0.2 }))
  }

  close = () => {
    this.props.unsetImage()
  }

  render() {
    return (
      <StyledViewer>
        <div className="viewer">
          <ReactPanZoom zoom={this.state.zoom}>
            <img src={this.props.currentImage} alt="" />
          </ReactPanZoom>
        </div>
        <div className="viewer-sidebar">
          <div className="top">
            <div className="item" onClick={this.close}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <div className="bottom">
            <div className="item" onClick={this.zoomIn}>
              <FontAwesomeIcon icon={faSearchPlus} />
            </div>
            <div className="item" onClick={this.zoomOut}>
              <FontAwesomeIcon icon={faSearchMinus} />
            </div>
          </div>
        </div>
      </StyledViewer>
    )
  }
}

const StyledViewer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  animation: ${fadeUp} 0.2s ease-in-out forwards;
  background-color: ${rgba('#000', 0.6)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexes.viewer};
  transition: 0.2s ease-in-out;
  cursor: pointer;

  .viewer-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 4em;
    height: 100%;
    background-color: ${rgba('#000', 0.7)};
    z-index: ${zIndexes.modalInner};
    color: #fff;

    .top {
      position: absolute;
      top: 0;
      .item {
        border-bottom: 1px solid #444;
      }
    }

    .bottom {
      position: absolute;
      bottom: 0;
      .item {
        border-bottom: 1px solid #444;
        &:first-child {
          border-top: 1px solid #444;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .item {
      width: 2em;
      height: 2em;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 2em;

      &:hover {
        background-color: ${rgba('#000', 0.6)};
      }
    }
  }
`

export default Viewer
