import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'
import Image from './images/survey-review.jpg'

const StyledCustomerSurveyReview = styled.div`
  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const CustomerSurveyReview = props => {
  return (
    <StyledCustomerSurveyReview>
      <Header title="Your site visit information is being reviewed." />
      <Container>
        <Notice text="Your site visit is complete! We’re looking it over to make sure it meets our quality standards. You’ll be notified via email when we begin estimating your project." />

        <div className="centered">
          <img src={Image} alt="Site visit graphic" />
        </div>
      </Container>
    </StyledCustomerSurveyReview>
  )
}

export default CustomerSurveyReview
