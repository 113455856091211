import React from 'react'
import styled from 'styled-components'
import Title from '../sharedComponents/Title'
import { colors } from './../../helpers/foundation'

const StyledHeader = styled.div`
  border-bottom: ${props => (props.noBorderBottom ? 'none' : `1px solid ${colors.lightGrey}`)};
  padding: 1.75em 1em;
`

const Header = ({ title, ...rest }) => {
  return (
    <StyledHeader {...rest}>
      <Title tag="h2" text={title} step={2} />
    </StyledHeader>
  )
}

export default Header
