import React, { Component } from 'react'
import styled from 'styled-components'
import { resetScrollEffect } from '../../utils'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import SurveyOptionsSelect from './SurveyOptionsSelect'

class SurveyStyle extends Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount = () => {
    // this.container.current.scrollTop = 0
    // window.scrollTo(0, 0)
    // this.handleScroll()
    this.container.current.scrollTop = 0
  }
  handleScroll = () => {
    this.container.current.scrollIntoView({ behavior: 'smooth' })
  }
  handleClick = itemId => {
    const { isMsStep } = this.props
    const updateObj = {}
    if (isMsStep) {
      updateObj['ms_style'] = itemId
      updateObj['ms_options'] = []
    } else {
      updateObj['style'] = itemId
      updateObj['options'] = []
    }
    // reset options to avoid error/bug
    // updateObj['options'] = []
    this.props
      .updateSurveyServer(this.props.survey._id, { ...updateObj })
      .then(success => console.log('updated survey material'))
  }

  render() {
    const { _styles, survey, isMsStep } = this.props

    return (
      <StyledSurveyStyle ref={this.container}>
        <Header title="Choose a fence style." noBorderBottom />
        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <SurveyOptionsSelect
            items={_styles}
            handleClick={this.handleClick}
            survey={survey}
            columns={3}
            field={isMsStep ? 'ms_style' : 'style'}
          />
        </Container>
      </StyledSurveyStyle>
    )
  }
}

export default SurveyStyle

const StyledSurveyStyle = styled.div``
