import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import isRole from './../../helpers/roles'

import Sidebar from './Sidebar'
import SidebarLayout from './../sharedComponents/layouts/SidebarLayout'
import Loader from './../sharedComponents/Loader'
import EstimateStart from './Start'
import EstimateParts from './Parts'
import EstimateLaborNeeds from './LaborNeeds'
import EstimateExtraServices from './ExtraServices'
// import EstimateDetails from './Details'
import EstimateOverview from './Overview'
import History from './../sharedComponents/History/'
import Button from './../sharedComponents/Button'
import NotPermitted from './../routeUtils/NotPermitted'
import MobileAction from '../sharedComponents/MobileAction'
import { ViewerConsumer, withViewerContext } from './../../contexts/ViewerContext'
import Viewer from './../sharedComponents/Viewer'

class Estimate extends Component {
  state = {
    stepPointer: 0,
  }

  componentDidMount() {
    this.props.estimateGet(this.props.project.estimate._id).then(success => {
      if (success) {
        // update step pointer to be what the currentStep is
        this.setState(prevState => {
          return {
            // change logic here
            stepPointer: this.props.estimate.currentStep,
          }
        })
      }
    })
  }

  toggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    })
  }

  startEstimation = () => {
    console.log('wtf', this.props.project)
    const {
      survey: { details },
    } = this.props.project

    // create and format object from survey.details
    // to estimate.services including new props added
    const servicesFromDetails = []
    for (let key of Object.keys(details)) {
      const detail = details[key]
      if (detail.selected) {
        servicesFromDetails.push({
          name: key,
          text: detail.question,
          images: [...detail.images],
          rate: '0',
          quantity: '0',
        })
      }
    }

    this.props
      .estimateUpdateServer(this.props.estimate._id, {
        status: 'In Progress',
        currentStep: 1,
        services: {
          total: '0',
          services: servicesFromDetails,
        },
        lastStatus: Date.now(),
        from: 'To Do',
        projectId: this.props.project._id,
      })
      .then(success => {
        if (success) {
          this.props.updateProjectStageStatus('estimate', 'In Progress')

          this.setState(prevState => {
            return {
              stepPointer: 1,
            }
          })
        }
      })
  }

  validateStep = step => {
    const { estimate } = this.props
    const { labors } = estimate.labors
    const { stepPointer } = this.state

    if (stepPointer < step) return true

    switch (step) {
      case 1:
        if (estimate.parts.data.length < 1) {
          return false
        } else {
          return true
        }
      case 2:
        if (labors.length < 1) {
          return false
        } else {
          return true
        }
      default:
        return true
    }
  }

  stepNext = () => {
    // const MAX_STEPS = 5

    // if(this.state.stepPointer >= MAX_STEPS) {
    //   return false
    // }

    if (this.state.stepPointer < this.props.estimate.currentStep) {
      // handle if they click back to a previos step and then hit 'Next'
      this.setState(prevState => {
        return {
          stepPointer: prevState.stepPointer + 1,
        }
      })
    } else {
      // Validate the current step
      if (this.validateStep(this.props.estimate.currentStep)) {
        this.props
          .estimateUpdateServer(this.props.estimate._id, {
            currentStep: this.props.estimate.currentStep + 1,
          })
          .then(({ currentStep }) => {
            this.setState(prevState => {
              return {
                stepPointer: currentStep,
              }
            })
          })
      }
    }
  }

  changeStepPointer = (e, stepIdx) => {
    // Go to top of page
    window.scrollTo(0, 0)

    this.setState(prevState => {
      return {
        stepPointer: stepIdx,
      }
    })
  }

  renderComponentsDynamically = () => {
    switch (this.state.stepPointer) {
      case 0:
        return <EstimateStart startEstimation={this.startEstimation} {...this.props} />
      case 1:
        return <EstimateParts {...this.props} />
      case 2:
        return <EstimateLaborNeeds {...this.props} />
      case 3:
        return <EstimateExtraServices {...this.props} />
      case 4:
        return (
          <EstimateOverview
            estimate={this.props.estimate}
            changeStepPointer={this.changeStepPointer}
            {...this.props}
          />
        )
      default:
        return ''
    }
  }

  render() {
    if (this.props.loading) {
      return <Loader centered={true} />
    }

    const { project, user, estimate } = this.props

    if (!(isRole(user).admin || isRole(user).estimate)) {
      return <NotPermitted history={this.props.history} toText="Projects" toUrl="/projects" />
    }

    return (
      <StyledEstimate>
        <ViewerConsumer>
          {/* sets the viewer for images being viewed from the history sidebar */}
          {({ currentImage, unsetImage }) => {
            if (currentImage) {
              return <Viewer currentImage={currentImage} unsetImage={unsetImage} />
            }
          }}
        </ViewerConsumer>
        <SidebarLayout
          statusComponent={
            <Fragment>
              <MobileAction>
                {estimate.currentStep !== 0 &&
                  (estimate.currentStep !== 4 && (
                    <Button
                      variant={this.validateStep(estimate.currentStep) ? 'lg' : 'lg disabled'}
                      text="Next"
                      handleClick={this.validateStep(estimate.currentStep) ? this.stepNext : null}
                      fluid={true}
                    />
                  ))}
              </MobileAction>
              {this.renderComponentsDynamically()}
            </Fragment>
          }
          history={
            <History
              project={project}
              survey={project.survey}
              estimate={project.estimate}
              paperwork={null}
            />
          }
        >
          <Sidebar
            currentStep={estimate.currentStep}
            stepPointer={this.state.stepPointer}
            estimate={estimate}
            stepNext={this.stepNext}
            canStepNext={this.validateStep}
            changeStepPointer={this.changeStepPointer}
          />
        </SidebarLayout>
      </StyledEstimate>
    )
  }
}

export default Estimate

const StyledEstimate = styled.div`
  position: relative;
  padding-bottom: 55px;

  .estimate-body {
    width: 100%;
    border: 1px solid #eee;
    padding-bottom: 4rem;
  }
`

// const StyledContainer = styled.div`
//   position: absolute;
//   right: 0;
//   top: 0;
//   min-height: calc(100vh - 111px);
//   height: 100%;
//   width: ${props => (props.sidebarOpen ? 'calc(100% - 240px)' : '100%')};
//   margin-left: ${props => (props.sidebarOpen ? '240px' : '0px')};
//   transition: margin 0.2s ease-out, width 0.2s ease-out;
// `
