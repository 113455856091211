import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { em } from 'polished'
import Container from './../../sharedComponents/Container'
import MainContent from './../../sharedComponents/layouts/MainContent'
import Title from './../../sharedComponents/Title'
import Icon from './../../sharedComponents/Icon'
import placeholder from './../../../assets/placeholder.jpg'
import { colors, breakpoint } from '../../../helpers/foundation'

const StyledProfile = styled.div`
  color: ${colors.grey3};
  margin-top: 40px;

  .header {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .intro {
    padding-right: 40px;
    display: flex;
    align-items: center;
  }

  .portrait {
    border-radius: 50%;
    margin-right: 1.25em;
  }

  .subtitle {
    font-weight: 600;
  }

  .content {
    margin-bottom: 40px;
    max-width: 550px;
  }

  .details {
    ${breakpoint('medium')} {
      display: flex;
    }
  }

  .column {
    margin-bottom: 20px;

    ${breakpoint('medium')} {
      padding-right: 40px;
      margin-bottom: 0px;
      flex: 0 0 ${(1 / 3) * 100}%;
    }
  }

  .option {
    font-size: ${em('14px')};
    margin-top: 20px;

    ${breakpoint('medium')} {
      margin-top: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: ${colors.grey};

      &:hover,
      &:active,
      &:focus {
        color: ${colors.primary};

        path,
        circle {
          stroke: ${colors.primary};
        }
      }
    }

    path,
    circle {
      transition: stroke 0.15s ease-in-out;
    }

    .text {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
`

export default class Profile extends Component {
  render() {
    const { user } = this.props

    return (
      <MainContent>
        <Container width="880">
          <StyledProfile>
            <div className="header">
              <div className="intro">
                <img
                  className="portrait"
                  src={user.profileImage || placeholder}
                  alt={user.displayName}
                />
                <div>
                  <Title step={1} text={user.displayName} />
                  <span className="subtitle">{user.title}</span>
                </div>
              </div>
              <div className="option">
                <Link to="/settings">
                  <Icon icon="settings" /> <span className="text">My Settings</span>
                </Link>
              </div>
            </div>
            <div className="content">
              <Title step={2} text="About Me" />
              <p>{user.bio}</p>
            </div>
            <div className="details">
              <div className="column">
                <Title step={2} tag="h3" text="Contact" />
                <div>
                  <a className="link" href={`mailto:${user.email}`}>
                    {user.email}
                  </a>
                </div>
                <div>
                  <a className="link" href={`tel:${user.phone}`}>
                    {user.phone}
                  </a>
                </div>
              </div>

              <div className="column">
                <Title step={2} tag="h3" text="Address" />
                {user.address && (
                  <td className="answer">
                    {user.address} <br /> {user.city}, {user.state} {user.zip}
                  </td>
                )}
              </div>

              {user.role !== 'Customer' && (
                <div className="column">
                  <Title step={2} tag="h3" text="Teams" />
                  {user.teams.map(team => {
                    return (
                      <div key={team.name}>
                        {team.name} Team ({team.role})
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </StyledProfile>
        </Container>
      </MainContent>
    )
  }
}
