import React, { Component } from 'react'
import axios from 'axios'
import format from 'date-fns/format'
import isAfter from 'date-fns/is_after'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import differenceInDays from 'date-fns/difference_in_days'
import differenceInHours from 'date-fns/difference_in_hours'
import { faDiaspora } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from './../../../helpers/foundation'
import { expirationDate } from '../../../helpers/time'
import { addProductionUrl } from './../../../helpers/constants'

import Card from './../../sharedComponents/Card'
import Reveal from './../../sharedComponents/Reveal'
import Tag from '../../sharedComponents/Tag'
import Loader from '../../sharedComponents/Loader'
import DeleteProjectModal from './DeleteProjectModal'

const tagColor = {
  survey: `${colors.blue}`,
  estimate: `${colors.purple}`,
  paperwork: `${colors.orange}`,
  overdue: `${colors.danger}`,
  installation: `${colors.pink}`,
}

class ProjectCard extends Component {
  state = {
    menuIsOpen: false,
    timeDistance: null,
    pdfDownloading: false,
    pdfError: null,
    duplicateError: null,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeMenu)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeMenu)
  }

  toggleMenu = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
    })
  }

  closeMenu = e => {
    if (this.reveal && !this.reveal.contains(e.target)) {
      this.setState({
        menuIsOpen: false,
      })
    }
  }

  downloadPDF = projectId => {
    this.setState({ pdfDownloading: true })
    axios
      .get(`${addProductionUrl()}/api/pdfs/${projectId}?q=employee`, {
        responseType: 'blob',
      })
      .then(res => {
        // turn the blob into dataURL, set to a link, click it, then remove it
        // TODO: can probably use existing function from utils to do this
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.props.title}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link)
        this.setState({ pdfDownloading: false })
      })
      .catch(err => {
        this.setState({ pdfError: 'Error', pdfDownloading: false })
      })
  }

  render() {
    const {
      id,
      url,
      title,
      info,
      stage,
      members,
      expiration,
      expirationTerm,
      // createdAt,
      // updatedAt,
      duplicate,
      update,
      user,
      archived,
      compact,
      timeDistance,
      openModal,
      project,
      isAdmin,
      openDeleteProjectModal,
      fakeItem,
    } = this.props

    const today = new Date()
    const { menuIsOpen } = this.state
    const { address, city, state, zip } = info
    const termBasedExpiration = (date, term) => differenceInDays(date, today) < term / 3
    const expirationUrgency = (date, hours = 1) => differenceInHours(date, today) < hours

    const menu = (
      <Reveal key={id}>
        <div className="inner" ref={node => (this.reveal = node)}>
          <div className="item" onClick={() => openModal(this.props)}>
            More Info
          </div>

          <div className="item" onClick={() => this.downloadPDF(id)}>
            Download PDF{' '}
            {this.state.pdfDownloading ? (
              <div className="pdf-loader">
                <Loader />
              </div>
            ) : (
              ''
            )}
          </div>

          {stage === 'survey' && (
            <div
              className="item"
              onClick={() =>
                duplicate(id)
                  .then(proj => true)
                  .catch(err => {
                    this.setState({ duplicateError: err.response.data.message })
                    // clear the message after 3 seconds
                    setTimeout(() => this.setState({ duplicateError: null }), 3000)
                  })
              }
            >
              Duplicate Project
            </div>
          )}

          {this.state.duplicateError && (
            <div className="item error">{this.state.duplicateError}</div>
          )}

          {archived ? (
            <div
              className="item"
              onClick={() =>
                update(id, {
                  archived: false,
                })
              }
            >
              Restore Project
            </div>
          ) : (
            <div
              className="item"
              onClick={() =>
                update(id, {
                  archived: true,
                })
              }
            >
              Archive Project
            </div>
          )}

          {isAdmin && (
            <div
              className="item"
              onClick={() => openDeleteProjectModal(project._id, { isQuote: false })}
            >
              Delete Project
            </div>
          )}

          {isAdmin && (
            <div
              className="item"
              onClick={() =>
                this.props.history.push(`/project/${project._id}/${project.slug}/audit`)
              }
            >
              Audit Page
            </div>
          )}
        </div>
      </Reveal>
    )

    return (
      <Card
        items={members}
        hasMenu={this.props.user !== 'Customer' ? true : false}
        menu={menu}
        menuIsOpen={menuIsOpen}
        toggleMenu={this.toggleMenu}
        link={this.props.fakeItem ? '#' : url}
        compact={true}
        fakeItem={this.props.fakeItem}
      >
        <h2 className="title">{title}</h2>
        {!compact ? (
          <p className="info">
            {address} <br /> {city}, {state} {zip}
          </p>
        ) : (
          <p className="info">
            {address} {city}, {state} {zip}
          </p>
        )}

        {stage === 'estimate' && expiration ? (
          isAfter(today, expiration) ? (
            <span className="info -danger">Expired on {format(expiration, 'MMMM D')}</span>
          ) : (
            <span
              className={`info${termBasedExpiration(expiration, expirationTerm) ? ' -danger' : ''}`}
            >
              Expires in {distanceInWordsToNow(expiration)}
            </span>
          )
        ) : null}

        <div className="footer flex-between">
          <div className="left">
            <Tag variant={fakeItem ? 'skeleton' : ''} color={tagColor[stage]}>
              {stage}
            </Tag>

            {timeDistance && (
              <Tag
                variant={fakeItem ? 'skeleton' : 'bordered'}
                color={expirationUrgency(timeDistance, 1) ? tagColor['overdue'] : null}
              >
                {expirationDate(timeDistance, project.stage === 'paperwork')}
              </Tag>
            )}
            {archived && <Tag color="grey">Archived</Tag>}
          </div>
          <div className="right">
            {project.scheduledBy && project.scheduledBy === 'Customer' && (
              <div className="icon-container">
                <FontAwesomeIcon
                  icon={faDiaspora}
                  className="asterisk-icon"
                  color={colors.primary}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    )
  }
}

export default ProjectCard
