import styled, { keyframes } from 'styled-components'
import { breakpoints, colors, navSize } from '../../../helpers/foundation'

export const Layout = styled.div`
  height: calc(100vh - ${navSize.large});
  display: flex;
  flex-direction: column;
  margin-top: ${navSize.large};
  @media screen and (max-width: ${breakpoints.medium}px) {
    height: ${props => `calc(100% - ${props.user ? '111px' : '60px'})`};
  }
`
