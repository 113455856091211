import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from './../../helpers/foundation'

export const StickyFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fafafa;
  padding: 2rem 1rem;
  .inner {
    width: 100%;
    padding: 0 1em 0 0;
    max-width: ${props => (props.width ? `${props.width}px` : 'auto')};
    margin: auto;
  }

  .split {
    display: flex;
    justify-content: space-between;
  }

  .list {
    > * {
      margin-right: 1rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    padding: 1rem;

    .split {
      display: block;
    }

    .list {
      > * {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    button {
      width: 100%;
      margin-bottom: 0.5em;
    }
  }
`
