import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { zIndexes } from './../../helpers/foundation'

const StyledOverlay = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s ease-out;
  opacity: ${props => (props.active ? '0.3' : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  z-index: ${zIndexes.panelOverlay};
`

const Overlay = props => {
  return <StyledOverlay active={props.active} onClick={props.handleClick} />
}

Overlay.propTypes = {
  active: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
}

export default Overlay
