import styled from 'styled-components'
import { lighten } from 'polished'
import { breakpoints, colors } from '../../../helpers/foundation'

const NavAction = styled.a`
  background-color: ${props => (props.isActive ? colors.grey3 : 'transparent')};
  border-bottom: 4px solid transparent;
  border-color: ${props => (props.isActive ? colors.primary : 'transparent')};
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  transition: 0.15s ease-in-out;
  padding: 1em;

  @media screen and (min-width: ${breakpoints.medium}px) {
    padding: 2em;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: ${lighten(0.15, colors.grey4)};
    border-color: ${colors.primary};

    path,
    circle,
    polyline,
    polygon {
      stroke: ${colors.primary};
    }
  }

  path,
  circle,
  polyline,
  polygon {
    stroke: ${props => (props.isActive ? colors.primary : '#fff')};
  }
`

export default NavAction
