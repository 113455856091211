import React, { Component } from 'react'
import loadImage from 'blueimp-load-image'
import Button from './../../sharedComponents/Button'
import Loader from './../../sharedComponents/Loader'
import Message from './../../sharedComponents/Message'
import Field from './../../sharedComponents/Field'
import Icon from './../../sharedComponents/Icon'
import Bucket from './../../sharedComponents/Bucket'
import Form from './../../sharedComponents/Form'
import FieldGroup from './../../sharedComponents/FieldGroup'
import TimePicker from '../../sharedComponents/TimePicker'
import SecondaryButton from '../../sharedComponents/SecondaryButton'
import PlaceholderImage from './../../../assets/placeholder.jpg'
import { dataURLToBlob } from './../../../helpers/images'
// import { isUserSurveyor } from '../../../helpers/auth'

class SettingsForm extends Component {
  constructor(props) {
    super(props)

    const { displayName, phone, bio, startTime, endTime, address, state, zip, city } = props.user

    this.state = {
      displayName: displayName ? displayName : '',
      phone: phone ? phone : '',
      bio: bio ? bio : '',
      startTime: startTime ? startTime : '',
      endTime: endTime ? endTime : '',
      address: address ? address : '',
      city: city ? city : '',
      state: 'FL',
      zip: zip ? zip : '',
      incompleteWorkHours: false,
      profileImage: {
        url: null,
        name: null,
      },
    }
  }

  updateTimes = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
    })
  }

  resizeDone = img => {
    if (img.type === 'error') {
      console.log('Error loading image')
    } else {
      this.setState(prevState => ({
        profileImage: {
          ...prevState.profileImage,
          url: img.toDataURL('image/jpeg'),
        },
      }))
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFile = e => {
    const file = e.target.files ? e.target.files[0] : null

    this.setState(prevState => ({
      profileImage: {
        ...prevState.profileImage,
        name: file['name'],
      },
    }))

    loadImage(file, img => this.resizeDone(img), {
      maxWidth: 100,
      maxHeight: 100,
      canvas: true,
      crop: true,
      orientation: true,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { user, updateUser, quickEdit } = this.props
    const {
      displayName,
      phone,
      bio,
      profileImage,
      startTime,
      endTime,
      address,
      state,
      zip,
      city,
    } = this.state

    let data = new FormData()

    if (phone !== '' && phone !== user.phone) {
      data.append('phone', phone)
    }

    if (address !== '' && address !== user.address) {
      data.append('address', address)
    }

    if (state !== '' && state !== user.state) {
      data.append('state', state)
    }

    if (zip !== '' && zip !== user.zip) {
      data.append('zip', zip)
    }

    if (city !== '' && city !== user.city) {
      data.append('city', city)
    }

    if (!quickEdit) {
      if (profileImage.url !== null) {
        data.append('file', dataURLToBlob(profileImage.url), profileImage.name)
      }

      if (displayName !== '' && displayName !== user.displayName) {
        data.append('displayName', displayName)
      }

      if (bio !== '' && bio !== user.bio) {
        data.append('bio', bio)
      }

      if (startTime !== '') {
        data.append('startTime', startTime)
      }

      if (endTime !== '') {
        data.append('endTime', endTime)
      }

      if (user.role !== 'Customer') {
        if (
          !startTime.split(':')[0] ||
          !endTime.split(':')[0] ||
          !startTime.split(':')[1] ||
          !endTime.split(':')[1]
        ) {
          this.setState({
            incompleteWorkHours: true,
          })
        } else {
          this.setState({
            incompleteWorkHours: false,
          })

          updateUser(user._id, data)
        }
      } else {
        updateUser(user._id, data)
      }
    } else {
      updateUser(user._id, data)
    }
  }

  render() {
    const { user, error, loading, success } = this.props
    const {
      address,
      city,
      state,
      zip,
      bio,
      displayName,
      phone,
      profileImage,
      incompleteWorkHours,
    } = this.state

    if (loading) {
      return <Loader />
    }

    if (this.props.quickEdit) {
      return (
        <Form onSubmit={this.handleSubmit}>
          {error && <Message type="error" message={this.props.error} />}
          {success && <Message type="success" message="Updated user" />}
          <fieldset>
            <Field>
              <label htmlFor="address">Street Address</label>
              <input
                id="address"
                name="address"
                type="text"
                value={address || ''}
                onChange={this.handleChange}
              />
            </Field>

            <Field>
              <label htmlFor="city">City</label>
              <input
                id="city"
                name="city"
                type="text"
                value={city || ''}
                onChange={this.handleChange}
              />
            </Field>

            <FieldGroup>
              <Field>
                <label htmlFor="state">State</label>
                <select
                  name="state"
                  id="state"
                  type="text"
                  value={state === '' ? user.state : state}
                  onChange={this.handleChange}
                >
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </Field>

              <Field>
                <label htmlFor="zip">Zip</label>
                <input
                  id="zip"
                  name="zip"
                  type="text"
                  value={zip || ''}
                  onChange={this.handleChange}
                />
              </Field>
            </FieldGroup>
            <Field>
              <label htmlFor="phone">Phone</label>
              <input
                id="phone"
                name="phone"
                type="text"
                value={phone || ''}
                onChange={this.handleChange}
              />
            </Field>
          </fieldset>
          <Button type="submit" variant="small" text="Update" />
        </Form>
      )
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        {error && <Message type="error" message={this.props.error} />}
        <fieldset>
          <div className="mbm">
            <Bucket>
              <div className="media">
                {user.profileImage || profileImage.url ? (
                  <img
                    className="portrait"
                    src={profileImage.url ? profileImage.url : user.profileImage}
                    alt={user.displayName}
                  />
                ) : (
                  <img className="portrait" src={PlaceholderImage} alt={user.displayName} />
                )}
              </div>
              <div className="content">
                <Field>
                  <label htmlFor="displayName">Display Name</label>
                  <input
                    type="text"
                    name="displayName"
                    value={displayName}
                    onChange={this.handleChange}
                  />
                </Field>
                <SecondaryButton tag="label" name="profileImage">
                  <Icon icon="survey" />
                  <span>Choose Profile Image</span>
                  <input
                    type="file"
                    name="profileImage"
                    onChange={this.handleFile}
                    accept="image/jpeg"
                  />
                </SecondaryButton>
                <div className="small">{profileImage.name}</div>
              </div>
            </Bucket>
          </div>
          <Field>
            <label htmlFor="phone">Phone</label>
            <input id="phone" name="phone" type="text" value={phone} onChange={this.handleChange} />
          </Field>

          {this.props.user.role !== 'Customer' && (
            <Field invalid={incompleteWorkHours}>
              <TimePicker
                title="My work hours"
                startTime={user.startTime}
                endTime={user.endTime}
                updateTimes={this.updateTimes}
              />
              <span className="error">Please fill out your complete work hours.</span>
            </Field>
          )}

          <Field>
            <label htmlFor="address">Street Address</label>
            <input
              id="address"
              name="address"
              type="text"
              value={address}
              onChange={this.handleChange}
            />
          </Field>

          <Field>
            <label htmlFor="city">City</label>
            <input id="city" name="city" type="text" value={city} onChange={this.handleChange} />
          </Field>

          <FieldGroup>
            <Field>
              <label htmlFor="state">State</label>
              <select
                name="state"
                id="state"
                type="text"
                value={state === '' ? user.state : state}
                onChange={this.handleChange}
              >
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </Field>

            <Field>
              <label htmlFor="zip">Zip</label>
              <input id="zip" name="zip" type="text" value={zip} onChange={this.handleChange} />
            </Field>
          </FieldGroup>

          <Field>
            <label htmlFor="bio">About Me</label>
            <textarea type="text" name="bio" value={bio} onChange={this.handleChange} />
          </Field>
        </fieldset>
        <div className="tar">
          <Button type="submit" variant="small" text="Save" />
        </div>
      </Form>
    )
  }
}

export default SettingsForm
