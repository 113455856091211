import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, breakpoints, navSize, actionBarSize, zIndexes } from './../../helpers/foundation'

const Panel = styled.aside`
  background-color: ${props => props.color || colors.white};
  border-right: ${props => (props.attached ? `1px solid ${colors.lightGrey}` : 'none')};
  padding: ${props => (props.padded ? '1em 2em' : '0')};
  height: calc(100% - ${navSize.small});
  width: 100%;
  max-width: 100%;
  position: fixed;
  border-right: ${props => props.noBorder && 0};

  ${props => {
    if (props.position === 'left') {
      return 'left: 0;'
    } else {
      return 'right: 0;'
    }
  }}

  top: ${navSize.small};
  overflow-y: auto;
  transition: 0.2s ease-out transform;
  transform: translateX(
    ${props => (props.open ? '0' : props.position === 'left' ? '-100%' : '100%')}
  );
  z-index: ${zIndexes.panel};

  @media screen and (min-width: ${breakpoints.medium}px) {
    width: 100%;
    max-width: ${props => props.size || 240}px;
    height: calc(100% - ${navSize.large});
    top: ${navSize.large};
    transform: translateX(
      ${props =>
        props.open
          ? '0'
          : props.position === 'left'
          ? `-${props.size || 240}px`
          : `${props.size || 240}px`}
    );
    background-color: ${props => props.color || colors.white};
  }
`

Panel.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  attached: PropTypes.bool,
  padded: PropTypes.bool,
  position: PropTypes.string,
}

export default Panel
