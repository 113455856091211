import {
  requestingProject,
  requestProjectError,
  updateCurrentProject,
  updatingProject,
  updateProjectError,
  updateStageStatus,
} from './actions'
import axios from 'axios'
import { updateProject as updateProjectInProjects } from '../projects/'

const baseUrl = process.env.REACT_APP_SERVER_URL

export const requestProject = pid => {
  return async dispatch => {
    dispatch(requestingProject())

    try {
      const project = await axios.get(`${baseUrl}/api/projects/${pid}`)

      dispatch(updateCurrentProject(project.data))
    } catch (err) {
      dispatch(
        requestProjectError(
          err.response ? err.response.data.message : 'Could not retrieve project at this time.'
        )
      )
    }
  }
}

export const updateProject = (pid, updates) => {
  return async dispatch => {
    dispatch(updatingProject())

    try {
      const response = await axios.patch(`${baseUrl}/api/projects/${pid}`, updates)

      updateProjectInProjects(response.data.project)
      dispatch(updateCurrentProject(response.data.project))
    } catch (err) {
      dispatch(
        updateProjectError(
          err.response ? err.response.data.message : 'Could not update project at this time.'
        )
      )
    }
  }
}
