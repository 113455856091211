import React from 'react'
import RouterWithAuth from './containers/authContainer'

const App = () => (
  <React.Fragment>
    <RouterWithAuth />
  </React.Fragment>
)

export default App
