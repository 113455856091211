import React, { Component } from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from './../../helpers/foundation'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import FilesTable from './FilesTable'
import GatherFilesModal from './GatherFilesModal'

class GatherFiles extends Component {
  state = {
    filesWithMeta: [],
    modalInfo: null,
    modalBeingUsed: null,
    // needed for when opening revision modal from FilesTable, need to pass
    // the data for props.updateFile to be called within Modal
    modalExtra: null,
    filesReadyToSend: {
      hoa: false,
      permits: false,
      hoaFilesSentToEmail: false,
    },
  }

  componentDidMount = () => {
    // add virtual 'from' property to each array, so when updating an object
    // it will know which array (files, addtionalFiles) to choose from
    this.addVirtualPropsToPaperworkAndReadyCheck()
  }

  componentDidUpdate = (prevProps, prevState) => {
    // check if the contents of a file change, or additionalFile file has changed
    if (
      JSON.stringify(prevProps.paperwork.files) !== JSON.stringify(this.props.paperwork.files) ||
      JSON.stringify(prevProps.paperwork.additionalFiles) !==
        JSON.stringify(this.props.paperwork.additionalFiles)
    ) {
      this.addVirtualPropsToPaperworkAndReadyCheck()
    }
  }

  addVirtualPropsToPaperworkAndReadyCheck = () => {
    let hoaFilesReady = true
    let permitFilesReady = true
    // add virtual 'from' property to each array
    const files = this.props.paperwork.files.map(file => {
      // all prop to tell which array within redux container to update (files/additionalFiles)
      file.from = 'files'
      // check if all files are marked as approved for hoa
      if (this.props.hoaRequired && file.type === 'hoa' && file.status !== 'approved') {
        hoaFilesReady = false
      }
      // check if all files are marked as approved for permits
      if (file.type === 'permit' && file.status !== 'approved') {
        permitFilesReady = false
      }
      // add virtual prop for handling open/close of popover menu
      if (file.menuOpen === undefined) {
        file.menuOpen = false
      }

      if (file.uploading === undefined) {
        file.uploading = false
      }

      return file
    })

    const additionalFiles = this.props.paperwork.additionalFiles.map(file => {
      file.from = 'additionalFiles'
      if (this.props.hoaRequired && file.type === 'hoa' && file.status !== 'approved') {
        hoaFilesReady = false
      }
      if (file.type === 'permit' && file.status !== 'approved') {
        permitFilesReady = false
      }
      if (file.menuOpen === undefined) {
        file.menuOpen = false
      }
      if (file.uploading === undefined) {
        file.uploading = false
      }
      return file
    })

    this.setState(prevState => ({
      // combine files + additionalFiles
      filesWithMeta: [...files, ...additionalFiles],
      filesReadyToSend: {
        hoa: hoaFilesReady,
        permits: permitFilesReady,
      },
    }))
  }

  toggleOpenMenu = fileid => {
    // find file by id, then open/close the menu
    this.setState(prevState => {
      const fileIdx = prevState.filesWithMeta.findIndex(file => file.id === fileid)
      return {
        filesWithMeta: [
          ...prevState.filesWithMeta.slice(0, fileIdx),
          {
            ...prevState.filesWithMeta[fileIdx],
            menuOpen: !prevState.filesWithMeta[fileIdx].menuOpen,
          },
          ...prevState.filesWithMeta.slice(fileIdx + 1),
        ],
      }
    })
  }

  openModal = (type, whichModal, extra = null) => {
    let modalInfo

    if (whichModal === 'newFile') {
      modalInfo = { name: '', responsible: 'ofs', type }
    } else if (whichModal === 'confirmSend') {
      modalInfo = { confirmDelivery: false, type }
      if (type === 'hoa') {
        modalInfo = { ...modalInfo, hoaEmailText: '', hoaEmailValid: false }
      }
    } else if (whichModal === 'confirmApprove') {
      modalInfo = { confirmRecieved: false, type }
    } else if (whichModal === 'revisionNotes') {
      modalInfo = { revisionNotes: extra.revisionNotes ? extra.revisionNotes : '', type }
    } else if (whichModal === 'viewRevisionNotes') {
      modalInfo = { revisionNotes: extra.revisionNotes ? extra.revisionNotes : '', type }
    }

    this.setState(prevState => ({
      modalInfo,
      modalBeingUsed: whichModal,
      modalExtra: extra,
    }))
  }

  closeModal = () => {
    this.setState(prevState => ({
      modalInfo: null,
      modalBeingUsed: null,
    }))
  }

  fileUploading = (fileid, isUploading) => {
    this.setState(prevState => {
      const fileIdx = prevState.filesWithMeta.findIndex(file => file.id === fileid)
      return {
        filesWithMeta: [
          ...prevState.filesWithMeta.slice(0, fileIdx),
          {
            ...prevState.filesWithMeta[fileIdx],
            uploading: isUploading,
          },
          ...prevState.filesWithMeta.slice(fileIdx + 1),
        ],
      }
    })
  }

  render() {
    const { hoaRequired } = this.props
    const { filesWithMeta, modalInfo, modalBeingUsed, modalExtra, filesReadyToSend } = this.state

    return (
      <StyledGatherFiles>
        {modalBeingUsed && (
          <GatherFilesModal
            projectId={this.props.project._id}
            paperworkId={
              this.props.project.paperwork._id
                ? this.props.project.paperwork._id
                : this.props.project.paperwork
            }
            modalInfo={modalInfo}
            modalBeingUsed={modalBeingUsed}
            modalExtra={modalExtra}
            openModal={this.openModal} // used when going from viewRevisionNotes -> revisionNotes
            closeModal={this.closeModal}
            addFile={this.props.addFile}
            updateStep={this.props.updateStep}
            updateFile={this.props.updateFile}
            hoaStatus={this.props.hoaStatus}
            permitsStatus={this.props.permitsStatus}
            readyAndEmailSent={filesReadyToSend.hoa && this.props.hoaEmailSent}
            sendHoaEmail={this.props.sendHoaEmail}
            hoaEmailSent={this.props.hoaEmailSent}
            hoaEmailSending={this.props.hoaEmailSending}
          />
        )}

        <Header title="Complete all the necessary paperwork for this project." />
        <Container>
          {hoaRequired && (
            <FilesTable
              title="HOA Files"
              tkey="hoa"
              projectId={this.props.project._id}
              paperworkId={
                this.props.project.paperwork._id
                  ? this.props.project.paperwork._id
                  : this.props.project.paperwork
              }
              loading={this.props.loading}
              files={filesWithMeta.filter(file => file.type === 'hoa')}
              updateFile={this.props.updateFile}
              removeFile={this.props.removeFile}
              toggleOpenMenu={this.toggleOpenMenu}
              openModal={this.openModal}
              ready={filesReadyToSend.hoa}
              readyAndEmailSent={filesReadyToSend.hoa && this.props.hoaEmailSent}
              status={this.props.hoaStatus}
              fileUploading={this.fileUploading}
              hoaEmail={true}
              hoaEmailSent={this.props.hoaEmailSent}
              hoaEmailError={this.props.hoaEmailError}
              sendHoaEmail={this.props.sendHoaEmail}
              hoaEmailSending={this.props.hoaEmailSending}
            />
          )}

          <FilesTable
            title="Permit Files"
            tkey="permit"
            loading={this.props.loading}
            projectId={this.props.project._id}
            paperworkId={
              this.props.project.paperwork._id
                ? this.props.project.paperwork._id
                : this.props.project.paperwork
            }
            files={filesWithMeta.filter(file => file.type === 'permit')}
            updateFile={this.props.updateFile}
            removeFile={this.props.removeFile}
            toggleOpenMenu={this.toggleOpenMenu}
            openModal={this.openModal}
            ready={filesReadyToSend.permits}
            status={this.props.permitsStatus}
            fileUploading={this.fileUploading}
          />
        </Container>
      </StyledGatherFiles>
    )
  }
}

export default GatherFiles

const StyledGatherFiles = styled.div`
  position: relative;
  thead {
    .filetype-th {
      width: 30%;
    }
    .ourfiles-th {
      width: 30%;
    }
    .customerfiles-th {
      width: 30%;
    }
    .status-th {
      width: 10%;
    }
  }

  tbody {
    td {
      position: relative;
      font-size: 14px;
      color: ${colors.grey3};
      padding: 1.5em 1.25em;

      &.flex-td {
        display: flex;
      }
      b {
        font-weight: 600;
        margin: 0;
      }

      .grey {
        color: ${colors.grey};
      }
    }
  }

  .action {
    padding: 1em;

    button {
      margin-bottom: 0;
    }
  }

  .menu {
    position: relative;
    left: 0;
    top: 0;

    .item {
      span {
        margin-left: 1em;
      }
    }

    .menu-section {
      border-bottom: 1px solid ${colors.grey7};
    }

    &.pl {
      > div {
        left: -35px;
      }
    }
  }

  .dots {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 2px;

    &.ml {
      margin-right: 1em;
    }

    .dot {
      cursor: pointer;
      background-color: ${colors.grey};
      height: 4px;
      width: 4px;
      margin: 1px 0;
      border-radius: 50%;
    }
  }

  .view-icon {
    margin: 0 1em;
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }

  .hidden-input {
    display: none;
    width: 0;
    height: 0;
  }

  .revise-icon {
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }

  .action-row {
    display: flex;
    /* justify-content: space-between; */
    /* align-self: auto; */

    @media screen and (max-width: ${breakpoints.medium}px) {
      display: block;
    }
  }
`
