import React, { Component } from 'react'
import { blobToDataURL } from './../../../../helpers/images'
import { isURL, downloadURL, downloadDataURI } from './../../../../utils'

import SecondaryButton from '../../../sharedComponents/SecondaryButton'
import Icon from '../../../sharedComponents/Icon'
import Card from './Card'

class FileCard extends Component {
  handleFile = (e, fileid, whichArr) => {
    const file = e.target.files[0]
    blobToDataURL(file, dataUrl => {
      this.props.customerUpdateFile(
        this.props.projectId,
        this.props.paperworkId,
        fileid,
        { filename: file.name, filepath: dataUrl, status: null, revisionNotes: '' },
        whichArr
      )
    })
  }

  render() {
    const { file } = this.props
    const revision = file.status && file.status === 'revise'
    const uploaded = file.filename && file.filepath
    const approved = file.status && file.status === 'approved'
    const filepath = file.filepath

    const status = () => {
      if (revision) {
        return 'danger'
      } else if (uploaded) {
        return 'success'
      } else {
        return 'default'
      }
    }

    return (
      <Card status={status()}>
        <div className="header">
          <h3 className="title">{file.name}</h3>

          {approved && <Icon icon="solidCheck" color="success" />}
        </div>

        {filepath && (
          <div
            className="info"
            onClick={e => {
              isURL(file.filepath)
                ? downloadURL(file.filepath, file.filename)
                : downloadDataURI(file.filepath, file.filename)
            }}
          >
            <Icon icon="download" color="grey" />
            <span className="meta">{file.filename}</span>
          </div>
        )}

        {!approved && (
          <SecondaryButton tag="label" name="profileImage" icon="fill">
            <Icon icon="upload" />
            <span>{uploaded ? 'Replace File' : 'Upload Your File'}</span>
            <input
              type="file"
              name="profileImage"
              onChange={e => this.handleFile(e, file.id, file.from)}
              // accept="image/jpeg"
            />
          </SecondaryButton>
        )}

        <div className="extra">
          {revision && (
            <p className="message">
              {file.revisionNotes.length > 0 ? file.revisionNotes : 'No revision notes provided.'}
            </p>
          )}
        </div>
      </Card>
    )
  }
}

export default FileCard
