import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { connect } from 'react-redux'
import { groupBy, chain, isEmpty } from 'lodash'
import { addProductionUrl } from './../../../helpers/constants'
import { requestProject } from './../../../redux/modules/project/operations'
import MainContent from './../../sharedComponents/layouts/MainContent'
import Container from './../../sharedComponents/Container'
import Header from './../../sharedComponents/Header'
import TextLink from './../../sharedComponents/TextLink'
import Sector from './../../sharedComponents/Sector'
import Loader from './../../sharedComponents/Loader'
import Actions from './../../sharedComponents/History/Actions'

class Audit extends Component {
  state = {
    projectActionsLoading: false,
    projectActions: null,
  }
  componentDidMount = () => {
    this.setState(prevState => ({ projectActionsLoading: true }))
    axios
      .get(`${addProductionUrl()}/api/actions/${this.props.match.params.id}`)
      .then(response => {
        if (response.data) {
          const groupedActions = chain(response.data)
            .map((action, i) => {
              const stage = action.type.split('_')[0].toLowerCase()
              return {
                stage,
                ...action,
              }
            })
            .groupBy('stage')
            .value()

          this.setState(prevState => ({
            projectActions: groupedActions,
            projectActionsLoading: false,
          }))
        }
      })
      .catch(err => {
        console.log('got err', err)
        this.setState(prevState => ({
          projectActionsLoading: false,
        }))
      })
  }
  render() {
    const { projectActionsLoading, projectActions } = this.state

    return (
      <StyledAudit>
        <MainContent>
          <Header title={`Audit for project ${this.props.match.params.slug}`} />
          <Container>
            {!projectActionsLoading && (projectActions && isEmpty(projectActions)) && (
              <>There are no actions for this project.</>
            )}
            {projectActionsLoading && <Loader />}

            {!projectActionsLoading && (projectActions && !isEmpty(projectActions)) && (
              <>
                <TextLink color="primary" onClick={this.props.history.goBack}>
                  Go Back
                </TextLink>
                <br />
                <br />
                <Sector title="Survey" color="white" bordered={true}>
                  {!projectActions.survey || projectActions.survey.length < 1 ? (
                    <div className="underline">No Survey actions yet.</div>
                  ) : (
                    <Actions actions={projectActions.survey} />
                  )}
                </Sector>
                <Sector title="Estimate" color="white" bordered={true}>
                  {!projectActions.estimate || projectActions.estimate.length < 1 ? (
                    <div className="underline">No Estimate actions yet.</div>
                  ) : (
                    <Actions actions={projectActions.estimate} />
                  )}
                </Sector>
                <Sector title="Paperwork" color="white" bordered={true}>
                  {!projectActions.paperwork || projectActions.paperwork.length < 1 ? (
                    <div className="underline">No Paperwork actions yet.</div>
                  ) : (
                    <Actions actions={projectActions.paperwork} />
                  )}
                </Sector>
                <Sector title="Installation" color="white" bordered={true}>
                  {!projectActions.installation || projectActions.installation.length < 1 ? (
                    <div className="underline">No Installation actions yet.</div>
                  ) : (
                    <Actions actions={projectActions.installation} />
                  )}
                </Sector>
              </>
            )}
          </Container>
        </MainContent>
      </StyledAudit>
    )
  }
}

export default Audit

const StyledAudit = styled.div`
  .underline {
    text-decoration: underline;
    font-size: 14px;
    padding: 0.5em 1em;
  }
`
