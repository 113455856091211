import styled from 'styled-components'
import { actionBarSize, breakpoints, zIndexes } from '../../helpers/foundation'

const AntiMobileAction = styled.div`
  @media screen and (max-width: ${breakpoints.medium}px) {
    display: none;
  }
`

export default AntiMobileAction
