import React from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'

const Sector = ({ color, bordered, size, title, ...props }) => {
  const StyledSector = styled.div`
    padding: ${size === 'lg' ? '2em' : '1em'};
    position: relative;
    background-color: ${color ? color : colors.grey5};
    border: ${bordered ? `1px solid ${colors.lightGrey}` : 'none'};
    border-radius: 4px;
    margin-bottom: 1em;

    > *:last-child {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 0.5em;
    }
  `

  return (
    <StyledSector>
      {title && <h3>{title}</h3>}
      {props.children}
    </StyledSector>
  )
}

export default Sector
