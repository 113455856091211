import React from 'react'
import styled from 'styled-components'
import { breakpoints, actionBarSize, navSize } from './../../../../helpers/foundation'

import Button from './../../../sharedComponents/Button'

const BlueprintComments = ({ active = true, ...props }) => {
  return (
    <StyledBlueprintComments>
      <div className="comments">
        {props.comments.map((comment, i) => {
          const commentNumber = i + 1

          return (
            <div key={comment._id} className="comment">
              <div
                className="comment-head"
                style={{
                  borderBottom: `1px solid ${comment.hexcode === '' ? '#CED4DA' : comment.hexcode}`,
                }}
              >
                <div
                  className="number"
                  style={{
                    color: `${comment.hexcode === '' ? '#CED4DA' : comment.hexcode}`,
                  }}
                >
                  {commentNumber < 10 ? `0${commentNumber}` : commentNumber}
                </div>
                {active && comment.user === props.user._id && (
                  <a className="edit" onClick={e => props.openEditComment(e, comment._id)}>
                    edit
                  </a>
                )}
              </div>

              <div className="text">{comment.text}</div>
            </div>
          )
        })}
      </div>
    </StyledBlueprintComments>
  )
}

export default BlueprintComments

const StyledBlueprintComments = styled.div`
  position: relative;
  background-color: #f7f8f9;
  padding: 0.5em 0.5em ${actionBarSize.small};
  height: 100%;

  @media screen and (min-width: ${breakpoints.medium}px) {
    padding: 0.5em;
    display: block;
    flex: 0 0 300px;
    height: calc(100vh - ${actionBarSize.small} - ${navSize.large});
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.aboveTablet}px) {
    height: 30%;
  }

  .comments {
    width: 100%;

    @media screen and (min-width: ${breakpoints.medium}px) {
      height: calc(100% - 96px);
      overflow-y: auto;
      padding: 0.5em;
    }

    @media screen and (max-width: ${breakpoints.aboveTablet}px) {
      height: 100%;
    }
  }

  .comment {
    margin-bottom: 2em;

    .comment-head {
      position: relative;
      display: flex;
      justify-content: space-between;

      .number {
        font-weight: 600;
        font-size: 1.2em;
      }

      .edit {
        position: relative;
        top: 4px;
        font-size: 0.9em;
        cursor: pointer;
        color: #ced4da;
      }
    }

    .text {
      font-size: 0.9em;
      color: #868e96;
    }
  }

  .comment-actions {
    text-align: center;
    width: 100%;
    padding: 1em 0 2em;

    @media screen and (min-width: ${breakpoints.medium}px) {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    > button {
      margin: 0 auto;
    }
  }
`
