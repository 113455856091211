import React, { Component } from 'react'
import styled from 'styled-components'

import Field from './../../sharedComponents/Field'
import SecondaryButton from './../../sharedComponents/SecondaryButton'

class RemarkForm extends Component {
  state = {
    remark: '',
    error: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <form action="">
        <Field>
          <textarea
            id="remark"
            name="remark"
            value={this.state.remark}
            onChange={this.handleChange}
          />
        </Field>
        <SecondaryButton
          tag="label"
          name="addNewFile"
          handleClick={e => this.props.submitRemark(this.state.remark)}
        >
          <span>Submit</span>
        </SecondaryButton>
      </form>
    )
  }
}

const StyledRemarkForm = styled.div``

export default RemarkForm
