import React, { Component } from 'react'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import RadioButton from './../sharedComponents/RadioButton'
import RadioGroup from './../sharedComponents/RadioGroup'
import Button from './../sharedComponents/Button'
import Modal from './../sharedComponents/Modal'

class SurveyMultiStyle extends Component {
  state = {
    promptOpen: false,
  }

  handleChange = multiStyleStatus => {
    const { survey } = this.props
    const { isMultiStyle } = survey

    // handle if already past multiStyle
    if (survey.currentStep > 5) {
      if (multiStyleStatus && !isMultiStyle) {
        this.props.updateSurveyServer(survey._id, {
          isMultiStyle: true,
          currentStep: 5,
        })
      }

      if (!multiStyleStatus && isMultiStyle) {
        this.openPrompt()
      }
    } else {
      this.props.updateSurveyServer(survey._id, {
        isMultiStyle: multiStyleStatus,
      })
    }
  }

  openPrompt = () => {
    this.setState({
      promptOpen: true,
    })
  }

  closePrompt = () => {
    this.setState({
      promptOpen: false,
    })
  }

  continueChangingMs = () => {
    const { survey } = this.props
    this.props.resetMaterialAndChildren(survey._id, 'ms', null)
    this.closePrompt()
  }

  render() {
    const { survey } = this.props
    const { promptOpen } = this.state

    return (
      <div>
        {promptOpen && (
          <Modal centered={true} close={this.closePrompt}>
            <div>
              <h3 className="title">
                This will remove all of the data for the second material. The Photos, Details,
                Blueprint, and Notes will not be removed, but you will have to fill out information
                for the second material before returning to them.
              </h3>
              <div className="options">
                <Button variant="danger" text="Cancel" handleClick={this.closePrompt} />
                <Button variant="primary" text="Continue" handleClick={this.continueChangingMs} />
              </div>
            </div>
          </Modal>
        )}
        <Header title="Will this be a multi-style fence?" noBorderBottom />
        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <RadioGroup split columns={2}>
            <RadioButton
              id="multiStyle-1"
              itemId="multiStyle-1"
              name="multiStyle"
              label="No"
              value="no"
              checked={survey.isMultiStyle ? false : true}
              handleChange={() => this.handleChange(false)}
              fit={true}
              columns={2}
            />
            <RadioButton
              id="multiStyle-2"
              itemId="multiStyle-2"
              name="multiStyle"
              label="Yes"
              value="no"
              checked={survey.isMultiStyle ? true : false}
              handleChange={() => this.handleChange(true)}
              fit={true}
            />
          </RadioGroup>
        </Container>
      </div>
    )
  }
}

export default SurveyMultiStyle
