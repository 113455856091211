import { combineReducers } from 'redux'
import { startOfWeek, endOfWeek, getMonth, getYear } from 'date-fns'
import types from './types'

const initState = {
  loadingQuote: false,
  quote: null,
  quoteExists: true,
  quoteValid: true,

  loadingEvents: true,
  confirmingTime: false,
  error: null,
  customTimeError: null,
  confirmTimeError: null,
  slots: [],
  selectedTime: null,
  start: startOfWeek(new Date()),
  end: endOfWeek(new Date()),

  calendar: {
    currentYear: getYear(new Date()),
    currentMonth: getMonth(new Date()),
    selectedMonth: getMonth(new Date()),
  },
}

const eventsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_QUOTE:
      return {
        ...state,
        loadingQuote: action.payload.loadingQuote,
        // loadingEvents: action.payload.loadingEvents,
        quote: action.payload.quote,
        quoteExists: action.payload.quoteExists,
        quoteValid: action.payload.quoteValid,
      }
    case types.EVENTS_RECEIVED:
      return {
        ...state,
        loadingEvents: false,
        slots: action.payload.slots,
      }
    case types.CHANGE_WEEK:
      return {
        ...state,
        loadingEvents: true,
        start: action.payload.start,
        end: action.payload.end,
        slots: [],
      }
    case types.CHANGE_MONTH:
      return {
        ...state,
        loadingEvents: true,
        start: action.payload.start ? action.payload.start : state.start,
        end: action.payload.end ? action.payload.end : state.end,
        slots: [],
        calendar: {
          ...state.calendar,
          currentYear:
            action.payload.year && state.calendar.currentYear !== action.payload.year
              ? action.payload.year
              : state.calendar.currentYear,
          currentMonth: action.payload.month,
          selectedMonth: action.payload.month,
        },
      }
    case types.SELECT_TIME:
      return {
        ...state,
        selectedTime: action.payload.slot,
      }
    case types.CONFIRMING_TIME:
      return {
        ...state,
        confirmingTime: true,
      }
    case types.TIME_CONFIRMED:
      return {
        ...state,
        confirmingTime: false,
      }
    case types.TIME_CONFIRMED_ERROR:
      return {
        ...state,
        confirmingTime: false,
        confirmTimeError: action.payload.error,
      }
    case types.ERROR:
      return {
        ...state,
        loadingEvents: false,
        error: action.payload.error,
      }
    case types.CUSTOM_TIME_ERROR:
      return {
        ...state,
        loadingEvents: false,
        customTimeError: action.payload.error,
      }
    case types.RESET:
      return initState
    default:
      return state
  }
}

const reducer = combineReducers({
  events: eventsReducer,
})

export default reducer
