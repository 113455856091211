import React, { Component } from 'react'
import Button from './Button'
import Field from './Field'
import Loader from './Loader'
import isEmpty from 'validator/lib/isEmpty'

class CreateProjectForm extends Component {
  state = {
    fields: {
      address: '',
      city: '',
      state: 'FL',
      zip: '',
      notes: '',
      desiredHeight: '',
      estimatedFootage: '',
    },
    errors: {},
    valid: false,
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState(
      {
        errors: this.validateFields(),
      },
      () => {
        const errors = Object.keys(this.state.errors)
          .map(error => {
            return this.state.errors[error]
          })
          .filter(err => {
            return err
          })

        if (errors.length === 0 && !this.props.loading) {
          this.props.createProject(this.state.fields, this.props.customer)

          this.props.closePanel()
        }
      }
    )
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    })
  }

  validateFields = () => {
    const { address, city, state, zip } = this.state.fields

    const errors = {
      address: isEmpty(address) ? true : false,
      city: isEmpty(city) ? true : false,
      state: isEmpty(state) ? true : false,
      zip: isEmpty(zip) ? true : false,
    }

    return errors
  }

  render() {
    const { errors } = this.state
    const { address, city, state, zip, notes, desiredHeight, estimatedFootage } = this.state.fields
    const { loading } = this.props

    return (
      <React.Fragment>
        <h3>Create new project</h3>
        <form onSubmit={e => this.handleSubmit(e)} noValidate>
          <fieldset>
            <Field invalid={errors.address}>
              <label htmlFor="address">Street Address*</label>
              <input
                id="address"
                name="address"
                type="text"
                value={address}
                onChange={this.handleChange}
              />
              <span className="error">Please include the street.</span>
            </Field>

            <Field invalid={errors.city}>
              <label htmlFor="city">City*</label>
              <input id="city" name="city" type="text" value={city} onChange={this.handleChange} />
              <span className="error">Please include the city.</span>
            </Field>

            <Field invalid={errors.state}>
              <label htmlFor="state">State*</label>
              <select
                name="state"
                id="state"
                type="text"
                value={state}
                onChange={this.handleChange}
              >
                <option value="FL">Florida</option>
              </select>
              <span className="error">Please include the state.</span>
            </Field>

            <Field invalid={errors.zip}>
              <label htmlFor="zip">Zip*</label>
              <input id="zip" name="zip" type="text" value={zip} onChange={this.handleChange} />
              <span className="error">Please include the zip.</span>
            </Field>

            <Field>
              <label htmlFor="estimatedFootage">Estimated Footage (ft)</label>
              <input
                id="estimatedFootage"
                name="estimatedFootage"
                type="number"
                value={estimatedFootage}
                onChange={this.handleChange}
              />
            </Field>

            <Field>
              <label htmlFor="desiredHeight">Desired Height (ft)</label>
              <input
                id="desiredHeight"
                name="desiredHeight"
                type="number"
                value={desiredHeight}
                onChange={this.handleChange}
              />
            </Field>

            <Field>
              <label htmlFor="desiredHeight">Additional Notes</label>
              <textarea id="notes" name="notes" value={notes} onChange={this.handleChange} />
            </Field>
          </fieldset>

          <Button type="submit" variant={loading ? 'disabled' : ''} text="Create" />

          {loading ? <Loader /> : ''}
        </form>
      </React.Fragment>
    )
  }
}

export default CreateProjectForm
