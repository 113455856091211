import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

import Image from './images/estimate-creation.png'

const CustomerEstimateBeingCreated = props => {
  return (
    <StyledCustomerEstimateBeingCreated>
      <Header title="Your estimate is being created!" />
      <Container>
        <Notice text="Now that your site visit has been reviewed, we’re working on creating an estimate for your project. You’ll get an email when it’s ready for you to approve!" />
        <div className="centered">
          <img src={Image} alt="Estimate creation graphic" />
        </div>
      </Container>
    </StyledCustomerEstimateBeingCreated>
  )
}

export default CustomerEstimateBeingCreated

const StyledCustomerEstimateBeingCreated = styled.div`
  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
