import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import CustomerTutorial from './tutorial/'
import CustomerDashboard from './CustomerDashboard'
import Customer from './index'
import Controls from './../pages/Projects/Controls'
import Loader from './../sharedComponents/Loader'
import MainContent from './../sharedComponents/layouts/MainContent'
import Search from './../sharedComponents/Search'
import NotFound from './../routeUtils/notFound'

class CustomerRouter extends Component {
  state = {
    filteredProjects: [],
  }

  componentDidMount() {
    this.props.customerFetchData(this.props.user._id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer.projects !== this.props.customer.projects) {
      this.setState({
        filteredProjects: this.props.customer.projects,
      })
    }
  }

  activeSearch = projects => {
    this.setState({
      filteredProjects: projects,
    })
  }

  render() {
    const { filteredProjects } = this.state

    if (this.props.loading) {
      return <Loader />
    }

    // Render route for other roles to act on behalf of customer
    if (this.props.user.role !== 'Customer') {
      return (
        <Switch>
          <Route
            exact={true}
            path="/project/:pid/:slug/customer"
            render={props => {
              return <Customer projectId={props.match.params.pid} {...props} {...this.props} />
            }}
          />
          <Route component={NotFound} />
        </Switch>
      )
    }

    return (
      <MainContent>
        {this.props.customer.showTutorial ? (
          <CustomerTutorial
            customerId={this.props.customer._id}
            customerHideTutorial={this.props.customerHideTutorial}
          />
        ) : (
          <React.Fragment>
            <Route
              exact={true}
              path="/"
              render={props => (
                <Controls>
                  <div className="search-bar">
                    <Search
                      handleChange={this.activeSearch}
                      items={this.props.customer.projects}
                      properties={['name', 'additionalClientInfo.address']}
                      ref="search"
                    />
                  </div>
                </Controls>
              )}
            />
            <Switch>
              <Route
                exact={true}
                path="/"
                render={props => (
                  <CustomerDashboard projects={filteredProjects} {...props} {...this.props} />
                )}
              />

              <Route
                exact={true}
                path="/project/:pid/:slug"
                render={props => {
                  return <Customer projectId={props.match.params.pid} {...props} {...this.props} />
                }}
              />

              <Route component={NotFound} />
            </Switch>
          </React.Fragment>
        )}
      </MainContent>
    )
  }
}

export default CustomerRouter
