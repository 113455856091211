import React, { Component } from 'react'
import styled from 'styled-components'
import { actionBarSize, navSize, breakpoints } from './../../helpers/foundation'
// import familyImage from './../../assets/familyImage.png'
import height2 from './../../assets/height-2.svg'
import height3 from './../../assets/height-3.svg'
import height4 from './../../assets/height-4.svg'
import height5 from './../../assets/height-5.svg'
import height6 from './../../assets/height-6.svg'
import height7 from './../../assets/height-7.svg'
import height8 from './../../assets/height-8.svg'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'

class SurveyHeight extends Component {
  componentDidMount = () => {
    const { survey, selectedMaterial, isMsStep } = this.props
    // dont set initial height if height is already set
    const heightOrMsHeight = isMsStep ? 'ms_height' : 'height'
    if (survey[heightOrMsHeight]) {
      return false
    }
    this.props
      .updateSurveyServer(survey._id, {
        [heightOrMsHeight]: selectedMaterial.minHeight,
      })
      .then(success => console.log('set default height!'))
  }

  // amt will either be +0.5 or -0.5
  changeHeight = amt => {
    const { survey, selectedMaterial } = this.props
    const heightOrMsHeight = this.props.isMsStep ? 'ms_height' : 'height'

    if (amt < 0 && survey[heightOrMsHeight] + amt < selectedMaterial.minHeight) {
      return false
    }
    if (amt > 0 && survey[heightOrMsHeight] + amt > selectedMaterial.maxHeight) {
      return false
    }

    this.props.updateSurveyServer(survey._id, {
      [heightOrMsHeight]: survey[heightOrMsHeight] + amt,
    })
  }

  heightVisual = () => {
    const height = this.props.survey[this.props.isMsStep ? 'ms_height' : 'height']
    switch (height) {
      case 2:
        return <img src={height2} alt="" />
      case 2.5:
        return <img src={height2} alt="" />
      case 3:
        return <img src={height3} alt="" />
      case 3.5:
        return <img src={height3} alt="" />
      case 4:
        return <img src={height4} alt="" />
      case 4.5:
        return <img src={height4} alt="" />
      case 5:
        return <img src={height5} alt="" />
      case 5.5:
        return <img src={height5} alt="" />
      case 6:
        return <img src={height6} alt="" />
      case 6.5:
        return <img src={height6} alt="" />
      case 7:
        return <img src={height7} alt="" />
      case 7.5:
        return <img src={height7} alt="" />
      case 8:
        return <img src={height8} alt="" />
      default:
        return ''
    }
  }

  render() {
    const { isMsStep, survey } = this.props
    return (
      <StyledSurveyHeight>
        <Header title="Choose a height for your fence." noBorderBottom />

        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <div className="sh-content">
            <div className="bottom">
              <div className="height-img-container">{this.heightVisual()}</div>

              <div className="height-selector">
                <div className="up" onClick={e => this.changeHeight(0.5)}>
                  +
                </div>
                <div className="number">{survey[isMsStep ? 'ms_height' : 'height']}</div>
                <div className="down" onClick={e => this.changeHeight(-0.5)}>
                  -
                </div>
              </div>
            </div>
          </div>
        </Container>
      </StyledSurveyHeight>
    )
  }
}

export default SurveyHeight

const StyledSurveyHeight = styled.div`
  .sh-content {
    position: relative;
    max-width: 38em;
    height: calc(100vh - 69px - 64px - 106px - 42px);

    @media screen and (max-width: ${breakpoints.medium}px) {
      height: calc(100vh - 69px - 60px - 106px - 83px);
    }

    .bottom {
      position: absolute;
      display: flex;
      align-items: flex-end;
      ${'' /* position: absolute;
      bottom: 0em; */}
      bottom: 8%;

      @media screen and (max-width: ${breakpoints.medium}px) {
        position: absolute;
        bottom: 100px;
        max-height: 500px;
      }
    }
  }

  .height-img-container {
    img {
      width: 600px;
    }
  }

  .height-selector {
    /* position: relative; */
    background: #fff;
    margin-left: 40px;
    margin-bottom: 12px;

    .up,
    .number,
    .down {
      font-weight: 700;
      font-size: 3rem;
      display: block;
      width: 6rem;
      height: 6rem;
      text-align: center;
      user-select: none;
      line-height: 5rem;
    }

    .up,
    .down {
      background-color: #eee;
      cursor: pointer;
      font-weight: 700;
      font-size: 4rem;
    }

    .number {
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }
  }
`
