import React from 'react'
import styled from 'styled-components'
import { em } from 'polished'

import { colors } from './../../../../helpers/foundation'

const InfoStep = ({ title, image, alt, text }) => {
  const StyledStep = styled.div`
    text-align: center;
    padding: 20px;

    .title {
      color: ${colors.primary};
      font-size: ${em('40px')};
    }

    .image {
      width: 100%;
      max-width: ${em('450px')};
      margin: 50px auto 0;
    }

    .text {
      margin-top: 50px;
      max-width: ${em('400px')};
      margin: 50px auto 0;
      font-size: ${em('18px')};
      line-height: ${em('24px')};
    }
  `

  return (
    <StyledStep>
      <h3 className="title">{title}</h3>
      <img className="image" src={image} alt={alt} />
      <p className="text">{text}</p>
    </StyledStep>
  )
}

export default InfoStep
