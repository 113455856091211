import React from 'react'
import styled from 'styled-components'
import { em } from 'polished'
import MainContent from '../../sharedComponents/layouts/MainContent'
import { colors, navSize, breakpoints } from './../../../helpers/foundation'

const StyledContainer = styled.div`
  @media screen and (min-width: ${breakpoints.medium}px) {
    display: flex;
    flex-wrap: nowrap;
  }

  > .container {
    background: ${colors.white};
    padding: 1em;
    max-width: 500px;
    margin: 0 auto;

    @media screen and (min-width: ${breakpoints.medium}px) {
      height: calc(100vh - ${navSize.large});
      max-width: 100%;
      width: 500px;
      flex-grow: 0;
      padding: 5em;
    }

    .title {
      color: ${colors.primary};
      font-size: ${em('24px')};
      text-align: center;

      @media screen and (min-width: ${breakpoints.medium}px) {
        text-align: left;
      }
    }
  }

  > .background {
    background-image: url(${props => props.background});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 500px;

    @media screen and (min-width: ${breakpoints.medium}px) {
      flex: 1 1 auto;
      height: auto;
    }
  }
`

const FormLayout = ({ background, form }) => {
  return (
    <MainContent>
      <StyledContainer background={background}>
        <div className="container">
          <h1 className="title">Welcome to OFS Online!</h1>
          {form}
        </div>
        <div className="background" />
      </StyledContainer>
    </MainContent>
  )
}

export default FormLayout
