import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from '../../helpers/foundation'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import Modal from './../sharedComponents/Modal'
import Button from './../sharedComponents/Button-NEW'
import Field from './../sharedComponents/Field'

// used for updating server only when user done typing
let timeout = null

class SurveyNotes extends Component {
  state = {
    homeownersRequest: this.props.survey.notes.homeownersRequest.answer || '',
    salesmanNotes: this.props.survey.notes.salesmanNotes.answer || '',
    specialConditions: this.props.survey.notes.specialConditions.answer || '',
  }

  updateValue = (e, noteKey) => {
    const tarName = e.target.name
    const tarVal = e.target.value
    this.setState(prevState => {
      return {
        [tarName]: tarVal,
      }
    })
    // call after user is done typeing
    clearTimeout(timeout)
    timeout = setTimeout(() => this.serverUpdate(tarName), 500)
  }

  serverUpdate = field => {
    const { survey } = this.props
    const fieldToUpdate = `notes.${field}.answer`
    this.props.updateSurveyServer(survey._id, {
      [fieldToUpdate]: this.state[field],
    })
  }

  render() {
    const {
      survey,
      completeSurveyModalOpen,
      closeSurveyModal,
      completeSurvey,
      completeSurveyAndCreateNew,
      isOnline,
    } = this.props
    return (
      <StyledSurveyNotes>
        {completeSurveyModalOpen && (
          <Modal centered={true} close={closeSurveyModal} width="456">
            <div>
              <h3 className="title">
                Are you sure you want to complete and submit this survey for review?
              </h3>

              <div className="modal-options btn-row">
                <Button text="No" variant="bordered-danger lg" handleClick={closeSurveyModal} />
                <Button text="Yes" variant="primary lg" handleClick={completeSurvey} />
              </div>

              <div className="modal-options secondary">
                <h3 className="title">
                  Would you like to complete this survey and create another survey?
                </h3>
              </div>

              <div className="modal-options">
                <Button
                  text="Complete and create another survey"
                  variant="bordered-grey lg"
                  fluid={true}
                  handleClick={completeSurveyAndCreateNew}
                />
              </div>
            </div>
          </Modal>
        )}
        <Header title="What else may be relevant to the project?" noBorderBottom />
        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          {!isOnline && (
            <Notice text="Wait until connection is regained to submit this survey. Do not close this tab." />
          )}
          <div className="sn-inner">
            <form className="questions">
              {Object.keys(survey.notes).map(noteKey => {
                const note = survey.notes[noteKey]
                return (
                  <Field className="question" key={noteKey}>
                    <label>{note.question}</label>
                    <textarea
                      name={noteKey}
                      value={this.state[noteKey]}
                      onChange={this.updateValue}
                    />
                  </Field>
                )
              })}
            </form>
          </div>
        </Container>
      </StyledSurveyNotes>
    )
  }
}

export default SurveyNotes

const StyledSurveyNotes = styled.div`
  .center-content {
    text-align: center;
    padding: 4em 6em 6em;
  }
  .modal-options {
    margin-top: 2.5em;
  }
  .question {
    margin-bottom: 2rem;
    label,
    textarea {
      display: block;
    }
    label {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
      color: ${colors.base};
    }
    textarea {
      height: 6.5rem;
    }
  }

  // Modal styles
  .title {
    text-align: left;
    font-size: 20px;
    margin-bottom: 40px;
  }

  .btn-row {
    display: flex;
    > button {
      width: 50%;
      &:first-child {
        margin-right: 0.5em;
      }
    }
  }
`
