import React, { Component } from 'react'
import styled from 'styled-components'
import { toTitleCase } from './../../utils'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import SelectItems from './SurveyOptionsSelect'

// TODO: Need to come up with reusable UI
const StyledGroups = styled.div`
  .group {
    margin: 3em 0;
    &:first-child {
      margin-top: 0;
    }
  }
`
// props._options will be ALL of the options for the given material
class SurveyOptions extends Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount = () => {
    // this.container.current.scrollTop = 0
  }

  selectOne = optId => {
    const optsOrMsOpts = this.props.isMsStep ? 'ms_options' : 'options'

    const { survey, _options } = this.props
    // get object/document for the selected doc from the _id
    const selectedOpt = _options.find(opt => opt._id === optId)

    // dont allow it to touch database if the option being selected is already in survey.options
    const existingIdx = survey[optsOrMsOpts].findIndex(opt => opt === optId)
    if (existingIdx > -1) {
      return false
    }

    // get the array of options, removing all other elements that have the same codeId
    const filteredOpts = survey[optsOrMsOpts].filter(opt => {
      const _option = _options.find(item => item._id === opt)
      if (_option.codeId !== selectedOpt.codeId) {
        return selectedOpt._id
      } else {
        return false
      }
    })

    // update the server with the filteredOpts + newly selected
    this.props
      .updateSurveyServer(survey._id, {
        [optsOrMsOpts]: [...filteredOpts, selectedOpt._id],
      })
      .then(success => console.log('successfully added opt!'))
  }

  selectMultipleOptions = optId => {
    const { survey } = this.props
    const optsOrMsOpts = this.props.isMsStep ? 'ms_options' : 'options'
    let newOptsArr
    const existingIdx = survey[optsOrMsOpts].findIndex(opt => opt === optId)
    if (existingIdx > -1) {
      // if option is in the survey[optsOrMsOpts], remove it from survey[optsOrMsOpts] when clicked
      newOptsArr = survey[optsOrMsOpts].filter(opt => opt !== optId)
    } else {
      // if option is not in the survey[optsOrMsOpts], add it
      newOptsArr = [...survey[optsOrMsOpts], optId]
    }

    this.props
      .updateSurveyServer(survey._id, {
        [optsOrMsOpts]: newOptsArr,
      })
      .then(success => console.log('successfully added opt!'))
    // item into the array, instead remove it
  }

  render() {
    const { _options, survey } = this.props
    // groups the objects { codeId1: [...all objs with codeId], codeId2: [...] }
    const groupedOpts = _options.reduce(
      (h, a) => Object.assign(h, { [a.codeId]: (h[a.codeId] || []).concat(a) }),
      {}
    )
    // codeId's corresponding full names / does it need picket opts,
    // and method to be used for selecting an individual option
    const optData = {
      VP: {
        name: 'POST CAP OPTIONS',
        picket: false,
        select: 'selectOne',
      },
      VPC: {
        name: 'PICKET CAP OPTIONS',
        picket: true,
        select: 'selectOne',
      },
      VGC: {
        name: 'GATE CAP OPTIONS',
        picket: false,
        select: 'selectOne',
      },
      VA: {
        name: 'ACCESSORIES AND HARDWARE',
        picket: false,
        select: 'selectMultipleOptions',
      },
      VC: {
        name: 'FENCE COLOR',
        picket: false,
        select: 'selectOne',
      },
      WA: {
        name: 'ACCESSORIES',
        picket: false,
        select: 'selectMultipleOptions',
      },
      WC: {
        name: 'MATERIAL',
        picket: false,
        select: 'selectOne',
      },
      WP: {
        name: 'PICKET OPTIONS',
        picket: true,
        select: 'selectOne',
      },
      APC: {
        name: 'PICKET CAPS',
        picket: true,
        select: 'selectOne',
      },
      APCA: {
        name: 'POST CAPS AND ACCESSORIES',
        picket: false,
        select: 'selectMultipleOptions',
      },
      AM: {
        name: 'MOUNTING HARDWARE AND ACCESSORIES',
        picket: false,
        select: 'selectMultipleOptions',
      },
      AG: {
        name: 'GATE STYLE OPTIONS',
        picket: false,
        select: 'selectMultipleOptions',
      },
      AGA: {
        name: 'GATE HARDWARE',
        picket: false,
        select: 'selectMultipleOptions',
      },
      AC: {
        name: 'COLORS',
        picket: false,
        select: 'selectOne',
      },

      CLSO: {
        name: 'SELVAGE OPTIONS',
        picket: false,
        select: 'selectOne',
      },
      CLS: {
        name: 'GATE STYLES',
        picket: false,
        select: 'selectMultipleOptions',
      },
      CLSC: {
        name: 'CHAIN LINK SIZING',
        picket: false,
        select: 'selectOne',
      },
      CLC: {
        name: 'FENCE COLOR',
        picket: false,
        select: 'selectOne',
      },
    }

    const selectedStyle = this.props._styles.find(
      s => s._id === this.props.survey[this.props.isMsStep ? 'ms_style' : 'style']
    )

    return (
      <div ref={this.container}>
        <Header title="Select the options for your fence." noBorderBottom />

        <Container>
          {survey.revisionNotes && <Notice text={survey.revisionNotes} />}
          <StyledGroups>
            {Object.keys(groupedOpts).map((key, idx) => {
              if (optData[key].picket && !selectedStyle.picket) {
                return false
              } else {
                return (
                  <div className="group" key={idx}>
                    <h3>{toTitleCase(optData[key].name)}</h3>
                    <SelectItems
                      survey={this.props.survey}
                      columns={3}
                      items={groupedOpts[key]}
                      // pass in the opt _id,
                      handleClick={this[optData[key].select]}
                      field={this.props.isMsStep ? 'ms_options' : 'options'}
                    />
                  </div>
                )
              }
            })}
          </StyledGroups>
        </Container>
      </div>
    )
  }
}

export default SurveyOptions

// chainlink id
// 5b6b486df1d0fc021c24a731
// date to use
// 2018-08-08T19:45:52.144+00:00
