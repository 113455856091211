import React, { Component } from 'react'
import styled from 'styled-components'
import image from './../../assets/404.jpg'

import Container from './../sharedComponents/Container'
import Button from './../sharedComponents/Button'

class NotPermitted extends Component {
  render() {
    const { toText, toUrl } = this.props
    return (
      <StyledNotPermitted>
        <Container>
          <img src={image} alt="Not allowed" />
          <h1 className="title">
            Sorry, you do not have permission to view this route at this point in time!
          </h1>
          <Button
            text={toText ? `Back to ${toText}` : 'Back to projects'}
            handleClick={() => this.props.history.push(toUrl ? toUrl : '/projects')}
          />
        </Container>
      </StyledNotPermitted>
    )
  }
}

export default NotPermitted

const StyledNotPermitted = styled.div`
  text-align: center;
  margin: 40px 0;

  img {
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 40px;
  }
`
