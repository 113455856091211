import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { colors } from './../../../helpers/foundation'
import actionMap from './../../../helpers/actionsMap'

const ActionItem = props => {
  const { action } = props

  const actionContent = actionMap[action.type] ? actionMap[action.type](action) : null

  return (
    <StyledActionItem>
      <div className="split">
        <div className="action-datetime">{format(action.createdAt, 'MM/DD/YY @ h:mma')} -</div>
        <div className="action-actioner"> {action.user.displayName}</div>
      </div>
      <div className="action-text">{actionContent ? actionContent : 'Action not available.'}</div>
    </StyledActionItem>
  )
}

const StyledActionItem = styled.div`
  padding: 0.5em 0;
  border-bottom: 1px solid ${colors.grey7};

  &:last-child {
    border-bottom: 0;
  }

  .action-datetime {
    font-size: 0.75em;
    color: ${colors.grey};
  }

  .action-text {
    font-size: 0.875em;
    color: ${colors.grey4};

    ul {
      li {
        list-style-type: square;
      }
    }
  }

  .action-actioner {
    font-size: 0.75em;
    color: ${colors.grey};
  }

  .split {
    display: flex;
  }
`

export default ActionItem
