import styled from 'styled-components'
import { breakpoint, breakpoints } from '../../../helpers/foundation'

const Controls = styled.div`
  position: relative;
  margin: 14px auto;
  min-height: 80px;
  width: 100%;
  max-width: 1600px;

  @media screen and (max-width: 1680px) {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  ${breakpoint('small')} {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
  }

  .actions {
    display: flex;
    justify-content: end;
    flex: 1;
  }

  .buttons {
    position: relative;
    top: 7px;
    flex: 1;
    text-align: right;
    margin-right: 0.5em;
    > button {
      padding: 0.675em;
    }
  }

  .search-bar {
    margin-top: 10px;
    align-self: center;

    ${breakpoint('small')} {
      margin-top: 0;
      flex: 0 1 18em;
    }
  }

  .filters-container {
    position: relative;
    margin-top: 7px;
    margin-left: 20px;
    align-self: center;

    > svg {
      cursor: pointer;
    }

    .filters-icon {
      cursor: pointer;
      > * {
        cursor: pointer;
      }
    }
  }
`

export default Controls
