import React, { Component } from 'react'
import styled from 'styled-components'
import { colors } from './../../helpers/foundation'
import shortid from 'shortid'

import Icon from './../sharedComponents/Icon'

const faketkey = shortid.generate()

class ResizeableSector extends Component {
  state = {
    height: null,
    open: true,
  }

  componentDidUpdate(prevProps) {
    let height = this.refs[`inner-${this.props.tkey ? this.props.tkey : faketkey}`].querySelector(
      '.rs-wrapper'
    )

    if (height && this.state.height !== height.offsetHeight) {
      this.setState(prevState => ({ height: height.offsetHeight }))
    }
  }

  toggleOpen = () => {
    // console.log('TOGGLING OPEN!')
    this.setState(prevState => ({ open: !prevState.open }))
  }

  chooseTitle = () => {
    const { noTitle, smallTitle, title } = this.props
    if (noTitle && !smallTitle) {
      return null
    } else if (!noTitle && smallTitle) {
      return (
        <div className="title">
          <h2>{title}</h2>
          <div className="small-title">{smallTitle}</div>
        </div>
      )
    } else {
      return <h2 className="title">{title}</h2>
    }
  }

  render() {
    const { height, open } = this.state
    const {
      color,
      size,
      bordered,
      borderColor,
      smallTitle,
      title,
      tkey,
      noResize,
      padding,
      margin,
    } = this.props
    return (
      <StyledResizeableSector
        color={color}
        size={size}
        bordered={bordered}
        borderColor={borderColor}
        isOpen={open}
        height={height}
        padding={padding}
        margin={margin}
      >
        <div className="container">
          <div className="flex">{this.chooseTitle()}</div>

          <div ref={`inner-${tkey ? tkey : faketkey}`} className="inner">
            {this.props.children}
          </div>
        </div>
      </StyledResizeableSector>
    )
  }
}

export default ResizeableSector

const StyledResizeableSector = styled.div`
  padding: ${props => (props.color && props.color === 'white' ? '0' : '1em')};

  background-color: ${props => (props.color && props.color === 'white' ? '#fff' : colors.grey5)};
  border: ${props => (props.bordered ? `1px solid ${colors.lightGrey}` : 'none')};
  border-top: ${props =>
    props.bordered && props.borderColor && `4px solid ${colors[props.borderColor]}`};
  border-radius: 4px;
  margin: ${props => (props.margin ? `${props.margin}` : '1em 0 3em')};

  .container {
    width: 100%;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .carrot {
    position: relative;
    top: 15px;
    right: 35px;
    cursor: pointer;
  }

  > .inner {
    transition: height 1s ease-in-out;
    height: ${props => (props.isOpen ? `${props.height}px` : '0px')};
    overflow: hidden;

    > .rs-wrapper {
      height: ${props => (props.isOpen ? `${props.height}px` : '0px')};
    }
  }

  h2 {
    font-size: ${props => {
      if (props.size && props.size === 'sm') return '1.25em'
      if (props.size && props.size === 'lg') return '2em'
      return '1.5em'
    }};
    font-weight: 600;
    margin: 0;
  }

  .title {
    padding: ${props => {
      if (props.padding) return props.padding
      if (props.color && props.color === 'white') return '0.75rem'
      return '0'
    }};

    margin-bottom: ${props => (props.color && props.color === 'white' ? '0' : '1.5em')};
    border-bottom: ${props =>
      props.color && props.color === 'white' ? `1px solid ${colors.grey5}` : 'none'};
    width: 100%;
    min-width: 100%;

    h2,
    .small-title {
      display: inline-block;
    }

    .small-title {
      font-size: 1em;
      color: ${colors.grey};
      margin-left: 1em;
    }
  }

  small {
    display: block;
    font-size: 12px;
    color: ${colors.grey2};
  }

  b {
    display: block;
    margin: 1em 0;
  }

  ol {
    padding-left: 1em;
    li {
      margin-bottom: 1em;
    }
    div {
      display: block;
    }
  }
`
