import React from 'react'
import styled from 'styled-components'

import Header from './../../sharedComponents/Header'
import Container from './../../sharedComponents/Container'
import Notice from './../../sharedComponents/Notice'

const Completed = props => {
  return <StyledCompleted>
    <Header title="This project has been completed." />
    <Container>
      <Notice text="The customer is now at the screen where they have an option to write a review." />
    </Container>
  </StyledCompleted>
}

const StyledCompleted = styled.div``

export default Completed
