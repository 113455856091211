import React, { Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import { toTitleCase, splitStringByCapital } from './../../../../../utils'

import Container from './../../../../sharedComponents/Container'
import { LargeCard } from './../../../../sharedComponents/Card-NEW'
import { MediumHeader } from './../../../../sharedComponents/Header-NEW'
import { Collection, CollectionItem } from './../../../../sharedComponents/layouts/Collection-NEW'
import Icon from './../../../../sharedComponents/Icon'

const ReviewSales = props => {
  const { survey } = props
  console.log('survey', survey)
  return (
    <Container width="1600">
      <div className="section">
        <MediumHeader>Material</MediumHeader>
        <Collection>
          {survey.material && survey.material instanceof Object && (
            <CollectionItem>
              <LargeCard
                id={survey.material._id}
                img={survey.material.image}
                text={survey.material.name}
              />
            </CollectionItem>
          )}
        </Collection>
      </div>
      <div className="section">
        <MediumHeader>Style</MediumHeader>
        <Collection>
          {survey.style && survey.style instanceof Object && (
            <CollectionItem>
              <LargeCard id={survey.style._id} img={survey.style.image} text={survey.style.name} />
            </CollectionItem>
          )}
        </Collection>
      </div>
      <div className="section">
        <MediumHeader>Options</MediumHeader>
        {survey.options && survey.options.length > 0 && survey.options[0] instanceof Object && (
          <Collection>
            {survey.options.map(opt => {
              return (
                <CollectionItem key={opt._id}>
                  <LargeCard id={opt._id} img={opt.image} text={toTitleCase(opt.name)} />
                </CollectionItem>
              )
            })}
          </Collection>
        )}
      </div>
      <div className="bordered-section">
        <div className="split">
          <div className="height">
            <MediumHeader>Height</MediumHeader>
            <div className="light">{survey.height} Feet</div>
          </div>
          <div className="contour">
            <MediumHeader>Contour</MediumHeader>
            <div className="light">{survey.contour ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </div>

      {/* secondary materials */}
      {survey.isMultiStyle && (
        <>
          <div className="section">
            <MediumHeader>Second Material</MediumHeader>
            <Collection>
              {survey.ms_material && survey.ms_material instanceof Object && (
                <CollectionItem>
                  <LargeCard
                    id={survey.ms_material._id}
                    img={survey.ms_material.image}
                    text={survey.ms_material.name}
                  />
                </CollectionItem>
              )}
            </Collection>
          </div>
          <div className="section">
            <MediumHeader>Second Style</MediumHeader>
            <Collection>
              {survey.ms_style && survey.ms_style instanceof Object && (
                <CollectionItem>
                  <LargeCard
                    id={survey.ms_style._id}
                    img={survey.ms_style.image}
                    text={survey.ms_style.name}
                  />
                </CollectionItem>
              )}
            </Collection>
          </div>
          <div className="section">
            <MediumHeader>Second Options</MediumHeader>
            {survey.ms_options &&
              survey.ms_options.length > 0 &&
              survey.ms_options[0] instanceof Object && (
                <Collection>
                  {survey.ms_options.map(opt => {
                    return (
                      <CollectionItem key={opt._id}>
                        <LargeCard id={opt._id} img={opt.image} text={toTitleCase(opt.name)} />
                      </CollectionItem>
                    )
                  })}
                </Collection>
              )}
          </div>
          <div className="bordered-section">
            <div className="split">
              <div className="height">
                <MediumHeader>Second Height</MediumHeader>
                <div className="light">{survey.ms_height} Feet</div>
              </div>
              <div className="contour">
                <MediumHeader>Second Contour</MediumHeader>
                <div className="light">{survey.ms_contour ? 'Yes' : 'No'}</div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="section">
        <MediumHeader>Property Photos</MediumHeader>
        {survey.photos &&
          survey.photos.frontLeft.small &&
          survey.photos.frontLeft.small.length > 0 && (
            <Collection>
              {Object.keys(survey.photos).map(photoKey => {
                const photo = survey.photos[photoKey]
                return (
                  <CollectionItem key={photoKey}>
                    <LargeCard
                      id={photoKey}
                      img={photo.large}
                      text={toTitleCase(splitStringByCapital(photoKey))}
                      selectable={true}
                      handleClick={e => props.setImage(photo.large)}
                    />
                  </CollectionItem>
                )
              })}
            </Collection>
          )}
      </div>
      <div className="section">
        <MediumHeader>Selected Details</MediumHeader>
        {Object.keys(survey.details).filter(detailKey => survey.details[detailKey].selected)
          .length > 0 && (
          <>
            {Object.keys(survey.details)
              .filter(detailKey => survey.details[detailKey].selected)
              .map(detailKey => {
                const detail = survey.details[detailKey]
                return (
                  <Fragment key={detailKey}>
                    <div className="head">
                      <span className="margin-right">
                        <Icon icon="solidCheck" color="primary" />
                      </span>
                      <span className="detail-name">
                        {toTitleCase(splitStringByCapital(detailKey))}
                      </span>
                    </div>
                    <Collection>
                      {detail.images.map((img, idx) => {
                        return (
                          <CollectionItem key={idx}>
                            <LargeCard
                              id={idx}
                              img={img.large}
                              text={img.notes && img.notes.length > 0 ? img.notes : 'No Notes.'}
                              selectable={true}
                              handleClick={e => props.setImage(img.large)}
                            />
                          </CollectionItem>
                        )
                      })}
                    </Collection>
                  </Fragment>
                )
              })}
          </>
        )}
      </div>
      <div className="bordered-section">
        <MediumHeader>Additional Details</MediumHeader>
        <div className="split">
          <div className="left">
            <div className="qa">
              <div className="question">{survey.additionalDetails['permits'].question}</div>
              <div className="answer">{survey.additionalDetails['permits'].answer}</div>
            </div>
            <div className="qa">
              <div className="question">
                {survey.additionalDetails['contoursSpaceAcknowledge'].question}
              </div>
              <div className="answer">
                {survey.additionalDetails['contoursSpaceAcknowledge'].answer}
              </div>
            </div>
            <div className="qa">
              <div className="question">
                {survey.additionalDetails['hoaAprovalRequired'].question}
              </div>
              <div className="answer">{survey.additionalDetails['hoaAprovalRequired'].answer}</div>
            </div>
          </div>
          <div className="right">
            <div className="qa">
              <div className="question">{survey.additionalDetails['waitForApproval'].question}</div>
              <div className="answer">{survey.additionalDetails['waitForApproval'].answer}</div>
            </div>
            <div className="qa">
              <div className="question">
                {survey.additionalDetails['gateSwingCorrect'].question}
              </div>
              <div className="answer">{survey.additionalDetails['gateSwingCorrect'].answer}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <MediumHeader>Blueprint Sketch</MediumHeader>
        <LargeCard
          isXL={true}
          img={survey.blueprint.sketch}
          text="Comments"
          content={
            survey.blueprint.comments.length < 1 ? (
              <div className="item">N/A</div>
            ) : (
              <>
                {survey.blueprint.comments.map((comment, i) => {
                  return <div className="item">{comment.text}</div>
                })}
              </>
            )
          }
        />
      </div>

      <div className="bordered-section no-bottom">
        <MediumHeader>Additional Notes</MediumHeader>
        <div className="qa">
          <div className="question">{survey.notes.homeownersRequest.question}</div>
          <div className="answer">
            {survey.notes.homeownersRequest.answer &&
            survey.notes.homeownersRequest.answer.length > 0
              ? survey.notes.homeownersRequest.answer
              : 'N/A'}
          </div>
        </div>
        <div className="qa">
          <div className="question">{survey.notes.salesmanNotes.question}</div>
          <div className="answer">
            {survey.notes.salesmanNotes.answer && survey.notes.salesmanNotes.answer.length > 0
              ? survey.notes.salesmanNotes.answer
              : 'N/A'}
          </div>
        </div>
        <div className="qa">
          <div className="question">{survey.notes.specialConditions.question}</div>
          <div className="answer">
            {survey.notes.specialConditions.answer &&
            survey.notes.specialConditions.answer.length > 0
              ? survey.notes.specialConditions.answer
              : 'N/A'}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ReviewSales
