import React, { Component } from 'react'
import styled from 'styled-components'
import { isURL, downloadURL, downloadDataURI } from './../../../utils'
import { colors } from './../../../helpers/foundation'

import ResizeableSector from './../ResizeableSector'
import StyledTable from './../StyledTable'
import Icon from './../Icon'

class Paperwork extends Component {
  getStatusIcon = file => {
    if (file.status === 'approved') {
      return <Icon icon="solidCheck" color="primary" />
    } else if (file.status === 'revise') {
      return <Icon icon="spinnerCounterClockwise" color="danger" />
    } else {
      return ''
    }
  }

  render() {
    let hoaFiles, permitFiles

    hoaFiles = [...this.props.paperwork.files, ...this.props.paperwork.additionalFiles].filter(
      file => {
        if (file.type === 'hoa' && file.filename && file.filepath) {
          return file
        }
      }
    )
    permitFiles = [...this.props.paperwork.files, ...this.props.paperwork.additionalFiles].filter(
      file => {
        if (file.type === 'permit' && file.filename && file.filepath) {
          return file
        }
      }
    )

    return (
      <StyledPaperwork>
        <ResizeableSector title="HOA Files" color="white" noResize={true}>
          <div className="rs-wrapper">
            {hoaFiles.length < 1 ? (
              'No HOA files have been uploaded yet.'
            ) : (
              <div className="files">
                {hoaFiles.map(file => {
                  return (
                    <div
                      className="file"
                      key={file.id}
                      onClick={e =>
                        isURL(file.filepath)
                          ? downloadURL(file.filepath, file.filename)
                          : downloadDataURI(file.filepath, file.filename)
                      }
                    >
                      <div className="status">{this.getStatusIcon(file)}</div>
                      <div className="name">{file.name}</div>
                      <div className="filename">{file.filename}</div>
                      <div className="download">
                        <Icon icon="download" />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </ResizeableSector>
        <ResizeableSector title="Permit Files" color="white" noResize={true}>
          <div className="rs-wrapper">
            {permitFiles.length < 1 ? (
              'No Permit files have been uploaded yet.'
            ) : (
              <div className="files">
                {permitFiles.map(file => {
                  return (
                    <div
                      className="file"
                      key={file.id}
                      onClick={e =>
                        isURL(file.filepath)
                          ? downloadURL(file.filepath, file.filename)
                          : downloadDataURI(file.filepath, file.filename)
                      }
                    >
                      <div className="status">{this.getStatusIcon(file)}</div>
                      <div className="name">{file.name}</div>
                      <div className="filename">{file.filename}</div>
                      <div className="download">
                        <Icon icon="download" />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </ResizeableSector>
      </StyledPaperwork>
    )
  }
}

const StyledPaperwork = styled.div`
  .rs-wrapper {
    padding: 0.75rem;
  }

  .file {
    position: relative;
    display: flex;
    border-bottom: 1px solid ${colors.grey7};
    padding: 0.5em 0;
    cursor: pointer;

    &:hover {
      background-color: ${colors.grey5};
    }

    .status {
      min-width: 1.25em;
      margin-right: 1em;
    }

    .name {
      margin-right: 1em;
      font-weight: 600;
    }

    .filename {
      margin-right: 0.5em;
      color: ${colors.grey};
    }

    .download {
      position: relative;
      top: 1px;
    }

    /* &:first-child {
      padding: 0 0 0.5em;
    } */

    &:last-child {
      border-bottom: 0;
    }
  }
`

export default Paperwork
