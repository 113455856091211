import addHours from 'date-fns/add_hours'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import isAfter from 'date-fns/is_after'
import addMinutes from 'date-fns/add_minutes'

export const formatMinutesToString = minutes => {
  const hours = Math.floor(Math.abs(minutes / 60))
  const mins = Math.abs(minutes % 60)
  // returns '01:25' or '-01:25'
  return `${minutes < 0 ? '-' : ''}${hours > 9 ? hours : '0' + hours}:${
    mins > 9 ? mins : '0' + mins
  }`
}

export const expirationDate = (date, isPaperwork = false) => {
  const cleanExpiration = distanceInWordsToNow(date)
    .replace('about', '')
    .replace('over', '')
    .replace('almost', '')

  if (isPaperwork) {
    return `Started ${cleanExpiration} ago`
  }

  if (isAfter(date, Date.now())) {
    return `Due in ${cleanExpiration}`
  } else {
    return `Due ${cleanExpiration} ago`
  }
}

export const getTimeDistance = (item, gs) => {
  if (!item.survey.lastStatus) {
    return null
  }

  if (!gs) {
    return null
  }

  // Last status is the date the status was last updated
  switch (item.stage) {
    case 'survey':
      let sTimer = null
      switch (item.survey.status) {
        // Schedule - 2 hours
        case 'To Schedule':
          return addHours(item.survey.lastStatus, 2)
        // To Do - Event start date
        case 'To Do':
          return addMinutes(item.survey.event.start, 30)
        // In Progress - 30 mins from when they start
        case 'In Progress':
          sTimer = gs.surveyTimers.inProgress

          return addMinutes(item.survey.lastStatus, sTimer.hour * 60 + sTimer.minute)
        // To Review - 2 hours
        case 'To Review':
          sTimer = gs.surveyTimers.toReview

          return addMinutes(item.survey.lastStatus, sTimer.hour * 60 + sTimer.minute)
        // To Revise - 2 hours
        case 'To Revise':
          sTimer = gs.surveyTimers.toRevise

          return addMinutes(item.survey.lastStatus, sTimer.hour * 60 + sTimer.minute)
        default:
          return ''
        // Todo - Start date of
      }

    case 'estimate':
      let eTimer = null
      switch (item.estimate.status) {
        // 2 hours for all items
        case 'To Do':
          eTimer = gs.estimateTimers.toDo
          // have to use survey here, otherwise would have to do a /api/estimates
          // request when marking survey as 'Completed'
          return addMinutes(item.survey.lastStatus, eTimer.hour * 60 + eTimer.minute)
        case 'In Progress':
          eTimer = gs.estimateTimers.inProgress

          return addMinutes(item.estimate.lastStatus, eTimer.hour * 60 + eTimer.minute)
        case 'To Review':
          eTimer = gs.estimateTimers.toReview

          return addMinutes(item.estimate.lastStatus, eTimer.hour * 60 + eTimer.minute)
        case 'To Revise':
          eTimer = gs.estimateTimers.toRevise

          return addMinutes(item.estimate.lastStatus, eTimer.hour * 60 + eTimer.minute)

        default:
          return ''
      }

    case 'paperwork':
      let pTimer = null

      pTimer = gs.paperworkTimers.gatherFiles
      // need to check the lastStatus of estimate here, it wont be set for paperwork
      return addMinutes(item.estimate.lastStatus, pTimer.hour * 60 + pTimer.minute)

    case 'installation':
      let iTimer = null
      switch (item.installation.installationStatus) {
        // 2 hours for all items
        case 'Schedule Installation':
          iTimer = gs.installationTimers.scheduleInstallation

          return addMinutes(item.paperwork.lastStatus, iTimer.hour * 60 + iTimer.minute)

        case 'Installation Prep':
          iTimer = gs.installationTimers.installationPrep

          return addMinutes(item.installation.lastStatus, iTimer.hour * 60 + iTimer.minute)
        case 'In Progress':
          iTimer = gs.installationTimers.inProgress

          return addMinutes(item.installation.lastStatus, iTimer.hour * 60 + iTimer.minute)
        case 'Inspection':
          iTimer = gs.installationTimers.inspection

          return addMinutes(item.installation.lastStatus, iTimer.hour * 60 + iTimer.minute)
      }
    default:
      return ''
  }
}
