import React from 'react'
import { VictoryPie } from 'victory'
import styled from 'styled-components'
import { colors, breakpoints } from './../../../../../helpers/foundation'

const ProjectGraph = ({ data, quotes }) => {
  const projectData = () => {
    // const Surveys = data.filter(project => {
    //   return project.stage === 'survey'
    // }).length

    // const Estimates = data.filter(project => {
    //   return project.stage === 'estimate'
    // }).length

    // const Quotes = quotes.filter(quote => {
    //   return quote.isValid
    // }).length

    // const Paperworks = data.filter(project => {
    //   return project.stage === 'paperwork'
    // }).length

    // const Installations = data.filter(project => {
    //   return project.stage === 'installation'
    // }).length

    const Surveys = data.survey
    const Estimates = data.estimate
    const Quotes = data.quote
    const Paperworks = data.paperwork
    const Installations = data.installation

    return [
      {
        stage: 'Quotes',
        amount: Quotes,
      },
      {
        stage: 'Projects in Survey',
        amount: Surveys,
      },
      {
        stage: 'Projects in Estimate',
        amount: Estimates,
      },
      {
        stage: 'Projects in Paperwork',
        amount: Paperworks,
      },
      {
        stage: 'Projects in Installation',
        amount: Installations,
      },
    ]
  }

  return (
    <StyledGraph>
      <div className="graph">
        <VictoryPie
          colorScale={['teal', colors.blue, colors.purple, colors.orange, colors.pink]}
          innerRadius={130}
          data={projectData()}
          labels={() => null}
          x="stage"
          y="amount"
        />
      </div>

      <div className="labels">
        {projectData().map(data => {
          return (
            <StyledLabel key={data.stage}>
              <span className="amount">{data.amount}</span>
              <span className="text">{data.stage}</span>
            </StyledLabel>
          )
        })}
      </div>
    </StyledGraph>
  )
}

const StyledGraph = styled.div`
  @media screen and (min-width: ${breakpoints.small}px) {
    display: flex;
    align-items: center;
  }

  .graph {
    flex: 1 1 50%;
  }

  .labels {
    flex: 1 0 50%;
    padding: 20px;
  }
`

const StyledLabel = styled.div`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(1) {
    .amount {
      background-color: teal;
    }
  }

  &:nth-child(2) {
    .amount {
      background-color: ${colors.blue};
    }
  }

  &:nth-child(3) {
    .amount {
      background-color: ${colors.purple};
    }
  }

  &:nth-child(4) {
    .amount {
      background-color: ${colors.orange};
    }
  }

  &:nth-child(5) {
    .amount {
      background-color: ${colors.pink};
    }
  }

  .amount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: ${colors.orange};
    color: #fff;
    border-radius: 4px;
    height: 24px;
    min-width: 24px;
    text-align: center;
  }

  .text {
    margin-left: 10px;
    color: ${colors.grey2};
  }
`

export default ProjectGraph
