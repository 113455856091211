import axios from 'axios'
import { addProductionUrl } from './../../helpers/constants'

export const ATTEMPTING_PASSWORD_CHANGE =
  'app/changePasswordForm/ATTEMPTING_PASSWORD_CHANGE'
export const FAILED = 'app/changePasswordForm/FAILED'
export const SUCCESS = 'app/changePasswordForm/SUCCESS'

const initialState = {
  error: null,
  message: null,
  inProgress: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ATTEMPTING_PASSWORD_CHANGE:
      return {
        ...state,
        inProgress: true,
        message: null,
        error: null,
      }
    case SUCCESS:
      return {
        ...state,
        inProgress: false,
        message: action.message,
        error: null,
      }
    case FAILED:
      return {
        ...state,
        inProgress: false,
        message: null,
        error: action.error,
      }
    default:
      return state
  }
}

export function attemptChangePassword() {
  return {
    type: ATTEMPTING_PASSWORD_CHANGE,
  }
}

export function changeFailed(error) {
  return {
    type: FAILED,
    error,
  }
}

export function changeSuccessful(message) {
  return {
    type: SUCCESS,
    message,
  }
}

export function changePassword(currentPassword, newPassword) {
  return dispatch => {
    dispatch(attemptChangePassword())

    axios
      .patch(`${addProductionUrl()}/api/users/change-password`, {
        currentPassword,
        newPassword,
      })
      .then(res => {
        dispatch(changeSuccessful(res.data.message))
      })
      .catch(error => {
        dispatch(changeFailed(error.response.data.message))
      })
  }
}
